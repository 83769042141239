export const campaignEndToNum = (campaign) => campaign.endTime ? +new Date(campaign.endTime) : Infinity

export const farthestCampaignendTime = (campaigns) => {
  return campaigns.reduce((acc, currentCampaign) => {
    return +acc < campaignEndToNum(currentCampaign)
      ? campaignEndToNum(currentCampaign)
      : acc
  }, 0)
}

export const getEndOfYearInSeconds = (date) => {
  return +new Date(date.getFullYear(), 11, 31)
}
