import { List } from '@mui/material'
import { IoHome, IoSync } from 'react-icons/io5'
import { SideNavItem } from './SideNavItem'

function DistributorNavRoutes ({ id, isSideNavOpen }) {
  return (
    <List>
      <SideNavItem
        id='distributor-home-nav-link'
        text='Distributor Home'
        route={`/distributors/${id}`}
        isSideNavOpen={isSideNavOpen}
        icon={<IoHome style={{ color: 'var(--phin-white)' }} size='1.5em' />}
      />
      <SideNavItem
        id='distributor-integrations-nav-link'
        text='Integrations'
        route={`/distributors/${id}/integrations`}
        isSideNavOpen={isSideNavOpen}
        icon={<IoSync style={{ color: 'var(--phin-white)' }} size='1.5em' />}
      />
    </List>
  )
}

export default DistributorNavRoutes
