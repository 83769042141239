import { createSlice } from '@reduxjs/toolkit'
import { orgLevels } from '../frontendConsts.js'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'

export const welcomeEmailSlice = createSlice({
  name: 'WelcomeEmail',
  initialState: {
    welcomeEmailBody: null,
    welcomeEmailSubject: null,
    welcomeEmailHasChanged: false,
    welcomeEmailFromName: null,
    welcomeEmailFromAddress: null,
    welcomeEmailFromDomain: null,
    unwelcomedUserIds: [],
    loaders: {
      isLoadingWelcomeEmail: false
    },
    welcomeEmailOrgLevel: null
  },
  reducers: {
    setWelcomeEmailBody: (state, action) => {
      state.welcomeEmailBody = action.payload
    },
    setWelcomeEmailSubject: (state, action) => {
      state.welcomeEmailSubject = action.payload
    },
    setWelcomeEmailHasChanged: (state, action) => {
      state.welcomeEmailHasChanged = action.payload
    },
    setWelcomeEmailFromName: (state, action) => {
      state.welcomeEmailFromName = action.payload
    },
    setWelcomeEmailFromAddress: (state, action) => {
      state.welcomeEmailFromAddress = action.payload
    },
    setIsLoadingWelcomeEmail: (state, action) => {
      state.loaders.isLoadingWelcomeEmail = action.payload
    },
    setWelcomeEmailOrgLevel: (state, action) => {
      state.welcomeEmailOrgLevel = action.payload
    },
    welcomeEmailLoaded: (state, action) => {
      state.welcomeEmailBody = action.payload.welcomeEmailTemplate.body
      state.welcomeEmailSubject = action.payload.welcomeEmailTemplate.subject
      state.welcomeEmailFromName = action.payload.welcomeEmailTemplate.fromName
      state.welcomeEmailFromAddress = action.payload.welcomeEmailTemplate.fromAddress
      state.welcomeEmailFromDomain = action.payload.welcomeEmailTemplate.fromDomain
      state.welcomeEmailOrgLevel = action.payload.welcomeEmailTemplate.orgLevel
    },
    setUnwelcomedUserIds: (state, action) => {
      state.unwelcomedUserIds = action.payload
    }
  }
})

export const {
  setWelcomeEmailBody: setWelcomeEmailBodyAction,
  setWelcomeEmailSubject: setWelcomeEmailSubjectAction,
  setWelcomeEmailHasChanged: setWelcomeEmailHasChangedAction,
  setWelcomeEmailFromName: setWelcomeEmailFromNameAction,
  setWelcomeEmailFromAddress: setWelcomeEmailFromAddressAction,
  setIsLoadingWelcomeEmail: setIsLoadingWelcomeEmailAction,
  setWelcomeEmailOrgLevel: setWelcomeEmailOrgLevelAction,
  welcomeEmailLoaded: welcomeEmailLoadedAction,
  setUnwelcomedUserIds: setUnwelcomedUserIdsAction
} = welcomeEmailSlice.actions

export default welcomeEmailSlice.reducer

export const saveWelcomeEmailThunk = ({ id, orgLevel, emailBody, emailFromAddress, emailFromName, emailSubject }) => {
  return async (dispatch, getState, api) => {
    let result = ''
    const emailOrgLevel = getState().welcomeEmail.welcomeEmailOrgLevel
    // when from domain is editable, change this to be passed in like the other values
    const emailFromDomain = getState().welcomeEmail.welcomeEmailFromDomain
    // If the org level from the DB (welcomeEmailOrgLevel) matches the org level from the component (orgLevel) we're editing an..
    // existing template from the same hierarchy level (DEFAULT > MSP > COMPANY)
    if (emailOrgLevel === orgLevel) {
      if (orgLevel === orgLevels.COMPANY) {
        result = await api.put(`/api/companies/${id}/welcome-email`, { emailBody, emailFromName, emailFromAddress, emailSubject, id, orgLevel, emailFromDomain })
      } else if (orgLevel === orgLevels.PARTNER) {
        result = await api.put(`/api/partners/${id}/welcome-email`, { emailBody, emailFromName, emailFromAddress, emailSubject, id, orgLevel, emailFromDomain })
      } else {
        throw new Error('Provided orglevel is invalid')
      }
    // If the orgLevels do not match, that means the template is inherited from a higher level of the hierarchy and we need to save..
    // a new template in the DB
    } else {
      if (orgLevel === orgLevels.COMPANY) {
        result = await api.post(`/api/companies/${id}/welcome-email`, { emailBody, emailFromName, emailFromAddress, emailSubject, id, orgLevel, emailFromDomain })
      } else if (orgLevel === orgLevels.PARTNER) {
        result = await api.post(`/api/partners/${id}/welcome-email`, { emailBody, emailFromName, emailFromAddress, emailSubject, id, orgLevel, emailFromDomain })
      } else {
        throw new Error('Provided org level is not valid')
      }
    }
    if (result.status === 200) {
      dispatch(setWelcomeEmailSubjectAction(emailSubject))
      dispatch(setWelcomeEmailBodyAction(emailBody))
      dispatch(setWelcomeEmailFromNameAction(emailFromName))
      dispatch(setWelcomeEmailFromAddressAction(emailFromAddress))
      dispatch(setWelcomeEmailHasChangedAction(false))
      dispatch(setWelcomeEmailOrgLevelAction(orgLevel))
      setTimeout(() => {
        NotificationUtilities.sendSuccessMessage('Successfully saved Welcome Email Template')
      }, 0)
    } else {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to save welcome email template.  Please try again or contact Phin Support if problem persists.')
      }, 0)
    }
  }
}
export const getWelcomeEmailThunk = (id, orgLevel) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingWelcomeEmailAction(true))
    try {
      let res = {}
      if (orgLevel === orgLevels.PARTNER) {
        res = await api.get(`/api/partners/${id}/welcome-email`)
      } else if (orgLevel === orgLevels.COMPANY) {
        res = await api.get(`/api/companies/${id}/welcome-email`)
      }
      if (res.status === 200) {
        const welcomeEmail = await res.json()
        dispatch(welcomeEmailLoadedAction(welcomeEmail))
      }
    } catch (err) {
      console.error(err)
    }
    dispatch(setIsLoadingWelcomeEmailAction(false))
  }
}

export const sendTestEmailThunk = (id, testEmail, sendEngine) => {
  return async (dispatch, getState, api) => {
    try {
      const res = await api.post(`/api/companies/${id}/notifications/welcome/test`, { sendAt: new Date(), testEmail, sendEngine })

      if (!res.ok) {
        NotificationUtilities.sendErrorMessage('Failed to send test email!')
      } else {
        NotificationUtilities.sendSuccessMessage('Successfully scheduled test email. It could be a few minutes before it arrives')

        return true
      }
    } catch (err) {
      console.error(err)
    }

    return false
  }
}

export const getUnWelcomedUsersThunk = (companyId) => {
  return async (dispatch, getState, api) => {
    try {
      const res = await api.get(`/api/companies/${companyId}/branding/unwelcomed-users`)

      if (res.ok) {
        const { unwelcomedUserIds } = await res.json()
        dispatch(setUnwelcomedUserIdsAction(unwelcomedUserIds))
      } else {
        const { errorMessage } = await res.json()
        throw new Error(errorMessage)
      }
    } catch (err) {
      console.error(err)
    }
  }
}
