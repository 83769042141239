import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Spinner from '../Spinner'
import NotificationUtilities from '../notifications/notificationUtils'
import { apiRequestUtils } from './../../../utils/apiRequestUtils'
import { microsoftIntegrationStatuses } from '../../../frontendConsts.js'

export function ExternalIntegrationPage () {
  const history = useHistory()

  useEffect(() => {
    async function setup () {
      const searchParams = new URLSearchParams(history.location.search)
      const adminConsent = searchParams.get('admin_consent')
      const tenantId = searchParams.get('tenant')
      const stateParams = searchParams.get('state')
      const stateParamsArray = stateParams.split('|')
      const companyId = stateParamsArray[0]
      const integrationId = stateParamsArray[1]
      const isAzureGovTenant = stateParamsArray[2]

      if (adminConsent !== 'True') {
        const error = searchParams.get('error')
        const errorSubcode = searchParams.get('error_subcode')
        setTimeout(() => {
          if (error && errorSubcode && errorSubcode === 'cancel') {
            NotificationUtilities.sendErrorMessage('An admin must consent to integration with Microsoft. The consent flow was cancelled')
          } else {
            NotificationUtilities.sendErrorMessage('An admin must consent to integration with Microsoft')
          }
        }, 0)
        if (companyId) {
          history.push(`/companies/${companyId}/integrations`)
        } else {
          history.push('/')
        }
        return
      }

      const requestBody = {
        integrationStatus: microsoftIntegrationStatuses.CONSENT_SUBMITTED, tenantId, integrationId
      }

      if (isAzureGovTenant) {
        requestBody.isAzureGovTenant = true
      }

      // store that admin has submitted consent
      const response = await apiRequestUtils.put(`/api/companies/${companyId}/integrations/microsoft/consent`, requestBody)

      if (response.status !== 200) {
        NotificationUtilities.sendErrorMessage('An error occured loading your consent from Microsoft. Please try again or contact Phin support for assistance if this issue persists.')
        history.push(`/companies/${companyId}/integrations`)
      } else {
        // redirect back to integration page
        return history.push(`/companies/${companyId}/integrations/${integrationId}`)
      }
    }

    setup()
  }, [])

  return (<Spinner message='Submitting Microsoft Consent' />)
}
