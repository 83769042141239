import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box } from '@mui/material'
import { getActiveCampaignByIdThunk } from '../../../store/CampaignsSlice'
import { FixedAudienceSelector } from '../audience/FixedAudienceSelector'

export const AddOnboardingUsersTable = ({
  companyId, campaignId, fixedAudienceList,
  setFixedAudienceList, initialFixedAudienceList, setInitialFixedAudienceList
}) => {
  const { usersMap } = useSelector((state) => state.users)
  const campaign = useSelector((state) => state.campaigns.campaignPreviewData)
  const dispatch = useDispatch()
  const users = Object.values(usersMap)

  useEffect(() => {
    const result = users.filter((user) => {
      if (campaign?.audienceMap) {
        return campaign.audienceMap[user?.id]
      } else {
        return null
      }
    })
    setFixedAudienceList(result)
    setInitialFixedAudienceList(result)
  }, [campaign])

  useEffect(() => {
    dispatch(getActiveCampaignByIdThunk({ companyId, campaignId }))
  }, [])

  return (
    <Box sx={{ maxHeight: '90vh', margin: '20px' }}>
      <Box
        sx={{ display: 'flex', p: 1 }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <h2>Add Users To Onboarding</h2>
        </Box>
      </Box>
      <p>The selected users will be enrolled immediately in this campaign</p>
      <Box sx={{ maxHeight: '80vh' }} className='scroll-shadows'>
        <FixedAudienceSelector
          fixedAudienceList={fixedAudienceList}
          setFixedAudienceList={setFixedAudienceList}
          isEditOnboarding
          initialFixedAudienceList={initialFixedAudienceList}
          users={users}
          verticalView
        />

      </Box>
    </Box>
  )
}
