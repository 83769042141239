import { DateTime, Interval } from 'luxon'
import { PHISHING_DEFAULT_SEND_WINDOW } from '../frontendConsts'

export const getYearAndNextMonth = (startDate = new Date()) => {
  const nextMonth = (startDate.getMonth() === 11 ? 0 : startDate.getMonth() + 1)
  const year = (nextMonth === 0) ? startDate.getFullYear() + 1 : startDate.getFullYear()
  return { year, nextMonth }
}

export const calculateStartTime = (now) => {
  const tomorrow = now.plus({ days: 1 }).startOf('day')

  const firstOfNextMonth = now.plus({ month: 1 }).startOf('month')

  if (Math.abs(firstOfNextMonth.diff(tomorrow, 'days').days) < 7) {
    return firstOfNextMonth.plus({ days: 7 })
  } else {
    return firstOfNextMonth.minus({ days: 1 })
  }
}

export const determinePhishingScheduleString = (campaign, companyTimeZone) => {
  const nextFireDateTime = DateTime.fromSeconds(campaign.nextFireTimestamp._seconds).setZone(companyTimeZone)

  // Subtract one from the window as we include the day we start as a sending day
  let endLaunchDate = nextFireDateTime.plus({ days: (campaign.phishingAttemptWindowDays || PHISHING_DEFAULT_SEND_WINDOW) - 1 })

  // If we crossed a weekend add two days to properly account we only fire on weekdays
  const thisSaturday = DateTime.fromISO(endLaunchDate.toISO()).set({ weekday: 6 })
  const phishingInterval = Interval.fromDateTimes(nextFireDateTime, endLaunchDate)

  const includesSaturday = phishingInterval.contains(thisSaturday)

  // If we have crossed or ended on a weekend, add two days to account for the weekend
  endLaunchDate = endLaunchDate.plus({ days: includesSaturday ? 2 : 0 })

  return {
    start: nextFireDateTime.toFormat('MM/d/yyyy'),
    end: endLaunchDate.toFormat('MM/d/yyyy')
  }
}

/**
   * Formats a date into a localized string representation.
   * @param {DateTime} date - The Luxon date to be formatted.
   * @returns {string} The formatted date string.
   */
export function formatDate (date) {
  return date.toLocaleString(DateTime.DATETIME_SHORT)
}

/**
 * Sanitize campaignFrequency from the front end to not include dropdown/input { text, value } objects.
 * Safe for use with scheduling backend routes.
 * @param {Object} campaignFrequency
 */
export function sanitizeCampaignFrequency (campaignFrequency) {
  const safeFrequency = { ...campaignFrequency }
  for (const [key, value] of Object.entries(safeFrequency)) {
    if (value?.text) {
      // Due to bad input seperation month can become a string when editing a campaign. It MUST be a number
      if (key === 'month') {
        safeFrequency[key] = parseInt(value.value)
      } else {
        safeFrequency[key] = value.value
      }
    }

    // This is an array and we need to process it
    if (value instanceof Array) {
      const newArray = [...value]
      for (let i = 0; i < value.length; i++) {
        if (value[i].text) {
          newArray[i] = value[i].value
        }
      }
      safeFrequency[key] = newArray
    }
  }

  return safeFrequency
}
