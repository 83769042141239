import React, { useEffect, useState } from 'react'
import {
  MDBContainer, MDBCol, MDBRow
} from 'mdb-react-ui-kit'

import { tzOptions } from './components/settingsPage/TimezoneSelect'
import GeneralSettings from './components/settingsPage/GeneralSettings'
import SendingSettings from './components/settingsPage/SendingSettings'
import {
  generateLeaderboardUrlThunk,
  updateBasicHeaderThunk,
  updateCustomHeaderThunk,
  updateGeneralCompanyInfoThunk,
  updateOnboardingChecklistDataThunk
} from '../store/CompanySlice'

import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import Spinner from './components/Spinner'
import { Button, TextField } from '@mui/material'
import { IoTrash } from 'react-icons/io5'
import { deleteCompanyThunk } from '../store/PartnerSlice'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import PhinModal from './components/PhinModal'
import { checkIsDistributorAdmin } from '../utils/roleManager'

const CUSTOM_HEADER = '#customHeader'

function SettingsPage ({
  id,
  props
}) {
  const history = useHistory()
  const { company, leaderboardUrl, leaderboardLookupId, onboardingChecklist, loaders } = useSelector(state => state.company)
  const { isLoadingCompany } = loaders
  const { authorization } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const focus = (props && props.location && props.location.hash) ? props.location.hash : null
  const [statelyReportingInboxAddress, setReportingInboxAddress] = useState('')
  const [statelySelectedTimezone, setSelectedTimezone] = useState({ text: '(GMT-04:00) Eastern Time (US and Canada)', utcOffset: -4, value: 'America/Detroit' })
  const [basicEmailHeader, setBasicEmailHeader] = useState(false)
  const [customSendingHeader, setCustomSendingHeader] = useState({ name: '', value: '' })
  const shouldFocusSendingSettings = (focus === CUSTOM_HEADER)
  const [deleteCompanyModal, setDeleteCompanyModal] = useState(false)
  const [deleteCompanyId, setDeleteCompanyId] = useState()
  const [deleteCompanyName, setDeleteCompanyName] = useState()

  const isDistributorAdmin = checkIsDistributorAdmin(authorization, company?.distributorId)
  const isCompanyInternal = company?.isInternal

  const isInternalDisabled = isCompanyInternal && !isDistributorAdmin

  useEffect(() => {
    if (company) {
      const { reportRouting, phishTestHeader, customSendingHeader, timezone } = company
      setReportingInboxAddress(reportRouting)
      setBasicEmailHeader(phishTestHeader)
      setCustomSendingHeader(customSendingHeader)
      setSelectedTimezone(timezone)
    }
  }, [company])

  const handleReportingInboxAddress = (text) => {
    setReportingInboxAddress(text)
  }

  const handleTimezoneChange = (tz) => {
    const utcOffset = moment.tz(tz.value).utcOffset() / 60
    const timezone = tzOptions.find((timezone) => (
      timezone.value === tz
    ))
    setSelectedTimezone({ ...timezone, utcOffset })
  }

  async function handleCustomHeaderChange () {
    await dispatch(updateCustomHeaderThunk(id))
    if (!onboardingChecklist.hasEmailSettings) {
      dispatch(updateOnboardingChecklistDataThunk(id, { hasEmailSettings: true }))
    }
  }

  async function handleBasicHeaderChange () {
    await dispatch(updateBasicHeaderThunk(id, !basicEmailHeader))
    if (!onboardingChecklist.hasEmailSettings) {
      dispatch(updateOnboardingChecklistDataThunk(id, { hasEmailSettings: true }))
    }
  }

  const saveGeneralSettings = async (ipIgnoreListEnabled) => {
    dispatch(updateGeneralCompanyInfoThunk(id, { reportingInboxAddress: statelyReportingInboxAddress, timezone: statelySelectedTimezone, ipIgnoreListEnabled }))
  }

  const renderLeaderboardURLGenerator = () => {
    return (
      <MDBRow className='my-2 d-flex'>
        <MDBCol size='3' className='d-flex justify-content-center align-items-center'>
          <Button
            id='generate-leaderboard-url-button'
            aria-label='Generate Leaderboard URL Button'
            color='primary'
            variant='contained'
            onClick={() => dispatch(generateLeaderboardUrlThunk(({ companyId: id, leaderboardLookupId })))}
          >Generate
          </Button>
        </MDBCol>
        <MDBCol size='9'>
          <TextField
            id='leaderboard-url-field'
            aria-label='Leaderboard URL Field'
            fullWidth
            label='Leaderboard URL'
            value={leaderboardUrl || 'Please Generate a Leaderboard URL'}
          />
        </MDBCol>
      </MDBRow>
    )
  }

  const selectCompanyToDelete = (companyId, companyName) => {
    setDeleteCompanyModal(true)
    setDeleteCompanyId(companyId)
    setDeleteCompanyName(companyName)
  }

  const handleDeleteClient = async (companyId) => {
    await dispatch(deleteCompanyThunk({ partnerId: company.partnerId, companyId }))
    setDeleteCompanyModal(false)
    history.push(`/partners/${company.partnerId}`)
  }

  return (
    <MDBContainer>
      <PhinModal
        isOpen={deleteCompanyModal}
        title='Delete Company'
        close={() => { setDeleteCompanyModal(false); setDeleteCompanyId('') }}
        action={() => handleDeleteClient(deleteCompanyId)}
        actionText='Delete'
        actionColor='error'
      >
        <p>Are you sure you want to delete company: "{deleteCompanyName}"? <strong>This can not be undone!</strong></p>
      </PhinModal>

      {(isLoadingCompany || !company) && (
        <Spinner />
      )}

      {(!isLoadingCompany && company) && (
        <>
          <GeneralSettings
            newReportingInboxAddress={statelyReportingInboxAddress}
            handleReportingInboxAddress={handleReportingInboxAddress}
            selectedTimezone={statelySelectedTimezone}
            handleTimezoneChange={handleTimezoneChange}
            leaderboardURLGenerator={renderLeaderboardURLGenerator()}
            saveGeneralSettings={saveGeneralSettings}
            ipIgnoreListEnabled={company.ipIgnoreListEnabled || false}
          />

          <SendingSettings
            phishTestHeader={basicEmailHeader}
            setPhishTestHeader={handleBasicHeaderChange}
            customSendingHeader={customSendingHeader}
            setCustomSendingHeader={handleCustomHeaderChange}
            shouldFocusSendingSettings={shouldFocusSendingSettings}
          />

          {((authorization.roles.distributors && Object.keys(authorization.roles.distributors).length > 0) ||
            !company.freeTrial) && (
              <div className='padding-bottom:2'>
                <div className='delete-company-container padding:2'>
                  <div>
                    <span className='phin-h5'>Delete this company</span>
                    <br />
                    This action is permanent and can only be performed by your administrator.
                  </div>

                  <div className='margin-left:2'>
                    <Button
                      disabled={!authorization.roles.partners[company.partnerId] || isInternalDisabled}
                      id='delete-company-button'
                      aria-label='Delete Company Button'
                      sx={{ color: 'var(--phin-red)' }}
                      endIcon={<IoTrash />} onClick={() => { selectCompanyToDelete(company.id, company.name) }}
                    >
                      Delete Company
                    </Button>
                  </div>
                </div>
              </div>
          )}
        </>
      )}

    </MDBContainer>
  )
}

export default SettingsPage
