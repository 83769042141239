import './css/integrationPhinAPI.css'
import { partnerIntegrationStatuses } from '../frontendConsts.js'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import Spinner from './components/Spinner'
import { createGradientApiIntegrationThunk, getGradientApiIntegrationThunk, disconnectGradientMSPApiThunk, syncCompanyAccountsWithGradientThunk } from '../store/GradientMSPIntegrationSlice'
import { DateTime } from 'luxon'
import PhinModal from './components/PhinModal.js'
import { IoOpenOutline } from 'react-icons/io5'
import { Button } from '@mui/material'

export function GradientIntegrationPage ({ id }) {
  const dispatch = useDispatch()

  const nextFireTime = DateTime.now().plus({ month: 1 }).set({ day: 1 })

  const { partnerIntegrationsMap } = useSelector((state) => state.partnerIntegrations)

  const { gradientMSPApiIntegration, loaders } = useSelector((state) => state.gradientMSPAPI)
  const { isLoadingGradientMSPAPI, isSyncingCompanyAccounts } = loaders

  const [disableButtons, setDisableButtons] = useState(false)
  const [isReusableModalOpen, setIsReusableModalOpen] = useState(false)
  const [partnerApiKey, setPartnerKey] = useState('')

  const gradientLink = (<a className='phin-link-external' href='https://app.usegradient.com/login' target='_blank' rel='noreferrer'><u>Gradient MSP</u> <IoOpenOutline size='1.3em' /></a>)

  useEffect(() => {
    if (partnerIntegrationsMap) {
      dispatch(getGradientApiIntegrationThunk())
    }
  }, [partnerIntegrationsMap])

  const renderStatusBreadCrumbs = (gradientMSPApiIntegration) => {
    const breadCrumbArray = []
    const gradientMSPIntegrationStatus = gradientMSPApiIntegration ? gradientMSPApiIntegration.integrationStatus : partnerIntegrationStatuses.NOT_ENABLED
    for (const [stage] of Object.entries(partnerIntegrationStatuses)) {
      if (partnerIntegrationStatuses[stage] === partnerIntegrationStatuses.ENABLED) {
        breadCrumbArray.push(
          <>
            <div className={(partnerIntegrationStatuses[stage] === gradientMSPIntegrationStatus) ? 'statusCrumb activeCrumb' : 'statusCrumb'}>{partnerIntegrationStatuses[stage]}</div>
          </>
        )
      } else {
        breadCrumbArray.push(
          <>
            <div className={(partnerIntegrationStatuses[stage] === gradientMSPIntegrationStatus) ? 'statusCrumb activeCrumb' : 'statusCrumb'}>{partnerIntegrationStatuses[stage]}</div><hr className='crumbHR' style={{ display: 'inline-block' }} />
          </>
        )
      }
    }
    return breadCrumbArray
  }

  function closeModal () {
    setIsReusableModalOpen(false)
    setDisableButtons(false)
  }

  const connectKey = async (value) => {
    if (disableButtons) {
      return
    }
    setDisableButtons(true)
    const successfulCreate = await dispatch(createGradientApiIntegrationThunk(id, value))
    if (successfulCreate) {
      setPartnerKey('')
    }
    setDisableButtons(false)
  }

  const disconnectIntegration = async (value) => {
    if (disableButtons) {
      return
    }
    setDisableButtons(true)
    setIsReusableModalOpen(true)
  }

  async function syncCompanyAccounts () {
    if (disableButtons) {
      return
    }

    setDisableButtons(true)

    await dispatch(syncCompanyAccountsWithGradientThunk(id))
    setDisableButtons(false)
  }

  async function confirmModalFunction () {
    await dispatch(disconnectGradientMSPApiThunk({ partnerId: id }))

    setDisableButtons(false)
    setPartnerKey('')
    setIsReusableModalOpen(false)
  }

  return (
    <div>
      <PhinModal
        isOpen={isReusableModalOpen}
        close={closeModal}
        closeText='Cancel'
        action={confirmModalFunction}
        actionText='Confirm'
      >
        <>
          <span>This will delete the integration mapping for this partner in Phin, you will still need to go to the GradientMSP portal to finish disconnecting. </span>
          <a className='phin-link-external' href='https://app.usegradient.com/login' target='_blank' rel='noreferrer'><u>Gradient MSP</u> <IoOpenOutline size='1.3em' /></a>
        </>
      </PhinModal>

      <div className='detailsPageHeadingGroup'>
        <img src='/gradientLogoGMedium.png' />
        <div>
          <div className='detailsTitleGroup'>
            <h2 className='phin-page-heading'>Gradient MSP Integration</h2>
          </div>
          {(true) && (
            <div className='crumbContainer'>
              {renderStatusBreadCrumbs(gradientMSPApiIntegration)}
            </div>
          )}
        </div>
      </div>
      <div className='details'>
        <div className='left'>
          {/* start of the consent card */}
          <div className='consentCard'>
            <div>
              <div className='consentTitle'> Gradient MSP Integration Info </div>
              <p className='phin-padding-top-point-5'>On enabling this integration, Phin will automatically send to Gradient a list of all companies currently configured for this partner. If a new company is added in the future and this integration is enabled, Phin will automatically add your companies to Gradient MSP. You will still need to perform the mapping for the new companies in {gradientLink}</p>
            </div>
            <div className='permissions'>
              <p className='consentSubtitle'>Opting into the Gradient MSP Integration will grant Gradient MSP read-only permission to:</p>

              <ul>
                <li>
                  Partner Name
                  <ul>
                    <li>
                      The name of the partner managing these users.
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  Usage Information
                  <ul>
                    <li>
                      The list of companies using Phin managed by this partner.
                    </li>
                    <li>
                      The number of users that were sent phishing emails.
                    </li>
                    <li>
                      How many users were assigned or completed training.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className='d-flex justify-content-center'>
              <a style={{ display: 'none' }} className={!(gradientMSPApiIntegration && gradientMSPApiIntegration.auth0ClientId) ? 'consentBtn noSelect' : 'consentBtn noSelect disabled-color'}>Acknowledge</a>
            </div>

          </div>
          {/* end of the consent card */}
        </div>
        {(!gradientMSPApiIntegration && isLoadingGradientMSPAPI)
          ? (
            <div style={{ margin: 'auto' }}><Spinner message='Connecting to Gradient MSP' /></div>
            )
          : (
            <div className='right'>
              <div className='consentCard'>
                <div id='api-credentials'>
                  <div className='card-header-group'>
                    <div className='consentTitle'>Manage Gradient MSP Integration</div>
                    <p className='phin-padding-top-point-5'>On the first day of each month, Phin will update connected Company Accounts' Unit Count to reflect the previous month's usage data.</p>
                    {(gradientMSPApiIntegration && gradientMSPApiIntegration.integrationStatus === partnerIntegrationStatuses.ENABLED) && (
                      <div>
                        <p className='phin-padding-top-bottom-2 text-align-center'><b>Next Fire Time: {nextFireTime.toLocaleString(DateTime.DATE_SHORT)}</b></p>
                        <div className='phin-padding-top-point-5'>
                          <div className='warning-banner'>
                            <p style={{ marginBottom: '0' }}>
                              Phin will automatically add your companies to Gradient MSP.
                              You will still need to perform the mapping for the new
                              companies in <a className='phin-link-external' href='https://app.usegradient.com/login' target='_blank' rel='noreferrer'><u>Gradient MSP</u> <IoOpenOutline size='1.3em' /></a>
                            </p>
                          </div>
                        </div>
                      </div>)}
                  </div>
                  <div id='gradient-key-group'>
                    <div id='gradient-connection-group'>
                      {(!gradientMSPApiIntegration) && (
                        <>
                          <input type='password' onChange={(e) => setPartnerKey(e.target.value)} value={partnerApiKey} className='phin-text-input' placeholder='Gradient Partner API Key Here' />
                          <button style={{ marginRight: '0' }} onClick={() => connectKey(partnerApiKey)} className='api-action-btn noSelect'>{
                          isLoadingGradientMSPAPI
                            ? <div
                                className='spinner-border'
                                style={{
                                  color: '#052460',
                                  width: '1.5rem',
                                  height: '1.5rem'
                                }}
                                role='status'
                              />
                            : 'Connect'
}
                          </button>
                        </>

                      )}
                    </div>
                  </div>
                  <div className='action-btn-group'>
                    {(gradientMSPApiIntegration && gradientMSPApiIntegration.integrationStatus === partnerIntegrationStatuses.ENABLED) && (
                      <button className='api-action-btn noSelect' id='syncCompanyAccounts' onClick={() => syncCompanyAccounts()}>
                        {isSyncingCompanyAccounts
                          ? <div
                              className='spinner-border'
                              style={{
                                color: '#FFFFFF',
                                width: '1.5rem',
                                height: '1.5rem',
                                margin: '0 1em'
                              }}
                              role='status'
                            />
                          : 'Sync Company Accounts'}
                      </button>)}

                    {(gradientMSPApiIntegration && gradientMSPApiIntegration.integrationStatus === partnerIntegrationStatuses.ENABLED) && (
                      <button className='api-action-btn noSelect' id='disconnect' onClick={() => { disconnectIntegration(partnerApiKey) }}>
                        {isLoadingGradientMSPAPI
                          ? <div
                              className='spinner-border'
                              style={{
                                color: '#FFFFFF',
                                width: '1.5rem',
                                height: '1.5rem',
                                margin: '0 1em'
                              }}
                              role='status'
                            />
                          : 'Disconnect'}
                      </button>
                    )}
                    <Button
                      id='documentation'
                      aria-label='View Documentation'
                      endIcon={<IoOpenOutline />}
                      href='https://www.phinsec.io/knowledge/gradient-msp-integration'
                      rel='noreferrer'
                      target='_blank'
                      size='large'
                      variant='outlined'
                      disableElevation
                      color='primary'
                    >Documentation
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            )}
      </div>
    </div>
  )
}
