import { Button, Card, Divider, Grid, Skeleton } from '@mui/material'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { BorderLinearProgress } from '../../shared/components/ProgressBar'
import { PAX8_ACCOUNT_STATUSES } from '../../frontendConsts'

export function Pax8BillingWidget ({ partnerId }) {
  const history = useHistory()
  const { partner, loaders } = useSelector((state) => state.partner)
  const { isLoadingPartner } = loaders
  const { pax8PricingPlans, isLoadingPax8PricingPlans } = useSelector((state) => state.pax8Partner)
  const [totalUnits, setTotalUnits] = useState(0)
  const [totalCost, setTotalCost] = useState(0)
  const [pricingPlan, setPricingPlan] = useState(null)
  const [pendingPricingPlan, setPendingPricingPlan] = useState(null)
  const [minimumBillablePercentage, setMinimumBillablePercentage] = useState(0)

  const today = DateTime.now()
  const currentMonth = today.monthLong
  let nextBillingDate
  if (partner?.pax8AccountStatus === PAX8_ACCOUNT_STATUSES.TRIALING) {
    // pax8TrialEndTimestamp
    const pax8TrialEndTimestamp = DateTime.fromSeconds(partner.pax8TrialEndTimestamp._seconds)
    nextBillingDate = pax8TrialEndTimestamp.plus({ months: 1 }).startOf('month').toLocaleString(DateTime.DATE_MED)
  } else {
    nextBillingDate = today.plus({ months: 1 }).startOf('month').toLocaleString(DateTime.DATE_MED)
  }

  useEffect(() => {
    if (partner && partner.pax8PlanId && pax8PricingPlans) {
      const localPax8PricingPlans = [...pax8PricingPlans]
      const currentPricingPlan = localPax8PricingPlans.find(plan => plan.id === partner.pax8PlanId)
      if (partner.pendingPax8PlanId) {
        const foundPendingPricingPlan = localPax8PricingPlans.find(plan => plan.id === partner.pendingPax8PlanId)
        setPendingPricingPlan(foundPendingPricingPlan)
      }

      const newTotalUnits = currentPricingPlan.minimumBillableUsers > (partner.currentBillableUsers || 0) ? currentPricingPlan.minimumBillableUsers : partner.currentBillableUsers
      setTotalCost(newTotalUnits * currentPricingPlan.perUserCost)
      setTotalUnits(newTotalUnits)
      setPricingPlan(currentPricingPlan)

      let tempMinimumBillablePercentage = 0
      if (partner.currentBillableUsers) {
        if (partner.currentBillableUsers < currentPricingPlan.minimumBillableUsers) {
          tempMinimumBillablePercentage = (partner.currentBillableUsers / currentPricingPlan.minimumBillableUsers) * 100
        } else {
          tempMinimumBillablePercentage = 100
        }
      }

      setMinimumBillablePercentage(tempMinimumBillablePercentage)
    } else if (partner && !partner.pax8PlanId) {
      history.push(`/partners/${partnerId}/pax8-pricing`)
    }
  }, [partner, pax8PricingPlans])

  return (
    <>
      {((isLoadingPartner || !partner || isLoadingPax8PricingPlans || !pax8PricingPlans || !pricingPlan)) && (
        <Skeleton sx={{ bgcolor: '#c4c4c4', minWidth: 200 }} variant='rectangle' height={242} />
      )}

      {(!isLoadingPartner && partner && !isLoadingPax8PricingPlans && pax8PricingPlans && pricingPlan) && (

        <Card
          variant='outlined' sx={{
            minWidth: 200,
            minHeight: 242,
            padding: 'var(--phin-s2)',
            borderRadius: 'var(--phin-s-2)',
            border: '2px solid var(--phin-light-gray)'
          }}
        >
          <div className='phin-h4'>Plan Summary</div>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='flex-end'
            sx={{ marginBottom: 'var(--phin-s1)' }}
          >
            <Grid item xs={12} sm={6} sx={{ paddingBottom: 'var(--phin-s-1)' }}>
              <div className='phin-h6'>
                Current Plan: <b>{pricingPlan.name}</b>
                <div className='phin-body-text-small'>Includes {pricingPlan.minimumBillableUsers} + ${pricingPlan.perUserCost} / additional user</div>
              </div>
            </Grid>

            {pendingPricingPlan && (partner.pax8PlanId !== partner.pendingPax8PlanId) && (

              <Grid item xs={12} sm={6} sx={{ backgroundColor: 'var(--gray-25)', borderRadius: '12px', padding: 'var(--phin-s-1) var(--phin-s-1)' }}>
                <div className='phin-body-text-small'>*After next bill</div>
                <div className='phin-h6'>
                  New Plan: <b>{pendingPricingPlan.name}</b>
                  <div className='phin-body-text-small'>Includes {pendingPricingPlan.minimumBillableUsers} + ${pendingPricingPlan.perUserCost} / additional user</div>
                </div>
              </Grid>
            )}
          </Grid>

          <Grid sx={{ marginBottom: 'var(--phin-s-2)' }}>
            <div className='phin-h4'>{currentMonth} Preview</div>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Grid item xs={10}>
                <BorderLinearProgress variant='determinate' value={minimumBillablePercentage} />
              </Grid>
              <Grid item xs={1}>
                <div className='phin-body-text-small'>{partner.currentBillableUsers || 0}</div>
                <div className='phin-body-text-small'>Billable Users</div>
              </Grid>
            </Grid>
          </Grid>

          <div style={{
            maxWidth: '600px'
          }}
          >

            <Grid container spacing={2} sx={{ marginBottom: 'var(--phin-s2)' }} justifyContent='space-between'>
              <Grid item xs={4}>
                <Grid container spacing={1} direction='column'>
                  <Grid item xs={6}>
                    <div className='phin-h6'>Total Users</div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className='phin-h5'>{totalUnits}</div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%'
                }}
                > x
                </div>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={1} direction='column'>
                  <Grid item xs={6}>
                    <div className='phin-h6'>Price per User</div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className='phin-h5'>${pricingPlan.perUserCost}</div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Divider sx={{ marginBottom: 'var(--phin-s0)', marginTop: 'var(--phin-s-1)', borderBottomWidth: '2px', borderColor: 'var(--phin-gray)' }} />

          </div>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container spacing={1} direction='column'>
                <Grid item xs={6}>
                  <div className='phin-h6'>Estimated Bill</div>
                </Grid>
                <Grid item xs={6}>
                  {(partner.freeTrial === true)
                    ? (<div className='phin-h5'><s>${totalCost.toFixed(2)}</s><div className='phin-h4'>$0</div></div>)
                    : (<div className='phin-h4'>${totalCost.toFixed(2)}</div>)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container spacing={2} justifyContent='space-between' alignItems='flex-end'
          >
            <Grid item className='phin-h6'>
              Next Bill: {nextBillingDate}
            </Grid>
            <Grid item>
              <Button
                id='select-pax8-plan-button'
                aria-label='Select Pax8 Plan Button'
                size='large'
                color='primary'
                variant='contained'
                disableElevation
                onClick={() => history.push(`/partners/${partnerId}/pax8-pricing`)}
              > Manage Plan
              </Button>
            </Grid>
          </Grid>
        </Card>
      )}
    </>
  )
}
