import React, { useState, useEffect } from 'react'
import { Pie } from 'react-chartjs-2'
import { Chart, ArcElement, registerables } from 'chart.js'
import {
  MDBRow, MDBCol, MDBCard, MDBIcon, MDBCardBody
} from 'mdb-react-ui-kit'
import { FormControl, Grid, InputLabel, MenuItem, Select, Slide, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Spinner from '../../Spinner'
import { createFileDownload } from '../../../../utils/fileUtils'
import { useSelector, useDispatch } from 'react-redux'
import { getCourseStatsThunk, resetTrainingForUserThunk } from '../../../../store/AnalyticsSlice'
import SlideUpDataExportButtons from '../../analyticsPage/SlideUpDataExportButtons.js'
import NotificationUtilities from '../../notifications/notificationUtils'
import { apiRequestUtils } from '../../../../utils/apiRequestUtils'
import UsersViewTrainingAnalytics from '../UsersViewTrainingAnalytics'
import { trainingAnalyticsSelectionTypes } from '../../../../frontendConsts.js'
import { UserDataTable } from './UserDataTable'
import { useHistory } from 'react-router-dom'
import CompleteTrainingModal from '../../training/CompleteTrainingModal'
Chart.register(...registerables)

Chart.register(ArcElement)

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function HUDElement ({ title, icon, value, tooltip }) {
  return (
    <Tooltip
      placement='top'
      className='mr-2 ml-2'
      material
      domElement
      title={tooltip}
    >
      <a>
        <MDBCol>
          <p className='dark-grey-text'><MDBIcon icon={icon} /> {title}</p>
          <h4 className='font-weight-bold dark-grey-text'>{value}</h4>
        </MDBCol>
      </a>
    </Tooltip>
  )
}

function PieChart ({ course }) {
  const chartBaseStyles = {
    title: {
      fontSize: 20,
      fontFamily: 'Roboto',
      fontColor: '#212529'
    },
    legend: {
      fontColor: '#212529',
      fontFamily: 'Roboto',
      fontSize: 13
    },
    axisColor: 'rgba(0, 0, 0, 0.3)',
    colors: ['#00C851', '#ff4444']
  }

  const chartData = {
    labels: [
      'Completed',
      'Not Completed'
    ],
    datasets: [{
      data: [
        course.totalCompleted,
        course.totalEnrolled - course.totalCompleted
      ],
      backgroundColor: chartBaseStyles.colors
    }]
  }

  const chartOptions = {
    response: true,
    tooltips: {
      enabled: true
    },
    title: {
      display: true,
      fontSize: chartBaseStyles.title.fontSize,
      text: 'Completion Rate',
      fontColor: chartBaseStyles.title.fontColor
    },
    legend: {
      labels: {
        fontColor: chartBaseStyles.legend.fontColor,
        fontFamily: chartBaseStyles.legend.fontFamily,
        fontSize: chartBaseStyles.legend.fontSize
      }
    }
  }

  return (
    <Pie data={chartData} options={chartOptions} height={250} />
  )
}

function CourseSelector ({ activeCampaigns, courseData, selectedCourse, switchCourse }) {
  const options = []
  const courseMap = {

  }

  for (const courseObj of courseData) {
    const course = courseObj.course
    courseMap[course.id] = courseObj
    options.push(
      <MenuItem key={course.id} value={course.id}>{course.name}</MenuItem>
    )
  }

  const currentCourseCampaign = activeCampaigns.find(campaign => campaign.id === selectedCourse.campaignId)
  let currentCourseCampaignName = 'Inactive Campaign'
  if (currentCourseCampaign && currentCourseCampaign.name) {
    currentCourseCampaignName = currentCourseCampaign.name
  } else if (currentCourseCampaign && !currentCourseCampaign.name) {
    currentCourseCampaignName = 'N/A'
  }

  return (
    <Box mb='1rem'>
      <Typography>{`Campaign: ${currentCourseCampaignName}`}</Typography>

      <FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id='analytics-switch-campaign-toggle-label'>Course</InputLabel>
        <Select
          labelId='analytics-switch-campaign-toggle-label'
          id='analytics-campaign-toggle'
          value={selectedCourse.id}
          onChange={(e) => switchCourse(courseMap[e.target.value])}
          label='Campaign'
        >
          {options}
        </Select>
      </FormControl>
    </Box>
  )
}

function TrainingAnalyticsPage ({ companyId }) {
  const history = useHistory()
  let defaultView
  // Open user table view based on router state. Used for nav from UserAnalyticsPage
  if (history.location.state?.userTableView) {
    defaultView = trainingAnalyticsSelectionTypes.USER_VIEW
  } else {
    defaultView = trainingAnalyticsSelectionTypes.COURSE_VIEW
  }
  const [analyticsSelectionType, setAnalyticsSelectionType] = useState(defaultView)

  const [exporting, setExportingFlag] = useState(false)
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [selectedCourseUsers, setSelectedCourseUsers] = useState(null)

  const [user, setUser] = useState([])

  const [isCompleteTrainingModalOpen, setIsCompleteTrainingModalOpen] = useState(false)

  const { courseStats, loaders } = useSelector((state) => state.analytics)
  const { activeCampaigns } = useSelector((state) => state.campaigns)
  const { isLoadingCourseStats } = loaders
  const dispatch = useDispatch()

  const toggleSelectionChange = (event) => {
    const selectionType = parseInt(event.target.value)
    setAnalyticsSelectionType(selectionType)
  }

  useEffect(() => {
    // Make sure we reset the scroll upon load
    dispatch(getCourseStatsThunk(companyId))
  }, [])

  useEffect(() => {
    if (courseStats && courseStats.length > 0) {
      if (!selectedCourse) {
        const { course, users } = courseStats[0]
        setSelectedCourse(course)
        setSelectedCourseUsers(users)
      } else {
        const selectedCourseStat = courseStats.find((courseStat) => courseStat.course.id === selectedCourse.id)
        if (selectedCourseStat) {
          setSelectedCourse(selectedCourseStat.course)
          setSelectedCourseUsers(selectedCourseStat.users)
        } else {
          const { course, users } = courseStats[0]
          setSelectedCourse(course)
          setSelectedCourseUsers(users)
        }
      }
    }
  }, [courseStats])

  function switchCourse (courseObj) {
    const { course, users } = courseObj
    setSelectedCourse(course)
    setSelectedCourseUsers(users)
  }

  async function remindUser (user) {
    try {
      const res = await apiRequestUtils.post(`/api/companies/${companyId}/training/remind-user`, {
        activeTrainingCourseId: selectedCourse.id,
        activeTrainingCourseName: selectedCourse.name,
        userId: user.id
      })
      await res.json()
      if (res.status === 200) {
        NotificationUtilities.sendSuccessMessage('Reminder Sent!')
      } else {
        NotificationUtilities.sendErrorMessage('Error Sending Reminder, please contact Phin if problem persists!')
      }
    } catch (error) {
      NotificationUtilities.sendErrorMessage('Error Sending Reminder, please contact Phin if problem persists!')
    }
  }

  async function resetTrainingForUser (user) {
    dispatch(resetTrainingForUserThunk({ user, courseId: selectedCourse.id, companyId }))
  }

  function toggleCompleteTrainingModalOpen ({ user }) {
    if (isCompleteTrainingModalOpen) {
      setUser()
    } else {
      setUser(user)
    }
    setIsCompleteTrainingModalOpen(!isCompleteTrainingModalOpen)
  }

  async function downloadOrgData () {
    setExportingFlag(true)
    NotificationUtilities.sendInfoMessage('Downloading your organization\'s data.')
    try {
      const res = await apiRequestUtils.get(`/api/companies/${companyId}/analytics/organization/training-data`)
      if (res.status !== 200) {
        NotificationUtilities.sendErrorMessage('There was an error downloading your training data, please contact Phin if problem persists!')
        setExportingFlag(false)
      } else {
        const message = await res.json()
        const todaysDate = new Date().toLocaleDateString('en-US')
        createFileDownload(message, `Company-Training-Data-${todaysDate}`)
        setExportingFlag(false)
        NotificationUtilities.sendSuccessMessage('Course data downloaded!')
      }
    } catch (error) {
      console.log(error)
      NotificationUtilities.sendErrorMessage('There was an error downloading your training data, please contact Phin if problem persists!')
      setExportingFlag(false)
    }
  }

  async function downloadTrainingCampaignData (selectedCourse) {
    setExportingFlag(true)

    NotificationUtilities.sendInfoMessage(`Downloading data for training course ${selectedCourse.name}`)
    try {
      const res = await apiRequestUtils.get(`/api/companies/${companyId}/analytics/training-course/${selectedCourse.id}`)

      if (res.status !== 200) {
        NotificationUtilities.sendErrorMessage('There was an error downloading your training course data, please contact Phin if problem persists!')
        setExportingFlag(false)
      } else {
        const message = await res.json()
        const todaysDate = new Date().toLocaleDateString('en-US')
        createFileDownload(message, `${selectedCourse.name}-${todaysDate}`)
        setExportingFlag(false)
        NotificationUtilities.sendSuccessMessage('Course data downloaded!')
      }
    } catch (error) {
      console.log(error)
      NotificationUtilities.sendErrorMessage('There was an error downloading your training course data, please contact Phin if problem persists!')
      setExportingFlag(false)
    }
  }

  return (
    <div className='d-flex flex-column'>
      <div className='mb-4' style={{ alignSelf: 'center' }}>
        <h1>Training Analytics</h1>
      </div>

      {isCompleteTrainingModalOpen && (

        <CompleteTrainingModal
          companyId={companyId}
          isOpen={isCompleteTrainingModalOpen}
          onClose={() => toggleCompleteTrainingModalOpen({})}
          confirmAction={() => toggleCompleteTrainingModalOpen({})}
          user={user}
          selectedCourse={selectedCourse}
          isSingleUserView={false}
        />
      )}

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', pb: 1 }}>
          <ToggleButtonGroup value={analyticsSelectionType} color='primary' exclusive onChange={toggleSelectionChange}>
            <ToggleButton id='courseView' value={trainingAnalyticsSelectionTypes.COURSE_VIEW}>Course View</ToggleButton>
            <ToggleButton id='userView' value={trainingAnalyticsSelectionTypes.USER_VIEW}>User View</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <TabPanel value={analyticsSelectionType} index={0}>
          {isLoadingCourseStats && (
            <Spinner message='Making everything look nice' />
          )}

          <Slide direction='up' in={courseStats && (courseStats.length === 0) && !isLoadingCourseStats} mountOnEnter timeout={750}>
            <Grid>
              <MDBRow className='d-flex mb-4 justify-content-center'>
                <MDBCol size='12'>
                  <MDBCard className='hud-stats'>
                    <MDBRow className='text-center d-flex justify-content-center m-4'>
                      <h3>
                        No course data found. Check back here once you have started a training course!
                      </h3>
                    </MDBRow>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </Grid>
          </Slide>

          {courseStats && (courseStats.length > 0) && !isLoadingCourseStats && selectedCourseUsers && selectedCourse && (
            <>
              <SlideUpDataExportButtons
                exporting={exporting}
                downloadCampaignData={() => downloadTrainingCampaignData(selectedCourse)}
                downloadOrgData={() => downloadOrgData()}
              />

              <Slide direction='up' in mountOnEnter timeout={750}>
                <Grid>

                  <div className='margin-right:4'>
                    <MDBRow>
                      <MDBCol className='d-flex justify-content-end p-0'>
                        <CourseSelector courseData={courseStats} switchCourse={switchCourse} selectedCourse={selectedCourse} activeCampaigns={activeCampaigns} />
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCard className='hud-stats w-100 mb-4'>
                        <MDBRow className='text-center d-flex justify-content-center my-4'>
                          <HUDElement title='Total Users' icon='users' tooltip='The number of users enrolled in this course, regardless of status: active, slept, deleted.' value={selectedCourse !== null ? selectedCourse.totalEnrolled : 0} />
                          <HUDElement title='All Finished Users' icon='medal' tooltip='The number of all users who have completed this course, regardless of status: active, slept, deleted.' value={selectedCourse !== null ? selectedCourse.totalCompleted : 0} />
                        </MDBRow>
                        <MDBRow className='text-center d-flex justify-content-center my-4'>
                          <HUDElement title='Active Users' icon='sun' tooltip='The number of active users enrolled in this course' value={selectedCourse !== null ? selectedCourse.totalActiveUsers : 0} />
                          <HUDElement title='Slept Users' icon='bed' tooltip='The number of slept users enrolled in this course' value={selectedCourse !== null ? selectedCourse.totalSleptUsers : 0} />
                          <HUDElement title='Deleted Users' icon='trash' tooltip='The number of deleted users enrolled in this course' value={selectedCourse !== null ? selectedCourse.totalDeletedUsers : 0} />
                        </MDBRow>
                      </MDBCard>
                    </MDBRow>

                    <MDBRow className='d-flex mb-4 justify-content-center'>
                      <MDBCol size='6'>
                        <MDBCard className='d-flex justify-content-center'>
                          <MDBCardBody className='p-1'>
                            <PieChart course={selectedCourse} />
                          </MDBCardBody>
                        </MDBCard>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow className='d-flex mb-4 justify-content-center'>
                      <div style={{ height: '65vh', width: '100%', marginBottom: '3rem' }} className='p-m-t-1'>
                        <UserDataTable
                          userData={selectedCourseUsers}
                          resetTrainingForUser={resetTrainingForUser}
                          remindUser={remindUser}
                          selectedCourse={selectedCourse}
                          isUserCourseTable
                          toggleCompleteTrainingModalOpen={toggleCompleteTrainingModalOpen}
                        />
                      </div>
                    </MDBRow>
                  </div>
                </Grid>
              </Slide>
            </>
          )}
        </TabPanel>
        <TabPanel value={analyticsSelectionType} index={1}>
          <UsersViewTrainingAnalytics
            companyId={companyId}
          />
        </TabPanel>
      </Box>

    </div>
  )
}

export default TrainingAnalyticsPage
