export function PhinCard ({ children, title, description, id = 'phin-card', showHeader = true, height = 'auto', overflowY = 'auto' }) {
  return (
    <div
      id={id}
      className='phin-card-container'
      style={{ height, overflowY }}
    >
      {showHeader && (
        <>
          <div className='phin-h4 padding-bottom:-2'>{title}</div>
          {description &&
      (<p>{description}</p>)}
        </>
      )}
      {children}
    </div>
  )
}
