import React, { useState } from 'react'
import ViewerModal from '../../components/ViewerModal'
import { IoAdd, IoCheckmark, IoEye, IoMail, IoPhonePortrait } from 'react-icons/io5'
import CloseIcon from '@mui/icons-material/Close'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import { MDBCardBody, MDBRow } from 'mdb-react-ui-kit'
import PhinBadge from '../PhinBadge'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Button, Grid, IconButton, Tooltip } from '@mui/material'
import { PhinCard } from '../PhinCard'

function CampaignTemplateEditor ({
  editCallback, allTemplates, selectedTemplates, parameters
}) {
  const [tablePageSize, setTablePageSize] = useState(10)
  const [viewerModal, setViewerModal] = useState(false)
  const [viewerTemplate, setViewerTemplate] = useState({})

  function handleToggleViewerModal (template = {}) {
    setViewerModal(!viewerModal)
    setViewerTemplate(template)
  }

  function previewTemplate (template) {
    setViewerModal(!viewerModal)
    setViewerTemplate(template)
  }

  function handleTemplateSelectAll () {
    editCallback(allTemplates.map(template => template.id))
  }

  function handleTemplateRemoveAll () {
    editCallback([])
  }

  function AddTemplateButton ({ template }) {
    return (
      <Tooltip
        title='Select' placement='top'
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -14]
                }
              }
            ]
          }
        }}
      >
        <IconButton
          id={`add-${template.name}-template-button`}
          aria-label={`Add ${template.name} Template Button`}
          color='primary'
          size='large'
          onClick={() => { editCallback([...selectedTemplates, template.id]) }}
        >
          <IoAdd />
        </IconButton>
      </Tooltip>
    )
  }

  function RemoveTemplateButton ({ template }) {
    return (
      <Tooltip
        title='Unselect' placement='top'
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -14]
                }
              }
            ]
          }
        }}
      >
        <IconButton
          id={`remove-${template.name}-template-button`}
          aria-label={`Remove ${template.name} Template Button`}
          color='success'
          size='large'
          onClick={() => { editCallback([...selectedTemplates.filter(selectedTemplateID => selectedTemplateID !== template.id)]) }}
        >
          <IoCheckmark />
        </IconButton>
      </Tooltip>
    )
  }

  const templateSelectionColumns = [
    {
      headerName: 'Name',
      field: 'name',
      flex: 2
    },
    {
      headerName: 'Channel',
      field: 'channel',
      flex: 1,
      renderCell: (params) => {
        return (
          <PhinBadge
            displayString={params.row.channel === 'email' ? 'Email' : 'SMS'}
            icon={params.row.channel === 'email' ? <IoMail /> : <IoPhonePortrait />}
            color='rgb(140, 140, 140)'
          />
        )
      }
    },
    {
      headerName: 'Tags',
      field: 'tags',
      flex: 1
    },
    {
      headerName: 'Actions',
      field: 'action',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Grid
            container
            direction='row'
            justifyContent='space-evenly'
            alignItems='center'
          >

            {selectedTemplates.includes(params.row.id)
              ? <RemoveTemplateButton template={params.row} />
              : <AddTemplateButton template={params.row} />}

            <Tooltip
              title='View Template' placement='top'
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -14]
                      }
                    }
                  ]
                }
              }}
            >
              <IconButton
                id={`view-${params.row.name}-category-button`}
                aria-label={`View ${params.row.name} Category Button`}
                color='primary'
                onClick={() => { previewTemplate(params.row) }}
              >
                <IoEye />
              </IconButton>
            </Tooltip>
          </Grid>
        )
      }
    }
  ]

  return (
    <>
      <ViewerModal title='Templates' isOpen={viewerModal} toggleViewerModal={handleToggleViewerModal} template={viewerTemplate} />
      <PhinCard
        title='Select Templates'
        description='Here you can select the templates sent during the campaign. You can select from any of the Phin Security default templates or any of your own.'
      >
        <MDBCardBody>
          <MDBRow className='mt-2 d-flex justify-content-end padding-right:1 padding-bottom:0'>
            <span className='margin-right:-1'>
              <Button
                onClick={handleTemplateRemoveAll} color='error'
                startIcon={<CloseIcon />}
              >Clear All
              </Button>
            </span>
            <Button
              variant='contained'
              onClick={handleTemplateSelectAll}
              color='primary' startIcon={<DoneAllIcon />}
            >Select All
            </Button>
          </MDBRow>

          <div id='template-selection-grid' style={{ paddingLeft: '10px', paddingRight: '10px', width: '100%', height: '700px' }}>
            <DataGrid
              style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
              className='DataGrid'
              rows={allTemplates}
              columns={templateSelectionColumns}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 }
                }
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableSelectionOnClick
              pageSize={tablePageSize}
              rowsPerPageOptions={[10, 20, 50]}
              onPageSizeChange={(newSize) => setTablePageSize(newSize)}
              pagination
            />
          </div>

        </MDBCardBody>
      </PhinCard>
    </>
  )
}

export default CampaignTemplateEditor
