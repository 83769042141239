import { FileUploadInput } from './FileUploadInput'

export function FileUploadInputVideo ({ file, setFile, acceptedFileTypes }) {
  return (
    <FileUploadInput file={file} setFile={setFile} acceptedFileTypes={acceptedFileTypes}>
      <p class='phin-h6'>Drag and drop your video file here</p>
      <p>MP4 only / 32mb limit</p>
    </FileUploadInput>
  )
}
