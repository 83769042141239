import { useEffect, useState } from 'react'
import { useGetCwCompaniesQuery, useSetCwMappingMutation } from '../../../../store/ConnectWiseApiSlice'
import { debounce } from 'lodash'
import { Autocomplete, TextField } from '@mui/material'

export function ConnectWiseCompanyDropdown ({ cwCompany, partnerId, companyId, isDisabled = false }) {
  const [nameFilter, setNameFilter] = useState('')
  const [cwCompanyState, setCwCompanyState] = useState(cwCompany)

  const { data } = useGetCwCompaniesQuery({ partnerId, nameFilter })
  const [triggerUpdateFunction, results] = useSetCwMappingMutation()
  const { isLoading } = results

  const debouncedGetCompanies = debounce((nameFilter) => {
    setNameFilter(nameFilter)
  }, 100)

  useEffect(() => {
    if (!isLoading) {
      setCwCompanyState(cwCompany)
    }
  }, [cwCompany, isLoading])

  // when a user selects the company dropdown, we want their selected company to be an option
  // instead of being empty
  let changeable = data?.data ? [...data?.data] : []

  if (data?.hasMorePages) {
    changeable = [{ id: null, label: 'Search for more companies...', disabled: true }, ...changeable]
  }

  return (
    <Autocomplete
      sx={{
        width: '100%',
        '.MuiOutlinedInput-notchedOutline':
      {
        borderStyle: 'none'
      }
      }}
      disabled={isDisabled}
      options={changeable}
      noOptionsText='No Companies found'
      getOptionDisabled={(option) => option.disabled}
      getOptionKey={(option) => option.id}
      renderInput={(params) => <TextField {...params} />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onInputChange={async (event, newValue, type) => {
        debouncedGetCompanies(newValue)

        if (type === 'clear') {
          setCwCompanyState(null)
          // Delete the Company mapping
          const result = await triggerUpdateFunction({
            partnerId,
            companyId,
            mappingUpdate: {
              cwCompanyId: null,
              cwCompanyName: null,
              cwAgreementId: null,
              cwAgreementName: null,
              cwAdditionId: null,
              cwAdditionName: null,
              lastSyncSucceeded: null,
              lastSyncTime: null,
              lastSyncUserCount: null
            }
          })

          if (result.error) {
            setCwCompanyState(cwCompany)
          }
        }
      }}
      onChange={async (event, newValue) => {
        if (newValue) {
          setCwCompanyState(newValue)
          const result = await triggerUpdateFunction({
            partnerId,
            companyId,
            mappingUpdate: {
              cwCompanyId: newValue.id,
              cwCompanyName: newValue.label,
              cwAgreementId: null,
              cwAgreementName: null,
              cwAdditionId: null,
              cwAdditionName: null,
              lastSyncSucceeded: null,
              lastSyncTime: null,
              lastSyncUserCount: null
            }
          })

          if (result.error) {
            setCwCompanyState(cwCompany)
          }
        }
      }}
      value={cwCompanyState}
      onKeyDown={(event) => {
        if ([' ', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
          event.stopPropagation()
        }
      }}
    />
  )
}
