import TrainingModulePreview from '../../pages/components/training/TrainingModulePreview'
import CustomTrainingVideoPreview from '../../pages/components/training/CustomTrainingVideoPreview'
import { Card } from '@mui/material'
import PhinModal from '../../pages/components/PhinModal'

export const CoursePreviewModal = ({ courseDefinition, companyId, showPreviewModal, previewModalHeader, togglePreviewModal }) => {
  return (
    <PhinModal
      isOpen={showPreviewModal}
      title={previewModalHeader}
      size='large'
      close={() => togglePreviewModal(null, false)}
      noButtons
    >
      <Card>
        {courseDefinition && courseDefinition.type === 'single' && (
          <CustomTrainingVideoPreview
            courseDefinition={courseDefinition}
            companyId={companyId}
          />
        )}

        {courseDefinition && courseDefinition.type !== 'single' && (
          <TrainingModulePreview
            courseDefinition={courseDefinition}
            companyId={companyId}
          />
        )}
      </Card>
    </PhinModal>
  )
}
