import { useEffect, useState } from 'react'
import { useLazyGetCwAgreementsQuery, useSetCwMappingMutation } from '../../../../store/ConnectWiseApiSlice'
import { debounce } from 'lodash'
import { Autocomplete, TextField } from '@mui/material'

export function ConnectWiseAgreementDropdown ({ cwCompanyId, cwAgreement, partnerId, companyId, isDisabled = false }) {
  const [cwAgreementState, setCwAgreementState] = useState(cwAgreement)
  const [getTriggerFunction, getResult] = useLazyGetCwAgreementsQuery()
  const { data, isLoading } = getResult

  const debouncedGetAgreements = debounce((nameFilter) => {
    nameFilter = nameFilter || ''
    getTriggerFunction({ partnerId, cwCompanyId, nameFilter })
  }, 100)

  const [updateTriggerFunction] = useSetCwMappingMutation()

  useEffect(() => {
    if (cwCompanyId) {
      getTriggerFunction({ partnerId, cwCompanyId, nameFilter: '' })
    }
  }, [cwCompanyId])

  useEffect(() => {
    if (!isLoading) {
      setCwAgreementState(cwAgreement)
    }
  }, [cwAgreement, isLoading])

  let changeable = data?.data ? [...data?.data] : []
  if (data?.hasMorePages) {
    changeable = [{ id: null, label: 'Search for more Agreements...', disabled: true }, ...changeable]
  }

  return (
    <Autocomplete
      sx={{
        width: '100%',
        '.MuiOutlinedInput-notchedOutline':
      {
        borderStyle: 'none'
      }
      }}
      disabled={isDisabled}
      options={changeable}
      noOptionsText={cwCompanyId ? 'No Agreements found' : 'Select a ConnectWise Company first'}
      getOptionDisabled={(option) => option.disabled}
      getOptionKey={(option) => option.id}
      renderInput={(params) => <TextField {...params} />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onInputChange={async (event, newValue, type) => {
        debouncedGetAgreements(newValue)
        if (type === 'clear') {
          setCwAgreementState(null)
          const result = await updateTriggerFunction({
            partnerId,
            companyId,
            mappingUpdate: {
              cwAgreementId: null,
              cwAgreementName: null,
              cwAdditionId: null,
              cwAdditionName: null,
              lastSyncSucceeded: null,
              lastSyncTime: null,
              lastSyncUserCount: null
            }
          })

          if (result.error) {
            setCwAgreementState(cwAgreement)
          }
        }
      }}
      onChange={async (event, newValue) => {
        if (newValue) {
          setCwAgreementState(newValue)
          const result = await updateTriggerFunction({ partnerId, companyId, mappingUpdate: { cwAgreementId: newValue.id, cwAgreementName: newValue.label, cwAdditionId: null, cwAdditionName: null } })

          if (result.error) {
            setCwAgreementState(cwAgreement)
          }
        }
      }}
      value={cwAgreementState}
      onKeyDown={(event) => {
        if ([' ', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
          event.stopPropagation()
        }
      }}
    />
  )
}
