import { createSlice } from '@reduxjs/toolkit'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'
import { createGenericDownload } from '../utils/fileUtils'
import fetch from 'isomorphic-fetch'
import { getPartnerAndSetNavThunk, partnerUpdatedAction, setPartnerAutomaticBillingEnabledAction } from './PartnerSlice'
import { notificationOpenedThunk } from './MuiNotificationsSlice'
import { NOTIFICATIONS } from '../frontendConsts'
import { setAuthorizationAction } from './AuthSlice'

export const partnerBillingSlice = createSlice({
  name: 'partnerBilling',
  initialState: {
    partnerBillingDetails: null,
    partnerBillingReports: null,
    partnerBillingInvoices: null,
    isAutomaticBillingReady: null,
    partnerBillingDetailsFailed: false,
    partnerBillingReportsFailed: false,
    partnerBillingInvoicesFailed: false,
    phinPricingPlans: null,
    loaders: {
      isLoadingPartnerBillingDetails: false,
      isLoadingPartnerBillingReports: false,
      isLoadingPartnerBillingInvoices: false,
      isDownloadingPartnerInvoiceReport: {},
      isLoadingIsAutomaticBillingReady: false,
      isLoadingEnableAutomatedBilling: false,
      isLoadingUpgradeSelfServicePlan: false,
      isLoadingPhinPricingPlans: false,
      isLoadingCancelFreeTrial: false
    }
  },
  reducers: {
    setIsLoadingPartnerBillingReports: (state, action) => {
      state.loaders.isLoadingPartnerBillingReports = action.payload
    },
    setPartnerBillingDetailsFailed: (state, action) => {
      state.partnerBillingDetailsFailed = action.payload
    },
    setPartnerBillingReportsFailed: (state, action) => {
      state.partnerBillingReportsFailed = action.payload
    },
    setPartnerBillingInvoicesFailed: (state, action) => {
      state.partnerBillingInvoicesFailed = action.payload
    },
    setPartnerBillingReports: (state, action) => {
      state.partnerBillingReports = action.payload
    },
    setIsLoadingPartnerBillingDetails: (state, action) => {
      state.loaders.isLoadingPartnerBillingDetails = action.payload
    },
    setPartnerBillingDetails: (state, action) => {
      state.partnerBillingDetails = action.payload
    },
    setIsLoadingPartnerBillingInvoices: (state, action) => {
      state.loaders.isLoadingPartnerBillingInvoices = action.payload
    },
    setPartnerBillingInvoices: (state, action) => {
      state.partnerBillingInvoices = action.payload
    },
    setIsDownloadingPartnerInvoiceReport: (state, action) => {
      state.loaders.isDownloadingPartnerInvoiceReport[action.payload.id] = action.payload.value
    },
    setPartnerIsAutomaticBillingReady: (state, action) => {
      state.isAutomaticBillingReady = action.payload
    },
    updateIsLoadingIsAutomaticBillingReady: (state, action) => {
      state.loaders.isLoadingIsAutomaticBillingReady = action.payload
    },
    updateIsLoadingEnableAutomatedBilling: (state, action) => {
      state.loaders.isLoadingEnableAutomatedBilling = action.payload
    },
    updateIsLoadingUpgradeSelfServicePlan: (state, action) => {
      state.loaders.isLoadingUpgradeSelfServicePlan = action.payload
    },
    updateIsLoadingPhinPricingPlans: (state, action) => {
      state.loaders.isLoadingPhinPricingPlans = action.payload
    },
    updatePhinPricingPlans: (state, action) => {
      state.phinPricingPlans = action.payload
    },
    updateIsLoadingCancelFreeTrial: (state, action) => {
      state.loaders.isLoadingCancelFreeTrial = action.payload
    }
  }
})

export const {
  updateIsLoadingUpgradeSelfServicePlan: updateIsLoadingUpgradeSelfServicePlanAction,
  setIsLoadingPartnerBillingReports: setIsLoadingPartnerBillingReportsAction,
  setPartnerBillingReports: setPartnerBillingReportsAction,
  setPartnerBillingReportsFailed: setPartnerBillingReportsFailedAction,
  setPartnerBillingDetailsFailed: setPartnerBillingDetailsFailedAction,
  setIsLoadingPartnerBillingDetails: setIsLoadingPartnerBillingDetailsAction,
  setPartnerBillingDetails: setPartnerBillingDetailsAction,
  setIsLoadingPartnerBillingInvoices: setIsLoadingPartnerBillingInvoicesAction,
  setPartnerBillingInvoices: setPartnerBillingInvoicesAction,
  setPartnerBillingInvoicesFailed: setPartnerBillingInvoicesFailedAction,
  setIsDownloadingPartnerInvoiceReport: setIsDownloadingPartnerInvoiceReportAction,
  setPartnerIsAutomaticBillingReady: setPartnerIsAutomaticBillingReadyAction,
  updateIsLoadingIsAutomaticBillingReady: updateIsLoadingIsAutomaticBillingReadyAction,
  updateIsLoadingEnableAutomatedBilling: updateIsLoadingEnableAutomatedBillingAction,
  updateIsLoadingPhinPricingPlans: updateIsLoadingPhinPricingPlansAction,
  updatePhinPricingPlans: updatePhinPricingPlansAction,
  updateIsLoadingCancelFreeTrial: updateIsLoadingCancelFreeTrialAction
} = partnerBillingSlice.actions

export default partnerBillingSlice.reducer

export const getPartnerBillingReportsThunk = ({ partnerId }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingPartnerBillingReportsAction(true))
    try {
      const res = await api.get(`/api/partners/${partnerId}/billing/reports`)

      if (res.status === 200) {
        const { partnerBillingReports } = await res.json()
        dispatch(setPartnerBillingReportsAction(partnerBillingReports))
      } else {
        if (!getState().partnerBilling.partnerBillingReportsFailed) {
          setTimeout(() => {
            NotificationUtilities.sendErrorMessage('Failed to load Billing Breakdown Reports. Please try again or contact Phin Support for assistance.')
          })
          // Mark that we've encountered a failure so we don't repeat the error on remount
          dispatch(setPartnerBillingReportsFailedAction(true))
        }
      }
    } catch (err) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to load Billing Breakdown Reports. Please try again or contact Phin Support for assistance.')
      })
      console.error(err)
    }

    dispatch(setIsLoadingPartnerBillingReportsAction(false))
  }
}

export const getPartnerBillingDetailsThunk = ({ partnerId }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingPartnerBillingDetailsAction(true))
    try {
      const res = await api.get(`/api/partners/${partnerId}/billing/details`)

      if (res.status === 200) {
        const { partnerBillingDetails } = await res.json()
        dispatch(setPartnerBillingDetailsAction(partnerBillingDetails))
      } else {
        if (!getState().partnerBilling.partnerBillingDetailsFailed) {
          dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.ERROR, alertMessage: 'Failed to load Partner Billing Details. Please try again or contact Phin Support for assistance.' }))
          // Mark that we've encountered a failure so we don't repeat the error on remount
          dispatch(setPartnerBillingDetailsFailedAction(true))
        }
      }
    } catch (err) {
      dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.ERROR, alertMessage: 'Failed to load Partner Billing Details. Please try again or contact Phin Support for assistance.' }))
      console.error(err)
    }

    dispatch(setIsLoadingPartnerBillingDetailsAction(false))
  }
}

export const getPartnerBillingInvoicesThunk = ({ partnerId }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingPartnerBillingInvoicesAction(true))
    try {
      const res = await api.get(`/api/partners/${partnerId}/billing/invoices`)

      if (res.status === 200) {
        const { partnerBillingInvoices } = await res.json()
        await dispatch(setPartnerBillingInvoicesAction(partnerBillingInvoices))
      } else {
        if (!getState().partnerBilling.partnerBillingInvoicesFailed) {
          dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.ERROR, alertMessage: 'Failed to load Billing Invoices. Please try again or contact Phin Support for assistance.' }))
          // Mark that we've encountered a failure so we don't repeat the error on remount
          dispatch(setPartnerBillingInvoicesFailedAction(true))
        }
      }
    } catch (err) {
      dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.ERROR, alertMessage: 'Failed to load Billing Invoices. Please try again or contact Phin Support for assistance.' }))
      console.error(err)
    }

    dispatch(setIsLoadingPartnerBillingInvoicesAction(false))
  }
}

export const getPartnerBillingInvoiceReportPDFThunk = ({ partnerId, invoice }) => {
  return async (dispatch, getState, api) => {
    try {
      dispatch(setIsDownloadingPartnerInvoiceReportAction({ id: invoice.id, value: true }))

      const res = await api.get(`/api/partners/${partnerId}/billing/invoices/${invoice.id}`)

      if (res.status === 200) {
        const { downloadURL } = await res.json()
        const downloadResponse = await fetch(downloadURL, { method: 'GET', 'Content-Type': 'application/pdf' })
        const reportBlob = await downloadResponse.blob()

        if (reportBlob.size === 0) {
          setTimeout(() => {
            NotificationUtilities.sendErrorMessage('Error downloading invoice, as it is empty, please contact Phin if problem persists.')
          })
        }

        createGenericDownload({ fileBlob: reportBlob, fileName: invoice.reportName, fileType: invoice.fileType })
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Error downloading invoice, please try again or contact Phin support for assistance')
        })
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Error downloading invoice, please try again or contact Phin support for assistance')
      })
    }
    dispatch(setIsDownloadingPartnerInvoiceReportAction({ id: invoice.id, value: false }))
  }
}

export const upgradeSelfServicePlanThunk = ({ partnerId, pricingPlanId }) => {
  return async (dispatch, getState, api) => {
    dispatch(updateIsLoadingUpgradeSelfServicePlanAction(true))
    dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.INFO, alertMessage: 'Upgrading Billing Plan.' }))

    try {
      const res = await api.post(`/api/partners/${partnerId}/billing/upgrade-self-service-plan`, {
        pricingPlanId
      })
      if (res.status === 200) {
        const { partnerUpdates } = await res.json()

        // Update Partner's Billing Details in Redux store
        dispatch(partnerUpdatedAction(partnerUpdates))

        dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.SUCCESS, alertMessage: 'Successfully Upgraded Billing Plan.' }))
      } else {
        dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.ERROR, alertMessage: 'Failed to Upgrade Billing Plan. Please try again or contact Phin Support for assistance.' }))
      }
    } catch (err) {
      dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.ERROR, alertMessage: 'Failed to Upgrade Billing Plan. Please try again or contact Phin Support for assistance.' }))
      console.error(err)
    }
    dispatch(updateIsLoadingUpgradeSelfServicePlanAction(false))
  }
}

export const enableAutomatedBillingThunk = ({ partnerId, billingCompanyName, billingEmail, isSelfServiceAccount, paymentTokenId, billingAddress, isAgreeToTerms, history, internalCompanyId }) => {
  return async (dispatch, getState, api) => {
    dispatch(updateIsLoadingEnableAutomatedBillingAction(true))
    dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.INFO, alertMessage: 'Enabling Automated Billing.' }))

    try {
      const res = await api.post(`/api/partners/${partnerId}/billing/enable-automated-billing`, {
        billingCompanyName,
        billingEmail,
        isSelfServiceAccount,
        paymentTokenId,
        billingAddress,
        isAgreeToTerms
      })

      if (res.status === 200) {
        dispatch(setPartnerAutomaticBillingEnabledAction(true))
        await dispatch(getPartnerAndSetNavThunk(partnerId))

        const authorization = { ...getState().auth.authorization, blockFullPhinAccess: false }
        dispatch(setAuthorizationAction(authorization))

        dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.SUCCESS, alertMessage: 'Successfully enabled Automated Billing.' }))

        if (isSelfServiceAccount && internalCompanyId) {
          history.push(`/companies/${internalCompanyId}/`)
        }
      } else {
        dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.ERROR, alertMessage: 'Failed to enable Automated Billing. Please try again or contact Phin Support for assistance.' }))
      }
    } catch (err) {
      dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.ERROR, alertMessage: 'Failed to enable Automated Billing. Please try again or contact Phin Support for assistance.' }))
      console.error(err)
    }
    dispatch(updateIsLoadingEnableAutomatedBillingAction(true))
  }
}

export const validateAutomatedBillingReadyForExistingPartnersThunk = ({ partnerId }) => {
  return async (dispatch, getState, api) => {
    dispatch(updateIsLoadingIsAutomaticBillingReadyAction(true))
    try {
      const res = await api.get(`/api/partners/${partnerId}/billing/validate-automated-billing-ready`)

      if (res.status === 200) {
        const { autoPayReady } = await res.json()
        dispatch(setPartnerIsAutomaticBillingReadyAction(autoPayReady))
      } else {
        dispatch(setPartnerIsAutomaticBillingReadyAction(false))
      }
    } catch (err) {
      dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.ERROR, alertMessage: 'Failed to validate Automated billing ready. Please try again or contact Phin Support for assistance.' }))
      console.error(err)
    }

    dispatch(updateIsLoadingIsAutomaticBillingReadyAction(false))
  }
}

// Get Phin Pricing Plans and add to Redux Store
export const getPhinPricingPlansThunk = ({ partnerId }) => {
  return async (dispatch, getState, api) => {
    dispatch(updateIsLoadingPhinPricingPlansAction(true))
    try {
      const res = await api.get(`/api/partners/${partnerId}/phin-pricing-plans`)

      if (res.status === 200) {
        const phinPricingPlans = await res.json()
        dispatch(updatePhinPricingPlansAction(phinPricingPlans))
      }
    } catch (err) {
      console.error(err)
    }
    dispatch(updateIsLoadingPhinPricingPlansAction(false))
  }
}

// Cancel Phin Partner Free Trial
export const cancelFreeTrialThunk = ({ partnerId }) => {
  return async (dispatch, getState, api) => {
    dispatch(updateIsLoadingCancelFreeTrialAction(true))
    try {
      const res = await api.post(`/api/partners/${partnerId}/billing/cancel-self-service-plan`)
      if (res.status === 200) {
        const { success, logOutCurrentAdmin } = await res.json()
        if (!logOutCurrentAdmin) {
          dispatch(partnerUpdatedAction({ isDeleted: true }))
        }

        dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.SUCCESS, alertMessage: 'Successfully Cancelled Phin Free Trial.' }))

        dispatch(updateIsLoadingCancelFreeTrialAction(false))

        return { success, logOutCurrentAdmin }
      } else {
        dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.ERROR, alertMessage: 'Failed to cancel Phin Free Trial. Please try again or contact Phin Support for assistance.' }))
      }
    } catch (error) {
      console.error(error)
    }
    dispatch(updateIsLoadingCancelFreeTrialAction(false))
    return { success: false }
  }
}
