import React from 'react'
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn
} from 'mdb-react-ui-kit'

import { videos } from '../../../frontendConsts.js'
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField, Tooltip } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

class BaseTemplateOptions extends React.Component {
  renderLearningTypePicker = (learningType, learningURL, updateLearningType, updateLearningUrl, id) => {
    function learningTypeClass (learningType, activeLearningType) {
      return learningType === activeLearningType ? 'learning-type-active' : ''
    }

    const dropdown = (
      <FormControl style={{ width: '100%' }}>
        <InputLabel id='learning-moment-type-dropdown-label'>Learning Moment Type</InputLabel>
        <Select
          labelId='learning-moment-type-dropdown-label'
          id='learning-moment-type-dropdown'
          value={learningType}
          onChange={(event) => {
            updateLearningType(event.target.value)
          }}
          label='Learning Moment Type'
        >
          <MenuItem value='lm' className={learningTypeClass(learningType || 'lm', 'lm')}>Learning Moment</MenuItem>
          <MenuItem value='video' className={learningTypeClass(learningType, 'video')}>Video</MenuItem>
          <MenuItem value='custom' className={learningTypeClass(learningType, 'custom')}>Custom URL</MenuItem>
        </Select>
      </FormControl>
    )

    function createInput (learningType, learningUrl) {
      if (learningType && learningType === 'custom') {
        return (
          <TextField
            sx={{ marginTop: 'var(--phin-s-2)' }}
            label='Custom URL'
            value={learningUrl}
            onChange={(e) => updateLearningUrl(e.target.value)}
          />
        )
      } else if (learningType && learningType === 'video') {
        const videoOptions = []
        for (const [videoName, videoPath] of Object.entries(videos)) {
          videoOptions.push(<MenuItem key={videoPath} value={videoPath}>{videoName}</MenuItem>)
        }
        return (
          <>
            <FormControl>
              <InputLabel id='learning-url-label'>Video URL</InputLabel>
              <Select
                labelId='learning-url-label'
                id='learning-url-dropdown'
                value={learningUrl}
                onChange={(e) => { updateLearningType(learningType); updateLearningUrl(e.target.value) }}
                label='Video URL'
              >
                {videoOptions}
              </Select>
            </FormControl>
          </>
        )
      }
    }

    return (
      <>
        {dropdown}
        {createInput(learningType, learningURL)}
      </>
    )
  }

  render () {
    const {
      tableData,
      tags,
      updateTags,
      categories,
      learningType,
      learningUrl,
      updateLearningType,
      updateLearningUrl
    } = this.props

    return (
      <>
        <MDBRow className='d-flex'>
          <MDBCol size='4'>
            <MDBCard className='mt-4 injected-fields-card'>
              <MDBCardHeader>
                <MDBRow className='d-flex'>
                  <MDBCol className='d-flex justify-content-start align-items-center'>
                    <h5>Learning Type</h5>
                  </MDBCol>
                  <MDBCol className='d-flex justify-content-end'>
                    <Tooltip
                      placement='left'
                      id='injectedfields'
                      material
                      title='This is where you can change the type of learning a user will receive when they fall for a phish. Choose from an automated tour of the email they fell for, a video from our library, or specify a custom URL for us to redirect to.'
                    >
                      <MDBBtn
                        color='elegant'
                        className='my-0'
                        floating
                        tag='a'
                        size='sm'
                      >
                        <MDBIcon icon='question' />
                      </MDBBtn>
                    </Tooltip>
                  </MDBCol>
                </MDBRow>
              </MDBCardHeader>
              <MDBCardBody>
                <MDBRow className='d-flex justify-content-center'>
                  <MDBCol
                    className='justify-content-center'
                    size='10'
                  >
                    {this.renderLearningTypePicker(learningType, learningUrl, updateLearningType, updateLearningUrl, this.props.id)}
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol size='8'>
            <MDBCard style={{ height: 400 }} className='mt-4 injected-fields-card'>
              <MDBCardHeader>
                <MDBRow className='d-flex'>
                  <MDBCol className='d-flex justify-content-start align-items-center'>
                    <h5>Injected Fields</h5>
                  </MDBCol>
                  <MDBCol className='d-flex justify-content-end'>
                    <Tooltip
                      placement='left'
                      id='injectedfields'
                      material
                      title='Any custom injected fields detected in the template will be shown here. Select a value you wish to assign each injection with here.'
                    >
                      <MDBBtn
                        color='elegant'
                        className='my-0'
                        floating
                        tag='a'
                        size='sm'
                      >
                        <MDBIcon icon='question' />
                      </MDBBtn>
                    </Tooltip>
                  </MDBCol>
                </MDBRow>
              </MDBCardHeader>
              <DataGrid
                height={400}
                columns={
                          tableData.columns
                        }
                rows={tableData.rows}
              />
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBCard className='mt-4'>
          <MDBCardHeader>
            <MDBRow className='d-flex'>
              <MDBCol className='d-flex justify-content-start align-items-center'>
                <h5>Tags</h5>
              </MDBCol>
              <MDBCol className='d-flex justify-content-end'>
                <Tooltip
                  placement='left'
                  id='tagspopover'
                  material
                  title={'These are the "keywords" of this template. These would be the category of template or a specific form of phish.  They are used to help categorize template types. Examples include: billing, notification and travel.'}
                >
                  <MDBBtn
                    color='elegant'
                    className='my-0'
                    floating
                    tag='a'
                    size='sm'
                  >
                    <MDBIcon icon='question' />
                  </MDBBtn>
                </Tooltip>
              </MDBCol>
            </MDBRow>
          </MDBCardHeader>
          <MDBCardBody>
            <MDBRow className='d-flex justify-content-center'>
              <MDBCol
                className='justify-content-center'
                size='10'
              >
                <MDBRow className='d-flex justify-content-center align-items-center'>
                  <FormControl style={{ width: '35%', marginTop: '.5rem' }}>
                    <Select
                      labelId='demo-multiple-checkbox-label'
                      label='Tags'
                      id='demo-multiple-checkbox'
                      multiple
                      value={tags}
                      onChange={updateTags}
                      input={<OutlinedInput label='Tag' />}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                    >
                      {categories.map((tag) => (
                        <MenuItem key={tag} value={tag}>
                          <Checkbox checked={tags.indexOf(tag) > -1} />
                          <ListItemText primary={tag} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      </>
    )
  }
}

export default BaseTemplateOptions
