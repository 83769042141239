import { Skeleton } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { orgLevels, shareLevels } from '../frontendConsts.js'
import { uploadNewLogoThunk } from '../store/LogoSlice.js'
import { getWelcomeEmailThunk } from '../store/WelcomeEmailSlice.js'
import ChangePartnerName from './components/branding/ChangePartnerName.js'
import WelcomeEmail from './components/branding/WelcomeEmail'
import NotificationUtilities from './components/notifications/notificationUtils.js'
import ImageSettings from './components/settingsPage/ImageSettings.js'

function PartnerBrandingPage ({ id, props }) {
  const { logoUrl } = useSelector(state => state.logo)
  const { isLoadingLogo } = useSelector((state) => state.logo.loaders)
  const { loaders } = useSelector((state) => state.welcomeEmail)
  const { isLoadingWelcomeEmail } = loaders

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getWelcomeEmailThunk(id, orgLevels.PARTNER))
  }, [])

  async function handleLogoImageUpload (file) {
    if (file.type.indexOf('image') === -1) {
      NotificationUtilities.sendErrorMessage('Please Upload an Image')
      return
    }

    const reader = new FileReader()

    try {
      reader.readAsDataURL(file)
      reader.onload = async (e) => {
        const formData = new FormData()
        formData.append('image', file, file.name)

        dispatch(uploadNewLogoThunk({ formData, id, shareLevel: shareLevels.PARTNER }))
      }
      reader.onerror = (err) => {
        console.error(err)
        throw new Error('Error while reading file')
      }
    } catch (err) {
      console.error(err)
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('There was an error reading your file, please try again')
      })
    }
  }

  return (
    <>
      {(isLoadingLogo || isLoadingWelcomeEmail) && (
        <>
          <div className='pageContainer'>
            <Skeleton sx={{ bgcolor: '#c4c4c4' }} variant='rectangle' width={1190} height={725} />
          </div>
          <div className='pageContainer'>
            <Skeleton sx={{ bgcolor: '#c4c4c4' }} variant='rectangle' width={1190} height={1400} />
          </div>
        </>
      )}
      {(!isLoadingLogo && !isLoadingWelcomeEmail) && (
        <>
          <ChangePartnerName id={id} />
          <ImageSettings
            id='imageSettings'
            logoURL={logoUrl}
            handleLogoImageChange={handleLogoImageUpload}
            shouldFocusImageSettings={false}
          />
          <WelcomeEmail id={id} orgLevel={orgLevels.PARTNER} />
        </>
      )}
    </>
  )
}

export default PartnerBrandingPage
