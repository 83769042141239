import { Box, Button, Chip, Divider, Grid, Skeleton } from '@mui/material'
import '../css/manageBilling.css'
import { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { useDispatch, useSelector } from 'react-redux'
import { getPax8PricingPlansThunk, selectPaxPartnerPricingPlanThunk } from '../../store/Pax8PartnerSlice'
import { useHistory } from 'react-router-dom'
import { renderPhinPlanSkeletons } from '../partnerPages/PartnerManageBillingPlanPage'
import { PAX8_ACCOUNT_STATUSES } from '../../frontendConsts'

function PricingPlanComponent ({ selectedPlan, pax8PricingPlan, setSelectedPlan, currentPlanId, pendingPlanId }) {
  let cardClassName = 'plan-card'

  if (selectedPlan?.id === pax8PricingPlan.id) {
    cardClassName = 'plan-selected'
  } else if (currentPlanId === pax8PricingPlan.id) {
    cardClassName = 'current-plan-card'
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}
    >
      {pax8PricingPlan.id === currentPlanId && (
        <Chip
          color='primary'
          variant='outlined'
          label='Current Plan'
          sx={{ margin: 'var(--phin-s0)' }}
        />)}
      {(pax8PricingPlan.id === pendingPlanId && pax8PricingPlan.id !== currentPlanId) && (
        <Chip
          color='phinGreen'
          variant='outlined'
          label='Pending Plan'
          sx={{ margin: 'var(--phin-s0)' }}
        />)}

      <div
        className={cardClassName}
        onClick={() => setSelectedPlan(pax8PricingPlan)}
        id={pax8PricingPlan.id}
        key={pax8PricingPlan.id}
      >
        <div className={(selectedPlan === pax8PricingPlan.id) ? 'plan-heading-selected' : 'plan-heading'}>

          <p className='phin-body-text'>{(pax8PricingPlan.type)}</p>
        </div>

        <div className='plan-contents'>
          <div>
            <p className='phin-h5'>{(pax8PricingPlan.name)}</p>
            <p className='phin-body-text'> includes {pax8PricingPlan.minimumBillableUsers} users + 1 internal company</p>
          </div>
          <div>
            <p className='phin-h5'>Pricing</p>
            <p className='phin-h4'>${pax8PricingPlan.price}</p>
            <p className='phin-body-text-small'>+${pax8PricingPlan.perUserCost} / additional user</p>
          </div>
          <div className='plan-discount'>
            {(pax8PricingPlan.discountAmount !== 0) && (
              <Chip
                sx={{
                  backgroundColor: 'var(--light-blue)',
                  color: 'white',
                  fontWeight: '500',
                  fontStyle: 'Roboto, sans-serif',
                  fontSize: '.833rem'
                }}
                label={`${pax8PricingPlan.discountAmount}% Discount`}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export function Pax8PlanPickerPage ({ partnerId }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const { isLoadingPax8PricingPlans, pax8PricingPlans } = useSelector((state) => state.pax8Partner)
  const { loaders, partner } = useSelector((state) => state.partner)
  const { isLoadingPartner } = loaders

  const today = DateTime.now()
  let nextBillingDate
  if (partner?.pax8AccountStatus === PAX8_ACCOUNT_STATUSES.TRIALING) {
    // pax8TrialEndTimestamp
    const pax8TrialEndTimestamp = DateTime.fromSeconds(partner.pax8TrialEndTimestamp._seconds)
    nextBillingDate = pax8TrialEndTimestamp.plus({ months: 1 }).startOf('month').toLocaleString(DateTime.DATE_MED)
  } else {
    nextBillingDate = today.plus({ months: 1 }).startOf('month').toLocaleString(DateTime.DATE_MED)
  }

  const [selectedPlan, setSelectedPlan] = useState()

  function generatePricingPlanCards () {
    const pricingPlanJSX = []

    if (pax8PricingPlans && pax8PricingPlans.length > 0) {
      for (const pax8PricingPlan of pax8PricingPlans) {
        pricingPlanJSX.push(
          <PricingPlanComponent
            selectedPlan={selectedPlan}
            pax8PricingPlan={pax8PricingPlan}
            setSelectedPlan={setSelectedPlan}
            currentPlanId={partner.pax8PlanId}
            pendingPlanId={partner.pendingPax8PlanId}
          />
        )
      }
    }

    return pricingPlanJSX
  }

  useEffect(() => {
    if (!pax8PricingPlans) {
      dispatch(getPax8PricingPlansThunk({ partnerId }))
    }
  }, [])

  useEffect(() => {
    if (partner && partner.pax8PlanId && pax8PricingPlans) {
      setSelectedPlan(pax8PricingPlans.find((plan) => plan.id === partner.pax8PlanId))
    } else if (pax8PricingPlans) {
      setSelectedPlan(pax8PricingPlans[0])
    }
  }, [pax8PricingPlans, partner])

  async function selectPlan () {
    const { success, hasCurrentPlan } = await dispatch(selectPaxPartnerPricingPlanThunk({ partnerId, pax8PlanId: selectedPlan.id }))

    if (success) {
      if (hasCurrentPlan) {
        // Redirect to the main Partner page
        history.push(`/partners/${partnerId}/billing`)
      } else {
        // Redirect to the main Partner page
        history.push(`/partners/${partnerId}`)
      }
    }
  }

  return (
    <Box padding={3} marginRight='100px'>
      <div className='single-page-test'>
        <div className='phin-h2'>Pax8 Plan Picker</div>
        {partner?.pax8AccountStatus === PAX8_ACCOUNT_STATUSES.TRIALING && (
          <div className='phin-body-text'>Please pick a plan to continue using Phin. At the end of your trial your account will be suspended and you will NOT be billed.</div>
        )}

        {(partner?.pax8AccountStatus === PAX8_ACCOUNT_STATUSES.ACTIVE || partner?.pax8AccountStatus === PAX8_ACCOUNT_STATUSES.PRORATE) && (
          <div className='phin-body-text'>Update your pricing plan here. Changes will take affect after the current billing cycle closes.</div>
        )}

        {partner?.pax8AccountStatus === PAX8_ACCOUNT_STATUSES.TRIAL_SUSPENDED && (
          <div className='phin-body-text'>Your account is currently suspended. Please pick a plan to reactivate your account.</div>
        )}

        {(isLoadingPax8PricingPlans || !pax8PricingPlans || isLoadingPartner || !partner) && (
          <div className='plan-container'>
            {renderPhinPlanSkeletons()}
          </div>
        )}

        {(!isLoadingPax8PricingPlans && pax8PricingPlans && !isLoadingPartner && partner) && (

          <div className='plan-container-center'>
            <div className='plan-container'>
              {generatePricingPlanCards()}
            </div>
          </div>
        )}

        {(isLoadingPax8PricingPlans || !pax8PricingPlans) && (
          <div className='plan-selection-summary'>
            <Skeleton sx={{ bgcolor: '#c4c4c4', marginBottom: '3rem' }} variant='rectangle' width={1600} height={200} />
          </div>
        )}

        {(!isLoadingPax8PricingPlans && pax8PricingPlans) && (
          <div>
            <Divider />
            <div className='padding:3'>
              <div className='d-flex justify-content-between align-content-start' style={{ gap: '20px' }}>
                <p className='phin-h4'>Estimated Monthly Bill Total</p>
                <p className='phin-h4'>${selectedPlan?.price || ''}</p>
              </div>
              <div className='d-flex justify-content-between align-content-start'>
                <p className='phin-h4'>Due on</p>
                <p className='phin-h4'>{nextBillingDate}</p>
              </div>
            </div>
          </div>
        )}

        <Grid container spacing={2} justifyContent='end' alignItems='center'>
          {(partner.pax8PlanId && (
            <Grid item>
              <Button
                id='select-pax8-plan-button'
                aria-label='Select Pax8 Plan Button'
                size='large'
                variant='outlined'
                color='primary' onClick={() => history.goBack()}
              >Cancel
              </Button>
            </Grid>
          ))}

          <Grid item>
            <Button
              id='select-pax8-plan-button'
              aria-label='Select Pax8 Plan Button'
              size='large'
              color='primary'
              variant='contained'
              disableElevation
              onClick={() => selectPlan()}
            > {partner.pax8PlanId ? 'Update Plan' : 'Select Plan'}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Box>

  )
}
