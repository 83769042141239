import { DataGrid } from '@mui/x-data-grid'
import { ConnectWiseCompanyDropdown } from './ConnectWiseCompanyDropdown'
import { useGetCwMappingsQuery, useGetSyncStatusQuery, useLazyGetCwMappingsQuery } from '../../../../store/ConnectWiseApiSlice'
import { Link, useHistory } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { IoCheckmarkCircle, IoCloseCircleSharp } from 'react-icons/io5'
import { TableFooter } from './TableFooter'
import { TableHeader } from './TableHeader'
import { useEffect } from 'react'

export function ConnectWiseServiceDeskTable ({ partnerId, cwServiceDeskEnabled }) {
  const history = useHistory()
  const { data: companyMappings, isLoading: isLoadingCwCompanyMappings } = useGetCwMappingsQuery({ partnerId })
  const [getMappingsTriggerFunction] = useLazyGetCwMappingsQuery()

  const { data: syncStatus } = useGetSyncStatusQuery({ partnerId }, { pollingInterval: 3000 })
  const isSyncing = syncStatus?.isSyncing || syncStatus?.isAutoMapping

  if (!cwServiceDeskEnabled) {
    history.replace(`/partners/${partnerId}/integrations/connectWise`)
  }

  useEffect(() => {
    if (!isSyncing) {
      getMappingsTriggerFunction({ partnerId })
    }
  }, [isSyncing, getMappingsTriggerFunction, partnerId])

  return (
    <div style={{ height: 700, width: '100%' }}>
      <DataGrid
        loading={isLoadingCwCompanyMappings}
        className='DataGrid'
        rowHeight={60}
        sx={{ marginBottom: 'var(--phin-s1)' }}
        components={{ Footer: TableFooter, Toolbar: TableHeader }}
        componentsProps={{
          footer: {
            companyMappings
          },
          toolbar: {
            partnerId,
            isButtonDisabled: isSyncing
          }
        }}
        columns={[
          {
            headerName: 'Phin Company',
            field: 'companyName',
            minWidth: 200,
            flex: 1
          },
          {
            headerName: 'Billable Users',
            field: 'lastMonthBillableUsers',
            minWidth: 190,
            flex: 1
          },
          {
            headerName: 'ConnectWise Company',
            field: 'cwCompanyName',
            minWidth: 300,
            flex: 2,
            renderCell: (params) => {
              return (
                <ConnectWiseCompanyDropdown
                  partnerId={partnerId}
                  companyId={params.row.companyId}
                  cwCompany={params.row.cwCompanyId ? { id: params.row.cwCompanyId, label: params.row.cwCompanyName } : null}
                  isDisabled={isSyncing || isLoadingCwCompanyMappings}
                />
              )
            }
          },
          {
            headerName: 'Report Phishing Button',
            field: 'isRAPConfigured',
            flex: 1,
            renderCell: (params) => {
              return (
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '0.5rem', alignItems: 'center' }}>
                  {params.row.isRAPConfigured
                    ? <>
                      <IoCheckmarkCircle size='1.5em' color='var(--phin-green)' />
                      <Typography>Enabled</Typography>
                      </>

                    : <>
                      <IoCloseCircleSharp color='var(--phin-red)' size='1.5em' />
                      <Typography>Not Enabled</Typography>
                      <Link target='_blank' to={`/companies/${params.row.id}/integrations/reportAPhish`}>
                        <Typography>Setup</Typography>
                      </Link>
                      </>}

                </Box>
              )
            }
          }
        ]}
        rows={companyMappings?.rows || []}
        disableSelectionOnClick
        disableColumnSelector
        disableDensitySelector
        isRowSelectable={() => false}
      />

    </div>
  )
}
