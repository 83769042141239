import React from 'react'
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBBadge
} from 'mdb-react-ui-kit'
import { TextField, Tooltip } from '@mui/material'

class SMSTemplateOptions extends React.Component {
  render () {
    const {
      handleSMSMessageEdit,
      selectedTemplate
    } = this.props

    return (
      <>
        <MDBCard className='mt-4'>
          <MDBCardHeader>
            <MDBRow className='d-flex'>
              <MDBCol className='d-flex justify-content-start align-items-center'>
                <h5>Text Message <MDBBadge className='ml-2' color='warning'>BETA</MDBBadge></h5>
              </MDBCol>
              <MDBCol className='d-flex justify-content-end'>
                <Tooltip
                  placement='left'
                  id='textmessage'
                  material
                  title='This is the body of the message being sent. Injected field markup is supported if you want to include injected fields in this message.'
                >
                  <MDBBtn
                    color='elegant'
                    className='my-0'
                    floating
                    tag='a'
                    size='sm'
                  >
                    <MDBIcon icon='question' />
                  </MDBBtn>
                </Tooltip>
              </MDBCol>
            </MDBRow>
          </MDBCardHeader>
          <MDBCardBody>
            <MDBRow className='d-flex justify-content-center'>
              <MDBCol>
                <TextField
                  fullWidth
                  name='sms-message'
                  label='Message'
                  labelClass='text-dark'
                  multiline
                  value={selectedTemplate.message}
                  minRows={5}
                  maxRows={10}
                  onChange={e => handleSMSMessageEdit(e)}
                />
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      </>
    )
  }
}

export default SMSTemplateOptions
