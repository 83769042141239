import './curriculumPreview.scss'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { campaignTypes } from '../../../../frontendConsts.js'
import { IoLockClosed } from 'react-icons/io5'
import { MdOutlineDragIndicator } from 'react-icons/md'

export const ReorderComponent = ({ campaignType, modules, setModules, tz, currentModule }) => {
  const reorder = (list, currentIndex, destinationIdex) => {
    const result = Array.from(list)
    const [removed] = result.splice(currentIndex, 1)

    // If the destination index is before the last released module,
    //    set it to be after the last released module
    if (destinationIdex < currentModule) {
      destinationIdex = currentModule
    }

    result.splice(destinationIdex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(
      modules,
      result.source.index,
      result.destination.index
    )

    setModules(items)
  }

  function pickBackgroundColor (isDragging, isLocked) {
    if (isDragging) {
      return 'var(--dark-blue-btn)'
    } else if (isLocked) {
      return 'var(--test-component-bg)'
    } else {
      return '#EAF5FF'
    }
  }

  function pickTextColor (isDragging) {
    if (isDragging) {
      return 'white'
    } else {
      return 'black'
    }
  }

  const getItemStyle = ({ isDragging, draggableStyle, isLocked }) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 8 * 2,
    margin: '0 0 8px 0',

    borderRadius: 8,
    // change background color if dragging
    background: pickBackgroundColor(isDragging, isLocked),
    color: pickTextColor(isDragging),

    // styles we need to apply on draggables
    ...draggableStyle
  })

  const getListStyle = () => ({
    overflowY: 'scroll',
    height: 450,
    paddingRight: 16,
    paddingLeft: 16,
    paddingBottom: 16
  })

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {(!modules || (modules.length < 1)) && (
        <p className='preview-warning-finalize'>no courses selected</p>
      )}
      <Droppable droppableId='curriculum-reorder'>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={getListStyle()}
          >
            {(modules) && modules.map((course, index) => (
              // note: prebuilt curriculums do not have an id. So using course-definition path in place for module reordering
              <Draggable
                className='preview-row'
                key={(course.id) ? course.id : course.path}
                draggableId={(course.id) ? course.id : course.path}
                index={index}
                isDragDisabled={campaignType === campaignTypes.ONBOARDING || (course.moduleId !== undefined && course.moduleId !== null)}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle({
                      isDragging: snapshot.isDragging,
                      draggableStyle: provided.draggableProps.style,
                      isLocked: course.moduleId !== undefined && course.moduleId !== null
                    })}
                  >
                    <div className='draggable-row'>
                      {campaignType === campaignTypes.TRAINING && (
                        <>
                          <p className='icon-col order-lock-icon'>
                            {campaignType === campaignTypes.ONBOARDING || (course.moduleId !== undefined && course.moduleId !== null)
                              ? <IoLockClosed size={24} />
                              : <MdOutlineDragIndicator size={24} />}
                          </p>
                          <p className='order-num-label order-col'> {index + 1} </p>
                        </>
                      )}
                      <p className='data-col'>{(course.name.length < 30) ? `${course.name}` : `${course.name.slice(0, 28)}...`}</p>
                      <p className='data-col'>{course.topic || 'Custom'}</p>
                      {campaignType === campaignTypes.TRAINING && (
                        <p className='data-col'> {(course.dateStarted?._seconds
                          ? new Date(course.dateStarted._seconds * 1000).toLocaleString(navigator.language, { timeZone: tz })
                          : false) || course.firetime?.toLocaleString(navigator.language, { timeZone: tz })} ({tz})
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
