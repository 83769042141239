import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import './curriculumCourseSelect.scss'
import { CardActions, Button, CardActionArea } from '@mui/material'
import { IoTimeOutline } from 'react-icons/io5'
import { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

export const CurriculumCard = ({
  curriculum, setModules, setCurriculumDescription,
  curriculumId, setCurriculumId
}) => {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  function curriculumChangeHandler (curriculum, setModules, setCurriculumDescription, curriculumId, setCurriculumId) {
    handleClose()
    if (curriculum.id === curriculumId) {
      setModules([])
      setCurriculumDescription(undefined)
      setCurriculumId(undefined)
    } else {
      setModules(curriculum.modules)
      setCurriculumDescription(curriculum.description)
      setCurriculumId(curriculum.id)
    }
  }

  return (
    <Card
      variant='outlined'
      className={curriculum.id === curriculumId ? 'curriculum-card active-card' : 'curriculum-card'}
    >
      <CardActionArea className='curriculum-card-content' onClick={() => curriculumChangeHandler(curriculum, setModules, setCurriculumDescription, curriculumId, setCurriculumId)}>
        <CardContent>
          <p className='curriculum-title'>{curriculum.name}</p>
          <p className='curriculum-description'>
            {curriculum.description}
          </p>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size='small' onClick={() => setOpen(true)}>Details</Button>
        <div className='time-estimate'>
          <IoTimeOutline />
          <p>{curriculum.modules.length * 10} mins</p>
        </div>
      </CardActions>

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle sx={{ textAlign: 'left' }} className='DialogTitle'>
          <h4 className='phin-h4'>{curriculum.name}</h4>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {curriculum.description}
          </DialogContentText>
          <DialogContentText>
            <br />Estimated Completion Time: {curriculum.modules.length * 10} minutes.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Back</Button>
          <Button onClick={() => curriculumChangeHandler(curriculum, setModules, setCurriculumDescription, curriculumId, setCurriculumId)} autoFocus>
            {curriculum.id === curriculumId ? 'Deselect This Curriculum' : 'Select This Curriculum'}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>

  )
}
