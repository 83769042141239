import '../../css/AutomaticSendingBanner.css'

export default function AutomaticSendingBanner ({ unwelcomedUserIds, setShowWelcomeUserModal }) {
  return (
    <>
      <div className='automaticSendingBanner'>
        {unwelcomedUserIds.length === 1 && (
          <div>Your audience has <b>{unwelcomedUserIds.length}</b> user that has not been welcomed &#8212; <span className='scheduleEmailsBtn' onClick={() => setShowWelcomeUserModal(true)}>Schedule Now</span></div>
        )}

        {unwelcomedUserIds.length > 1 && (
          <div>Your audience has <b>{unwelcomedUserIds.length}</b> users that have not been welcomed &#8212; <span className='scheduleEmailsBtn' onClick={() => setShowWelcomeUserModal(true)}>Schedule Now</span></div>
        )}
      </div>
    </>
  )
}
