import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  createPhinApiIntegrationThunk,
  disconnectPhinApiThunk,
  getPhinApiIntegrationThunk,
  rotatePhinApiSecretThunk,
  setAuth0ClientSecretAction
} from '../store/phinDistributorApiSlice.js'

import NotificationUtilities from './components/notifications/notificationUtils'
import { Button, Skeleton } from '@mui/material'
import Spinner from './components/Spinner'
import PhinModal from './components/PhinModal.js'
import { MDBIcon } from 'mdb-react-ui-kit'
import { distributorIntegrationStatuses } from '../frontendConsts.js'
import './css/integrationPhinAPI.css'
import { getDistributorIntegrationsThunk } from '../store/DistributorIntegrationSlice.js'
import { IoOpenOutline } from 'react-icons/io5'

export function PhinDistributorApiIntegrationPage ({ id }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const { distributorIntegrationsMap } = useSelector((state) => state.distributorIntegrations)
  const { phinApiIntegration, auth0ClientSecret, loaders } = useSelector((state) => state.phinDistributorAPI)
  const { isLoadingPhinAPI, isLoadingPhinApiSecret } = loaders

  const [disableButtons, setDisableButtons] = useState(false)
  const [isReusableModalOpen, setIsReusableModalOpen] = useState(false)
  const [confirmModalFunction, setConfirmModalFunction] = useState(null)
  const [modalText, setModalText] = useState()

  useEffect(() => {
    if (!phinApiIntegration) {
      dispatch(getPhinApiIntegrationThunk())
    }
  }, [distributorIntegrationsMap])

  useEffect(() => {
    if (!distributorIntegrationsMap) {
      dispatch(getDistributorIntegrationsThunk(id))
    }

    return function cleanUp () {
      dispatch(setAuth0ClientSecretAction(null))
    }
  }, [])

  const renderStatusBreadCrumbs = (phinApiIntegration) => {
    const breadCrumbArray = []
    const phinApiIntegrationStatus = phinApiIntegration ? phinApiIntegration.integrationStatus : distributorIntegrationStatuses.NOT_ENABLED
    for (const [stage] of Object.entries(distributorIntegrationStatuses)) {
      if (distributorIntegrationStatuses[stage] === distributorIntegrationStatuses.ENABLED) {
        breadCrumbArray.push(
          <>
            <div className={(distributorIntegrationStatuses[stage] === phinApiIntegrationStatus) ? 'statusCrumb activeCrumb' : 'statusCrumb'}>{distributorIntegrationStatuses[stage]}</div>
          </>
        )
      } else {
        breadCrumbArray.push(
          <>
            <div className={(distributorIntegrationStatuses[stage] === phinApiIntegrationStatus) ? 'statusCrumb activeCrumb' : 'statusCrumb'}>{distributorIntegrationStatuses[stage]}</div><hr className='crumbHR' style={{ display: 'inline-block' }} />
          </>
        )
      }
    }
    return breadCrumbArray
  }

  function closeModal () {
    setIsReusableModalOpen(false)
    setDisableButtons(false)
  }

  const createPhinApiIntegration = async () => {
    if (disableButtons) {
      return
    }
    setDisableButtons(true)
    await dispatch(createPhinApiIntegrationThunk(id))
    setDisableButtons(false)
  }

  const rotatePhinApiSecret = () => {
    if (disableButtons) {
      return
    }
    setDisableButtons(true)
    setIsReusableModalOpen(true)
    setConfirmModalFunction(() => {
      dispatch(rotatePhinApiSecretThunk(id))
      closeModal()
    })
    setModalText(<p>Are you sure you want to rotate your Phin API Client Secret? <br /> WARNING: This will revoke the current Client Secret. Any existing usage of the Phin API will need to use the new Client Secret.</p>)
  }

  const disconnectPhinApi = () => {
    if (disableButtons) {
      return
    }
    setDisableButtons(true)
    setIsReusableModalOpen(true)
    setConfirmModalFunction(() => {
      dispatch(disconnectPhinApiThunk({ distributorId: id, history }))
      closeModal()
    })
    setModalText(<p>Are you sure you want to delete the Phin API Integration? <br /> WARNING: This will revoke the current Client Secret. Any services using the Client ID and Client Secret will no longer be supported.</p>)
  }

  const saveToClipBoard = (value) => {
    navigator.clipboard.writeText(value).then(() => {
      NotificationUtilities.sendSuccessMessage('Copied!')
    })
  }

  return (
    <div>
      <PhinModal
        isOpen={isReusableModalOpen}
        title='Delete API Integration'
        close={closeModal}
        closeText='Cancel'
        action={confirmModalFunction}
        actionText='Confirm'
      >
        {modalText}
      </PhinModal>

      <div className='detailsPageHeadingGroup'>
        <img src='/logoBlueNoTextSmall.png' />
        <div>
          <div className='detailsTitleGroup'>
            <h2 className='phin-page-heading'>Phin API</h2>
          </div>
          <div className='crumbContainer'>
            {renderStatusBreadCrumbs(phinApiIntegration)}
          </div>
        </div>
      </div>
      <div className='details'>
        <div className='left'>
          {/* start of the consent card */}
          <div className='consentCard'>
            <div className='consentTitle'> In order to use the Phin API you will need to generate an ID and a secret </div>
            <div className='permissions'>
              <p className='consentSubtitle'>Opting into the Phin API will grant anyone with your ID and secret permission to read and write information for the distributor, partners, and their companies.</p>
              <p className='consentSubtitle'>This includes:</p>
              <ul>
                <li>Distributor-level details:
                  <ul>
                    <li>Create, Delete, Suspend, and Reactivate partners</li>
                    <li>Get the distributor's usage</li>
                  </ul>
                </li>
                <li>Partner-level details:
                  <ul>
                    <li>Get Partner information</li>
                    <li>Get Partner usage</li>
                    <li>Get Partner's companies</li>
                  </ul>
                </li>
                <li>Company-level details:
                  <ul>
                    <li>Create companies</li>
                    <li>Get company usage</li>
                    <li>Get company PDF reports</li>
                    <li>Get historical company data</li>
                    <li>Get full company information, including users' full name, email addresses, simulated phishing data, and training data.</li>
                  </ul>
                </li>
              </ul>
              <p className='consentSubtitle'>How will this information get used?</p>
              <ul>
                <li>
                  This information will get used to generate responses to API calls.
                </li>
              </ul>
            </div>

            <div className='d-flex justify-content-center'>
              <Button
                size='large'
                id='generate'
                aria-label='Generate API Connection Information'
                disabled={(phinApiIntegration && phinApiIntegration.auth0ClientId) ? 'disabled' : false}
                onClick={(phinApiIntegration && phinApiIntegration.auth0ClientId) ? false : createPhinApiIntegration}
                variant={(phinApiIntegration && phinApiIntegration.auth0ClientId) ? 'outlined' : 'contained'}
                color={(phinApiIntegration && phinApiIntegration.auth0ClientId) ? 'info' : 'primary'}
              >Generate
              </Button>
            </div>

          </div>
          {/* end of the consent card */}
        </div>
        {(!phinApiIntegration && isLoadingPhinAPI)
          ? (
            <div style={{ margin: 'auto' }}><Spinner message='Generating API Credentials' /></div>
            )
          : (
            <div className='right' style={(phinApiIntegration && phinApiIntegration.auth0ClientId) ? {} : { filter: 'opacity(.2)', pointerEvents: 'none' }}>
              <div className='consentCard'>
                <div id='api-credentials'>
                  <div className='card-header-group'>
                    <h4 className='phin-h4'>API Credentials</h4>
                    {auth0ClientSecret && (
                      <div className='card-desc'> Note:
                        The Client Secret value can only be viewed immediately after creation. Be sure to save the secret before navigating away.
                      </div>
                    )}
                    {!auth0ClientSecret && (
                      <div className='card-desc'> Note:
                        The Client Secret value cannot be viewed after creation. If you need a new Client Secret, use the Rotate Secret button to generate a new one.
                        This will revoke the current Client Secret.
                      </div>
                    )}
                  </div>
                  <div className='credential-center'>
                    <div className='cred-group'>
                      <p>Distributor ID:</p>
                      <div className='black-box'> {id} </div>
                      <button onClick={() => saveToClipBoard(id)} className='copy-btn'><MDBIcon far icon='clone' /></button>
                    </div>
                    <div className='cred-group'>
                      <p>Client ID:</p>
                      {isLoadingPhinAPI
                        ? (
                          <Skeleton sx={{ bgcolor: '#c4c4c4', margin: '.25em', borderRadius: '8px', paddingRight: '70%' }} height={40} width={380} variant='rectangle'>
                            <div className={(phinApiIntegration && phinApiIntegration.auth0ClientId) ? 'black-box' : 'black-box disabled-color'}> {(phinApiIntegration && phinApiIntegration.auth0ClientId) || '-----'} </div>
                          </Skeleton>
                          )
                        : (
                          <div className={(phinApiIntegration && phinApiIntegration.auth0ClientId) ? 'black-box' : 'black-box disabled-color'}> {(phinApiIntegration && phinApiIntegration.auth0ClientId) || '-----'} </div>
                          )}
                      <button disabled={!(phinApiIntegration && phinApiIntegration.auth0ClientId)} onClick={() => saveToClipBoard(phinApiIntegration.auth0ClientId)} className='copy-btn'><MDBIcon far icon='clone' /></button>
                    </div>
                    <div className='cred-group'>
                      <p>Client Secret:</p>
                      {(isLoadingPhinAPI || isLoadingPhinApiSecret)
                        ? (
                          <Skeleton sx={{ bgcolor: '#c4c4c4', margin: '.25em', borderRadius: '8px', paddingRight: '70%' }} height={40} width={380} variant='rectangle'>
                            <div className={(auth0ClientSecret) ? 'black-box' : 'black-box disabled-color'}> {auth0ClientSecret || '-----'} </div>
                          </Skeleton>
                          )
                        : (
                          <div className={(auth0ClientSecret) ? 'black-box' : 'black-box disabled-color'}> {auth0ClientSecret || '-----'} </div>
                          )}
                      <button disabled={!auth0ClientSecret} onClick={() => saveToClipBoard(auth0ClientSecret)} className='copy-btn'><MDBIcon far icon='clone' /></button>
                    </div>
                  </div>

                  <div className='action-btn-group'>
                    <Button
                      id='disconnect'
                      aria-label='Disable API Key'
                      size='large'
                      variant='contained'
                      color='error'
                      onClick={disconnectPhinApi}
                    >Disconnect
                    </Button>

                    <Button
                      id='documentation'
                      aria-label='View Documentation'
                      endIcon={<IoOpenOutline />}
                      href='https://www.phinsec.io/knowledge/api-technical-questions#phin-api-documentation'
                      rel='noreferrer'
                      target='_blank'
                      size='large'
                      variant='outlined'
                      disableElevation
                      color='primary'
                    >Documentation
                    </Button>

                    <Button
                      id='rotate'
                      aria-label='Rotate API Key'
                      onClick={rotatePhinApiSecret}
                      variant='contained'
                      size='large'
                      color='primary'
                    >Rotate Secret
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            )}

      </div>
    </div>
  )
}
