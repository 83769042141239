import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { useHistory, useLocation } from 'react-router-dom'

export function SideNavItem ({ id, isSideNavOpen, text, route, icon }) {
  const history = useHistory()
  const location = useLocation()

  return (
    <ListItem
      disablePadding
      sx={
        location?.pathname && location.pathname === route
          ? { display: 'block', backgroundColor: 'var(--phin-dark-blue)' }
          : { display: 'block' }
}
      onClick={() => route && (history.push(route))}
    >
      <ListItemButton
        id={id}
        sx={{
          minHeight: 48,
          justifyContent: isSideNavOpen ? 'initial' : 'center',
          px: 2.5
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: isSideNavOpen ? 1 : 'auto',
            justifyContent: 'center'
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={text} sx={{
            opacity: isSideNavOpen ? 1 : 0,
            fontStyle: 'Inter',
            fontWeight: 500,
            fontSize: '1.44rem'
          }}
        />
      </ListItemButton>
    </ListItem>
  )
}
