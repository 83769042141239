import { apiRequestUtils } from './apiRequestUtils'

export default function sendEmail (email, creds) {
  const { cuid } = creds
  apiRequestUtils.post('/api/error', { error: `Company with CUID: ${cuid}; Error: ${email}` })
    .then(response => {
      return response.json()
    })
    .catch(err => {
      console.error(err)
    })
}
