class MiddleWareError extends Error {
  constructor (message, code) {
    super(message)
    this.statusCode = code
    this.name = 'MiddleWareError'
  }
}

export { MiddleWareError }

export class ServerError extends Error {
  constructor (message) {
    super(message)
    this.name = 'ServerError'
  }
}
