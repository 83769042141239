/* global FileReader */

import {
  MDBRow, MDBCol, MDBCardBody,
  MDBListGroup, MDBListGroupItem
} from 'mdb-react-ui-kit'
import { useEffect, useState } from 'react'
import NotificationUtilities from './components/notifications/notificationUtils'
import ActionButton from './components/Campaigns/ActionButton'
import Papa from 'papaparse'
import uniqBy from 'lodash/uniqBy'
import differenceBy from 'lodash/differenceBy'
import ValidationUtils from '../utils/validationUtils'
import { apiRequestUtils } from '../utils/apiRequestUtils'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from './components/Spinner'
import { updateOnboardingChecklistDataThunk } from '../store/CompanySlice'
import { getUsersThunk, importUsersThunk } from '../store/UsersSlice'
import { SharedStepper } from '../shared/components/SharedStepper'
import { IoCloudUploadOutline, IoListOutline, IoCloudDoneOutline } from 'react-icons/io5'
import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import { createFileDownload } from '../utils/fileUtils'
import { FileUploadInput } from '../shared/components/FileUploadInput'
import PhinModal from './components/PhinModal'
import { STYLE } from '../frontendConsts'

function UsersImport ({ id }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { company, loaders, onboardingChecklist } = useSelector((state) => state.company)
  const { isLoadingComany } = loaders
  const { users, loaders: userLoaders } = useSelector((state) => state.users)
  const { isLoadingUsers, isLoadingImportUsers } = userLoaders

  const [step, setStep] = useState(0)
  const [parsedCSV, setParsedCSV] = useState([])
  const [selectModal, setSelectModal] = useState(false)

  const [selectedColumn, setSelectedColumn] = useState('')
  const [mappedField, setMappedField] = useState('')

  const [mappedColumns, setMappedColumns] = useState({})
  const [summaryInfo, setSummaryInfo] = useState({
    users: 0,
    conflicted: 0,
    importedColumns: [],
    validationMessages: []
  })

  const [selectableFields, setSelectableFields] = useState([])
  const [importedUsers, setImportedUsers] = useState([])
  const stepperArray = [
    {
      icon: IoCloudUploadOutline,
      label: 'Upload',
      tab: 0
    },
    {
      icon: IoListOutline,
      label: 'Match',
      tab: 1
    },
    {
      icon: IoCloudDoneOutline,
      label: 'Import to Phin',
      tab: 2
    }]

  const [inputFile, setInputFile] = useState(null)

  useEffect(() => {
    // Reset importer if a new file is uploaded
    setMappedColumns({})
    setImportedUsers([])
    if (company) {
      setSelectableFields(generateSelectableFields())
    }
  }, [parsedCSV])

  useEffect(() => {
    if (company) {
      setSelectableFields(generateSelectableFields())
    }
  }, [company])

  function generateSelectableFields (temporaryMappedColumns) {
    if (!temporaryMappedColumns) {
      temporaryMappedColumns = mappedColumns
    }

    const options = []
    const userStructure = JSON.parse(company.deviceStructure)
    for (const struct of userStructure) {
      const { label, field } = struct
      // If field is already mapped, don't add it to the selectable fields
      if (!Object.values(temporaryMappedColumns).includes(field)) {
        options.push({ text: `${label} (${field})`, value: field })
      }
    }

    return options
  }

  function hasDuplicateUsers (users, uniqueUsers) {
    return uniqueUsers.length !== users.length
  }

  const generateAndDownloadTemplateCsv = async () => {
    /** Grab all the possible CSV columns from the company  */
    const allPossibleColumns = JSON.parse(company.deviceStructure)
    const csvArray = []
    /** Create an array of all the possible columns (without all the extra stuff from the object)
     * Label is the most readable field
     */
    for (const item of allPossibleColumns) {
      /** Make sure to not include any extra , */
      csvArray.push(item.label.replace(',', ''))
    }

    /** The library we use for parsing into a csv Blob requires a 2D array as input
     *  where each line in the file is an array of Strings
     */
    return createFileDownload([csvArray], 'userImportTemplate')
  }

  function handleFixDuplicatedUsers (uniqueUsers, summary) {
    const validationMessages = summary.validationMessages
    const index = validationMessages.findIndex((item) => { return item.id === 1 })
    validationMessages[index].resolved = true

    const tempSummary = { ...summary }
    tempSummary.users = uniqueUsers.length
    tempSummary.validationMessages = validationMessages

    setSummaryInfo(tempSummary)
    setImportedUsers(uniqueUsers)
  }

  async function hasConflictedUsers (packagedUsers) {
    try {
      const res = await apiRequestUtils.post(`/api/companies/${id}/users/import/validate`, { users: packagedUsers })

      if (!res.ok) {
        const conflictedUsers = await res.json()
        return conflictedUsers
      } else {
        return []
      }
    } catch (err) {
      console.error(err)
      NotificationUtilities.sendErrorMessage('Failed to check for conflicted users! Please try to import again later')
    }
  }

  async function validateImport () {
    let packagedUsers = packageUsers(parsedCSV)
    const summary = {
      users: packagedUsers.length,
      conflicted: 0,
      importedColumns: Object.keys(mappedColumns),
      validationMessages: []
    }

    const conflictedUsers = await hasConflictedUsers(packagedUsers)
    if (conflictedUsers.length > 0) {
      summary.conflicted = conflictedUsers.length
      summary.users -= conflictedUsers.length
      packagedUsers = differenceBy(packagedUsers, conflictedUsers, 'email')
    }

    const uniqueUsers = uniqBy(packagedUsers, 'email')

    if (hasDuplicateUsers(packagedUsers, uniqueUsers)) {
      summary.validationMessages.push({
        id: 1,
        title: 'Duplicated Users',
        description: 'We can automatically fix this by removing any duplicated users and only keeping one instance of each.',
        handleMessage: () => handleFixDuplicatedUsers(uniqueUsers, summary),
        resolved: false
      })
    }

    setImportedUsers(packagedUsers)
    setSummaryInfo(summary)
  }

  function parseCSV (raw) {
    const csv = Papa.parse(raw, {
      header: true,
      skipEmptyLines: true,
      transformHeader: function (h) {
        return h.trim()
      }
    })

    if (csv.data.length === 0) {
      NotificationUtilities.sendWarningMessage('Your CSV is completely empty! Please make sure to edit and fill out your CSV first')
      return
    }

    const inputCols = Object.keys(csv.data[0])
    let malformed = false
    for (const col of inputCols) {
      if (!ValidationUtils.isNotWhiteSpace(col)) {
        malformed = true
      }
    }

    if (malformed) {
      NotificationUtilities.sendWarningMessage('You CSV appears to have a malformed header. Please make sure your CSV is valid before uploading')
      return
    }

    if (inputCols[inputCols.length - 1] === '') {
      inputCols.splice(-1)
    }

    setParsedCSV(csv.data)
  }

  function handleUploadedFile (file) {
    const reader = new FileReader()

    if (!file) {
      NotificationUtilities.sendWarningMessage('You must upload a CSV file!')
    }

    if (!file.name.includes('.csv')) {
      NotificationUtilities.sendWarningMessage('The file uploaded was not a CSV file. Please try again.')
      return
    }

    setInputFile(file)

    reader.onload = (event) => {
      const rawtext = event.target.result
      parseCSV(rawtext)
    }

    reader.readAsText(file)
  }

  function handleColumnSelect (column) {
    setSelectedColumn(column)
    toggleSelectModal()
  }

  function handleColumnRemoval (column) {
    const temporaryMappedColumns = { ...mappedColumns }

    delete temporaryMappedColumns[column]

    setMappedColumns(temporaryMappedColumns)

    // Recalculate selectable columns
    setSelectableFields(generateSelectableFields(temporaryMappedColumns))
  }

  function selectColumn () {
    const temp = { ...mappedColumns }

    setSelectableFields(selectableFields.filter((option) => { return option.value !== mappedField }))

    temp[selectedColumn] = mappedField

    setMappedField('')

    setMappedColumns(temp)
    toggleSelectModal()
  }

  function packageUsers (rawUsers) {
    const packagedUsers = []
    for (const user of rawUsers) {
      const tempUser = {}
      for (const mappedField of Object.entries(mappedColumns)) {
        if (mappedField[1] === 'email') {
          tempUser[mappedField[1]] = user[mappedField[0]].toLowerCase()
        } else {
          tempUser[mappedField[1]] = user[mappedField[0]]
        }
      }

      packagedUsers.push(tempUser)
    }

    return packagedUsers
  }

  async function importUsers () {
    const { success } = await dispatch(importUsersThunk({ id, importedUsers }))
    if (success) {
      if (!onboardingChecklist.hasUsers) {
        dispatch(updateOnboardingChecklistDataThunk(id, { hasUsers: true }))
      }
      NotificationUtilities.sendSuccessMessage(`Successfully imported ${summaryInfo.users} user(s)!`)
    }
  }

  function toggleSelectModal () {
    setSelectModal(!selectModal)
  }

  function previousStep () {
    if (step > 0) {
      setStep(step - 1)
    } else {
      history.goBack()
    }
  }

  async function nextStep () {
    switch (step) {
      case 0:
        if (parsedCSV.length >= 1) {
          setStep(1)
        } else {
          NotificationUtilities.sendWarningMessage('You must upload a valid CSV file before continuing')
        }
        break
      case 1:
        if (mappedColumns.length !== 0) {
          const values = Object.values(mappedColumns)

          let hasFirst = false
          let hasLast = false
          let hasEmail = false
          for (const val of values) {
            if (val === 'first') {
              hasFirst = true
            }

            if (val === 'last') {
              hasLast = true
            }

            if (val === 'email') {
              hasEmail = true
            }
          }

          if (hasFirst && hasLast && hasEmail) {
            validateImport()
            setStep(2)
            break
          }
        }
        NotificationUtilities.sendWarningMessage('You must import at least our three required fields (first, last and email)')
        break
      case 2:
        for (const message of summaryInfo.validationMessages) {
          if (!message.resolved) {
            NotificationUtilities.sendWarningMessage('You must resolve all issues before finishing the import')
            return
          }
        }

        await importUsers()
        await dispatch(getUsersThunk(id))
        history.goBack()
        break
    }
    return true
  }

  function renderColumns (parsedCSV) {
    const renderedColumns = []
    const csvColumns = Object.keys(parsedCSV[0])

    for (let i = 0; i < csvColumns.length; i++) {
      const column = csvColumns[i]

      const mappedColumn = mappedColumns[column]
      if (mappedColumn) {
        renderedColumns.push(
          <MDBListGroupItem key={i} color='success'>
            <div className='d-flex justify-content-between align-items-center'>
              <div>
                <h5>{column} {'>'} <strong>{mappedColumn}</strong></h5>
                {parsedCSV[0][column]}
              </div>
              <div className='align-items-center' style={{ maxHeight: '45px' }}>
                <ActionButton id={`${column}-button`} title='Remove' color='error' icon='times' onClick={() => handleColumnRemoval(column)} />
              </div>
            </div>
          </MDBListGroupItem>
        )
      } else {
        renderedColumns.push(
          <MDBListGroupItem key={i} color='warning'>
            <div className='d-flex justify-content-between align-items-center'>
              <div>
                <h5><strong>{column}</strong></h5>
                {parsedCSV[0][column]}
              </div>
              <div className='align-items-center' style={{ maxHeight: '45px' }}>
                <ActionButton id={`${column}-button`} title='Select' color='primary' icon='plus' onClick={() => handleColumnSelect(column)} />
              </div>
            </div>
          </MDBListGroupItem>
        )
      }
    }

    return renderedColumns
  }

  function renderFinalizationStepHeader () {
    const importedUsers = <><strong>{summaryInfo.users}</strong> user{summaryInfo.users > 1 || summaryInfo.users === 0 ? 's' : ''} will be imported to this client. </>
    let conflictedHeader

    if (summaryInfo.conflicted >= 1) {
      conflictedHeader = <><strong>{summaryInfo.conflicted}</strong> already exists and will be skipped. </>
    } else {
      conflictedHeader = <></>
    }

    return (
      <>
        {importedUsers}{conflictedHeader}The following information will be imported:
      </>
    )
  }

  function renderValidationMessages (messages) {
    if (messages.length === 0) {
      return <></>
    }

    let allMessagesResolved = true

    const renderedMessages = []
    for (const message of messages) {
      let actionButton = <></>
      if (message.resolved) {
        actionButton = <CheckIcon color='success' />
      } else {
        allMessagesResolved = false
        actionButton = <Button id='fix-duplicates-button' variant='contained' color='primary' onClick={message.handleMessage}>Fix</Button>
      }

      renderedMessages.push(
        <MDBListGroupItem color={message.resolved ? 'success' : 'warning'}>
          <div className='d-flex justify-content-between align-items-center'>
            <div>
              <h5><strong>{message.title}</strong></h5>
              {message.description}
            </div>
            <div className='align-items-center' style={{ maxHeight: '45px' }}>
              {actionButton}
            </div>
          </div>
        </MDBListGroupItem>
      )
    }

    let headerMessage
    if (allMessagesResolved) {
      headerMessage = 'All problems resolved! Your users are ready to be imported.'
    } else {
      headerMessage = 'We found some problems with your import. Choose below how you want to proceed and we\'ll handle it!'
    }

    return (
      <>
        <MDBRow className='mt-2'>
          <MDBCol>
            {headerMessage}
          </MDBCol>
        </MDBRow>
        <MDBRow className='mt-2'>
          <MDBCol>
            <MDBListGroup>
              {renderedMessages}
            </MDBListGroup>
          </MDBCol>
        </MDBRow>
      </>
    )
  }

  function renderStep () {
    switch (step) {
      case 0:
        return (
          <div className='phin-card-container'>
            <div className='phin-h4'>Upload Users</div>
            <MDBCardBody>
              <MDBRow>
                <MDBCol>
                  Upload a CSV file of your users below to get started. We'll handle sorting the data in the next step.
                </MDBCol>
              </MDBRow>
              <Grid sx={{ padding: 'var(--phin-s0) 0 var(--phin-s0)' }}>
                <FileUploadInput
                  file={inputFile}
                  setFile={handleUploadedFile}
                  acceptedFileTypes='.csv'
                />
              </Grid>
              <Button id='Download-Sample-CSV-Button' onClick={generateAndDownloadTemplateCsv}>Download Sample CSV</Button>
            </MDBCardBody>
          </div>
        )
      case 1:
        return (
          <div className='phin-card-container'>
            <div className='phin-h4'>Match and Validation</div>
            <div className='padding-top:1 padding-bottom:1'>
              Match your CSV columns to our importable fields
            </div>
            <MDBListGroup>
              {renderColumns(parsedCSV)}
            </MDBListGroup>
          </div>
        )
      case 2:
        return (
          <div className='phin-card-container'>
            <div className='phin-h4'>Finalize and Import</div>
            <div className='padding-top:1 padding-bottom:1'>
              Review any issues and finalize your import
            </div>
            {(isLoadingImportUsers) && (
              <Spinner />
            )}

            {(!isLoadingImportUsers) && (
              <>
                <div>
                  {renderFinalizationStepHeader()}
                </div>

                <ul className='mt-3'>
                  {summaryInfo.importedColumns.map((field, i) => { return <li key={i}>{field}</li> })}
                </ul>
                {renderValidationMessages(summaryInfo.validationMessages)}
              </>
            )}
          </div>
        )
    }
  }

  return (
    <div>
      <PhinModal
        isOpen={selectModal}
        size='medium'
        title='Field Mapping'
        close={toggleSelectModal}
        action={selectColumn}
        actionText='Confirm'
      >
        <div className='phin-flex-space-around-row'>
          <p>
            Select which of our fields you would like to map <strong>{selectedColumn}</strong> to
          </p>
        </div>
        <Grid
          container
          justifyContent='center'
          alignItems='center'
        >
          <Grid
            item
            xs={8}
          >
            <FormControl fullWidth variant='standard'>
              <InputLabel id='user-property-select-label'>Select Field</InputLabel>
              <Select
                labelId='user-property-select-label'
                id='user-property-select'
                label='Select Field'
                value={mappedField}
                onChange={(event) => setMappedField(event.target.value)}
                options={selectableFields}
              >
                {selectableFields.map((option) => {
                  return <MenuItem key={option} value={option.value}>{option.text}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </PhinModal>

      <MDBRow className='justify-content-center'>
        <h1>User Importer</h1>
      </MDBRow>

      {(!company || isLoadingComany ||
        !users || isLoadingUsers) && (
          <Spinner />
      )}

      {(company && !isLoadingComany &&
        users && !isLoadingUsers) && (
          <>
            <MDBRow className='justify-content-center pt-3 pb-2'>
              <SharedStepper activeTab={step} setActiveTab={setStep} steps={stepperArray} linear />
            </MDBRow>
            <MDBRow className='mt-4'>
              <MDBCol>
                {renderStep()}
              </MDBCol>
            </MDBRow>
            <div className='phin-flex-end padding-top:0 '>
              <span className='padding-right:-1'>
                <Button
                  id='cancel-and-back-button'
                  size='large'
                  aria-label='Cancel/Back Button'
                  color='primary'
                  style={STYLE.BUTTONS.TERTIARY}
                  disabled={isLoadingImportUsers}
                  onClick={previousStep}
                >{step === 0 ? 'Cancel' : 'Back'}
                </Button>
              </span>
              <Button
                id='next-and-finish-button'
                size='large'
                aria-label='Next/Finish Button'
                color='primary'
                variant='contained'
                disabled={isLoadingImportUsers}
                onClick={async () => { await nextStep() }}
              >{step === 2 ? 'Finish' : 'Next'}
              </Button>
            </div>
          </>
      )}
    </div>
  )
}

export default UsersImport
