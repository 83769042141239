import { FormGroup } from '@mui/material'
import { styled } from '@mui/material/styles'
import Switch from '@mui/material/Switch'

function PhinStyledSwitch ({ id, ariaLabel, checked, onClickFunction }) {
  const PhinSwitch = styled(Switch)(({ theme }) => ({
    width: 60,
    height: 34,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 26
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(24px)'
      }
    },
    '& .MuiSwitch-switchBase': {
      padding: 4,
      '&.Mui-checked': {
        transform: 'translateX(24px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#202020'
        }
      }
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 26,
      height: 26,
      borderRadius: '50%',
      transition: theme.transitions.create(['width'], {
        duration: 200
      })
    },
    '& .MuiSwitch-track': {
      borderRadius: 34 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? 'rgba(255,255,255,.35)'
          : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box'
    }
  }))

  return (
    <FormGroup>
      <PhinSwitch inputProps={{ id, 'data-testid': 'email-edit-toggle-btn', 'aria-label': ariaLabel }} checked={checked} onClick={() => onClickFunction()} />
    </FormGroup>
  )
}

export default PhinStyledSwitch
