import { Button, Grid, Typography } from '@mui/material'
import { PhinCard } from '../../PhinCard'
import { IoTrashOutline } from 'react-icons/io5'
import { generateLastMonthBillingDateRange } from './connectwiseUtils'
import { useEnableIntegrationMutation, useDisableIntegrationMutation, useDisconnectIntegrationMutation } from '../../../../store/ConnectWiseApiSlice'
import { integrationTypes } from '../../../../frontendConsts'
import { useSelector, useDispatch } from 'react-redux'
import NotificationUtilities from '../../notifications/notificationUtils'
import { getPartnerIntegrationsThunk } from '../../../../store/PartnerIntegrationSlice'
import { useState } from 'react'
import PhinModal from '../../PhinModal'

export function ConnectWiseFeatureSetup ({ partnerId }) {
  const dispatch = useDispatch()
  const cwIntegration = useSelector(state => state.partnerIntegrations?.partnerIntegrationsMap?.[integrationTypes.CONNECTWISE])

  const [disconnectCWIntegration] = useDisconnectIntegrationMutation()

  const billingIsEnabled = cwIntegration?.billingIsEnabled
  const serviceDeskIsEnabled = cwIntegration?.serviceDeskIsEnabled

  const [disconnectModal, setDisconnectModal] = useState(false)

  async function handleDisconnect () {
    const result = await disconnectCWIntegration({ partnerId })
    if (result.error) {
      NotificationUtilities.sendErrorMessage('Failed to disconnect ConnectWise')
    } else {
      dispatch(getPartnerIntegrationsThunk(partnerId))
      NotificationUtilities.sendSuccessMessage('Successfully disconnected ConnectWise')
      // Scroll to top of page after disconnecting
      window.scrollTo(0, 0)
    }
  }

  return (
    <>
      <PhinModal
        isOpen={disconnectModal}
        title='Disconnect ConnectWise Integration'
        close={() => setDisconnectModal(false)}
        closeText='Cancel'
        action={handleDisconnect}
        actionColor='error'
        actionText='Disconnect'
      >
        <p>Are you sure you want to disconnect the ConnectWise integration?</p>
      </PhinModal>
      <PhinCard title='ConnectWise Billing'>
        <Typography>Enable the ConnectWise Manage - Phin integration. Automatically send usage data for your clients' companies directly to ConnectWise Manage.</Typography>
        <h6 className='margin-top:1'>Permissions: </h6>
        <ul style={{ marginBottom: '0' }}>
          <li>Companies - Company Maintenance: Inquire Level (All), Manage Attachments: Add Level(All), Inquire Level (All)  </li>
          <li>Finance - Agreements: Inquiry Level, Agreements: Edit Level</li>
        </ul>
        <Grid container flex flexDirection='row' alignItems='center' gap={1} sx={{ padding: '1rem', background: 'var(--blue-0)', maxWidth: 'fit-content', marginTop: '1.5rem' }}>
          <Typography fontWeight='bold' variant='subtitle1'>Billing Period: </Typography>
          <Typography variant='subtitle1'>{generateLastMonthBillingDateRange()}</Typography>
        </Grid>
        <ToggleIntegrationButton
          partnerId={partnerId}
          integrationId={integrationTypes.CW_BILLING}
          isIntegrationEnabled={billingIsEnabled}
        />
      </PhinCard>
      <PhinCard title='ConnectWise Service Desk'>
        <Typography>Enable the ConnectWise Manage Service Desk - Phin integration. Automatically create service desk tickets for reported phishing emails. The Report Phishing Button must be enabled in at least one company.</Typography>
        <h6 className='margin-top:1'>Permissions: </h6>
        <ul>
          <li>Companies - Company Maintenance: Inquire Level (All), Manage Attachments: Add Level(All), Inquire Level (All)</li>
          <li>Service Desk - Service Tickets: Add Level (All), Inquire Level (All)</li>
        </ul>
        <ToggleIntegrationButton
          partnerId={partnerId}
          integrationId={integrationTypes.CW_SERVICE_DESK}
          isIntegrationEnabled={serviceDeskIsEnabled}
        />
      </PhinCard>
      <PhinCard title='Disconnect'>
        <Typography>Your mapping will be saved, but you will no longer have access to push to CW. </Typography>
        <Button
          onClick={() => setDisconnectModal(true)}
          sx={{ width: '200px', marginTop: '1.5rem', color: 'red', borderColor: 'red' }}
          variant='outlined'
          startIcon={<IoTrashOutline />}
        >
          Disconnect
        </Button>
      </PhinCard>
    </>

  )
}

function ToggleIntegrationButton ({ partnerId, integrationId, isIntegrationEnabled }) {
  const [enableCWIntegration, { isLoading: isLoadingEnable }] = useEnableIntegrationMutation()
  const [disableCWIntegration, { isLoading: isLoadingDisable }] = useDisableIntegrationMutation()
  const dispatch = useDispatch()

  async function handleToggleIntegration ({ integrationId, partnerId }) {
    let result, action
    if (isIntegrationEnabled) {
      action = 'disable'
      result = await disableCWIntegration({ partnerId, integrationId })
    } else {
      action = 'enable'
      result = await enableCWIntegration({ partnerId, integrationId })
    }

    if (result.error) {
      const errorMessage = result.error.data.error
      NotificationUtilities.sendErrorMessage(errorMessage)
    } else {
      dispatch(getPartnerIntegrationsThunk(partnerId))
      NotificationUtilities.sendSuccessMessage(`Successfully ${action}d ConnectWise ${integrationId}`)
    }
  }

  return (
    <Button
      id={`toggle-cw-${integrationId}-integration-button`}
      aria-label='Toggle ConnectWise Integration Button'
      sx={{ width: '200px', marginTop: '1.5rem' }}
      color={isIntegrationEnabled ? 'error' : 'primary'}
      size='large'
      variant='contained'
      onClick={() => handleToggleIntegration({ partnerId, integrationId })}
      disabled={isLoadingEnable || isLoadingDisable}
    >
      {isIntegrationEnabled ? 'Disable' : 'Enable'}
    </Button>
  )
}
