import { Pie, Line } from 'react-chartjs-2'
import React from 'react'
import { MDBCol } from 'mdb-react-ui-kit'
import { IoCheckmarkCircleSharp } from 'react-icons/io5'

const chartBaseStyles = {
  title: {
    fontSize: 20,
    fontFamily: 'Roboto',
    fontColor: '#212529'
  },
  legend: {
    fontColor: '#212529',
    fontFamily: 'Roboto',
    fontSize: 13
  },
  axisColor: 'rgba(0, 0, 0, 0.3)',
  colors: ['#4285F4', '#aa66cc', '#ff4444', '#33b5e5', '#2BBBAD']
}

function renderTooltip (tooltip, canvas, chartTitle) {
  // Tooltip Element
  const tooltipEl = document.getElementById(chartTitle)

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0
    return
  }

  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform')
  if (tooltip.yAlign) {
    tooltipEl.classList.add(tooltip.yAlign)
  } else {
    tooltipEl.classList.add('no-transform')
  }

  function getBody (bodyItem) {
    return bodyItem.lines
  }

  // Build tooltip
  if (tooltip.body) {
    const titleLines = tooltip.title || []
    const bodyLines = tooltip.body.map(getBody)

    let innerHtml = '<thead>'

    titleLines.forEach((title) => {
      innerHtml += `<tr><th>${title}</th></tr>`
    })
    innerHtml += '</thead><tbody>'

    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i]
      let style = 'background:' + colors.backgroundColor
      style += '; border-color:' + colors.borderColor
      style += '; border-width: 2px'
      const span = `<span class="chart-tooltip-key" style="${style}"></span>`
      innerHtml += `<tr><td>${span + body}</td></tr>`
    })

    innerHtml += '</tbody>'

    const tableRoot = tooltipEl.querySelector('table')
    tableRoot.innerHTML = innerHtml
  }

  const positionY = canvas.offsetTop
  const positionX = canvas.offsetLeft

  tooltipEl.style.opacity = 1
  tooltipEl.style.left = positionX + tooltip.caretX + 'px'
  tooltipEl.style.top = positionY + tooltip.caretY + 'px'
  tooltipEl.style.fontFamily = tooltip._bodyFontFamily
  tooltipEl.style.fontSize = tooltip.bodyFontSize
  tooltipEl.style.fontStyle = tooltip._bodyFontStyle
  tooltipEl.style.padding = tooltip.yPadding + 'px ' + tooltip.xPadding + 'px'
}

// Display Functions
export function generatePieChart (templateData, chartTitle) {
  // If there is no data replace with an equally sized element
  if (Object.keys(templateData).length === 0) {
    return (
      <MDBCol className='d-flex flex-column justify-content-center align-items-center p-1 text-center h-100'>
        <IoCheckmarkCircleSharp size='3.5em' color='#3CA03A' />
        <h5>Congrats! No templates have been clicked so far in this campaign!</h5>
      </MDBCol>
    )
  }

  // Property to hold canvas for this chart, is generated in below callback
  const chartCanvas = null

  const chartData = (canvas) => {
    const data = []
    const labels = []

    // Populate the chartData obj
    for (const [name, count] of Object.entries(templateData)) {
      labels.push(name)
      data.push(count)
    }

    return {
      labels,
      datasets: [{
        data,
        backgroundColor: chartBaseStyles.colors
      }]
    }
  }

  const chartOptions = {
    response: true,
    tooltips: {
      enabled: false,
      custom: (tooltip) => { renderTooltip(tooltip, chartCanvas, chartTitle) }
    },
    title: {
      display: true,
      fontSize: chartBaseStyles.title.fontSize,
      text: chartTitle,
      fontColor: chartBaseStyles.title.fontColor
    },
    legend: {
      labels: {
        fontColor: chartBaseStyles.legend.fontColor,
        fontFamily: chartBaseStyles.legend.fontFamily,
        fontSize: chartBaseStyles.legend.fontSize
      }
    }
  }

  return (
    <>
      <Pie data={chartData()} options={chartOptions} height={250} />
      <div id={chartTitle} className='chart-tooltip'>
        <table />
      </div>
    </>
  )
}

export function generateLineGraph (graphData, chartitle) {
  const data = {
    labels: graphData.labels,
    datasets: [
      {
        label: 'Percentage of Users Phished',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: graphData.data,
        yAxisId: 'y',
        xAxisId: 'x'
      }
    ]
  }
  const options = {
    scales: {
      y: {
        position: 'left',
        display: true,
        title: {
          display: true,
          text: '% of users phished'
        },

        ticks: {
          callback: function (value, index, values) {
            return `${value}%`
          }
        }
      },
      x: {
        position: 'bottom',
        title: {
          display: true,
          text: 'Months'
        }
      }
    }

  }

  return (<Line data={data} options={options} />)
}
