import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ValidationUtils from '../utils/validationUtils'
import CampaignTypeSelector from './components/Campaigns/CampaignTypeSelector'
import NotificationUtilities from './components/notifications/notificationUtils'

import { navigationRoutes } from '../frontendConsts.js'
import { resetAllCampaignFrequencyChoicesAction } from '../store/CampaignFrequencySlice'
import { useDispatch } from 'react-redux'
import { Grid, TextField } from '@mui/material'
import { StepperButtonTray } from './components/StepperButtonTray.js'
import { PhinCard } from './components/PhinCard.js'

function CampaignCreator ({ companyId }) {
  const history = useHistory()
  const dispatch = useDispatch()

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [campaignType, setCampaignType] = useState('fixed length')

  useEffect(() => {
    document.title = 'Phin Security | Campaign Creator'
  }, [])

  async function bundleCampaign () {
    // Validate inputs
    if (!ValidationUtils.isNotWhiteSpace(name)) {
      NotificationUtilities.sendWarningMessage('Campaign must have a name')
      return
    }

    if (!ValidationUtils.isNotWhiteSpace(campaignType)) {
      NotificationUtilities.sendWarningMessage('Campaign must have a type')
      return
    }

    if (!ValidationUtils.isNotWhiteSpace(description)) {
      NotificationUtilities.sendWarningMessage('Campaign must have a description')
      return
    }

    await dispatch(resetAllCampaignFrequencyChoicesAction())

    history.push(`/companies/${companyId}${navigationRoutes.CAMPAIGNEDITOR}`, { campaignName: name, campaignType, campaignDescription: description })
  }

  return (
    <div>
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='flex-start'
      >
        <div className='phin-h2 padding-bottom:-2'>Campaign Creator</div>
        <p>Here we'll set up the basic information of the campaign as well as it's type. Then we'll move to the editor to finish creation! Hover over the question bubbles for
          specific information about each step.
        </p>
      </Grid>

      <PhinCard
        title='Campaign Info'
      >
        <TextField
          id='campaign-name-field'
          variant='outlined'
          label='Campaign Name'
          name='campName'
          value={name}
          onChange={(event) => {
            setName(event.target.value)
          }}
          sx={{ marginBottom: 'var(--phin-s2)' }}
        />

        <TextField
          id='campaign-description-field'
          variant='outlined'
          label='Campaign Description'
          name='campDesc'
          value={description}
          multiline
          onChange={(event) => {
            setDescription(event.target.value)
          }}
          minRows={5}
          maxRows={8}
        />

      </PhinCard>
      <CampaignTypeSelector
        campaignType={campaignType}
        setCampaignType={setCampaignType}
      />

      <StepperButtonTray cancel={() => { history.goBack() }} action={bundleCampaign} />
    </div>
  )
}

export default CampaignCreator
