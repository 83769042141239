import { Alert, Button, Chip, Divider, Grid, Skeleton } from '@mui/material'
import { NOTIFICATIONS } from '../../frontendConsts.js'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import {
  getPhinPricingPlansThunk, upgradeSelfServicePlanThunk
} from '../../store/PartnerBillingSlice'
import '../css/manageBilling.css'
import { useHistory } from 'react-router-dom'
import { notificationOpenedThunk } from '../../store/MuiNotificationsSlice'
import { DateTime } from 'luxon'
import { calculateNextBillingDate } from './components/ChargebeePlanBillingWidget.js'

export function renderPhinPlanSkeletons () {
  const pricingSkeletons = []

  for (let i = 0; i < 5; i++) {
    pricingSkeletons.push(
      <Skeleton sx={{ bgcolor: '#c4c4c4', margin: 'var(--phin-s2)' }} variant='rectangle' width={250} height={400} />
    )
  }
  return pricingSkeletons
}

export function PartnerManageBillingPlanPage ({ partnerId }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { phinPricingPlans, loaders } = useSelector((state) => state.partnerBilling)
  const { isLoadingPhinPricingPlans } = loaders
  const { partner } = useSelector((state) => state.partner)
  const { authorization } = useSelector((state) => state.auth)
  const [selectedPlan, setSetSelectedPlan] = useState([])

  async function upgradeFreeTrial () {
    await dispatch(upgradeSelfServicePlanThunk({ partnerId: partner.id, pricingPlanId: selectedPlan.id }))
    history.push(`/partners/${partnerId}/billing`)
    dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.SUCCESS, alertMessage: 'Your Plan has been upgraded! Welcome aboard!' }))
  }

  const isBillingAdmin = authorization.billingAdminPartnerIds.includes(partnerId)

  useEffect(() => {
    if (!phinPricingPlans) {
      dispatch(getPhinPricingPlansThunk({ partnerId }))
    }
  }, [])

  useEffect(() => {
    if (phinPricingPlans) {
      setSetSelectedPlan(phinPricingPlans[0])
    }
  }, [phinPricingPlans])

  function renderPhinPricingPlans () {
    const pricingPlanOptions = []

    for (const phinPricingPlan of phinPricingPlans) {
      pricingPlanOptions.push(
        <div
          className={(selectedPlan.id === phinPricingPlan.id) ? 'plan-selected' : 'plan-card'}
          onClick={() => setSetSelectedPlan(phinPricingPlan)}
          id={phinPricingPlan.id}
          key={phinPricingPlan.id}
        >
          <div className={(selectedPlan === phinPricingPlan.id) ? 'plan-heading-selected' : 'plan-heading'}>
            <p className='phin-body-text'>{(phinPricingPlan.type)}</p>
            {(phinPricingPlan.type === 'Annual') && (<p className='phin-body-text-small-gray'>(billed monthly)</p>)}
          </div>

          <div className='plan-contents'>
            <div>
              <p className='phin-h5'>{(phinPricingPlan.name)}</p>
              <p className='phin-body-text'> includes {phinPricingPlan.minimumBillableUsers} users + 1 internal company</p>
            </div>
            <div>
              <p className='phin-h5'>Pricing</p>
              <p className='phin-h4'>${phinPricingPlan.price}</p>
              <p className='phin-body-text-small'>+${phinPricingPlan.perUserCost} / additional user</p>
            </div>
            <div className='plan-discount'>
              {(phinPricingPlan.discountAmount !== 0) && (
                <Chip
                  sx={{
                    backgroundColor: 'var(--light-blue)',
                    color: 'white',
                    fontWeight: '500',
                    fontStyle: 'Roboto, sans-serif',
                    fontSize: '.833rem'
                  }}
                  label={`${phinPricingPlan.discountAmount}% Discount`}
                />
              )}
            </div>
          </div>
        </div>

      )
    }

    return pricingPlanOptions
  }

  return isBillingAdmin
    ? (
      <div className='single-page-test'>
        <div>
          <div className='phin-h2'>Manage Plan</div>
        </div>
        {(isLoadingPhinPricingPlans || !phinPricingPlans)
          ? (
            <div className='plan-container'>
              {renderPhinPlanSkeletons()}
            </div>
            )
          : (
            <div className='plan-container-center'>
              <div className='plan-container'>
                {renderPhinPricingPlans()}
              </div>
            </div>
            )}

        {(isLoadingPhinPricingPlans)
          ? (
            <div className='plan-selection-summary'>
              <Skeleton sx={{ bgcolor: '#c4c4c4', marginBottom: '3rem' }} variant='rectangle' width={1600} height={200} />
            </div>
            )
          : (
            <div>
              <Divider />
              <div className='padding:3'>
                <div className='d-flex justify-content-between align-content-start' style={{ gap: '20px' }}>
                  <p className='phin-h4'>Estimated Monthly Bill Total</p>
                  <p className='phin-h4'>${selectedPlan.price}</p>
                </div>
                <div className='d-flex justify-content-between align-content-start'>
                  <p className='phin-h4'>Due on</p>
                  <p className='phin-h4'>{calculateNextBillingDate({ billingStartDate: DateTime.now(), currentDateTime: DateTime.now() })}</p>
                </div>
              </div>
            </div>
            )}

        <Grid container spacing={2} justifyContent='end' alignItems='center'>
          <Grid item>
            <Button
              id='cancel-button'
              aria-label='Cancel Operation Button'
              size='large'
              color='primary'
              variant='outlined'
              disableElevation
              onClick={() => history.push(`/partners/${partnerId}/billing`)}
            > Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              id='upgrade-free-trial-button'
              aria-label='Upgrade Free Trial Button'
              size='large'
              color='primary'
              variant='contained'
              disableElevation
              onClick={() => upgradeFreeTrial()}
            > Upgrade
            </Button>
          </Grid>
        </Grid>
      </div>
      )
    : (
      <Alert severity='warning' variant='outlined' sx={{ background: 'var(--phin-light-yellow)' }}>
        <div>You do not have permission to view this page. Please reach out to your administrator for permission or contact <a href='https://www.phinsec.io/knowledge/kb-tickets/new'>Phin Support.</a></div>
      </Alert>
      )
}
