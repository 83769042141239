import { GridFooter, GridFooterContainer } from '@mui/x-data-grid'
import { Grid, Typography } from '@mui/material'
import { DateTime } from 'luxon'

function countMappedCompanies (companyMappings) {
  return companyMappings?.rows.filter((row) => row.cwCompanyId).length
}

function getMostRecentUpdate (companyMappings) {
  return companyMappings?.rows.reduce((acc, row) => {
    const lastUpdatedDateTime = row.lastUpdated && DateTime.fromSeconds(row.lastUpdated._seconds)
    if (lastUpdatedDateTime > acc) {
      return lastUpdatedDateTime
    }
    return acc
  }, null)
}

export function TableFooter ({ companyMappings }) {
  const lastUpdated = getMostRecentUpdate(companyMappings)
  const mappedCompanies = countMappedCompanies(companyMappings)
  const totalCompanies = companyMappings?.rows.length

  return (
    <GridFooterContainer>
      <Grid container maxWidth='50%' direction='row' alignItems='center' gap={8} marginLeft={2}>
        <Grid item>
          <Grid container direction='row' alignItems='center' gap={1}>
            <Typography><strong>Mapping Saved:</strong></Typography>
            {lastUpdated === 'loading'
              ? <Typography color='var(--phin-gray)'>Loading...</Typography>

              : <Typography className='phin-body-text'>
                {lastUpdated?.toLocal().toLocaleString(DateTime.DATETIME_MED)}
              </Typography>}
          </Grid>
        </Grid>
        <Typography>
          {mappedCompanies} of {totalCompanies} companies mapped
        </Typography>
      </Grid>

      <GridFooter />
    </GridFooterContainer>
  )
}
