import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { UserSyncActionButtons } from './UserSyncActionButtons'
import { updateStagedUserStatusThunk } from '../../../store/StagedUsersSlice'
import { updateStagedGroupStatusThunk } from '../../../store/StagedGroupsSlice'
import { userStatuses, groupStatuses, operationTypes } from '../../../frontendConsts.js'
import '../../css/integrationsDetail.css'
import '../../css/integrationCard.css'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Button, Skeleton } from '@mui/material'

const sampleStagedUsers = [
  {
    email: 'User1@outlook.com',
    first: 'Test',
    last: 'User1',
    id: 3
  },
  {
    email: 'User2@outlook.com',
    first: 'Test',
    last: 'User2',
    id: 2
  },
  {
    email: 'User3@outlook.com',
    first: 'Test',
    last: 'User3',
    id: 1
  }
]

const sampleStagedGroups = [
  {
    displayName: 'Group 1',
    stagedMembers: ['1']
  },
  {
    displayName: 'Group 2',
    stagedMembers: []
  },
  {
    displayName: 'Group 3',
    stagedMembers: []
  }
]

export function StagedUserSync ({
  companyId, haveConsented, isStagingMode
}) {
  const dispatch = useDispatch()
  const { stagedUsers, loaders: stagedUsersLoaders } = useSelector((state) => state.stagedUsers)
  const { loadingStagedUsers } = stagedUsersLoaders
  const { stagedGroups, loaders: stagedGroupsLoaders } = useSelector((state) => state.stagedGroups)
  const { loadingStagedGroups } = stagedGroupsLoaders
  const { loaders: userSyncSliceLoaders } = useSelector((state) => state.userSync)
  const { loadingUserSyncStatus } = userSyncSliceLoaders
  const [tableType, setTableType] = useState('users')
  const [tablePageSize, setTablePageSize] = useState(10)

  const renderOperationType = (opType) => {
    if (opType === operationTypes.ADD) {
      return 'Add'
    } else if (opType === operationTypes.REMOVE) {
      return 'Remove'
    } else {
      return 'Update'
    }
  }

  const toggleIgnoreStagedUser = ({ stagedUserId, status }) => {
    const updatedStatus = status === userStatuses.ACTIVE ? userStatuses.SLEPT : userStatuses.ACTIVE
    dispatch(updateStagedUserStatusThunk({ companyId, stagedUserId, updatedFields: { status: updatedStatus } }))
  }

  const toggleIgnoreStagedGroup = ({ stagedGroupId, status }) => {
    const updatedStatus = status === groupStatuses.ACTIVE ? groupStatuses.SLEPT : groupStatuses.ACTIVE
    dispatch(updateStagedGroupStatusThunk({ companyId, stagedGroupId, updatedFields: { status: updatedStatus } }))
  }

  const renderStageUserToggleButton = (stagedUser) => {
    return (
      <Button
        id={`toggle-staged-user-status-button-${stagedUser.id}`}
        aria-label={stagedUser.status === userStatuses.SLEPT
          ? 'Toggle Staged User to Active'
          : 'Toggle Staged User to Slept'}
        variant={stagedUser.status === userStatuses.SLEPT ? 'outlined' : 'contained'}
        onClick={() => toggleIgnoreStagedUser({ stagedUserId: stagedUser.id, status: stagedUser.status })}
      >
        {stagedUser.status === userStatuses.SLEPT ? 'Include' : 'Sleep'}
      </Button>
    )
  }

  const renderStagedGroupToggleButton = (stagedGroup) => {
    return (
      <Button
        id={`toggle-staged-group-status-button-${stagedGroup.id}`}
        aria-label={stagedGroup.status === groupStatuses.SLEPT
          ? 'Toggle Staged Group to Active'
          : 'Toggle Staged Group to Slept'}
        variant={stagedGroup.status === groupStatuses.SLEPT ? 'outlined' : 'contained'}
        onClick={() => toggleIgnoreStagedGroup({ stagedGroupId: stagedGroup.id, status: stagedGroup.status })}
      >
        {stagedGroup.status === groupStatuses.SLEPT ? 'Include' : 'Sleep'}
      </Button>
    )
  }

  const stagedUserColumns = [
    {
      headerName: 'Email',
      field: 'email',
      width: 250
    },
    {
      headerName: 'First',
      field: 'first',
      width: 120
    },
    {
      headerName: 'Last',
      field: 'last',
      width: 120
    },
    {
      headerName: 'Operation',
      field: 'operationType',
      width: 100,
      valueGetter: (params) => { return renderOperationType(params.row.operationType) }
    },
    {
      headerName: 'Action',
      field: 'action',
      width: 100,
      renderCell: (params) => renderStageUserToggleButton(params.row),
      sortable: false,
      filterable: false,
      disableExport: true
    }
  ]

  const stagedGroupColumns = [
    {
      headerName: 'Display Name',
      field: 'displayName',
      flex: 3
    },
    {
      headerName: 'Operation',
      field: 'operationType',
      flex: 1,
      valueGetter: (params) => { return renderOperationType(params.row.operationType) }
    },
    {
      headerName: 'Action',
      field: 'action',
      flex: 1,
      renderCell: (params) => renderStagedGroupToggleButton(params.row),
      sortable: false,
      filterable: false,
      disableExport: true
    }
  ]

  return (
    <div className='right' style={haveConsented ? {} : { filter: 'opacity(.2)', pointerEvents: 'none' }}>
      <div className='tableTabSection'>
        <div className='tableTabGroup'>
          <Button
            onClick={() => setTableType('users')}
            variant={tableType === 'users' ? 'contained' : 'outlined'}
            sx={{ mr: 2 }}
          >Users
          </Button>
          <Button
            onClick={() => setTableType('groups')}
            variant={tableType === 'groups' ? 'contained' : 'outlined'}
          >
            Groups
          </Button>
        </div>
        <div className='phinCardTitle'>
          User Sync {isStagingMode ? 'Staging' : ''}
        </div>

      </div>

      {(haveConsented && (loadingStagedUsers || !stagedUsers || loadingStagedGroups || !stagedGroups || loadingUserSyncStatus)) && (
        <div className='margin-top:0' style={{ height: '100%' }}>
          <Skeleton variant='rounded' width='100%' height='100%' />
        </div>
      )}

      {(!haveConsented || (!loadingStagedUsers && stagedUsers && !loadingStagedGroups && stagedGroups && !loadingUserSyncStatus)) && (
        <div className='margin-top:0' style={{ height: '100%' }}>
          {tableType === 'users' && (
            <DataGrid
              style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
              className='DataGrid'
              initialState={{
                sorting: {
                  sortModel: [{ field: 'last', sort: 'asc' }]
                }
              }}
              rows={haveConsented ? stagedUsers : sampleStagedUsers}
              columns={stagedUserColumns}
              getRowClassName={(params) => params.row.status === userStatuses.SLEPT ? 'slept' : ''}
              pageSize={tablePageSize}
              onPageSizeChange={(newSize) => setTablePageSize(newSize)}
              rowsPerPageOptions={[10, 25, 50]}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: {
                    debounceMs: 500,
                    id: 'staged-users-table-search',
                    'aria-label': 'Search'
                  },
                  printOptions: { disableToolbarButton: true },
                  csvOptions: {
                    fileName: 'StagedUsers',
                    delimiter: ','
                  }
                }
              }}
              disableSelectionOnClick
              disableColumnSelector
              disableDensitySelector
              disableColumnFilter
              disableColumnMenu
            />
          )}
          {tableType === 'groups' && (
            <DataGrid
              style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
              className='DataGrid'
              initialState={{
                sorting: {
                  sortModel: [{ field: 'displayName', sort: 'asc' }]
                }
              }}
              rows={haveConsented ? stagedGroups : sampleStagedGroups}
              columns={stagedGroupColumns}
              getRowClassName={(params) => params.row.status === groupStatuses.SLEPT ? 'slept' : ''}
              pageSize={tablePageSize}
              onPageSizeChange={(newSize) => setTablePageSize(newSize)}
              rowsPerPageOptions={[10, 25, 50]}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: {
                    debounceMs: 500,
                    id: 'staged-groups-table-search',
                    'aria-label': 'Search'
                  },
                  printOptions: { disableToolbarButton: true },
                  csvOptions: {
                    fileName: 'StagedGroups',
                    delimiter: ','
                  }
                }
              }}
              disableSelectionOnClick
              disableColumnSelector
              disableDensitySelector
              disableColumnFilter
              disableColumnMenu
            />
          )}

        </div>
      )}

      <UserSyncActionButtons companyId={companyId} stagedUsers={stagedUsers} />
    </div>
  )
}
