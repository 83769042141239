import React, { useEffect, useState } from 'react'
import {
  MDBCol, MDBRow, MDBCard
} from 'mdb-react-ui-kit'
import Moment from 'moment'
import Papa from 'papaparse'
import AddUserModal from './components/AddUserModal'

import NotificationUtilities from './components/notifications/notificationUtils'

import ValidationUtils from '../utils/validationUtils'

import { groupStatuses, navigationRoutes, orgLevels } from '../frontendConsts.js'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from './components/Spinner'
import { addUserThunk, bulkSleepThunk, bulkWelcomeThunk, deleteUsersThunk, setEditingUserAction } from '../store/UsersSlice'
import { setUserCountAction, removeAdminThunk, addAdminThunk, incrementUserCountAction, updateOnboardingChecklistDataThunk, getAdminAccessibleCompaniesThunk } from '../store/CompanySlice'

import './css/users.css'

import { apiRequestUtils } from '../utils/apiRequestUtils'
import { useHistory } from 'react-router-dom'
import WelcomeUsersModal from './components/WelcomeUsersModal'
import { getWelcomeEmailThunk } from '../store/WelcomeEmailSlice'

import { TabContext, TabPanel, TabList } from '@mui/lab/'
import { Box, Button, Checkbox, Grid, IconButton, Tab, Tooltip, TextField } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { IoBed, IoCheckmarkCircle, IoEye, IoPaperPlane, IoPencil, IoSunny, IoTrash, IoWarning } from 'react-icons/io5'
import PhinModal from './components/PhinModal.js'

export function UsersPage ({ id }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const [addAdminModal, setAddAdminModal] = useState(false)
  const [addUserModal, setAddUserModal] = useState(false)
  const [deleteUsersModal, setDeleteUsersModal] = useState(false)
  const [deleteAdminModal, setDeleteAdminModal] = useState(false)
  const [welcomeUsersModal, setWelcomeUsersModal] = useState(false)
  const [sleepUsersModal, setSleepUsersModal] = useState(false)
  const [wakeUsersModal, setWakeUsersModal] = useState(false)
  const [selectedAdmin, setSelectedAdmin] = useState('')
  const [selectedGroupId, setSelectedGroupId] = useState()
  const [groupTabView, setGroupTabView] = useState('overview')
  const [bulkUsers, setBulkUsers] = useState([])
  const [newUFirstName, setNewUFirstName] = useState('')
  const [newULastName, setNewULastName] = useState('')
  const [newUEmail, setNewUEmail] = useState('')
  const [user, setUser] = useState({})
  const [allUsersSelected, setAllUsersSelected] = useState(false)
  const [tablePageSize, setTablePageSize] = useState(10)

  const today = Moment()
  const [sendTime, setSendTime] = useState(today)
  const [sendDate, setSendDate] = useState(today)

  const { users, groups } = useSelector((state) => state.users)
  const { company, onboardingChecklist, adminAccess } = useSelector((state) => state.company)
  const { hasUserSync } = useSelector((state) => state.integrations)

  const [currentTab, setCurrentTab] = useState('1')

  const toggleBetweenTabs = (event, newValue) => {
    setCurrentTab(newValue)
  }

  useEffect(() => {
    dispatch(getWelcomeEmailThunk(id, orgLevels.COMPANY))
  }, [])

  const userColumns = [
    {
      headerName: (
        <Checkbox
          id='selectAllBox'
          aria-label='Select All Users Checkbox'
          checked={allUsersSelected}
          onClick={() => selectAllUsers()}
        />
      ),
      field: 'id',
      renderCell: (params) => {
        return (
          <Checkbox
            id={`user-${params.row.email}-checkbox`}
            aria-label='User Checkbox'
            checked={searchBulkUserList(params.row.id)}
            onClick={() => updateBulkUsers(params.row)}
          />
        )
      },
      filterable: false,
      sortable: false,
      flex: 0.5
    },
    {
      headerName: 'Last',
      field: 'last',
      flex: 1
    },
    {
      headerName: 'First',
      field: 'first',
      flex: 1
    },
    {
      headerName: 'Email',
      field: 'email',
      flex: 1.5
    },
    {
      headerName: 'Status',
      field: 'status',
      flex: 0.5
    },
    {
      headerName: 'Welcomed',
      field: 'welcomed',
      renderCell: (field) => {
        return renderWelcomedBadge(field.value)
      },
      flex: 0.5
    },
    {
      headerName: 'Actions',
      field: 'actions',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Tooltip
            title={(params.row.externalId && hasUserSync) ? 'View User' : 'Edit User'} placement='top'
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -14]
                    }
                  }
                ]
              }
            }}
          >
            <IconButton
              color='primary'
              onClick={() => { handleEditingUser(params.row) }}
              id={`edit-user-${params.row.email}-button`}
              aria-label='Edit User Button'
            >
              {(params.row.externalId && hasUserSync) ? <IoEye /> : <IoPencil />}
            </IconButton>
          </Tooltip>
        )
      }
    }
  ]

  function renderWelcomedBadge (welcomed) {
    if (welcomed) {
      return <IoCheckmarkCircle size='2.25em' style={{ color: '#2e7d32' }} />
    } else {
      return <IoWarning size='2.25em' style={{ color: '#8C8C8C' }} />
    }
  }

  const selectAllUsers = () => {
    if (allUsersSelected) {
      setBulkUsers([])
      setAllUsersSelected(false)
    } else {
      setBulkUsers(users)
      setAllUsersSelected(true)
    }
  }

  const masterToggle = (modal) => {
    switch (modal) {
      case 'Close Add User':
        setAddUserModal(!addUserModal)
        setUser({})
        break
      case 'Open Add User':
        setAddUserModal(!addUserModal)
        break
      case 'Add Admin':
        setAddAdminModal(!addAdminModal)
        setNewULastName('')
        setNewUFirstName('')
        setNewUEmail('')
        break
      case 'Welcome Users':
        setWelcomeUsersModal(!welcomeUsersModal)
        break
      case 'Delete Users':
        setDeleteUsersModal(!deleteUsersModal)
        break
      case 'Delete Admin':
        setDeleteAdminModal(!deleteAdminModal)
        break
      case 'Sleep Users':
        setSleepUsersModal(!sleepUsersModal)
        break
      case 'Wake Users':
        setWakeUsersModal(!wakeUsersModal)
        break
    }
  }

  const searchBulkUserList = (userId) => {
    const found = bulkUsers.find(user => user.id === userId)
    if (found) {
      return true
    } else return false
  }

  const uploadUserInputs = async () => {
    const upUser = user
    const missing = ['first', 'last', 'email'].filter(key => !Object.keys(upUser).includes(key))
    if (missing.length) {
      NotificationUtilities.sendWarningMessage(`Missing ${missing} fields. These are required for all new devices.`)
      return
    }

    try {
      const { success, statusCode } = await dispatch(addUserThunk(id, upUser))
      if (success) {
        setAddUserModal(false)
        setUser({})
        dispatch(incrementUserCountAction())

        if (!onboardingChecklist.hasUsers) {
          dispatch(updateOnboardingChecklistDataThunk(id, { hasUsers: true }))
        }

        NotificationUtilities.sendSuccessMessage('User successfully added!')
      } else {
        if (statusCode === 409) {
          NotificationUtilities.sendWarningMessage('A user with that email already exists.')
        } else {
          NotificationUtilities.sendErrorMessage('There was a problem uploading user.')
        }
      }
    } catch (err) {
      console.error(err)
      NotificationUtilities.sendErrorMessage('Error adding new user.')
    }
  }

  const downloadDevices = () => {
    apiRequestUtils.get(`/api/companies/${id}/users`)
      .then((devices) => {
        return devices.json()
      })
      .then((devices) => {
        const today = Moment().format('YYYY-MM-DD')
        const inputFields = JSON.parse(company.deviceStructure).map(col => col.field)
        inputFields.push('status')
        const csv = Papa.unparse(devices.length ? devices : [{}], { columns: inputFields })

        const a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(new Blob([csv]), { type: 'csv' })
        a.download = `${today}-Active-Devices.csv`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      })
  }

  async function addAdmin () {
    const tempEmail = newUEmail.trim()
    const tempFirstName = newUFirstName.trim()
    const tempLastName = newULastName.trim()

    if (!ValidationUtils.isNotWhiteSpace(tempFirstName)) {
      NotificationUtilities.sendWarningMessage('You must enter a valid first name for this admin')
      return
    }

    if (!ValidationUtils.isNotWhiteSpace(tempLastName)) {
      NotificationUtilities.sendWarningMessage('You must enter a valid last name for this admin')
      return
    }

    if (!ValidationUtils.isValidEmail(tempEmail)) {
      NotificationUtilities.sendWarningMessage('You must enter a valid email')
      return
    }

    NotificationUtilities.sendInfoMessage('Creating your admin now...')

    const success = await dispatch(addAdminThunk(id, company.partnerName, tempEmail, tempFirstName, tempLastName))

    if (success) {
      if (!onboardingChecklist.hasPersonalizations) {
        await dispatch(updateOnboardingChecklistDataThunk(id, { hasPersonalizations: true }))
      }

      masterToggle('Add Admin')
    }
  }

  const handleEditingUser = (user) => {
    dispatch(setEditingUserAction(user))
    history.push(`/companies/${id}/edit-user`)
  }

  const handleRemovingAdmin = async (uid) => {
    setSelectedAdmin(uid)
    await dispatch(getAdminAccessibleCompaniesThunk(uid))
    masterToggle('Delete Admin')
  }

  const removeAdmin = async (uid) => {
    const success = await dispatch(removeAdminThunk({ companiesList: adminAccess[uid].companies, adminId: uid }))

    if (success) {
      masterToggle('Delete Admin')
    }
  }

  const bulkActionDelete = async () => {
    const syncedUsers = bulkUsers.reduce((prev, curr) => {
      return prev && (!curr.externalId || !hasUserSync)
    }, true)
    if (!syncedUsers) {
      NotificationUtilities.sendErrorMessage(`Error deleting ${bulkUsers.length} users! Some of these users have been synced from an external system and must be deleted from there.`)
      return
    }
    const res = await dispatch(deleteUsersThunk(id, bulkUsers.map(user => user.id)))
    if (res.status !== 200) {
      NotificationUtilities.sendErrorMessage(`Error deleting ${bulkUsers.length} users! Our team has been notified.`)
    } else {
      let userDelta = 0
      const usersToDelete = bulkUsers
      for (const user of usersToDelete) {
        if (user.status === 'active') {
          userDelta++
        }
      }

      await dispatch(setUserCountAction(company.totalDevices - userDelta))

      NotificationUtilities.sendSuccessMessage(`${bulkUsers.length} users deleted!`)
      setBulkUsers([])
      setAllUsersSelected(false)
      masterToggle('Delete Users')
    }
  }

  const bulkActionSleepToggle = async (status) => {
    if (bulkUsers.length === 0) {
      NotificationUtilities.sendWarningMessage('You must select at least one user.')
      return
    }

    const userStatuses = []
    if (status === 'slept') { // button type check
      for (const user of bulkUsers) {
        const userStatus = { id: user.id, shouldSleep: true }
        userStatuses.push(userStatus)
      }
    } else {
      for (const user of bulkUsers) {
        const userStatus = { id: user.id, shouldSleep: false }
        userStatuses.push(userStatus)
      }
    }

    // send request to back end
    const { success, users } = await dispatch(bulkSleepThunk(id, userStatuses))

    if (success) {
      let userDelta = 0
      for (const user of users) {
        users.forEach(existingUser => {
          if (existingUser.id === user.userId) {
            if (user.oldStatus === 'slept' && user.status === 'active') {
              userDelta++
            } else if (user.oldStatus === 'active' && user.status === 'slept') {
              userDelta--
            }
          }
        })
      }

      dispatch(setUserCountAction(company.totalDevices + userDelta))
      setBulkUsers([])
      setAllUsersSelected(false)

      NotificationUtilities.sendSuccessMessage(`Users' statuses successfully changed to ${status}!`)

      if (users.length > 1) {
        if (status === 'slept') {
          masterToggle('Sleep Users')
        } else {
          masterToggle('Wake Users')
        }
      }
    }
  }

  async function bulkActionWelcome (sendAt) {
    NotificationUtilities.sendInfoMessage('Scheduling welcome messages...')

    const success = await dispatch(bulkWelcomeThunk(id, bulkUsers.map(user => user.id), sendAt))

    if (success) {
      setBulkUsers([])
      setAllUsersSelected(false)
      masterToggle('Welcome Users')
      if (!onboardingChecklist.hasWelcomedUsers) {
        dispatch(updateOnboardingChecklistDataThunk(id, { hasWelcomedUsers: true }))
      }
      NotificationUtilities.sendSuccessMessage('Welcome messages have been scheduled. It may take a few minutes for the changes to appear.')
    }
  }

  const updateBulkUsers = (bulkUser) => {
    // add or remove bulk user list
    const bulkUsersCopy = [...bulkUsers]
    const userFound = bulkUsersCopy.find(userObj => userObj.id === bulkUser.id)
    if (userFound) {
      const newBulkUsers = bulkUsersCopy.filter(existingUser => existingUser.id !== bulkUser.id)
      setBulkUsers(newBulkUsers)
    } else {
      bulkUsersCopy.push(bulkUser)
      setBulkUsers(bulkUsersCopy)
    }
  }

  const addUserInfo = (e, fieldName) => {
    const addUser = user
    addUser[fieldName] = e.target.value
    setUser(addUser)
  }

  const navigateToGroupViewer = (selectedGroupId) => {
    setGroupTabView('viewer')
    setSelectedGroupId(selectedGroupId)
  }

  const navigateToGroupOverview = () => {
    setGroupTabView('overview')
    setSelectedGroupId(undefined)
  }

  function handleWelcomeModalToggle () {
    if (bulkUsers.length === 0) {
      NotificationUtilities.sendWarningMessage('You must select at least one user.')
      return
    }

    masterToggle('Welcome Users')
  }

  function handleDeleteModalToggle () {
    if (bulkUsers.length === 0) {
      NotificationUtilities.sendWarningMessage('You must select at least one user.')
      return
    }

    masterToggle('Delete Users')
  }

  function handleWakeModalToggle () {
    if (bulkUsers.length === 0) {
      NotificationUtilities.sendWarningMessage('You must select at least one user.')
    } else if (bulkUsers.length > 1) {
      masterToggle('Wake Users')
    } else {
      bulkActionSleepToggle('active')
    }
  }

  function handleSleepModalToggle () {
    if (bulkUsers.length === 0) {
      NotificationUtilities.sendWarningMessage('You must select at least one user.')
    } else if (bulkUsers.length > 1) {
      masterToggle('Sleep Users')
    } else {
      bulkActionSleepToggle('slept')
    }
  }

  const renderGroupMembersViewer = (selectedGroupId) => {
    let selectedGroupMemberIds
    for (const group of groups) {
      if (group.id === selectedGroupId) {
        selectedGroupMemberIds = group.members
        break
      }
    }

    const groupMembers = users.filter(user => selectedGroupMemberIds.includes(user.id))

    const groupMemberColumns = [
      {
        headerName: 'Last',
        field: 'last',
        flex: 0.8
      },
      {
        headerName: 'First',
        field: 'first',
        flex: 0.8
      },
      {
        headerName: 'Email',
        field: 'email',
        flex: 1
      },
      {
        headerName: 'Microsoft Entra ID',
        field: 'externalId',
        flex: 1
      }
    ]

    return (
      <div style={{ height: '70vh', width: '100%' }}>

        <DataGrid
          style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
          className='DataGrid'
          initialState={{
            sorting: {
              sortModel: [{ field: 'last', sort: 'asc' }]
            }
          }}
          rows={groupMembers}
          columns={groupMemberColumns}
          pageSize={tablePageSize}
          onPageSizeChange={(newSize) => setTablePageSize(newSize)}
          rowsPerPageOptions={[10, 25, 50]}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 250 },
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true }
            }
          }}
          disableColumnSelector
          disableDensitySelector
        />
      </div>
    )
  }

  if (!company || !users || !groups) {
    return <Spinner />
  }

  const adminColumns = [
    {
      headerName: 'Name',
      field: 'name',
      sort: 'asc',
      flex: 2
    },
    {
      headerName: 'Email',
      field: 'email',
      flex: 2
    },
    {
      headerName: 'Actions',
      field: 'actions',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip
            title='Delete Admin' placement='top'
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -14]
                    }
                  }
                ]
              }
            }}
          >
            <IconButton
              color='error'
              id={`delete-admin-${params.row.email}-button`}
              aria-label='Delete Admin Button'
              onClick={async (e) => {
                await handleRemovingAdmin(params.row.uid)
              }}
            >
              <IoTrash />
            </IconButton>
          </Tooltip>
        )
      }
    }
  ]

  const groupColumns = [
    {
      headerName: 'Group Name',
      field: 'displayName',
      flex: 1
    },
    {
      headerName: 'Members',
      field: 'members',
      valueGetter: (params) => {
        return params.row.members.length
      },
      flex: 1
    },
    {
      headerName: 'Actions',
      field: 'actions',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Tooltip
            title='View Group Members' placement='top'
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -14]
                    }
                  }
                ]
              }
            }}
          >
            <IconButton
              id={`view-group-${params.row.displayName}-button`}
              aria-label={`View ${params.row.displayName} Group Button`}
              color='primary'
              onClick={() => navigateToGroupViewer(params.row.id)}
            >
              <IoEye />
            </IconButton>
          </Tooltip>
        )
      }
    }
  ]

  return (
    <Grid>
      {/* Add User Modal */}
      <AddUserModal
        isOpen={addUserModal}
        addUserInfo={addUserInfo}
        uploadUser={uploadUserInputs}
        masterToggle={masterToggle}
        user={user}
      />

      {/* Add Admin Modal */}

      <PhinModal
        isOpen={addAdminModal}
        size='medium'
        title='Add Admin'
        close={() => { masterToggle('Add Admin') }}
        closeText='Cancel'
        action={addAdmin}
        actionText='Add Admin'
      >
        <Grid container direction='column' p={1} rowGap={2}>
          <TextField
            onChange={(e) => {
              setNewUFirstName(e.target.value)
            }}
            name='newUFirstName'
            label='First Name'
            id='add-admin-modal-input-first'
            aria-label='Add Admin Modal First Name Field'
          />
          <TextField
            onChange={(e) => {
              setNewULastName(e.target.value)
            }}
            name='newULastName'
            label='Last Name'
            id='add-admin-modal-input-last'
            aria-label='Add Admin Modal Last Name Field'
          />
          <TextField
            onChange={(e) => {
              setNewUEmail(e.target.value)
            }}
            name='newUEmail'
            label='Email'
            id='add-admin-modal-input-email'
            aria-label='Add Admin Modal Email Field'
          />
        </Grid>
      </PhinModal>
      {/* End Add Admin Modal */}

      <PhinModal
        isOpen={welcomeUsersModal}
        title='Welcome Users'
        size='large'
        close={() => masterToggle('Welcome Users')}
        action={() => {
          const completeDate = Moment(sendDate)
          completeDate.hour(sendTime.hours())
          completeDate.minute(sendTime.minutes())
          bulkActionWelcome(completeDate.toDate())
        }}
      >
        <WelcomeUsersModal
          today={today}
          sendTime={sendTime}
          sendDate={sendDate}
          setSendDate={setSendDate}
          setSendTime={setSendTime}
          userCount={bulkUsers.length}
        />
      </PhinModal>

      <PhinModal
        isOpen={deleteUsersModal}
        title='Delete Users'
        close={() => masterToggle('Delete Users')}
        closeText='Keep Users'
        action={bulkActionDelete}
        actionText='Delete Users'
        actionColor='error'
      >
        <p>Are you sure you want to delete these users? <strong>This can not be undone!</strong></p>
      </PhinModal>

      <PhinModal
        isOpen={sleepUsersModal}
        title='Sleep Users'
        close={() => masterToggle('Sleep Users')}
        closeText='Cancel'
        action={() => {
          bulkActionSleepToggle('slept')
        }}
        actionText='Sleep Users'
      >
        <p>Are you sure you want to sleep <strong>{bulkUsers.length}</strong> users?</p>
      </PhinModal>

      <PhinModal
        isOpen={wakeUsersModal}
        title='Wake Users'
        close={() => masterToggle('Wake Users')}
        closeText='Cancel'
        action={() => {
          bulkActionSleepToggle('active')
        }}
        actionText='Wake Users'
      >
        <p>Are you sure you want to wake <strong>{bulkUsers.length}</strong> users?</p>
      </PhinModal>

      <PhinModal
        isOpen={deleteAdminModal}
        title='Delete Admin'
        close={() => masterToggle('Delete Admin')}
        closeText='Cancel'
        action={() => removeAdmin(selectedAdmin)}
        actionText='Delete'
      >
        {adminAccess && adminAccess[selectedAdmin] && adminAccess[selectedAdmin].companies && adminAccess[selectedAdmin].companies.length === 1 &&
          <p>Are you sure you want to delete this admin? This will remove their access to the dashboard including this company.</p>}

        {adminAccess && adminAccess[selectedAdmin] && adminAccess[selectedAdmin].companies && adminAccess[selectedAdmin].companies.length > 1 &&
          (<p>Are you sure you want to delete this admin? This will remove their access to the dashboard including ALL companies they are an admin in:
            <ul>
              {adminAccess[selectedAdmin].companies.map((company) => { return <li key={company.id}>{company.name}</li> })}
            </ul>
          </p>)}
        <p><strong>This can not be undone!</strong></p>
      </PhinModal>

      {/* End Modals */}

      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='start'
        sx={{ marginBottom: 'var(--phin-s4)' }}
      >
        <h2 className='phin-h2'>User Management</h2>
      </Grid>

      <MDBRow className='mt-4 d-flex justify-content-center'>
        <MDBCol xl='5' md='6' className='mb-5'>
          <MDBCard cascade className='cascading-admin-card'>
            <div className='admin-up'>
              <div className='data'>
                <p>Active Users</p>
                <h4 id='device-count' className='font-weight-bold dark-grey-text'>{company.totalDevices || 0}</h4>
              </div>
            </div>
          </MDBCard>
        </MDBCol>
      </MDBRow>

      <TabContext value={currentTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={toggleBetweenTabs} aria-label='Users tabs'>
            <Tab test-id='users-toggle' label='Users' value='1' />
            <Tab test-id='groups-toggle' label='Groups' value='2' />
            <Tab test-id='admins-toggle' label='Admins' value='3' />
          </TabList>
        </Box>
        <TabPanel value='1'>
          <Grid
            container
            direction='row'
            justifyContent='start'
            alignItems='center'
            spacing={2}
          >
            <Grid item>
              <Button size='large' variant='contained' id='download-active-users-button' onClick={() => downloadDevices()}>Download All Users</Button>
            </Grid>
            <Grid item>
              <Button size='large' variant='contained' id='upload-user-csv-button' aria-label='Upload User CSV Button' onClick={() => history.push(`/companies/${id}${navigationRoutes.USERS_UPLOAD}`)}>Upload CSV</Button>
            </Grid>
            <Grid item>
              <Button size='large' variant='contained' id='add-user-button' onClick={() => { masterToggle('Open Add User') }}>Add User</Button>
            </Grid>
          </Grid>

          <hr />

          <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='space-between'
          >
            <Grid
              container
              direction='row'
              justifyContent='start'
              alignItems='center'
              spacing={1}
              sx={{ marginBottom: 'var(--phin-s0)' }}
            >
              <Grid item>
                <Tooltip title='Wake Users' placement='top'>
                  <IconButton
                    id='wake-users-button'
                    aria-label='Wake Users Button'
                    color='primary'
                    size='large'
                    onClick={handleWakeModalToggle}
                  >
                    <IoSunny />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item>
                <Tooltip title='Sleep Users' placement='top'>
                  <IconButton
                    id='sleep-users-button'
                    aria-label='Sleep Users Button'
                    color='primary'
                    size='large'
                    onClick={handleSleepModalToggle}
                  >
                    <IoBed />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item>
                <Tooltip title='Welcome Users' placement='top'>
                  <IconButton
                    id='welcome-users-button'
                    aria-label='Welcome Users Button'
                    color='primary'
                    size='large'
                    onClick={handleWelcomeModalToggle}
                  >
                    <IoPaperPlane />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item>
                <Tooltip title='Delete Users' placement='top'>
                  <IconButton
                    id='delete-users-button'
                    aria-label='Delete Users Button'
                    color='error'
                    size='large'
                    onClick={handleDeleteModalToggle}
                  >
                    <IoTrash />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>

            <>
              {/* Users Table */}
              {(users && users.length < 1000) && (
                <div style={{ height: '70vh', width: '100%' }}>
                  <DataGrid
                    style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
                    className='DataGrid'
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'last', sort: 'asc' }]
                      }
                    }}
                    rows={users}
                    columns={userColumns}
                    pageSize={tablePageSize}
                    onPageSizeChange={(newSize) => setTablePageSize(newSize)}
                    rowsPerPageOptions={[10, 25, 50]}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                      toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: {
                          debounceMs: 250,
                          id: 'users-table-search'
                        },
                        csvOptions: { disableToolbarButton: true },
                        printOptions: { disableToolbarButton: true }
                      }
                    }}
                    getRowClassName={(params) => { return params.row.status === 'slept' ? 'slept-row' : '' }}
                    disableColumnSelector
                    disableDensitySelector
                    disableRowSelectionOnClick
                  />
                </div>
              )}

              {(users && users.length > 1000) && (
                <h4>You have over 1000 users! Please download the list to see them all.</h4>
              )}
            </>
          </Grid>
        </TabPanel>
        <TabPanel value='2'>
          <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='space-between'
            spacing={1}
          >
            {groupTabView === 'viewer' &&
              (
                <Grid
                  container
                  direction='column'
                  justifyContent='center'
                  alignItems='space-between'
                >
                  <Grid
                    item
                    sx={{ marginBottom: 'var(--phin-s0)' }}
                  >
                    <Button size='large' id='back-to-groups-view-button' aria-label='Go Back to Groups View Button' variant='contained' onClick={() => navigateToGroupOverview()}> Go Back</Button>
                  </Grid>

                  {/* Group Members Table */}
                  {renderGroupMembersViewer(selectedGroupId)}
                </Grid>)}

            {/* Groups Table */}
            {groupTabView === 'overview' && (
              <div style={{ height: '70vh', width: '100%' }}>
                <DataGrid
                  autoHeight
                  style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
                  className='DataGrid'
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'last', sort: 'asc' }]
                    }
                  }}
                  rows={groups.filter((group) => {
                    return group.status === groupStatuses.ACTIVE
                  })}
                  columns={groupColumns}
                  pageSize={tablePageSize}
                  onPageSizeChange={(newSize) => setTablePageSize(newSize)}
                  rowsPerPageOptions={[10, 25, 50]}
                  components={{ Toolbar: GridToolbar }}
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 250 },
                      csvOptions: { disableToolbarButton: true },
                      printOptions: { disableToolbarButton: true }
                    }
                  }}
                  getRowClassName={(params) => { return params.row.status === 'slept' ? 'slept-row' : '' }}
                  disableColumnSelector
                  disableDensitySelector
                  disableRowSelectionOnClick
                />
              </div>
            )}
          </Grid>
        </TabPanel>
        <TabPanel value='3'>
          <Grid
            container
            direction='column'
            justifyContent='space-between'
            alignItems='start'
            spacing={1}
          >
            <Grid item>
              <p>Company Level Admins</p>
            </Grid>

            <Grid item>
              <Button size='large' variant='contained' id='add-admin-button' aria-label='Add Admin Button' onClick={() => { masterToggle('Add Admin') }}>Add Admin</Button>
            </Grid>

            {/* Company Admins Table */}
            <Grid
              item
              sx={{ width: '100%' }}
            >
              <div style={{ height: '70vh', width: '100%' }}>
                <DataGrid
                  autoHeight
                  style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
                  className='DataGrid'
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'last', sort: 'asc' }]
                    }
                  }}
                  getRowId={(row) => row.uid}
                  rows={company.admins || []}
                  columns={adminColumns}
                  pageSize={tablePageSize}
                  onPageSizeChange={(newSize) => setTablePageSize(newSize)}
                  rowsPerPageOptions={[10, 25, 50]}
                  components={{ Toolbar: GridToolbar }}
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 250 },
                      csvOptions: { disableToolbarButton: true },
                      printOptions: { disableToolbarButton: true }
                    }
                  }}
                  getRowClassName={(params) => { return params.row.status === 'slept' ? 'slept-row' : '' }}
                  disableColumnSelector
                  disableDensitySelector
                  disableRowSelectionOnClick
                />
              </div>
            </Grid>
          </Grid>
        </TabPanel>
      </TabContext>
    </Grid>
  )
}

export default UsersPage
