import { useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { smartRoute } from '../../utils/RouterUtils'
import { useHistory } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useGetPartnerReportedEmailsQuery } from '../../store/PartnerTriagingSlice'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Box, Chip, FormGroup, Button, ButtonGroup, InputLabel, Autocomplete, TextField, Checkbox } from '@mui/material'
import { EMAIL_TRIAGE_TAGS, EMAIL_TRIAGE_SEVERITY, featureToggleNames } from '../../frontendConsts'
import { DateTime } from 'luxon'
import { getFeatureToggleEnabledThunk } from '../../store/AuthSlice'

function FilterToolbar ({ companies, onFilterChange, tableFilters, isProcessWithAIEnabled }) {
  const DATA_RANGE_OPTIONS = {
    ALL: 'all',
    TODAY: 'today',
    THIS_WEEK: 'thisWeek',
    THIS_MONTH: 'thisMonth'
  }

  const defaultFilterState = {
    severity: [],
    companies: [],
    tags: [],
    timeRange: DATA_RANGE_OPTIONS.ALL,
    isResolved: false
  }

  const handleChangeMultiFilter = ({ value, filterType, clear = false }) => {
    if (clear) {
      const filterChange = { ...defaultFilterState }
      if (filterType) {
        filterChange[filterType] = typeof value === 'string' ? value.split(',') : value
      }
      onFilterChange(filterChange)
    } else {
      onFilterChange(prevFilters => ({
        ...prevFilters,
        [filterType]: typeof value === 'string' ? value.split(',') : value
      }))
    }
  }

  const handleDateRangeChange = (range) => {
    if (range === tableFilters.timeRange) {
      onFilterChange(prevFilters => ({
        ...prevFilters,
        timeRange: DATA_RANGE_OPTIONS.ALL
      }))
    } else {
      onFilterChange(prevFilters => ({
        ...prevFilters,
        timeRange: range
      }))
    }
  }

  return (
    <>
      <FormGroup row sx={{ marginTop: '2em' }}>
        <Box sx={{ paddingRight: '1em', borderRight: 'solid 1px grey' }}>
          <Button
            variant='contained' onClick={() => {
              handleChangeMultiFilter({ clear: true })
            }}
          >
            Default
          </Button>
        </Box>
        <Button
          variant='contained'
          sx={{ marginLeft: '1em' }}
          onClick={() => {
            handleChangeMultiFilter({ clear: true })
            handleDateRangeChange(DATA_RANGE_OPTIONS.THIS_WEEK)
          }}
        >
          This Week
        </Button>
        {isProcessWithAIEnabled &&
          <Button
            variant='contained'
            sx={{ marginLeft: '1em' }}
            onClick={() => { handleChangeMultiFilter({ value: true, filterType: 'spamOnly', clear: true }) }}
          >
            Spam
          </Button>}
        {isProcessWithAIEnabled &&
          <Button
            variant='contained'
            sx={{ marginLeft: '1em' }}
            onClick={() => handleChangeMultiFilter({ value: 'high', filterType: 'severity', clear: true })}
          >
            Critical Severity
          </Button>}
      </FormGroup>

      <FormGroup row sx={{ margin: '1.5em 0', display: 'flex', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <InputLabel>Data Range</InputLabel>
          <ButtonGroup size='small' sx={{ height: '2em' }}>
            <Button
              variant={tableFilters.timeRange === DATA_RANGE_OPTIONS.ALL ? 'contained' : 'outlined'}
              onClick={() => handleDateRangeChange(DATA_RANGE_OPTIONS.ALL)}
            >
              All
            </Button>
            <Button
              variant={tableFilters.timeRange === DATA_RANGE_OPTIONS.TODAY ? 'contained' : 'outlined'}
              onClick={() => handleDateRangeChange(DATA_RANGE_OPTIONS.TODAY)}
            >
              Today
            </Button>
            <Button
              variant={tableFilters.timeRange === DATA_RANGE_OPTIONS.THIS_WEEK ? 'contained' : 'outlined'}
              onClick={() => handleDateRangeChange(DATA_RANGE_OPTIONS.THIS_WEEK)}
            >
              This Week
            </Button>
            <Button
              variant={tableFilters.timeRange === DATA_RANGE_OPTIONS.THIS_MONTH ? 'contained' : 'outlined'}
              onClick={() => handleDateRangeChange(DATA_RANGE_OPTIONS.THIS_MONTH)}
            >
              This Month
            </Button>
          </ButtonGroup>
        </Box>

        {isProcessWithAIEnabled &&
          <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '1em' }}>
            <InputLabel>Tags</InputLabel>
            <Autocomplete
              size='small'
              sx={{ minHeight: '2.5em', minWidth: '10em', maxWidth: '18em' }}
              id='tag-filters-select'
              multiple
              limitTags={3}
              value={tableFilters.tags}
              options={Object.keys(EMAIL_TRIAGE_TAGS).map((key) => EMAIL_TRIAGE_TAGS[key])}
              getOptionLabel={(option) => option}
              onChange={(event, value) => handleChangeMultiFilter({ value, filterType: 'tags' })}
              renderInput={(params) => (<TextField {...params} label='Tags' />)}
            />
          </Box>}

        <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '1em' }}>
          <InputLabel>Company Select</InputLabel>
          <Autocomplete
            size='small'
            sx={{ minHeight: '2em', minWidth: '10em', maxWidth: '18em' }}
            id='company-filters-select'
            multiple
            limitTags={2}
            value={tableFilters.companies}
            options={companies.map((company) => company.name)}
            getOptionLabel={((option) => option)}
            onChange={(event, value) => handleChangeMultiFilter({ value, filterType: 'companies' })}
            renderInput={(params) => (<TextField {...params} label='Companies' />)}
          />
        </Box>

        {isProcessWithAIEnabled &&
          <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '1em' }}>
            <InputLabel>Severity Level</InputLabel>
            <Autocomplete
              size='small'
              sx={{ minHeight: '2em', minWidth: '10em', maxWidth: '18em' }}
              id='severity-filters-select'
              multiple
              limitTags={3}
              value={tableFilters.severity}
              options={Object.keys(EMAIL_TRIAGE_SEVERITY).map((key) => EMAIL_TRIAGE_SEVERITY[key])}
              getOptionLabel={(option) => option}
              onChange={(event, value) => handleChangeMultiFilter({ value, filterType: 'severity' })}
              renderInput={(params) => (<TextField {...params} label='Severities' />)}
            />
          </Box>}

        <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '1em' }}>
          <InputLabel>Resolved</InputLabel>
          <Checkbox
            checked={tableFilters.resolvedOnly}
            onChange={(event) => { handleChangeMultiFilter({ value: event.target.checked, filterType: 'resolvedOnly' }) }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Box>
      </FormGroup>
    </>
  )
}

export const PartnerTriagingPage = ({ partnerId }) => {
  const { partner } = useSelector((state) => state.partner)
  const { authorization, featureToggles } = useSelector((state) => state.auth)
  const history = useHistory()
  const { logout } = useAuth0()
  const dispatch = useDispatch()

  async function getProcessReportedEmailsFeatureToggle () {
    await dispatch(getFeatureToggleEnabledThunk(featureToggleNames.PROCESS_REPORTED_EMAILS, partnerId))
  }

  async function getEmailAIProcessingFeatureToggle () {
    await dispatch(getFeatureToggleEnabledThunk(featureToggleNames.EMAIL_AI_PROCESSING, partnerId))
  }

  useEffect(() => {
    if (!featureToggles[partnerId] || featureToggles[partnerId]?.processReportedEmails === undefined) {
      getProcessReportedEmailsFeatureToggle()
    } else if (!featureToggles[partnerId]?.processReportedEmails) {
      smartRoute({ authorization, history, logout })
    }
  }, [])

  useEffect(() => {
    if (!featureToggles[partnerId] || featureToggles[partnerId]?.emailAIProcessing === undefined) {
      getEmailAIProcessingFeatureToggle()
    }
  }, [])

  const isProcessWithAIEnabled = featureToggles[partnerId]?.emailAIProcessing

  const DATA_RANGE_OPTIONS = {
    ALL: 'all',
    TODAY: 'today',
    THIS_WEEK: 'thisWeek',
    THIS_MONTH: 'thisMonth'
  }

  const [filters, setFilters] = useState({
    severity: [],
    companies: [],
    tags: [],
    spamOnly: false,
    timeRange: DATA_RANGE_OPTIONS.ALL,
    resolvedOnly: false
  })

  const triageTableColumns = [
    { headerName: 'From Address', field: 'senderEmail', width: 220 },
    { headerName: 'Subject Line', field: 'subject', width: 220 },
    { headerName: 'Reporter/Company', field: 'ReporterAndCompany', width: 300 },
    { headerName: 'Attachments', field: 'attachments', width: 100 },
    {
      headerName: 'Received Date',
      field: 'receivedDate',
      width: 200,
      valueFormatter: (value, row) => DateTime.fromISO(value.value).toLocaleString(DateTime.DATETIME_MED)
    },
    { headerName: 'External', field: 'external', width: 40 },
    { headerName: 'Sender Domain', field: 'senderDomain', width: 200 },
    { headerName: 'Sender IP Address', field: 'senderIpAddress', width: 200 },
    { headerName: 'Resolved', field: 'isResolved', width: 200, renderCell: (params) => params.row.isResolved ? 'Resolved' : 'Unresolved' }
  ]

  if (isProcessWithAIEnabled) {
    triageTableColumns.push({ headerName: 'Severity', field: 'severity', width: 100 })
    triageTableColumns.push({
      field: 'tags',
      headerName: 'Tags',
      width: 200,
      renderCell: (params) => params.row.tags?.map(tag => (<Chip sx={{ margin: '2px' }} size='small' key={tag.name} label={tag.name} />))
    })
    triageTableColumns.push({ headerName: 'Spam', field: 'spam', width: 100 })
  }

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    senderEmail: true,
    subject: true,
    ReporterAndCompany: true,
    tags: true,
    severity: true,
    attachments: true,
    receivedDate: true,
    spam: false,
    external: false,
    senderDomain: false,
    senderIpAddress: false,
    isResolved: false
  })

  const { data: reportedEmailsData, isLoading: isLoadingReportedEmailsData } = useGetPartnerReportedEmailsQuery(partnerId)

  const filteredDataRows = useMemo(() => {
    return (reportedEmailsData?.filter((row) => {
      const reportedDate = DateTime.fromSeconds(Number(row.reportedTime?._seconds))
      const now = new Date()

      const todayStart = new Date(now.setHours(0, 0, 0, 0))
      const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay()))
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1)

      const matchesSeverity = filters.severity.length === 0 || filters.severity.includes(row.severity)
      const matchesCompany = filters.companies.length === 0 || filters.companies.includes(row.companyName)
      const matchesTags = filters.tags.length === 0 || row.tags?.some(tag => filters.tags.includes(tag.name))
      const matchesDisposition = filters.spamOnly ? row.spam : true
      const matchesResolutionStatus = filters.resolvedOnly ? row.isResolved : !row.isResolved

      const matchesTimeRange = () => {
        switch (filters.timeRange) {
          case DATA_RANGE_OPTIONS.TODAY:
            return reportedDate >= todayStart && reportedDate < new Date(todayStart.getTime() + 24 * 60 * 60 * 1000)
          case DATA_RANGE_OPTIONS.THIS_WEEK:
            return reportedDate >= startOfWeek
          case DATA_RANGE_OPTIONS.THIS_MONTH:
            return reportedDate >= startOfMonth
          default:
            return true
        }
      }

      return matchesSeverity && matchesCompany && matchesTags && matchesDisposition && matchesResolutionStatus && matchesTimeRange()
    }) || []).map((row, index) => ({
      id: row.id || index,
      subject: row.messageContent?.subject || 'No Subject',
      ReporterAndCompany: `${row.reporterName}/${row.companyName}`,
      attachments: row.attachmentCount || 0,
      spam: row.spam ? row.spam : false,
      receivedDate: DateTime.fromSeconds(Number(row.reportedTime?._seconds)).toISO(),
      ...row
    }))
  }, [reportedEmailsData, filters])

  return (
    <div>
      <h1>Partner Triaging Page</h1>
      <Box component='span'>
        Start triaging mail reported by your Phin users. Save preset filters to speed up your process.
      </Box>
      <Box>
        <FilterToolbar companies={partner?.clients} onFilterChange={setFilters} tableFilters={filters} isProcessWithAIEnabled={isProcessWithAIEnabled} />
      </Box>
      <Box style={{ height: '500px', width: '100%' }}>
        <DataGrid
          style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
          className='DataGrid'
          rows={filteredDataRows}
          columns={triageTableColumns}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
          pageSize={10}
          rowsPerPageOptions={[10]}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true }
            }
          }}
          disableDensitySelector
          loading={isLoadingReportedEmailsData}
          onRowClick={(gridData) => history.push(`/partners/${partnerId}/triaging/${gridData.row.messageId}`)}
          sx={{
            '& .MuiDataGrid-row': {
              cursor: 'pointer'
            }
          }}
        />
      </Box>
    </div>
  )
}
