import { Box, Grid } from '@mui/material'
import { Pax8BillingWidget } from './Pax8BillingWidget'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPax8PricingPlansThunk } from '../../store/Pax8PartnerSlice'
import { UsageBreakdownReportsTable } from '../partnerPages/components/UsageBreakdownReportsTable'

export function Pax8BillingPage ({ partnerId }) {
  const dispatch = useDispatch()
  const { pax8PricingPlans } = useSelector((state) => state.pax8Partner)

  useEffect(() => {
    if (pax8PricingPlans === null) {
      dispatch(getPax8PricingPlansThunk({ partnerId }))
    }
  }, [])

  return (
    <Box>
      <div className='phin-h2 margin-bottom:2'>Billing</div>

      <Grid container spacing={4}>
        <Grid item md={12} lg={6}>
          <Pax8BillingWidget partnerId={partnerId} />
        </Grid>

        <Grid item sm={12}>
          <UsageBreakdownReportsTable
            partnerId={partnerId}
          />
        </Grid>
      </Grid>

    </Box>
  )
}
