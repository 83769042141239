import { DateTime } from 'luxon'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'
import apiSlice from './apiSlice.js'

export const cwApi = apiSlice.injectEndpoints({
  tagTypes: ['CwCompanies', 'CwCompanyMappings'],
  endpoints: (builder) => ({
    connectIntegration: builder.mutation({
      query: ({ partnerId, cwPartnerId, publicKey, privateKey, cwApiUrl }) => ({
        url: `/partners/${partnerId}/integrations/cw-api/connect`,
        body: { cwPartnerId, publicKey, privateKey, cwApiUrl },
        method: 'POST'
      })
    }),
    disconnectIntegration: builder.mutation({
      query: ({ partnerId }) => ({
        url: `/partners/${partnerId}/integrations/cw-api/disconnect`,
        method: 'DELETE'
      })
    }),
    enableIntegration: builder.mutation({
      query: ({ partnerId, integrationId }) => ({
        url: `/partners/${partnerId}/integrations/cw-api/enable`,
        body: { integrationId },
        method: 'POST'
      })
    }),
    disableIntegration: builder.mutation({
      query: ({ partnerId, integrationId }) => ({
        url: `/partners/${partnerId}/integrations/cw-api/disable`,
        body: { integrationId },
        method: 'DELETE'
      })
    }),
    getCwMappings: builder.query({
      query: ({ partnerId }) => `/partners/${partnerId}/integrations/cw-api/company-mapping`,
      providesTags: ['CwCompanyMappings']
    }),
    getSyncStatus: builder.query({
      query: ({ partnerId }) => `/partners/${partnerId}/integrations/cw-api/sync`
    }),
    getCwCompanies: builder.query({
      query: ({ partnerId, nameFilter }) => `/partners/${partnerId}/integrations/cw-api/companies?name=${nameFilter}`,
      providesTags: ['CwCompanies']
    }),
    getCwAgreements: builder.query({
      query: ({ partnerId, cwCompanyId, nameFilter }) => `/partners/${partnerId}/integrations/cw-api/${cwCompanyId}/agreements?name=${nameFilter}`
    }),
    getCwAdditions: builder.query({
      query: ({ partnerId, cwAgreementId, nameFilter }) => `/partners/${partnerId}/integrations/cw-api/agreements/${cwAgreementId}/additions?name=${nameFilter}`
    }),
    setCwMapping: builder.mutation({
      query: ({ partnerId, companyId, mappingUpdate }) => ({
        url: `/partners/${partnerId}/integrations/cw-api/company-mapping`,
        method: 'POST',
        body: { companyId, mappingUpdate }
      }),
      invalidatesTags: ['CwCompanyMappings'],
      async onQueryStarted ({ partnerId, companyId, mappingUpdate }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          cwApi.util.updateQueryData('getCwMappings', { partnerId }, (draft) => {
            if (draft.rows) {
              // Update the specific mapping with the new changes
              draft.rows = draft.rows.map((mapping) => {
                if (mapping.companyId === companyId) {
                  return { ...mapping, ...mappingUpdate, lastUpdated: { _seconds: DateTime.now().toSeconds() } }
                }

                return mapping
              })
            }
          })
        )

        try {
          await queryFulfilled
        } catch {
          NotificationUtilities.sendErrorMessage('Failed to update ConnectWise Company Mapping')
          patchResult.undo()
        }
      }
    }),
    syncCwCompanies: builder.mutation({
      query: ({ partnerId }) => ({
        url: `/partners/${partnerId}/integrations/cw-api/sync`,
        method: 'POST'
      }),
      async onQueryStarted ({ partnerId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          cwApi.util.updateQueryData('getSyncStatus', { partnerId }, (draft) => {
            Object.assign(draft, { isSyncing: true })
          })
        )

        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    }),
    startAutomap: builder.mutation({
      query: ({ partnerId }) => ({
        url: `/partners/${partnerId}/integrations/cw-api/automap`,
        method: 'POST'
      })
    })
  })
})

export const {
  useConnectIntegrationMutation,
  useDisconnectIntegrationMutation,
  useEnableIntegrationMutation,
  useDisableIntegrationMutation,
  useGetCwMappingsQuery,
  useLazyGetCwMappingsQuery,
  useGetSyncStatusQuery,
  useGetCwCompaniesQuery,
  useLazyGetCwAgreementsQuery,
  useLazyGetCwAdditionsQuery,
  useSetCwMappingMutation,
  useSyncCwCompaniesMutation,
  useStartAutomapMutation
} = cwApi
