import React, { useEffect, useState } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Checkbox } from '@mui/material'

export const TrainingTopicsSelectorTable = ({
  trainingTopics, setTrainingTopics,
  baseTrainingCampaignTopics, nonBaseTrainingCampaignTopics,
  rowCount, lock
}) => {
  const [trainingTopicsCatalog, setTrainingTopicsCatalog] = useState([])
  const [isAllChecked, setAllChecked] = useState(false)

  useEffect(() => {
    setTrainingTopicsCatalog([...baseTrainingCampaignTopics, ...nonBaseTrainingCampaignTopics].map((name, idx) => { return { id: idx, name } }))
  }, [])

  useEffect(() => {
    if (!isAllChecked && (trainingTopics.length === trainingTopicsCatalog.length)) {
      setAllChecked(true)
    } else if (isAllChecked && (trainingTopics.length !== trainingTopicsCatalog.length)) {
      setAllChecked(false)
    }
  }, [trainingTopics, trainingTopicsCatalog])

  const handleCheckboxChange = (topic) => {
    const found = trainingTopics.find(t => { return t === topic })
    if (found) {
      setTrainingTopics(trainingTopics.filter(t => { return t !== topic }))
    } else {
      setTrainingTopics([...trainingTopics, topic])
    }
  }

  const handleAllCheckboxesSelected = () => {
    const indeterminate = trainingTopics.length > 0 && (trainingTopics.length !== trainingTopicsCatalog.length)

    if (indeterminate || isAllChecked) {
      // deselect all
      setTrainingTopics([])
    } else {
      // select all
      setTrainingTopics(trainingTopicsCatalog.map(o => o.name))
    }
  }

  const trainingTopicsColumns = [
    {
      field: 'selected',
      headerName: 'Select',
      flex: 0.25,
      filterable: false,
      sortable: false,
      renderHeader: (params) => {
        return (
          <>
            <Checkbox
              id='checkbox-all'
              disabled={lock}
              checked={isAllChecked}
              indeterminate={trainingTopics.length > 0 && (trainingTopics.length !== trainingTopicsCatalog.length)}
              onClick={handleAllCheckboxesSelected}
            />
          </>
        )
      },
      renderCell: (params) => {
        return (
          <Checkbox
            id={`checkbox-${params.row.id}`}
            disabled={lock}
            checked={trainingTopics.includes(params.row.name)}
            value={params.row.name}
            onClick={() => handleCheckboxChange(params.row.name)}
          />
        )
      }
    },
    { field: 'name', headerName: 'Topic Name', flex: 1.5 }
  ]

  return (
    <>
      {trainingTopicsCatalog.length > 0 && (
        <DataGrid
          autoHeight
          style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
          sx={(lock) ? { pointerEvents: 'none', opacity: '0.6' } : {}}
          className='DataGrid'
          rows={trainingTopicsCatalog}
          columns={trainingTopicsColumns}
          pageSize={rowCount}
          rowsPerPageOptions={[rowCount]}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true }
            }
          }}
          disableRowSelectionOnClick
          disableColumnSelector
          disableColumnMenu
          disableDensitySelector
          disableMultipleRowSelection
        />
      )}
    </>
  )
}
