import { useSelector } from 'react-redux'
import { integrationTypes, partnerIntegrationStatuses } from '../../../../frontendConsts'
import { ConnectWiseIntegrationSetup } from './ConnectWiseIntegrationSetup'
import { ConnectWiseFeatureSetup } from './ConnectWiseFeatureSetup'

export function ConnectWiseIntegrationTab ({ partnerId }) {
  const { partnerIntegrationsMap } = useSelector((state) => state.partnerIntegrations)
  const isIntegrationConnected = partnerIntegrationsMap[integrationTypes.CONNECTWISE]?.integrationStatus === partnerIntegrationStatuses.ENABLED

  if (isIntegrationConnected) {
    return (
      <ConnectWiseFeatureSetup partnerId={partnerId} />
    )
  }

  return (
    <ConnectWiseIntegrationSetup partnerId={partnerId} />
  )
}
