import { Alert, Grid, Skeleton } from '@mui/material'
import { useSelector } from 'react-redux'
import { PartnerAutomatedBillingForm } from './PartnerAutomatedBillingForm'

export function EnableAutomatedBillingWidget () {
  const { partner } = useSelector((state) => state.partner)
  const { isAutomaticBillingReady, loaders } = useSelector((state) => state.partnerBilling)
  const { isLoadingIsAutomaticBillingReady } = loaders

  return (
    <>
      {(partner && !partner.automaticBillingEnabled) && (
        <>
          {(isLoadingIsAutomaticBillingReady) && (
            <Skeleton width={750} height={400} />
          )}

          {(!isLoadingIsAutomaticBillingReady && !isAutomaticBillingReady) && (
            <div className='margin-bottom:2'>
              <Grid
                container
                s={4}
              >
                <Alert severity='warning' variant='outlined' sx={{ background: 'var(--phin-light-yellow)' }}>
                  Unable to configure Automated Billing. Please reach out to <a href='https://www.phinsec.io/knowledge/kb-tickets/new'> Phin Support.</a>
                </Alert>
              </Grid>
            </div>
          )}

          {(!isLoadingIsAutomaticBillingReady && (isAutomaticBillingReady || partner.freeTrial) && (partner && !partner.automaticBillingEnabled)) && (
            <PartnerAutomatedBillingForm />
          )}
        </>
      )}
    </>
  )
}
