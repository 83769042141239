import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Grid, Button, IconButton } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { IoAdd, IoTrash } from 'react-icons/io5'
import { deleteAdminThunk } from '../../../store/DistributorSlice'
import PhinModal from '../PhinModal'

function DistributorAdminManagementTable ({ distributor, history }) {
  const [distributorTableRows, setDistributorTableRows] = useState({})
  const [tablePageSize, setTablePageSize] = useState(10)
  const [adminToDeleteUid, setAdminToDeleteId] = useState('')
  const [deleteAdminConfirm, setDeleteAdminConfirm] = useState(false)

  const dispatch = useDispatch()

  const deleteAdmin = async (uid, id) => {
    const success = await dispatch(deleteAdminThunk({ uid, id }))

    if (success) {
      setDeleteAdminConfirm(false)
    }
  }

  useEffect(() => {
    if (distributor) {
      const distributorAdminRows = []
      distributor.admins.forEach((admin) => {
        distributorAdminRows.push({
          ...admin
        })
      })
      setDistributorTableRows(distributorAdminRows)
    }
  }, [distributor])

  const generateDeleteButton = (id, email) => {
    return (
      <IconButton
        id={`delete-admin-${email}-button`}
        style={{ color: 'var(--phin-red)' }}
        onClick={() => { setAdminToDeleteId(id); setDeleteAdminConfirm(true) }}
      >
        <IoTrash />
      </IconButton>
    )
  }

  const distributorTableColumns = [
    {
      headerName: 'Name',
      field: 'name',
      flex: 1
    },
    {
      headerName: 'Email Address',
      field: 'email',
      flex: 1
    },
    {
      headerName: 'Action',
      field: 'uid',
      renderCell: (params) => generateDeleteButton(params.id, params.row.email),
      width: 200,
      sortable: false,
      filterable: false
    }
  ]

  return (
    <>
      <PhinModal
        isOpen={deleteAdminConfirm}
        title='Delete Admin'
        close={() => setDeleteAdminConfirm(!deleteAdminConfirm)}
        action={() => deleteAdmin(adminToDeleteUid, distributor.id)}
        actionText='Delete'
        actionColor='error'
      >
        <p>Are you sure you want to delete this admin? <strong>This can not be undone!</strong></p>
      </PhinModal>
      <Grid container>
        <Grid container sx={{ mb: 1 }}>
          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'Left' }}>
            <h4 className='phin-h4' style={{ textAlign: 'left' }}>Administrators</h4>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <span>These are admins who can see multiple clients in your partner network.</span>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              id='add-admin-button'
              aria-label='Add an Admin Button'
              variant='contained'
              startIcon={<IoAdd />}
              style={{ height: '3em' }}
              onClick={() => {
                history.push(`/distributors/${distributor.id}/create-admin`)
              }}
            >
              ADD AN ADMIN
            </Button>
          </Grid>
        </Grid>
        <div style={{ width: '100%' }}>
          <DataGrid
            getRowId={(row) => row.uid}
            style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
            className='DataGrid'
            initialState={{
              sorting: {
                sortModel: [{ field: 'name', sort: 'asc' }]
              }
            }}
            autoHeight
            rows={distributorTableRows}
            columns={distributorTableColumns}
            pageSize={tablePageSize}
            onPageSizeChange={(newSize) => setTablePageSize(newSize)}
            rowsPerPageOptions={[10, 25, 50]}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500, id: 'distributor-admin-table-search', 'aria-label': 'Search' },
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true }
              }
            }}
            disableSelectionOnClick
            disableColumnSelector
            disableDensitySelector
            disableColumnFilter
            disableColumnMenu
          />
        </div>
      </Grid>
    </>
  )
}

export default DistributorAdminManagementTable
