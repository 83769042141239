import React, { useEffect } from 'react'
import { Button, Grid } from '@mui/material'
import { GridToolbarContainer, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid'
import { useStartAutomapMutation } from '../../../../store/ConnectWiseApiSlice'
import NotificationUtilities from '../../notifications/notificationUtils'

export function TableHeader ({ partnerId, isButtonDisabled }) {
  const [startAutomap, { isLoading: isLoadingAutomap, isSuccess: isStartAutomapSuccess, error: startAutomapError }] = useStartAutomapMutation()

  useEffect(() => {
    if (isStartAutomapSuccess) {
      NotificationUtilities.sendSuccessMessage('Connectwise Syncing process started successfully. Please wait a moment for the sync to complete')
    } else if (startAutomapError) {
      NotificationUtilities.sendErrorMessage("Connectwise Syncing process couldn't be started. Please try again or contact Phin support if the issue persists.")
    }
  }, [isStartAutomapSuccess, startAutomapError])

  return (
    <GridToolbarContainer>
      <Grid container justifyContent='space-between' alignItems='center'>
        <Grid item>
          <GridToolbarFilterButton />
          <Button
            id='auto-map-button'
            sx={{ textTransform: 'none' }}
            onClick={() => startAutomap({ partnerId })}
            disabled={isButtonDisabled || isLoadingAutomap}
          >Auto-Map
          </Button>
        </Grid>
        <GridToolbarQuickFilter />
      </Grid>
    </GridToolbarContainer>
  )
}
