import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ConfigQuestion from './components/resourceConfig/ConfigQuestion'
import NotificationUtilities from './components/notifications/notificationUtils'
import ContinuousCampaignViewer from './components/partnersPage/ContinuousCampaignViewer'
import ContinuousCampaignLauncher from './components/partnersPage/ContinuousCampaignLauncher'
import { SharedStepper } from '../shared/components/SharedStepper'
import { IoArrowBackOutline, IoBook, IoHelp, IoTime } from 'react-icons/io5'

import { useDispatch, useSelector } from 'react-redux'
import Spinner from './components/Spinner'
import { getConfigQuestionsThunk, updateOnboardingChecklistDataThunk } from '../store/CompanySlice'
import { createContinuousCampaignsThunk, getActiveCampaignsThunk, getBaseTrainingTopicsForCampaignThunk } from '../store/CampaignsSlice'
import { Button, Step } from '@mui/material'
import { calculateStartTime } from '../utils/dateUtils'
import { STYLE } from '../frontendConsts'
import { DateTime } from 'luxon'

function ClientAutoConfig ({ companyId }) {
  const dispatch = useDispatch()
  const { company, configQuestions, loaders } = useSelector((state) => state.company)
  const { isLoadingCompany, isLoadingConfigQuestions } = loaders
  const { activeCampaigns, baseTrainingCampaignTopics, loaders: campaignLoaders } = useSelector((state) => state.campaigns)
  const { isLoadingActiveCampaigns, isLoadingCreateContinuousCampaigns } = campaignLoaders

  const history = useHistory()
  const [activeTab, setActiveTab] = useState(0)
  const phishingDescription = 'Phin generated continuous phishing campaign'
  const trainingDescription = 'Phin generated continuous training campaign'

  const [phishingCampaignName, setPhishingCampaignName] = useState('')
  const [trainingCampaignName, setTrainingCampaignName] = useState('')
  const [phishingCategories, setPhishingCategories] = useState(['notification', 'current event', 'healthcare', 'unsubscribe', 'giveaway'])
  const [trainingTopics, setTrainingTopics] = useState([])
  const [questionsCollection, setQuestionsCollection] = useState([])
  const [phishingStartTime, setPhishingStartTime] = useState(calculateStartTime(DateTime.now()))
  const [phishingAttemptWindowDays, setPhishingAttemptWindowDays] = useState(3)
  const campaignFrequency = useSelector(state => state.campaignFrequency)
  const trainingCampaignFrequency = useSelector(state => state.trainingCampaignFrequency)
  const [trainingStartTime, setTrainingStartTime] = useState(calculateStartTime(DateTime.now()))
  const [isCampaignFrequencyWeeksValid, setIsCampaignFrequencyWeeksValid] = useState(true)
  const [recurringScheduleErrorPhishing, setRecurringScheduleErrorPhishing] = useState(false)
  const [recurringScheduleErrorTraining, setRecurringScheduleErrorTraining] = useState(false)
  const [firstFireDatePhishing, setFirstFireDatePhishing] = useState(DateTime.now().set({ hour: 9, minute: 0, second: 0, millisecond: 0 }).plus({ days: 1 }))
  const [firstFireDateTraining, setFirstFireDateTraining] = useState(DateTime.now().set({ hour: 9, minute: 0, second: 0, millisecond: 0 }).plus({ days: 1 }))

  useEffect(() => {
    dispatch(getConfigQuestionsThunk(companyId))
    dispatch(getBaseTrainingTopicsForCampaignThunk(companyId))
    if (!activeCampaigns) {
      dispatch(getActiveCampaignsThunk(companyId))
    }
  }, [])

  useEffect(() => {
    if (baseTrainingCampaignTopics.length > 0) {
      setTrainingTopics([...baseTrainingCampaignTopics])
    }
  }, [baseTrainingCampaignTopics])

  useEffect(() => {
    if (company) {
      setPhishingCampaignName(company.name + ' Continuous Phishing Campaign')
      setTrainingCampaignName(company.name + ' Continuous Training Campaign')
    }
  }, [company])

  useEffect(() => {
    if (configQuestions) {
      setQuestionsCollection(JSON.parse(JSON.stringify(configQuestions)))
    }
  }, [configQuestions])

  const NEXT = 'next'
  const PREVIOUS = 'previous'

  const changeTab = (direction) => {
    if (activeTab === (questionsCollection.length - 1) + 2) {
      if (direction === NEXT) {
        submit()
      } else {
        setActiveTab(activeTab - 1)
      }
    } else if (activeTab === 0) {
      if (direction === NEXT) {
        setActiveTab(activeTab + 1)
      } else {
        history.goBack()
      }
    } else {
      if (direction === NEXT) {
        setActiveTab(activeTab + 1)
      } else {
        setActiveTab(activeTab - 1)
      }
    }
  }

  const validateSurvey = () => {
    let isValid = true
    questionsCollection.forEach((question, index) => {
      if (question.answer === undefined) {
        if (isValid) {
          setActiveTab(index)
        }
        isValid = false
      }
    })
    if (!isValid) {
      NotificationUtilities.sendErrorMessage('Please fill out all the questions to create your campaigns.')
    }
    return isValid
  }

  const submit = async () => {
    if (validateSurvey()) {
      if (recurringScheduleErrorPhishing) {
        NotificationUtilities.sendErrorMessage(recurringScheduleErrorPhishing)
        return
      }

      if (recurringScheduleErrorTraining) {
        NotificationUtilities.sendErrorMessage(recurringScheduleErrorTraining)
        return
      }

      const phishingCampaign = {
        campaignType: 'continuous',
        phishingCategories,
        learningTypes: {
          custom: false,
          lm: true,
          video: false
        },
        name: phishingCampaignName,
        description: phishingDescription,
        parameters: [{
          field: 'first',
          type: '!=',
          value: '~~~'
        }],
        audienceFilterType: 'first',
        sendInterval: 'monthly',
        sendingChannels: {
          email: true,
          sms: false
        },
        startTime: phishingStartTime,
        isContinuous: true,
        isAutoGenerated: true,
        phishingAttemptWindowDays,
        firstFireDate: firstFireDatePhishing.toISO(),
        nextFireTimestamp: firstFireDatePhishing.toISO()
      }

      const trainingCampaign = {
        name: trainingCampaignName,
        description: trainingDescription,
        curriculum: {
          description: 'Phin generated curriculum',
          name: `${company.name} custom curriculum`,
          modules: []
        },
        startTime: trainingStartTime,
        audienceFilterType: 'first',
        audienceFilters: [{
          field: 'first',
          type: '!=',
          value: '~~~'
        }],
        campaignType: 'training',
        isContinuous: true,
        trainingTopics,
        isAutoGenerated: true,
        firstFireDate: firstFireDateTraining.toISO(),
        nextFireTimestamp: firstFireDateTraining.toISO()
      }

      await dispatch(createContinuousCampaignsThunk({ companyId, questionsCollection, trainingCampaign, phishingCampaign, history }))
      await dispatch(updateOnboardingChecklistDataThunk(companyId, { hasActiveCampaigns: true }))
    }
  }

  const renderStepper = (questionsCollection) => {
    const stepsArray = []
    for (const [index, value] of questionsCollection.entries()) {
      value.tab = index
      value.label = `Question ${index + 1}`
      value.icon = IoHelp
      stepsArray.push(value)
    }

    // adding the steps for continous campaign review

    stepsArray.push({
      tab: questionsCollection.length,
      label: 'Review',
      icon: IoBook
    },
    {
      tab: questionsCollection.length + 1,
      label: 'Scheduling',
      icon: IoTime
    })

    return (<><SharedStepper steps={stepsArray} activeTab={activeTab} setActiveTab={setActiveTab} /></>)
  }

  const addPhishingCategories = (newPhishingCategories) => {
    setPhishingCategories([...new Set(phishingCategories.concat(newPhishingCategories))])
  }

  const removePhishingCategories = (newPhishingCategories) => {
    newPhishingCategories.forEach(category => {
      const index = phishingCategories.indexOf(category)
      if (index !== -1) {
        phishingCategories.splice(index, 1)
      }
    })
    setPhishingCategories(phishingCategories)
  }

  const addTrainingTopic = (trainingTopic) => {
    if (!trainingTopics.includes(trainingTopic)) {
      setTrainingTopics([...trainingTopics, trainingTopic])
    }
  }

  const removeTrainingTopic = (trainingTopic) => {
    const index = trainingTopics.indexOf(trainingTopic)
    if (index !== -1) {
      const newTrainingTopics = [...trainingTopics]
      newTrainingTopics.splice(index, 1)
      setTrainingTopics(newTrainingTopics)
    }
  }

  return (
    <div>
      <div className='padding-bottom:1'>
        <Button
          variant='text'
          style={STYLE.BUTTONS.TERTIARY}
          color='primary' size='small' onClick={() => history.goBack()} startIcon={<IoArrowBackOutline />}
        >Return to Company Home
        </Button>
      </div>
      <div className='padding-bottom:2'>
        <h1>Continuous Campaign Creator</h1>
      </div>

      {(!company || !configQuestions || !activeCampaigns ||
        isLoadingCompany || isLoadingConfigQuestions ||
        isLoadingActiveCampaigns ||
        isLoadingCreateContinuousCampaigns) && (
          <Spinner />
      )}

      {(company && configQuestions && activeCampaigns &&
        !isLoadingCompany && !isLoadingConfigQuestions &&
        !isLoadingActiveCampaigns &&
        !isLoadingCreateContinuousCampaigns) && (
          <>
            {renderStepper(questionsCollection)}
            <Step icon='boxes' stepName='Resource Viewer' onClick={() => setActiveTab(questionsCollection.length)} />
            <Step icon='clock' stepName='Campaigns Launcher' onClick={() => setActiveTab(questionsCollection.length + 1)} />

            <div className='padding-top:2' />
            {(questionsCollection.length !== 0) &&

              <div>
                {(activeTab <= (questionsCollection.length - 1)) &&
                  <ConfigQuestion
                    index={activeTab}
                    questionsCollection={questionsCollection}
                    setQuestionsCollection={setQuestionsCollection}
                    addPhishingCategories={addPhishingCategories}
                    removePhishingCategories={removePhishingCategories}
                    addTrainingTopic={addTrainingTopic}
                    removeTrainingTopic={removeTrainingTopic}
                  />}

                {(activeTab === questionsCollection.length) &&
                  <ContinuousCampaignViewer
                    phishingDescription={phishingDescription}
                    phishingCampaignName={phishingCampaignName}
                    phishingCategories={phishingCategories}
                    trainingCampaignName={trainingCampaignName}
                    trainingDescription={trainingDescription}
                    trainingTopics={trainingTopics}
                  />}

                {(activeTab === (questionsCollection.length + 1)) &&
                  <ContinuousCampaignLauncher
                    company={company}
                    phishingStartTime={phishingStartTime}
                    setPhishingStartTime={setPhishingStartTime}
                    trainingStartTime={trainingStartTime}
                    setTrainingStartTime={setTrainingStartTime}
                    phishingAttemptWindowDays={phishingAttemptWindowDays}
                    setPhishingAttemptWindowDays={setPhishingAttemptWindowDays}
                    campaignFrequency={campaignFrequency}
                    trainingCampaignFrequency={trainingCampaignFrequency}
                    isCampaignFrequencyWeeksValid={isCampaignFrequencyWeeksValid}
                    firstFireDatePhishing={firstFireDatePhishing}
                    setFirstFireDatePhishing={setFirstFireDatePhishing}
                    firstFireDateTraining={firstFireDateTraining}
                    setFirstFireDateTraining={setFirstFireDateTraining}
                    setIsCampaignFrequencyWeeksValid={setIsCampaignFrequencyWeeksValid}
                    recurringScheduleErrorPhishing={recurringScheduleErrorPhishing}
                    setRecurringScheduleErrorPhishing={setRecurringScheduleErrorPhishing}
                    recurringScheduleErrorTraining={recurringScheduleErrorTraining}
                    setRecurringScheduleErrorTraining={setRecurringScheduleErrorTraining}
                  />}
                <div className='phin-flex-end padding-top:0'>
                  <span className='padding-right:-1'>
                    <Button
                      size='large'
                      id='cancel-previous-button'
                      aria-label='Cancel or Previous Button'
                      onClick={() => changeTab(PREVIOUS)}
                      variant={activeTab === 0 ? 'text' : 'outlined'}
                      style={activeTab === 0
                        ? STYLE.BUTTONS.TERTIARY
                        : {}}
                    >{(activeTab === 0) ? 'Cancel' : 'Previous'}
                    </Button>
                  </span>
                  <Button
                    size='large'
                    id='next-submit-button'
                    aria-label='Next or Submit Button'
                    onClick={() => changeTab(NEXT)}
                    color='primary'
                    variant='contained'
                  >{(activeTab === (questionsCollection.length - 1) + 2) ? 'Submit' : 'Next'}
                  </Button>
                </div>
              </div>}
          </>
      )}
    </div>
  )
}

export default ClientAutoConfig
