export function reverseMap (map) {
  const newMap = {}
  for (const [key, value] of Object.entries(map)) {
    newMap[value] = key
  }
  return newMap
}

export function makeUID (length) {
  let text = ''
  const possible = 'abcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < length; i++) { text += possible.charAt(Math.floor(Math.random() * possible.length)) }

  return text
}
