import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { campaignTypes, WEEK_DAYS, CAMPAIGN_SCHEDULE_PREVIEWER_DATE_DETERMINER } from '../../../frontendConsts'
import { useEffect, useState } from 'react'
import { determinePhishingScheduleString } from '../../../utils/dateUtils'
import { useSelector } from 'react-redux'

export const CampaignSchedulePreviewer = ({ campaign, nextTraining = null }) => {
  const [nextPhishingSendTime, setNextPhishingSendTime] = useState(null)
  const companyTimeZone = useSelector((state) => state.company?.company?.timezone?.value)

  const campaignSchedule = campaign?.campaignFrequency
  const campaignType = (campaign?.campaignType === campaignTypes.FIXEDLENGTH || campaign?.campaignType === campaignTypes.CONTINUOUS) ? 'phishing' : 'training'
  const assignmentTypeText = campaignType === 'phishing' ? 'Phishing' : 'Courses'

  const determineScheduleString = () => {
    let result
    if (campaignSchedule.iterations === 'All At Once') {
      result = `${campaignSchedule.iterations} at ${new Date(campaign.startTime).toLocaleString()}`
    } else if (campaignSchedule.frequency === 'Quarter') {
      result = ` ${campaignSchedule.iterations} a ${campaignSchedule.frequency} in the ${CAMPAIGN_SCHEDULE_PREVIEWER_DATE_DETERMINER[campaignSchedule.month]} Month on the ${determineScheduleMonthDays()} week on ${WEEK_DAYS[campaignSchedule.weekday]}`
    } else if (campaignSchedule.frequency === 'Month') {
      result = ` ${campaignSchedule.iterations} a ${campaignSchedule.frequency} on the ${determineScheduleMonthDays()} week on ${WEEK_DAYS[campaignSchedule.weekday]}`
    } else if (campaignSchedule.frequency === 'Week') {
      result = ` ${campaignSchedule.iterations} a Week on ${WEEK_DAYS[campaignSchedule.weekday]}`
    }
    return result
  }

  useEffect(() => {
    if (campaignSchedule && companyTimeZone) {
      const phishingSendTime = determinePhishingScheduleString(campaign, companyTimeZone)
      setNextPhishingSendTime(phishingSendTime)
    }
  }, [campaign])

  const determineScheduleMonthDays = () => {
    let dateString = ''
    if (campaignSchedule.week?.length === 1) {
      dateString = CAMPAIGN_SCHEDULE_PREVIEWER_DATE_DETERMINER[campaignSchedule.week[0]]
    } else {
      dateString = `${CAMPAIGN_SCHEDULE_PREVIEWER_DATE_DETERMINER[campaignSchedule.week[0]]} and ${CAMPAIGN_SCHEDULE_PREVIEWER_DATE_DETERMINER[campaignSchedule.week[1]]}`
    }
    return dateString
  }

  const generateMessageString = () => {
    if (campaignType === 'phishing') {
      return 'New Phishing messages are scheduled:'
    } else {
      return 'New Courses are scheduled:'
    }
  }

  if (!campaign) return null
  return (
    <Box sx={{ paddingBottom: '2em', width: '100%', height: 'auto' }}>
      <h3 className='phin-h4' style={{ paddingBottom: '0.5em' }}>Schedule</h3>
      <Box sx={{ display: 'flex', borderRadius: '8px', border: '2px solid var(--phin-light-gray)', padding: '1rem', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant='body2'>{generateMessageString()}
          <Typography id='campaign-schedule-text' sx={{ fontWeight: 'bold', color: 'var(--phin-blue)' }}>{determineScheduleString()}</Typography>
        </Typography>
        {nextTraining && (
          <Typography sx={{ padding: '1rem', background: '#EAF5FF' }} variant='body2'> Next Assignment
            <Typography sx={{ fontWeight: 'bold' }}>{` ${nextTraining.name} @ ${new Date(nextTraining.scheduledDate).toLocaleString()}`}</Typography>
          </Typography>
        )}
        {!nextTraining && nextPhishingSendTime && (
          <Typography sx={{ padding: '1rem', background: '#EAF5FF' }} variant='body2'> Next Phishing Attempt
            <Typography sx={{ fontWeight: 'bold' }}>{`${nextPhishingSendTime.start} - ${nextPhishingSendTime.end}`}</Typography>
          </Typography>
        )}
        {(!nextTraining && !nextPhishingSendTime) && (
          <Typography sx={{ padding: '1rem', background: '#EAF5FF', fontStyle: 'italic' }}>{`No New ${assignmentTypeText} to be Assigned`} </Typography>
        )}
      </Box>
    </Box>
  )
}
