import React from 'react'
import { useHistory } from 'react-router-dom'
import { MDBRow, MDBContainer } from 'mdb-react-ui-kit'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import Spinner from './components/Spinner'
import PartnerManagementTable from './components/partnerManagement/PartnerManagementTable'
import DistributorAdminManagementTable from './components/partnerManagement/DistributorAdminManagementTable'

const PartnerOverviewPage = () => {
  const history = useHistory()
  const { isLoadingCreateAdmin, isLoadingDistributor, isLoadingAddPartner } = useSelector((state) => state.distributor.loaders)

  const distributor = useSelector((state) => state.distributor.distributor)

  return (
    <MDBContainer>
      <Box sx={{ marginBottom: 4 }}>
        <h1>Distributor Home </h1>
      </Box>

      <MDBRow size='12' className='d-flex justify-content-center'>
        <MDBContainer fluid>
          {(!distributor || isLoadingDistributor || isLoadingAddPartner) && (
            <Spinner message='Loading Distributor Data' />
          )}
          {(distributor && !isLoadingDistributor && !isLoadingAddPartner) && (
            <PartnerManagementTable
              distributor={distributor}
              history={history}
            />
          )}
        </MDBContainer>
      </MDBRow>
      <br />
      <MDBRow size='12' className='d-flex justify-content-center'>
        <MDBContainer fluid style={{ marginBottom: '4em' }}>
          {distributor && !isLoadingDistributor && !isLoadingCreateAdmin && (
            <DistributorAdminManagementTable
              distributor={distributor}
              history={history}
            />
          )}
        </MDBContainer>
      </MDBRow>
    </MDBContainer>
  )
}

export default PartnerOverviewPage
