import React from 'react'
import { campaignTypes } from '../../../frontendConsts.js'
import { StaticAlert } from '../../../shared/components/StaticAlert.js'
import { Collapse, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import { PhinCard } from '../PhinCard.js'

export function CampaignTypeSelector ({ campaignType, setCampaignType }) {
  return (
    <PhinCard
      title='Campaign Type'
    >
      <Grid
        container
        direction='column'
        justifyContent='space-between'
        alignItems='center'
      >

        <p>
          This will control the frequency messages will be sent to users during the campaign. A fixed-length campaign will run from start until an end date you choose. You can also select continuous which will run automatically from when you start it until you stop it.
        </p>

        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          sx={{ paddingLeft: '16px', paddingRight: '16px' }}
        >
          <FormControl>
            <RadioGroup
              aria-label='campaign-type-radio-buttons-group'
              defaultValue='Fixed Length'
              name='radio-buttons-group'
              value={campaignType}
              onChange={(event) => setCampaignType(event.target.value)}
              row
            >
              <FormControlLabel
                key='fixed length'
                id='fixed length-radio-button'
                value='fixed length'
                control={<Radio />}
                label='Fixed Length'
                aria-label='Fixed Length Radio Button'
              />
              <FormControlLabel
                key='continuous'
                id='continuous-radio-button'
                value='continuous'
                control={<Radio />}
                label='Continuous'
                aria-label='Continuous Radio Button'
              />
            </RadioGroup>
          </FormControl>

        </Grid>

        <div className='margin:0'>
          <Collapse in={campaignType === campaignTypes.CONTINUOUS} {...(campaignType === campaignTypes.CONTINUOUS ? { timeout: 500 } : {})}>
            <StaticAlert
              severity='warning' color='warning' text='Due to the large number of templates a continuous campaign uses, previewing this campaign before launch is not available'
            />
          </Collapse>
        </div>
      </Grid>
    </PhinCard>
  )
}

export default CampaignTypeSelector
