import { useEffect, useState } from 'react'
import { MDBRow, MDBContainer, MDBCol } from 'mdb-react-ui-kit'
import CampaignTable from './components/Campaigns/CampaignTable'
import HUDStats from './components/Campaigns/modules/HUDData'
import { downloadCSV } from '../utils/fileUtils'
import { useHistory } from 'react-router'
import NotificationUtilities from './components/notifications/notificationUtils'
import { hasAccessToSessionStorage } from '../utils/localStorageUtils'

import { navigationRoutes, campaignTypes } from '../frontendConsts.js'
import './css/hud.css'

import { useDispatch, useSelector } from 'react-redux'
import { cancelPhishingCampaignThunk } from '../store/CampaignsSlice'
import Spinner from './components/Spinner'
import { apiRequestUtils } from '../utils/apiRequestUtils'
import { StaticAlert } from '../shared/components/StaticAlert.js'
import { Button } from '@mui/material'
import PhinModal from './components/PhinModal.js'
import { IoAddCircleSharp, IoRocket } from 'react-icons/io5'

function PhishingDashboard ({ companyId }) {
  const history = useHistory()
  const dispatch = useDispatch()

  const { company, loaders } = useSelector((state) => state.company)
  const { isLoadingCompany } = loaders
  const { activeCampaigns, pastCampaigns, loaders: campaignsLoaders } = useSelector((state) => state.campaigns)
  const { isLoadingActiveCampaigns, isLoadingPastCampaigns } = campaignsLoaders

  const supportsSessionStorage = hasAccessToSessionStorage()

  const [campaignHUDCache, setCampaignHUDCache] = useState({})
  const [campaignHUDData, setCampaignHUDData] = useState({})
  const [focusedCampaign, setFocusedCampaign] = useState(-1)

  const [cancelCampaignModal, setCancelCampaignModal] = useState(false)
  const [cancelCampaignId, setCancelCampaignId] = useState('')
  const [phishingCampaigns, setPhishingCampaigns] = useState([])
  const [pastPhishingCampaigns, setPastPhishingCampaigns] = useState([])

  useEffect(() => {
    document.title = 'Phin Security | Phishing Campaigns'

    if (activeCampaigns) {
      if (supportsSessionStorage) {
        const selected = parseFloat(window.sessionStorage.getItem(`focusedHud-${companyId}`) || -1)
        if (selected >= 0) {
          focusCampaign(activeCampaigns[selected], selected)
        }
      }
    }
  }, [activeCampaigns])

  useEffect(() => {
    if (activeCampaigns) {
      setPhishingCampaigns(activeCampaigns.filter(campaign => campaign.campaignType === campaignTypes.CONTINUOUS || campaign.campaignType === campaignTypes.FIXEDLENGTH))
    }
    if (pastCampaigns) {
      setPastPhishingCampaigns(pastCampaigns.filter(campaign => campaign.campaignType === campaignTypes.CONTINUOUS || campaign.campaignType === campaignTypes.FIXEDLENGTH))
    }
  }, [activeCampaigns, pastCampaigns])

  async function handleCSVDownload (campaignId, campaignName, campaignStatus) {
    try {
      await downloadCSV(companyId, campaignId, campaignName, campaignStatus)
    } catch (err) {
      console.error(err)
      NotificationUtilities.sendErrorMessage('Something went wrong when generating your stats!')
    }
  }

  function handleCancelCampaign (campaignId) {
    setCancelCampaignModal(true)
    setCancelCampaignId(campaignId)
  }

  function handleEditCampaign (campaignId) {
    history.push(`/companies/${companyId}${navigationRoutes.CAMPAIGNEDITOR}`, { edit: true, campaignId })
  }

  async function cancelCampaign () {
    const canceledCampaignId = await dispatch(cancelPhishingCampaignThunk({ companyId, cancelCampaignId }))

    if (canceledCampaignId) {
      if (campaignHUDData.campaignId === canceledCampaignId) {
        setFocusedCampaign(-1)
        setCampaignHUDData({})
      }
    }

    setCancelCampaignId('')
    setCancelCampaignModal(false)
  }

  async function goGetStats (campaignId, startTime, endTime, name, sendInterval) {
    try {
      const res = await apiRequestUtils.get(`/api/companies/${companyId}/campaigns/${campaignId}/stats/hud?startTime=${startTime}&endTime=${endTime}&name=${name}&sendInterval=${sendInterval}`)
      return await res.json()
    } catch (err) {
      console.error(err)
    }
  }

  async function focusCampaign (campaign) {
    if (!campaign) {
      return
    }

    setFocusedCampaign(campaign)

    const campaignStats = campaignHUDCache[campaign.id]

    // Store this campaign in session
    if (supportsSessionStorage) {
      window.sessionStorage.setItem(`focusedHud-${companyId}`, campaign.id)
    }

    if (campaignStats === undefined) {
      try {
        if (campaign.campaignType === campaignTypes.TRAINING) {
          return
        }
        const stats = await goGetStats(campaign.id, campaign.startTime, campaign.endTime, campaign.name, campaign.sendInterval)

        stats.campaignHUDdata.campaignType = campaign.campaignType
        const newCache = Object.assign({}, campaignHUDCache)
        newCache[campaign.id] = stats.campaignHUDdata
        setCampaignHUDCache(newCache)
        setCampaignHUDData(stats.campaignHUDdata)
      } catch (err) {
        console.error(err)
      }
    } else {
      setCampaignHUDData(campaignStats)
    }
  }

  function renderHUDStats () {
    if (!campaignHUDData || campaignHUDData.name === undefined) {
      if (phishingCampaigns.length !== 0) {
        return <HUDStats disabled campaignHUDdata={campaignHUDData} campaigns={phishingCampaigns} />
      } else {
        return <></>
      }
    } else {
      return <HUDStats disabled={false} campaignHUDdata={campaignHUDData} campaigns={phishingCampaigns} />
    }
  }

  function handleCreateCampaign () {
    history.push(`/companies/${companyId}${navigationRoutes.CAMPAIGNCREATOR}`)
  }

  function handleLaunchPreset () {
    history.push(`/companies/${companyId}${navigationRoutes.CAMPAIGNLAUNCHER}`)
  }

  return (
    <MDBContainer>
      <PhinModal
        isOpen={cancelCampaignModal}
        title='Delete Campaign'
        close={() => setCancelCampaignModal(false)}
        action={() => cancelCampaign()}
        closeText='Continue Campaign'
        actionText='Delete Campaign'
        actionColor='error'
      >
        <p>Are you sure you would like to delete this campaign? <strong>This can not be undone!</strong></p>
      </PhinModal>

      <MDBRow className='d-flex justify-content-center align-items-center mb-4'>
        <MDBCol className='w-100 text-center'>
          <h1>Phishing Dashboard</h1>
          <p>Configure and manage phishing campaigns across your organization</p>
        </MDBCol>
      </MDBRow>

      <MDBRow className='d-flex justify-content-center mb-5'>
        <MDBCol xl='4' md='4' className='d-flex justify-content-center align-items-center'>
          <Button variant='contained' onClick={handleCreateCampaign} color='primary'>
            <MDBRow className='d-flex justify-content-center align-items-center'>
              <MDBCol size='2' className='pr-1 pl-0'>
                <IoAddCircleSharp size='3em' />
              </MDBCol>
              <MDBCol className='pr-0 pl-0' id='startNewCampaignBtn' size='9'>
                Launch a Phishing Campaign
              </MDBCol>
            </MDBRow>
          </Button>
        </MDBCol>
        <MDBCol xl='4' md='4' className='d-flex justify-content-center align-items-center'>
          <Button variant='contained' onClick={handleLaunchPreset} color='secondary'>
            <MDBRow className='d-flex justify-content-center align-items-center'>
              <MDBCol size='2' className='pr-1 pl-0'>
                <IoRocket size='3em' />
              </MDBCol>
              <MDBCol className='pr-0 pl-0' id='launchNewCampaignBtn' size='9'>
                Launch a Preset Phishing Campaign
              </MDBCol>
            </MDBRow>
          </Button>
        </MDBCol>
      </MDBRow>

      {(!company || isLoadingCompany ||
      !activeCampaigns || isLoadingActiveCampaigns ||
      !pastCampaigns || isLoadingPastCampaigns) && (
        <Spinner message='Loading Campaign Data' />
      )}

      {company && !isLoadingCompany &&
      activeCampaigns && !isLoadingActiveCampaigns &&
      pastCampaigns && !isLoadingPastCampaigns && (
        <>
          {renderHUDStats()}

          {company.totalDevices === 0 && (
            <div className='margin-bottom:2'>
              <StaticAlert severity='info' color='phinGray' variant='filled' text="You haven't added any users yet. Go upload some on the users page and then come back and create your first campaign!" />
            </div>
          )}

          <CampaignTable
            title='Active Campaigns'
            campaigns={phishingCampaigns}
            csvDownloadHandler={handleCSVDownload}
            handleCancelCampaign={handleCancelCampaign}
            handleEditCampaign={handleEditCampaign}
            focusedCampaign={focusedCampaign}
            focusCallback={focusCampaign}
            tz={company ? company.timezone.value : 'America/New_York'}
          />

          <CampaignTable
            title='Past Campaigns'
            campaigns={pastPhishingCampaigns}
            csvDownloadHandler={handleCSVDownload}
            pastCampaigns
            tz={company ? company.timezone.value : 'America/New_York'}
          />
        </>
      )}
    </MDBContainer>
  )
}

export default PhishingDashboard
