import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { updateOnboardingChecklistDataThunk } from '../store/CompanySlice'
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, Grid } from '@mui/material'
import { IoBan, IoCheckmarkCircle, IoColorPalette, IoMail, IoMegaphone, IoPaperPlane, IoPeopleCircle } from 'react-icons/io5'
import { CompanyHomePageBadge } from '../shared/components/CompanyHomePageBadge'
import PhinModal from './components/PhinModal'

function CompanyOnboardingChecklist ({
  id, companyName
}) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { onboardingChecklist } = useSelector((state) => state.company)

  const [currentCard, setCard] = useState('')
  const [stepToOverride, setStepToOverride] = useState(null)
  const [stepToOverrideText, setStepToOverrideText] = useState(null)
  const [showOverrideModal, setShowOverrideModal] = useState(false)

  const handleChange = (panel) => (event, newExpanded) => {
    setCard(newExpanded ? panel : false)
  }

  const selectOpenCard = () => {
    let defaultCard = ''
    if (!onboardingChecklist.hasPersonalizations) {
      defaultCard = 'personalizeCard'
    } else if (!onboardingChecklist.hasEmailSettings) {
      defaultCard = 'emailCard'
    } else if (!onboardingChecklist.hasWelcomedUsers) {
      defaultCard = 'welcomeCard'
    } else if (!onboardingChecklist.hasUsers) {
      defaultCard = 'userCard'
    } else if (!onboardingChecklist.hasActiveCampaigns) {
      defaultCard = 'campaignCard'
    }

    setCard(defaultCard)
  }

  useEffect(() => {
    selectOpenCard()
  }, [onboardingChecklist])

  const acknowledgeOverride = async () => {
    const updateObject = {}
    updateObject[stepToOverride] = true

    await dispatch(updateOnboardingChecklistDataThunk(id, updateObject))

    setStepToOverride(null)
    setStepToOverrideText(null)
    setShowOverrideModal(false)
  }

  const overrideChecklist = (stepToOverride, stepToOverrideText, e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation()
    }

    setStepToOverride(stepToOverride)
    setStepToOverrideText(stepToOverrideText)
    setShowOverrideModal(true)
  }

  return (
    <div>
      <PhinModal
        isOpen={showOverrideModal}
        title='Override Onboarding Step'
        close={() => setShowOverrideModal(false)}
        action={() => acknowledgeOverride()}
        actionText='Override'
      >
        <div style={{ textAlign: 'left' }}>
          <p>Are you sure you want to override this step: <b>{stepToOverrideText}</b>?</p>
          <p>This action cannot be undone.</p>
        </div>
      </PhinModal>

      <Card sx={{ backgroundColor: '#39A0FF', padding: 'var(--phin-s-1)', marginBottom: 'var(--phin-s3)' }}>
        <br />
        <Accordion expanded={currentCard === 'personalizeCard'} onChange={handleChange('personalizeCard')} sx={{ marginBottom: 'var(--phin-s0)' }}>
          <AccordionSummary aria-controls='panel1d-content' id='personalization-card'>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <IoColorPalette size='4em' style={{ color: 'var(--phin-white)', backgroundColor: '#0057B2', padding: 'var(--phin-s-1)' }} />
              <div className='phin-h4'>Personalization</div>
              <>
                {(onboardingChecklist.hasPersonalizations)
                  ? (
                    <CompanyHomePageBadge
                      id='setup-personalization-badge'
                      icon={<IoCheckmarkCircle size='3em' style={{ marginRight: 'var(--phin-s-2)' }} />}
                      color='var(--phin-green)'
                    />
                    )
                  : (
                    <Button
                      color='phinYellow'
                      variant='contained'
                      id='override-personalization-button'
                      onClick={(e) => overrideChecklist('hasPersonalizations', 'Personalization', e)}
                    >
                      <IoBan size='2em' />
                    </Button>
                    )}
              </>

            </Grid>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#184066', color: 'white' }}>
            <Grid
              container
              direction='row'
              justifyContent='space-evenly'
              alignItems='center'
              sx={{ marginBottom: 'var(--phin-s1)', marginTop: 'var(--phin-s0)' }}
            >
              <Button
                variant='contained'
                onClick={() => history.push(`/companies/${id}/branding`)}
                color='primary'
                id='personalize-button'
                aria-label='Personalize Button'
              >Personalize
              </Button>
              <Button
                variant='contained'
                onClick={() => history.push(`/companies/${id}/users`)}
                color='secondary'
                id='manage-company-admins-button'
                aria-label='Manage Company Admins Button'
              >Manage Company Admins
              </Button>
            </Grid>

            <div className='phin-h5'>
              Personalize this company's experience by
              configuring a logo or learning moment image,
              adding {companyName} admins to help manage
              or view this company, or configure notification emails
              for weekly company activity reports.
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={currentCard === 'emailCard'} onChange={handleChange('emailCard')} sx={{ marginBottom: 'var(--phin-s0)' }}>
          <AccordionSummary aria-controls='panel2d-content' id='email-settings-card'>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <IoMail size='4em' style={{ color: 'var(--phin-white)', backgroundColor: '#0057B2', padding: 'var(--phin-s-1)' }} />
              <div className='phin-h4'>Email Settings</div>
              <>
                {(onboardingChecklist.hasEmailSettings)
                  ? (
                    <CompanyHomePageBadge
                      icon={<IoCheckmarkCircle size='3em' style={{ marginRight: 'var(--phin-s-2)' }} />}
                      color='var(--phin-green)'
                      id='setup-email-settings-badge'
                    />
                    )
                  : (
                    <Button
                      color='phinYellow'
                      variant='contained'
                      id='override-email-settings-button'
                      onClick={(e) => overrideChecklist('hasEmailSettings', 'Email Settings', e)}
                    >
                      <IoBan size='2em' />
                    </Button>
                    )}
              </>
            </Grid>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#184066', color: 'white' }}>
            <Grid
              container
              direction='row'
              justifyContent='space-evenly'
              alignItems='center'
              sx={{ marginBottom: 'var(--phin-s1)', marginTop: 'var(--phin-s0)' }}
            >
              <Button
                variant='contained'
                target='_blank'
                onClick={() => window.open('https://www.phinsec.io/knowledge/allow-listing-guide', '_blank').focus()}
                color='primary'
                id='review-allowlisting-guide-button'
                aria-label='Review Allowlisting Guide Button'
              >Review Allowlisting Guide
              </Button>
              <Button
                variant='contained'
                onClick={() => history.push(`/companies/${id}/settings#customHeader`)}
                color='secondary'
                id='enable-custom-header-button'
                aria-label='Enable Custom Header Button'
              >Enable Custom Header
              </Button>
            </Grid>

            <div className='phin-h5'>
              Complete Phin's IP Allowlisting guide to ensure this company's firewall does not block Phin's emails.
              Set a custom header to be attached to emails we send.
              You can use this value to find and filter our emails in other email products.
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={currentCard === 'welcomeCard'} onChange={handleChange('welcomeCard')} sx={{ marginBottom: 'var(--phin-s0)' }}>
          <AccordionSummary aria-controls='panel3d-content' id='welcome-email-card'>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <IoPaperPlane size='4em' style={{ color: 'var(--phin-white)', backgroundColor: '#0057B2', padding: 'var(--phin-s-1)' }} />
              <div className='phin-h4'>Configure Welcome Emails</div>
              <>
                {(onboardingChecklist.hasWelcomedUsers)
                  ? (
                    <CompanyHomePageBadge
                      id='setup-welcome-emails-badge'
                      icon={<IoCheckmarkCircle size='3em' style={{ marginRight: 'var(--phin-s-2)' }} />}
                      color='var(--phin-green)'
                    />
                    )
                  : (
                    <Button
                      color='phinYellow'
                      variant='contained'
                      id='override-welcome-emails-button'
                      onClick={(e) => overrideChecklist('hasWelcomedUsers', 'Welcome Users', e)}
                    >
                      <IoBan size='2em' />
                    </Button>
                    )}
              </>
            </Grid>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#184066', color: 'white' }}>
            <Grid
              container
              direction='row'
              justifyContent='space-evenly'
              alignItems='center'
              sx={{ marginBottom: 'var(--phin-s1)', marginTop: 'var(--phin-s0)' }}
            >
              <Button
                variant='contained'
                onClick={() => history.push(`/companies/${id}/branding#welcomeEmails`)}
                color='primary'
                id='enable-automatic-welcome-emails-button'
                aria-label='Enable Automatic Welcome Emails Button'
              >Enable Automatic Welcome Emails
              </Button>
              <Button
                variant='contained'
                onClick={() => history.push(`/companies/${id}/users`)}
                color='secondary'
                id='send-welcome-emails-button'
                aria-label='Send Welcome Emails Button'
              >Send Welcome Emails
              </Button>
            </Grid>

            <div className='phin-h5'>
              Enable automatic welcome email sending on the branding page so users get a notification when they are added or imported. The welcome
              message sent can also be customized on the branding page. If you prefer you can instead send welcome notifications directly
              to your users manually from the users page.
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={currentCard === 'userCard'} onChange={handleChange('userCard')} sx={{ marginBottom: 'var(--phin-s0)' }}>
          <AccordionSummary aria-controls='panel3d-content' id='user-management-card'>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <IoPeopleCircle size='4em' style={{ color: 'var(--phin-white)', backgroundColor: '#0057B2', padding: 'var(--phin-s-1)' }} />
              <div className='phin-h4'>User Management</div>
              <>
                {(onboardingChecklist.hasUsers)
                  ? (
                    <CompanyHomePageBadge
                      id='setup-user-management-badge'
                      icon={<IoCheckmarkCircle size='3em' style={{ marginRight: 'var(--phin-s-2)' }} />}
                      color='var(--phin-green)'
                    />
                    )
                  : (
                    <Button
                      color='phinYellow'
                      variant='contained'
                      id='override-user-management-button'
                      onClick={(e) => overrideChecklist('hasUsers', 'User Management', e)}
                    >
                      <IoBan size='2em' />
                    </Button>
                    )}
              </>
            </Grid>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#184066', color: 'white' }}>
            <Grid
              container
              direction='row'
              justifyContent='space-evenly'
              alignItems='center'
              sx={{ marginBottom: 'var(--phin-s1)', marginTop: 'var(--phin-s0)' }}
            >
              <Button
                variant='contained'
                onClick={() => history.push(`/companies/${id}/integrations/userSync`)}
                color='primary'
                id='sync-users-with-azure-button'
                aria-label='Sync Users with Azure Button'
              >Sync users with Azure
              </Button>
              <Button
                variant='contained'
                onClick={() => history.push(`/companies/${id}/users`)}
                color='secondary'
                id='manually-import-users-button'
                aria-label='Manually Import Users Button'
              >Manually import users
              </Button>
            </Grid>

            <div className='phin-h5'>
              Turn on Phin's Azure User Sync integration to automatically
              import employees from this company's Microsoft Azure instance.
              Available in Azure AD (global service) and
              Azure AD for US Government.
              Separately, manually upload users using Phin's CSV upload tool
              or by adding users individually.
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={currentCard === 'campaignCard'} onChange={handleChange('campaignCard')} sx={{ marginBottom: 'var(--phin-s0)' }}>
          <AccordionSummary aria-controls='panel3d-content' id='campaign-card'>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <IoMegaphone size='4em' style={{ color: 'var(--phin-white)', backgroundColor: '#0057B2', padding: 'var(--phin-s-1)' }} />
              <div className='phin-h4'>Training and Phishing Campaigns</div>
              <>
                {(onboardingChecklist.hasActiveCampaigns)
                  ? (
                    <CompanyHomePageBadge
                      id='setup-campaigns-badge'
                      icon={<IoCheckmarkCircle size='3em' style={{ marginRight: 'var(--phin-s-2)' }} />}
                      color='var(--phin-green)'
                    />
                    )
                  : (
                    <Button
                      color='phinYellow'
                      variant='contained'
                      id='override-campaigns-button'
                      onClick={(e) => overrideChecklist('hasActiveCampaigns', 'Training and Phishing Campaigns', e)}
                    >
                      <IoBan size='2em' />
                    </Button>
                    )}
              </>
            </Grid>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#184066', color: 'white' }}>
            <Grid
              container
              direction='row'
              justifyContent='space-evenly'
              alignItems='center'
              sx={{ marginBottom: 'var(--phin-s1)', marginTop: 'var(--phin-s0)' }}
            >
              <Button
                variant='contained'
                onClick={() => history.push(`/companies/${id}/config-wizard`)}
                color='primary'
                id='continuous-campaign-creator-button'
                aria-label='Continuous Campaign Creator Button'
              >Continuous Campaign Creator
              </Button>
            </Grid>
            <Grid
              container
              direction='row'
              justifyContent='space-evenly'
              alignItems='center'
              sx={{ marginBottom: 'var(--phin-s1)', marginTop: 'var(--phin-s0)' }}
            >
              <Button
                variant='contained'
                onClick={() => history.push(`/companies/${id}/training`)}
                color='phinGreen'
                id='training-dashboard-button'
                aria-label='Training Dashboard Button'
              >Training Dashboard
              </Button>
              <Button
                variant='contained'
                onClick={() => history.push(`/companies/${id}/phishing/campaigns`)}
                color='secondary'
                id='phishing-dashboard-button'
                aria-label='Phishing Dashboard Button'
              >Phishing Dashboard
              </Button>
            </Grid>

            <div className='phin-h5'>
              Create continuous Training and Phishing campaigns
              with Phin's Continuous Campaign Creator.
              Schedule Training Campaigns or manually launch
              a training module from the Training Dashboard.
              Schedule continuous or fixed-length Phishing
              campaigns from the Phishing Dashboard.
            </div>
          </AccordionDetails>
        </Accordion>
      </Card>
    </div>
  )
}

export default CompanyOnboardingChecklist
