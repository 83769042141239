import { useEffect, useState } from 'react'
import { useLazyGetCwAdditionsQuery, useSetCwMappingMutation } from '../../../../store/ConnectWiseApiSlice'
import { debounce } from 'lodash'
import { Autocomplete, TextField } from '@mui/material'

export function ConnectWiseAdditionDropdown ({ cwAgreementId, cwAddition, partnerId, companyId, isDisabled = false }) {
  const [cwAdditionState, setCwAdditionState] = useState(cwAddition)
  const [getTriggerFunction, getResult] = useLazyGetCwAdditionsQuery()
  const { data, isLoading } = getResult

  const debouncedGetAdditions = debounce((nameFilter) => {
    nameFilter = nameFilter || ''
    getTriggerFunction({ partnerId, cwAgreementId, nameFilter })
  }, 100)

  const [updateTriggerFunction] = useSetCwMappingMutation()

  useEffect(() => {
    if (cwAgreementId) {
      getTriggerFunction({ partnerId, cwAgreementId, nameFilter: '' })
    }
  }, [cwAgreementId])

  useEffect(() => {
    if (!isLoading) {
      setCwAdditionState(cwAddition)
    }
  }, [cwAddition, isLoading])

  let changeable = data?.data ? [...data?.data] : []

  if (data?.hasMorePages) {
    changeable = [{ id: null, label: 'Search for more Additions...', disabled: true }, ...changeable]
  }

  return (
    <Autocomplete
      sx={{
        width: '100%',
        '.MuiOutlinedInput-notchedOutline':
      {
        borderStyle: 'none'
      }
      }}
      options={changeable}
      disabled={isDisabled}
      getOptionDisabled={(option) => option.disabled}
      getOptionKey={(option) => option.id}
      noOptionsText={cwAgreementId ? 'No Additions found' : 'Select an Agreement first'}
      renderInput={(params) => <TextField {...params} />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onInputChange={async (event, newValue, type) => {
        debouncedGetAdditions(newValue)
        if (type === 'clear') {
          setCwAdditionState(null)
          const result = await updateTriggerFunction({
            partnerId,
            companyId,
            mappingUpdate:
                {
                  cwAdditionId: null,
                  cwAdditionName: null,
                  lastSyncSucceeded: null,
                  lastSyncTime: null,
                  lastSyncUserCount: null
                }
          })

          if (result.error) {
            setCwAdditionState(cwAddition)
          }
        }
      }}
      onChange={async (event, newValue) => {
        if (newValue) {
          setCwAdditionState(newValue)
          const result = await updateTriggerFunction({ partnerId, companyId, mappingUpdate: { cwAdditionId: newValue.id, cwAdditionName: newValue.label } })

          if (result.error) {
            setCwAdditionState(cwAddition)
          }
        }
      }}
      value={cwAdditionState}
      onKeyDown={(event) => {
        if ([' ', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
          event.stopPropagation()
        }
      }}
    />
  )
}
