import { computeCampaignStatus } from './statusUtils'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { navigationRoutes } from '../../../frontendConsts'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { useState } from 'react'
import { DateTime } from 'luxon'
import { Grid, IconButton, Tooltip } from '@mui/material'
import { IoCloudDownload, IoEye, IoPencil, IoSearch, IoTrash } from 'react-icons/io5'

function renderStatus (campaign, tz) {
  const { firstFireDate, startTime, endTime, nextFireTimestamp } = campaign

  const statusObj = computeCampaignStatus({ firstFireDate, startTime, endTime, nextFireTimestamp, tz })
  return (<h4><span className={`badge badge-${statusObj.color}`}>{statusObj.message}</span></h4>)
}

function CampaignTable ({
  title, campaigns, pastCampaigns = false,
  csvDownloadHandler = undefined, handleCancelCampaign = undefined, handleEditCampaign = undefined,
  focusedCampaign, focusCallback, tz
}) {
  const history = useHistory()
  const { company } = useSelector((state) => state.company)
  const [tablePageSize, setTablePageSize] = useState(10)

  if (campaigns.length === 0) {
    return <></>
  }

  function handleSelection (params) {
    focusCallback(params.row)
  }

  function handleViewActiveCampaign (campaignId) {
    history.push(`/companies/${company.id}${navigationRoutes.PHISHING_VIEWER}/${campaignId}`)
  }

  function generateDownloadButton (campaign) {
    return (
      <Tooltip
        title='Download Stats' placement='top'
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -14]
                }
              }
            ]
          }
        }}
      >
        <IconButton
          id={`download-campaign-stats-button-${campaign.id}`}
          cypress-test-id={`download-campaign-stats-button-${campaign.name}`}
          aria-label='Download Campaign Stats Button'
          size='medium'
          onClick={() => csvDownloadHandler(campaign.id, campaign.name, pastCampaigns ? 'pastCampaigns' : 'activeCampaigns')}
          color='primary'
        >
          <IoCloudDownload />
        </IconButton>
      </Tooltip>
    )
  }

  const pastCampaignColumns = [
    { field: 'name', headerName: 'Name', minWidth: 300, flex: 2 },
    { field: 'startTime', headerName: 'Start Date', minWidth: 200, flex: 1, renderCell: (params) => { return (params.value ? DateTime.fromISO(params.value).toLocaleString(DateTime.DATE_FULL) : '') } },
    { field: 'endTime', headerName: 'End Date', minWidth: 200, flex: 1, renderCell: (params) => { return (params.value ? DateTime.fromISO(params.value).toLocaleString(DateTime.DATE_FULL) : '') } },
    { field: 'action', headerName: 'Action', width: 200, sortable: false, renderCell: (params) => generateDownloadButton(params.row) }
  ]

  function generateActiveCampaignButtons (campaign) {
    return (
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        {/* View Campaign */}
        <Tooltip
          title='View' placement='top'
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -14]
                  }
                }
              ]
            }
          }}
        >
          <IconButton
            id={`view-campaign-button-${campaign.id}`}
            cypress-test-id={`view-campaign-button-${campaign.name}`}
            aria-label='View Phishing Campaign Button'
            size='medium'
            onClick={() => handleViewActiveCampaign(campaign.id)}
            color='primary'
          >
            <IoEye />
          </IconButton>
        </Tooltip>
        {/* Edit Campaign */}
        <Tooltip
          title='Edit' placement='top'
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -14]
                  }
                }
              ]
            }
          }}
        >
          <IconButton
            id={`edit-campaign-button-${campaign.id}`}
            cypress-test-id={`edit-campaign-button-${campaign.name}`}
            aria-label='Edit Phishing Campaign Button'
            size='medium'
            onClick={() => handleEditCampaign(campaign.id)}
            color='primary'
          >
            <IoPencil />
          </IconButton>
        </Tooltip>
        {/* Download Stats */}
        <Tooltip
          title='Download Stats' placement='top'
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -14]
                  }
                }
              ]
            }
          }}
        >
          <IconButton
            id={`download-campaign-stats-button-${campaign.id}`}
            cypress-test-id={`download-campaign-stats-button-${campaign.name}`}
            aria-label='Download Campaign Stats Button'
            size='medium'
            onClick={() => csvDownloadHandler(campaign.id, campaign.name, pastCampaigns ? 'pastCampaigns' : 'activeCampaigns')}
            color='primary'
          >
            <IoCloudDownload />
          </IconButton>
        </Tooltip>
        {/* Delete Campaign */}
        <Tooltip
          title='Cancel' placement='top'
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -14]
                  }
                }
              ]
            }
          }}
        >
          <IconButton
            id={`cancel-campaign-button-${campaign.id}`}
            cypress-test-id={`cancel-campaign-button-${campaign.name}`}
            aria-label='Cancel Campaign Button'
            size='medium'
            onClick={() => handleCancelCampaign(campaign.id)}
            color='error'
          >
            <IoTrash />
          </IconButton>
        </Tooltip>
      </Grid>
    )
  }

  const activeCampaignColumns = [
    { field: 'name', headerName: 'Name', minWidth: 300, flex: 2, renderCell: (params) => params.row.id === focusedCampaign.id ? <><b className='margin-right:-2'><IoSearch size='1.25rem' /></b>{params.value}</> : params.value },
    { field: 'status', headerName: 'Status', minWidth: 200, flex: 2, renderCell: (params) => renderStatus(params.row, tz) },
    { field: 'action', headerName: 'Action', minWidth: 200, flex: 1, sortable: false, renderCell: (params) => generateActiveCampaignButtons(params.row) }
  ]

  return (
    <Grid>
      <div className='phin-h4 margin-bottom:-2'>{title}</div>

      <div className='phin-body-text margin-bottom:-2'>{pastCampaigns
        ? 'View the past Phishing Campaigns that have been run for this company as well as download the stats for each campaign.'
        : 'Manage all your active Phishing Campaigns here. View, edit, download stats, and cancel campaigns.'}
      </div>

      <div id={pastCampaigns ? 'past-campaigns-table' : 'active-campaigns-table'} style={{ width: '100%' }}>
        <DataGrid
          sx={{
            borderRadius: '8px',
            border: '2px solid var(--phin-light-gray)',
            '.MuiDataGrid-cell:focus': {
              outline: 'none'
            },
            marginBottom: 'var(--phin-s3)'
          }}
          className='DataGrid'
          initialState={{
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }]
            }
          }}
          autoHeight
          onRowClick={!pastCampaigns ? handleSelection : null}
          rows={campaigns}
          columns={pastCampaigns ? pastCampaignColumns : activeCampaignColumns}
          pageSize={tablePageSize}
          onPageSizeChange={(newSize) => setTablePageSize(newSize)}
          rowsPerPageOptions={[10, 25, 50]}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: {
                debounceMs: 500,
                id: pastCampaigns ? 'past-phishing-campaigns-table-search' : 'active-phishing-campaigns-table-search',
                'aria-label': 'Search'
              },
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true }
            }
          }}
          disableSelectionOnClick
          disableColumnSelector
          disableDensitySelector
          disableColumnFilter
          disableColumnMenu
        />
      </div>
    </Grid>
  )
}

export default CampaignTable
