import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Collapse, Grid, Switch, TextField } from '@mui/material'

import {
  MDBCardBody, MDBRow, MDBCol
} from 'mdb-react-ui-kit'

import { orgTypes, shareLevels } from '../../../frontendConsts.js'
import { StaticAlert } from '../../../shared/components/StaticAlert.js'
import { IoBuild, IoRocket, IoSave } from 'react-icons/io5'
import { PhinChip } from '../../../shared/components/PhinChip.js'
import { PhinCard } from '../PhinCard.js'

function PublishSelector ({
  option, handleOptions, handleEmail,
  emails, emailsDisabled, handlePhones, phones,
  phonesDisabled, handleShareLevel,
  allowPreview = false, previewSuccess = false
}) {
  const { authorization } = useSelector((state) => state.auth)
  const { company } = useSelector((state) => state.auth)

  function hasAccessToParentPartner (partners) {
    return partners.includes()
  }

  const [toggle, setToggle] = useState(false)

  useEffect(() => {
    if (company && authorization) {
      const { roles } = authorization
      const partners = Object.keys(roles[orgTypes.PARTNER] || {})
      setToggle(hasAccessToParentPartner(partners))
    }
  }, [company, authorization])

  useEffect(() => {
    if (toggle) {
      handleShareLevel(shareLevels.PARTNER)
    } else {
      handleShareLevel(shareLevels.CLIENT)
    }
  }, [toggle])

  function renderContent () {
    if (option === 'preview') {
      return (
        <>
          <div className='margin:0'>
            <Collapse in={previewSuccess} {...(previewSuccess ? { timeout: 500 } : {})}>
              <StaticAlert
                severity='success' color='success' text='Your preview campaign has been sent! If the emails look good then you can choose to save and launch, or hit cancel if you just wanted to preview some templates.'
              />
            </Collapse>
          </div>
          <MDBRow className='mt-2'>
            <MDBCol>
              <MDBRow className='d-flex justify-content-center text-center'>
                <MDBCol size='11'>
                  <p>Send all templates to the emails (and phones if using SMS) for viewing.
                    If they look good, you will have the option to save this preset to launch later.
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <hr />
          <Grid
            container
            direction='row'
            justifyContent='space-around'
            alignItems='center'
          >
            <Grid
              direction='column'
              justifyContent='space-between'
              alignItems='center'
            >

              <TextField
                fullWidth
                disabled={emailsDisabled}
                id='email1'
                value={emails[0]}
                onChange={(event) => updateEmails(0, event.target.value)}
                label='Email Address'
                sx={{ marginBottom: 'var(--phin-s0)' }}
              />
              <TextField
                fullWidth
                disabled={emailsDisabled}
                id='email2'
                value={emails[1]}
                onChange={(event) => updateEmails(1, event.target.value)}
                label='Email Address'
                sx={{ marginBottom: 'var(--phin-s0)' }}
              />
              <TextField
                fullWidth
                disabled={emailsDisabled}
                id='email3'
                value={emails[2]}
                onChange={(event) => updateEmails(2, event.target.value)}
                label='Email Address'
                sx={{ marginBottom: 'var(--phin-s0)' }}
              />
            </Grid>
            <Grid
              direction='column'
              justifyContent='space-between'
              alignItems='center'
            >
              <TextField fullWidth disabled={phonesDisabled} id='phone1' value={phones[0]} onChange={(event) => updatePhones(0, event.target.value)} label='Phone Number' sx={{ marginBottom: 'var(--phin-s0)' }} />
              <TextField fullWidth disabled={phonesDisabled} id='phone2' value={phones[1]} onChange={(event) => updatePhones(1, event.target.value)} label='Phone Number' sx={{ marginBottom: 'var(--phin-s0)' }} />
              <TextField fullWidth disabled={phonesDisabled} id='phone3' value={phones[2]} onChange={(event) => updatePhones(2, event.target.value)} label='Phone Number' sx={{ marginBottom: 'var(--phin-s0)' }} />
            </Grid>
          </Grid>
        </>
      )
    } else if (option === 'preset') {
      return (
        <MDBRow className='mt-2'>
          <MDBCol>
            <MDBRow className='d-flex justify-content-center text-center'>
              <MDBCol size='11'>
                <p>This campaign will be saved to your preset library so you can start a similar campaign again later.
                  It will then also schedule this campaign for your users.
                </p>
              </MDBCol>
            </MDBRow>
            <MDBRow className='d-flex justify-content-center text-center'>
              {hasAccessToParentPartner && (
                <div>
                  <p>You can also share this preset with other companies:</p>
                  <Grid item xs={6}>
                    Share Preset
                    <Switch
                      id='sharePresetCampaignToggle' checked={toggle} onClick={() => { setToggle(!toggle) }}
                    />
                  </Grid>
                </div>
              )}
            </MDBRow>
          </MDBCol>
        </MDBRow>
      )
    } else if (option === 'launch') {
      return (
        <MDBRow className='mt-2'>
          <MDBCol>
            <MDBRow className='d-flex justify-content-center text-center'>
              <MDBCol size='11'>
                <p>This campaign will be scheduled for your users. However, it will not save this configuration as a preset for later use.
                  This is a good setting for one-time campaigns or special configurations.
                </p>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      )
    }
  }

  function updateEmails (index, email) {
    const newEmails = [...emails]
    newEmails[index] = email
    handleEmail(newEmails)
  }

  function updatePhones (index, phone) {
    const newPhones = [...phones]
    newPhones[index] = phone
    handlePhones(newPhones)
  }

  return (
    <PhinCard
      title='Publish Options'
      description='These are the ways we can publish this new campaign. Click on each of the options below to see more details.  Save and Launch is the default.'
    >
      <MDBCardBody className='w-100 h-100'>
        <Grid
          container
          direction='row'
          justifyContent='space-around'
          alignItems='center'
        >
          <PhinChip
            id='save-and-launch-chip'
            icon={<IoSave size='2em' />}
            color={option === 'preset' ? 'primary' : 'phinBlack'}
            variant={option === 'preset' ? 'filled' : 'outlined'}
            onClickFunction={() => handleOptions('preset')}
            label='Save and Launch'
            size='large'
          />
          <PhinChip
            id='launch-campaign-chip'
            icon={<IoRocket size='2em' />}
            color={option === 'launch' ? 'primary' : 'phinBlack'}
            variant={option === 'launch' ? 'filled' : 'outlined'}
            onClickFunction={() => handleOptions('launch')}
            label='Launch Campaign'
            size='large'
          />
          {allowPreview && (
            <PhinChip
              id='preview-campaign-chip'
              icon={<IoBuild size='2em' />}
              color={option === 'preview' ? 'primary' : 'phinBlack'}
              variant={option === 'preview' ? 'filled' : 'outlined'}
              onClickFunction={() => handleOptions('preview')}
              label='Run a Preview'
              size='large'
            />
          )}
        </Grid>
        <hr />
        {renderContent()}
      </MDBCardBody>
    </PhinCard>
  )
}

export default PublishSelector
