import AudienceFilters from '../audience/AudienceFilters'
import { FixedAudienceSelector } from '../audience/FixedAudienceSelector'
import './curriculumAudience.scss'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { audienceSelectionTypes, campaignTypes } from '../../../frontendConsts.js'

function CurriculumAudience ({
  groups, audienceFilterType, audienceSelectionType,
  departmentNames, supervisorEmails, setAudienceFilterType,
  setAudienceSelectionType, audienceFilters,
  setAudienceFilters, audienceStats,
  fixedAudienceList, setFixedAudienceList,
  campaignType, isCampaignNew, initialFixedAudienceList
}) {
  const handleChange = (val) => {
    const parameters = [{
      field: 'first',
      type: '!=',
      value: '~~~'
    }]

    if (val.target.value !== audienceSelectionType) {
      if (val.target.value === audienceSelectionTypes.FIXED) {
        setAudienceFilterType('first')
        setAudienceFilters(parameters)
        setAudienceSelectionType(audienceSelectionTypes.FIXED)
      } else {
        setAudienceFilterType('first')
        setAudienceFilters(parameters)
        setAudienceSelectionType(audienceSelectionTypes.DYNAMIC)
      }
    }
  }
  const isEditOnboarding = !isCampaignNew && campaignType === campaignTypes.ONBOARDING

  return (
    <div className='curriculum-audience-container'>
      <h4 className='phin-h4'> Audience Builder </h4>

      <div className='audience-switch-container'>
        <ToggleButtonGroup disabled={isEditOnboarding} value={audienceSelectionType} color='primary' exclusive onChange={handleChange}>
          <ToggleButton
            id='fixed-audience-toggle'
            aria-label='Fixed Audience Toggle'
            value={audienceSelectionTypes.FIXED}
          >Fixed
          </ToggleButton>
          <ToggleButton
            id='dynamic-audience-toggle'
            aria-label='Dynamic Audience Toggle'
            value={audienceSelectionTypes.DYNAMIC}
          >Dynamic
          </ToggleButton>
        </ToggleButtonGroup>
        <p className='switch-description'>
          {campaignType === campaignTypes.POLICY
            ? <span className='description'>{audienceSelectionType === audienceSelectionTypes.DYNAMIC ? 'All users who match the audience filter below will be sent the policy. New users matching the filter will have this policy sent to them automatically.' : 'Create a customized list of users to send this policy to.'}</span>
            : <span className='description'>{audienceSelectionType === audienceSelectionTypes.DYNAMIC ? 'Users who match the audience filter below will be added to the training campaign the next time it fires. New users will be automatically added to this campaign. Users who no longer match the filter will not be included.' : 'Use the list below to build out a customized list of users. New users added will be in the training campaign the next time it fires. Removing a user from this list will prevent them from receiving future training. Slept or Deleted users will no longer receive future training courses.'}</span>}
        </p>
      </div>

      {campaignType === campaignTypes.ONBOARDING && <p>New users matching the audience filter below will be added to the onboarding campaign.</p>}

      {audienceSelectionType === audienceSelectionTypes.DYNAMIC && (
        <AudienceFilters
          groups={groups}
          audienceFilterType={audienceFilterType}
          departmentNames={departmentNames}
          supervisorEmails={supervisorEmails}
          setAudienceFilterType={setAudienceFilterType}
          parameters={audienceFilters}
          setParameters={setAudienceFilters}
          users={audienceStats}
          showHeader={false}
        />
      )}

      {audienceSelectionType === audienceSelectionTypes.FIXED && (
        <FixedAudienceSelector
          users={audienceStats}
          fixedAudienceList={fixedAudienceList}
          setFixedAudienceList={setFixedAudienceList}
          departmentNames={departmentNames}
          supervisorEmails={supervisorEmails}
          isEditOnboarding={isEditOnboarding}
          initialFixedAudienceList={initialFixedAudienceList}
        />
      )}
    </div>
  )
}

export default CurriculumAudience
