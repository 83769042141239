import React from 'react'
import { useHistory } from 'react-router-dom'
import Grade from './components/Grade/index.js'
import { useDispatch, useSelector } from 'react-redux'
import { toggleSideNavAction } from '../store/SideNavSlice.js'
import UserProfileButton from './components/UserProfileButton.js'

import './css/topNav.css'
import { orgTypes, PAX8_ACCOUNT_STATUSES } from '../frontendConsts.js'
import { Breadcrumbs, Chip, Grid, IconButton, Link, AppBar, Toolbar, styled } from '@mui/material'
import { IoBusiness, IoFish, IoMenu, IoShield } from 'react-icons/io5'

const drawerWidth = 240

const MuiAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  zIndex: '900 !important',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

function TopNavBar () {
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    topNavOrgType,
    topNavDistributorName, topNavDistributorId,
    topNavPartnerName, topNavCompanyIsInternal, topNavPartnerId,
    topNavCompanyName, topNavCompanyId
  } = useSelector((state) => state.topNav)
  const { activeCampaigns } = useSelector((state) => state.campaigns)
  const { authorization } = useSelector((state) => state.auth)
  const { partner } = useSelector((state) => state.partner)
  const { company } = useSelector((state) => state.company)

  const renderRoleType = () => {
    if (topNavOrgType === orgTypes.COMPANY) {
      return (
        <Link
          component='button'
          underline='hover'
          color='inherit'
          onClick={() => history.push(`/companies/${topNavCompanyId}`)}
        >
          <Grid container direction='row' alignItems='center' spacing={1}>
            <Grid item><IoBusiness size='1.25em' /></Grid>
            <Grid item>{topNavCompanyName}</Grid>
            {topNavCompanyIsInternal && (<Grid item><Chip size='small' label='NFR' sx={{ backgroundColor: 'var(--purple-75)', color: 'white' }} /></Grid>)}
          </Grid>

        </Link>
      )
    } else if (topNavOrgType === orgTypes.PARTNER) {
      return (
        <Link
          component='button'
          underline='hover'
          color='inherit'
          onClick={() => history.push(`/partners/${topNavPartnerId}`)}
        >
          <IoShield size='1.25em' /> {topNavPartnerName}
        </Link>
      )
    } else if (topNavOrgType === orgTypes.DISTRIBUTOR) {
      return (
        <Link
          component='button'
          underline='hover'
          color='inherit'
          onClick={() => history.push(`/distributors/${topNavDistributorId}`)}
        >
          <IoFish size='1.25em' /> {topNavDistributorName}
        </Link>
      )
    }
  }

  function hasAccessToParentPartner (partners) {
    return partners.includes(topNavPartnerId)
  }

  function hasAccessToParentDistributor (distributors) {
    return distributors.includes(topNavDistributorId)
  }

  const renderBackToPartner = () => {
    if (authorization) {
      const { roles } = authorization
      const partners = Object.keys(roles[orgTypes.PARTNER] || {})
      const distributors = Object.keys(roles[orgTypes.DISTRIBUTOR] || {})

      if (topNavOrgType === orgTypes.PARTNER) {
        if (hasAccessToParentDistributor(distributors, topNavDistributorId)) {
          return (
            <Link
              component='button'
              aria-label='Distributor Breadcrumb'
              id='to-distributor-link'
              underline='hover'
              color='inherit'
              onClick={() => history.push(`/distributors/${topNavDistributorId}`)}
            >
              {topNavDistributorName}
            </Link>
          )
        }
      }

      if (topNavOrgType === orgTypes.COMPANY) {
        const hasPartnerAccess = hasAccessToParentPartner(partners)
        const hasDistributorAccess = hasAccessToParentDistributor(distributors)
        const breadCrumbs = []
        if (hasDistributorAccess) {
          breadCrumbs.push((
            <div key='distributor'>
              <Link
                component='button'
                aria-label='Distributor Breadcrumb'
                id='to-distributor-link'
                underline='hover'
                color='inherit'
                onClick={() => history.push(`/distributors/${topNavDistributorId}`)}
              >
                {topNavDistributorName}
              </Link>
            </div>
          ))
        }

        if (hasPartnerAccess) {
          breadCrumbs.push((
            <div key='partner'>
              <Link
                component='button'
                aria-label='Distributor Breadcrumb'
                id='to-partner-link'
                underline='hover'
                color='inherit'
                onClick={() => history.push(`/partners/${topNavPartnerId}`)}
              >
                {topNavPartnerName}
              </Link>
            </div>
          ))
        }

        return breadCrumbs
      }
    }
  }

  const applyNoticeBannerStyles = partner?.freeTrial ||
  company?.freeTrial ||
  partner?.pax8AccountStatus === PAX8_ACCOUNT_STATUSES.TRIALING ||
  partner?.pax8AccountStatus === PAX8_ACCOUNT_STATUSES.AWAITING_FREE_TRIAL_START ||
  partner?.suspensionStartTimestamp

  return (
    <MuiAppBar
      position='fixed' sx={applyNoticeBannerStyles
        ? { marginTop: '48px', maxHeight: 64, backgroundColor: '#FFFFFF', color: 'var(--black)', zIndex: '900 !important', boxShadow: 'none', borderBottom: '2px solid #D8D8D8' }
        : { maxHeight: 64, backgroundColor: '#FFFFFF', color: 'var(--black)', zIndex: '900 !important', boxShadow: 'none', borderBottom: '2px solid #D8D8D8' }}
    >
      <Toolbar>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{ 'flex-wrap': 'nowrap' }}
        >
          <Grid>
            <IconButton
              size='large'
              edge='start'
              color='inherit'
              aria-label='menu'
              sx={{ mr: 2 }}
              onClick={() => { dispatch(toggleSideNavAction()) }}
            >
              <IoMenu color='var(--black)' />
            </IconButton>
          </Grid>

          <Grid
            container
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
          >
            {process.env.REACT_APP_DEV_MODE && (
              <Chip color='secondary' label='DEVELOPMENT' />
            )}

            <Breadcrumbs aria-label='breadcrumb' sx={{ marginLeft: 'var(--phin-s0)', marginRight: 'var(--phin-s0)' }}>
              {renderBackToPartner()}

              {renderRoleType()}
            </Breadcrumbs>

            {topNavOrgType === orgTypes.COMPANY &&
              <Grade activeCampaigns={activeCampaigns} />}

            <UserProfileButton distributorId={topNavDistributorId} companyId={topNavCompanyId} />
          </Grid>
        </Grid>
      </Toolbar>
    </MuiAppBar>
  )
}

export default TopNavBar
