import CompanyOnboardingChecklist from './CompanyOnboardingChecklist'
import Spinner from './components/Spinner'
import { useSelector } from 'react-redux'
import { Chip, Grid } from '@mui/material'

function CompanyHome ({
  id
}) {
  const { company, loaders } = useSelector((state) => state.company)
  const { activeCampaigns, loaders: campaignLoaders } = useSelector((state) => state.campaigns)
  const { isLoadingActiveCampaigns } = campaignLoaders
  const { isLoadingCompany, isLoadingOnboardingChecklist } = loaders

  return (
    <div>
      {(!company || !activeCampaigns || isLoadingCompany ||
      isLoadingActiveCampaigns || isLoadingOnboardingChecklist) && (
        <Spinner message='Loading Company Data' />
      )}

      {company && activeCampaigns && !isLoadingCompany &&
      !isLoadingActiveCampaigns && !isLoadingOnboardingChecklist && (
        <div className='margin-left:5 margin-right:5'>
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
          >
            <Grid className='padding-bottom:3' container direction='row' alignItems='center' spacing={1}>
              <Grid item><h2 className='phin-h2'>{company.name} Home</h2></Grid>
              {company.isInternal && <Grid item><Chip label='NFR' sx={{ backgroundColor: 'var(--purple-75)', color: 'white', marginLeft: '.5rem' }} /></Grid>}
            </Grid>
          </Grid>
          <CompanyOnboardingChecklist
            id={id}
            companyName={company.name}
          />
        </div>
      )}
    </div>
  )
}

export default CompanyHome
