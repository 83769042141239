import { createSlice } from '@reduxjs/toolkit'

export const googleUserSyncIntegrationSlice = createSlice({
  name: 'googleWorkspaceAPI',
  initialState: {
  },
  reducers: {
  }
})

export default googleUserSyncIntegrationSlice.reducer

export const postGoogleUserSyncConsentThunk = (id, { code }) => {
  return async (dispatch, getState, api) => {
    const res = await api.post(`/api/companies/${id}/integrations/googleUserSync/consent`, { code })
    return res
  }
}

export const getGoogleOauthUrlThunk = (id) => {
  return async (dispatch, getState, api) => {
    const response = await api.get(`/api/companies/${id}/integrations/googleUserSync/oauth-url`)
    return response
  }
}

export const getGoogleWorkspaceUsers = (id) => {
  return async (dispatch, getState, api) => {
    const response = await api.get(`/api/companies/${id}/integrations/googleUserSync/users`)
    return response
  }
}

export const getGoogleWorkspaceUsersByGroup = (id, groupId) => {
  return async (dispatch, getState, api) => {
    const response = await api.get(`/api/companies/${id}/integrations/googleUserSync/${groupId}/members`)
    return response
  }
}

export const getGoogleWorkspaceGroups = (id) => {
  return async (dispatch, getState, api) => {
    const response = await api.get(`/api/companies/${id}/integrations/googleUserSync/groups`)
    return response
  }
}

export const syncGoogleWorkspaceUsers = (id, users) => {
  return async (dispatch, getState, api) => {
    const response = await api.post(`/api/companies/${id}/integrations/googleUserSync/sync`, { users })
    return response
  }
}
