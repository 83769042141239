import { useAuth0 } from '@auth0/auth0-react'
import LogoutIcon from '@mui/icons-material/Logout'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import { ClickAwayListener, Box, Grow, MenuItem, MenuList, Paper, Popper, Stack, Chip } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { useState, useRef, useEffect } from 'react'
import { getAccessibleDistributorsThunk } from '../../store/DistributorSlice'
import { getAccessibleCompaniesThunk } from '../../store/CompanySlice'

import Spinner from './Spinner'
import { useHistory } from 'react-router-dom'
import PhinModal from './PhinModal'

function UserProfileButton ({ distributorId, companyId }) {
  const { logout } = useAuth0()
  const dispatch = useDispatch()
  const history = useHistory()
  const { authorization } = useSelector((state) => state.auth)
  const { accessibleDistributors } = useSelector((state) => state.distributor)
  const { isLoadingAccessibleDistributors } = useSelector((state) => state.distributor.loaders)
  const distributors = authorization?.roles?.distributors
  const [open, setOpen] = useState(false)
  const [openDistributorsModal, setOpenDistributorsModal] = useState(false)
  const [selectedDistributor, setSelectedDistributor] = useState(null)

  const { accessibleCompanies } = useSelector((state) => state.company)
  const { isLoadingAccessibleCompanies } = useSelector((state) => state.company.loaders)
  const [openCompaniesModal, setOpenCompaniesModal] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState(null)

  const anchorRef = useRef(null)

  useEffect(() => {
    if (!accessibleDistributors && authorization?.uid) {
      dispatch(getAccessibleDistributorsThunk())
    }
    if (!accessibleCompanies && authorization?.uid) {
      dispatch(getAccessibleCompaniesThunk())
    }
  }, [authorization])

  useEffect(() => {
    if (distributorId) {
      setSelectedDistributor(distributorId)
    }
  }, [distributorId])

  useEffect(() => {
    if (companyId) {
      setSelectedCompany(companyId)
    }
  }, [companyId])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <div>
      <div className='user-info'>
        <span
          ref={anchorRef}
          onClick={handleToggle}
          id='profile-btn'
          aria-haspopup='true'
          style={{
            borderRadius: '50%',
            width: '1.15em',
            height: '1.15em',
            color: '#FFF',
            marginTop: '-3px',
            textAlign: 'center',
            font: '1.6em Arial, sans-serif'
          }}
        >{authorization && authorization.name && authorization.name.toUpperCase()[0]}
        </span>
      </div>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement='bottom-start'
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  sx={{ minWidth: '200px' }}
                  autoFocusItem={open}
                  id='composition-menu'
                  aria-labelledby='composition-button'
                >
                  <MenuItem
                    id='logout-button'
                    aria-label='Logout Button'
                    onClick={() => logout({ returnTo: window.location.origin })}
                  >
                    <LogoutIcon sx={{ mr: '.5rem' }} />
                    Logout
                  </MenuItem>
                  {distributors && Object.keys(distributors).length > 1 &&
                    <MenuItem
                      id='switch-distributors-button'
                      aria-label='Switch Distributors Button'
                      onClick={() => setOpenDistributorsModal(true)}
                    >
                      <ManageAccountsIcon sx={{ mr: '.5rem' }} />
                      Switch Distributors
                    </MenuItem>}
                  {accessibleCompanies && accessibleCompanies.length > 1 && (
                    <MenuItem
                      id='switch-companies-button'
                      aria-label='Switch Companies Button'
                      onClick={() => setOpenCompaniesModal(true)}
                    >
                      <ManageAccountsIcon sx={{ mr: '.5rem' }} />
                      Switch Companies
                    </MenuItem>)}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <PhinModal
        isOpen={openDistributorsModal}
        title='Change Distributor'
        size='medium'
        close={() => {
          setSelectedDistributor(distributorId)
          setOpenDistributorsModal(false)
        }}
        action={() => {
          history.push(`/distributors/${selectedDistributor}`)
          setOpenDistributorsModal(false)
        }}
        actionText='submit'
      >
        <Box>
          <Stack sx={{ mt: '.5rem' }}>
            {
                  isLoadingAccessibleDistributors || !accessibleDistributors
                    ? <Spinner />
                    : accessibleDistributors.map((dist) => (
                      <Box
                        key={dist.id}
                        sx={{ display: 'flex', justifyContent: 'space-between', border: selectedDistributor === dist.id ? '2px solid #2e7d32' : 'none', cursor: 'pointer', padding: '.5rem', borderRadius: '5px' }}
                        onClick={() => setSelectedDistributor(dist.id)}
                      >
                        <Box>{dist.name}</Box>
                        <Box>
                          {dist.id === distributorId && <Chip label='Current' size='small' color='success' />}
                        </Box>
                      </Box>
                    ))
                }
          </Stack>
        </Box>
      </PhinModal>

      <PhinModal
        isOpen={openCompaniesModal}
        title='Change Company'
        size='medium'
        close={() => {
          setSelectedCompany(companyId)
          setOpenCompaniesModal(false)
        }}
        action={() => {
          history.push(`/companies/${selectedCompany}`)
          setOpenCompaniesModal(false)
        }}
        actionText='submit'
      >
        <Box>
          <Stack sx={{ mt: '.5rem' }}>
            {
                  isLoadingAccessibleCompanies || !accessibleCompanies
                    ? <Spinner />
                    : accessibleCompanies.map((company) => (
                      <Box
                        key={company.id}
                        sx={{ display: 'flex', justifyContent: 'space-between', border: selectedCompany === company.id ? '2px solid #2e7d32' : 'none', cursor: 'pointer', padding: '.5rem', borderRadius: '5px' }}
                        onClick={() => setSelectedCompany(company.id)}
                      >
                        <Box>{company.name}</Box>
                        <Box>
                          {company.id === companyId && <Chip label='Current' size='small' color='success' />}
                        </Box>
                      </Box>
                    ))
                }
          </Stack>
        </Box>
      </PhinModal>
    </div>
  )
}

export default UserProfileButton
