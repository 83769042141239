import React, { useState } from 'react'
import TemplatesPreviewer from './TemplatesPreviewer'
import CampaignSendingChannelEditor from './CampaignSendingChannelEditor'
import CampaignLearningTypesEditor from './CampaignLearningTypesEditor'
import { Button, Grid, IconButton, Tooltip } from '@mui/material'
import { IoAdd, IoCheckmark, IoEye } from 'react-icons/io5'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import CloseIcon from '@mui/icons-material/Close'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import { PhinCard } from '../PhinCard'

function CampaignTemplateCategoryEditor ({
  allTemplates, editCallback, selectedCampaignCategories,
  campaignCategories, sendingChannels, learningTypes,
  disableUpdating, updateSendingChannels, toggleLearningTypes
}) {
  const [previewModal, setPreviewModal] = useState(false)
  const [selectedTemplates, setSelectedTemplates] = useState([])
  const [tablePageSize, setTablePageSize] = useState(10)

  function closePreviewModal () {
    setPreviewModal(!previewModal)
    setSelectedTemplates([])
  }

  function previewTemplates (category) {
    const previewTemplates = []

    allTemplates.forEach((template) => {
      if (template.tags !== undefined && template.tags.includes(category)) {
        previewTemplates.push(template)
      }
    })

    setPreviewModal(true)
    setSelectedTemplates(previewTemplates)
  }

  /**
  * Loops through all the category options, selects them and updates state accordingly
  */
  function handleCategorySelectAll () {
    editCallback(campaignCategories)
  }

  /**
     * Loops through all the category options, deselects them and updates state accordingly
     */
  function handleCategoryRemoveAll () {
    editCallback([])
  }

  function AddCategoryButton ({ category }) {
    return (
      <Tooltip
        title='Select' placement='top'
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -14]
                }
              }
            ]
          }
        }}
      >
        <IconButton
          id={`add-${category.name}-category-button`}
          aria-label={`Add ${category.name} Category Button`}
          color='primary'
          size='large'
          onClick={() => { editCallback([...selectedCampaignCategories, category.name]) }}
        >
          <IoAdd />
        </IconButton>
      </Tooltip>
    )
  }

  function RemoveCategoryButton ({ category }) {
    return (
      <Tooltip
        title='Unselect' placement='top'
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -14]
                }
              }
            ]
          }
        }}
      >
        <IconButton
          id={`remove-${category.name}-category-button`}
          aria-label={`Remove ${category.name} Category Button`}
          color='success'
          size='large'
          onClick={() => { editCallback([...selectedCampaignCategories.filter(selectedCategoryName => selectedCategoryName !== category.name)]) }}
        >
          <IoCheckmark />
        </IconButton>
      </Tooltip>
    )
  }

  const categorySelectionColumns = [
    {
      headerName: 'Category Name',
      field: 'name',
      flex: 2
    },
    {
      headerName: 'Actions',
      field: 'actions',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            {selectedCampaignCategories.includes(params.row.name)
              ? <RemoveCategoryButton category={params.row} />
              : <AddCategoryButton category={params.row} />}

            <Tooltip
              title='View Category' placement='top'
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -14]
                      }
                    }
                  ]
                }
              }}
            >
              <IconButton
                id={`view-${params.row.name}-category-button`}
                aria-label={`View ${params.row.name} Category Button`}
                color='primary'
                onClick={() => { previewTemplates(params.row.name) }}
              >
                <IoEye />
              </IconButton>
            </Tooltip>
          </Grid>
        )
      }
    }
  ]

  return (
    <div id='category-previewer'>
      <TemplatesPreviewer
        toggleModal={() => closePreviewModal()}
        isOpen={previewModal}
        templates={selectedTemplates}
      />
      <CampaignSendingChannelEditor disableUpdating={disableUpdating || false} sendingChannels={sendingChannels} updateSendingChannels={updateSendingChannels} />
      <hr />
      <CampaignLearningTypesEditor disableUpdating={disableUpdating || false} learningTypes={learningTypes} toggleLearningTypes={toggleLearningTypes} />
      <hr />
      <PhinCard
        title='Template Categories'
        description='Here you can select the templates sent during the campaign. You can select from any of the Phin Security default templates or any of your own.'
      >
        <Grid container justifyContent='end' sx={{ marginBottom: 'var(--phin-s-2)' }}>
          <Button
            onClick={handleCategoryRemoveAll} color='error'
            startIcon={<CloseIcon />}
            sx={{ marginRight: 'var(--phin-s-2)' }}
          >Clear All
          </Button>
          <Button
            variant='contained'
            onClick={handleCategorySelectAll}
            color='primary' startIcon={<DoneAllIcon />}
          >Select All
          </Button>
        </Grid>
        <div style={{ paddingLeft: '10px', paddingRight: '10px', height: '50vh', width: '100%' }}>
          <DataGrid
            getRowId={(row) => row.name}
            style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
            className='DataGrid'
            rows={campaignCategories.map((category) => {
              return {
                name: category
              }
            })}
            columns={categorySelectionColumns}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 }
              }
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableSelectionOnClick
            pageSize={tablePageSize}
            rowsPerPageOptions={[10, 20, 50]}
            onPageSizeChange={(newSize) => setTablePageSize(newSize)}
            pagination
          />
        </div>
      </PhinCard>
    </div>
  )
}

export default CampaignTemplateCategoryEditor
