import store from '../../../store'
import { notificationOpenedThunk } from '../../../store/MuiNotificationsSlice'
import { NOTIFICATIONS } from '../../../frontendConsts'

/**
 * Series of static utility methods that allow for easy interaction with the notification system.
 */
class NotificationUtilities {
  /**
   * Sends a warning notification to the user with the passed message
   * @static
   * @param {String} message The message you wish to send
   * @memberof NotificationUtilities
   */
  static sendWarningMessage (message) {
    store.dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.WARNING, alertMessage: message }))
  };

  /**
   * Sends a error notification to the user with the passed message
   *
   * @static
   * @param {String} message The message to send to the user
   * @memberof NotificationUtilities
   */
  static sendErrorMessage (message) {
    store.dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.ERROR, alertMessage: message }))
  };

  /**
   * Sends a success notification to the user with the passed in message
   * @static
   * @param {String} message The message to send to the user
   * @memberof NotificationUtilities
   */
  static sendSuccessMessage (message) {
    store.dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.SUCCESS, alertMessage: message }))
  }

  /**
   * Sends a success notification to the user with the passed in message
   * @static
   * @param {String} message The message to send to the user
   * @memberof NotificationUtilities
   */
  static sendInfoMessage (message) {
    store.dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.INFO, alertMessage: message }))
  }
}

export default NotificationUtilities
