import { Alert, Box, Button, Card, Grid, Skeleton } from '@mui/material'
import { IoCard, IoExit } from 'react-icons/io5'
import { useSelector } from 'react-redux'

export function PaymentInfoWidget ({ isLoading }) {
  const { partnerBillingDetails } = useSelector((state) => state.partnerBilling)

  return (
    <Box
      sx={{
        height: '100%',
        minHeight: 'calc(var(--paymentInfoSkeletonHeight) / 2)',
        gridArea: 'paymentInfoWidget',
        paddingBottom: 'var(--phin-s1)'
      }}
    >
      <div style={{ height: '100%' }}>
        {isLoading && (
          <Skeleton sx={{ bgcolor: '#c4c4c4', height: '100%', width: '100%' }} variant='rectangle' />
        )}

        <Card
          variant='outlined' sx={{
            border: 'solid 2px var(--light-gray)',
            borderRadius: '.5rem',
            minWidth: 200,
            height: '100%',
            padding: 'var(--phin-s2)',
            flex: '1'
          }}
        >
          <div className='phin-h4 margin-bottom:1'>Payment Info</div>

          <Grid
            container
            direction='column'
            justifyContent='space-between'
            alignItems='end'
          >
            {(!partnerBillingDetails.paymentInfo) && (
              <Grid item sx={{ margin: 'var(--phin-s0)' }}>
                <Alert severity='warning' variant='outlined' sx={{ background: 'var(--phin-light-yellow)' }}>
                  Please configure Payment Method via ChargeBee below.
                </Alert>
              </Grid>
            )}
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              {partnerBillingDetails.paymentInfo && (
                <Grid item>
                  <IoCard className='margin-right:-1' size='3em' style={{ color: 'var(--phin-gray)' }} />
                  <span className='phin-h6 margin-right:-1'>{partnerBillingDetails.paymentInfo.card}</span>
                  <span>{partnerBillingDetails.paymentInfo.expirationMonth}/{partnerBillingDetails.paymentInfo.expirationYear}</span>
                </Grid>
              )}

              {partnerBillingDetails.billingAddress && (
                <Grid item>
                  <div>{partnerBillingDetails.billingAddress.line1}</div>
                  {partnerBillingDetails.billingAddress.line2 && (
                    <div>{partnerBillingDetails.billingAddress.line2}</div>
                  )}
                  <div>{partnerBillingDetails.billingAddress.city}, {partnerBillingDetails.billingAddress.state_code} {partnerBillingDetails.billingAddress.zip} {partnerBillingDetails.billingAddress.country}</div>
                </Grid>
              )}
            </Grid>

            <Grid item sx={{ marginTop: 'var(--phin-s3)' }}>
              <Button
                id='manage-payment-information-button'
                aria-label='Manage Payment Information'
                endIcon={<IoExit />}
                href={process.env.REACT_APP_CHARGEBEE_PORTAL_LINK}
                target='_blank'
                size='large'
                variant='outlined'
                disableElevation
              > Manage Payment Info
              </Button>
            </Grid>
          </Grid>
        </Card>

      </div>
    </Box>
  )
}
