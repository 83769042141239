export default (opt = {}) => {
  const editor = opt.editor
  const codeViewer = editor && editor.CodeManager.getViewer('CodeMirror').clone()
  const btnImp = document.createElement('button')
  const container = document.createElement('div')
  const pfx = opt.pfx || ''
  // Init import button
  btnImp.innerHTML = opt.modalBtnImport
  btnImp.className = pfx + 'btn-prim ' + pfx + 'btn-import'
  btnImp.onclick = () => {
    const code = codeViewer.editor.getValue()
    editor.DomComponents.getWrapper().set('content', '')
    editor.setComponents(code)
    editor.Modal.close()
  }
  // Init code viewer
  codeViewer.set({
    codeName: 'htmlmixed',
    theme: opt.codeViewerTheme,
    readOnly: 0
  })
  return {
    run (editor, sender) {
      const md = editor.Modal
      let viewer = codeViewer.editor
      md.setTitle(opt.modalTitleImport)
      // Init code viewer if not yet instantiated
      if (!viewer) {
        const txtarea = document.createElement('textarea')
        if (opt.modalLabelImport) {
          const labelEl = document.createElement('div')
          labelEl.className = pfx + 'import-label'
          labelEl.innerHTML = opt.modalLabelImport
          container.appendChild(labelEl)
        }
        container.appendChild(txtarea)
        container.appendChild(btnImp)
        codeViewer.init(txtarea)
        viewer = codeViewer.editor
      }
      md.setContent('')
      md.setContent(container)
      codeViewer.setContent(opt.importPlaceholder || '')
      md.open()
      viewer.refresh()
      sender && sender.set('active', 0)
    }
  }
}
