import { Box, Chip, Grid, Skeleton, Tab, Tabs } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Route, Switch, useRouteMatch, useLocation } from 'react-router-dom'
import { integrationTypes } from '../../../../frontendConsts'
import { updateHasSeenCWIntegrationThunk } from '../../../../store/AuthSlice'
import { getPartnerIntegrationsThunk } from '../../../../store/PartnerIntegrationSlice'
import { StatusBreadCrumbs } from '../StatusBreadCrumbs'
import { ConnectWiseBillingTab } from './ConnectWiseBillingTab'
import { ConnectWiseIntegrationTab } from './ConnectWiseIntegrationTab'
import { ConnectWiseServiceDeskTable } from './ConnectWiseServiceDeskTable'

export function ConnectwiseIntegration ({ partnerId }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { path } = useRouteMatch()

  const tabPaths = ['', 'serviceDesk', 'billing']
  function handleChangeTab (event, newValue) {
    history.push(`/partners/${partnerId}/integrations/connectWise/${tabPaths[newValue]}`)
  }

  function getCurrentTabFromUrl () {
    const currentPath = location.pathname.split('/').pop()
    const tabIndex = tabPaths.indexOf(currentPath)
    return tabIndex !== -1 ? tabIndex : 0
  }

  const { authorization } = useSelector((state) => state.auth)

  const { hasSeenCWIntegration } = authorization
  const { partnerIntegrationsMap, partnerIdOfLastFetch, loaders } = useSelector((state) => state.partnerIntegrations)
  const { isLoadingPartnerIntegrations } = loaders
  const { partner, loaders: partnerLoaders } = useSelector((state) => state.partner)
  const { isLoadingPartner } = partnerLoaders

  const cwIntegrationEnabled = partnerIntegrationsMap?.[integrationTypes.CONNECTWISE]?.integrationStatus === 'enabled'
  const cwBillingEnabled = partnerIntegrationsMap?.[integrationTypes.CONNECTWISE]?.billingIsEnabled === true
  const cwServiceDeskEnabled = partnerIntegrationsMap?.[integrationTypes.CONNECTWISE]?.serviceDeskIsEnabled === true

  useEffect(() => {
    if (!partnerIntegrationsMap) {
      dispatch(getPartnerIntegrationsThunk(partnerId))
      return
    }

    if (!isLoadingPartnerIntegrations && !cwIntegrationEnabled && location.pathname !== `/partners/${partnerId}/integrations/connectWise`) {
      history.replace(`/partners/${partnerId}/integrations/connectWise`)
    }
  }, [isLoadingPartnerIntegrations, partnerIntegrationsMap])

  // Fetch the partner integrations if the partnerIntegrationsMap is null or the partnerIdOfLastFetch is different from the current partnerId
  useEffect(() => {
    if (!partnerIntegrationsMap || partnerIdOfLastFetch !== partnerId) {
      dispatch(getPartnerIntegrationsThunk(partnerId))
    }
  }, [dispatch, partnerId, partnerIntegrationsMap, partnerIdOfLastFetch])

  useEffect(() => {
    if (!hasSeenCWIntegration) {
      dispatch(updateHasSeenCWIntegrationThunk({ adminId: authorization.uid, hasSeenCWIntegration: true }))
    }
  }, [])

  if (isLoadingPartner || !partner) {
    return <Skeleton variant='rectangle' width={1190} height={600} />
  }

  return (
    <>
      <Box pb='2rem'>

        <div className='detailsTitleGroup' style={{ marginBottom: 'var(--phin-s-1)' }}>
          <h2 className='phin-page-heading'>ConnectWise Integrations </h2>
          <Chip label='BETA' sx={{ backgroundColor: 'var(--purple-75)', color: 'white', marginLeft: '.5rem' }} />
        </div>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{ marginTop: 'var(--phin-s-1)', marginBottom: 'var(--phin-s-1)' }}
        >
          <Grid>
            <StatusBreadCrumbs
              steps={['Not Connected', 'Connected']}
              step={partnerIntegrationsMap?.[integrationTypes.CONNECTWISE] ? 1 : 0}
            />
          </Grid>
        </Grid>

        <Tabs value={getCurrentTabFromUrl()} onChange={handleChangeTab} sx={{ marginBottom: 'var(--phin-s2)' }}>
          <Tab label='Integrations' id='integration-tab' disabled={(!cwIntegrationEnabled)} />
          <Tab label='Service Desk' id='service-desk-tab' disabled={(!cwIntegrationEnabled || !cwServiceDeskEnabled)} />
          <Tab label='Billing' id='billing-tab' disabled={(!cwIntegrationEnabled || !cwBillingEnabled)} />
        </Tabs>
        <Switch>
          <Route exact path={path}> <ConnectWiseIntegrationTab partnerId={partnerId} /> </Route>
          <Route path={`${path}/serviceDesk`}>
            <ConnectWiseServiceDeskTable partnerId={partnerId} cwServiceDeskEnabled={cwServiceDeskEnabled} />
          </Route>
          <Route path={`${path}/billing`}>
            <ConnectWiseBillingTab partnerId={partnerId} cwBillingEnabled={cwBillingEnabled} />
          </Route>
        </Switch>
      </Box>
    </>
  )
}
