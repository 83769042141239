import { Button, Card, Chip, Grid } from '@mui/material'
import { IoTrash } from 'react-icons/io5'
import { apiRequestUtils } from '../../utils/apiRequestUtils'
import { useEffect } from 'react'
import PDFObject from 'pdfobject/pdfobject.js'
import { DateTime } from 'luxon'
import { formatDate } from '../../utils/dateUtils'

export function ActivePolicyCard ({ policy, openCancelModal, companyId }) {
  useEffect(() => {
    async function getPolicy () {
      const policyIFrame = document.querySelector(`#policy-frame-${policy.id}`)
      policyIFrame.src = apiRequestUtils.getActivePolicyRootPath(policy.id, companyId)
      PDFObject.embed(apiRequestUtils.getActivePolicyRootPath(policy.id, companyId), `#policy-frame-${policy.id}`)
    }
    getPolicy()
  }, [])

  return (
    <Card
      variant='outlined'
      sx={{
        width: '325px',
        minHeight: '350px',
        marginBottom: 'var(--phin-s2)',
        padding: '16px',
        borderWidth: '3px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <div style={{ minHeight: '100px' }}>
        <Chip
          label={<span className='phin-body-text'>Policy</span>}
          color='phinSecondary'
          sx={{ marginBottom: 'var(--phin-s-1)' }}
        />
        <p className='phin-h5 policy-card-title' id={`policy-card-title-${policy.name}`}>{policy.name}</p>
      </div>

      <iframe id={`policy-frame-${policy.id}`} className=' w-100' style={{ height: '200px', backgroundColor: 'grey', marginBottom: 'var(--phin-s0)' }} />

      <div className='margin-bottom:0'>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{ marginBottom: 'var(--phin-s-1)' }}
        >
          Assignment Date:
          <Chip
            color='primary'
            variant='outlined'
            label={formatDate(DateTime.fromISO(policy.dateStarted))}
          />
        </Grid>

        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{ marginBottom: 'var(--phin-s-1)' }}
        >
          Total Assigned:
          <Chip
            id={`total-assigned-chip-${policy.name}`}
            color='primary'
            variant='outlined'
            label={policy.totalAssigned}
          />
        </Grid>

        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          Total Completed:
          <Chip
            id={`total-completed-chip-${policy.name}`}
            color='primary'
            variant='outlined'
            label={policy.totalCompleted}
          />
        </Grid>
      </div>

      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
      >

        <Button
          variant='contained'
          id={`cancel-${policy.name}-policy-button`}
          aria-label={`Cancel ${policy.name} Policy Button`}
          startIcon={<IoTrash />}
          color='error'
          onClick={() => openCancelModal(policy)}
        >Cancel Policy
        </Button>
      </Grid>
    </Card>
  )
}
