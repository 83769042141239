import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CourseDetailModal } from '../../CourseDetailModal'
import Spinner from '../../Spinner'
import './onboardingSelectorTable.scss'
import { IoEye, IoInformation } from 'react-icons/io5'
import { CoursePreviewModal } from '../../../../shared/components/CoursePreviewModal'
import { Box, IconButton } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'

export const TrainingModuleSelectorTable = ({ companyId, modules, setModules, rowCount, lock }) => {
  const { courseCatalog, loaders } = useSelector((state) => state.trainings)
  const { isLoadingCourseCatalog } = loaders
  const [maxPage, setMaxPage] = useState(null)
  const [showDetailModal, setShowDetailModal] = useState(false)
  const [courseDetailSelected, setCourseDetailSelected] = useState()
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [selectedModuleIds, setSelectedModuleIds] = useState([])
  const [previewCourse, setPreviewCourse] = useState()
  const [tablePageSize, setTablePageSize] = useState(10)

  const toggleDetailModal = (course, shouldShowDetailModal) => {
    setShowDetailModal(shouldShowDetailModal)
    setCourseDetailSelected(course)
  }

  const togglePreviewModal = (course, shouldShowPreviewModal) => {
    setPreviewCourse(course)
    setShowPreviewModal(shouldShowPreviewModal)
  }

  useEffect(() => {
    setSelectedModuleIds(modules.map((module) => module.id))
    if (courseCatalog) {
      setMaxPage(Math.ceil(courseCatalog.length / rowCount))
    }
  }, [courseCatalog])

  const columns = [
    {
      headerName: 'Provider',
      field: 'provider',
      flex: 1
    },
    {
      headerName: 'Course Name',
      field: 'name',
      flex: 1
    },
    {
      headerName: 'Topic',
      field: 'topic',
      flex: 1
    },
    {
      headerName: 'Duration (min)',
      field: 'durationInMinutes',
      flex: 1
    },
    {
      headerName: 'Details',
      field: 'details',
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              id={`course-details-${params.row.id}-button`}
              aria-label={`Course Details ${params.row.name} Button`}
              onClick={() => {
                setShowDetailModal(true)
                setCourseDetailSelected(params.row)
              }}
            >
              <IoInformation id='details' />
            </IconButton>
          </Box>
        )
      },
      width: 100,
      sortable: false,
      filterable: false
    },
    {
      headerName: 'Preview',
      field: 'preview',
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              id={`course-preview-${params.row.id}-button`}
              aria-label={`Course Preview ${params.row.name} Button`}
              onClick={() => {
                setPreviewCourse(params.row)
                setShowPreviewModal(true)
              }}
            >
              <IoEye id='delete' />
            </IconButton>
          </Box>
        )
      },
      width: 100,
      sortable: false,
      filterable: false
    }
  ]

  const averageDurationOfCustomModules = 5

  return !maxPage || isLoadingCourseCatalog
    ? <Spinner />
    : (
      <>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <DataGrid
            getRowId={(row) => row.id}
            style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
            className='DataGrid'
            initialState={{
              sorting: {
                sortModel: [{ field: 'name', sort: 'asc' }]
              }
            }}
            autoHeight
            rows={courseCatalog}
            columns={columns}
            pageSize={tablePageSize}
            onPageSizeChange={(newSize) => setTablePageSize(newSize)}
            rowsPerPageOptions={[10, 25, 50]}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500, id: 'course-select-table-search', 'aria-label': 'Search' },
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true }
              }
            }}
            onSelectionModelChange={(selectedModules) => {
              setModules(selectedModules.map((moduleId) => {
                const { id, name, path, durationInMinutes } = courseCatalog.find((catalogCourse) => catalogCourse.id === moduleId)
                return ({ id, name, path, durationInMinutes })
              })
              )
              setSelectedModuleIds(selectedModules)
            }}
            selectionModel={selectedModuleIds}
            disableSelectionOnClick
            disableDensitySelector
            disableColumnMenu
            checkboxSelection
          />
          <div style={{ paddingTop: '32px', display: 'flex', justifyContent: 'space-around' }}>
            <span>
              Course Count: {modules.length}
            </span>
            <span>
              Estimated Completion Time: {modules.length > 0 ? modules.reduce((acc, module) => acc + (module.durationInMinutes !== undefined ? module.durationInMinutes : averageDurationOfCustomModules), 0) : 0} Minutes
            </span>
          </div>
        </div>
        <CoursePreviewModal
          companyId={companyId}
          courseDefinition={previewCourse}
          previewModalHeader={previewCourse?.name || ''}
          showPreviewModal={showPreviewModal}
          togglePreviewModal={togglePreviewModal}
        />
        <CourseDetailModal
          course={courseDetailSelected}
          detailModalHeader='Course Details'
          showDetailModal={showDetailModal}
          toggleDetailModal={toggleDetailModal}
        />
      </>
      )
}
