import { DataGrid } from '@mui/x-data-grid'
import { ConnectWiseCompanyDropdown } from './ConnectWiseCompanyDropdown'
import { ConnectWiseAgreementDropdown } from './ConnectWiseAgreementDropdown'
import { ConnectWiseAdditionDropdown } from './ConnectWiseAdditionDropdown'
import { Box, Button, Grid, Tooltip, Typography } from '@mui/material'
import { IoCheckmarkCircle, IoCloseCircleSharp } from 'react-icons/io5'

import { useGetCwMappingsQuery, useSyncCwCompaniesMutation, useGetSyncStatusQuery, useLazyGetCwMappingsQuery, useStartAutomapMutation } from '../../../../store/ConnectWiseApiSlice'
import NotificationUtilities from '../../notifications/notificationUtils'
import { useEffect } from 'react'
import { TableFooter } from './TableFooter'
import { generateLastMonthBillingDateRange } from './connectwiseUtils'
import { useHistory } from 'react-router-dom'
import { TableHeader } from './TableHeader'

export function ConnectWiseBillingTab ({ partnerId, cwBillingEnabled }) {
  const history = useHistory()
  if (!cwBillingEnabled) {
    history.replace(`/partners/${partnerId}/integrations/connectWise`)
  }
  const [syncCwCompanies, { data: syncCwCompaniesData, error: syncCwCompaniesError, isLoading: isLoadingSyncCwCompanies, isSuccess: isSyncCwCompaniesSuccess }] = useSyncCwCompaniesMutation()

  const { data: companyMappings, isLoading: isLoadingCwCompanyMappings } = useGetCwMappingsQuery({ partnerId })
  const [getMappingsTriggerFunction] = useLazyGetCwMappingsQuery()

  const { data: syncStatus } = useGetSyncStatusQuery({ partnerId }, { pollingInterval: 3000 })
  const lastSyncTime = syncStatus?.lastSyncTime
  const isSyncing = syncStatus?.isSyncing || isLoadingSyncCwCompanies || syncStatus?.isAutoMapping  
  const checkValidCwMappingExists = companyMappings?.rows.some((row) => (row.cwCompanyId && row.cwAgreementId && row.cwAdditionId))

  const handleSyncCWButtonPress = () => {
    if (checkValidCwMappingExists) {
      try {
        syncCwCompanies({ partnerId })
      } catch (error) {
        NotificationUtilities.sendErrorMessage('Failed to sync ConnectWise')
      }
    } else {
      NotificationUtilities.sendErrorMessage('At least one company must be fully configured to begin syncing')
    }
  }

  useEffect(() => {
    if (isSyncCwCompaniesSuccess) {
      NotificationUtilities.sendSuccessMessage('Connectwise Syncing process started successfully. Please wait a moment for the sync to complete')
    } else if (syncCwCompaniesError) {
      NotificationUtilities.sendErrorMessage(syncCwCompaniesError?.data?.error)
    }
  }, [isSyncCwCompaniesSuccess, syncCwCompaniesError, syncCwCompaniesData])

  useEffect(() => {
    if (!isSyncing) {
      getMappingsTriggerFunction({ partnerId })
    }
  }, [isSyncing, getMappingsTriggerFunction, partnerId])

  return (
    <Box>
      <Grid
        container
        direction='column'
        justifyContent='space-between'
        alignItems='start'
        sx={{ marginBottom: 'var(--phin-s-1)' }}
      >
        <Grid container flex flexDirection='row' alignItems='center' gap={1} sx={{ padding: '1rem', background: 'var(--blue-0)', maxWidth: 'fit-content', borderRadius: '4px' }}>
          <Typography fontWeight='bold' variant='subtitle1'>Billing Period: </Typography>
          <Typography variant='subtitle1'>{generateLastMonthBillingDateRange()}</Typography>
        </Grid>
      </Grid>
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          loading={isLoadingCwCompanyMappings}
          className='DataGrid'
          rowHeight={60}
          sx={{ marginBottom: 'var(--phin-s1)' }}
          columns={[
            {
              headerName: 'Phin Company',
              field: 'companyName',
              minWidth: 200,
              flex: 1
            },
            {
              headerName: 'Billable Users',
              field: 'lastMonthBillableUsers',
              minWidth: 190,
              flex: 1
            },
            {
              headerName: 'ConnectWise Company',
              field: 'cwCompanyName',
              minWidth: 300,
              flex: 2,
              renderCell: (params) => {
                return (
                  <ConnectWiseCompanyDropdown
                    partnerId={partnerId}
                    companyId={params.row.companyId}
                    cwCompany={params.row.cwCompanyId ? { id: params.row.cwCompanyId, label: params.row.cwCompanyName } : null}
                    isDisabled={isSyncing || isLoadingSyncCwCompanies}
                  />
                )
              }
            },
            {
              headerName: 'ConnectWise Agreement',
              field: 'cwAgreementName',
              minWidth: 300,
              flex: 2,
              renderCell: (params) => {
                return (
                  <ConnectWiseAgreementDropdown
                    cwCompanyId={params.row.cwCompanyId}
                    cwAgreement={params.row.cwAgreementId ? { id: params.row.cwAgreementId, label: params.row.cwAgreementName } : null}
                    partnerId={partnerId}
                    companyId={params.row.companyId}
                    isDisabled={isSyncing || isLoadingSyncCwCompanies}
                  />
                )
              }
            },
            {
              headerName: 'ConnectWise Addition',
              field: 'cwAdditionName',
              minWidth: 300,
              flex: 2,
              renderCell: (params) => {
                return (
                  <ConnectWiseAdditionDropdown
                    cwAgreementId={params.row.cwAgreementId}
                    cwAddition={params.row.cwAdditionId ? { id: params.row.cwAdditionId, label: params.row.cwAdditionName } : null}
                    partnerId={partnerId}
                    companyId={params.row.companyId}
                    isDisabled={isSyncing || isLoadingSyncCwCompanies}
                  />
                )
              }
            },
            {
              headerName: 'Billing Status',
              field: 'lastSyncSucceeded',
              minWidth: 150,
              align: 'center',
              renderCell: (params) => {
                if (!params.row.cwAdditionId ||
                          params.row.lastSyncSucceeded === undefined ||
                          params.row.lastSyncSucceeded === null) {
                  return <></>
                }

                return (
                  <Tooltip title={params.row.lastSyncTime}>
                    <Grid>
                      {params.row.lastSyncSucceeded
                        ? (
                          <IoCheckmarkCircle color='var(--phin-green)' size='1.5em' />
                          )
                        : (
                          <IoCloseCircleSharp color='var(--phin-red)' size='1.5em' />
                          )}
                    </Grid>
                  </Tooltip>
                )
              }
            }
          ]}
          rows={companyMappings?.rows || []}
          components={{ Footer: TableFooter, Toolbar: TableHeader }}
          componentsProps={{
            toolbar: {
              partnerId,
              isButtonDisabled: isSyncing
            },
            footer: {
              companyMappings
            }
          }}
          disableSelectionOnClick
          disableColumnSelector
          disableDensitySelector
          isRowSelectable={() => false}
        />

      </div>

      <Grid container direction='row' justifyContent='end' alignItems='center' gap={3} sx={{ mt: 'var(--phin-s-1)' }}>

        {lastSyncTime && (
          <Grid item>
            <Grid container direction='row' gap={1}>
              <Typography fontWeight='bold' variant='subtitle1'>Last Push: </Typography>
              <Typography variant='subtitle1'>{new Date(lastSyncTime._seconds * 1000).toLocaleString()}</Typography>
            </Grid>
          </Grid>)}
        <Button
          id='sync-cw-billing-data-button'
          aria-label='Sync with ConnectWise Button'
          variant='contained'
          size='large'
          disabled={isSyncing || !cwBillingEnabled}
          onClick={handleSyncCWButtonPress}
        >
          {isSyncing ? 'Syncing Usage' : cwBillingEnabled ? 'Push Billing' : 'Billing Disabled'}
        </Button>
      </Grid>
    </Box>
  )
}

