import {
  MDBCol, MDBRow
} from 'mdb-react-ui-kit'
import React, { useState } from 'react'
import { navigationRoutes } from '../frontendConsts.js'
import { useHistory } from 'react-router'
import NotificationUtilities from './components/notifications/notificationUtils'
import { apiRequestUtils } from '../utils/apiRequestUtils'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from './components/Spinner'
import { deleteTrainingCourseThunk, enrollUsersThunk } from '../store/TrainingSlice'
import { deleteTrainingCampaignThunk } from '../store/CampaignsSlice'
import { resetAllCampaignFrequencyChoicesAction } from '../store/CampaignFrequencySlice'
import { EnrollUserModal } from './components/training/EnrollUserModal'
import { getCampaignNameFromId } from './components/training/utils'
import ActiveTrainingCampaigns from './components/training/ActiveTrainingCampaigns'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Button, Grid, IconButton, Tooltip } from '@mui/material'
import { DateTime } from 'luxon'
import { IoAddCircle, IoAddCircleSharp, IoPaperPlane, IoTrash } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import PhinModal from './components/PhinModal.js'

function TrainingPage ({ id }) {
  const history = useHistory()
  const dispatch = useDispatch()

  const { company, loaders } = useSelector((state) => state.company)
  const { isLoadingCompany } = loaders
  const { activeCampaigns, pastCampaigns, loaders: campaignLoaders } = useSelector((state) => state.campaigns)
  const { isLoadingCampaigns } = campaignLoaders
  const { trainings, loaders: trainingLoaders } = useSelector((state) => state.trainings)
  const { isLoadingTrainings } = trainingLoaders

  const [cancelModal, showCancelModal] = useState(false)
  const [confirmModal, showConfirmModal] = useState(false)
  const [selectedCourse, selectCourse] = useState(null)
  const [selectedDeleteCourse, selectCourseForDelete] = useState(null)
  const [cancelCampaignModal, showCampaignCancelModal] = useState(false)
  const [selectedCampaign, setSelectedCampaign] = useState(null)

  const [selectedUsers, selectUsers] = useState([])
  const [isConfirmEnrollModalOpen, setIsConfirmEnrollModalOpen] = useState(false)
  const [shouldSendReminders, setShouldSendReminders] = useState(false)

  const [isEnrollUserModalOpen, setEnrollUserModalOpen] = useState(false)
  const [tablePageSize, setTablePageSize] = useState(10)

  const toggleEnrollUserModal = ({ isOpen, course }) => {
    setEnrollUserModalOpen(isOpen)
    selectCourse(course)
  }

  const handleCreateCurriculum = async () => {
    await dispatch(resetAllCampaignFrequencyChoicesAction())
    history.push(`/companies/${id}${navigationRoutes.TRAINING_CURRICULUM_FORM}/new`)
  }

  function openConfirmModal (course) {
    selectCourse(course)
    showConfirmModal(true)
  }

  function closeConfirmModal () {
    selectCourse(null)
    showConfirmModal(false)
  }

  function openCancelModal (course) {
    selectCourseForDelete(course)
    showCancelModal(true)
  }

  function closeCancelModal () {
    selectCourseForDelete(null)
    showCancelModal(false)
  }

  // NOTE: campaign modification modals, keeping them separate in hopes of dropping adhoc courses in the future
  function closeCancelCampaignModal () {
    showCampaignCancelModal(false)
  }

  function openCancelCampaignModal (campaignId) {
    setSelectedCampaign(campaignId)
    showCampaignCancelModal(true)
  }

  async function confirmCourseDeletion (course) {
    const success = await dispatch(deleteTrainingCourseThunk({ id, course }))

    if (success) {
      selectCourseForDelete(null)
      showCancelModal(false)
    }
  }

  async function confirmTrainingCampaignDeletion (campaign) {
    const success = await dispatch(deleteTrainingCampaignThunk({ id, campaignId: campaign.id }))

    if (success) {
      setSelectedCampaign(null)
      showCampaignCancelModal(false)
    }
  }

  async function confirmCourseNotification (course) {
    try {
      const res = await apiRequestUtils.post(`/api/companies/${id}/training/training-reminder`, {
        activeTrainingCourseId: course.id,
        activeTrainingCourseName: course.name,
        userNotificationType: 'reminder'
      })

      await res.json()

      if (res.status === 200) {
        NotificationUtilities.sendSuccessMessage('Reminders Sent!')
      } else {
        NotificationUtilities.sendErrorMessage('Error Sending Reminders, please contact Phin if problem persists!')
      }
    } catch (error) {
      NotificationUtilities.sendErrorMessage('Error Sending Reminders, please contact Phin if problem persists!')
    }

    selectCourse(null)
    showConfirmModal(false)
  }

  function generateActionButtons (course) {
    return (
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Tooltip
          title='Enroll User' placement='top'
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -14]
                  }
                }
              ]
            }
          }}
        >
          <IconButton
            id={`enroll-users-button-${course.id}`}
            cypress-test-id={`enroll-users-button-${course.name}`}
            aria-label='Enroll Users Button'
            size='medium'
            onClick={() => toggleEnrollUserModal({ isOpen: true, course })}
            color='primary'
          >
            <IoAddCircle />
          </IconButton>
        </Tooltip>
        <Tooltip
          title='Remind Users' placement='top'
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -14]
                  }
                }
              ]
            }
          }}
        >
          <IconButton
            id={`remind-users-button-${course.id}`}
            cypress-test-id={`remind-users-button-${course.name}`}
            aria-label='Remind Users Button'
            size='medium'
            onClick={() => openConfirmModal(course)}
            color='primary'
          >
            <IoPaperPlane />
          </IconButton>
        </Tooltip>

        <Tooltip
          title='Cancel Course' placement='top'
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -14]
                  }
                }
              ]
            }
          }}
        >
          <IconButton
            id={`cancel-course-button-${course.id}`}
            cypress-test-id={`cancel-course-button-${course.id}`}
            aria-label='Cancel Course Button'
            size='medium'
            onClick={() => openCancelModal(course)}
            color='error'
          >
            <IoTrash />
          </IconButton>
        </Tooltip>
      </Grid>
    )
  }

  const trainingCourseColumns = [
    { field: 'name', headerName: 'Name', minWidth: 400, flex: 2 },
    { field: 'totalEnrolled', headerName: 'Enrolled', minWidth: 100, flex: 1 },
    { field: 'totalCompleted', headerName: 'Completed', minWidth: 100, flex: 1 },
    { field: 'campaignId', headerName: 'Linked Campaign Name', minWidth: 300, flex: 2, valueGetter: (params) => params.row.campaignId !== 'adhoc' ? getCampaignNameFromId(params.row.campaignId, [...activeCampaigns, ...pastCampaigns]) : '' },
    { field: 'dateStarted', headerName: 'Start Date', minWidth: 200, flex: 1, renderCell: (field) => { return DateTime.fromISO(field.value).toLocaleString(DateTime.DATETIME_SHORT) } },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 175,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      flex: 1,
      renderCell: (field) => {
        return generateActionButtons(field.row)
      }
    }
  ]

  const renderSelectedUsersNames = () => {
    const selectedUserNames = []

    if (selectedUsers) {
      for (const selectedUser of selectedUsers) {
        selectedUserNames.push(
          <li>{selectedUser.value.first} {selectedUser.value.last}</li>
        )
      }
    }

    return selectedUserNames
  }

  return (
    <div className='padding-bottom:2'>
      <PhinModal
        isOpen={confirmModal}
        title='Send Reminder'
        close={() => closeConfirmModal()}
        closeText='Cancel'
        action={() => confirmCourseNotification(selectedCourse)}
        actionText='Remind Users'
      >
        <p>You are about to notify all users who have not yet completed this course. </p> <p> Are you sure you would like to do this?</p>
      </PhinModal>

      <PhinModal
        isOpen={cancelModal}
        title='Cancel Course'
        close={() => closeCancelModal()}
        action={() => confirmCourseDeletion(selectedDeleteCourse)}
        closeText='Continue Course'
        actionText='Cancel Course'
        actionColor='error'
      >
        <p>Are you sure you would like to cancel this course? <strong>This can not be undone!</strong>
          <br /> Your data will be saved and viewable from the Analytics page.
        </p>
      </PhinModal>

      <PhinModal
        isOpen={cancelCampaignModal}
        title='Cancel Campaign'
        close={() => closeCancelCampaignModal()}
        closeText='Cancel'
        action={() => confirmTrainingCampaignDeletion(selectedCampaign)}
        actionText='Delete Campaign'
        actionColor='error'
      >
        <p>Are you sure you would like to cancel this training campaign? <strong>This can not be undone!</strong>
          <br /> Your data will be saved and viewable from the Analytics page.
        </p>
      </PhinModal>

      <PhinModal
        isOpen={isConfirmEnrollModalOpen}
        title='Enroll Users'
        close={() => { setIsConfirmEnrollModalOpen(false); toggleEnrollUserModal({ isOpen: true, course: selectedCourse }) }}
        closeText='Cancel'
        action={async () => {
          const userValues = selectedUsers.map((user) => user.value)
          const success = await dispatch(enrollUsersThunk({ companyId: id, courseId: selectedCourse.id, selectedUsers: userValues, shouldSendReminders }))

          if (success) {
            selectUsers([])
            setIsConfirmEnrollModalOpen(false)
          }
        }}
        actionText={`Enroll ${selectedUsers && selectedUsers.length > 1 ? 'Users' : 'User'}`}
      >
        <div style={{ textAlign: 'left' }}>
          <p>You are about to enroll {selectedUsers ? selectedUsers.length : 0} {selectedUsers && selectedUsers.length > 1 ? 'users' : 'user'} in this course: '{selectedCourse?.name}'. </p>
          {shouldSendReminders && (
            <p>Reminder emails will be sent immediately.</p>
          )}
          <p>{selectedUsers && selectedUsers.length > 1 ? 'These users' : 'This user'} will be enrolled:
            <ul>
              {renderSelectedUsersNames()}
            </ul>
          </p>
          <p> Are you sure you would like to do this?</p>
        </div>
      </PhinModal>

      {isEnrollUserModalOpen && (

        <EnrollUserModal
          isOpen={isEnrollUserModalOpen}
          companyId={id}
          selectedUsers={selectedUsers}
          selectUsers={selectUsers}
          selectedCourse={selectedCourse}
          toggleEnrollUserModal={toggleEnrollUserModal}
          setEnrollModalOpen={setEnrollUserModalOpen}
          setIsConfirmEnrollModalOpen={setIsConfirmEnrollModalOpen}
          setShouldSendReminders={setShouldSendReminders}
        />
      )}

      <MDBRow className='d-flex justify-content-center'>
        <h1>Training Dashboard</h1>
      </MDBRow>
      <MDBRow className='d-flex justify-content-center'>
        <p>All active trainings are shown here at a glance as well as info on their progress</p>
      </MDBRow>
      <MDBRow className='d-flex justify-content-center mb-5'>
        <MDBCol xl='4' md='4' className='d-flex justify-content-center align-items-center'>
          <Button onClick={handleCreateCurriculum} variant='contained' color='primary' size='lg'>
            <MDBRow className='d-flex justify-content-center align-items-center'>
              <MDBCol size='2' className='pr-1 pl-0'>
                <IoAddCircleSharp size='3em' />
              </MDBCol>
              <MDBCol className='pr-0 pl-0' id='startNewCurriculumBtn' size='9'>
                Launch a Training Campaign
              </MDBCol>
            </MDBRow>
          </Button>
        </MDBCol>
      </MDBRow>
      <h1>Active Training Campaigns</h1>
      <p>Active Training Campaigns include any current Onboarding, Continuous, or Fixed campaigns that are assigning courses to your audience. Manage and view details whenever you need to by selecting 'edit' or 'view' in the cards below.</p>
      <br />

      {(!activeCampaigns || isLoadingCompany || isLoadingCampaigns) && (
        <Spinner />
      )}

      {activeCampaigns && !isLoadingCompany && !isLoadingCampaigns && (
        <ActiveTrainingCampaigns
          openCancelCampaignModal={openCancelCampaignModal}
          company={company}
        />
      )}
      <br />
      <hr />
      <br />
      <h1>Active Training Courses</h1>
      <p>Active Courses include any courses that have been assigned through a campaign or individually from our <Link relative='path' to={`/companies/${id}/training/catalog`}>Course Library</Link>. Manage each assignment whenever you need to by adding users, sending reminders, or removing courses in the table below.</p>
      <br />

      {(trainings && !isLoadingTrainings) && (
        <Grid sx={{ width: '100%' }}>
          {/* Training Courses DataGrid */}
          <DataGrid
            autoHeight
            style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
            className='DataGrid'
            initialState={{
              sorting: {
                sortModel: [{ field: 'dateStarted', sort: 'desc' }]
              }
            }}
            rows={trainings}
            columns={trainingCourseColumns}
            pageSize={tablePageSize}
            onPageSizeChange={(newSize) => setTablePageSize(newSize)}
            rowsPerPageOptions={[10, 25, 50]}
            disableSelectionOnClick
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true }
              }
            }}
            disableColumnSelector
            disableDensitySelector
          />
        </Grid>
      )}
    </div>
  )
}

export default TrainingPage
