import { shareLevels } from '../../../../frontendConsts.js'
import { Button, Card, CardActions, CardContent, Chip, IconButton, Tooltip } from '@mui/material'
import { IoPencil, IoRocket, IoTrash } from 'react-icons/io5'

export default function PresetCard ({
  cardInfo, launchCampaignPreset,
  editCallback = undefined, deleteCallback = undefined
}) {
  function generateCampaignPresetType () {
    switch (cardInfo.shareLevel) {
      case shareLevels.PREMIUM:
        return 'Phin'
      case shareLevels.PARTNER:
        return 'Shared'
      default:
        return 'Private'
    }
  }

  function generateCampaignPresetColor () {
    switch (cardInfo.shareLevel) {
      case shareLevels.PREMIUM:
        return 'primary'
      case shareLevels.PARTNER:
        return 'phinGreen'
      default:
        return 'phinSecondary'
    }
  }

  return (
    <Card
      variant='outlined'
      sx={{
        padding: 'var(--phin-s2)',
        minHeight: '350px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <CardContent sx={{ padding: 0 }}>
        <div style={{ minHeight: '100px' }}>
          <Chip
            label={<span id={`preset-card-type-${cardInfo.name}`} className='phin-body-text'>{generateCampaignPresetType()}</span>}
            color={generateCampaignPresetColor()}
            sx={{ marginBottom: 'var(--phin-s-1)' }}
          />
          <p id={`preset-card-${cardInfo.name}`} className='phin-h5'>{cardInfo.name}</p>
        </div>

        <p className='phin-body-text-small-gray'>{cardInfo.description}</p>

      </CardContent>
      <CardActions sx={{ padding: 0 }}>
        <Button
          id={`launch-campaign-preset-${cardInfo.name}-button`}
          aria-label='Launch Campaign Preset'
          size='large'
          variant='outlined'
          startIcon={<IoRocket />}
          onClick={() => launchCampaignPreset(cardInfo)}
        >Launch
        </Button>

        {(cardInfo.shareLevel !== shareLevels.PREMIUM) && (
          <Button
            id={`edit-campaign-preset-${cardInfo.name}-button`}
            aria-label='Edit Campaign Preset'
            size='large'
            variant='outlined'
            startIcon={<IoPencil />}
            onClick={() => editCallback(cardInfo)}
          >Edit
          </Button>
        )}

        {(cardInfo.shareLevel !== shareLevels.PREMIUM) && (
          <Tooltip title='Delete Campaign Preset'>
            <IconButton
              id={`delete-campaign-preset-${cardInfo.name}-button`}
              aria-label={`Delete ${cardInfo.name} Preset Button`}
              color='error'
              size='small'
              onClick={() => deleteCallback(cardInfo)}
            >
              <IoTrash />
            </IconButton>
          </Tooltip>
        )}
      </CardActions>
    </Card>
  )
}
