import React from 'react'
import { StaticAlert } from '../../../shared/components/StaticAlert'
import { Collapse, FormControlLabel, Checkbox, Grid } from '@mui/material'
import { PhinCard } from '../PhinCard'

const renderTextWaring = (sendingChannels) => {
  return (
    <div className='margin:0'>
      <Collapse in={sendingChannels.sms === true} {...(sendingChannels.sms === true ? { timeout: 500 } : {})}>
        <StaticAlert
          severity='warning' color='warning' variant='filled' text='You have selected to send SMS Messages to your users. Please ensure you have the correct 10 digit phone number for each user
          or we cannot guarantee proper delivery.'
        />
      </Collapse>
    </div>
  )
}

const CampaignSendingChannelEditor = ({ sendingChannels, updateSendingChannels, disableUpdating = false }) => {
  return (
    <PhinCard
      title='Sending Channels'
      description='Here you can select the channels from which you would like your users to receive phishes. For Example: Email & SMS.'
    >
      <Grid container justifyContent='start'>
        <FormControlLabel
          label='Email'
          control={
            <Checkbox
              id='emailCheckBox'
              disabled={disableUpdating}
              checked={sendingChannels?.email}
              onChange={() => updateSendingChannels('email')}
            />
  }
        />
        <FormControlLabel
          label='SMS'
          control={
            <Checkbox
              id='SMSCheckBox'
              disabled={disableUpdating}
              checked={sendingChannels?.sms}
              onChange={() => updateSendingChannels('sms')}
            />
  }
        />
      </Grid>
      {renderTextWaring(sendingChannels)}
    </PhinCard>
  )
}

export default CampaignSendingChannelEditor
