import { Grid, Skeleton } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CloudDownloadButton } from '../../components/buttons/CloudDownloadButton'
import { DateTime } from 'luxon'
import { getPartnerBillingInvoiceReportPDFThunk } from '../../../store/PartnerBillingSlice'

export function ChargeBeeInvoicesTable ({ partnerId }) {
  const dispatch = useDispatch()
  const { partner, loaders } = useSelector((state) => state.partner)
  const {
    isLoadingPartner
  } = loaders

  const { partnerBillingInvoices, loaders: partnerBillingLoaders } = useSelector((state) => state.partnerBilling)
  const { isLoadingPartnerBillingInvoices, isDownloadingPartnerInvoiceReport } = partnerBillingLoaders

  const [tablePageSize, setTablePageSize] = useState(10)

  const columns = [
    {
      headerName: 'Name',
      field: 'reportName',
      flex: 1
    },
    {
      headerName: 'Billing Period',
      field: 'billingPeriod',
      flex: 1
    },
    {
      headerName: 'Creation Date',
      field: 'invoiceCreationDate',
      flex: 1,
      renderCell: (params) => { return DateTime.fromSeconds(params.row.invoiceCreationDate).toLocaleString(DateTime.DATE_FULL) }
    },
    {
      headerName: '',
      field: 'id',
      flex: 0.5,
      sortable: false,
      filterable: false,
      renderCell: (params) => { return generateDownloadButton(params.row) }
    }
  ]

  const generateDownloadButton = (invoice) => {
    return (
      <CloudDownloadButton
        id='test'
        isDownloading={isDownloadingPartnerInvoiceReport[invoice.id]}
        downloadAction={() => {
          dispatch(getPartnerBillingInvoiceReportPDFThunk({ partnerId, invoice }))
        }}
      />
    )
  }

  return (
    <Grid container className='margin-bottom:2'>
      {(isLoadingPartner || isLoadingPartnerBillingInvoices) && (
        <Skeleton sx={{ bgcolor: '#c4c4c4' }} variant='rectangle' width={1396} height={400} />
      )}

      {(!isLoadingPartner && partner && !isLoadingPartnerBillingInvoices && partnerBillingInvoices) && (
        <Grid container sx={{ mb: 1 }}>
          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', marginBottom: 'var(--phin-s-2)' }}>
            <div className='phin-h4' style={{ textAlign: 'left' }}>Invoices</div>
          </Grid>

          <div style={{ width: '100%' }}>
            <DataGrid
              style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
              className='DataGrid'
              initialState={{
                sorting: {
                  sortModel: [{ field: 'billingPeriodDate', sort: 'asc' }]
                }
              }}
              autoHeight
              rows={partnerBillingInvoices}
              columns={columns}
              pageSize={tablePageSize}
              onPageSizeChange={(newSize) => setTablePageSize(newSize)}
              rowsPerPageOptions={[10, 25, 50]}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: {
                    debounceMs: 500,
                    id: 'company-management-table-search',
                    'aria-label': 'Search'
                  },
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true }
                }
              }}
              disableSelectionOnClick
              disableColumnSelector
              disableDensitySelector
              disableColumnFilter
              disableColumnMenu
            />
          </div>
        </Grid>
      )}
    </Grid>
  )
}
