import { roleTypes } from '../frontendConsts'
import { getBaseRole } from './roleManager'

export function smartRoute ({ authorization, history, logout }) {
  const baseRole = getBaseRole(authorization)

  if (baseRole === null) {
    logout({ returnTo: window.location.origin })
  } else {
    if (baseRole.roleType === roleTypes.COMPANY) {
      history.push(`/companies/${baseRole.id}`)
    } else if (baseRole.roleType === roleTypes.PARTNER) {
      history.push(`/partners/${baseRole.id}`)
    } else if (baseRole.roleType === roleTypes.DISTRIBUTOR) {
      history.push(`/distributors/${baseRole.id}`)
    }
  }
}
