import { Chip, Grid } from '@mui/material'

export function PhinChip ({ id, icon, color, label, variant, onClickFunction, size }) {
  let verticalPaddingValue, horizontalPaddingValue, textClassName

  switch (size) {
    case 'large':
      verticalPaddingValue = 'var(--phin-s2)'
      horizontalPaddingValue = 'var(--phin-s1)'
      textClassName = 'phin-h6'
      break
    case 'medium':
      verticalPaddingValue = 'var(--phin-s2)'
      horizontalPaddingValue = 'var(--phin-s0)'
      textClassName = 'phin-body-text'
      break
    default:
      verticalPaddingValue = 'var(--phin-s1)'
      horizontalPaddingValue = 'var(--phin-s1)'
      textClassName = 'phin-body-text'
  }

  return (
    <Chip
      id={id}
      label={
        <Grid
          container
          direction='column'
          justifyContent='space-between'
          alignItems='center'
        >
          {icon}
          <div className={textClassName}>{label}</div>
        </Grid>
            }
      color={color}
      variant={variant}
      onClick={onClickFunction}
      sx={{ padding: verticalPaddingValue + ' ' + horizontalPaddingValue }}
    />
  )
}
