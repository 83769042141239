import { useState, useEffect } from 'react'
import {
  MDBCol, MDBRow, MDBIcon, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu
} from 'mdb-react-ui-kit'
import { apiRequestUtils } from '../../../utils/apiRequestUtils'
import { generateGroupDropdownItems, renderGroups } from './groupFilters'
import '../../css/audience.css'

function AudienceFiltersV2 ({ groups, parameters, setParameters, audienceFilterType, setAudienceFilterType, id }) {
  const [inputValue, setInputValue] = useState('')
  const [audienceStats, setAudienceStats] = useState({})

  useEffect(() => {
    const setInitialAudienceStats = async () => {
      await refreshAudienceStats(parameters)
    }
    // audience filter inital value
    setInitialAudienceStats()
  }, [])

  useEffect(() => {
    refreshAudienceStats(parameters)
  }, [audienceFilterType, parameters])

  useEffect(() => {
    if (audienceFilterType !== 'group') {
      if (parameters.length > 0) {
        setInputValue(parameters[0].value)
      }
    }
  }, [audienceFilterType, parameters])

  async function refreshAudienceStats (parameters) {
    try {
      const resp = await apiRequestUtils.post(`/api/companies/${id}/audience/stats`, { isGroup: audienceFilterType === 'group', userParameters: parameters })
      const audience = await resp.json()
      setAudienceStats(audience.users)
    } catch (err) {
      console.error(err)
    }
  }

  function generatePresetCustomization () {
    switch (audienceFilterType) {
      case 'group':
        return (
          <>
            <MDBRow className='mt-2'>
              <MDBCol>
                <MDBRow className='d-flex justify-content-center'>
                  This filter will send messages to every active user within the specified groups.
                </MDBRow>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mt-2'>
              <MDBCol>
                <MDBRow className='d-flex justify-content-center'>
                  <MDBDropdown dropright>
                    <MDBDropdownToggle id='group-audience-dropdown' aria-label='Group Audience Dropdown' caret color='primary'>
                      Groups
                    </MDBDropdownToggle>
                    <MDBDropdownMenu id='group-dropdown-list' basic>
                      {generateGroupDropdownItems(parameters, groups, setParameters)}
                    </MDBDropdownMenu>
                  </MDBDropdown>
                  {renderGroups(parameters, setParameters)}
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </>
        )
      case 'first':
        return (
          <>
            <section className='filterMessage'>
              This filter will send messages to every active user in the company.
            </section>
            <hr style={{ height: '1em' }} />
          </>
        )
      case 'supvemail':
        return (
          <>
            <section className=''>
              This filter will send messages to every active user under the specified supervisor matching on email address.
            </section>
            <input
              type='text'
              id='supervisor-email-text-field'
              label='Supervisor Email'
              value={inputValue}
              placeholder='Supervisor Email'
              className='audienceTextInput'
              maxLength={75}
              onChange={(e) => { handleChanges(e, e.target.value) }}
            />
          </>
        )
      case 'deptname':
        return (
          <>
            <section className=''>
              This filter will send messages to every active user within the specified department.
            </section>
            <input
              type='text'
              id='department-name-text-field'
              label='Department Name'
              value={inputValue}
              placeholder='Department Name'
              className='audienceTextInput'
              maxLength={75}
              onChange={(e) => { handleChanges(e, e.target.value) }}
            />
          </>
        )
    }
  }

  function handleChanges (e, value) {
    e.preventDefault()
    setInputValue(value)
    parameters[0].value = value
    setParameters([...parameters])
  }

  const selectDifferentAudienceFilter = (newAudienceFilterType) => {
    const parameters = []
    switch (newAudienceFilterType) {
      case 'group':
        setAudienceFilterType('group')
        setParameters(parameters)
        break
      case 'first':
        setAudienceFilterType('first')
        parameters.push({
          field: 'first',
          type: '!=',
          value: '~~~'
        })
        setParameters(parameters)
        break
      case 'supvemail':
        setAudienceFilterType('supvemail')
        parameters.push({
          field: 'supvemail',
          type: '==',
          value: ''
        })
        setParameters(parameters)
        break
      case 'deptname':
        setAudienceFilterType('deptname')
        parameters.push({
          field: 'deptname',
          type: '==',
          value: ''
        })
        setParameters(parameters)
        break
    }
  }

  const presetCustomizer = generatePresetCustomization()
  return (
    <div id='audienceComponent'>
      <div className='audienceFilter'>{/* start of audience filters  */}
        <p className='phinSubHeading'>Audience Filters</p>
        <section className='audienceOptionsGroup'>
          <section className='audienceFilterBtnGroup'>
            <div
              className={audienceFilterType === 'first' ? 'audienceFilterBtn filterBtnActive noSelect' : 'audienceFilterBtn filterBtnInactive noSelect'}
              id='full-company-audience-select'
              aria-label='Full Company Audience Select'
              onClick={() => selectDifferentAudienceFilter('first')}
            >
              <div
                className='iconTextCenter noSelect'
                aria-current='page'
              >
                <MDBIcon style={{ margin: '.2em' }} size='lg' icon='globe' /> Full <br /> Company
              </div>
            </div>
            <div
              className={audienceFilterType === 'deptname' ? 'audienceFilterBtn filterBtnActive noSelect' : 'audienceFilterBtn filterBtnInactive noSelect'}
              id='department-audience-select'
              aria-label='Department Audience Select'
              onClick={() => selectDifferentAudienceFilter('deptname')}
            >
              <div
                className='iconTextCenter noSelect'
                aria-current='page'
              >
                <MDBIcon style={{ margin: '.2em' }} size='lg' icon='address-book' />Department
              </div>
            </div>
            <div
              className={audienceFilterType === 'supvemail' ? 'audienceFilterBtn filterBtnActive noSelect' : 'audienceFilterBtn filterBtnInactive noSelect'}
              id='supervisor-audience-select'
              aria-label='Supervisor Audience Select'
              onClick={() => selectDifferentAudienceFilter('supvemail')}
            >
              <div
                className='iconTextCenter noSelect'
                aria-current='page'
              >
                <MDBIcon style={{ margin: '.2em' }} size='lg' icon='user' />Supervisor
              </div>
            </div>
            {groups && groups.length > 0 &&
              <div
                className={audienceFilterType === 'group' ? 'audienceFilterBtn filterBtnActive noSelect' : 'audienceFilterBtn filterBtnInactive noSelect'}
                id='group-audience-select'
                aria-label='Group Audience Select'
                onClick={() => selectDifferentAudienceFilter('group')}
              >
                <div
                  className='iconTextCenter noSelect'
                  aria-current='page'
                >
                  <MDBIcon style={{ margin: '.2em' }} size='lg' icon='users' />Groups
                </div>
              </div>}
          </section>

          {presetCustomizer}
        </section>
      </div>{/* end of filtering side  */}

      <div className='audienceStats'>{/* start of the audience stats  */}
        <p className='phinSubHeading'>Audience Size</p>
        <section className='audienceStatCenter'>
          <div className={audienceStats?.length === 0 ? 'red-text iconTextCenter' : 'iconTextCenter'}>
            <MDBIcon style={{ fontSize: '30px' }} icon='users' />
            <h5 style={{ fontSize: '20px' }} className='font-weight-bold'>{audienceStats?.length} Users</h5>
          </div>
        </section>
      </div>
    </div>
  )
}

export default AudienceFiltersV2
