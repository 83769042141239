import React from 'react'
import './Grade.css'

import { farthestCampaignendTime, getEndOfYearInSeconds } from './utils'
import { IoCheckmark } from 'react-icons/io5'
import { Tooltip, styled } from '@mui/material'
import { tooltipClasses } from '@mui/material/Tooltip'

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    fontSize: '14px',
    fontWeight: 300,
    textAlign: 'center'
  }
})

function Grade ({ activeCampaigns }) {
  function tooltipContent ({ gradeCode }) {
    // generate text for each tooltip and check off each criteria that has already been met
    const contents = [
      { text: 'Have campaigns set up for the rest of the year' },
      { text: 'Have at least one active campaign' }]
      .map((content, i) => {
        const header = i >= 1 ? <hr /> : ''
        return (
          <div key={i}>
            {header}
            {gradeCode <= i ? <><del>{content.text}</del> <IoCheckmark /></> : content.text}
          </div>
        )
      })
    return (
      contents
    )
  }

  function calculateGrade (activeCampaigns) {
    if (!activeCampaigns || activeCampaigns.length === 0) {
      return { gradeStr: 'C+', color: 'danger', gradeCode: 2 }
    } else if (activeCampaigns.length > 0 && (getEndOfYearInSeconds(new Date()) > farthestCampaignendTime(activeCampaigns))) {
      return { gradeStr: 'B+', color: 'warning', gradeCode: 1 }
    }
    return { gradeStr: 'A+', color: 'success', gradeCode: 0 }
  }

  const gradeObj = calculateGrade(activeCampaigns)

  return (
    <CustomWidthTooltip title={tooltipContent(gradeObj)}>
      <div className='nav-link'>
        {activeCampaigns &&
          <span className={`rounded-grade grade-border btn-outline-${gradeObj.color}`}>{gradeObj.gradeStr}</span>}
      </div>
    </CustomWidthTooltip>
  )
}

export default Grade
