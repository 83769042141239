import {
  MDBDropdownItem
} from 'mdb-react-ui-kit'
import { groupStatuses } from '../../../frontendConsts.js'
import { Chip } from '@mui/material'

export function removeGroupFromFilter (group, parameters, setSelectedGroups) {
  const newGroups = parameters.filter(existingGroup => existingGroup.id !== group.id)
  setSelectedGroups(newGroups)
}

function addGroupToFilter (group, selectedGroups, updateSelectedGroups) {
  const updatedGroups = [...selectedGroups]
  updatedGroups.push(group)
  updateSelectedGroups(updatedGroups)
}

export function generateGroupDropdownItems (selectedGroups, groups, updateSelectedGroups) {
  let sortedGroups = [...groups]
  sortedGroups = sortedGroups.filter((group) => {
    return group.status === groupStatuses.ACTIVE
  }).sort((a, b) => (a.displayName < b.displayName) ? -1 : 1)
  return sortedGroups.map(group => {
    return (
      <MDBDropdownItem
        disabled={selectedGroups.reduce((prev, curr) => curr.id === group.id || prev, false)}
        onClick={() => { addGroupToFilter(group, selectedGroups, updateSelectedGroups) }}
        key={group.id}
      >
        {group.displayName}
      </MDBDropdownItem>
    )
  })
}

export function renderGroups (selectedGroups, setSelectedGroups) {
  return selectedGroups.map(group => {
    return (
      <Chip key={group.id} label={group.displayName} onDelete={() => removeGroupFromFilter(group, selectedGroups, setSelectedGroups)} />
    )
  })
}
