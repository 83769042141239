import React from 'react'
import CampaignFrequencySelector from '../Campaigns/modules/CampaignFrequencySelector'
import { campaignTypes } from '../../../frontendConsts'

function ContinuousCampaignLauncher ({
  company, campaignFrequency, trainingCampaignFrequency,
  isCampaignFrequencyWeeksValid,
  setIsCampaignFrequencyWeeksValid,
  phishingStartTime, setPhishingStartTime,
  trainingStartTime, setTrainingStartTime,
  phishingAttemptWindowDays, setPhishingAttemptWindowDays,
  firstFireDatePhishing, setFirstFireDatePhishing,
  firstFireDateTraining, setFirstFireDateTraining,
  recurringScheduleErrorPhishing, setRecurringScheduleErrorPhishing,
  recurringScheduleErrorTraining, setRecurringScheduleErrorTraining
}) {
  const minDate = new Date()
  minDate.setDate(minDate.getDate() + 1)

  return (
    <div>
      <h2 className='phin-h2 d-flex justify-content-left padding-top:2 padding-bottom:2'>Scheduling and Campaign Launcher</h2>
      <CampaignFrequencySelector
        startTime={phishingStartTime}
        setStartTime={setPhishingStartTime}
        phishingAttemptWindowDays={phishingAttemptWindowDays}
        setPhishingAttemptWindowDays={setPhishingAttemptWindowDays}
        campaignFrequency={campaignFrequency}
        tz={company ? company.timezone.value : 'America/New_York'}
        campaignType={campaignTypes.PHISHING}
        isRunning={false}
        nextFireTimes={[]}
        firstFireDate={firstFireDatePhishing}
        setFirstFireDate={setFirstFireDatePhishing}
        nextFireTimesErrorMessage={null}
        isCampaignFrequencyWeeksValid={isCampaignFrequencyWeeksValid}
        setIsCampaignFrequencyWeeksValid={setIsCampaignFrequencyWeeksValid}
        recurringScheduleError={recurringScheduleErrorPhishing}
        setRecurringScheduleError={setRecurringScheduleErrorPhishing}
      />

      <br />

      <CampaignFrequencySelector
        startTime={trainingStartTime}
        setStartTime={setTrainingStartTime}
        campaignFrequency={trainingCampaignFrequency}
        tz={company ? company.timezone.value : 'America/New_York'}
        campaignType={campaignTypes.TRAINING}
        isRunning={false}
        nextFireTimes={[]}
        firstFireDate={firstFireDateTraining}
        setFirstFireDate={setFirstFireDateTraining}
        nextFireTimesErrorMessage={null}
        isCampaignFrequencyWeeksValid={isCampaignFrequencyWeeksValid}
        setIsCampaignFrequencyWeeksValid={setIsCampaignFrequencyWeeksValid}
        useTrainingSelector
        recurringScheduleError={recurringScheduleErrorTraining}
        setRecurringScheduleError={setRecurringScheduleErrorTraining}
      />
    </div>
  )
}

export default ContinuousCampaignLauncher
