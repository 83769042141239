import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  commitStagingThunk,
  disconnectUserSyncThunk,
  syncUsersThunk,
  updateIntegrationStatusThunk
} from '../../../store/UserSyncSlice'

import { Button, Tooltip } from '@mui/material'
import { IoArrowBack } from 'react-icons/io5'

import { integrationTypes, microsoftIntegrationStatuses } from '../../../frontendConsts.js'

import '../../css/integrationsDetail.css'
import { deleteStagedUsersAction } from '../../../store/StagedUsersSlice.js'
import { deleteStagedGroupsAction } from '../../../store/StagedGroupsSlice.js'
import { setUserCountAction, updateOnboardingChecklistDataThunk } from '../../../store/CompanySlice.js'
import { updateCompanyInPartnerAction } from '../../../store/PartnerSlice.js'
import PhinModal from '../PhinModal.js'

export function UserSyncActionButtons ({ companyId, stagedUsers }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const { users } = useSelector((state) => state.users)
  const { integrationsMap } = useSelector((state) => state.integrations)
  const { loaders: userSyncSliceLoaders } = useSelector((state) => state.userSync)
  const { loadingUserSyncStatus } = userSyncSliceLoaders
  const { loaders: stagedUsersLoaders } = useSelector((state) => state.stagedUsers)
  const { loadingStagedUsers } = stagedUsersLoaders
  const { loaders: stagedGroupsLoaders } = useSelector((state) => state.stagedGroups)
  const { loadingStagedGroups } = stagedGroupsLoaders

  const [disableButtons, setDisableButtons] = useState(false)
  const [disconnectModal, setDisconnectModal] = useState(false)

  async function calculateAndUpdateUserCounts () {
    // calculate the unique users in Phin right away to update the store
    const uniqueUsers = {}
    const allUsers = [...users, ...stagedUsers]

    for (const user of allUsers) {
      if (!uniqueUsers[user.email] && user.status === 'active') {
        uniqueUsers[user.email] = 1
      }
    }

    const totalDevices = Object.keys(uniqueUsers).length
    await dispatch(updateOnboardingChecklistDataThunk(companyId, { hasUsers: true }))
    await dispatch(updateCompanyInPartnerAction({ companyId, totalDevices }))
    await dispatch(setUserCountAction(totalDevices))
  }

  async function handleBackToTempStage () {
    if (disableButtons) {
      return
    }
    setDisableButtons(true)
    await dispatch(updateIntegrationStatusThunk({
      companyId,
      integrationId: integrationTypes.USER_SYNC,
      changeToIntegrationStatus: microsoftIntegrationStatuses.TEMP_STAGED
    }))
    await dispatch(deleteStagedGroupsAction())
    await dispatch(deleteStagedUsersAction())
    setDisableButtons(false)
  }

  async function handleDisconnect () {
    if (disableButtons) {
      return
    }
    setDisableButtons(true)
    const success = await dispatch(disconnectUserSyncThunk({ companyId, history }))

    if (success) {
      setDisconnectModal(false)
      setDisableButtons(false)
    }
  }

  async function handleSync () {
    if (disableButtons || integrationsMap.userSync.isSyncing) {
      return
    }
    setDisableButtons(true)
    await dispatch(syncUsersThunk({ companyId }))
    setDisableButtons(false)
  }

  async function handleCommit () {
    if (disableButtons || (integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.COMMITTING)) {
      return
    }

    setDisableButtons(true)
    await calculateAndUpdateUserCounts()
    dispatch(commitStagingThunk({ companyId }))
    setDisableButtons(false)
  }

  return (
    <>
      <PhinModal
        isOpen={disconnectModal}
        title='Disconnect'
        close={() => setDisconnectModal(false)}
        closeText='Cancel'
        action={handleDisconnect}
        actionText='Disconnect'
      >
        Are you sure you want to disconnect Azure User Sync? Users will no longer sync automatically.
      </PhinModal>

      <div className='actionBtnGroup'>
        {integrationsMap.userSync && integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.STAGED && (
          <Tooltip title='back' placement='top' arrow>
            <Button
              id='back-button'
              aria-label='Back Button'
              color='secondary'
              sx={{ mr: 3 }}
              variant='contained'
              onClick={handleBackToTempStage}
            ><IoArrowBack /> Back
            </Button>
          </Tooltip>
        )}
        <span className='padding-right:-1'>
          <Button
            id='disconnect-button'
            aria-label='Disconnect Button'
            color='error'
            size='large'
            onClick={() => setDisconnectModal(true)}
          > Disconnect
          </Button>
        </span>

        {integrationsMap.userSync && integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.CONNECTED && (
          <Button
            id='sync-button'
            aria-label='Sync Button'
            disabled={(integrationsMap.userSync.isSyncing || loadingUserSyncStatus)}
            color='primary'
            variant='contained'
            size='large'
            onClick={handleSync}
          >{(integrationsMap.userSync.isSyncing || loadingUserSyncStatus) ? 'Syncing' : 'Sync Now'}
          </Button>
        )}

        {integrationsMap.userSync && integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.STAGED && (
          <Tooltip title='commit' placement='top' arrow>
            <Button
              id='commit-button'
              aria-label='Sync Button'
              disabled={loadingUserSyncStatus || loadingStagedUsers || loadingStagedGroups}
              color='primary'
              variant='contained'
              onClick={handleCommit}
            >Commit
            </Button>
          </Tooltip>
        )}
      </div>
    </>
  )
}
