import { Button } from '@mui/material'
import { STYLE } from '../../frontendConsts'

export const SaveCancelButtonsRow = ({
  close,
  closeText,
  closeVariant = 'text',
  closeId,
  action,
  actionText,
  actionId,
  isSubmitting
}) => {
  return (
    <div className='buttonContainer'>
      <span className='padding-right:-1'>
        <Button
          size='large'
          variant={closeVariant === 'underlined' ? 'text' : closeVariant}
          style={closeVariant === 'underlined' ? STYLE.BUTTONS.TERTIARY : {}}
          id={closeId || 'cancel-staged-changes-button'}
          aria-label='Cancel Changes Button'
          className='resetContentsBtn noSelect'
          onClick={close}
          disabled={isSubmitting}
        >
          {closeText}
        </Button>
      </span>
      <Button
        size='large'
        variant='contained'
        id={actionId || 'save-staged-changes-button'}
        aria-label='Save Changes Button'
        className='saveContentsBtn noSelect'
        onClick={action}
        disabled={isSubmitting}
      >{actionText}
      </Button>
    </div>
  )
}
