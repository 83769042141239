import { Box, Button, Card, Grid, IconButton, OutlinedInput, Skeleton, styled } from '@mui/material'
import { useSelector } from 'react-redux'
import { IoBookOutline, IoCopyOutline } from 'react-icons/io5'
import NotificationUtilities from '../../components/notifications/notificationUtils'
import { STYLE } from '../../../frontendConsts'

const saveToClipBoard = (value) => {
  navigator.clipboard.writeText(value).then(() => {
    NotificationUtilities.sendSuccessMessage('Copied!')
  })
}

export function ReferralCodeWidget () {
  const { partner, loaders } = useSelector((state) => state.partner)
  const { isLoadingPartner } = loaders

  const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
    fieldset: {
      top: '-12px'
    }
  }))
  if (isLoadingPartner && !partner) {
    return (
      <Skeleton sx={{ bgcolor: '#c4c4c4', width: '100%', height: '100%' }} variant='rectangle' />
    )
  }

  return (
    <div className='referralCodeWidget'>
      <Card
        variant='outlined'
        sx={{
          border: 'solid 2px var(--light-gray)',
          borderRadius: '.5rem',
          minWidth: 200,
          minHeight: 180,
          padding: 'var(--phin-s2)'
        }}
      >
        <div className='phin-h4 margin-bottom:1'>Share Phin with a Friend and get up to $250!</div>

        <Box marginBottom='var(--phin-s2)'>
          <span> When a partner signs up with YOUR code, you'll each get a credit of up to $250. </span>
          <Button
            variant='text'
            style={STYLE.BUTTONS.TERTIARY}
            color='primary'
            size='small'
            href='https://www.phinsec.io/knowledge/partner-referral-codes'
            startIcon={<IoBookOutline />}
            target='_blank'
          > See referral program details here.
          </Button>
        </Box>
        <Grid
          container
          alignItems='center'
          direction='row'
        >
          <Grid item xs={10}>
            <StyledOutlinedInput
              fullWidth
              variant='outlined'
              disabled
              value={partner.referralCode}
            />
          </Grid>
          <Grid item>
            <IconButton
              sx={{
                borderRadius: '.5rem',
                border: 'solid 2px var(--phin-blue)',
                backgroundColor: 'var(--blue-25)',
                marginLeft: 'var(--phin-s0)'
              }}
              onClick={() => saveToClipBoard(partner.referralCode)}
            ><IoCopyOutline style={{ display: 'flex', stroke: 'var(--gray-100)', alignItems: 'center' }} size='1.2em' />
            </IconButton>
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}
