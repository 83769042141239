import { parseMustacheTemplate } from '../../utils/templateUtils'
import Mustache from 'mustache'
import PhinModal from './PhinModal'
const phishingEmailFieldMap = [
  {
    field: 'fromEmail',
    label: 'From'
  },
  {
    field: 'fromName',
    label: 'Display Name'
  },
  {
    field: 'subject',
    label: 'Subject Line'
  },
  {
    field: 'tags',
    label: 'Template Tag(s)'
  },
  {
    field: 'appeals',
    label: 'Template Appeal(s)'
  }
]

const generateStringFromArray = (arr) => {
  let returnString = ''

  arr.forEach((stringValue, index) => {
    if (index + 1 === arr.length) {
      returnString += stringValue
      return
    }
    returnString += `${stringValue}, `
  })
  return returnString
}

const renderTemplateDoc = (sendRecord) => {
  const templateDoc = sendRecord.templateDoc
  if (templateDoc === undefined) { return <strong>Template Not Found</strong> }

  const html = templateDoc.html
  const detectedFields = parseMustacheTemplate(html)

  if (detectedFields.length === 0) { return renderIframe(html) }
  const injectedFields = {}
  const fieldValues = detectedFields.map(fieldPair => fieldPair.name)

  /* setting this here to ask the question
    we have no "injectedFields" object on sendrecords, making
    us detect the injected fields every time and render accordingly
    we should consider adding injectedFields object to the sendRecord Document
  */
  fieldValues.forEach(detectedField => {
    if (sendRecord[detectedField] !== undefined) {
      injectedFields[detectedField] = sendRecord[detectedField]
    }
  })

  return (renderIframe(Mustache.render(html, injectedFields)))
}

const renderIframe = (html) => {
  if (html === undefined) { return <strong>Phishing email not found.</strong> }
  return (<iframe title='Viewer' style={{ borderColor: '#fff', boxShadow: 'none' }} width='100%' height='95%' srcDoc={String(html)} />)
}

const generatePhishingEmailFieldCard = (phishingEmail, selectedField) => {
  const fieldValue = selectedField.field
  const fieldDisplay = (Array.isArray(phishingEmail[fieldValue]) === true) ? generateStringFromArray(phishingEmail[fieldValue]) : phishingEmail[fieldValue]

  return (
    <div><strong>{selectedField.label}: </strong> {fieldDisplay} </div>
  )
}

const generatePhishingEmailFieldCards = (phishingEmail) => {
  const phishingCards = []

  phishingEmailFieldMap.forEach(potentialField => {
    if (phishingEmail[potentialField.field] === undefined) { return }
    if (Array.isArray(phishingEmail[potentialField.field]) && phishingEmail[potentialField.field].length === 0) { return }

    phishingCards.push(generatePhishingEmailFieldCard(phishingEmail, potentialField))
  })

  return (phishingCards)
}

const PhishingEmailViewer = ({ sendRecordInfo, isOpen, toggleViewerModal }) => {
  const phishingEmail = sendRecordInfo.templateDoc || ''

  const phishingEmailCards = generatePhishingEmailFieldCards(phishingEmail)

  return (
    <PhinModal
      key='phishingEmailViewer'
      title='Phishing Email View'
      size='large'
      isOpen={isOpen}
      close={() => toggleViewerModal(false)}
      noButtons
    >
      <div style={{ margin: '15px' }}>
        {phishingEmailCards.map((card, index) =>
          <div key={index}>
            {card}
          </div>
        )}
      </div>
      <div style={{ height: '50vh', width: '100%' }}>
        {renderTemplateDoc(sendRecordInfo)}
      </div>
    </PhinModal>
  )
}

export default PhishingEmailViewer
