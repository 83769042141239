import React, { useEffect, useState } from 'react'
import {
  MDBCol, MDBRow
} from 'mdb-react-ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../Spinner'
import { setMonthlyReportSendTimeThunk, setSendMonthlyReportsThunk, setSendWeeklyReportsThunk, setWeeklyReportSendTimeThunk } from '../../../store/CompanySlice'
import { createReportViewerThunk, deleteReportViewerThunk, getReportViewersThunk, updateReportViewerThunk } from '../../../store/ReportViewersSlice'
import ValidationUtils from '../../../utils/validationUtils'
import NotificationUtilities from '../notifications/notificationUtils'
import { TextField, Grid, Switch, Button, IconButton } from '@mui/material'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { setCompanyReportSettingsThunk } from '../../../store/ReportingSettingsSlice'
import '../../css/reportSettings.scss'
import { IoPencil, IoTrash } from 'react-icons/io5'
import PhinModal from '../PhinModal'
import { DateTime } from 'luxon'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'

const ReportSettings = ({
  companyId
}) => {
  const dispatch = useDispatch()
  const { company, loaders } = useSelector((state) => state.company)
  const {
    monthlyReportHistoricalDataEnabled,
    monthlyReportUsersToWatchEnabled,
    weeklyReportHistoricalDataEnabled,
    weeklyReportUsersToWatchEnabled,
    weeklyPhishingClicksCSVEnabled,
    weeklyReportUsersToWatchCSVEnabled,
    monthlyPhishingClicksCSVEnabled,
    weeklyTrainingDataCSVEnabled,
    monthlyTrainingDataCSVEnabled,
    monthlyPhishingPDFReportEnabled,
    monthlyReportUsersToWatchCSVEnabled
  } = useSelector(state => state.reportingSettings)
  const { isLoadingCompany } = loaders
  const { reportViewers, loaders: reportViewerLoaders } = useSelector((state) => state.reportViewers)
  const { isLoadingReportViewers, isLoadingUpdateReportViewer } = reportViewerLoaders
  const [newReportViewerEmail, setNewReportViewerEmail] = useState()
  const [editableReportViewerEmail, setEditableReportViewerEmail] = useState()
  const [editableReportviewerId, setEditableReportviewerId] = useState()
  const [showEditModal, setShowEditModal] = useState(false)
  const [monthlyReportSendTime, setMonthlyReportSendTime] = useState(DateTime.now().set({ hour: 8, minute: 0, seconds: 0 }))
  const [weeklyReportSendTime, setWeeklyReportSendTime] = useState(DateTime.now().set({ hour: 8, minute: 0, seconds: 0 }))
  const [confirmDeleteReportViewerOpen, setConfirmDeleteReportViewerOpen] = useState(false)
  const [reportViewerToDelete, setReportViewerToDelete] = useState(null)

  const openConfirmDeleteReportViewer = (reportViewer) => {
    setReportViewerToDelete(reportViewer)
    setConfirmDeleteReportViewerOpen(true)
  }

  const closeConfirmDeleteReportViewer = () => {
    setReportViewerToDelete(null)
    setConfirmDeleteReportViewerOpen(false)
  }

  const confirmDeleteReportViewer = async () => {
    const success = await dispatch(deleteReportViewerThunk({ companyId, id: reportViewerToDelete.id }))

    if (success) {
      setReportViewerToDelete(null)
      setConfirmDeleteReportViewerOpen(false)
    }
  }

  useEffect(() => {
    dispatch(getReportViewersThunk({ companyId }))
  }, [])

  useEffect(() => {
    if (company && company.monthlyReportSendTime) {
      const timeArray = company.monthlyReportSendTime.split(':')
      setMonthlyReportSendTime(DateTime.now().set({ hour: timeArray[0], minute: timeArray[1], seconds: 0 }))
    }

    if (company && company.weeklyReportSendTime) {
      const timeArray = company.weeklyReportSendTime.split(':')
      setWeeklyReportSendTime(DateTime.now().set({ hour: timeArray[0], minute: timeArray[1], seconds: 0 }))
    }
  }, [company])

  const toggleShowEditModal = (editableReportviewer) => {
    if (editableReportviewer) {
      setEditableReportviewerId(editableReportviewer.id)
      setEditableReportViewerEmail(editableReportviewer.reportViewerEmail)
      setShowEditModal(true)
    } else {
      setEditableReportviewerId(null)
      setEditableReportViewerEmail(null)
      setShowEditModal(false)
    }
  }

  const updateMonthlyReportSendTime = async (value) => {
    if (value.isValid) {
      let minutesString = '' + value.minute

      if (minutesString.length === 1) {
        minutesString = '0' + minutesString
      }

      const timeString = `${value.hour}:${minutesString}`

      await dispatch(setMonthlyReportSendTimeThunk({ companyId, timeString }))
    }
  }

  const updateWeeklyReportSendTime = async (value) => {
    if (value.isValid) {
      let minutesString = '' + value.minute

      if (minutesString.length === 1) {
        minutesString = '0' + minutesString
      }

      const timeString = `${value.hour}:${minutesString}`
      await dispatch(setWeeklyReportSendTimeThunk({ companyId, timeString }))
    }
  }

  const generateReportViewerList = () => {
    const reportViewersList = []

    if (reportViewers && reportViewers.length > 0) {
      reportViewers.forEach((reportViewer, idx) => {
        reportViewersList.push(
          <MDBRow key={idx} className='padding-left:2 d-flex justify-content-start align-items-center'>
            <span className='align-self-center'>{reportViewer.reportViewerEmail}</span>

            <div className='phin-flex-end'>
              <div className='padding-left:-1 padding-right:-1'>
                <IconButton
                  id={`edit-report-viewer-${reportViewer.reportViewerEmail}-button`}
                  aria-label={`Edit Report Viewer ${reportViewer.reportViewerEmail} Button`}
                  onClick={() => toggleShowEditModal(reportViewer)}
                  rounded
                  className='align-items-center'
                  type='submit'
                  size='large'
                  color='primary'
                  variant='contained'
                >
                  <IoPencil />
                </IconButton>
              </div>
              <IconButton
                id={`delete-report-viewer-${reportViewer.reportViewerEmail}-button`}
                aria-label={`Delete Report Viewer ${reportViewer.reportViewerEmail} Button`}
                onClick={() => openConfirmDeleteReportViewer(reportViewer)}
                rounded
                className='align-items-center'
                type='submit'
                size='large'
                variant='contained'
                color='error'
              >
                <IoTrash />
              </IconButton>
            </div>
          </MDBRow>
        )
      })
    } else {
      reportViewersList.push(
        <h5 key='1'>No Report Viewers</h5>
      )
    }

    return reportViewersList
  }

  const createReportViewer = async () => {
    if (newReportViewerEmail && newReportViewerEmail !== '') {
      if (ValidationUtils.isValidEmail(newReportViewerEmail)) {
        const succeeded = await dispatch(createReportViewerThunk({ companyId, reportViewerEmail: newReportViewerEmail }))

        if (succeeded) {
          setNewReportViewerEmail('')
        }
      } else {
        NotificationUtilities.sendWarningMessage('Please enter a valid email address.')
      }
    }
  }

  const updateReportViewer = async () => {
    if (editableReportViewerEmail && editableReportViewerEmail !== '') {
      if (ValidationUtils.isValidEmail(editableReportViewerEmail)) {
        const succeeded = await dispatch(updateReportViewerThunk({ companyId, id: editableReportviewerId, reportViewerEmail: editableReportViewerEmail }))

        if (succeeded) {
          toggleShowEditModal()
        }
      } else {
        NotificationUtilities.sendWarningMessage('Please enter a valid email address.')
      }
    }
  }

  return (
    <>
      <PhinModal
        isOpen={confirmDeleteReportViewerOpen}
        title='Delete Report Viewer'
        close={closeConfirmDeleteReportViewer}
        action={confirmDeleteReportViewer}
        actionText='Delete'
        actionColor='error'
      >Are you sure you would like to delete this Report Viewer?
      </PhinModal>

      <PhinModal
        isOpen={showEditModal}
        title='Edit Report Viewer'
        close={() => toggleShowEditModal()}
        action={updateReportViewer}
        actionText='Save'
      >
        {isLoadingUpdateReportViewer && (
          <Spinner />
        )}
        {!isLoadingUpdateReportViewer && (
          <div className='padding-top:1'>
            <TextField
              style={{ width: '50ch' }}
              id='email-address-field'
              variant='outlined'
              label='Email Address'
              name='EmailAddress'
              value={editableReportViewerEmail || ''}
              onChange={(event) => {
                setEditableReportViewerEmail(event.target.value)
              }}
              sx={{ marginBottom: 'var(--phin-s2)' }}
            />
          </div>
        )}
      </PhinModal>

      <Grid sx={{ mt: 6 }}>
        <div style={{ alignSelf: 'start' }}>
          <h4 className='phin-h4 margin-bottom:-1' style={{ textAlign: 'left' }}>Settings</h4>
        </div>
        <Grid>
          <div cascade className='phin-border padding:2'>
            {(!company || isLoadingCompany ||
              !reportViewers || isLoadingReportViewers) && (
                <Spinner />
            )}
            {(company && !isLoadingCompany &&
              reportViewers && !isLoadingReportViewers) && (

                <>
                  <h4>Report Viewers</h4>
                  <div className='margin-bottom:2'>Add email addresses to send a weekly or monthly report for this company containing: Phishing, Training, and performance related details.</div>
                  {generateReportViewerList()}

                  <div className='padding-bottom:2' />

                  <div>
                    <MDBRow className='padding-left:0 d-flex justify-content-start align-items-start'>
                      <MDBCol size='8'>
                        <TextField
                          id='report-viewer-email-input-field'
                          aria-label='Report Viewer Email Input Field'
                          fullWidth
                          value={newReportViewerEmail}
                          onChange={(e) => setNewReportViewerEmail(e.target.value)}
                          label='Email Address'
                        />
                      </MDBCol>
                      <Button
                        id='add-report-viewer-button'
                        aria-label='Add Report Viewer Button'
                        variant='contained'
                        size='large'
                        onClick={() => createReportViewer()}
                      >
                        Add
                      </Button>
                    </MDBRow>
                  </div>
                  <MDBRow className='pt-5 d-flex justify-content-between pb-3'>
                    <div className='phin-h4'>Weekly Reports</div>
                    <Grid item xs={6}>
                      <Switch
                        id='toggleSendWeeklyReports' checked={company.sendWeeklyReports} onClick={() => { dispatch(setSendWeeklyReportsThunk({ companyId, value: !company.sendWeeklyReports })) }}
                      />
                    </Grid>
                  </MDBRow>
                  <MDBRow className='d-flex justify-content-between align-items-center pb-3'>
                    {company.sendWeeklyReports && (
                      <>
                        <p>Send Time On Mondays</p>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                          <TimePicker
                            label='Weekly Send Time'
                            value={weeklyReportSendTime}
                            onChange={(newValue) => {
                              updateWeeklyReportSendTime(newValue)
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </>
                    )}
                  </MDBRow>
                  {company.sendWeeklyReports &&
                    (
                      <>
                        <Grid item xs={6} className='phin-flex-space-between'>
                          <>Include "Historical Data" Table</>
                          <Switch
                            id='toggleWeeklyReportHistoricalDataTable' checked={weeklyReportHistoricalDataEnabled} onClick={(e) => { dispatch(setCompanyReportSettingsThunk({ companyId, updateOpts: { weeklyReportHistoricalDataEnabled: !weeklyReportHistoricalDataEnabled } })) }}
                          />
                        </Grid>
                        <Grid item xs={6} className='phin-flex-space-between'>
                          <>Include "Users To Watch" Table</>
                          <Switch
                            id='toggleWeeklyReportUsersToWatchTable' checked={weeklyReportUsersToWatchEnabled} onClick={() => { dispatch(setCompanyReportSettingsThunk({ companyId, updateOpts: { weeklyReportUsersToWatchEnabled: !weeklyReportUsersToWatchEnabled } })) }}
                          />
                        </Grid>
                        <Grid item xs={6} className='phin-flex-space-between'>
                          <>Include CSV output of "Users To Watch" Table</>
                          <Switch
                            id='toggleWeeklyTrainingDataCSVEnabledUsersToWatch' checked={weeklyReportUsersToWatchCSVEnabled} onClick={() => { dispatch(setCompanyReportSettingsThunk({ companyId, updateOpts: { weeklyReportUsersToWatchCSVEnabled: !weeklyReportUsersToWatchCSVEnabled } })) }}
                          />
                        </Grid>
                        <Grid item xs={6} className='phin-flex-space-between'>
                          <>Include CSV output of raw phishing data</>
                          <Switch
                            id='toggleWeeklyPhishingClicksCSVEnabledRawPhishing' checked={weeklyPhishingClicksCSVEnabled} onClick={() => { dispatch(setCompanyReportSettingsThunk({ companyId, updateOpts: { weeklyPhishingClicksCSVEnabled: !weeklyPhishingClicksCSVEnabled } })) }}
                          />
                        </Grid>
                        <Grid item xs={6} className='phin-flex-space-between'>
                          <>Include CSV output of training data</>
                          <Switch
                            id='toggleWeeklyTrainingDataCSVEnabledTrainingData' checked={weeklyTrainingDataCSVEnabled} onClick={() => { dispatch(setCompanyReportSettingsThunk({ companyId, updateOpts: { weeklyTrainingDataCSVEnabled: !weeklyTrainingDataCSVEnabled } })) }}
                          />
                        </Grid>
                      </>
                    )}
                  <br />
                  <hr />
                  <MDBRow className='pt-5 d-flex justify-content-between pb-3'>
                    <div className='phin-h4'>Monthly Reports</div>
                    <Grid item xs={6}>
                      <Switch
                        id='toggleSendMonthlyReports' checked={company.sendMonthlyReports} onClick={() => { dispatch(setSendMonthlyReportsThunk({ companyId, value: !company.sendMonthlyReports })) }}
                      />
                    </Grid>
                  </MDBRow>
                  <MDBRow className='d-flex justify-content-between align-items-center pb-3'>
                    {company.sendMonthlyReports && (
                      <>
                        <p>Send Time On The 1st</p>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                          <TimePicker
                            label='Monthly Send Time'
                            value={monthlyReportSendTime}
                            onChange={(newValue) => {
                              updateMonthlyReportSendTime(newValue)
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </>
                    )}
                  </MDBRow>
                  {company.sendMonthlyReports &&
                    (
                      <>
                        <Grid item xs={6} className='phin-flex-space-between'>
                          <>Include "Historical Data" Table</>
                          <Switch
                            id='toggleMonthlyReportHistoricalDataTable' checked={monthlyReportHistoricalDataEnabled} onClick={() => { dispatch(setCompanyReportSettingsThunk({ companyId, updateOpts: { monthlyReportHistoricalDataEnabled: !monthlyReportHistoricalDataEnabled } })) }}
                          />
                        </Grid>

                        <Grid item xs={6} className='phin-flex-space-between'>
                          <>Include "Users To Watch" Table</>
                          <Switch
                            id='toggleMonthlyReportUsersToWatchTable' checked={monthlyReportUsersToWatchEnabled} onClick={() => { dispatch(setCompanyReportSettingsThunk({ companyId, updateOpts: { monthlyReportUsersToWatchEnabled: !monthlyReportUsersToWatchEnabled } })) }}
                          />
                        </Grid>

                        <Grid item xs={6} className='phin-flex-space-between'>
                          <>Include CSV output of "Users To Watch" Table</>
                          <Switch
                            id='toggleMonthlyTrainingDataCSVEnabledUsersToWatch' checked={monthlyReportUsersToWatchCSVEnabled} onClick={() => { dispatch(setCompanyReportSettingsThunk({ companyId, updateOpts: { monthlyReportUsersToWatchCSVEnabled: !monthlyReportUsersToWatchCSVEnabled } })) }}
                          />
                        </Grid>

                        <Grid item xs={6} className='phin-flex-space-between'>
                          <>Include CSV output of raw phishing data</>
                          <Switch
                            id='toggleMonthlyPhishingClicksCSVEnabledRawPhishing' checked={monthlyPhishingClicksCSVEnabled} onClick={() => { dispatch(setCompanyReportSettingsThunk({ companyId, updateOpts: { monthlyPhishingClicksCSVEnabled: !monthlyPhishingClicksCSVEnabled } })) }}
                          />
                        </Grid>

                        <Grid item xs={6} className='phin-flex-space-between'>
                          <>Include CSV output of training data</>
                          <Switch
                            id='toggleMonthlyTrainingDataCSVEnabledTrainingData' checked={monthlyTrainingDataCSVEnabled} onClick={() => { dispatch(setCompanyReportSettingsThunk({ companyId, updateOpts: { monthlyTrainingDataCSVEnabled: !monthlyTrainingDataCSVEnabled } })) }}
                          />
                        </Grid>

                        <Grid item xs={6} className='phin-flex-space-between'>
                          <>Include Phishing PDF Report</>
                          <Switch
                            id='toggleMonthlyPhishingPDFReportEnabled' checked={monthlyPhishingPDFReportEnabled} onClick={() => { dispatch(setCompanyReportSettingsThunk({ companyId, updateOpts: { monthlyPhishingPDFReportEnabled: !monthlyPhishingPDFReportEnabled } })) }}
                          />
                        </Grid>
                      </>
                    )}
                </>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default ReportSettings
