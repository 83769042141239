import { Button } from '@mui/material'
export function AllowlistPermissions ({ actionToPerform, handler }) {
  return (
    <div className='consentCard' style={{ height: 'auto' }}>
      <div className='consentTitle'>Microsoft Permissions</div>
      <div className='permissions'>
        <p className='consentSubtitle'>What are the permissions?</p>
        <ul>
          <li>
            Exchange Adminstrator role
            <ul>
              <li>
                This is a Microsoft Entra Role, not an API permission. Phin will assign this role to our Microsoft Azure AD application. This is required for running the allowlist.
              </li>
            </ul>
          </li>
        </ul>
        <ul>
          <li>
            RoleManagement.ReadWrite.Directory
            <ul>
              <li>
                Allows Phin to assign the Exchange Administrator role to our Microsoft Azure AD application. This is required for running the allowlist.
              </li>
            </ul>
          </li>
        </ul>
        <ul>
          <li>
            Exchange.ManageAsApp
            <ul>
              <li>
                Allows the app to send mail as any user without a signed-in user.
                Used to forward reported phishing emails to the configured email or Microsoft's reporting service
              </li>
            </ul>
          </li>
        </ul>
        <p className='consentSubtitle'>How will Phin use the permissions?</p>
        <ul>
          <li>
            Phin will use the above listed permissions to automatically add our domains and IPs to the tenant's allowlist.
          </li>
        </ul>
      </div>
      <div className='d-flex justify-content-center'>

        {actionToPerform === 'connect' && (
          <button
            id='continue-to-microsoft-button'
            aria-label='Continue to Microsoft Button'
            className='consentBtn'
            onClick={() => handler()}
          >
            Continue to Microsoft
          </button>
        )}

        {actionToPerform === 'disconnect' && (
          <Button
            id='continue-to-microsoft-button'
            aria-label='Continue to Microsoft Button'
            color='error'
            sx={{ mr: 3 }}
            variant='contained'
            onClick={() => handler()}
          >
            Disconnect
          </Button>
        )}
      </div>
    </div>
  )
}
