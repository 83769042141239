import React, { Component } from 'react'
import NotificationUtilities from '../notifications/notificationUtils'
import ValidationUtils from '../../../utils/validationUtils'
import { Alert, Box, FormControl, InputLabel, MenuItem, Select, Typography, TextField, Grid } from '@mui/material'
import PhinModal from '../PhinModal'

class AdminCreator extends Component {
  constructor (props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      adminPermission: 'standard'
    }
  }

  async handleCreation () {
    NotificationUtilities.sendInfoMessage('Creating your new admin now...')

    const firstName = this.state.firstName.trim()
    const lastName = this.state.lastName.trim()

    const email = this.state.email.trim().toLowerCase()
    const adminPermission = this.state.adminPermission

    // Run validation
    if (!ValidationUtils.isNotWhiteSpace(firstName)) {
      NotificationUtilities.sendWarningMessage('You must have a first name for the admin')
      return
    }

    if (!ValidationUtils.isNotWhiteSpace(lastName)) {
      NotificationUtilities.sendWarningMessage('You must have a last name for the admin')
      return
    }

    if (!ValidationUtils.isValidEmail(email)) {
      NotificationUtilities.sendWarningMessage('You must have a valid email for the admin')
      return
    }

    if (!adminPermission) {
      NotificationUtilities.sendWarningMessage('you must select a billing permission for the admin')
      return
    }

    if (adminPermission === 'full-access' && !this.props.isFullAccessAdmin) {
      NotificationUtilities.sendWarningMessage('You cannot grant full access to an admin if you do not have full access permission, contact your main admin for support')
      return
    }

    await this.props.createAdmin(firstName, lastName, email, adminPermission)
  }

  render () {
    return (
      <PhinModal
        isOpen={this.props.isOpen}
        size='medium'
        title='Admin Creator'
        close={this.props.closeModal}
        closeText='Cancel'
        action={() => this.handleCreation()}
        actionText='Create'
      >
        <h6>Fill in the information for the new admin below. These admins will have full access to partner clients.</h6>
        <Grid container direction='column' p={1} rowGap={2}>
          <TextField
            onChange={(e) => {
              this.setState({ firstName: e.target.value })
            }}
            name='newUName'
            label='First Name'
            id='first-name-field'
            aria-label='First Name Field'
          />
          <TextField
            onChange={(e) => {
              this.setState({ lastName: e.target.value })
            }}
            name='newUName'
            label='Last Name'
            id='last-name-field'
            aria-label='Last Name Field'
          />
          <TextField
            onChange={(e) => {
              this.setState({ email: e.target.value })
            }}
            name='newUEmail'
            label='Email'
            id='email-field'
            aria-label='Email Field'
          />
        </Grid>
        <Box mt='1rem'>
          <Typography m='1rem'>Select permission levels for this Admin</Typography>
          <Alert severity='info' sx={{ marginBottom: '1rem' }}>Full Access grants Partner admins additional permission to view & edit billing information in our Billing page and to configure Partner admin permissions under Administrators.</Alert>
          <FormControl sx={{ m: 1, minWidth: 80 }} size='medium'>
            <InputLabel id='demo-simple-select-autowidth-label'>Permission</InputLabel>
            <Select
              labelId='demo-simple-select-autowidth-label'
              id='demo-simple-select-autowidth'
              value={this.state.adminPermission}
              onChange={(event) => {
                this.setState({ adminPermission: event.target.value })
              }}
              label='Permissions'
            >
              <MenuItem value='full-access'>Full Access</MenuItem>
              <MenuItem value='standard'>Standard</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </PhinModal>
    )
  }
}

export default AdminCreator
