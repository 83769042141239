import React, { useEffect, useRef, useState } from 'react'
import Spinner from '../Spinner'
import NotificationUtilities from '../notifications/notificationUtils'
import { apiRequestUtils } from '../../../utils/apiRequestUtils'
import { useDispatch, useSelector } from 'react-redux'
import { microsoftIntegrationStatuses, integrationStatuses, integrationTypes } from '../../../frontendConsts.js'

import { getUserSyncSettingsThunk } from '../../../store/UserSyncSlice'
import { getStagedUsersByCompanyThunk } from '../../../store/StagedUsersSlice'
import { getStagedGroupsByCompanyThunk } from '../../../store/StagedGroupsSlice'
import { getTempStagedGroupsByCompanyThunk } from '../../../store/TempStagedGroupsSlice'

import { integrationStatusUpdatedAction, integrationUpdatedAction } from '../../../store/IntegrationsSlice'
import { StagedUserSync } from './StagedUserSync'
import { useHistory } from 'react-router-dom'
import { SyncedUsers } from './SyncedUsers'
import { SyncSummary } from './SyncSummary'
import '../../css/integrationsDetail.css'
import '../../css/integrationCard.css'
import '../../css/integrationSyncSummary.css'
import { MicrosoftConsentCard } from './MicrosoftConsentCard'
import { getGroupsThunk, getUsersThunk } from '../../../store/UsersSlice'
import { StageGroups } from './StageGroups.js'
import { Alert, Grid } from '@mui/material'
import { StatusBreadCrumbs } from './StatusBreadCrumbs.js'

export function MicrosoftUserSyncPage ({ id }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const intervalRef = useRef()

  const [isAzureGovTenant, setIsAzureGovTenant] = useState(false)

  const { userSyncSettings, loaders: userSyncLoaders } = useSelector((state) => state.userSync)
  const { tempStagedGroups, loaders: tempStagedGroupsLoaders } = useSelector((state) => state.tempStagedGroups)
  const loaders = { ...tempStagedGroupsLoaders, ...userSyncLoaders }
  const { loadedUserSyncSettings, loadingTempStagedGroups } = loaders

  const { stagedUsers } = useSelector((state) => state.stagedUsers)
  const { stagedGroups } = useSelector((state) => state.stagedGroups)

  const { company } = useSelector((state) => state.company)
  const { integrationsMap } = useSelector((state) => state.integrations)

  function toggleIsAzureGovTenant () {
    setIsAzureGovTenant(!isAzureGovTenant)
  }

  const cleanUpInterval = () => {
    clearInterval(intervalRef.current)
  }

  const getBreadCrumbSteps = () => {
    const steps = { ...microsoftIntegrationStatuses }

    delete steps.CONSENTED
    delete steps.TEMP_STAGING
    delete steps.TEMP_STAGED
    delete steps.COMMITTING

    return Object.keys(steps).map((key) => steps[key])
  }

  const getBreadCrumbStep = (status) => {
    if (!status) return 0 // Show not connected if no integration exists
    const steps = getBreadCrumbSteps()

    if (status === microsoftIntegrationStatuses.TEMP_STAGING || status === microsoftIntegrationStatuses.TEMP_STAGED) {
      return 2 // Compatability for both temp_staging and temp_staged
    }
    return Object.keys(steps).findIndex((key) => steps[key] === status)
  }

  const consentToIntegration = () => {
    const redirectURL = encodeURIComponent(`${window.location.protocol}//${window.location.host}/integrations`)
    let adminConsentLink = 'https://login.microsoftonline.com/common/adminconsent'
    let integrationType = 'userSync'
    let applicationId = process.env.REACT_APP_MICROSOFT_USER_SYNC_APPLICATION_ID

    if (isAzureGovTenant) {
      adminConsentLink = 'https://login.microsoftonline.us/common/adminconsent'
      integrationType = 'userSync%7Ctrue'
      applicationId = process.env.REACT_APP_AZURE_GOV_USER_SYNC_APPLICATION_ID
    }
    window.open(`${adminConsentLink}?client_id=${applicationId}&redirect_uri=${redirectURL}&state=${id}%7C${integrationType}`, '_self')
  }

  const validateConsent = () => {
    apiRequestUtils.put(`/api/companies/${id}/integrations/microsoft/validateConsent`, { integrationId: integrationTypes.USER_SYNC }).then(async (response) => {
      if (response.status === 200) {
        const data = await response.json()
        if (data.integrationStatus === microsoftIntegrationStatuses.CONSENTED) {
          dispatch(integrationUpdatedAction({ integrationId: integrationTypes.USER_SYNC, updatedFields: { integrationStatus: data.integrationStatus } }))
        } else {
          clearInterval(intervalRef.current)
          intervalRef.current = setInterval(() => {
            apiRequestUtils.put(`/api/companies/${id}/integrations/microsoft/validateConsent`, { integrationId: integrationTypes.USER_SYNC }).then(async (response) => {
              if (response.status === 200) {
                const data = await response.json()
                if (data.integrationStatus === microsoftIntegrationStatuses.CONSENTED) {
                  clearInterval(intervalRef.current)
                  dispatch(integrationUpdatedAction({ integrationId: integrationTypes.USER_SYNC, updatedFields: { integrationStatus: data.integrationStatus } }))
                }
              }
            })
          }, 10000)
        }
      } else {
        clearInterval(intervalRef.current)
        intervalRef.current = setInterval(() => {
          apiRequestUtils.put(`/api/companies/${id}/integrations/microsoft/validateConsent`, { integrationId: integrationTypes.USER_SYNC }).then(async (response) => {
            if (response.status === 200) {
              const data = await response.json()
              if (data.integrationStatus === microsoftIntegrationStatuses.CONSENTED) {
                clearInterval(intervalRef.current)
                dispatch(integrationUpdatedAction({ integrationId: integrationTypes.USER_SYNC, updatedFields: { integrationStatus: data.integrationStatus } }))
              }
            }
          })
        }, 10000)
      }
    })
  }

  const pollForIntegrationStatus = async (statusToPollFor) => {
    const response = await apiRequestUtils.get(`/api/companies/${id}/integrations/userSync`)
    if (response.status === 200) {
      const integration = await response.json()
      if (integration.integrationStatus === statusToPollFor) {
        dispatch(integrationUpdatedAction({ integrationId: integration.id, updatedFields: integration }))
        if (statusToPollFor === integrationStatuses.CONNECTED) {
          dispatch(getUsersThunk(id))
          dispatch(getGroupsThunk(id))
        }
      } else {
        clearInterval(intervalRef.current)
        intervalRef.current = setInterval(async () => {
          const response = await apiRequestUtils.get(`/api/companies/${id}/integrations/userSync`)
          if (response.status === 200) {
            const integration = await response.json()
            if (integration.integrationStatus === statusToPollFor) {
              clearInterval(intervalRef.current)
              dispatch(integrationUpdatedAction({ integrationId: integration.id, updatedFields: integration }))
              if (statusToPollFor === integrationStatuses.CONNECTED) {
                dispatch(getUsersThunk(id))
                dispatch(getGroupsThunk(id))
              }
            }
          } else {
            clearInterval(intervalRef.current)
            setTimeout(() => {
              NotificationUtilities.sendErrorMessage('Failed to load User Sync data. Please try again or contact Phin Support for assistance.')
            }, 0)
          }
        }, 10000)
      }
    } else {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to load User Sync data. Please try again or contact Phin Support for assistance.')
      }, 0)
      history.push(`/companies/${id}/integrations`)
    }
  }

  useEffect(() => {
    if (company) {
      dispatch(getUserSyncSettingsThunk(company.id))
    }
  }, [company])

  useEffect(() => {
    return cleanUpInterval
  }, [])

  useEffect(() => {
    clearInterval(intervalRef.current)

    if (integrationsMap && integrationsMap.userSync) {
      if (integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.CONSENT_SUBMITTED) {
        validateConsent()
        return cleanUpInterval
      } else if (integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.CONSENTED) {
        pollForIntegrationStatus(microsoftIntegrationStatuses.TEMP_STAGED)
        return cleanUpInterval
      } else if (integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.TEMP_STAGED) {
        if (!tempStagedGroups) {
          dispatch(getTempStagedGroupsByCompanyThunk({ companyId: id }))
        }
      } else if (integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.STAGING) {
        pollForIntegrationStatus(microsoftIntegrationStatuses.STAGED)
        return cleanUpInterval
      } else if (integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.STAGED) {
        if (!stagedUsers) {
          dispatch(getStagedUsersByCompanyThunk({ companyId: id }))
        }
        if (!stagedGroups) {
          dispatch(getStagedGroupsByCompanyThunk({ companyId: id }))
        }
      } else if (integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.COMMITTING) {
        pollForIntegrationStatus(microsoftIntegrationStatuses.CONNECTED)
        return cleanUpInterval
      }
    }
  }, [integrationsMap])

  return (
    <>
      <div>
        <div className='detailsPageHeadingGroup'>
          <img src='/azureMed.png' />
          <div>
            <div className='detailsTitleGroup'>
              <h2 className='phin-page-heading'>Azure User Sync</h2>
            </div>
            {company && integrationsMap && (
              <StatusBreadCrumbs
                steps={getBreadCrumbSteps()}
                step={getBreadCrumbStep(integrationsMap.userSync?.integrationStatus)}
              />
            )}
          </div>
        </div>

        {(!company || !integrationsMap) && (
          <Spinner />
        )}

        {(company && integrationsMap) && (
          <>
            {(!integrationsMap.userSync || integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.NOT_CONNECTED) && (
              <>
                <div className='details-single'>
                  <MicrosoftConsentCard
                    consentFunction={consentToIntegration}
                    isAzureGovTenant={isAzureGovTenant}
                    toggleIsAzureGovTenant={toggleIsAzureGovTenant}
                  />
                </div>
              </>)}

            {integrationsMap.userSync && integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.CONSENT_SUBMITTED &&
              <div className='details'>
                <Spinner message='Verifying Microsoft Consent Status' secondaryMessage='This may take a few minutes' />
              </div>}

            {integrationsMap.userSync && (integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.CONSENTED) && (
              <div className='details'>
                <Spinner message='Fetching Group List' secondaryMessage='This may take a few minutes' />
              </div>
            )}

            {integrationsMap.userSync && (integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.TEMP_STAGING) && (
              <div className='details'>
                <Spinner message='Staging User Data' secondaryMessage='This may take a few minutes' />
              </div>
            )}

            {integrationsMap.userSync && loadingTempStagedGroups && (integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.TEMP_STAGED) && (
              <div className='details'>
                <Spinner message='Fetching Group Data' />
              </div>
            )}

            {(loadedUserSyncSettings && !loadingTempStagedGroups && integrationsMap.userSync && (integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.TEMP_STAGED)) && (
              <div className='d-flex flex-column'>
                <StageGroups
                  companyId={id}
                  userSyncSettings={userSyncSettings}
                  tempStagedGroups={tempStagedGroups || []}
                  integrationStatusUpdatedAction={integrationStatusUpdatedAction}
                  integrationId={integrationTypes.USER_SYNC}
                  microsoftIntegrationStatuses={microsoftIntegrationStatuses}
                />
              </div>
            )}

            {integrationsMap.userSync && (integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.STAGING) && (
              <div className='details'>
                <Spinner message='Preparing to Stage Data' />
              </div>
            )}

            {integrationsMap.userSync && integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.STAGED && (
              <>
                {stagedUsers && stagedUsers.length === 0 && (
                  <div className='detailsPageHeadingGroup'>
                    <Grid item xl={12} sx={{ flexGrow: 1, mt: 1, mb: 1 }}>
                      <Alert severity='warning'>
                        You have zero valid users across your selected groups. Please check the users licenses and mailboxes in your Azure tenant. You may use the "back" button to stage a different set of groups.
                      </Alert>
                    </Grid>
                  </div>
                )}
                <div className='details'>
                  <SyncSummary
                    stagedGroups={stagedGroups}
                    stagedUsers={stagedUsers}
                  />
                  <StagedUserSync
                    companyId={id}
                    haveConsented
                    isStagingMode
                  />
                </div>
              </>
            )}

            {integrationsMap.userSync && integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.COMMITTING && (
              <div className='details'>
                <Spinner message='Commiting First Sync' />
              </div>
            )}

            {integrationsMap.userSync && integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.CONNECTED && (
              <div className='details'>
                <SyncSummary />
                <SyncedUsers
                  companyId={id}
                  integration={integrationsMap.userSync}
                  users={[]}
                />
              </div>
            )}

          </>)}

      </div>
    </>
  )
}
