import React, { useState, useEffect } from 'react'
import { Grid, Button, Skeleton, Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, TextField, Stack, IconButton } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { IoAdd, IoCreateSharp, IoTrash } from 'react-icons/io5'
import { useSelector, useDispatch } from 'react-redux'

import AdminCreator from './components/partnersPage/adminCreator'
import { createAdminThunk, deleteAdminThunk, editAdminThunk } from '../store/PartnerSlice'

import CompaniesTable from './components/companies/CompaniesTable'
import PhinModal from './components/PhinModal'

export const PartnersPage = ({ id }) => {
  const { partner, loaders } = useSelector((state) => state.partner)
  const { authorization } = useSelector((state) => state.auth)

  const isFullAccessAdmin = authorization.fullAccessAdminPartnerIds.includes(id)

  const {
    isLoadingPartner
  } = loaders
  const dispatch = useDispatch()

  const [adminCreatorModal, setAdminCreatorModal] = useState(false)
  const [adminToDeleteUid, setAdminToDeleteUid] = useState('')
  const [adminToEdit, setAdminToEdit] = useState({})
  const [deleteAdminConfirm, setDeleteAdminConfirm] = useState(false)
  const [openEditAdminModal, setOpenEditAdminModal] = useState(false)
  const [tablePageSize, setTablePageSize] = useState(10)
  const [adminTableRows, setAdminTableRows] = useState([])

  useEffect(() => {
    if (partner) {
      const partnerAdminRows = []
      partner.admins.forEach((admin) => {
        partnerAdminRows.push({
          ...admin
        })
      })
      setAdminTableRows(partnerAdminRows)
    }
  }, [partner])

  const adminTableColumns = [
    {
      headerName: 'Name',
      field: 'name',
      flex: 1
    },
    {
      headerName: 'Email Address',
      field: 'email',
      flex: 1
    }, {
      headerName: 'Admin Level',
      field: 'adminLevels',
      renderCell: (params) => generateAdminCell(params.row.adminLevels),
      width: 200,
      sortable: false,
      filterable: false
    },
    {
      headerName: 'Actions',
      field: 'uid',
      renderCell: (params) => generateActions(params.id, params.row.email, params.row.adminLevels, params.row.name),
      width: 200,
      sortable: false,
      filterable: false
    }
  ]

  const generateAdminCell = (permissionLevel) => {
    const textString = permissionLevel === 'full-access' ? 'Full Access' : 'Standard'
    return (
      textString
    )
  }

  const generateActions = (id, email, permissionLevel, name) => {
    const firstName = name.split(' ')[0]
    const lastName = name.split(' ')[1]
    return (
      <Box>
        <IconButton color='error' onClick={() => { setAdminToDeleteUid(id); setDeleteAdminConfirm(true) }}>
          <IoTrash id={`delete-${email}`} />
        </IconButton>
        <IconButton onClick={() => { setAdminToEdit({ id, permissionLevel, firstName, lastName }); setOpenEditAdminModal(true) }}>
          <IoCreateSharp id={`edit-${email}`} style={{ color: '#1976d2' }} />
        </IconButton>
      </Box>
    )
  }

  const onChangeEditInput = (e) => {
    setAdminToEdit({ ...adminToEdit, [e.target.name]: e.target.value })
  }

  const createAdmin = async (adminFirst, adminLast, adminEmail, adminPermission) => {
    const success = await dispatch(createAdminThunk({
      adminEmail,
      adminFirst,
      adminLast,
      id,
      partnerName: partner.name,
      adminPermission
    }))

    if (success) {
      setAdminCreatorModal(false)
    }
  }

  const deleteAdmin = async (uid) => {
    /**
     * uid -> The auth0 id of the admin
     * id -> The partner id of the partner to delete the admin from
     */
    const result = await dispatch(deleteAdminThunk({ id, uid, distributorId: partner?.distributorId }))

    if (result) {
      setDeleteAdminConfirm(false)
    }
  }

  const editAdmin = async (adminToEdit) => {
    await dispatch(editAdminThunk({ partnerId: id, editedFields: adminToEdit }))

    setOpenEditAdminModal(false)
  }

  return (
    <div>
      {/* Admin Creator Modal */}
      <AdminCreator
        isOpen={adminCreatorModal}
        isFullAccessAdmin={isFullAccessAdmin}
        closeModal={() => { setAdminCreatorModal(false) }}
        createAdmin={createAdmin}
      />

      <PhinModal
        isOpen={deleteAdminConfirm}
        title='Delete Admin'
        close={() => setDeleteAdminConfirm(!deleteAdminConfirm)}
        action={() => deleteAdmin(adminToDeleteUid)}
        actionColor='error'
        actionText='Delete'
      >
        <p>Are you sure you want to delete this admin? <strong>This can not be undone!</strong></p>
      </PhinModal>

      <PhinModal
        isOpen={openEditAdminModal}
        size='medium'
        title='Edit Admin'
        close={() => setOpenEditAdminModal(!openEditAdminModal)}
        action={() => editAdmin(adminToEdit)}
        actionText='Save'
      >
        <Box>
          <Stack>
            <TextField sx={{ margin: '1rem 0' }} name='firstName' size='small' label='First Name' variant='outlined' defaultValue={adminToEdit.firstName} onChange={onChangeEditInput} />
            <TextField sx={{ marginBottom: '1rem' }} name='lastName' size='small' label='Last Name' variant='outlined' defaultValue={adminToEdit.lastName} onChange={onChangeEditInput} />
            {/* add back in with edit email feature <TextField sx={{ marginBottom: '1rem' }} name='email' size='small' label='Email' variant='outlined' defaultValue={adminToEdit.email} onChange={onChangeEditInput} /> */}
          </Stack>
          <FormControl>
            <FormLabel id='demo-radio-buttons-group-label'>Access Level</FormLabel>
            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              defaultValue={adminToEdit.permissionLevel}
              name='radio-buttons-group'
              onChange={(e) => { setAdminToEdit({ ...adminToEdit, permissionLevel: e.target.value }) }}
            >
              <FormControlLabel value='full-access' control={<Radio />} label='Full Access' />
              <FormControlLabel value='standard' control={<Radio />} label='Standard' />
            </RadioGroup>
          </FormControl>
        </Box>
      </PhinModal>

      <Box sx={{ marginBottom: 4 }}>
        <h1 style={{ fontWeight: 500 }}>Partner Home</h1>
      </Box>

      <div className='main-container-centered'>

        <CompaniesTable />

        <Grid container sx={{ paddingBottom: '1em' }}>

          {(isLoadingPartner || !partner) && (
            <Skeleton sx={{ bgcolor: '#c4c4c4' }} variant='rectangle' width={1110} height={400} />
          )}

          {(!isLoadingPartner && partner) && (
            <>
              <Grid container sx={{ mb: 1 }}>
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'Left' }}>
                  <h4 className='phin-h4' style={{ textAlign: 'left' }}>Administrators</h4>
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                  <span>These are admins who can access all companies in your partner.</span>
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    variant='contained'
                    startIcon={<IoAdd />}
                    id='create-partner-admin-button'
                    style={{ height: '3em' }}
                    disabled={!partner || isLoadingPartner}
                    onClick={() => {
                      setAdminCreatorModal(true)
                    }}
                  >
                    ADD AN ADMIN
                  </Button>
                </Grid>
              </Grid>

              <div style={{ width: '100%' }}>
                <DataGrid
                  getRowId={(row) => row.uid}
                  style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
                  className='DataGrid'
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'name', sort: 'asc' }]
                    }
                  }}
                  autoHeight
                  rows={adminTableRows}
                  columns={adminTableColumns}
                  pageSize={tablePageSize}
                  onPageSizeChange={(newSize) => setTablePageSize(newSize)}
                  rowsPerPageOptions={[10, 25, 50]}
                  components={{ Toolbar: GridToolbar }}
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500, id: 'partner-admin-table-search', 'aria-label': 'Search' },
                      csvOptions: { disableToolbarButton: true },
                      printOptions: { disableToolbarButton: true }
                    }
                  }}
                  disableSelectionOnClick
                  disableColumnSelector
                  disableDensitySelector
                  disableColumnFilter
                  disableColumnMenu
                />
              </div>
            </>
          )}
        </Grid>

      </div>
    </div>
  )
}

export default PartnersPage
