import { createSlice } from '@reduxjs/toolkit'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'

export const messagesSlice = createSlice({
  name: 'messages',
  initialState: {
    loaders: {
      isLoadingMessages: false
    },
    failedMessages: null,
    outgoingMessages: null
  },
  reducers: {
    messagesLoadingToggled: (state, action) => {
      state.loaders.isLoadingMessages = action.payload
    },
    failedMessagesLoaded: (state, action) => {
      state.failedMessages = action.payload
    },
    outgoingMessagesLoaded: (state, action) => {
      state.outgoingMessages = action.payload
    }
  }
})

export const {
  messagesLoadingToggled: messagesLoadingToggledAction,
  failedMessagesLoaded: failedMessagesLoadedAction,
  outgoingMessagesLoaded: outgoingMessagesLoadedAction
} = messagesSlice.actions

export default messagesSlice.reducer

export const getMessagesThunk = (id) => {
  return async (dispatch, getState, api) => {
    dispatch(messagesLoadingToggledAction(true))
    try {
      let res = await api.get(`/api/companies/${id}/failedMessages`)
      if (res.status === 200) {
        const { logs } = await res.json()

        dispatch(failedMessagesLoadedAction(logs))
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to load company failed messages. Please try again or contact Phin suport if the issue persists.')
        })
      }
      res = await api.get(`/api/companies/${id}/outgoingMessages`)
      if (res.status === 200) {
        const { outgoingMessages } = await res.json()

        dispatch(outgoingMessagesLoadedAction(outgoingMessages))
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to load company outgoing messages. Please try again or contact Phin suport if the issue persists.')
        })
      }
    } catch (err) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to load company messages. Please try again or contact Phin suport if the issue persists.')
      })
    }
    dispatch(messagesLoadingToggledAction(false))
  }
}
