import { Button } from '@mui/material'
import { STYLE } from '../../frontendConsts'

export function StepperButtonTray ({ cancel, cancelText = 'Cancel', noCancel = false, cancelVariant = 'underlined', action, actionText = 'Next' }) {
  const getCancelButtonVariants = () => {
    if (cancelVariant === 'underlined') {
      return 'text'
    } else if (cancelVariant === 'contained') {
      return 'contained'
    } else if (cancelVariant === 'outlined') {
      return 'outlined'
    } else {
      return 'text'
    }
  }

  return (
    <div className='padding-top:0 phin-flex-end phin-width-100'>
      {!noCancel &&
        <Button
          id='stepper-cancel-button'
          style={cancelVariant === 'underlined'
            ? STYLE.BUTTONS.TERTIARY
            : {}}
          variant={getCancelButtonVariants()}
          aria-label='Cancel Button'
          onClick={cancel}
          color='primary'
          sx={{ marginRight: 'var(--phin-s-1)' }}
          size='large'
        >{cancelText}
        </Button>}
      <Button
        id='stepper-next-button'
        aria-label='Next Button'
        variant='contained'
        onClick={action}
        size='large'
      > {actionText}
      </Button>
    </div>
  )
}
