import React, { useState } from 'react'
import { TimezoneSelect } from './TimezoneSelect'
import { MDBRow, MDBCol } from 'mdb-react-ui-kit'
import { Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { PhinCard } from '../PhinCard'

const GeneralSettings = ({
  selectedTimezone,
  handleTimezoneChange,
  leaderboardURLGenerator,
  saveGeneralSettings,
  ipIgnoreListEnabled
}) => {
  const [ipIgnoreListEnabledState, setIpIgnoreListEnabledState] = useState(ipIgnoreListEnabled)

  const handleChange = (event) => {
    setIpIgnoreListEnabledState(event.target.checked)
  }
  return (
    <div>

      <MDBCol xl='12' md='12' className='mb-5'>
        <PhinCard title='General Settings'>
          <div className='admin-up'>
            <div className='data' />
          </div>
          <MDBRow className='my-2 d-flex'>
            <MDBCol size='12'>
              <TimezoneSelect className='browser-default custom-select' selectedTimezone={selectedTimezone} onChange={handleTimezoneChange} />
            </MDBCol>
          </MDBRow>

          {leaderboardURLGenerator}

          <FormGroup className='margin-left:0 margin-top:2 margin-bottom:2'>
            <FormControlLabel
              control={
                <Checkbox
                  checked={ipIgnoreListEnabledState}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  color='warning'
                />
}
              label={(<div className='phin-h5'>Ignore Third Party Service IP Addresses</div>)}
            />
            <p className='padding-left:2'>Enabling this feature will help prevent third party services such as Microsoft Defender from producing false positive phishing results. Visit our <a href='https://www.phinsec.io/knowledge/ignore-third-party-service-ip-addresses' target='_blank' rel='noreferrer'>Knowledge Base</a> to learn more.</p>
          </FormGroup>

          <div className='phin-flex-end'>
            <Button id='save-company-settings-changes-button' aria-label='Save Company Settings Changes Button' tag='a' variant='contained' title='Save Changes' onClick={() => saveGeneralSettings(ipIgnoreListEnabledState)}>
              Save Changes
            </Button>
          </div>
        </PhinCard>
      </MDBCol>
    </div>
  )
}

export default GeneralSettings
