import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { cancelFreeTrialThunk } from '../../../store/PartnerBillingSlice'
import { useAuth0 } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom'

export function CancelFreeTrialModal ({ open, closeModal, isDeleteAcknowledged, setIsDeleteAcknowledged }) {
  const { logout } = useAuth0()
  const dispatch = useDispatch()
  const history = useHistory()
  const { partner } = useSelector((state) => state.partner)

  const { loaders } = useSelector((state) => state.partnerBilling)
  const { isLoadingCancelFreeTrial } = loaders

  async function cancelFreeTrial () {
    const { success, logOutCurrentAdmin } = await dispatch(cancelFreeTrialThunk({ partnerId: partner.id }))

    if (success) {
      if (logOutCurrentAdmin) {
        logout({ returnTo: 'https://www.phinsec.io/free-trial-cancel' })
      } else {
        closeModal()
        history.push(`/distributors/${partner.distributorId}`)
      }
    } else {
      setIsDeleteAcknowledged(false)
    }
  }

  return (
    <>

      <Dialog
        sx={{ '& .MuiDialog-paper': { maxWidth: '30vw', maxHeight: '100vh', padding: 'var(--phin-s1)' } }}
        maxWidth='xs'
        open={open}
        onClose={closeModal}
      >
        <DialogTitle>
          <div style={{ display: 'flex', justifyContent: 'start' }} className='phin-h4'>
            Cancel Trial
          </div>
        </DialogTitle>
        <DialogContent>
          <p className='phin-body-text'>By selecting 'Cancel Trial', your access to Phin will be removed and your account data will be deleted.</p>
          <FormGroup sx={{ padding: 'var(--phin-s0)' }}>
            <FormControlLabel checked={isDeleteAcknowledged} required control={<Checkbox onChange={() => setIsDeleteAcknowledged(!isDeleteAcknowledged)} />} label='I acknowledge that I no longer need access to Phin' />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button
            id='cancel-operation-button'
            aria-label='Cancel Operation'
            onClick={closeModal}
            disableElevation
            disabled={isLoadingCancelFreeTrial}
          >Cancel
          </Button>
          <Button
            id='cancel-free-trial-button'
            aria-label='Cancel Free trial Button'
            color='error'
            disableElevation
            variant='contained'
            onClick={() => cancelFreeTrial()}
            disabled={isLoadingCancelFreeTrial || !isDeleteAcknowledged}
          >Cancel Trial
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
