import React from 'react'
import {
  MDBCol, MDBRow, MDBCard, MDBIcon, MDBCardBody

} from 'mdb-react-ui-kit'
import moment from 'moment'

import '../../../css/hud.css'
import { campaignTypes } from '../../../../frontendConsts.js'
import { Tooltip } from '@mui/material'

const createHUDStats = ({ campaignHUDdata, disabled }) => {
  // Return the disabled version before trying to render the stats
  if (disabled) {
    return (
      <MDBRow className='mb-4 d-flex justify-content-center'>
        <MDBCol xl='12' md='12' className='mb-5'>
          <MDBCard cascade className='cascading-admin-card hud-stats disabled-hud'>
            <div className='admin-up'>
              <div className='data'>
                <h4><strong>Campaign Stats</strong></h4>
              </div>
            </div>
            <MDBRow className='text-center d-flex justify-content-center my-4'>
              <h5><strong>Select one of the active campaigns below to view additional stats here</strong></h5>
            </MDBRow>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    )
  }

  const percentageComplete = Math.floor(campaignHUDdata.percentageComplete * 100)
  const stats = [
    { icon: 'bullhorn', name: 'Total Sends', stat: campaignHUDdata.totalSends, tooltipText: 'The total number of emails sent in this campaign' },
    { icon: 'users', name: 'Users Reached', stat: campaignHUDdata.usersReached, tooltipText: 'The number of unique individuals that have received at least 1 email from this campaign ' },
    { icon: 'fish', name: 'Total Clicks', stat: campaignHUDdata.clicked, tooltipText: 'The number of times a test has been failed in this campaign' }]

  if (campaignHUDdata.campaignType !== campaignTypes.CONTINUOUS) {
    stats.push(
      { icon: 'check-circle', name: 'Completion', stat: `${percentageComplete > 100 ? 100 : percentageComplete}%`, tooltipText: 'The percentage of the phishing campaign that has completed' })
  }

  const createStatBlock = ({ icon, name, stat, tooltipText }, index) => {
    return (
      <Tooltip
        placement='top'
        id={`${icon}-${name}`}
        title={tooltipText}
      >
        <MDBCol key={index}>
          <p><MDBIcon icon={icon} />  {name}</p>
          <h4 className='font-weight-bold dark-grey-text'>{stat}</h4>
        </MDBCol>
      </Tooltip>
    )
  }

  const createEndTime = (campaignHUDdata) => {
    if (campaignHUDdata.campaignType === campaignTypes.CONTINUOUS) {
      return (
        <p className='text-center card-text'><MDBIcon icon='clock' /> Campaign will run continuously </p>
      )
    } else {
      return (
        <p className='text-center card-text'><MDBIcon icon='clock' /> Campaign ends <strong>{moment(campaignHUDdata.endTime).format('MMMM DD, YYYY')}</strong></p>
      )
    }
  }

  return (
    <MDBRow className='mb-4 d-flex justify-content-center'>
      <MDBCol xl='12' md='12' className='mb-5'>
        <MDBCard cascade className='cascading-admin-card hud-stats'>
          <div className='admin-up'>
            <div className='data'>
              <h4><strong>{campaignHUDdata.name}</strong></h4>
            </div>
          </div>
          <MDBRow className='text-center d-flex justify-content-center my-4'>
            {stats.map((statObj, index) => createStatBlock(statObj, index))}
          </MDBRow>
          <MDBRow>
            <MDBCardBody cascade>
              {createEndTime(campaignHUDdata)}
            </MDBCardBody>
          </MDBRow>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  )
}

export default createHUDStats
