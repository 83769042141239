import apiSlice from './apiSlice'

export const allowlistApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    startAllowlist: builder.mutation({
      query: ({ companyId }) => ({
        url: `/companies/${companyId}/allowlist`,
        body: {},
        method: 'POST'
      }),
      async onQueryStarted ({ companyId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          allowlistApi.util.updateQueryData('getAllowListProcessingData', { companyId }, (draft) => {
            draft.processing = true
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    }),
    getAllowListProcessingData: builder.query({
      query: (companyId) => `/companies/${companyId}/allowlist`
    })
  })
})

export const {
  useStartAllowlistMutation,
  useGetAllowListProcessingDataQuery
} = allowlistApi
