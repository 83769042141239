import './fixedAudienceSelector.scss'
import { FiDelete } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { DataGrid, useGridApiContext, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid'
import Box from '@mui/material/Box'
import { IoAddCircleOutline } from 'react-icons/io5'
import { Button } from '@mui/material'

const CustomToolbar = ({ fixedAudienceList, setFixedAudienceList, initialFixedAudienceList, isEditOnboarding }) => {
  const apiRef = useGridApiContext()

  const checkAllUsers = () => {
    const visibleUsers = apiRef.current?.getVisibleRowModels() || []
    const visibleUsersArr = Array.from(visibleUsers, ([name, value]) => ({ ...value }))

    setFixedAudienceList([...visibleUsersArr, ...fixedAudienceList])
  }

  const uncheckAllUsers = () => {
    if (isEditOnboarding && fixedAudienceList?.length) {
      setFixedAudienceList(initialFixedAudienceList)
    } else {
      setFixedAudienceList([])
    }
  }

  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <Button id='Fixed-Audience-Select-All-Button' variant='contained' size='small' sx={{ marginRight: '.5rem', marginLeft: '.5rem' }} onClick={checkAllUsers}>Select All</Button>
      <Button
        id='Fixed-Audience-Deselect-All'
        variant='contained'
        color='error'
        size='small'
        onClick={uncheckAllUsers}
        disabled={!fixedAudienceList?.length || (isEditOnboarding && fixedAudienceList.length === initialFixedAudienceList.length)}
      >Deselect All
      </Button>
    </GridToolbarContainer>
  )
}

export const FixedAudienceSelector = ({
  users, fixedAudienceList, setFixedAudienceList,
  isEditOnboarding, initialFixedAudienceList, verticalView = false
}) => {
  const { usersMap } = useSelector((state) => state.users)
  const validUsersList = fixedAudienceList.map((user) => {
    const userData = usersMap[user.id]
    if (userData) {
      return userData
    } else {
      return null
    }
  }).filter((nonNullUser) => nonNullUser)

  const columns = [
    { field: 'add', headerName: '', renderCell: (params) => <IoAddCircleOutline aria-label={`Add ${params.row.email} Button`} id={`add-${params.row.email}-button`} onClick={() => { toggleCheckedUser(params.row) }} className='add-icon' />, width: 0, sortable: false, filterable: false },
    { field: 'last', headerName: 'Last name', flex: 1 },
    { field: 'first', headerName: 'First name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 2 },
    {
      field: 'supvemail',
      headerName: 'Supervisor Email',
      type: 'singleSelect',
      valueOptions: users.map(({ supvemail }) => supvemail).filter((email, index, array) => array.indexOf(email) === index && email !== undefined)
    },
    {
      field: 'deptname',
      headerName: 'Department Name',
      type: 'singleSelect',
      valueOptions: users.map(({ deptname }) => deptname).filter((name, index, array) => array.indexOf(name) === index && name !== undefined)
    }
    // TODO: add conditional unseen singleSelect column for filtering by groups
  ]

  const toggleCheckedUser = (user) => {
    const checkedUserIds = fixedAudienceList.map((u) => u.id)
    if (checkedUserIds.includes(user.id)) {
      setFixedAudienceList(fixedAudienceList.filter((u) => u.id !== user.id))
    } else {
      setFixedAudienceList([...fixedAudienceList, user])
    }
  }

  return (
    <div className='fixed-audience-selector-container'>
      <div className='left-column' style={{ minWidth: verticalView ? '500px' : '264px' }}>
        <Box style={{ width: '100%' }}>
          <DataGrid
            autoHeight
            rows={users.filter(user => !fixedAudienceList?.some(audienceMember => audienceMember.id === user.id)) || []}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableColumnSelector
            disableDensitySelector
            disableExport
            disableColumnMenu
            components={{
              Toolbar: CustomToolbar
            }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
                fixedAudienceList,
                setFixedAudienceList,
                initialFixedAudienceList,
                isEditOnboarding
              }
            }}
            columnVisibilityModel={{
              supvemail: false,
              deptname: false
            }}
          />
        </Box>
      </div>
      <div className='right-column'>
        <Box className='checked-users-title'>
          <p>{`Selected Users: ${validUsersList.length}`} </p>
        </Box>
        <div className='checked-users-container'>
          {!validUsersList.length && <p className='no-select-msg'>No Users Selected</p>}
          {validUsersList.map((userData) => {
            const disabled = isEditOnboarding && initialFixedAudienceList.filter(function (user) { return user.id === userData.id }).length > 0
            return (
              <div key={userData.id} className={`checked-user-row user-row-${userData.id}`}>
                <div className='user-fullname' style={{ opacity: disabled ? '.5' : '1' }}>
                  <p>{userData.first} {userData.last}</p>
                  <p>{userData.email}</p>
                </div>
                <FiDelete style={{ opacity: disabled ? '.2' : '1', pointerEvents: disabled ? 'none' : 'auto' }} onClick={() => toggleCheckedUser(userData)} className='delete-user' />
              </div>
            )
          }
          )}
        </div>
      </div>
    </div>
  )
}
