import React from 'react'
import { Fab, Grid, Skeleton, Tooltip } from '@mui/material'
import { IoBusiness, IoCloudDownload } from 'react-icons/io5'

const SlideUpDataExportButtons = ({ exporting, downloadOrgData, downloadCampaignData }) => {
  let exportButtonContent
  if (exporting) {
    exportButtonContent = (
      <>
        <Skeleton variant='circular' width={56} height={56} sx={{ marginBottom: 'var(--phin-s2)' }} />
        <Skeleton variant='circular' width={56} height={56} />
      </>
    )
  } else {
    exportButtonContent = (

      <Grid container direction='column'>
        <Tooltip
          title={<span className='phin-body-text'>Download All Company Data</span>} placement='top' slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -12]
                  }
                }
              ]
            }
          }}
        >
          <Fab color='primary' aria-label='add' onClick={downloadOrgData} sx={{ marginBottom: 'var(--phin-s2)' }}>
            <IoBusiness size='2em' />
          </Fab>
        </Tooltip>
        <Tooltip
          title={<span className='phin-body-text'>Download Course Data</span>} placement='top' slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -12]
                  }
                }
              ]
            }
          }}
        >
          <Fab color='primary' aria-label='add' onClick={downloadCampaignData}>
            <IoCloudDownload size='2em' />
          </Fab>
        </Tooltip>
      </Grid>
    )
  }
  return (
    <div className='mb-5 mr-5 phin-fixed-bottom-right'>
      {exportButtonContent}
    </div>
  )
}

export default SlideUpDataExportButtons
