import apiSlice from './apiSlice'

export const reportedEmailsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPartnerReportedEmails: builder.query({
      query: (partnerId) => `/partners/${partnerId}/triaging/reported-emails`,
      providesTags: ['ReportedEmails']
    }),
    getPartnerReportedEmailById: builder.query({
      query: ({ partnerId, messageId }) => `/partners/${partnerId}/triaging/reported-emails/${messageId}`,
      providesTags: ['ReportedEmails']
    }),
    getSimilarEmailsByFilter: builder.query({
      query: ({ partnerId, messageId, filterBy }) => `/partners/${partnerId}/triaging/${messageId}/similarEmails/${filterBy}`
    }),
    getDeletedEmailsByReportedEmail: builder.query({
      query: ({ partnerId, messageId }) => `/partners/${partnerId}/triaging/${messageId}/deleted-emails`
    }),
    resolveReportedEmail: builder.mutation({
      query: ({ partnerId, reportedEmailId, disposition, tags }) => ({
        url: `/partners/${partnerId}/triaging/reported-emails/${reportedEmailId}/resolve`,
        method: 'POST',
        body: { disposition, tags }
      }),
      invalidatesTags: ['ReportedEmails']
    }),
    deleteEmailsFromSender: builder.mutation({
      query: ({ partnerId, senderAddress, filterBy, reportedEmailId }) => ({
        url: `/partners/${partnerId}/triaging/reported-emails/deleteSimilar/${filterBy}`,
        method: 'POST',
        body: { senderAddress, reportedEmailId }
      }),
      invalidatesTags: ['ReportedEmails']
    })
  })
})

export const {
  useGetPartnerReportedEmailsQuery,
  useGetPartnerReportedEmailByIdQuery,
  useLazyGetSimilarEmailsByFilterQuery,
  useGetDeletedEmailsByReportedEmailQuery,
  useResolveReportedEmailMutation,
  useDeleteEmailsFromSenderMutation
} = reportedEmailsApi
