import PresetCard from './PresetCard'
import { Grid } from '@mui/material'

export default function PresetLibrary ({
  campaignPresets, launchCampaignPreset,
  editCallback = undefined, deleteCallback = undefined
}) {
  return (
    <Grid container spacing={4}>
      {campaignPresets.map((cardInfo, i) => (
        <Grid
          xl={2} lg={3} md={4} sm={6} xs={12}
          item
          key={cardInfo.id}
        >
          <PresetCard
            cardInfo={cardInfo}
            launchCampaignPreset={launchCampaignPreset}
            editCallback={editCallback}
            deleteCallback={deleteCallback}
          />
        </Grid>
      ))}
    </Grid>
  )
}
