import React from 'react'
import '../../css/integrationSyncSummary.css'

export function StatusBreadCrumbs ({ steps, step }) {
  return (
    <div className='crumbContainer'>
      {steps.map((stepLabel, index) => {
        return (
          <span key={index}>
            <div className={(index === step) ? 'statusCrumb activeCrumb' : 'statusCrumb'}>{stepLabel}</div>
            {index !== (steps.length - 1) && <hr className='crumbHR' style={{ display: 'inline-block' }} />}
          </span>
        )
      })}
    </div>
  )
}
