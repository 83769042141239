import './curriculumCourseSelect.scss'
import { CurriculumCard } from './CurriculumCard'

function renderCurriculums (curriuculumCatalog, setModules, setCurriculumDescription, curriculumId, setCurriculumId) {
  const map = curriuculumCatalog.map(curriculum => <CurriculumCard key={curriculum.id} curriculum={curriculum} setModules={setModules} setCurriculumDescription={setCurriculumDescription} curriculumId={curriculumId} setCurriculumId={setCurriculumId} />)
  return map
}

function renderTopics (trainingTopics) {
  const renderArray = []
  trainingTopics.forEach((trainingTopic) => {
    renderArray.push(<div className='p-5'><h5>{trainingTopic}</h5></div>)
  })

  return renderArray
}

function CurriculumCourseSelect ({ curriculumCatalog, isContinuous, trainingTopics, lock, setModules, setCurriculumDescription, curriculumId, setCurriculumId }) {
  return (
    <div className='prebuilt-curriculum-container' style={(lock) ? { pointerEvents: 'none', opacity: '0.6' } : {}}>
      {isContinuous &&
        <div className='d-flex justify-content-space-around'>
          {renderTopics(trainingTopics)}
        </div>}
      {!isContinuous && renderCurriculums(curriculumCatalog, setModules, setCurriculumDescription, curriculumId, setCurriculumId)}
    </div>
  )
}
export default CurriculumCourseSelect
