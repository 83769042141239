import React from 'react'
import PhinModal from '../PhinModal'

function SMSPreviewModal ({ template, isOpen, toggleModal }) {
  return (
    <PhinModal
      isOpen={isOpen}
      title='Preview SMS'
      close={toggleModal}
      closeText='close'
      noButtons
    >
      {template.message}
    </PhinModal>
  )
}

export default SMSPreviewModal
