import React, { useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import Spinner from './components/Spinner'
import { Button } from '@mui/material'
import { IoCloseCircle, IoPauseCircle, IoPlayCircle } from 'react-icons/io5'
import { cancelPartnerSuspensionThunk, reactivatePartnerThunk, schedulePartnerSuspensionThunk } from '../store/PartnerSlice'
import PhinModal from './components/PhinModal'
import { checkIsDistributorAdmin } from '../utils/roleManager'
import { DateTime } from 'luxon'

function PartnerSettingsPage ({ id, props }) {
  const dispatch = useDispatch()
  const { partner, loaders } = useSelector(state => state.partner)
  const { isLoadingPartner } = loaders
  const { authorization } = useSelector((state) => state.auth)
  const [reactivatePartnerModal, setReactivatePartnerModal] = useState(false)
  const [suspendPartnerModal, setSuspendPartnerModal] = useState(false)
  const [cancelPartnerSuspensionModal, setCancelPartnerSuspensionModal] = useState(false)

  const isDistributorAdmin = checkIsDistributorAdmin(authorization, partner?.distributorId)

  const handleSchedulePartnerSuspension = async ({ partnerId }) => {
    await dispatch(schedulePartnerSuspensionThunk({ partnerId }))
    setSuspendPartnerModal(false)
  }

  const handleCancelPartnerSuspension = async ({ partnerId }) => {
    await dispatch(cancelPartnerSuspensionThunk({ partnerId }))
    setCancelPartnerSuspensionModal(false)
  }

  const handleReactivatePartner = async ({ partnerId }) => {
    await dispatch(reactivatePartnerThunk({ partnerId }))
    setReactivatePartnerModal(false)
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}
    >

      {reactivatePartnerModal && (
        <>
          <PhinModal
            isOpen={reactivatePartnerModal}
            title='Reactivate Partner'
            close={() => setReactivatePartnerModal(false)}
            action={() => handleReactivatePartner({ partnerId: partner.id })}
            actionText='Reactivate'
            actionColor='primary'
          >
            <p>Are you sure you want to reactivate the partner: "{partner.name}"?</p>
          </PhinModal>
        </>)}

      {suspendPartnerModal && (
        <>
          <PhinModal
            isOpen={suspendPartnerModal}
            title='Schedule Partner Suspension'
            close={() => setSuspendPartnerModal(false)}
            action={() => handleSchedulePartnerSuspension({ partnerId: partner.id })}
            actionText='Schedule Suspension'
            actionColor='error'
          >
            <p>Are you sure you want to schedule the suspension for partner "{partner.name}"?</p>
            <p>Suspension will be active as of {DateTime.now().startOf('month').plus({ months: 1 }).toFormat('MM-dd-yyyy hh:mm:ss a')}</p>
          </PhinModal>
        </>)}

      {cancelPartnerSuspensionModal && (
        <>
          <PhinModal
            isOpen={cancelPartnerSuspensionModal}
            title='Cancel Partner Suspension'
            close={() => setCancelPartnerSuspensionModal(false)}
            action={() => handleCancelPartnerSuspension({ partnerId: partner.id })}
            actionText='Cancel Suspension'
            actionColor='error'
          >
            <p>Are you sure you want to cancel the suspension for partner: "{partner.name}"?</p>
          </PhinModal>
        </>)}

      {(isLoadingPartner || !partner) && (<Spinner />)}

      {(!isLoadingPartner && partner) && (
        <>
          {isDistributorAdmin && (
            <div className='padding-bottom:2'>
              {!partner.isSuspended && !partner.suspensionStartTimestamp && (
                <div className='suspend-partner-container padding:2'>
                  <div>
                    <span className='phin-h5'>Schedule Suspension for this Partner</span>
                    <br />
                    The suspension is not immediate and will take place at the end of the current billing cycle.
                    <br />
                    Once fully suspended, the partner will not be able to access Phin.
                    <br />
                    This action can only be performed by your administrator.
                  </div>

                  <div className='margin-left:2'>
                    <Button
                      disabled={!authorization.roles.partners[partner.id]}
                      id='suspend-partner-button'
                      aria-label='Suspend Partner Button'
                      sx={{ color: 'var(--phin-red)' }}
                      endIcon={<IoPauseCircle />} onClick={() => { setSuspendPartnerModal(true) }}
                    >
                      Suspend Partner
                    </Button>
                  </div>
                </div>)}

              {!partner.isSuspended && partner.suspensionStartTimestamp && (
                <div className='cancel-pending-partner-suspension-container padding:2'>
                  <div>
                    <span className='phin-h5'>Cancel Pending Suspension for this Partner</span>
                    <br />
                    This will cancel the pending suspension for the partner.
                    <br />
                    This action can only be performed by your administrator.
                  </div>

                  <div className='margin-left:2'>
                    <Button
                      disabled={!authorization.roles.partners[partner.id]}
                      id='cancel-partner-suspension-button'
                      aria-label='Cancel Suspension Partner Button'
                      sx={{ color: 'var(--phin-red)' }}
                      endIcon={<IoCloseCircle />} onClick={() => { setCancelPartnerSuspensionModal(true) }}
                    >
                      Cancel Suspension
                    </Button>
                  </div>
                </div>)}

              {partner.isSuspended && (
                <div className='reactivate-partner-container padding:2'>
                  <div>
                    <span className='phin-h5'>Reactivate this Partner</span>
                    <br />
                    This will immediately reactivate the partner.
                    <br />
                    This action can only be performed by your administrator.
                  </div>

                  <div className='margin-left:2'>
                    <Button
                      disabled={!authorization.roles.partners[partner.id]}
                      id='reactivate-partner-button'
                      aria-label='Reactivate Partner Button'
                      sx={{ color: 'var(--green-100)' }}
                      endIcon={<IoPlayCircle />} onClick={() => { setReactivatePartnerModal(true) }}
                    >
                      Reactivate Partner
                    </Button>
                  </div>
                </div>)}
            </div>
          )}
        </>
      )}

    </div>
  )
}

export default PartnerSettingsPage
