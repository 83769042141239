import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  MDBCardBody, MDBRow,
  MDBCol, MDBContainer
} from 'mdb-react-ui-kit'
import SyncIcon from '@mui/icons-material/Sync'
import NotificationUtilities from './components/notifications/notificationUtils'
import ValidationUtils from '../utils/validationUtils'
import './EditUser.css'
import { useDispatch, useSelector } from 'react-redux'
import { resetEditingUserAction, editUserThunk } from '../store/UsersSlice'
import Spinner from './components/Spinner'
import { Button, Tooltip, TextField } from '@mui/material'
import { STYLE } from '../frontendConsts'
import { PhinCard } from './components/PhinCard'

const totalSteps = 1

function previousStep (currentStep, setStep) {
  setStep(currentStep - 1)
}

function cancelCreation (history, resetEditingUser) {
  history.goBack()
  resetEditingUser()
}

const EditUser = ({ id }) => {
  const [currentStep, setStep] = useState(0)
  const [editedUserFields, setEditedUserFields] = useState({})
  const history = useHistory()
  const dispatch = useDispatch()
  const { editingUser: user } = useSelector(state => state.users)
  const { company } = useSelector(state => state.company)
  const { hasUserSync } = useSelector(state => state.integrations)
  if (!company) {
    return <Spinner />
  }

  let { deviceStructure: userStructure } = company
  userStructure = JSON.parse(userStructure)

  function validate () {
    if (editedUserFields.first !== undefined && !ValidationUtils.isNotWhiteSpace(editedUserFields.first)) {
      throw Error('Please input a first name')
    }
    if (editedUserFields.last !== undefined && !ValidationUtils.isNotWhiteSpace(editedUserFields.last)) {
      throw Error('Please input a last name')
    }
    if (editedUserFields.email !== undefined && !ValidationUtils.isValidEmail(editedUserFields.email)) {
      throw Error('Please enter a valid email')
    }
  }

  function nextStep (currentStep, setStep, finishCreation) {
    try {
      switch (currentStep) {
        // Getting started
        case (totalSteps - 1):
          finishCreation()
          break
        case 0:
          validate()
          setStep(currentStep + 1)
          break
        default:
          setStep(currentStep + 1)
          break
      }
    } catch (error) {
      console.error(error)
      NotificationUtilities.sendWarningMessage(error.message)
    }
  }

  async function finishCreation () {
    try {
      validate()
      await dispatch(editUserThunk(id, user.id, editedUserFields))
      history.goBack()
    } catch (error) {
      console.error(error)
      NotificationUtilities.sendWarningMessage(error.message)
    }
  }

  // Custom Tooltip for Sync Icon
  const renderSyncIcon = () => {
    return (
      <div className='sync-icon'>
        <Tooltip
          placement='right'
          id='sync-user-icon'
          material
          title='This user has been synced from an external system and must be edited from there'
        >
          <SyncIcon />
        </Tooltip>
      </div>
    )
  }
  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol style={{ alignItems: 'center' }} className='d-flex flex-column flex-center mb-3'>
          <h1 id='partner-page-heading'>Edit A User {(user.externalId && hasUserSync) ? renderSyncIcon() : ''}</h1>
        </MDBCol>
      </MDBRow>
      {currentStep === 0 &&
        <PhinCard
          title='User Information'
        >
          <MDBCardBody className='p-3'>
            <MDBRow className='d-flex justify-content-center'>
              {userStructure.map(userField => {
                return (
                  <MDBCol key={userField.field} size='6'>
                    <TextField
                      sx={{ marginBottom: 'var(--phin-s1)' }}
                      fullWidth
                      disabled={(user.externalId && hasUserSync)}
                      value={editedUserFields[userField.field] !== undefined ? editedUserFields[userField.field] : user[userField.field]}
                      id={`edit-user-${userField.field}-field`}
                      aria-label={`Edit User ${userField.field} Field`}
                      onChange={(e) => {
                        setEditedUserFields({ ...editedUserFields, [userField.field]: e.target.value })
                      }} name={userField.field} label={userField.label}
                    />
                  </MDBCol>
                )
              })}
            </MDBRow>

          </MDBCardBody>
        </PhinCard>}
      <div className='phin-flex-end padding-top:0'>
        <Button
          id='cancel-user-changes-button'
          aria-label='Cancel User Changes Button'
          style={STYLE.BUTTONS.TERTIARY}
          size='large'
          onClick={() => cancelCreation(history, () => dispatch(resetEditingUserAction()))}
        >Cancel
        </Button>
        {currentStep > 0 &&
          <Button className='mt-4' onClick={() => previousStep(currentStep, setStep)} color='secondary'>Previous</Button>}
        {user.email && (user.externalId === undefined || !hasUserSync) &&
          <span className='padding-left:-1'>
            <Button
              id='save-user-changes-button'
              aria-label='Save User Changes Button'
              onClick={() => nextStep(currentStep, setStep, finishCreation)}
              size='large'
              color='primary'
              variant='contained'
            >{currentStep < totalSteps - 1 ? 'Next' : 'Save'}
            </Button>
          </span>}
      </div>
    </MDBContainer>
  )
}

export default EditUser
