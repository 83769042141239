import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from './components/Spinner'
import { deletePolicyThunk } from '../store/PoliciesSlice'
import { StaticAlert } from '../shared/components/StaticAlert'
import { Grid } from '@mui/material'
import { ActivePolicyCard } from './policies/ActivePolicyCard'
import PhinModal from './components/PhinModal'

function ActivePolicyPage ({ companyId }) {
  const dispatch = useDispatch()
  const { policies, loaders } = useSelector((state) => state.policies)
  const { isLoadingPolicies } = loaders

  const [cancelModal, showCancelModal] = useState(false)
  const [selectedPolicy, selectPolicy] = useState(null)

  function openCancelModal (policy) {
    selectPolicy(policy)
    showCancelModal(true)
  }

  function closeCancelModal () {
    selectPolicy(null)
    showCancelModal(false)
  }

  async function confirmPolicyDeletion (policy) {
    const success = await dispatch(deletePolicyThunk({ companyId, policy }))

    if (success) {
      selectPolicy(null)
      showCancelModal(false)
    }
  }

  return (
    <div>
      <PhinModal
        isOpen={cancelModal}
        title='Delete Policy'
        close={() => closeCancelModal()}
        closeText='Keep Policy'
        action={() => confirmPolicyDeletion(selectedPolicy)}
        actionText='Cancel Policy'
        actionColor='error'
      >
        <p>Are you sure you would like to cancel this policy? <strong>This can not be undone!</strong>
          <br /> Your data will be saved and viewable from the Analytics page.
        </p>
      </PhinModal>

      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
      >
        <h1>Active Policy Documents</h1>
        <p>All active policy documents are shown here at a glance as well as info on their progress.</p>
      </Grid>

      {(!policies || isLoadingPolicies) && (
        <Spinner />
      )}
      {(policies && !isLoadingPolicies) && (
        <>
          {policies.length === 0
            ? (
              <StaticAlert severity='info' color='phinGray' variant='filled' text='You do not have any assigned policy documents yet. Go to the policy catalog to start one!' />
              )
            : (
              <Grid container spacing={4}>
                {policies.map(policy =>
                  <Grid item key={policy.id}>
                    <ActivePolicyCard policy={policy} openCancelModal={openCancelModal} companyId={companyId} />
                  </Grid>
                )}
              </Grid>
              )}
        </>
      )}
    </div>
  )
}

export default ActivePolicyPage
