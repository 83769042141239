import { createSlice } from '@reduxjs/toolkit'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'

export const policiesSlice = createSlice({
  name: 'policies',
  initialState: {
    policies: null,
    policyCatalog: null,
    loaders: {
      isLoadingPolicies: false,
      isLoadingPolicyCatalog: false,
      isLoadingLaunchPolicy: false,
      isLoadingCreatePolicy: false
    }
  },
  reducers: {
    setIsLoadingPolicies: (state, action) => {
      state.loaders.isLoadingPolicies = action.payload
    },
    getPolicies: (state, action) => {
      state.policies = action.payload
    },
    setIsLoadingPolicyCatalog: (state, action) => {
      state.loaders.isLoadingPolicyCatalog = action.payload
    },
    getPolicyCatalog: (state, action) => {
      state.policyCatalog = action.payload
    },
    setIsLoadingLaunchPolicy: (state, action) => {
      state.loaders.isLoadingPolicyCatalog = action.payload
    },
    launchPolicy: (state, action) => {
      const updatedPolicies = [...state.policies]
      updatedPolicies.push(action.payload)
      state.policies = updatedPolicies
    },
    deletePolicy: (state, action) => {
      const updatedPolicies = [...state.policies]
      const deletedPolicyIndex = updatedPolicies.findIndex(policy => policy.id === action.payload)
      updatedPolicies.splice(deletedPolicyIndex, 1)
      state.policies = updatedPolicies
    },
    setIsLoadingCreatePolicy: (state, action) => {
      state.loaders.isLoadingCreatePolicy = action.payload
    },
    createPolicy: (state, action) => {
      const updatedPolicyCatalog = [...state.policies]
      updatedPolicyCatalog.push(action.payload)
      state.policyCatalog = updatedPolicyCatalog
    }
  }
})

export const {
  setIsLoadingPolicies: setIsLoadingPoliciesAction,
  getPolicies: getPoliciesAction,
  setIsLoadingPolicyCatalog: setIsLoadingPolicyCatalogAction,
  getPolicyCatalog: getPolicyCatalogAction,
  setIsLoadingLaunchPolicy: setIsLoadingLaunchPolicyAction,
  launchPolicy: launchPolicyAction,
  deletePolicy: deletePolicyAction,
  setIsLoadingCreatePolicy: setIsLoadingCreatePolicyAction,
  createPolicy: createPolicyAction
} = policiesSlice.actions

export default policiesSlice.reducer

export const getPoliciesThunk = ({ id }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingPoliciesAction(true))
    try {
      const res = await api.get(`/api/companies/${id}/policy/assigned`)
      if (res.status === 200) {
        const { policies } = await res.json()
        dispatch(getPoliciesAction(policies))
      }
    } catch (err) {
      console.error(err)
    }
    dispatch(setIsLoadingPoliciesAction(false))
  }
}

export const getPolicyCatalogThunk = ({ id }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingPoliciesAction(true))
    try {
      const res = await api.get(`/api/companies/${id}/policy/catalog`)
      if (res.status === 200) {
        const { policies: policyCatalog } = await res.json()
        dispatch(getPolicyCatalogAction(policyCatalog))
      }
    } catch (err) {
      console.error(err)
    }
    dispatch(setIsLoadingPoliciesAction(false))
  }
}

// audienceStats is used for a Dynamic Audience
// fixedAudienceList is used for a Fixed Audience

// TODO: change audienceStats to dynamicAudienceList
export const launchPolicyThunk = ({ id, policyId, audienceSelectionType, audienceStats, audienceFilters, fixedAudienceList, audienceFilterType, history }) => {
  return async (dispatch, getState, api) => {
    try {
      const response = await api.post(`/api/companies/${id}/policy/launch/${policyId}`,
        { audienceSelectionType, audienceStats, audienceFilters, fixedAudienceList, audienceFilterType })

      if (response.status === 200) {
        const { assignedPolicy } = await response.json()
        dispatch(launchPolicyAction(assignedPolicy))
        setTimeout(() => {
          NotificationUtilities.sendSuccessMessage('Successfully distributed policy.')
        })
        history.push(`/companies/${id}/policy/active`)
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to distribute policy. Please try again or contact Phin Security if the problem persists.')
        })
      }
    } catch (err) {
      console.error('Failed to distribute policy', err)
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to distribute policy. Please try again or contact Phin Security if the problem persists.')
      })
    }
  }
}

export const deletePolicyThunk = ({ companyId, policy }) => {
  return async (dispatch, getState, api) => {
    const deletedPolicyId = policy.id
    setTimeout(() => {
      NotificationUtilities.sendInfoMessage('Cancelling policy...')
    })
    try {
      const res = await api.post(`/api/companies/${companyId}/policy/cancel/${policy.id}`)

      if (res.status === 200) {
        await dispatch(deletePolicyAction(deletedPolicyId))

        NotificationUtilities.sendSuccessMessage('Policy successfully cancelled.')
        return true
      } else {
        NotificationUtilities.sendErrorMessage('Error Cancelling Policy. Please try again or contact Phin Security if the problem persists.')
      }
    } catch (error) {
      NotificationUtilities.sendErrorMessage('Error Cancelling Policy. Please try again or contact Phin Security if the problem persists.')
    }

    return false
  }
}

export const createPolicyThunk = ({ id, formData, history }) => {
  return async (dispatch, getState, api) => {
    try {
      const response = await api.postForm(`/api/companies/${id}/policy/catalog`, formData)
      if (response.status === 200) {
        const newPolicy = await response.json()

        dispatch(createPolicyAction(newPolicy))

        setTimeout(() => {
          NotificationUtilities.sendSuccessMessage('Uploaded policy successfully.')
        }, 0)
        history.push(`/companies/${id}/policy/catalog`)
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to upload policy. Please try again or contact Phin Security if the problem persists.')
        })
      }
    } catch (error) {
      console.log(error)
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to upload policy. Please try again or contact Phin Security if the problem persists.')
      })
    }
  }
}
