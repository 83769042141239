import { PhinCard } from '../PhinCard'

function renderTemplateTags (templateTags) {
  const templateTagsArray = []

  templateTags.forEach(templateTag => {
    templateTagsArray.push(<li key={templateTag}>{templateTag}</li>)
  })

  return templateTagsArray
}

function PhishingCampaignResource ({ phishingCategories, phishingDescription, phishingCampaignName }) {
  return (
    <PhinCard
      title={phishingCampaignName}
      description={phishingDescription}
    >
      <div className='phin-h5 padding-bottom:-2'>Campaign Tags:</div>
      <ul style={{ fontWeight: '400' }}>
        {renderTemplateTags(phishingCategories)}
      </ul>
    </PhinCard>
  )
}

export default PhishingCampaignResource
