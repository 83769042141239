import { Button, Checkbox, Chip, FormControlLabel, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { integrationTypes, microsoftIntegrationStatuses } from '../../../../frontendConsts.js'
import { useStartAllowlistMutation, useGetAllowListProcessingDataQuery } from '../../../../store/AllowlistApiSlice.js'
import { disconnectIntegrationThunk, integrationUpdatedAction } from '../../../../store/IntegrationsSlice.js'
import { apiRequestUtils } from '../../../../utils/apiRequestUtils.js'
import '../../../css/integrationCard.css'
import '../../../css/integrationsDetail.css'
import PhinModal from '../../PhinModal.js'
import Spinner from '../../Spinner.js'
import { StatusBreadCrumbs } from '../StatusBreadCrumbs.js'
import { AllowlistPermissions } from './AllowlistPermissions.jsx'
import { AllowlistResultAccordion } from './AllowlistResultAccordion'
import { smartRoute } from '../../../../utils/RouterUtils.js'
import { useAuth0 } from '@auth0/auth0-react'

export function AutomatedAllowlistPage ({ id }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const intervalRef = useRef()

  const [disconnectModal, setDisconnectModal] = useState(false)
  const [agreedToDisconnect, setAgreedToDisconnect] = useState(false)

  const { company } = useSelector((state) => state.company)
  const { integrationsMap } = useSelector((state) => state.integrations)
  const isIntegrationConnected = integrationsMap?.allowlist?.integrationStatus === microsoftIntegrationStatuses.CONNECTED

  const [startAllowlist] = useStartAllowlistMutation()
  const { data: allowlistProcessingData } = useGetAllowListProcessingDataQuery(id, { pollingInterval: 3000, skip: !isIntegrationConnected })

  const getReadableDate = () => {
    if (allowlistProcessingData.lastRunTimestamp) {
      return new Date(allowlistProcessingData.lastRunTimestamp._seconds * 1000 + allowlistProcessingData.lastRunTimestamp._nanoseconds / 1000000).toLocaleString()
    }
  }

  const { authorization } = useSelector((state) => state.auth)
  const { logout } = useAuth0()
  if (company && !company?.featureFlags?.allowlist) {
    smartRoute({ authorization, history, logout })
  }

  const consentToIntegration = () => {
    const redirectURL = encodeURIComponent(`${window.location.protocol}//${window.location.host}/integrations`)
    const adminConsentLink = 'https://login.microsoftonline.com/common/adminconsent'
    const integrationType = 'allowlist'
    const applicationId = process.env.REACT_APP_MICROSOFT_ALLOWLIST_APPLICATION_ID
    window.open(`${adminConsentLink}?client_id=${applicationId}&redirect_uri=${redirectURL}&state=${id}%7C${integrationType}`, '_self')
  }

  const validateConsent = () => {
    apiRequestUtils.put(`/api/companies/${id}/integrations/microsoft/validateConsent`, { integrationId: integrationTypes.ALLOWLIST }).then(async (response) => {
      if (response.status === 200) {
        const data = await response.json()
        if (data.integrationStatus === microsoftIntegrationStatuses.CONNECTED) {
          dispatch(integrationUpdatedAction({ integrationId: integrationTypes.ALLOWLIST, updatedFields: { integrationStatus: data.integrationStatus } }))
        } else {
          clearInterval(intervalRef.current)
          intervalRef.current = setInterval(() => {
            apiRequestUtils.put(`/api/companies/${id}/integrations/microsoft/validateConsent`, { integrationId: integrationTypes.ALLOWLIST }).then(async (response) => {
              if (response.status === 200) {
                const data = await response.json()
                if (data.integrationStatus === microsoftIntegrationStatuses.CONNECTED) {
                  clearInterval(intervalRef.current)
                  dispatch(integrationUpdatedAction({ integrationId: integrationTypes.ALLOWLIST, updatedFields: { integrationStatus: data.integrationStatus } }))
                }
              }
            })
          }, 10000)
        }
      } else {
        clearInterval(intervalRef.current)
        intervalRef.current = setInterval(() => {
          apiRequestUtils.put(`/api/companies/${id}/integrations/microsoft/validateConsent`, { integrationId: integrationTypes.ALLOWLIST }).then(async (response) => {
            if (response.status === 200) {
              const data = await response.json()
              if (data.integrationStatus === microsoftIntegrationStatuses.CONNECTED) {
                clearInterval(intervalRef.current)
                dispatch(integrationUpdatedAction({ integrationId: integrationTypes.ALLOWLIST, updatedFields: { integrationStatus: data.integrationStatus } }))
              }
            }
          })
        }, 10000)
      }
    })
  }

  async function handleDisconnect () {
    if (agreedToDisconnect) {
      const success = await dispatch(disconnectIntegrationThunk({ id, integrationId: integrationTypes.ALLOWLIST, history }))

      if (success) {
        setDisconnectModal(false)
      }
    }
  }

  const getBreadCrumbSteps = () => {
    const steps = { ...microsoftIntegrationStatuses }

    delete steps.TEMP_STAGING
    delete steps.TEMP_STAGED
    delete steps.STAGING
    delete steps.STAGED
    delete steps.COMMITTING

    return Object.keys(steps).map((key) => steps[key])
  }

  const getBreadCrumbStep = (status) => {
    if (!status) return 0 // Show not connected if no integration exists
    const steps = getBreadCrumbSteps()
    return Object.keys(steps).findIndex((key) => steps[key] === status)
  }

  useEffect(() => {
    if (integrationsMap && integrationsMap.allowlist) {
      if (integrationsMap.allowlist.integrationStatus === microsoftIntegrationStatuses.CONSENT_SUBMITTED) {
        validateConsent()
        return function cleanUpInterval () {
          clearInterval(intervalRef.current)
        }
      }
    }
  }, [integrationsMap])

  async function handleStartAllowlisting () {
    await startAllowlist({ companyId: id })
  }
  return (
    <div>
      {(!company || !integrationsMap) && (
        <Spinner />
      )}

      {(company && integrationsMap) && (
        <>
          <div className='detailsPageHeadingGroup'>
            <img src='/RaPIcon.png' />
            <div>
              <div className='detailsTitleGroup'>
                <h2 className='phin-page-heading'>Automated Allowlist</h2>
                <Chip label='BETA' sx={{ backgroundColor: 'var(--purple-75)', color: 'white', marginLeft: '.5rem' }} />
              </div>
              <StatusBreadCrumbs
                step={getBreadCrumbStep(integrationsMap?.allowlist?.integrationStatus)}
                steps={getBreadCrumbSteps()}
              />
            </div>
          </div>

          <div className='d-flex justify-content-center'>
            {/* Spinners */}
            {integrationsMap.allowlist && integrationsMap.allowlist.integrationStatus === microsoftIntegrationStatuses.CONSENT_SUBMITTED && (
              <Spinner message='Verifying Microsoft Consent Status' secondaryMessage='This may take a few minutes' />
            )}

            <div className='details'>

              {/* Page Content */}
              <div className='left'>
                {(!integrationsMap.allowlist || integrationsMap.allowlist.integrationStatus === microsoftIntegrationStatuses.NOT_CONNECTED) && (
                  <AllowlistPermissions
                    actionToPerform='connect'
                    handler={consentToIntegration}
                  />
                )}

                {integrationsMap.allowlist && integrationsMap.allowlist.integrationStatus === microsoftIntegrationStatuses.CONNECTED && (
                  <>
                    <PhinModal
                      isOpen={disconnectModal}
                      title='Are you sure you want to disconnect?'
                      close={() => {
                        setDisconnectModal(false)
                        setAgreedToDisconnect(false)
                      }}
                      closeText='Cancel'
                      action={handleDisconnect}
                      actionColor='error'
                      actionText='Disconnect'
                      actionButtonDisabled={!agreedToDisconnect}
                    >
                      <FormControlLabel
                        sx={{ marginTop: 'var(--phin-s-2', marginLeft: 'var(--phin-s2)' }}
                        label='I understand & want to disconnect Automated Allowlist.'
                        control={
                          <Checkbox
                            required
                            checked={agreedToDisconnect}
                            onChange={(e) => {
                              setAgreedToDisconnect(e.target.checked)
                            }}
                            inputProps={{ 'aria-label': 'I understand & want to disconnect Automated Allowlist' }}
                          />
                        }
                      />
                    </PhinModal>
                    <AllowlistPermissions
                      actionToPerform='disconnect'
                      handler={() => setDisconnectModal(true)}
                    />
                  </>
                )}
              </div>

              {(!integrationsMap.allowlist || (integrationsMap.allowlist && integrationsMap.allowlist.integrationStatus === microsoftIntegrationStatuses.CONNECTED)) && (
                <div className='right' style={integrationsMap.allowlist ? {} : { filter: 'opacity(.2)', pointerEvents: 'none' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '1rem' }}>
                    {allowlistProcessingData?.lastRunTimestamp && <Typography>{`Last successful run: ${getReadableDate()}`}</Typography>}
                    {allowlistProcessingData?.processing && <Spinner message='Processing Allowlist' secondaryMessage='This may take a few minutes...' />}
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={handleStartAllowlisting}
                      disabled={allowlistProcessingData?.processing}
                      style={{ alignSelf: 'flex-start' }}
                    >
                      {allowlistProcessingData?.processing ? 'Pending' : 'Start Allowlist'}
                    </Button>
                    {!allowlistProcessingData?.processing && allowlistProcessingData?.allowlistResults && (
                      <>
                        <AllowlistResultAccordion
                          output={allowlistProcessingData.allowlistResults.outputs.allowlistOutput}
                          error={allowlistProcessingData.allowlistResults.errors.allowlistError}
                          label='Block List'
                        />
                        <AllowlistResultAccordion
                          output={allowlistProcessingData.allowlistResults.outputs.transportRuleOutput}
                          error={allowlistProcessingData.allowlistResults.errors.transportRuleError}
                          label='Tenant Transport Rule'
                        />
                        <AllowlistResultAccordion
                          output={allowlistProcessingData.allowlistResults.outputs.phishSimRulesOutput}
                          error={allowlistProcessingData.allowlistResults.errors.phishSimRulesError}
                          label='Phishing Sim Rule'
                        />
                      </>
                    )}
                  </div>
                </div>
              )}

            </div>
          </div>
        </>
      )}
    </div>
  )
}
