import { createSlice } from '@reduxjs/toolkit'
import { integrationTypes } from '../frontendConsts.js'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'
import { removeDistributorIntegrationAction } from './DistributorIntegrationSlice.js'

export const phinDistributorApiSlice = createSlice({
  name: 'phinDistributorAPI',
  initialState: {
    phinApiIntegration: null,
    auth0ClientSecret: null,
    loaders: {
      isLoadingPhinAPI: false,
      isLoadingPhinApiSecret: false
    }
  },
  reducers: {
    setIsLoadingPhinAPI: (state, action) => {
      state.loaders.isLoadingPhinAPI = action.payload
    },
    setIsLoadingPhinApiSecret: (state, action) => {
      state.loaders.isLoadingPhinApiSecret = action.payload
    },
    setAuth0ClientSecret: (state, action) => {
      state.auth0ClientSecret = action.payload
    },
    setPhinApiIntegration: (state, action) => {
      state.phinApiIntegration = action.payload
    }
  }

})

export const {
  setIsLoadingPhinAPI: setIsLoadingPhinAPIAction,
  setIsLoadingPhinApiSecret: setIsLoadingPhinApiSecretAction,
  setAuth0ClientSecret: setAuth0ClientSecretAction,
  setPhinApiIntegration: setPhinApiIntegrationAction
} = phinDistributorApiSlice.actions

export default phinDistributorApiSlice.reducer

export const createPhinApiIntegrationThunk = (distributorId) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingPhinAPIAction(true))
    try {
      const response = await api.post(`/api/distributors/${distributorId}/integrations/phin-api`)

      if (response.status === 200) {
        const { phinApiIntegration, auth0ClientSecret } = await response.json()
        dispatch(setPhinApiIntegrationAction(phinApiIntegration))
        dispatch(setAuth0ClientSecretAction(auth0ClientSecret))
      } else {
        const statusObj = await response.json()

        setTimeout(() => {
          NotificationUtilities.sendErrorMessage(statusObj.error)
        }, 0)
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to create Phin API Integration. Please try again or contact Phin Support for assistance.')
      }, 0)
    }
    dispatch(setIsLoadingPhinAPIAction(false))
  }
}

export const getPhinApiIntegrationThunk = () => {
  return async (dispatch, getState) => {
    dispatch(setIsLoadingPhinAPIAction(true))
    try {
      const distributorIntegrationsMap = { ...getState().distributorIntegrations.distributorIntegrationsMap }

      if (distributorIntegrationsMap && distributorIntegrationsMap[integrationTypes.PHIN_API]) {
        dispatch(setPhinApiIntegrationAction(distributorIntegrationsMap[integrationTypes.PHIN_API]))
      } else {
        dispatch(setPhinApiIntegrationAction(null))
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to load Phin API Integration. Please try again or contact Phin Support for assistance.')
      }, 0)
    }
    dispatch(setIsLoadingPhinAPIAction(false))
  }
}

export const rotatePhinApiSecretThunk = (distributorId) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingPhinApiSecretAction(true))
    try {
      const response = await api.put(`/api/distributors/${distributorId}/integrations/phin-api/rotate-secret`)

      if (response.status === 200) {
        const { auth0ClientSecret } = await response.json()

        dispatch(setAuth0ClientSecretAction(auth0ClientSecret))
      } else {
        const statusObj = await response.json()

        setTimeout(() => {
          NotificationUtilities.sendErrorMessage(statusObj.error)
        }, 0)
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to rotate Phin API Secret. Please try again or contact Phin Support for assistance.')
      }, 0)
    }
    dispatch(setIsLoadingPhinApiSecretAction(false))
  }
}

export const disconnectPhinApiThunk = ({ distributorId, history }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingPhinAPIAction(true))
    try {
      const response = await api.delete(`/api/distributors/${distributorId}/integrations/phin-api`)

      if (response.status === 200) {
        await response.json()

        dispatch(setAuth0ClientSecretAction(null))
        dispatch(setPhinApiIntegrationAction(null))
        dispatch(removeDistributorIntegrationAction(integrationTypes.PHIN_API))

        history.push(`/distributors/${distributorId}/integrations`)
      } else {
        const statusObj = await response.json()

        setTimeout(() => {
          NotificationUtilities.sendErrorMessage(statusObj.error)
        }, 0)
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to delete Phin API Integration. Please try again or contact Phin Support for assistance.')
      }, 0)
    }
    dispatch(setIsLoadingPhinAPIAction(false))
  }
}
