import { DateTime } from 'luxon'

export function computeCampaignStatus ({ firstFireDate, startTime, endTime, nextFireTimestamp, tz }) {
  let firstFireDateTime
  if (firstFireDate) {
    firstFireDateTime = DateTime.fromISO(firstFireDate).setZone(tz)
  } else {
    firstFireDateTime = DateTime.fromJSDate(startTime).setZone(tz)
  }

  let endDateTime
  if (endTime) {
    endDateTime = DateTime.fromJSDate(endTime).setZone(tz)
  }

  const nextFireDateTime = DateTime.fromSeconds(nextFireTimestamp._seconds).setZone(tz)

  // Campaign has NOT started yet
  if (DateTime.now() < firstFireDateTime) {
    return { status: 0, message: `First Fire Time: ${nextFireDateTime.toLocaleString(DateTime.DATETIME_SHORT)}`, color: 'success' }
  } else if (endDateTime && endDateTime > DateTime.now()) {
    return { status: 3, message: 'Completed', color: 'primary' }
  } else {
    return { status: 1, message: `Next Fire Time: ${nextFireDateTime.toLocaleString(DateTime.DATETIME_SHORT)}`, color: 'default' }
  }
}

export function getTimeInAppropriateUnits (time, referenceTime) {
  const monthOfMillis = 2629800000
  const dayOfMillis = 86400000
  const absTime = Math.abs(time - referenceTime)

  if (absTime > monthOfMillis) {
    const num = Math.floor(absTime / monthOfMillis)
    return `${num} month${num !== 1 ? 's' : ''}`
  } else if (absTime > dayOfMillis) {
    const num = Math.ceil(absTime / dayOfMillis)
    return `${num} day${num !== 1 ? 's' : ''}`
  } else {
    return 'less than a day'
  }
}
