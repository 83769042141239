import { createSlice } from '@reduxjs/toolkit'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'

export const hubSpotPartnersSlice = createSlice({
  name: 'hubSpotPartners',
  initialState: {
    loaders: {
      isLoadingValidateHubSpotPartner: false,
      hubSpotPartnerName: null,
      isValidHubSpotPartner: false,
      minimumBillableUsers: null,
      pricePerUserOption: null,
      hubSpotPartnerErrorMessage: null
    }
  },
  reducers: {
    setIsLoadingValidateHubSpotPartner: (state, action) => {
      state.loaders.isLoadingValidateHubSpotPartner = action.payload
    },
    updateHubSpotPartnerName: (state, action) => {
      state.hubSpotPartnerName = action.payload
    },
    updateIsValidHubSpotPartner: (state, action) => {
      state.isValidHubSpotPartner = action.payload
    },
    updateMinimumBillableUsers: (state, action) => {
      state.minimumBillableUsers = action.payload
    },
    updatePricePerUserOption: (state, action) => {
      state.pricePerUserOption = action.payload
    },
    updateHubSpotPartnerErrorMessage: (state, action) => {
      state.hubSpotPartnerErrorMessage = action.payload
    }
  }
})

export const {
  setIsLoadingValidateHubSpotPartner: setIsLoadingValidateHubSpotPartnerAction,
  updateHubSpotPartnerName: updateHubSpotPartnerNameAction,
  updateIsValidHubSpotPartner: updateIsValidHubSpotPartnerAction,
  updateMinimumBillableUsers: updateMinimumBillableUsersAction,
  updatePricePerUserOption: updatePricePerUserOptionAction,
  updateHubSpotPartnerErrorMessage: updateHubSpotPartnerErrorMessageAction
} = hubSpotPartnersSlice.actions

export default hubSpotPartnersSlice.reducer

export const validateHubSpotPartnerThunk = ({ distributorId, hubSpotCompanyId }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingValidateHubSpotPartnerAction(true))

    const response = await api.get(`/api/distributors/${distributorId}/hubSpot/validate/${hubSpotCompanyId}`)

    if (response.status === 200) {
      const {
        hubSpotPartnerName,
        minimumBillableUsers, pricePerUserOption
      } = await response.json()

      dispatch(updateIsValidHubSpotPartnerAction(true))
      dispatch(updateHubSpotPartnerErrorMessageAction(null))
      dispatch(updateHubSpotPartnerNameAction(hubSpotPartnerName))
      dispatch(updateMinimumBillableUsersAction(minimumBillableUsers))
      dispatch(updatePricePerUserOptionAction(pricePerUserOption))
    } else {
      const { errorMessage } = await response.json()
      dispatch(updateHubSpotPartnerErrorMessageAction(errorMessage))
      dispatch(updateIsValidHubSpotPartnerAction(false))
      dispatch(updateHubSpotPartnerNameAction())
      dispatch(updateMinimumBillableUsersAction())
      dispatch(updatePricePerUserOptionAction())

      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to validate Partner in HubSpot.')
      })
    }
    dispatch(setIsLoadingValidateHubSpotPartnerAction(false))
  }
}
