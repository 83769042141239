import SelectOptions from './components/SelectOptions'

import { useHistory } from 'react-router'
import NotificationUtilities from './components/notifications/notificationUtils'
import { navigationRoutes } from '../frontendConsts.js'

import React, { useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { getCourseCatalogThunk, launchTrainingCourseThunk } from '../store/TrainingSlice'

import Spinner from './components/Spinner'
import { CoursePreviewModal } from '../shared/components/CoursePreviewModal'
import { FreeTrialLockoutModal } from './partnerPages/components/FreeTrialLockoutModal.js'
import { CourseCatalogCard } from './components/training/CourseCatalogCard.js'
import { Button, Grid } from '@mui/material'
import { IoAddOutline } from 'react-icons/io5'
import PhinModal from './components/PhinModal.js'

function CatalogPage ({ companyId }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { company, loaders } = useSelector((state) => state.company)
  const { isLoadingCompany } = loaders
  const { trainings, courseCatalog, loaders: trainingLoaders } = useSelector((state) => state.trainings)
  const { isLoadingTrainings, isLoadingCourseCatalog } = trainingLoaders

  const [isFreeTrialLockoutModalOpen, setIsFreeTrialLockoutModalOpen] = useState(false)
  const [confirmModal, showConfirmModal] = useState(false)
  const [selectedCourse, selectCourse] = useState(null)
  const [selectedTopic, setSelectedTopic] = useState('All')
  const [selectedProvider, setSelectedProvider] = useState('All')
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [previewModalHeader, setPreviewModalHeader] = useState('')
  const [course, setCourse] = useState(null)
  const [topics, setTopics] = useState([
    {
      selected: true,
      disabled: false,
      icon: null,
      value: 'All',
      text: 'All'
    }
  ])
  const [providers, setProviders] = useState([
    {
      selected: true,
      disabled: false,
      icon: null,
      value: 'All',
      text: 'All'
    }
  ])

  useEffect(() => {
    dispatch(getCourseCatalogThunk({ companyId }))
  }, [])

  useEffect(() => {
    if (courseCatalog) {
      const topicsSet = new Set()
      const providersSet = new Set()
      const topicsArray = [{
        selected: true,
        disabled: false,
        icon: null,
        value: 'All',
        text: 'All'
      }]
      const providersArray = [{
        selected: true,
        disabled: false,
        icon: null,
        value: 'All',
        text: 'All'
      }]

      courseCatalog.forEach((course) => {
        if (course.topic && course.topic !== '') {
          topicsSet.add(course.topic)
        }
        if (course.provider && course.provider !== '') {
          providersSet.add(course.provider)
        }
      })

      topicsSet.forEach((topic) => {
        topicsArray.push({
          selected: false,
          disabled: false,
          icon: null,
          value: topic,
          text: topic
        })
      })

      providersSet.forEach((provider) => {
        providersArray.push({
          selected: false,
          disabled: false,
          icon: null,
          value: provider,
          text: provider
        })
      })

      setTopics(topicsArray)
      setProviders(providersArray)
    }
  }, [courseCatalog])

  function handleTrainingCreatorPress () {
    if (company.freeTrial) {
      setIsFreeTrialLockoutModalOpen(!isFreeTrialLockoutModalOpen)
    } else {
      history.push(`/companies/${companyId}${navigationRoutes.TRAININGCREATOR}`)
    }
  }

  async function launchTraining () {
    NotificationUtilities.sendInfoMessage('Starting course...')
    const success = await dispatch(launchTrainingCourseThunk({ companyId, selectedCourse, history }))
    if (success) {
      cancelTrainingLaunch()
      history.push(`/companies/${companyId}/training`)
    }
  }

  function openConfirmModal (course) {
    selectCourse(course)
    showConfirmModal(true)
  }

  function cancelTrainingLaunch () {
    selectCourse(null)
    showConfirmModal(false)
  }

  function togglePreviewModal (course, shouldShowPreviewModal) {
    setCourse(course)
    setPreviewModalHeader(course === null ? '' : course.name)
    setShowPreviewModal(shouldShowPreviewModal)
  }

  return (
    <div>
      <PhinModal
        isOpen={confirmModal}
        title='Schedule Course'
        close={() => cancelTrainingLaunch()}
        closeText='Cancel'
        action={launchTraining}
        actionText='Start Course'
      >
        <p>Are you sure you want to schedule this course?</p>
      </PhinModal>

      <CoursePreviewModal
        companyId={companyId}
        courseDefinition={course}
        previewModalHeader={previewModalHeader}
        showPreviewModal={showPreviewModal}
        togglePreviewModal={togglePreviewModal}
      />

      <FreeTrialLockoutModal
        open={isFreeTrialLockoutModalOpen}
        feature='custom course upload'
        featureIntroTitle='upload your own custom videos'
        description='Looking to upload your own video content? No problem, upgrade to get full access to the Phin platform and start uploading your own training videos today.'
        KBLink='https://www.phinsec.io/knowledge/how-to-upload-your-own-content-to-phins-platform'
        closeModal={() => setIsFreeTrialLockoutModalOpen(false)}
      />

      <div className='d-flex justify-content-start'>
        <h2 className='phin-h2 padding-bottom:3'>Training Courses Library</h2>
      </div>

      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='baseline'
      >
        <p>View our available course selection here as well as launch new trainings for your employees.</p>
        <Button
          id='upload-intro-video-button'
          variant='contained'
          onClick={() => handleTrainingCreatorPress()}
          startIcon={<IoAddOutline />}
          size='large'
          sx={!company || company.freeTrial ? { opacity: '0.6', marginRight: 10 } : { marginRight: 10 }}
          color={!company || company.freeTrial ? 'grey' : 'primary'}
        >
          Upload Content
        </Button>
      </Grid>

      {(!company || isLoadingCompany || !trainings ||
        isLoadingTrainings || !courseCatalog || isLoadingCourseCatalog) && (
          <Spinner message='Loading Course Catalog' />
      )}

      {(company && !isLoadingCompany &&
        trainings && !isLoadingTrainings &&
        courseCatalog && !isLoadingCourseCatalog) && (
          <>
            <Grid
              container
              spacing={2}
              direction='row'
              justifyContent='flex-start'
              alignItems='center'
              sx={{ marginBottom: 'var(--phin-s1)', marginTop: 'var(--phin-s3)' }}
            >
              <Grid item xs={2}>
                <SelectOptions
                  label='Course Topic'
                  name='Course Topic'
                  onChange={(e) => setSelectedTopic(e.target.value)}
                  options={topics}
                />
              </Grid>
              <Grid item xs={2}>
                <SelectOptions
                  label='Provider'
                  name='Provider'
                  onChange={(e) => setSelectedProvider(e.target.value)}
                  options={providers}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent='center' spacing={4}>
              {courseCatalog.filter((course) => {
                return ((course.topic === selectedTopic || selectedTopic === 'All') &&
                (course.provider === selectedProvider || selectedProvider === 'All'))
              }).map((course) => {
                return (
                  <Grid
                    item
                    key={course.id}
                  >
                    <CourseCatalogCard
                      course={course}
                      openConfirmModal={openConfirmModal}
                      togglePreviewModal={togglePreviewModal}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </>
      )}
    </div>
  )
}

export default CatalogPage
