import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'

export const AudienceDetailsTable = ({ audience, audienceColumns, rowClickAction, addUsersButton }) => {
  return (
    <Box
      id='audience-details-table'
      aria-label='Audience Details Table'
      sx={{ paddingBottom: '2em', width: '100%', height: 'auto' }}
    >
      <Grid
        container
        justifyContent='space-between'
        sx={{ paddingBottom: '0.5em' }}
      >
        <h3 className='phin-h4'>Audience</h3>
        {addUsersButton}
      </Grid>
      <DataGrid
        onRowClick={(params) => { rowClickAction(params.row.id) }}
        autoHeight
        style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
        className='DataGrid'
        initialState={{
          sorting: {
            sortModel: [{ field: 'creationDate', sort: 'desc' }]
          }
        }}
        rows={audience?.users || []}
        columns={audienceColumns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 250, id: 'audience-details-table-search', 'aria-label': 'Search' },
            csvOptions: { disableToolbarButton: true },
            printOptions: { disableToolbarButton: true }
          }
        }}
        disableColumnSelector
        disableDensitySelector
        sx={{
          borderRadius: '8px',
          border: '2px solid var(--phin-light-gray)',
          '.MuiDataGrid-cell:focus': {
            outline: 'none'
          },
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer'
          }
        }}
      />
    </Box>
  )
}
