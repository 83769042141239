import { createSlice } from '@reduxjs/toolkit'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'

export const resourcesSlice = createSlice({
  name: 'resources',
  initialState: {
    resourceVideos: [],
    loaders: {
      isLoadingResourceVideos: false
    }
  },
  reducers: {
    getResourceVideos: (state, action) => {
      state.resourceVideos = action.payload
      state.loaders.isLoadingResourceVideos = false
    },
    setIsLoadingResourceVideos: (state, action) => {
      state.loaders.isLoadingResourceVideos = action.payload
    }
  }

})

export const {
  getResourceVideos: getResourceVideosAction,
  setIsLoadingResourceVideos: setIsLoadingResourceVideosAction
} = resourcesSlice.actions

export default resourcesSlice.reducer

export const getResourceVideosThunk = (id) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingResourceVideosAction(true))
    try {
      const res = await api.get('/api/resources/platformvideos')
      if (res.status === 200) {
        const videos = await res.json()
        dispatch(getResourceVideosAction(videos))
      } else {
        dispatch(setIsLoadingResourceVideosAction(false))
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to load Resource Videos')
        })
      }
    } catch (err) {
      console.error(err)
    }
  }
}
