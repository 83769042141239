import { IoClose } from 'react-icons/io5'
import { TrainingCourseCard } from './TrainingCourseCard'
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'

export const CourseDetailModal = ({ course, showDetailModal, detailModalHeader, toggleDetailModal }) => {
  return (
    <>
      {showDetailModal && course && (
        <Dialog
          id='course-detail-modal'
          onClose={() => toggleDetailModal(null, false)} open={showDetailModal && course}
          sx={{ padding: 'var(--phin-s0)' }}
        >
          <DialogTitle>
            {detailModalHeader}
            <IconButton
              aria-label='close'
              onClick={() => toggleDetailModal(null, false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8
              }}
            >
              <IoClose />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TrainingCourseCard course={course} />
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
