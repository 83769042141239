import { createSlice } from '@reduxjs/toolkit'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'
import { partnerUpdatedAction } from './PartnerSlice'
import { PAX8_ACCOUNT_STATUSES } from '../frontendConsts'

export const pax8PartnerSlice = createSlice({
  name: 'pax8Partner',
  initialState: {
    pax8PricingPlans: null,
    isLoadingPax8PricingPlans: false,
    isLoadingStartPax8FreeTrial: false
  },
  reducers: {
    setLoadingPax8PricingPlans: (state, action) => {
      state.isLoadingPax8PricingPlans = action.payload
    },
    setPax8PricingPlans: (state, action) => {
      state.pax8PricingPlans = action.payload
    },
    setIsLoadingStartPax8FreeTrial: (state, action) => {
      state.isLoadingStartPax8FreeTrial = action.payload
    }
  }
})

export const {
  setLoadingPax8PricingPlans: setLoadingPax8PricingPlansAction,
  setPax8PricingPlans: setPax8PricingPlansAction,
  setIsLoadingStartPax8FreeTrial: setIsLoadingStartPax8FreeTrialAction
} = pax8PartnerSlice.actions

export default pax8PartnerSlice.reducer

export const getPax8PricingPlansThunk = ({ partnerId }) => {
  return async (dispatch, getState, api) => {
    await dispatch(setLoadingPax8PricingPlansAction(true))

    try {
      const response = await api.get(`/api/partners/${partnerId}/pax8/pricing-plans`)

      if (response.status === 200) {
        const { pax8PricingPlans } = await response.json()

        await dispatch(setPax8PricingPlansAction(pax8PricingPlans))
        await dispatch(setLoadingPax8PricingPlansAction(false))

        return true
      } else {
        NotificationUtilities.sendErrorMessage('Failed to load Pax Pricing Plans. Please try again or reach out to Pax8 Support for assistance.')
      }
    } catch (error) {
      NotificationUtilities.sendErrorMessage('Failed to load Pax Pricing Plans. Please try again or reach out to Pax8 Support for assistance.')
    }

    dispatch(setLoadingPax8PricingPlansAction(false))

    return false
  }
}

export const selectPaxPartnerPricingPlanThunk = ({ partnerId, pax8PlanId }) => {
  return async (dispatch, getState, api) => {
    dispatch(setLoadingPax8PricingPlansAction(true))

    try {
      const response = await api.post(`/api/partners/${partnerId}/pax8/pricing-plan/${pax8PlanId}`)

      if (response.status === 200) {
        const { pax8PlanId } = await response.json()

        const hasCurrentPlan = getState().partner.partner.pax8PlanId || false

        if (!hasCurrentPlan || getState().partner.partner.pax8AccountStatus === PAX8_ACCOUNT_STATUSES.TRIALING) {
          await dispatch(partnerUpdatedAction({ pax8PlanId }))
        } else {
          await dispatch(partnerUpdatedAction({ pendingPax8PlanId: pax8PlanId }))
        }

        await dispatch(setLoadingPax8PricingPlansAction(false))

        NotificationUtilities.sendSuccessMessage('Pricing Plan saved successfully.')

        return { success: true, hasCurrentPlan }
      } else {
        NotificationUtilities.sendErrorMessage('Failed to save Pricing Plan. Please try again or reach out to Pax8 Support for assistance.')
      }
    } catch (error) {
      NotificationUtilities.sendErrorMessage('Failed to save Pricing Plan. Please try again or reach out to Pax8 Support for assistance.')
    }

    dispatch(setLoadingPax8PricingPlansAction(false))

    return false
  }
}

export const startPax8FreeTrialThunk = ({ partnerId }) => {
  return async (dispatch, getState, api) => {
    try {
      await dispatch(setIsLoadingStartPax8FreeTrialAction(true))
      const response = await api.post(`/api/partners/${partnerId}/pax8/start-free-trial`)

      if (response.status === 200) {
        const { pax8AccountStatus, pax8TrialStartTimestamp, pax8TrialEndTimestamp } = await response.json()

        await dispatch(partnerUpdatedAction({ pax8AccountStatus, pax8TrialStartTimestamp, pax8TrialEndTimestamp }))

        await dispatch(setIsLoadingStartPax8FreeTrialAction(false))
        return true
      } else {
        console.error('Failed to start Pax8 Free Trial')
      }
    } catch (error) {
      console.error('Failed to start Pax8 Free Trial')
    }
    await dispatch(setIsLoadingStartPax8FreeTrialAction(false))
    return false
  }
}
