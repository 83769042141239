import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SaveCancelButtonsRow } from '../../../shared/components/SaveCancelButtonsRow'
import { changePartnerNameThunk } from '../../../store/PartnerSlice'
import ValidationUtils from '../../../utils/validationUtils'
import NotificationUtilities from '../notifications/notificationUtils'

const ChangePartnerName = ({ id }) => {
  const partnerName = useSelector((state) => state.partner.partner?.name)
  const [newPartnerName, setNewPartnerName] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const dispatch = useDispatch()

  const onSubmit = async () => {
    if (!ValidationUtils.isNotWhiteSpace(newPartnerName)) {
      NotificationUtilities.sendWarningMessage('Partner Name can not be empty')
      return
    }

    if (submitting) {
      return true
    }

    setSubmitting(true)
    await dispatch(changePartnerNameThunk(id, newPartnerName))
    setSubmitting(false)
  }

  useEffect(() => {
    setNewPartnerName(partnerName)
  }, [partnerName])

  return (
    <div className='pageContainer'>
      <div id='logoEditCard'>
        <div>
          <div>
            <p className='phinCardHeader'>Partner Name</p>
            <p>The name of your partner. This will be used as the name of your partner in the Phin portal and in all communications Phin will send.</p>
          </div>

          <input
            id='new-partner-name-field'
            aria-label='New Partner Name Field'
            className='textInputBox2 w-100 mb-4'
            placeholder='New Partner Name'
            type='text' value={newPartnerName}
            onChange={(e) => { setNewPartnerName(e.target.value) }}
            name='new-partner-name'
          />

          <SaveCancelButtonsRow
            action={onSubmit}
            actionText='Save Changes'
            actionId='save-partner-name-update-button'
            close={() => { setNewPartnerName(partnerName) }}
            closeText='Discard Changes'
            closeId='cancel-partner-name-update-button'
          />
        </div>
      </div>
    </div>
  )
}

export default ChangePartnerName
