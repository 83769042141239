import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  MDBRow,
  MDBCol
} from 'mdb-react-ui-kit'
import './css/integrationCard.css'
import { integrationStatuses, integrationTypes, distributorIntegrationStatuses } from '../frontendConsts.js'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from './components/Spinner'
import { getDistributorIntegrationsThunk } from '../store/DistributorIntegrationSlice.js'

const integrationsCatalog = {
  phinApi: {
    integrationStatus: integrationStatuses.NOT_CONNECTED,
    name: 'Phin API',
    provider: 'Phin',
    integrationType: integrationTypes.PHIN_API,
    id: integrationTypes.PHIN_API,
    description: 'Enable the Phin API. Use the API Client ID and Client Secret to make requests for distributor, partner, and company specific data from an external application.',
    logo: '/logoBlueNoTextSmall.png'
  }
}

export function DistributorIntegrationsPage ({ id }) {
  const history = useHistory()
  const dispatch = useDispatch()

  const { distributorIntegrationsMap, loaders, distributorIdOfLastFetch } = useSelector((state) => state.distributorIntegrations)
  const { isLoadingDistributorIntegrations } = loaders
  const { distributor, loaders: distributorLoaders } = useSelector((state) => state.distributor)
  const { isLoadingDistributor } = distributorLoaders

  const [integrations, setIntegrations] = useState(integrationsCatalog)

  // Fetch the distributor integrations if the distributorIntegrationsMap is null or the distributorIdOfLastFetch is different from the current distributorId
  useEffect(() => {
    if (!distributorIntegrationsMap || distributorIdOfLastFetch !== id) {
      dispatch(getDistributorIntegrationsThunk(id))
    }
  }, [])

  async function validateDistributorIntegrations () {
    const updatedIntegrationsMap = { ...integrations }

    for (const distributorIntegration of Object.values(updatedIntegrationsMap)) {
      if (distributorIntegrationsMap && distributorIntegrationsMap[distributorIntegration.id]) {
        distributorIntegration.integrationStatus = distributorIntegrationsMap[distributorIntegration.id].integrationStatus
      } else {
        distributorIntegration.integrationStatus = integrationStatuses.NOT_CONNECTED
      }
    }

    setIntegrations(updatedIntegrationsMap)
  }

  useEffect(() => {
    validateDistributorIntegrations()
  }, [distributorIntegrationsMap])

  const generateStatus = (integrationStatus, integrationId) => {
    if (integrationStatus === distributorIntegrationStatuses.ENABLED) {
      return <p className='statusBadge connected'>connected</p>
    } else {
      return <p className='statusBadge connect'>connect</p>
    }
  }

  const generateOnClickFunction = (integrationId, integrationType) => {
    return () => history.push(`integrations/${integrationId}`)
  }

  function generateIntegrationCard ({ key, integrationId, name, integrationStatus, integrationType, description, logo }) {
    const onclickFunction = generateOnClickFunction(integrationId, integrationType)
    return (
      <div style={{ cursor: 'pointer' }} key={key} onClick={onclickFunction}>
        <div className='interCardPadding'>
          <div
            id={`${name}-integration-card`}
            aria-label={`${name} Integration Card`}
            className='cardBody'
          >
            <div className='cardContents'>
              <div className='cardInfoQuick'>
                <img src={logo} className='cardLogo' />
                <p className='cardStatus'>{generateStatus(integrationStatus, integrationId)}</p>
              </div>
              <div className='cardTitle'>{name}</div>
              <p className='cardDesc'>{description}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function generateIntegrationsList () {
    return Object.entries(integrations)
    // Filter out integrations that have a feature flag that is not enabled
      .filter(([id, integration]) => !integration.featureFlag || (integration.featureFlag && distributor.featureFlags?.[integration.featureFlag]))
      .map(([id, integration], i) => {
        let { name, integrationStatus, description, logo } = integration

        if (!name) {
          name = 'Unknown Integration'
          integrationStatus = integrationStatuses.CONNECTED
        }

        return (
          generateIntegrationCard({
            key: i,
            name,
            integrationId: integration.id,
            integrationStatus,
            integrationType: integration.integrationType,
            description,
            logo
          })
        )
      })
  }

  return (
    <div>
      <MDBRow className=''>
        <MDBCol className=''>
          <div className='phin-h2'>Phin Integrations</div>
        </MDBCol>
      </MDBRow>
      <MDBRow className='d-flex justify-content-start align-items-center'>

        {(isLoadingDistributor || !distributor || !distributorIntegrationsMap || isLoadingDistributorIntegrations) && (
          <Spinner message='Loading Distributor Integrations' />
        )}

        {(!isLoadingDistributor && distributor && distributorIntegrationsMap && !isLoadingDistributorIntegrations) && (
          generateIntegrationsList()
        )}
      </MDBRow>
    </div>
  )
}
