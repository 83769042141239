import { createSlice } from '@reduxjs/toolkit'
import { audienceSelectionTypes, navigationRoutes, shareLevels } from '../frontendConsts.js'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'
import { resetAllCampaignFrequencyChoicesAction } from './CampaignFrequencySlice.js'
import { DateTime } from 'luxon'

export const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState: {
    baseTrainingCampaignTopics: [],
    nonBaseTrainingCampaignTopics: [],
    activeCampaigns: null,
    campaignPreviewData: null,
    pastCampaigns: null,
    phishingCampaignPresets: null,
    campaignAudience: null,
    campaignCourses: null,
    loaders: {
      isLoadingActiveCampaigns: false,
      isLoadingPastCampaigns: false,
      isLoadingCreateTrainingCampaign: false,
      isLoadingUpdateTrainingCampaign: false,
      isLoadingCreateContinuousCampaigns: false,
      isLoadingPhishingCampaignPresets: false,
      isLoadingCreatePhishingCampaign: false,
      isLoadingCampaignAudience: false,
      isLoadingCampaignCourses: false,
      setIsLoadingBaseTraining: false,
      setIsLoadingNonBaseTraining: false
    }
  },
  reducers: {
    getActiveCampaigns: (state, action) => {
      state.activeCampaigns = action.payload
    },
    getBaseTrainingCampaignTopics: (state, action) => {
      state.baseTrainingCampaignTopics = action.payload
    },
    getNonBaseTrainingCampaignTopics: (state, action) => {
      state.nonBaseTrainingCampaignTopics = action.payload
    },
    getPastCampaigns: (state, action) => {
      state.pastCampaigns = action.payload
    },
    getPhishingCampaignPresets: (state, action) => {
      state.phishingCampaignPresets = action.payload
    },
    setIsLoadingActiveCampaigns: (state, action) => {
      state.loaders.isLoadingActiveCampaigns = action.payload
    },
    setIsLoadingBaseTraining: (state, action) => {
      state.loaders.setIsLoadingBaseTraining = action.payload
    },
    setIsLoadingNonBaseTraining: (state, action) => {
      state.loaders.setIsLoadingNonBaseTraining = action.payload
    },
    setIsLoadingPastCampaigns: (state, action) => {
      state.loaders.isLoadingPastCampaigns = action.payload
    },
    deleteTrainingCampaign: (state, action) => {
      const updateActiveCampaigns = [...state.activeCampaigns]
      const deletedCampaignIndex = updateActiveCampaigns.findIndex(campaign => campaign.id === action.payload)
      const deletedCampaigns = updateActiveCampaigns.splice(deletedCampaignIndex, 1)
      state.activeCampaigns = updateActiveCampaigns

      const updatedPastCampaigns = [...state.pastCampaigns]
      for (const deletedCampaign of deletedCampaigns) {
        updatedPastCampaigns.push(deletedCampaign)
      }
      state.pastCampaigns = updatedPastCampaigns
    },
    cancelPhishingCampaign: (state, action) => {
      let updateActiveCampaigns = [...state.activeCampaigns]
      const campaign = updateActiveCampaigns.find((item) => item.id === action.payload)
      updateActiveCampaigns = updateActiveCampaigns.filter((item) => item.id !== action.payload)
      state.activeCampaigns = updateActiveCampaigns

      const updatedPastCampaigns = [...state.pastCampaigns]
      updatedPastCampaigns.push(campaign)
      state.pastCampaigns = updatedPastCampaigns
    },
    setIsLoadingCreateTrainingCampaign: (state, action) => {
      state.loaders.isLoadingCreateTrainingCampaign = action.payload
    },
    createTrainingCampaign: (state, action) => {
      const updateActiveCampaigns = [...state.activeCampaigns]
      updateActiveCampaigns.push(action.payload)
      state.activeCampaigns = updateActiveCampaigns
    },
    createOnboardingCampaign: (state, action) => {
      const updateActiveCampaigns = [...state.activeCampaigns]
      updateActiveCampaigns.push(action.payload)
      state.activeCampaigns = updateActiveCampaigns
    },
    setIsLoadingUpdateTrainingCampaign: (state, action) => {
      state.loaders.isLoadingUpdateTrainingCampaign = action.payload
    },
    updateTrainingCampaign: (state, action) => {
      const updateActiveCampaigns = [...state.activeCampaigns]
      const updatedTrainingIndex = updateActiveCampaigns.findIndex(campaign => campaign.id === action.payload.id)
      updateActiveCampaigns[updatedTrainingIndex] = action.payload
      state.activeCampaigns = updateActiveCampaigns
    },
    setIsLoadingCreateContinuousCampaigns: (state, action) => {
      state.loaders.isLoadingCreateContinuousCampaigns = action.payload
    },
    createContinuousCampaigns: (state, action) => {
      const { newPhishingCampaign, newTrainingCampaign } = action.payload
      const updateActiveCampaigns = [...state.activeCampaigns]
      updateActiveCampaigns.push(newPhishingCampaign)
      updateActiveCampaigns.push(newTrainingCampaign)
      state.activeCampaigns = updateActiveCampaigns
    },
    setIsLoadingPhishingCampaignPresets: (state, action) => {
      state.loaders.isLoadingPhishingCampaignPresets = action.payload
    },
    setCampaignPreviewData: (state, action) => {
      state.campaignPreviewData = action.payload
    },
    setCampaignAudience: (state, action) => {
      state.campaignAudience = action.payload
    },
    setIsLoadingCampaignAudience: (state, action) => {
      state.loaders.isLoadingCampaignAudience = action.payload
    },
    setCampaignCourses: (state, action) => {
      state.campaignCourses = action.payload
    },
    setIsLoadingCampaignCourses: (state, action) => {
      state.loaders.isLoadingCampaignCourses = action.payload
    },
    removePhishingCampaignPreset: (state, action) => {
      const presetsTemp = [...state.phishingCampaignPresets]
      const presetsUpdate = presetsTemp.filter((preset) => preset.id !== action.payload)
      state.phishingCampaignPresets = presetsUpdate
    },
    updatePhishingCampaignPresets: (state, action) => {
      const tempPhishingCampaignPresets = [...state.phishingCampaignPresets]
      const { presetId, editedPreset } = action.payload
      tempPhishingCampaignPresets.forEach((preset, i, arr) => {
        if (preset.id === presetId) {
          arr[i] = Object.assign(preset, editedPreset)
        }
      })

      state.phishingCampaignPresets = tempPhishingCampaignPresets
    },
    setPhishingCampaignPresets: (state, action) => {
      state.phishingCampaignPresets = action.payload
    },
    setIsLoadingCreatePhishingCampaign: (state, action) => {
      state.loaders.isLoadingCreatePhishingCampaign = action.payload
    },
    createPhishingCampaign: (state, action) => {
      const updateActiveCampaigns = [...state.activeCampaigns]
      updateActiveCampaigns.push(action.payload)
      state.activeCampaigns = updateActiveCampaigns
    },
    updatePhishingCampaign: (state, action) => {
      const updateActiveCampaigns = [...state.activeCampaigns]
      const updatedPhishingIndex = updateActiveCampaigns.findIndex(campaign => campaign.id === action.payload.campaignId)
      updateActiveCampaigns[updatedPhishingIndex] = action.payload
      state.activeCampaigns = updateActiveCampaigns
    }
  }
})

export const {
  getActiveCampaigns: getActiveCampaignsAction,
  getBaseTrainingCampaignTopics: getBaseTrainingCampaignTopicsAction,
  getNonBaseTrainingCampaignTopics: getNonBaseTrainingCampaignTopicsAction,
  getPastCampaigns: getPastCampaignsAction,
  getPhishingCampaignPresets: getPhishingCampaignPresetsAction,
  setIsLoadingActiveCampaigns: setIsLoadingActiveCampaignsAction,
  setIsLoadingBaseTraining: setIsLoadingBaseTrainingAction,
  setIsLoadingNonBaseTraining: setIsLoadingNonBaseTrainingAction,
  setIsLoadingPastCampaigns: setIsLoadingPastCampaignsAction,
  deleteTrainingCampaign: deleteTrainingCampaignAction,
  cancelPhishingCampaign: cancelPhishingCampaignAction,
  setIsLoadingCreateTrainingCampaign: setIsLoadingCreateTrainingCampaignAction,
  createTrainingCampaign: createTrainingCampaignAction,
  createOnboardingCampaign: createOnboardingCampaignAction,
  setIsLoadingUpdateTrainingCampaign: setIsLoadingUpdateTrainingCampaignAction,
  updateTrainingCampaign: updateTrainingCampaignAction,
  setIsLoadingCreateContinuousCampaigns: setIsLoadingCreateContinuousCampaignsAction,
  createContinuousCampaigns: createContinuousCampaignsAction,
  setIsLoadingPhishingCampaignPresets: setIsLoadingPhishingCampaignPresetsAction,
  removePhishingCampaignPreset: removePhishingCampaignPresetAction,
  updatePhishingCampaignPresets: updatePhishingCampaignPresetsAction,
  setPhishingCampaignPresets: setPhishingCampaignPresetsAction,
  setIsLoadingCreatePhishingCampaign: setIsLoadingCreatePhishingCampaignAction,
  createPhishingCampaign: createPhishingCampaignAction,
  updatePhishingCampaign: updatePhishingCampaignAction,
  setCampaignPreviewData: setCampaignPreviewDataAction,
  setCampaignAudience: setCampaignAudienceAction,
  setIsLoadingCampaignAudience: setIsLoadingCampaignAudienceAction,
  setCampaignCourses: setCampaignCoursesAction,
  setIsLoadingCampaignCourses: setIsLoadingCampaignCoursesAction
} = campaignsSlice.actions

export default campaignsSlice.reducer

export const getActiveCampaignsThunk = (id) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingActiveCampaignsAction(true))
    try {
      const res = await api.get(`/api/companies/${id}/campaigns`)
      if (res.status === 200) {
        const campaigns = await res.json()
        const sortedCampaigns = campaigns.sort((a, b) => (a.startTime > b.startTime) ? 1 : -1)
        dispatch(getActiveCampaignsAction(sortedCampaigns))
      }
    } catch (err) {
      console.error(err)
    }
    dispatch(setIsLoadingActiveCampaignsAction(false))
  }
}

export const getBaseTrainingTopicsForCampaignThunk = (id) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingBaseTrainingAction(true))
    try {
      const res = await api.get(`/api/companies/${id}/campaigns/base-training-topics`)
      if (res.status === 200) {
        const { baseTrainingTopics } = await res.json()
        dispatch(getBaseTrainingCampaignTopicsAction(baseTrainingTopics))
      }
    } catch (err) {
      console.error(err)
    }
    dispatch(setIsLoadingBaseTrainingAction(false))
  }
}

export const getNonBaseTrainingTopicsForCampaignThunk = ({ companyId }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingNonBaseTrainingAction(true))
    try {
      const res = await api.get(`/api/companies/${companyId}/campaigns/nonbase-training-topics`)
      if (res.status === 200) {
        const { nonBaseTrainingTopics } = await res.json()
        dispatch(getNonBaseTrainingCampaignTopicsAction(nonBaseTrainingTopics))
      }
    } catch (err) {
      console.error(err)
    }
    dispatch(setIsLoadingNonBaseTrainingAction(false))
  }
}

export const getActiveCampaignByIdThunk = ({ companyId, campaignId }) => {
  return async (dispatch, getState, api) => {
    try {
      const res = await api.get(`/api/companies/${companyId}/training/campaign/${campaignId}`)
      if (res.status === 200) {
        const campaignData = await res.json()
        dispatch(setCampaignPreviewDataAction(campaignData))
      } else {
        NotificationUtilities.sendErrorMessage('Error getting campaign, please contact Phin if problem persists.')
      }
    } catch (error) {
      console.log(error)
      NotificationUtilities.sendErrorMessage(`error: ${error} while getting campaign, please contact Phin if problem persists.`)
    }
  }
}

// Despite the name, this currently only gets the audience for onboarding campaigns. If this is to be used for getting
// the audience of other campaigns in the future it will need to be updated to account for different types
export const getCampaignAudienceThunk = ({ companyId, campaignId }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingCampaignAudienceAction(true))
    try {
      const res = await api.get(`/api/companies/${companyId}/training/campaign/${campaignId}/audience`)
      if (res.status === 200) {
        const audience = await res.json()
        dispatch(setCampaignAudienceAction(audience))
      } else {
        NotificationUtilities.sendErrorMessage('Error getting campaign audience, please contact Phin if problem persists.')
      }
    } catch (error) {
      console.log(error)
      NotificationUtilities.sendErrorMessage(`error: ${error} while getting campaign audience, please contact Phin if problem persists.`)
    }
    dispatch(setIsLoadingCampaignAudienceAction(false))
  }
}

export const getCampaignCoursesWithTotalEnrolledThunk = ({ companyId, campaignId }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingCampaignCoursesAction(true))
    try {
      const res = await api.get(`/api/companies/${companyId}/training/campaign/${campaignId}/courses`)
      if (res.status === 200) {
        const courses = await res.json()
        dispatch(setCampaignCoursesAction(courses))
      } else {
        NotificationUtilities.sendErrorMessage('Error getting campaign courses, please contact Phin if problem persists.')
      }
    } catch (error) {
      console.log(error)
      NotificationUtilities.sendErrorMessage(`error: ${error} while getting campaign courses, please contact Phin if problem persists.`)
    }
    dispatch(setIsLoadingCampaignCoursesAction(false))
  }
}

export const getPastCampaignsThunk = (id) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingPastCampaignsAction(true))
    try {
      const res = await api.get(`/api/companies/${id}/campaigns?type=pastCampaigns`)
      if (res.status === 200) {
        const campaigns = await res.json()
        const sortedCampaigns = campaigns.sort((a, b) => (a.startTime < b.startTime) ? 1 : -1)
        dispatch(getPastCampaignsAction(sortedCampaigns))
      }
    } catch (err) {
      console.error(err)
    }
    dispatch(setIsLoadingPastCampaignsAction(false))
  }
}

export const deleteTrainingCampaignThunk = ({ id, campaignId }) => {
  return async (dispatch, getState, api) => {
    NotificationUtilities.sendInfoMessage('Cancelling Campaign.')
    try {
      const res = await api.delete(`/api/companies/${id}/training/campaign/${campaignId}`)
      if (res.status === 200) {
        await dispatch(deleteTrainingCampaignAction(campaignId))
        NotificationUtilities.sendSuccessMessage('Campaign Cancelled.')
        return true
      } else {
        NotificationUtilities.sendErrorMessage('Error Cancelling Campaign, please contact Phin if problem persists.')
      }
    } catch (error) {
      console.log(error)
      NotificationUtilities.sendErrorMessage('Error Cancelling Course, please contact Phin if problem persists.')
    }
    return false
  }
}

export const getNextCampaignScheduleThunk = ({ companyId, campaignFrequency, startDate, count = 5 }) => {
  return async (dispatch, getState, api) => {
    try {
      const res = await api.post(`/api/companies/${companyId}/campaigns/schedule/next?count=${count}`, { campaignFrequency, startDate })
      if (res.status !== 200) {
        NotificationUtilities.sendErrorMessage('Failed to calculate campaign schedule!')
      } else {
        const body = await res.json()

        // Convert back to a DateTime from the ISO
        const nextFireDateTime = DateTime.fromISO(body.nextFireDateTime)
        const nextFireDateTimes = []

        for (const isoDate of body.nextFireDateTimes) {
          nextFireDateTimes.push(DateTime.fromISO(isoDate))
        }

        return { nextFireDateTime, nextFireDateTimes }
      }
    } catch (err) {
      console.error(err)

      NotificationUtilities.sendErrorMessage('Failed to calculate campaign schedule!')
    }
  }
}

export const cancelPhishingCampaignThunk = ({ companyId, cancelCampaignId }) => {
  return async (dispatch, getState, api) => {
    NotificationUtilities.sendInfoMessage('Cancelling campaign...')

    try {
      const res = await api.delete(`/api/companies/${companyId}/campaigns/${cancelCampaignId}`)
      if (res.status !== 200) {
        NotificationUtilities.sendErrorMessage('Failed to cancel campaign!')

        return false
      } else {
        dispatch(cancelPhishingCampaignAction(cancelCampaignId))

        NotificationUtilities.sendSuccessMessage('Campaign successfully cancelled!')

        return cancelCampaignId
      }
    } catch (err) {
      console.error(err)

      NotificationUtilities.sendErrorMessage('Failed to cancel campaign!')

      return false
    }
  }
}

export const createOnboardingCampaignThunk = ({ companyId, trainingCampaign, history }) => {
  const onboardingCampaign = trainingCampaign
  delete trainingCampaign.startTime
  delete trainingCampaign.endTime

  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingCreateTrainingCampaignAction(true))

    NotificationUtilities.sendInfoMessage('Creating onboarding Campaign.')
    try {
      const response = await api.post(`/api/companies/${companyId}/training/onboardingCampaign`, { onboardingCampaign })
      if (response.status === 200) {
        const newOnboardingCampaign = await response.json()
        setTimeout(() => {
          if (onboardingCampaign.audienceSelectionType === audienceSelectionTypes.FIXED) {
            NotificationUtilities.sendSuccessMessage('Onboarding Campaign Created. It takes a moment for enrollment to send, please check back in a few minutes.')
          } else {
            NotificationUtilities.sendSuccessMessage('Onboarding Campaign Created.')
          }
        }, 0)
        await dispatch(createOnboardingCampaignAction(newOnboardingCampaign))
        dispatch(resetAllCampaignFrequencyChoicesAction())
        history.push(`/companies/${companyId}/training`)
      } else {
        NotificationUtilities.sendErrorMessage('Failed to create onboarding campaign.')

        dispatch(setIsLoadingCreateTrainingCampaignAction(false))
        return { success: false, response }
      }
    } catch (err) {
      NotificationUtilities.sendErrorMessage('Failed to create onboarding campaign.')

      dispatch(setIsLoadingCreateTrainingCampaignAction(false))
      return { success: false, reason: err.message }
    }
    dispatch(setIsLoadingCreateTrainingCampaignAction(false))
  }
}

export const createTrainingCampaignThunk = ({ companyId, trainingCampaign, history }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingCreateTrainingCampaignAction(true))
    setTimeout(() => {
      NotificationUtilities.sendInfoMessage('Creating Training Campaign.')
    }, 0)
    try {
      const campaignFrequency = getState().campaignFrequency
      const campaignFrequencyPayload = {
        iterations: campaignFrequency.iterations.value,
        frequency: campaignFrequency.frequency.value,
        week: campaignFrequency.week.map(week => +week.value),
        weekday: +campaignFrequency.weekday.value,
        time: campaignFrequency.time
      }

      if (campaignFrequency.frequency.value === 'Quarter') {
        campaignFrequencyPayload.month = +campaignFrequency.month.value
      }

      const response = await api.post(`/api/companies/${companyId}/training/campaign`, { trainingCampaign: { ...trainingCampaign, campaignFrequency: campaignFrequencyPayload } })
      if (response.status === 200) {
        const newTrainingCampaign = await response.json()
        setTimeout(() => {
          NotificationUtilities.sendSuccessMessage('Training Campaign Created.')
        }, 0)
        await dispatch(createTrainingCampaignAction(newTrainingCampaign))
        dispatch(resetAllCampaignFrequencyChoicesAction())
        history.push(`/companies/${companyId}/training`)
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to start Training Campaign.')
        }, 0)
      }
    } catch (err) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to start Training Campaign.')
      }, 0)
    }
    dispatch(setIsLoadingCreateTrainingCampaignAction(false))
  }
}

export const updateTrainingCampaignThunk = ({ companyId, campaignId, trainingCampaign, history }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingUpdateTrainingCampaignAction)
    setTimeout(() => {
      NotificationUtilities.sendInfoMessage('Updating Training Campaign.')
    }, 0)
    try {
      const campaignFrequency = getState().campaignFrequency
      const campaignFrequencyPayload = {
        iterations: campaignFrequency.iterations.value,
        frequency: campaignFrequency.frequency.value,
        week: campaignFrequency.week.map(week => +week.value),
        weekday: +campaignFrequency.weekday.value,
        time: campaignFrequency.time
      }

      if (campaignFrequency.frequency.value === 'Quarter') {
        campaignFrequencyPayload.month = +campaignFrequency.month.value
      }

      const res = await api.put(`/api/companies/${companyId}/training/campaign/${campaignId}`, { trainingCampaign: { ...trainingCampaign, campaignFrequency: campaignFrequencyPayload } })
      if (res.status === 200) {
        const updatedTrainingCampaign = await res.json()
        dispatch(updateTrainingCampaignAction({ ...updatedTrainingCampaign, campaignId, id: campaignId }))
        dispatch(resetAllCampaignFrequencyChoicesAction())

        setTimeout(() => {
          NotificationUtilities.sendSuccessMessage('Training Campaign updated.')
        }, 0)

        history.push(`/companies/${companyId}/training`)
      } else if (res.status === 400) {
        const statusObj = await res.json()
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage(statusObj.error)
        }, 0)
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to update Training Campaign.')
        }, 0)
      }
    } catch (err) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to update Training Campaign.')
      }, 0)
    }
  }
}

export const updateOnboardingCampaignThunk = ({ companyId, campaignId, trainingCampaign, history }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingUpdateTrainingCampaignAction)
    setTimeout(() => {
      NotificationUtilities.sendInfoMessage('Updating Onboarding Campaign.')
    }, 0)
    try {
      const onboardingCampaign = trainingCampaign

      const res = await api.put(`/api/companies/${companyId}/training/onboardingCampaign/${campaignId}`, { onboardingCampaign })
      if (res.status === 200) {
        const updatedOnboardingCampaign = await res.json()

        dispatch(updateTrainingCampaignAction({ ...updatedOnboardingCampaign, campaignId, id: campaignId }))
        dispatch(resetAllCampaignFrequencyChoicesAction())

        setTimeout(() => {
          if (onboardingCampaign.audienceChangeSets) {
            NotificationUtilities.sendSuccessMessage('Onboarding Campaign Updated. It takes a moment for enrollment to send, please check back in a few minutes.')
          } else {
            NotificationUtilities.sendSuccessMessage('Onboarding Campaign Updated.')
          }
        }, 0)

        history.push(`/companies/${companyId}/training`)
      } else if (res.status === 400) {
        const statusObj = await res.json()
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage(statusObj.error)
        }, 0)
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to update Onboarding Campaign.')
        }, 0)
      }
    } catch (err) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to update Onboarding Campaign.')
      }, 0)
    }
  }
}

export const enrollUsersInOnboardingCampaignThunk = ({ companyId, campaignId, userIdsList, usersList }) => {
  return async (dispatch, getState, api) => {
    try {
      const res = await api.post(`/api/companies/${companyId}/training/onboardingCampaign/${campaignId}/enroll-user-in-onboarding`, { userIdsList })

      if (res.status === 200) {
        const { userIdsList: results } = await res.json()

        // Dispatch action to update the Campaign audience with which users have been added here
        const audience = getState().campaigns.campaignAudience.users.map((user) => user)

        await dispatch(setCampaignAudienceAction({ users: [...usersList, ...audience] }))

        NotificationUtilities.sendSuccessMessage(`Enrolling  ${results.length} user(s) in Onboarding Campaign. It takes a moment for enrollment to send, please check back in a few minutes.`)

        return true
      } else {
        NotificationUtilities.sendErrorMessage('Failed to enroll users in Onboarding Campaign. Please try again or contact Phin Support if issue persists.')
      }
    } catch (error) {
      NotificationUtilities.sendErrorMessage('Failed to enroll users in Onboarding Campaign. Please try again or contact Phin Support if issue persists.')
    }

    return false
  }
}

export const createContinuousCampaignsThunk = ({ companyId, questionsCollection, trainingCampaign, phishingCampaign, history }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingCreateContinuousCampaignsAction(true))
    try {
      const phishingCampaignFrequency = getState().campaignFrequency
      const trainingCampaignFrequency = getState().trainingCampaignFrequency
      const phishingCampaignFrequencyPayload = {
        iterations: phishingCampaignFrequency.iterations.value,
        frequency: phishingCampaignFrequency.frequency.value,
        week: phishingCampaignFrequency.week.map(week => +week.value),
        weekday: +phishingCampaignFrequency.weekday.value,
        time: phishingCampaignFrequency.time
      }

      if (phishingCampaignFrequency.frequency.value === 'Quarter') {
        phishingCampaignFrequencyPayload.month = +phishingCampaignFrequency.month.value
      }

      delete phishingCampaign.sendInterval
      phishingCampaign.campaignFrequency = phishingCampaignFrequencyPayload

      const trainingCampaignFrequencyPayload = {
        iterations: trainingCampaignFrequency.iterations.value,
        frequency: trainingCampaignFrequency.frequency.value,
        week: trainingCampaignFrequency.week.map(week => +week.value),
        weekday: +trainingCampaignFrequency.weekday.value,
        time: trainingCampaignFrequency.time
      }

      if (trainingCampaignFrequencyPayload.frequency === 'Quarter') {
        trainingCampaignFrequencyPayload.month = +trainingCampaignFrequency.month.value
      }

      trainingCampaign.campaignFrequency = trainingCampaignFrequencyPayload
      const res = await api.post(`/api/companies/${companyId}/config/results`, { configResults: { questionsCollection }, trainingCampaign, phishingCampaign })
      if (res.status === 200) {
        const { newTrainingCampaign, newPhishingCampaign } = await res.json()
        dispatch(createContinuousCampaignsAction({ newPhishingCampaign, newTrainingCampaign }))
        dispatch(resetAllCampaignFrequencyChoicesAction())
        history.push(`/companies/${companyId}`)
        setTimeout(() => {
          NotificationUtilities.sendSuccessMessage('Successfully submitted')
        }, 0)
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('There was an issue submitting results. If the problem persists please reachout to phin support')
        })
      }
    } catch (err) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('There was an issue submitting results. If the problem persists please reachout to phin support')
      })
    }
    dispatch(setIsLoadingCreateContinuousCampaignsAction(false))
  }
}

export const getPhishingCampaignPresetsThunk = (id) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingPhishingCampaignPresetsAction(true))
    try {
      const res = await api.get(`/api/companies/${id}/campaign-presets`)
      if (res.status === 200) {
        const campaignPresets = await res.json()
        dispatch(getPhishingCampaignPresetsAction(campaignPresets))
      }
    } catch (err) {
      console.error(err)
    }
    dispatch(setIsLoadingPhishingCampaignPresetsAction(false))
  }
}

export const createPhishingCampaignThunk = ({ companyId, selectedCampaign, history }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingCreatePhishingCampaignAction(true))
    try {
      NotificationUtilities.sendInfoMessage('Launching campaign...')

      const campaignFrequency = getState().campaignFrequency
      const campaignFrequencyPayload = {
        iterations: campaignFrequency.iterations.value,
        frequency: campaignFrequency.frequency.value,
        week: campaignFrequency.week.map(week => +week.value),
        weekday: +campaignFrequency.weekday.value,
        time: campaignFrequency.time
      }

      if (campaignFrequency.frequency.value === 'Quarter') {
        campaignFrequencyPayload.month = +campaignFrequency.month.value
      }

      const res = await api.post(`/api/companies/${companyId}/campaigns`, { ...selectedCampaign, campaignFrequency: campaignFrequencyPayload })

      if (!res.ok) {
        NotificationUtilities.sendErrorMessage('Failed to launch campaign!')
      } else {
        const { nextFireTimestamp, id } = await res.json()
        dispatch(createPhishingCampaignAction(
          {
            ...selectedCampaign,
            id,
            nextFireTimestamp,
            shareLevel: shareLevels.CLIENT,
            sendInterval: selectedCampaign.sendInterval,
            campaignFrequency: campaignFrequencyPayload
          }))

        dispatch(resetAllCampaignFrequencyChoicesAction())
        NotificationUtilities.sendSuccessMessage('Successfully launched your campaign!')

        history.push(`/companies/${companyId}${navigationRoutes.CAMPAIGNS}`)
      }
    } catch (err) {
      NotificationUtilities.sendErrorMessage('Failed to launch campaign!')
      console.error(`Failed to launch campaign for client ${companyId}. ${err}`)
    }
    dispatch(setIsLoadingCreatePhishingCampaignAction(false))
  }
}
