import * as React from 'react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'

import Spinner from '../Spinner'
import { capitalizeFirstLetter } from '../../../utils/FormattingUtils'
import { navigationRoutes } from '../../../frontendConsts.js'
import { useHistory } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { isUserSelecting } from '../../../utils/userInterfaceUtils'

function UsersViewTrainingAnalytics ({ companyId }) {
  const history = useHistory()

  const { users, loaders } = useSelector((state) => state.users)
  const { isLoadingUsers } = loaders

  const [userRows, setUserRows] = useState([])

  function viewUserTrainingAnalytics (userId) {
    if (!isUserSelecting(window)) history.push(`/companies/${companyId}${navigationRoutes.ANALYTICS_USERS}/${userId}`, { previousPageTitle: 'Training Analytics' })
  }

  const userColumns = [
    {
      field: 'last',
      headerName: 'Last Name (Click for details)',
      flex: 1,
      renderCell: (params) =>
        <Box sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }} id={`View-${params.row.email}-button`}>
          <Typography sx={{ color: 'var(--phin-blue)', fontWeight: 'bold' }}>{params.row.last}</Typography>
        </Box>
    },
    { field: 'first', headerName: 'First Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 2 },
    { field: 'status', headerName: 'User Status', flex: 1, valueGetter: ({ row }) => capitalizeFirstLetter(row.status), type: 'singleSelect', valueOptions: [{ value: 'Active', label: 'Active' }, { value: 'Deleted', label: 'Deleted' }, { value: 'Slept', label: 'Slept' }] }
  ]

  useEffect(() => {
    const userRowData = []
    if (users !== null) {
      for (const user of users) {
        const userData = { ...user }
        userRowData.push(userData)
      }
      setUserRows(userRowData)
    }
  }, [users])

  return (
    <div>

      {(isLoadingUsers || !users) && (
        <Spinner message='Loading Users' />
      )}

      {(!isLoadingUsers && users) &&
        <div style={{ height: '65vh', width: '100%' }}>
          {/* TODO: Allow users to change the pageSize */}
          <DataGrid
            onRowClick={(params) => { viewUserTrainingAnalytics(params.row.id) }}
            className='DataGrid'
            style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
            rows={userRows}
            columns={userColumns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true }
              }
            }}
            initialState={{
              filter: {
                filterModel: {
                  items: [{
                    columnField: 'status',
                    operatorValue: 'is',
                    value: 'Active'
                  }]
                }
              }
            }}
            disableColumnSelector
            disableDensitySelector
            sx={{
              borderRadius: '8px',
              border: '2px solid var(--phin-light-gray)',
              '.MuiDataGrid-cell:focus': {
                outline: 'none'
              },
              '& .MuiDataGrid-row:hover': {
                cursor: 'pointer'
              }
            }}
          />
        </div>}
    </div>
  )
}

export default UsersViewTrainingAnalytics
