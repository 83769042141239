import React, { useState } from 'react'
import { generateLineGraph, generatePieChart } from '../../../utils/analyticsUtils'
import {
  MDBContainer,
  MDBCol,
  MDBCardBody,
  MDBCard,
  MDBRow,
  MDBIcon
} from 'mdb-react-ui-kit'
import { Tooltip, Grid, IconButton, Slide } from '@mui/material'
import { userStatuses } from '../../../frontendConsts.js'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { DateTime } from 'luxon'
import { IoEyeSharp } from 'react-icons/io5'
import { capitalizeFirstLetter } from '../../../utils/FormattingUtils'

const months = ['January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December']

const monthSorter = (a, b) => {
  const [aMonth, aYear] = a.label.split(' ')
  const [bMonth, bYear] = b.label.split(' ')
  if (aYear !== bYear) {
    return aYear - bYear
  } else {
    return months.indexOf(aMonth) - months.indexOf(bMonth)
  };
}

const calculatePerformanceScore = (employeeData) => {
  const { sends, clicks } = employeeData
  const phishProne = clicks / sends

  return +(1 - phishProne).toFixed(2)
}
const pickTop5 = (object) => {
  const top5 = Object.entries(object).sort((pair1, pair2) => pair2[1] - pair1[1])
  return Object.fromEntries(top5.slice(0, 5))
}

const transformSendRecords = ({ sendRecords }) => {
  const recentRecords = {}
  const phishedRecords = {}
  const lmRecords = {}
  const performanceRecords = {}
  let activeUsers = 0
  let sleptUsers = 0
  let deletedUsers = 0

  sendRecords.forEach(sendRecord => {
    const { first, last, duid, status, clicked, learningMomentFinished, email, phishingReported } = sendRecord

    if (recentRecords[duid] === undefined) {
      performanceRecords[duid] = { first, last, email, status, sends: 0, clicks: 0, phishingReported: 0, lmFinished: 0 }
      recentRecords[duid] = 0

      if (status === userStatuses.ACTIVE) {
        activeUsers++
      } else if (status === userStatuses.SLEPT) {
        sleptUsers++
      } else {
        deletedUsers++
      }
    }
    performanceRecords[duid].sends += 1
    recentRecords[duid] += 1

    if (clicked !== undefined) {
      if (phishedRecords[duid] === undefined) {
        phishedRecords[duid] = 0
      }

      performanceRecords[duid].clicks += 1
      phishedRecords[duid] += 1

      if (learningMomentFinished !== undefined) {
        if (lmRecords[duid] === undefined) {
          lmRecords[duid] = 0
        }
        performanceRecords[duid].lmFinished += 1
        lmRecords[duid] += 1
      }
    }

    if (phishingReported !== undefined) {
      performanceRecords[duid].phishingReported += 1
    }
  })

  const recent = Object.keys(recentRecords).length
  const phished = Object.keys(phishedRecords).length
  const lm = Object.keys(lmRecords).length

  return { recent, activeUsers, sleptUsers, deletedUsers, phished, lm, performanceRecords }
}

const renderSelectionButton = (userId, user, handleIndividualSelection) => {
  return (
    <Grid container alignContent='center' justifyContent='center'>
      <Grid item>
        <IconButton
          aria-label='View Template'
          id={`View-User-Analytics-${user.email}`}
          onClick={() => {
            handleIndividualSelection(userId, user)
          }}
        >
          <IoEyeSharp />
        </IconButton>
      </Grid>
    </Grid>
  )
}

const createUserPerformanceTable = (performers, handleIndividualSelection) => {
  const performanceRows = []
  for (const [userId, employeeData] of Object.entries(performers)) {
    performanceRows.push({
      id: userId,
      ...employeeData,
      score: calculatePerformanceScore(employeeData),
      status: capitalizeFirstLetter(employeeData.status),
      select: renderSelectionButton(userId, employeeData, handleIndividualSelection)
    })
  }

  return performanceRows
}

const createPhishProneData = (sendRecords) => {
  const phishProneRecords = {}
  sendRecords.forEach(sendRecord => {
    const monthYear = new Date(sendRecord.created).toLocaleString('en-us', { month: 'long', year: 'numeric' })
    let recordInfo = phishProneRecords[monthYear]
    const { duid, clicked } = sendRecord
    if (recordInfo === undefined) {
      recordInfo = {
        sends: {},
        clicks: {}
      }
      recordInfo.sends[duid] = 1
      if (clicked !== undefined) { recordInfo.clicks[duid] = 1 }
    } else {
      const sends = recordInfo.sends
      const clicks = recordInfo.clicks
      if (sends[duid] === undefined) {
        sends[duid] = 1
      } else {
        sends[duid] += 1
      }

      if (sendRecord.clicked !== undefined) {
        if (clicks[duid] === undefined) {
          clicks[duid] = 1
        } else {
          clicks[duid] += 1
        }
      }
      recordInfo.sends = sends
      recordInfo.clicks = clicks
    }
    phishProneRecords[monthYear] = recordInfo
  })

  const dataKeys = Object.keys(phishProneRecords)

  let phishProneData = dataKeys.map(monthYear => {
    const sends = Object.keys(phishProneRecords[monthYear].sends).length
    const clicks = Object.keys(phishProneRecords[monthYear].clicks).length
    if (sends === 0) {
      return { label: monthYear, data: 0 }
    } else {
      return { label: monthYear, data: Math.trunc((clicks / sends) * 100) }
    }
  })
  phishProneData = phishProneData.sort(monthSorter)
  return { labels: phishProneData.map(obj => obj.label), data: phishProneData.map(obj => obj.data) }
}

const createPhishProneGraph = (sendRecords) => {
  const graphData = createPhishProneData(sendRecords)
  return generateLineGraph(graphData, 'test')
}

const transformSendRecordsForTemplatePies = (sendRecords) => {
  const mostClickedTemplatesByName = {}
  const mostClickedTemplateTags = {}

  sendRecords.forEach(sendRecord => {
    if (sendRecord.clicked !== undefined) {
      const { templateName, templateDoc } = sendRecord
      const { tags } = templateDoc

      if (templateName in mostClickedTemplatesByName) {
        mostClickedTemplatesByName[templateName] += 1
      } else {
        mostClickedTemplatesByName[templateName] = 1
      }

      tags.forEach(tag => {
        if (tag in mostClickedTemplateTags) {
          mostClickedTemplateTags[tag] += 1
        } else {
          mostClickedTemplateTags[tag] = 1
        }
      })
    }
  })

  return { mostClickedTemplatesByName, mostClickedTemplateTags }
}

const renderTemplatePies = (sendRecords) => {
  const { mostClickedTemplatesByName, mostClickedTemplateTags } = transformSendRecordsForTemplatePies(sendRecords)

  const clickedByName = pickTop5(mostClickedTemplatesByName)
  const clickedByTag = pickTop5(mostClickedTemplateTags)

  const clickedByNamePie = generatePieChart(clickedByName, 'Most clicked templates by name')
  const clickedByTagPie = generatePieChart(clickedByTag, 'Most clicked template tags')
  return { clickedByNamePie, clickedByTagPie }
}

const PhishingStat = ({ label, statistic, tooltipText }) => {
  return (
    <Tooltip
      placement='top'
      material
      domElement
      className='mr-2 ml-2'
      title={tooltipText}
    >
      <MDBCol>
        <p className='dark-grey-text'><MDBIcon icon='users' /> {label}</p>
        <h4 className='font-weight-bold dark-grey-text'>{statistic}</h4>
      </MDBCol>
      <div />
    </Tooltip>
  )
}

const renderBanner = (recent, activeUsers, sleptUsers, deletedUsers, phished, lm) => {
  const phishProne = (recent === 0) ? 0 : Math.trunc((phished / recent) * 100)
  const engaged = (phished === 0) ? 0 : Math.trunc((lm / phished) * 100)

  return (
    <MDBCard className='hud-stats'>
      <MDBRow className='text-center d-flex justify-content-around my-4'>
        <PhishingStat label='Unique Users Assessed' statistic={recent} tooltipText='The number of users who have been sent a phishing simulation message for assessment.' />
        <PhishingStat label='Phish Percentage' statistic={`${phishProne}%`} tooltipText="The percentage of people who have been phished by Phin's simulated phishing at least once." />
        <PhishingStat label='Training Rate' statistic={`${engaged}%`} tooltipText='The percentage of people who have fallen for a phishing simulation message and then completed the Learning Moment.' />
      </MDBRow>

      <MDBRow className='text-center d-flex justify-content-around my-4'>
        <PhishingStat label='Active Users' statistic={activeUsers} tooltipText='The number of active users who have been sent a phishing simulation message for assessment.' />
        <PhishingStat label='Slept Users' statistic={sleptUsers} tooltipText='The number of slept users who have been sent a phishing simulation message for assessment.' />
        <PhishingStat label='Deleted Users' statistic={deletedUsers} tooltipText='The number of deleted users who have been sent a phishing simulation message for assessment.' />
      </MDBRow>
    </MDBCard>
  )
}

function Analytics ({ sendRecords, handleIndividualSelection }) {
  const { recent, activeUsers, sleptUsers, deletedUsers, phished, lm, performanceRecords } = transformSendRecords({ sendRecords })

  const phishingPerfomanceColumns = [
    { field: 'first', headerName: 'First Name', minWidth: 100, flex: 0.5 },
    { field: 'last', headerName: 'Last Name', minWidth: 100, flex: 0.5 },
    { field: 'email', headerName: 'Email', minWidth: 100, flex: 1 },
    { field: 'status', headerName: 'Status', minWidth: 100, flex: 0.5, valueGetter: ({ row }) => capitalizeFirstLetter(row.status), type: 'singleSelect', valueOptions: [{ value: 'Active', label: 'Active' }, { value: 'Deleted', label: 'Deleted' }, { value: 'Slept', label: 'Slept' }] },
    { field: 'sends', headerName: 'Sends', minWidth: 100, flex: 0.25 },
    { field: 'clicks', headerName: 'Clicks', minWidth: 100, flex: 0.25 },
    { field: 'phishingReported', headerName: 'Reported', minWidth: 100, flex: 0.25 },
    { field: 'lmFinished', headerName: 'Learning Moments Completed', minWidth: 100, flex: 0.5 },
    { field: 'score', headerName: 'Performance Score', minWidth: 100, flex: 0.5 },
    { field: 'select', headerName: 'User Analytics', minWidth: 100, flex: 0.5, disableColumnMenu: true, sortable: false, filterable: false, renderCell: ({ row }) => row.select }
  ]

  const phishProneGraph = createPhishProneGraph(sendRecords)
  const banner = renderBanner(recent, activeUsers, sleptUsers, deletedUsers, phished, lm)
  const { clickedByNamePie, clickedByTagPie } = renderTemplatePies(sendRecords)
  const formattedPerformers = createUserPerformanceTable(performanceRecords, handleIndividualSelection)
  const [filterModel, setFilterModel] = useState({
    items: [{
      columnField: 'status',
      operatorValue: 'is',
      value: 'Active'
    }]
  })
  return (
    <MDBContainer>
      <Slide direction='up' in mountOnEnter timeout={750}>
        <Grid>

          <MDBRow className='d-flex mb-4 justify-content-center'>
            <MDBCol size='12'>
              {banner}
            </MDBCol>

          </MDBRow>

          <MDBRow className='d-flex mb-4 justify-content-center'>
            <MDBCol size='12'>
              <MDBCard className='d-flex justify-content-center h-100'>
                <MDBCardBody>
                  {phishProneGraph}
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>

          <MDBRow className='d-flex mb-4 justify-content-center'>
            <MDBCol size='6'>
              <MDBCard className='d-flex justify-content-center'>
                <MDBCardBody className='p-1'>
                  {clickedByNamePie}
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol size='6'>
              <MDBCard className='d-flex justify-content-center'>
                <MDBCardBody className='p-1'>
                  {clickedByTagPie}
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>

          <div style={{ height: '65vh', width: '100%' }}>
            <DataGrid
              style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
              className='DataGrid'
              rows={formattedPerformers}
              columns={phishingPerfomanceColumns}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                  csvOptions: {
                    fields: ['first', 'last', 'email', 'status', 'sends', 'clicks', 'phishingReported', 'lmFinished', 'score'],
                    fileName: `Phishing Analytics ${DateTime.now().toLocaleString()}`
                  },
                  printOptions: { disableToolbarButton: true }
                }
              }}
              // We can't use initial state here because the table doesn't render until after the initial state is set
              // Instead we just set up a filter model that will always be applied and let the onChange handler update
              // that state
              filterModel={filterModel}
              onFilterModelChange={(model) => setFilterModel(model)}
              disableColumnSelector
              disableDensitySelector
              disableSelectionOnClick
            />
          </div>
        </Grid>
      </Slide>
    </MDBContainer>
  )
}

const CompanyAnalytics = ({ sendRecords, handleIndividualSelection, companyId }) => {
  if (sendRecords.length === 0) {
    return (
      <Slide direction='up' in mountOnEnter timeout={750}>
        <Grid>
          <MDBRow className='d-flex mb-4 justify-content-center'>
            <MDBCol size='12'>
              <MDBCard className='hud-stats'>
                <MDBRow className='text-center d-flex justify-content-center m-4'>
                  <h3>
                    Hey! Based on this criteria, we didn't find any data!
                    <br />
                    <br />
                    If you think this is a mistake, please let us know
                  </h3>
                </MDBRow>
              </MDBCard>
            </MDBCol>

          </MDBRow>
        </Grid>
      </Slide>
    )
  } else {
    return (
      <Analytics
        sendRecords={sendRecords}
        handleIndividualSelection={handleIndividualSelection}
      />
    )
  }
}

export default CompanyAnalytics
