import { createSlice } from '@reduxjs/toolkit'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'
import { setSideNavOrgDataAction } from './SideNavSlice'
import { setTopNavDistributorDataAction } from './TopNavSlice'
import { orgTypes } from '../frontendConsts.js'
import { getAuthorizationSilentlyThunk } from './AuthSlice'
import { updateHubSpotPartnerErrorMessageAction, updateHubSpotPartnerNameAction, updateIsValidHubSpotPartnerAction, updateMinimumBillableUsersAction, updatePricePerUserOptionAction } from './HubSpotPartnersSlice'

export const distributorSlice = createSlice({
  name: 'distributor',
  initialState: {
    distributor: null,
    accessibleDistributors: null,
    loaders: {
      isLoadingDistributor: false,
      isLoadingAccessibleDistributors: false,
      isLoadingAddPartner: false,
      isLoadingCreateAdmin: false
    }
  },
  reducers: {
    getDistributor: (state, action) => {
      state.distributor = action.payload
      state.loaders.isLoadingDistributor = false
    },
    setAccessibleDistributors: (state, action) => {
      state.accessibleDistributors = action.payload
    },
    addPartner: (state, action) => {
      const updatedDistributor = { ...state.distributor }
      updatedDistributor.partners.push(action.payload)
      updatedDistributor.partnersMap[action.payload.id] = action.payload
      state.distributor = updatedDistributor
      state.loaders.isLoadingAddPartner = false
    },
    setIsLoadingDistributor: (state, action) => {
      state.loaders.isLoadingDistributor = action.payload
    },
    setIsLoadingAccessibleDistributors: (state, action) => {
      state.loaders.isLoadingAccessibleDistributors = action.payload
    },
    setIsLoadingAddPartner: (state, action) => {
      state.loaders.isLoadingAddPartner = action.payload
    },
    setIsLoadingCreateAdmin: (state, action) => {
      state.loaders.isLoadingCreateAdmin = action.payload
    },
    createAdmin: (state, action) => {
      const updatedDistributor = { ...state.distributor }
      updatedDistributor.admins.push(action.payload)
      state.distributor = updatedDistributor
      state.loaders.isLoadingCreateAdmin = false
    },
    deleteAdmin: (state, action) => {
      const updatedDistributor = { ...state.distributor }
      const updateAdmins = updatedDistributor.admins.filter(({ uid: adminUid }) => adminUid !== action.payload)
      updatedDistributor.admins = updateAdmins
      state.distributor = updatedDistributor
    },
    updatePartnerInDistributor: (state, action) => {
      if (state.distributor) {
        const updatedDistributor = { ...state.distributor }

        updatedDistributor.partners = updatedDistributor.partners.map((partner) => {
          if (partner.id === action.payload.partnerId) {
            partner.name = action.payload.partnerName
          }
          return partner
        })

        state.distributor = updatedDistributor
      }
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  getDistributor: getDistributorAction,
  addPartner: addPartnerAction,
  setIsLoadingDistributor: setIsLoadingDistributorAction,
  setAccessibleDistributors: setAccessibleDistributorsAction,
  setIsLoadingAccessibleDistributors: setIsLoadingAccessibleDistributorsAction,
  setIsLoadingAddPartner: setIsLoadingAddPartnerAction,
  setIsLoadingCreateAdmin: setIsLoadingCreateAdminAction,
  createAdmin: createAdminAction,
  deleteAdmin: deleteAdminAction,
  updatePartnerInDistributor: updatePartnerInDistributorAction
} = distributorSlice.actions

export default distributorSlice.reducer

export const getDistributorThunk = (id) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingDistributorAction(true))
    try {
      const res = await api.get(`/api/distributors/${id}`)
      const distributor = await res.json()
      dispatch(getDistributorAction(distributor))
      dispatch(setSideNavOrgDataAction({ id, name: distributor.name, isFreeTrial: false, orgType: orgTypes.DISTRIBUTOR }))
      dispatch(setTopNavDistributorDataAction({ id, name: distributor.name, orgType: orgTypes.DISTRIBUTOR }))
    } catch (err) {
      console.error(`unable to fetch Accessible distributor, error: ${err.stack}`)
    }
  }
}

export const getAccessibleDistributorsThunk = () => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingAccessibleDistributorsAction(true))
    try {
      const res = await api.get('/api/distributors/')
      const distributors = await res.json()
      dispatch(setAccessibleDistributorsAction(distributors))
      dispatch(setIsLoadingAccessibleDistributorsAction(false))
    } catch (err) {
      console.error(`unable to fetch distributors list, error: ${err.stack}`)
    }
  }
}

/**
 * Creates a new partner and admin.
 */
export const addPartnerThunk = ({
  id, adminFirst, adminLast,
  adminEmail, partnerName, partnerFreeTrial,
  authId, history, hubSpotCompanyId
}) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingAddPartnerAction(true))
    try {
      const res = await api.post(`/api/distributors/${id}/partners`,
        { adminFirst, adminLast, adminEmail, partnerName, partnerFreeTrial, hubSpotCompanyId })

      if (res.status === 200) {
        const { partnerName, partnerId } = await res.json()
        await dispatch(getAuthorizationSilentlyThunk(authId))
        dispatch(addPartnerAction({ name: partnerName, id: partnerId }))
        dispatch(updateHubSpotPartnerNameAction())
        dispatch(updateMinimumBillableUsersAction())
        dispatch(updatePricePerUserOptionAction())
        dispatch(updateHubSpotPartnerErrorMessageAction())
        dispatch(updateIsValidHubSpotPartnerAction(false))
        setTimeout(() => {
          NotificationUtilities.sendSuccessMessage(`Successfully created new partner: ${partnerName}.`)
        }, 0)
        history.push(`/distributors/${id}`)
        return { success: true, status: res.status }
      } else if (res.status === 409) {
        dispatch(setIsLoadingAddPartnerAction(false))
        return { success: false, status: res.status }
      } else {
        dispatch(setIsLoadingAddPartnerAction(false))
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to create partner.')
        }, 0)
        dispatch(updateHubSpotPartnerNameAction())
        dispatch(updateMinimumBillableUsersAction())
        dispatch(updatePricePerUserOptionAction())
        dispatch(updateIsValidHubSpotPartnerAction(false))
        history.push(`/distributors/${id}`)
        return { success: false, status: res.status }
      }
    } catch (err) {
      console.error(err)
      return { success: false, status: 500 }
    }
  }
}

export const createAdminThunk = ({
  adminEmail, adminFirst, adminLast,
  id, distributorName, history
}) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingCreateAdminAction(true))
    const res = await api.post('/api/auth/users', { email: adminEmail, displayName: `${adminFirst} ${adminLast}`, distributors: { [id]: 'admin' }, whiteLabelName: distributorName })
    if (res.status === 200) {
      const { uid } = await res.json()
      dispatch(createAdminAction({ uid, name: `${adminFirst} ${adminLast}`, email: adminEmail }))
      setTimeout(() => {
        NotificationUtilities.sendSuccessMessage('Successfully created admin.')
      })
      history.goBack()
    } else {
      const { error } = await res.json()
      dispatch(setIsLoadingCreateAdminAction(false))
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage(error)
      })
    }
  }
}

export const deleteAdminThunk = ({ id, uid }) => {
  return async (dispatch, getState, api) => {
    const res = await api.delete(`/api/auth/users/${uid}`, { distributors: { [id]: 'admin' } })
    if (res.status === 200) {
      const uid = await res.json()
      await dispatch(deleteAdminAction(uid))
      NotificationUtilities.sendSuccessMessage('Successfully deleted admin.')

      return true
    } else {
      NotificationUtilities.sendErrorMessage('Failed to delete admin.')
    }

    return false
  }
}
