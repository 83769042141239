import { Grid } from '@mui/material'

export const TrainingCourseCard = ({ course }) => {
  return (
    <Grid>
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
        sx={{ marginBottom: 'var(--phin-s0)' }}
      >
        <img
          src={course.thumbnail || 'https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20%28131%29.jpg'}
          style={{ width: '22rem', border: '2px solid grey', borderRadius: '8px' }}
        />
      </Grid>
      <Grid
        container
        direction='column'
        justifyContent='space-between'
        alignItems='start'
        sx={{ margin: 'var(--phin-s-1)' }}
      >
        <div className='phin-h4 margin-bottom:-1'>{course.name}</div>
        <div className='phin-body-text-gray margin-bottom:-1'>Topic: {course.topic ? course.topic : 'N/A'}</div>
        <div className='phin-body-text-gray margin-bottom:-1'>Provider: {course.provider ? course.provider : 'N/A'}</div>
        <div className='phin-body-text-gray margin-bottom:-1'>{course.description}</div>
        <div className='phin-body-text-gray'>Estimated Duration: {course.durationInMinutes ? course.durationInMinutes : '15 minutes'}</div>
      </Grid>
    </Grid>
  )
}
