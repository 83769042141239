import { DateTime } from 'luxon'

export function generateLastMonthBillingDateRange () {
  function getOrdinal (day) {
    if (day > 3 && day < 21) {
      return 'th' // catch 11th, 12th, 13th
    }
    switch (day % 10) {
      case 1: return 'st'
      case 2: return 'nd'
      case 3: return 'rd'
      default: return 'th'
    }
  }

  const now = DateTime.now()
  const previousMonth = now.minus({ months: 1 })

  const startOfMonth = previousMonth.startOf('month')
  const endOfMonth = previousMonth.endOf('month')

  const startDay = startOfMonth.day
  const endDay = endOfMonth.day

  const startString = startOfMonth.toFormat('MMMM d') + getOrdinal(startDay)
  const endString = endOfMonth.toFormat('MMMM d') + getOrdinal(endDay) + endOfMonth.toFormat(' yyyy')

  return `${startString} - ${endString}`
}
