import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SaveCancelButtonsRow } from '../../../shared/components/SaveCancelButtonsRow'
import { changeCompanyNameThunk } from '../../../store/CompanySlice'
import ValidationUtils from '../../../utils/validationUtils'
import NotificationUtilities from '../notifications/notificationUtils'
import { checkIsDistributorAdmin } from '../../../utils/roleManager'

const ChangeCompanyName = ({ id }) => {
  const companyName = useSelector((state) => state.company.company?.name)
  const isCompanyInternal = useSelector((state) => state.company.company?.isInternal)
  const distributorId = useSelector((state) => state.company.company?.distributorId)
  const authState = useSelector((state) => state.auth.authorization)
  const isDistributorAdmin = checkIsDistributorAdmin(authState, distributorId)
  const isInternalDisabled = isCompanyInternal && !isDistributorAdmin

  const [newCompanyName, setNewCompanyName] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const dispatch = useDispatch()

  const onSubmit = async function () {
    if (!ValidationUtils.isNotWhiteSpace(newCompanyName)) {
      NotificationUtilities.sendWarningMessage('Company Name can not be empty')
      return
    }
    try {
      if (submitting) return true
      setSubmitting(true)

      await dispatch(changeCompanyNameThunk(id, newCompanyName))

      setSubmitting(false)
    } catch (err) {
      setSubmitting(false)
    }
  }

  const updateCompanyName = function (updatedCompanyName) {
    setNewCompanyName(updatedCompanyName)
  }

  const resetChanges = function () {
    setNewCompanyName(companyName)
  }

  useEffect(() => {
    setNewCompanyName(companyName)
  }, [companyName])

  return (
    <div className='pageContainer'>
      <div id='logoEditCard'>
        <div className='w-100'>
          <div>
            <p className='phinCardHeader'>Company Name</p>
            <p>The name of your company. This will be used as the name of your company in the Phin portal and in all communications Phin will send.</p>
            {isInternalDisabled && <p style={{ color: 'var(--phin-red)' }}>This is the internal company associated with the partner. The name cannot be edited.</p>}
          </div>
          <input
            className='textInputBox2 w-100 mb-4'
            disabled={isInternalDisabled}
            id='company-name-field'
            aria-label='Company Name Field'
            placeholder='New Company Name'
            type='text'
            value={newCompanyName}
            onChange={(e) => { updateCompanyName(e.target.value) }}
            name='new-company-name'
          />

          {!isInternalDisabled && <SaveCancelButtonsRow
            action={onSubmit}
            actionText='Save Changes'
            actionId='save-company-name-update-button'
            close={() => { resetChanges() }}
            closeText='Discard Changes'
            closeId='cancel-company-name-update-button'
            isSubmitting={submitting}
                                  />}
        </div>
      </div>
    </div>
  )
}

export default ChangeCompanyName
