import { PhinCard } from '../../PhinCard'
import { Accordion, AccordionSummary, Grid, Typography } from '@mui/material'
import { useConnectIntegrationMutation } from '../../../../store/ConnectWiseApiSlice'
import { ConnectWiseIntegrationForm } from './ConnectWiseIntegrationForm'
import { IoCaretDownSharp } from 'react-icons/io5'

export function ConnectWiseIntegrationSetup ({ partnerId }) {
  const [enableCWIntegration, { isLoading: isLoadingEnableIntegration }] = useConnectIntegrationMutation()
  return (
    <Grid container direction='row' spacing={4}>
      <Grid item lg={6} md={12} sm={12} xs={12} mb={4}>
        <PhinCard height='700px' overflowY='scroll' title='ConnectWise Permissions'>
          <Accordion defaultExpanded sx={{ boxShadow: 'none' }}>
            <AccordionSummary
              expandIcon={<IoCaretDownSharp />}
              aria-controls='panel1-content'
              id='panel1-header'
            >
              <Typography sx={{ fontWeight: '500', fontSize: '1.2rem' }}>Billing Integration Permissions</Typography>
            </AccordionSummary>
            <ul>
              <li className='phin-h6'>Companies</li>
              <ul>
                <li>Company Maintenance: Inquire Level (All)</li>
                <p className='margin-left:0'>Phin needs Inquiry Level access for reading the list of Companies from ConnectWise. Phin will store the names and IDs of these Companies.</p>
              </ul>
              <ul>
                <li>Manage Attachments: Add Level(All), Inquire Level (All)</li>
                <p className='margin-left:0'>Phin needs Add and Inquire level access for uploading attachments to the created ticket.</p>
              </ul>
              <li className='phin-h6'>Finance</li>
              <ul>
                <li>Agreements: Inquiry Level</li>
                <p className='margin-left:0'>Phin needs Inquiry Level access for reading the list of Agreements and Additions. Phin will store the names and IDs of Additions and Agreements.</p>
                <li>Agreements: Edit Level</li>
                <p className='margin-left:0'>Phin needs Edit Level access for updating the "Total Quantity" field for the Phin Addition.</p>
              </ul>
            </ul>
          </Accordion>
          <Accordion defaultExpanded sx={{ boxShadow: 'none' }}>
            <AccordionSummary
              expandIcon={<IoCaretDownSharp />}
              aria-controls='panel1-content'
              id='panel1-header'
            >
              <Typography sx={{ fontWeight: '500', fontSize: '1.2rem' }}>Service Desk Integration Permissions</Typography>
            </AccordionSummary>
            <ul>
              <li className='phin-h6'>Companies</li>
              <ul>
                <li>Company Maintenance: Inquire Level (All)</li>
                <p className='margin-left:0'>Phin needs Inquiry Level access for reading the list of Companies from ConnectWise. Phin will store the names and IDs of these Companies.</p>
              </ul>
              <ul>
                <li>Manage Attachments: Add Level(All), Inquire Level (All)</li>
                <p className='margin-left:0'>Phin needs Add and Inquire level access for uploading attachments to the created ticket.</p>
              </ul>
              <li className='phin-h6'>Service Desk</li>
              <ul>
                <li>Service Tickets: Add Level (All), Inquire Level (All)</li>
                <p className='margin-left:0'>Phin needs Inquire and Add level access for creating a ticket.</p>
              </ul>
            </ul>
          </Accordion>
        </PhinCard>
      </Grid>

      <Grid item lg={6} md={12} sm={12} xs={12}>
        <ConnectWiseIntegrationForm
          isLoadingEnableIntegration={isLoadingEnableIntegration}
          enableIntegration={enableCWIntegration}
          partnerId={partnerId}
        />
      </Grid>
    </Grid>
  )
}
