import { AppBar, Grid, Toolbar } from '@mui/material'
import { useEffect, useState } from 'react'
import { IoInformationCircle } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { getPartnerThunk } from '../../store/PartnerSlice'
import { DateTime } from 'luxon'

export const FreeTrialBanner = () => {
  const { authorization } = useSelector((state) => state.auth)
  const { partner } = useSelector((state) => state.partner)
  const { company } = useSelector((state) => state.company)
  const history = useHistory()
  const dispatch = useDispatch()
  const [daysRemaining, setDaysRemaining] = useState(0)

  useEffect(() => {
    if (company &&
      ((authorization?.roles?.partners &&
      Object.keys(authorization.roles.partners).length > 0) ||
      (authorization?.roles?.distributors &&
        Object.keys(authorization.roles.distributors).length > 0))) {
      if (!partner) {
        dispatch(getPartnerThunk(company.partnerId))
      }
    }
  }, [authorization, company])

  useEffect(() => {
    if (partner) {
      const currentDateTime = DateTime.now()

      if (partner.freeTrialEndDate) {
        const freeTrialEndDate = DateTime.fromSeconds(partner.freeTrialEndDate._seconds)
        setDaysRemaining(Math.ceil(freeTrialEndDate.diff(currentDateTime, 'days').toObject().days))
      }
    }
  }, [partner])

  const redirectToPartnerBillingPage = () => {
    if (partner?.id) {
      history.push(`/partners/${partner.id}/billing`)
    } else if (company?.partnerId) {
      history.push(`/partners/${company.partnerId}/billing`)
    }
  }

  return (
    <>
      {(partner?.freeTrial || (!partner && company?.freeTrial)) && (
        <AppBar sx={{ maxHeight: 48, backgroundColor: '#FF975C', color: '#000000', boxShadow: 'none', zIndex: 1201 }}>
          <Toolbar>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='flex-start'
              sx={{ flexGrow: 1, maxHeight: 48 }}
            >
              {partner && (
                <span className='phin-h6 margin-bottom:0'><IoInformationCircle size={24} />
                  {(partner?.freeTrialEndDate)
                    ? (
                      <span>&nbsp;Hey Phriend! You have&nbsp;<b>{daysRemaining}&nbsp;days</b>&nbsp;left in your Free Trial.
                        {(authorization?.roles?.partners && Object.keys(authorization.roles.partners).length > 0) ||
            (authorization?.roles?.distributors && Object.keys(authorization.roles.distributors).length > 0)
                          ? <>&nbsp;Manage your subscription any time&nbsp;<u><a onClick={() => redirectToPartnerBillingPage()}>here</a></u>.</>
                          : <>&nbsp;Contact your primary admin for any subscription questions.</>}
                      </span>
                      )
                    : (
                      <span>&nbsp;Hey Phriend! Finish setting up your account to start your 30 day Free Trial.</span>
                      )}
                </span>
              )}
            </Grid>
          </Toolbar>
        </AppBar>
      )}
    </>
  )
}
