import React from 'react'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'
import './App.css'
import { CompanyRouter } from './routers/CompanyRouter'
import { PartnerRouter } from './routers/PartnerRouter'
import { DistributorRouter } from './routers/DistributorRouter'
import { ExternalIntegrationPage } from './pages/components/integrations/ExternalIntegrationPage'
import Spinner from './pages/components/Spinner'
import { useSelector } from 'react-redux'
import { MuiNotificationWrapper } from './pages/components/notifications/MuiNotificationWrapper'
import { ExternalGoogleUserSyncIntegrationPage } from './pages/components/integrations/ExternalGoogleUserSyncIntegrationPage'
import { PAX8_ACCOUNT_STATUSES, PHIN_DISTRIBUTOR_ID } from './frontendConsts'

function RootRouter () {
  const { authorization, isLoadingAuthorization } = useSelector((state) => state.auth)
  const { partner } = useSelector((state) => state.partner)
  const { company } = useSelector((state) => state.company)
  const location = useLocation()

  const id = window.location.pathname.split('/')[2]

  return (
    <>
      <MuiNotificationWrapper />

      {(!authorization || isLoadingAuthorization) &&
      (<Spinner message='Authorizing' />)}

      {(!isLoadingAuthorization && authorization) && (
        <div className='mt-2'>
          <Switch>
            <main>
              <Route
                exact
                path='/integrations'
                render={() => (<ExternalIntegrationPage />)}
              />
              <Route
                path='/integrations/googleUserSync'
                render={() => (<ExternalGoogleUserSyncIntegrationPage />)}
              />

              <Route
                path='/companies/:id'
                render={() => {
                  // Redirect Trial Suspended Pax8 Partners until they select a plan if they are NOT a distributor admin
                  if ((!authorization.roles.distributors || Object.keys(authorization.roles.distributors).length === 0) &&
                   partner && partner.distributorId === process.env.REACT_APP_PAX8_DISTRIBUTOR_ID &&
                    partner.pax8AccountStatus === PAX8_ACCOUNT_STATUSES.TRIAL_SUSPENDED &&
                     !partner.pax8PlanId && !location.pathname.endsWith('/pax8-pricing')) {
                    return <Redirect to={`/partners/${partner?.id}/pax8-pricing`} />
                  }

                  if (company && authorization?.blockFullPhinAccess &&
                    company.distributorId === PHIN_DISTRIBUTOR_ID && !location.pathname.endsWith('/billing')) {
                    return <Redirect to={`/partners/${company?.partnerId}/billing`} />
                  }

                  // Render the wrapped component normally if the condition is not met
                  return <CompanyRouter id={id} />
                }}
              />
              <Route
                path='/partners/:id'
                render={() => {
                  // Redirect Trial Suspended Pax8 Partners until they select a plan if they are NOT a distributor admin
                  if ((!authorization.roles.distributors || Object.keys(authorization.roles.distributors).length === 0) &&
                   partner && partner.distributorId === process.env.REACT_APP_PAX8_DISTRIBUTOR_ID &&
                    partner.pax8AccountStatus === PAX8_ACCOUNT_STATUSES.TRIAL_SUSPENDED &&
                     !partner.pax8PlanId && !location.pathname.endsWith('/pax8-pricing')) {
                    return <Redirect to={`/partners/${partner?.id}/pax8-pricing`} />
                  }

                  // Redirect to the billing page if on free trial and no credit card
                  if (partner && authorization?.blockFullPhinAccess &&
                    partner.distributorId === PHIN_DISTRIBUTOR_ID && !location.pathname.endsWith('/billing')) {
                    return <Redirect to={`/partners/${partner?.id}/billing`} />
                  }

                  // Render the wrapped component normally if the condition is not met
                  return <PartnerRouter id={id} />
                }}
              />

              <Route
                path='/distributors/:id'
                render={() => (
                  <DistributorRouter
                    id={id}
                  />
                )}
              />
            </main>
          </Switch>
        </div>
      )}
    </>
  )
}

export default RootRouter
