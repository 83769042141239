import React, { useEffect, useRef, useState } from 'react'
import {
  MDBRow,
  MDBCol
} from 'mdb-react-ui-kit'
import {
  checkIfUserTemplateExists, processFromField
} from '../utils/templateUtils.js'
import { apiRequestUtils } from '../utils/apiRequestUtils'

import NotificationUtilities from './components/notifications/notificationUtils'

import Mustache from 'mustache'

import TemplateBuilder from './components/templatesPage/TemplateBuilder'
import SMSPreviewModal from './components/templatesPage/SMSPreviewModal'

import { useDispatch, useSelector } from 'react-redux'

import './css/templates.css'

import { cloneTemplateThunk, uploadTemplateThunk, deleteTemplateThunk, shareTemplateThunk, getInjectedFieldDefaultsThunk } from '../store/TemplatesSlice'
import { FreeTrialLockoutModal } from './partnerPages/components/FreeTrialLockoutModal.js'
import { Button, ButtonGroup, ClickAwayListener, Grid, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Skeleton, Tooltip } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { IoBulb, IoCaretDown, IoCopy, IoEye, IoMail, IoOpen, IoPhonePortrait, IoVideocam, IoShareSocialOutline, IoAddCircleSharp } from 'react-icons/io5'
import { learningTypes } from '../frontendConsts.js'
import PhinBadge from './components/PhinBadge.js'
import PhinModal from './components/PhinModal.js'

function DefaultTemplatesTable ({ previewTemplateFunc, cloneTemplate }) {
  const { defaultTemplates, loaders } = useSelector((state) => state.templates)
  const { isLoadingTemplates } = loaders
  const [defaultTemplatesTablePageSize, setDefaultTemplatesTablePageSize] = useState(10)

  function renderActionButtons (template) {
    return (
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Tooltip
          title='View' placement='top'
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -14]
                  }
                }
              ]
            }
          }}
        >
          <IconButton
            id={`view-default-template-button-${template.id}`}
            cypress-test-id={`view-default-template-button-${template.name}`}
            aria-label='View Default Template Button'
            size='medium'
            onClick={() => previewTemplateFunc(template)}
            color='primary'
          >
            <IoEye />
          </IconButton>
        </Tooltip>
        <Tooltip
          title='Clone' placement='top'
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -14]
                  }
                }
              ]
            }
          }}
        >
          <IconButton
            id={`clone-default-template-button-${template.id}`}
            cypress-test-id={`clone-default-template-button-${template.name}`}
            aria-label='Clone Default Template Button'
            size='medium'
            onClick={() => cloneTemplate(template)}
            color='primary'
          >
            <IoCopy />
          </IconButton>
        </Tooltip>
      </Grid>
    )
  }

  const defaultTemplatesColumns = [
    { field: 'name', headerName: 'Template Name', minWidth: 300, flex: 4 },
    {
      field: 'channel',
      headerName: 'Channel',
      minWidth: 300,
      flex: 1,
      renderCell: (params) => {
        let icon, displayString

        if (params.value === 'email') {
          icon = <IoMail />
          displayString = 'Email'
        } else {
          icon = <IoPhonePortrait />
          displayString = 'Text Message'
        }

        return (
          <PhinBadge
            icon={icon}
            displayString={displayString}
            color='var(--phin-blue)'
          />
        )
      }
    },
    { field: 'tags', headerName: 'Tags', minWidth: 200, flex: 2 },
    { field: 'from', headerName: 'From', minWidth: 300, flex: 2, valueGetter: (params) => processFromField(params.row) },
    { field: 'actions', headerName: 'Actions', minWidth: 125, flex: 1, sortable: false, renderCell: (params) => renderActionButtons(params.row) }
  ]

  return (

    <Grid sx={{ width: '100%', marginBottom: 'var(--phin-s3)' }}>
      <div className='phin-h4 margin-bottom:-2'>Default Templates</div>
      <div className='phin-body-text margin-bottom:-2'>Phishing Templates provided by Phin. These templates can be previewed or cloned and edited.</div>

      {(isLoadingTemplates || !defaultTemplates) && (
        <Skeleton variant='rectangular' width='100%' height='300px' />
      )}

      {(defaultTemplates && !isLoadingTemplates) && (
        <DataGrid
          sx={{
            borderRadius: '8px',
            border: '2px solid var(--phin-light-gray)',
            '.MuiDataGrid-cell:focus': {
              outline: 'none'
            }
          }}
          className='DataGrid'
          initialState={{
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }]
            }
          }}
          autoHeight
          rows={defaultTemplates}
          columns={defaultTemplatesColumns}
          pageSize={defaultTemplatesTablePageSize}
          onPageSizeChange={(newSize) => setDefaultTemplatesTablePageSize(newSize)}
          rowsPerPageOptions={[10, 25, 50]}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: {
                debounceMs: 500,
                id: 'default-phishing-templates-table-search',
                'aria-label': 'Search'
              },
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true }
            }
          }}
          disableSelectionOnClick
          disableColumnSelector
          disableDensitySelector
          disableColumnFilter
          disableColumnMenu
        />
      )}
    </Grid>
  )
}

function UserTemplateActionButtons ({
  template, previewTemplateFunc, cloneTemplate,
  showTemplateShareWarning, masterToggle, selectTemplateForEditing
}) {
  const options = ['Preview', 'Clone', 'Share', 'Delete']
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)

  const handleMenuItemClick = (event, index) => {
    switch (options[index]) {
      case 'Preview':
        previewTemplateFunc(template)
        break
      case 'Clone':
        cloneTemplate(template)
        break
      case 'Share':
        showTemplateShareWarning(template)
        break
      case 'Delete':
        masterToggle('openDelete', template)
        break
    }
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <ButtonGroup
        variant='contained'
        aria-label='Button group for selecting template operation'
      >
        <Button
          id={`edit-template-button-${template.id}`}
          cypress-test-id={`edit-template-button-${template.name}`}
          onClick={() => selectTemplateForEditing(template)}
          aria-label='Edit Template Button'
        >Edit
        </Button>
        <Button
          ref={anchorRef}
          id={`additional-operations-menu-button-${template.id}`}
          cypress-test-id={`additional-operations-menu-button-${template.name}`}
          size='small'
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='Select Other Template Operation Button'
          aria-haspopup='menu'
          onClick={handleToggle}
        >
          <IoCaretDown />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 100000
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement='bottom-start'
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu' autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      disabled={option === 'Share' && template.shared}
                      id={`template-operation-menu-item-${option}-${template.id}`}
                      cypress-test-id={`template-operation-menu-item-${option}-${template.name}`}
                      key={option}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

function UserTemplatesTable ({
  previewTemplateFunc, cloneTemplate, showTemplateShareWarning,
  masterToggle, selectTemplateForEditing
}) {
  const { userTemplates, sharedTemplates, loaders } = useSelector((state) => state.templates)
  const { isLoadingTemplates } = loaders
  const [templatesTablePageSize, setTemplatesTablePageSize] = useState(10)

  const defaultTemplatesColumns = [
    {
      field: 'name',
      headerName: 'Template Name',
      minWidth: 300,
      flex: 2,
      renderCell: (params) =>
        (<div>{params.value}{params.row.shared ? <Tooltip title='Shared'><span className='padding-left:-2'><IoShareSocialOutline /></span></Tooltip> : ''}</div>)
    },
    {
      field: 'channel',
      headerName: 'Channel',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        let icon, displayString

        if (params.value === 'email') {
          icon = <IoMail />
          displayString = 'Email'
        } else {
          icon = <IoPhonePortrait />
          displayString = 'Text Message'
        }

        return (
          <PhinBadge
            icon={icon}
            displayString={displayString}
            color='var(--phin-blue)'
          />
        )
      }
    },
    {
      field: 'learningType',
      headerName: 'Learning',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        let icon, displayString

        if (params.value === learningTypes.LM) {
          displayString = 'Learning Moment'
          icon = <IoBulb />
        } else if (params.value === learningTypes.CUSTOM) {
          displayString = 'Custom Redirect'
          icon = <IoOpen />
        } else if (params.value === learningTypes.VIDEO) {
          displayString = 'Video'
          icon = <IoVideocam />
        } else {
          displayString = 'Learning Moment'
          icon = <IoBulb />
        }

        return (
          <PhinBadge
            icon={icon}
            displayString={displayString}
            color='var(--phin-blue)'
          />
        )
      }
    },
    { field: 'tags', headerName: 'Tags', minWidth: 200, flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 150,
      flex: 1,
      sortable: false,
      renderCell: (params) =>
        <UserTemplateActionButtons
          template={params.row}
          masterToggle={masterToggle}
          cloneTemplate={cloneTemplate}
          previewTemplateFunc={previewTemplateFunc}
          showTemplateShareWarning={showTemplateShareWarning}
          selectTemplateForEditing={selectTemplateForEditing}
        />
    }
  ]

  return (

    <Grid sx={{ width: '100%', marginBottom: 'var(--phin-s3)' }}>
      <div className='phin-h4 margin-bottom:-2'>Your Templates</div>
      <div className='phin-body-text margin-bottom:-2'>Manage Phishing Templates that are available for this company. Edit, preview, clone, share, and delete templates below.</div>

      {(isLoadingTemplates || !userTemplates || !sharedTemplates) && (
        <Skeleton variant='rectangular' width='100%' height='300px' />
      )}

      {(sharedTemplates && userTemplates && !isLoadingTemplates) && (
        <DataGrid
          sx={{
            borderRadius: '8px',
            border: '2px solid var(--phin-light-gray)',
            '.MuiDataGrid-cell:focus': {
              outline: 'none'
            }
          }}
          className='DataGrid'
          initialState={{
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }]
            }
          }}
          autoHeight
          rows={[...userTemplates, ...sharedTemplates]}
          columns={defaultTemplatesColumns}
          pageSize={templatesTablePageSize}
          onPageSizeChange={(newSize) => setTemplatesTablePageSize(newSize)}
          rowsPerPageOptions={[10, 25, 50]}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: {
                debounceMs: 500,
                id: 'default-phishing-templates-table-search',
                'aria-label': 'Search'
              },
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true }
            }
          }}
          disableSelectionOnClick
          disableColumnSelector
          disableDensitySelector
          disableColumnFilter
          disableColumnMenu
        />
      )}
    </Grid>
  )
}

function TemplatesPage ({ id }) {
  const dispatch = useDispatch()
  const { campaignCategories, sendingDomains, injectedFieldDefaults } = useSelector((state) => state.templates)
  const { company } = useSelector((state) => state.company)

  const [editModal, setEditModal] = useState(false)
  const [showTemplateBuilder, setShowTemplateBuilder] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [shareModal, setShareModal] = useState(false)
  const [previewModal, setPreviewModal] = useState(false)
  const [isFreeTrialLockoutModalOpen, setIsFreeTrialLockoutModalOpen] = useState(false)
  const [focusedTemplate, setFocusedTemplate] = useState({})
  const [previewTemplate, setPreviewTemplate] = useState({})
  const [selectedTemplate, setSelectedTemplate] = useState({})

  function masterToggle (modalName, info) {
    switch (modalName) {
      case 'create':
        setShowTemplateBuilder(!showTemplateBuilder)
        setSelectedTemplate({})
        break

      case 'edit':
        setShowTemplateBuilder(!showTemplateBuilder)
        break

      case 'closeEditTemplate':
        setEditModal(!editModal)
        setSelectedTemplate({})
        break

      case 'openDelete':
        setDeleteModal(true)
        setFocusedTemplate(info)
        break

      case 'closeDelete':
        setDeleteModal(false)
        break
      case 'share':
        setShareModal(!shareModal)
        break
      case 'preview':
        setPreviewModal(!previewModal)
        break
      case 'freeTrial':
        setIsFreeTrialLockoutModalOpen(!isFreeTrialLockoutModalOpen)
        break
      default:
        console.log(`No modal with the name ${modalName}`)
    }
  }

  useEffect(() => {
    dispatch(getInjectedFieldDefaultsThunk(id))
  }, [])

  function parseTemplate (text) {
    const injectedFields = []
    try {
      const result = Mustache.parse(text)
        .filter(v => {
          return v[0] === 'name'
        })
        .map(v => {
          return v[1]
        })
      result.forEach((field) => {
        if (field !== 'action_url') {
          injectedFields.push({ name: field, value: '' })
        }
      })
      return injectedFields
    } catch (error) {

    }
  }

  async function uploadTemplate ({ newTemplate, templateId, company }) {
    let endPoint, organizationId
    if (newTemplate.shared === true) {
      endPoint = 'partners'
      organizationId = company.partnerId
    } else {
      endPoint = 'companies'
      organizationId = id
    }
    return dispatch(uploadTemplateThunk(organizationId, { newTemplate, endPoint, templateId }))
  }

  async function editTemplateFunc (template, company) {
    const oldId = template.id
    const deleteResponseStatus = await deleteTemplate(template.id, template.shared, company)
    if (deleteResponseStatus !== 200) {
      NotificationUtilities.sendErrorMessage('Failed to edit your template. Our team has been notified.')
      console.log('Failed to edit template: ', deleteResponseStatus)
      return
    }

    const uploadResponseStatus = await uploadTemplate({ newTemplate: template, templateId: oldId, company })

    if (uploadResponseStatus !== 200) {
      NotificationUtilities.sendErrorMessage('There was an error uploading your template. Our team has been notified.')
      return
    }

    NotificationUtilities.sendSuccessMessage('Template successfully updated')
  }

  async function createTemplate (newTemplateObj, company) {
    NotificationUtilities.sendInfoMessage('Creating Template...')

    const checkForDuplicateResponseStatus = await checkIfUserTemplateExists(id, company.partnerId, newTemplateObj.name, apiRequestUtils)

    if (checkForDuplicateResponseStatus === 200) {
      NotificationUtilities.sendWarningMessage('A template with that name already exists!')
      return
    } else if (checkForDuplicateResponseStatus === 500) {
      masterToggle('create')
      NotificationUtilities.sendErrorMessage('Failed to create template! Our team has been notified.')
      return
    }

    // creating a new template is never shared by default. Hence the empty second param and third param
    const uploadResponseStatus = await uploadTemplate({ newTemplate: newTemplateObj, company })

    if (uploadResponseStatus !== 200) {
      masterToggle('create')
      NotificationUtilities.sendErrorMessage('Error uploading your template! Our team has been notified.')
      return
    }

    NotificationUtilities.sendSuccessMessage('Template successfully created!')
  }

  function cloneTemplate (templateToClone) {
    if (company.freeTrial) {
      masterToggle('freeTrial')
    } else {
      const template = Object.assign({}, templateToClone)
      // clone directly into this companies templates
      template.shared = false
      // ?
      template.id = undefined

      if (!template.injectedFields) {
        try {
          template.injectedFields = parseTemplate(template.html)
        } catch (error) {
          NotificationUtilities.sendWarningMessage('There is an error in the template you are trying to copy')
          return
        }
      }

      template.name = templateToClone.name + ' - Copy'
      dispatch(cloneTemplateThunk(id, template))
    }
  }

  async function templateBuilderCallback (template, shouldEdit = false, company) {
    if (shouldEdit) {
      await editTemplateFunc(template, company)
    } else {
      await createTemplate(template, company)
    }
  }

  function selectTemplateForEditing (template) {
    const templateCopy = Object.assign({}, template)
    setSelectedTemplate(templateCopy)
    masterToggle('edit')
  }

  function previewSMSTemplate (template) {
    setPreviewTemplate(template)
    masterToggle('preview')
  }

  function previewTemplateFunc (template) {
    if (template.channel === 'email') {
      const win = window.open('')
      const cssString = template.css ? `<style>${template.css}</style>` : ''
      win.document.body.innerHTML = template.html + cssString
      win.document.title = template.name
    } else {
      previewSMSTemplate(template)
    }
  }

  async function deleteTemplate (templateId, shared, company) {
    // trigger render manually since we edited the object itself
    const orgId = shared ? company.partnerId : company.id
    const orgType = shared ? 'partners' : 'companies'
    return dispatch(deleteTemplateThunk(templateId, orgType, orgId))
  }

  async function deleteTemplateFromButton (template) {
    try {
      NotificationUtilities.sendInfoMessage('Deleting Template...')
      await deleteTemplate(template.id, template.shared, company)
      NotificationUtilities.sendSuccessMessage('Template successfully deleted!')
    } catch (err) {
      NotificationUtilities.sendErrorMessage('Error while deleting your template! Our team has been notified.')
    }

    masterToggle('closeDelete')
  }

  async function shareTemplate (template) {
    await dispatch(shareTemplateThunk(id, company.partnerId, template))
    masterToggle('share')
  }

  const typeOptions = [
    {
      text: 'Choose a template type',
      value: '',
      disabled: true,
      selected: true
    },
    {
      text: 'Email',
      value: 'email'
    },
    {
      text: 'Text (SMS) Beta',
      value: 'sms'
    }
  ]

  const sendingOptions = [
    {
      text: 'Choose a Sending Domain',
      value: '',
      disabled: true,
      selected: true
    }
  ]

  sendingDomains.forEach((domain) => {
    sendingOptions.push({ text: domain, value: domain })
  })

  const showTemplateShareWarning = (template) => {
    masterToggle('share')
    setFocusedTemplate(template)
  }

  const handleTemplateCreate = () => {
    if (company.freeTrial) {
      masterToggle('freeTrial')
    } else {
      masterToggle('create')
    }
  }

  return (
    <>
      <TemplateBuilder
        sendingDomains={sendingOptions}
        toggle={masterToggle}
        injectedFieldDefaults={injectedFieldDefaults}
        company={company}
        id={id}
        display={showTemplateBuilder}
        categories={campaignCategories}
        templateUpdateCallback={templateBuilderCallback}
        typeOptions={typeOptions}
        editTemplate={selectedTemplate}
      />

      <SMSPreviewModal template={previewTemplate} isOpen={previewModal} toggleModal={(() => masterToggle('preview'))} />

      <PhinModal
        isOpen={shareModal}
        title='Share Template'
        close={() => masterToggle('share')}
        closeText='Cancel'
        action={() => shareTemplate(focusedTemplate)}
        actionText='Share'
      >
        <p>Are you sure you would like to share this template? It will become available to all companies in your Phin tenant.</p>
        <p><strong>This can not be undone!</strong></p>
      </PhinModal>

      {/* Template Deletion modal */}
      <PhinModal
        isOpen={deleteModal}
        title='Delete Template'
        close={() => masterToggle('closeDelete')}
        closeText='Keep Template'
        action={() => deleteTemplateFromButton(focusedTemplate)}
        actionText='Delete Template'
        actionColor='error'
      >
        <>
          {focusedTemplate.shared && (<p>This template is shared with your partner network. Deleting it will delete it for <b> everyone</b>.</p>)}
          <p>Are you sure you would like to delete this template?</p>
        </>
      </PhinModal>

      <FreeTrialLockoutModal
        open={isFreeTrialLockoutModalOpen}
        feature='custom phishing template editing'
        featureIntroTitle='create and modify phishing templates'
        description='Do you have an idea for a phishing template that is not a part of our template library? Use our custom phishing template editor to create your own.'
        KBLink='https://www.phinsec.io/knowledge/how-to-create-your-own-phishing-template'
        closeModal={() => masterToggle('freeTrial')}
      />

      <MDBRow className='d-flex justify-content-center'>
        <MDBCol className='text-center'>
          <h1>Template Manager</h1>
          <p>Add, Remove, and search for your templates here</p>
        </MDBCol>
      </MDBRow>

      <MDBRow className='d-flex justify-content-center'>
        <MDBCol xl='4' md='6' className='mb-5 d-flex justify-content-center'>
          <Button
            variant='contained'
            id='create-phishing-template-button'
            aria-label='Create Phishing Template Button'
            onClick={() => { handleTemplateCreate() }}
            style={company?.freeTrial ? { opacity: '0.6' } : {}}
            color={company?.freeTrial ? 'grey' : 'primary'}
          >
            <MDBRow>
              <MDBCol size='2'>
                <IoAddCircleSharp size='3em' />
              </MDBCol>
              <MDBCol size='10'>
                Create a new template
              </MDBCol>
            </MDBRow>
          </Button>
        </MDBCol>
      </MDBRow>

      <UserTemplatesTable
        previewTemplateFunc={previewTemplateFunc}
        cloneTemplate={cloneTemplate}
        masterToggle={masterToggle}
        showTemplateShareWarning={showTemplateShareWarning}
        selectTemplateForEditing={selectTemplateForEditing}
      />

      <DefaultTemplatesTable
        previewTemplateFunc={previewTemplateFunc}
        cloneTemplate={cloneTemplate}
      />
    </>
  )
}

export default TemplatesPage
