import './curriculumPreview.scss'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { MdOutlineDragIndicator } from 'react-icons/md'

export const ReorderTopicsComponent = ({ trainingTopics, setTrainingTopics, currentModule }) => {
  const reorder = (list, currentIndex, destinationIdex) => {
    const result = Array.from(list)
    const [removed] = result.splice(currentIndex, 1)

    result.splice(destinationIdex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(
      trainingTopics,
      result.source.index,
      result.destination.index
    )

    setTrainingTopics(items)
  }

  function pickBackgroundColor (isDragging) {
    if (isDragging) {
      return 'var(--dark-blue-btn)'
    } else {
      return '#EAF5FF'
    }
  }

  function pickTextColor (isDragging) {
    if (isDragging) {
      return 'white'
    } else {
      return 'black'
    }
  }

  const getItemStyle = ({ isDragging, draggableStyle }) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 8 * 2,
    margin: '0 0 8px 0',

    borderRadius: 8,
    // change background color if dragging
    background: pickBackgroundColor(isDragging),
    color: pickTextColor(isDragging),

    // styles we need to apply on draggables
    ...draggableStyle
  })

  const getListStyle = () => ({
    overflowY: 'scroll',
    height: 450,
    paddingRight: 16,
    paddingLeft: 16,
    paddingBottom: 16
  })

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {(!trainingTopics || (trainingTopics.length < 1)) && (
        <p className='preview-warning-finalize'>no topics selected</p>
      )}
      <Droppable droppableId='curriculum-reorder'>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={getListStyle()}
          >
            {(trainingTopics) && trainingTopics.map((topicName, index) => (
              <Draggable
                className='preview-row'
                key={topicName}
                draggableId={topicName}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle({
                      isDragging: snapshot.isDragging,
                      draggableStyle: provided.draggableProps.style
                    })}
                  >
                    <div className='draggable-row'>
                      <p className='icon-col'><MdOutlineDragIndicator size={24} /></p>
                      <p className='order-num-label order-col'> {index + 1} </p>
                      <p className='data-col phin-flex-space-between-row'>
                        {(topicName.length < 30) ? topicName : `${topicName.slice(0, 28)}...`}
                        {(index === (currentModule % trainingTopics.length)) && (<span style={{ color: 'var(--blue-75)' }} class='phin-h6'>NEXT ASSIGNMENT</span>)}
                      </p>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
