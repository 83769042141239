import React from 'react'
import {
  MDBSpinner
} from 'mdb-react-ui-kit'
import { Button, Tooltip } from '@mui/material'
import { FileDownload } from '@mui/icons-material'

const DataExportButtons = ({ exporting, downloadOrgData }) => {
  let exportButtonContent
  if (exporting) {
    exportButtonContent = (<MDBSpinner multicolor />)
  } else {
    exportButtonContent = (
      <Tooltip
        title='Download CSV based on selected filters'
      >
        <Button
          floating
          size='lg'
          color='primary'
          variant='contained'
          sx={{ borderRadius: 65, height: 65 }}
          onClick={downloadOrgData}
        >
          <FileDownload />
        </Button>
      </Tooltip>
    )
  }
  return (
    <div className='mb-5 mr-5 phin-fixed-bottom-right'>
      {exportButtonContent}
    </div>
  )
}

export default DataExportButtons
