import { createTheme } from '@mui/material'

export const phinTheme = createTheme({
  palette: {
    // This is the main action color.
    // This should be used for buttons, links, and other interactive elements as the primary color
    primary: {
      main: '#39A0FF',
      light: '#BADCFF',
      dark: '#0978DB',
      contrastText: '#fff'
    },
    phinGray: {
      main: '#8C8C8C',
      light: '#D8D8D8',
      dark: '#606060',
      contrastText: '#fff'
    },
    phinBlack: {
      main: '#000000',
      light: '#333333',
      dark: '#000000',
      contrastText: '#fff'
    },
    phinYellow: {
      main: '#FBC030',
      light: '#FFDD80',
      dark: '#D59E0C',
      contrastText: '#fff'
    },
    phinWhite: {
      main: '#FFFFFF',
      light: '#FFFFFF',
      dark: '#FFFFFF',
      contrastText: '#000'
    },
    phinGreen: {
      main: '#3CA03A',
      light: '#D1EDC0',
      dark: '#1B5E20',
      contrastText: '#fff'
    },
    phinSecondary: {
      main: '#433E99',
      light: '#7b78b8',
      dark: '#221F4D',
      contrastText: '#fff'
    },
    phinInvertedSecondary: {
      main: '#fff',
      light: '#fff',
      dark: '#fff',
      contrastText: '#0057B2'
    }
  },
  /* See App.css --phin-breakpoints- for the other place this is maintained. Keep them in sync */
  breakpoints: {
    values: {
      xs: 0,
      sm: 972,
      md: 1364,
      lg: 1624,
      xl: 2324
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px'
        },
        outlined: {
          border: '2px solid',
          '&:hover': {
            border: '2px solid'
          }
        },
        outlinedPrimary: {
          borderColor: 'rgba(57, 160, 255, .5)'
        }
      }
    }
  }
})
