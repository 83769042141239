import React from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export function AllowlistResultAccordion ({ label, output, error }) {
  return (
    <Accordion
      sx={{
        width: '100%',
        backgroundColor: error ? 'rgba(255, 0, 0, 0.1)' : 'rgba(0, 255, 0, 0.1)',
        '&:before': {
          display: 'none'
        }
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='allowlist-result-content'
        id='allowlist-result-header'
      >
        <Typography color={error ? 'error' : 'success'}>
          {label} Results
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box mb={2}>
          <Typography variant='subtitle1' gutterBottom>
            <strong>{label}</strong>
          </Typography>
          {output && (
            <>
              <Typography variant='subtitle2' gutterBottom>
                Output:
              </Typography>
              <Typography variant='body2' component='div'>
                <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{output}</pre>
              </Typography>
            </>
          )}
          {error && (
            <>
              <Typography variant='subtitle2' gutterBottom color='error'>
                Error:
              </Typography>
              <Typography variant='body2' component='div' color='error'>
                <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{error}</pre>
              </Typography>
            </>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}
