import React from 'react'
import { MDBIcon } from 'mdb-react-ui-kit'
import { Button, Tooltip } from '@mui/material'

function ActionButton ({ title, onClick, color = 'default', icon = 'pencil-alt', className = '', id, outline = false }) {
  return (
    <Tooltip
      placement='top'
      title={title}
    >
      <Button
        rounded
        type='submit'
        size='sm'
        color={color}
        variant='contained'
        outline={outline}
        onClick={onClick}
        className={className}
        id={id}
      >
        <MDBIcon
          size='lg'
          icon={icon}
        />
      </Button>
    </Tooltip>
  )
}

export default ActionButton
