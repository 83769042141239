import {
  MDBCol, MDBRow
} from 'mdb-react-ui-kit'
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { PhinChip } from '../../../shared/components/PhinChip.js'
import { IoBusiness, IoGlobeSharp, IoPeopleCircleSharp, IoPerson } from 'react-icons/io5'
import { PhinCard } from '../PhinCard.js'

function AudienceFilters ({
  groups, departmentNames,
  parameters, setParameters, audienceFilterType,
  setAudienceFilterType, supervisorEmails, filterText,
  description, users = null, showHeader = true
}) {
  function generatePresetCustomization () {
    const autoCompleteProps = {
      options: groups,
      getOptionLabel: (option) => option.displayName
    }

    switch (audienceFilterType) {
      case 'group':
        return (
          <>
            <MDBRow className='mt-2'>
              <MDBCol>
                <MDBRow className='d-flex justify-content-center'>
                  {filterText?.group ? filterText.group : 'This filter will include every active user within the specified groups.'}
                </MDBRow>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mt-2'>
              <MDBCol>
                <MDBRow className='d-flex justify-content-center'>
                  <Autocomplete
                    {...autoCompleteProps}
                    disablePortal
                    multiple
                    id='groups-audience-dropdown-autocomplete'
                    sx={{ width: 500 }}
                    value={parameters}
                    onChange={handleGroupChange}
                    autoComplete
                    renderInput={(params) => <TextField {...params} label='Group' />}
                  />
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </>
        )
      case 'first':
        return (
          <>
            <MDBRow className='mt-2'>
              <MDBCol>
                <MDBRow className='d-flex justify-content-center'>
                  {filterText?.all ? filterText.all : 'This filter will include every active user.'}
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </>
        )
      case 'supvemail':
        return (
          <>
            <MDBRow className='mt-2'>
              <MDBCol>
                <MDBRow className='d-flex justify-content-center'>
                  {filterText?.supervisor ? filterText.supervisor : 'This filter will include every active user with the specified supervisor.'}
                </MDBRow>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mt-2'>
              <MDBCol>
                <FormControl style={{ width: '35%', marginTop: '.5rem', marginLeft: '35%' }}>
                  <InputLabel id='supervisor-email'>Supervisor Email</InputLabel>
                  <Select
                    id='supervisor-email-audience-select'
                    labelId='supervisor-email'
                    value={parameters[0].value}
                    label='Supervisor Email'
                    onChange={handleChange}
                  >
                    {supervisorEmails.map((email) => (
                      <MenuItem
                        id={`${email}-supervisor-option`}
                        aria-label={`${email} Supervisor Option`}
                        value={email}
                        key={email}
                      >{email}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </MDBCol>
            </MDBRow>
          </>
        )
      case 'deptname':
        return (
          <>
            <MDBRow className='mt-2'>
              <MDBCol>
                <MDBRow className='d-flex justify-content-center'>
                  {filterText?.department ? filterText.department : 'This filter will include every active user within the specified department.'}
                </MDBRow>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mt-2'>
              <MDBCol>
                <FormControl style={{ width: '35%', marginTop: '.5rem', marginLeft: '35%' }}>
                  <InputLabel id='demo-simple-select-label'>Department Name</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='dept-name-audience-select'
                    value={parameters[0].value}
                    label='Department Name'
                    onChange={handleChange}
                  >
                    {departmentNames.map((deptName) => (
                      <MenuItem
                        id={`${deptName}-department-option`}
                        aria-label={`${deptName} Department Option`}
                        value={deptName}
                        key={deptName}
                      >{deptName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </MDBCol>
            </MDBRow>
          </>
        )
      default:
        console.log('No Case Found')
    }
  }

  function handleChange (e) {
    const value = e.target.value
    e.preventDefault()
    parameters[0].value = value
    setParameters([...parameters])
  }

  const handleGroupChange = (e, newValue) => {
    // MUI already packs the group objects into an array, no need to do a transformation here!
    const value = newValue
    setParameters(value)
  }

  const selectDifferentAudienceFilter = (newAudienceFilterType) => {
    const parameters = []
    switch (newAudienceFilterType) {
      case 'group':
        setAudienceFilterType('group')
        setParameters(parameters)
        break
      case 'first':
        setAudienceFilterType('first')
        parameters.push({
          field: 'first',
          type: '!=',
          value: '~~~'
        })
        setParameters(parameters)
        break
      case 'supvemail':
        setAudienceFilterType('supvemail')
        parameters.push({
          field: 'supvemail',
          type: '==',
          value: null
        })
        setParameters(parameters)
        break
      case 'deptname':
        setAudienceFilterType('deptname')
        parameters.push({
          field: 'deptname',
          type: '==',
          value: null
        })
        setParameters(parameters)
        break
    }
  }

  const userColumns = [
    { field: 'last', headerName: 'Last name', flex: 1 },
    { field: 'first', headerName: 'First name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 2 }
  ]

  return (
    <PhinCard
      title='Audience Builder'
      description='Select one of the below filters to choose who will be involved in this campaign.'
      showHeader={showHeader}
    >
      {description && <MDBRow className='ml-2 mb-2'>{description}</MDBRow>}

      <Grid
        container
        direction='row'
        justifyContent='space-around'
        alignItems='center'
      >
        <PhinChip
          id='company-campaign-audience-tab-icon'
          icon={<IoGlobeSharp size='2em' />}
          color={audienceFilterType === 'first' ? 'primary' : 'phinBlack'}
          variant={audienceFilterType === 'first' ? 'filled' : 'outlined'}
          onClickFunction={() => selectDifferentAudienceFilter('first')}
          label='Full Company'
          size='medium'
        />
        <PhinChip
          id='dept-name-audience-tab-icon'
          icon={<IoBusiness size='2em' />}
          color={audienceFilterType === 'deptname' ? 'primary' : 'phinBlack'}
          variant={audienceFilterType === 'deptname' ? 'filled' : 'outlined'}
          onClickFunction={() => selectDifferentAudienceFilter('deptname')}
          label='Department'
          size='medium'
        />
        <PhinChip
          id='supvemail-audience-tab-icon'
          icon={<IoPerson size='2em' />}
          color={audienceFilterType === 'supvemail' ? 'primary' : 'phinBlack'}
          variant={audienceFilterType === 'supvemail' ? 'filled' : 'outlined'}
          onClickFunction={() => selectDifferentAudienceFilter('supvemail')}
          label='Supervisor'
          size='medium'
        />
        {groups && groups.length > 0 && (
          <PhinChip
            id='group-audience-tab-icon'
            icon={<IoPeopleCircleSharp size='2em' />}
            color={audienceFilterType === 'group' ? 'primary' : 'phinBlack'}
            variant={audienceFilterType === 'group' ? 'filled' : 'outlined'}
            onClickFunction={() => selectDifferentAudienceFilter('group')}
            label=' Group Campaign'
            size='medium'
          />
        )}
      </Grid>

      {generatePresetCustomization()}
      <Box style={{ width: '100%', paddingTop: '2em' }}>
        <DataGrid
          autoHeight
          rows={users || []}
          columns={userColumns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableExport
          disableColumnMenu
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 250 }
            }
          }}
        />
      </Box>
    </PhinCard>
  )
}

export default AudienceFilters
