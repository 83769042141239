import ValidationUtils from '../../../utils/validationUtils'
import { Alert, Autocomplete, Box, Button, Checkbox, FormControlLabel, Grid, TextField } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NOTIFICATIONS } from '../../../frontendConsts'
import { notificationOpenedThunk } from '../../../store/MuiNotificationsSlice'
import { enableAutomatedBillingThunk } from '../../../store/PartnerBillingSlice'
import { CardComponent, CardCVV, CardNumber, CardExpiry } from '@chargebee/chargebee-js-react-wrapper'
import { COUNTRIES } from '../../../constants/Countries'
import { STATES } from '../../../constants/States'
import { useAuth0 } from '@auth0/auth0-react'
import { getSaasAgreementThunk } from '../../../store/PartnerSlice'
import { DateTime } from 'luxon'
import { calculateNextBillingDate } from './ChargebeePlanBillingWidget'
import { useHistory, useParams } from 'react-router-dom'
import CreateAdminForm from './CreateAdminForm'
import PhinModal from '../../components/PhinModal'

export function PartnerAutomatedBillingForm ({ isSelfServiceAccount }) {
  const dispatch = useDispatch()

  const cardRef = useRef(null)

  const history = useHistory()
  const { partner } = useSelector((state) => state.partner)
  const { loaders } = useSelector((state) => state.partnerBilling)
  const { isLoadingIsAutomaticBillingReady } = loaders

  const { user } = useAuth0()
  const [billingCompanyName, setBillingCompanyName] = useState(partner.name)
  const [billingCompanyNameError, setBillingCompanyNameError] = useState(false)
  const [billingEmail, setBillingEmail] = useState(user.email)
  const [billingEmailError, setBillingEmailError] = useState(false)
  const [billingAddress, setBillingAddress] = useState({
    firstName: '',
    lastName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    country: ''
  })

  const [billingAddressErrors, setBillingAddressErrors] = useState({
    firstName: false,
    lastName: false,
    addressLine1: false,
    city: false,
    state: false,
    zipCode: false,
    country: false
  })

  const [selectedState, setSelectedState] = useState('')
  const [selectedCountry, setSelectedCountry] = useState('')

  const [isAgreeToTerms, setIsAgreeToTerms] = useState(false)
  const [isAgreeToTermsError, setIsAgreeToTermsError] = useState(false)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [agreementUrl, setAgreementUrl] = useState(null)

  const [openInviteAdminModal, setOpenInviteAdminModal] = useState(false)
  const { id: partnerId } = useParams()

  const getAgreementAndSetUrl = async () => {
    const url = await dispatch(getSaasAgreementThunk({ partnerId: partner.id }))
    setAgreementUrl(url)
  }

  useEffect(() => {
    getAgreementAndSetUrl()
  }, [])

  const submitAutomatedBillingForm = async () => {
    let alertMessage = ''
    const updatedBillingAddressErrors = {}

    if (!isSubmitting) {
      setIsSubmitting(true)
      if (!isLoadingIsAutomaticBillingReady) {
        // When the partner is setting up self-service, we fill the company name for them
        // This should never fire because they should always have a valid company name
        if (!ValidationUtils.isNotWhiteSpace(billingCompanyName)) {
          alertMessage = 'Please provide a company billing name.'

          setBillingCompanyNameError(true)
        }

        if (!ValidationUtils.isNotWhiteSpace(billingAddress.firstName)) {
          if (!alertMessage) {
            alertMessage = 'Please provide a first name.'
          }

          billingAddressErrors.firstName = true
        }

        if (!ValidationUtils.isNotWhiteSpace(billingAddress.lastName)) {
          if (!alertMessage) {
            alertMessage = 'Please provide a last name.'
          }

          billingAddressErrors.lastName = true
        }

        if (!ValidationUtils.isValidEmail(billingEmail)) {
          if (!alertMessage) {
            alertMessage = 'Please provide a company billing email address.'
          }

          setBillingEmailError(true)
        }

        if (!ValidationUtils.isNotWhiteSpace(billingAddress.addressLine1)) {
          if (!alertMessage) {
            alertMessage = 'Please provide a valid street address.'
          }

          billingAddressErrors.addressLine1 = true
        }

        if (!ValidationUtils.isNotWhiteSpace(billingAddress.city)) {
          if (!alertMessage) {
            alertMessage = 'Please provide a city.'
          }

          billingAddressErrors.city = true
        }

        if (!ValidationUtils.isNotWhiteSpace(billingAddress.country?.code)) {
          if (!alertMessage) {
            alertMessage = 'Please select a country.'
          }

          billingAddressErrors.country = true
        }

        if (billingAddress.country?.code === 'US' && !ValidationUtils.isNotWhiteSpace(billingAddress.state?.code)) {
          if (!alertMessage) {
            alertMessage = 'Please select a state.'
          }

          billingAddressErrors.state = true
        }

        let token
        try {
          token = (await cardRef.current.tokenize()).token
        } catch ({ name, message }) {
          if (!alertMessage) {
            alertMessage = message
          }
        }

        if (!isAgreeToTerms && isSelfServiceAccount) {
          if (!alertMessage) {
            alertMessage = 'Please agree to the terms of service and privacy policy.'
          }
          setIsAgreeToTermsError(true)
        }

        if (alertMessage) {
          dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.WARNING, alertMessage }))
          setBillingAddressErrors({ ...billingAddressErrors, ...updatedBillingAddressErrors })
          setIsSubmitting(false)
          return
        }

        const submittedBillingAddress = { ...billingAddress, country: billingAddress.country.code, state: billingAddress.state?.code || billingAddress.state }

        await dispatch(enableAutomatedBillingThunk({ partnerId: partner.id, billingCompanyName, billingEmail, isSelfServiceAccount, paymentTokenId: token, billingAddress: submittedBillingAddress, isAgreeToTerms, history, internalCompanyId: partner.clients[0].id }))
        setIsSubmitting(false)
      }
    }
  }

  return (
    <>
      <PhinModal
        isOpen={openInviteAdminModal}
        size='medium'
        close={() => setOpenInviteAdminModal(false)}
        noButtons
      >
        <CreateAdminForm partnerId={partnerId} partner={partner} />
      </PhinModal>
      <Box padding={3} marginRight='100px'>
        {isSelfServiceAccount &&
          <>
            <p className='phin-h3 padding:0 pl-0 align-self-start'>Welcome to Phin</p>
            <div className='phin-body-text'>
              We're excited to have you here! Your <b style={{ fontWeight: 'bold' }}>30 day free trial</b> will begin after you set up your account.
              Our platform helps you easily manage your Security Awareness Training programs with simple steps, effective campaigns, and insightful reports. <b style={{ fontWeight: 'bold' }}>Finish setting up your account below to start exploring</b>.
              <br /><br />
              Check out the <a href='https://www.phinsec.io/knowledge/getting-started' target='_blank' rel='noreferrer' style={{ color: '#132B5E', fontWeight: 'bold', textDecoration: 'underline' }}>Phin Knowledge Base</a> for some helpful tips.
            </div>
          </>}

        {isSelfServiceAccount &&
          <div className='d-flex justify-content-center mt-5'>
            <iframe width='560' height='315' src='https://www.youtube.com/embed/9MXzYNDItxw?si=Q4pW1EsrLyQAygJH' title='Phin Security Free Trial Demo' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' allowfullscreen />
          </div>}

        <p className='phin-h6 padding:0 pl-0 align-self-start mt-2'>{isSelfServiceAccount ? 'Account Setup' : 'Automatic Billing Setup'}</p>
        <Box display='flex' justifyContent='space-between'>
          <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
            marginRight='var(--phin-s2)'
          >

            <Grid sx={{ marginBottom: 'var(--phin-s-1)', width: '100%' }}>
              <Alert icon={<InfoIcon />} severity='info' variant='outlined' sx={{ background: 'var(--phin-light-blue)', borderRadius: '8px' }}>
                Phin uses <a target='_blank' rel='noreferrer' href='https://www.chargebee.com/'>ChargeBee</a> for payments. You'll get an email with login information to manage your billing.
              </Alert>
            </Grid>

            {!isSelfServiceAccount && (
              <TextField
                id='billing-company-name'
                label='Company Name'
                value={billingCompanyName}
                required
                fullWidth
                error={billingCompanyNameError}
                onChange={(e) => {
                  setBillingCompanyName(e.target.value)
                  setBillingCompanyNameError(false)
                }}
                sx={{ marginBottom: 'var(--phin-s-1)' }}
              />
            )}

            <Grid
              container direction='row'
              justifyContent='space-between'
              alignItems='center'
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6}>
                <TextField
                  id='first-name'
                  label='First Name'
                  value={billingAddress.firstName}
                  fullWidth
                  required
                  error={billingAddressErrors.firstName}
                  onChange={(e) => {
                    setBillingAddress({ ...billingAddress, firstName: e.target.value })
                    setBillingAddressErrors({ ...billingAddressErrors, firstName: false })
                  }}
                  sx={{ marginBottom: 'var(--phin-s-1)' }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id='last-name'
                  label='Last Name'
                  value={billingAddress.lastName}
                  fullWidth
                  required
                  error={billingAddressErrors.lastName}
                  onChange={(e) => {
                    setBillingAddress({ ...billingAddress, lastName: e.target.value })
                    setBillingAddressErrors({ ...billingAddressErrors, lastName: false })
                  }}
                  sx={{ marginBottom: 'var(--phin-s-1)' }}
                />
              </Grid>
            </Grid>

            <TextField
              id='billing-email-address'
              label='Billing Email'
              value={billingEmail}
              fullWidth
              required
              error={billingEmailError}
              onChange={(e) => {
                setBillingEmail(e.target.value)
                setBillingEmailError(false)
              }}
            />

            {isSelfServiceAccount &&
              <Box
                marginTop='var(--phin-s0)'
                width='100%'
                className='phin-border'
                backgroundColor='#f5f5f5'
              >
                <Grid container padding={2} alignItems='center' alignContent='center' justifyContent='space-between'>
                  <span className='phin-body-text'>Need someone else to provide the billing information so you can start your trial?</span>
                  <Button
                    sx={{ color: 'var(--light-blue)' }}
                    variant='outlined'
                    id='invite-your-team-button'
                    onClick={() => setOpenInviteAdminModal(true)}
                  > Invite Your Team
                  </Button>

                </Grid>
              </Box>}

            <p className='phin-h6 padding:0 pl-0 align-self-start'>Billing Address</p>

            <TextField
              id='billing-address-line-1'
              label='Address Line 1'
              value={billingAddress.addressLine1}
              fullWidth
              required
              error={billingAddressErrors.addressLine1}
              onChange={(e) => {
                setBillingAddress({ ...billingAddress, addressLine1: e.target.value })
                setBillingAddressErrors({ ...billingAddressErrors, addressLine1: false })
              }}
              sx={{ marginBottom: 'var(--phin-s-1)' }}
            />

            <TextField
              id='billing-address-line-2'
              label='Address Line 2'
              value={billingAddress.addressLine2}
              fullWidth
              onChange={(e) => {
                setBillingAddress({ ...billingAddress, addressLine2: e.target.value })
              }}
              sx={{ marginBottom: 'var(--phin-s-1)' }}
            />

            <Grid
              container direction='row'
              justifyContent='space-between'
              alignItems='center'
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6}>
                <TextField
                  id='billing-city'
                  label='City'
                  value={billingAddress.city}
                  fullWidth
                  required
                  error={billingAddressErrors.city}
                  onChange={(e) => {
                    setBillingAddress({ ...billingAddress, city: e.target.value })
                    setBillingAddressErrors({ ...billingAddressErrors, city: false })
                  }}
                  sx={{ marginBottom: 'var(--phin-s-1)' }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id='billing-zip'
                  label='Zip'
                  value={billingAddress.zipCode}
                  fullWidth
                  onChange={(e) => {
                    setBillingAddress({ ...billingAddress, zipCode: e.target.value })
                  }}
                  sx={{ marginBottom: 'var(--phin-s-1)' }}
                />
              </Grid>
            </Grid>

            <Grid
              container direction='row'
              justifyContent='space-between'
              alignItems='center'
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6}>
                <Autocomplete
                  disablePortal
                  id='billing-country'
                  label='Country'
                  required
                  options={COUNTRIES}
                  renderInput={(params) => <TextField {...params} required label='Country' error={billingAddressErrors.country} />}
                  value={billingAddress.country}
                  onChange={(event, newValue) => {
                    setBillingAddress({ ...billingAddress, country: newValue, state: '' })

                    setSelectedState('')
                    setBillingAddressErrors({ ...billingAddressErrors, country: false })
                  }}
                  inputValue={selectedCountry}
                  onInputChange={(event, newInputValue) => {
                    setSelectedCountry(newInputValue)
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                {(billingAddress.country?.code === 'US')
                  ? (
                    <Autocomplete
                      disablePortal
                      id='billing-state'
                      label='State'
                      options={STATES}
                      sx={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} required label='State' error={billingAddressErrors.state} />}
                      value={billingAddress.state}
                      onChange={(event, newValue) => {
                        setBillingAddress({ ...billingAddress, state: newValue })
                        setBillingAddressErrors({ ...billingAddressErrors, state: false })
                      }}
                      inputValue={selectedState}
                      onInputChange={(event, newInputValue) => {
                        setSelectedState(newInputValue)
                      }}
                    />
                    )
                  : (
                    <TextField
                      id='billing-state'
                      label='State'
                      value={billingAddress.state}
                      fullWidth
                      onChange={(e) => {
                        setBillingAddress({ ...billingAddress, state: e.target.value })
                        setBillingAddressErrors({ ...billingAddressErrors, state: false })
                      }}
                    />
                    )}
              </Grid>
            </Grid>

            <p className='phin-h6 padding:0 pl-0 align-self-start'>Payment Method</p>

            {/* Payment */}
            <CardComponent
              ref={cardRef}
              className='w-100'
            >
              <Grid
                container
                spacing={2}
                marginLeft={0}
                marginTop={0}
                padding={4}
                border={1}
                borderColor='#e0e0e0'
                borderRadius={2}
                width='100%'
              >
                <Grid item sm={6} style={{ paddingLeft: 0, paddingTop: 0 }}>
                  <CardNumber placeholder='Card number' className='border border-1 border-light p-2 rounded flex-grow-1' />
                </Grid>
                <Grid item sm={3} style={{ paddingTop: 0 }}>
                  <CardExpiry placeholder='Exp Date' className='border border-1 border-light p-2 rounded flex-grow-1' />
                </Grid>
                <Grid item sm={3} style={{ paddingTop: 0 }}>
                  <CardCVV placeholder='CVV' className='border border-1 border-light p-2 rounded flex-grow-1' />
                </Grid>
              </Grid>
            </CardComponent>

            {isSelfServiceAccount &&
              <FormControlLabel
                required
                value={isAgreeToTerms}
                onChange={(e) => {
                  setIsAgreeToTerms(e.target.checked)
                  setIsAgreeToTermsError(false)
                }}
                error={isAgreeToTermsError}
                sx={{ marginTop: 'var(--phin-s-1)', alignSelf: 'flex-start' }}
                control={<Checkbox />}
                label={
                  <span>
                    I agree to Phin's <a target='_blank' rel='noreferrer' href={agreementUrl}>Terms of Service</a>,
                    and <a target='_blank' href='https://www.phinsec.io/privacy/training-platform' rel='noreferrer'>Privacy Policy</a>
                  </span>
                }
              />}

          </Grid>
          {isSelfServiceAccount &&
            <Box
              border={2}
              borderRadius={2}
              borderColor='#e0e0e0'
              height='fit-content'
              minWidth={324}
            >
              <Box
                backgroundColor='#f5f5f5'
                padding={4}
                borderBottom={1}
                borderColor='#e0e0e0'
                sx={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
              >
                <p className='phin-h4'>Billing Summary</p>
                <div className='d-flex justify-content-between align-content-start'>
                  <p className='phin-body-text-small'>Free Trial End {partner.freeTrialEndDate}</p>
                  <p className='phin-body-text-small'> {DateTime.now().plus({ months: 1 }).toLocaleString()}</p>
                </div>
              </Box>
              <Box
                padding={4}
              >
                <p className='phin-h5'>Default Plan</p>
                <p className='phin-body-text margin-bottom:1'>Small Plan Month to Month</p>

                <p className='phin-h5'>Pricing</p>
                <p className='phin-body-text'><b>$250</b> for 125 users</p>
                <p className='phin-body-text-small'>+ $2 /additional user</p>

                <hr style={{ border: '1px solid #e0e0e0' }} />
                <div
                  className='d-flex flex-column justify-content-between'
                  style={{
                    height: '6rem'
                  }}
                >
                  <div className='d-flex justify-content-between align-content-start'>
                    <p className='phin-h5'>Due Today</p>
                    <p className='phin-h5'>$0</p>
                  </div>

                  <div>
                    <div className='d-flex justify-content-between align-content-start' style={{ gap: '20px' }}>
                      <p className='phin-h5'>Estimated Total</p>
                      <p className='phin-h5'>$250 <span className='phin-body-text-small'>/month</span></p>
                    </div>
                    <div className='d-flex justify-content-between align-content-start'>
                      <p className='phin-body-text-small'>Due on</p>
                      <p className='phin-body-text-small'> {calculateNextBillingDate({ billingStartDate: DateTime.now().plus({ months: 1, days: 1 }), currentDateTime: DateTime.now() })}</p>
                    </div>
                  </div>
                </div>
              </Box>
            </Box>}
        </Box>

        <div className='d-flex justify-content-end mt-4'>
          <Button
            size='large'
            id='enable-automated-billing-button'
            aria-label='Enroll In Automated Billing Button'
            variant='contained'
            disabled={isSubmitting}
            onClick={() => { submitAutomatedBillingForm() }}
            sx={{ backgroundColor: 'var(--light-blue)' }}
          >
            {isSelfServiceAccount ? 'Get Started' : 'Enable billing'}
          </Button>
        </div>
      </Box>
    </>
  )
}
