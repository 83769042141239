import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import '../../css/integrationsDetail.css'
import '../../css/integrationCard.css'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Alert, Button, Checkbox, Grid, Tooltip } from '@mui/material'
import { stageThunk } from '../../../store/StagedGroupsSlice'
import { integrationUpdatedAction } from '../../../store/IntegrationsSlice'
import { integrationTypes } from '../../../frontendConsts'
import PhinModal from '../PhinModal'

export function StageGroups ({
  companyId,
  userSyncSettings,
  tempStagedGroups
}) {
  const dispatch = useDispatch()

  const [isAllChecked, setAllChecked] = useState(false)
  const [isContinueModalShowing, setIsContinueModalShowing] = useState(false)
  const [loadingContinue, setLoadingContinue] = useState(false)
  const [selectedGroups, setSelectedGroups] = useState([])
  const [maxTotalGroups, setMaxTotalGroups] = useState(false)
  const [tablePageSize, setTablePageSize] = useState(10)
  const [isConfirmSkipOpen, setIsConfirmSkipOpen] = useState(false)

  useEffect(() => {
    if (tempStagedGroups.length >= userSyncSettings.maxGroupsThreshold) {
      setMaxTotalGroups(true)
    } else {
      setMaxTotalGroups(false)
    }
  }, [])

  useEffect(() => {
    if (!isAllChecked && tempStagedGroups.length > 0 && (selectedGroups.length === tempStagedGroups.length)) {
      setAllChecked(true)
    } else if (isAllChecked && (selectedGroups.length !== tempStagedGroups.length)) {
      setAllChecked(false)
    }
  }, [selectedGroups])

  const handleSkip = async () => {
    const result = await dispatch(stageThunk({ companyId, groupIds: [], syncAll: true }))

    if (result) {
      setIsConfirmSkipOpen(false)
    }
  }

  const handleContinue = () => {
    setIsContinueModalShowing(true)
  }

  const handleCloseContinueModal = () => {
    setLoadingContinue(false)
    setIsContinueModalShowing(false)
  }

  const handleContinueConfirm = async () => {
    const groupIds = selectedGroups.map(g => g.id)

    // set that this whole process is groupSync
    dispatch(integrationUpdatedAction({ integrationId: integrationTypes.USER_SYNC, updatedFields: { groupSync: true } }))

    // do other stuff like sync groups
    const success = await dispatch(stageThunk({ companyId, groupIds, syncAll: false }))

    if (success) {
      setIsContinueModalShowing(false)
    }
  }

  const handleAllCheckboxesSelected = () => {
    const indeterminate = selectedGroups.length > 0 && (selectedGroups.length < tempStagedGroups.length)

    if (indeterminate || isAllChecked) {
      // deselect all
      setSelectedGroups([])
    } else {
      // select all
      setSelectedGroups(tempStagedGroups)
    }
  }

  const handleCheckboxChange = (id) => {
    const found = selectedGroups.find(g => { return g.id === id })
    if (found) {
      setSelectedGroups(selectedGroups.filter(g => { return g.id !== id }))
    } else {
      setSelectedGroups([...selectedGroups, ...tempStagedGroups.filter(g => g.id === id)])
    }
  }

  const stageGroupColumns = [
    {
      field: 'selected',
      headerName: 'Select',
      flex: 0.25,
      filterable: false,
      sortable: false,
      renderHeader: (params) => {
        return (
          <>
            <Checkbox
              id='checkbox-all'
              checked={isAllChecked}
              indeterminate={selectedGroups.length > 0 && (selectedGroups.length !== tempStagedGroups.length)}
              onClick={handleAllCheckboxesSelected}
            />
          </>
        )
      },
      renderCell: (params) => {
        return (
          <Checkbox
            id={`checkbox-${params.row.displayName}`}
            checked={selectedGroups.filter(g => g.id === params.row.id).map(o => o.id).includes(params.row.id)}
            value={params.row.id}
            onClick={() => handleCheckboxChange(params.row.id)}
          />
        )
      }
    },
    {
      headerName: 'Group Name',
      field: 'displayName',
      flex: 1
    },
    {
      headerName: 'Group Id',
      field: 'externalId',
      flex: 1
    }
  ]

  return (
    <>
      <PhinModal
        isOpen={isConfirmSkipOpen}
        title='Skip Group Selection'
        close={() => {
          setIsConfirmSkipOpen(false)
        }}
        action={handleSkip}
      >
        <p>Are you sure you want to skip selecting any Groups?</p>
      </PhinModal>

      <Grid container>

        {maxTotalGroups && (
          <Grid item xs={12} sx={{ mt: 1, mb: 3, display: 'inline-block', alignItems: 'center' }}>
            <Alert variant='outlined' severity='warning' sx={{ background: 'var(--phin-light-yellow)' }}>
              You have <strong>{tempStagedGroups.length} groups</strong> in this tenant, which exceeds the <strong>{userSyncSettings.maxGroupsThreshold} group</strong> limit for full sync. Please use the group select below to choose a subset of groups that you would like to sync.
            </Alert>
          </Grid>
        )}

        {!maxTotalGroups && (
          <Grid item xs={12} sx={{ mt: 1, mb: 3, display: 'inline-block', alignItems: 'center' }}>
            {tempStagedGroups.length === 0 && (
              <Alert severity='info'>
                Your tenant has no groups. Click the "Skip" button below to continue to sync all users.
              </Alert>)}

            {tempStagedGroups.length > 0 && (
              <Alert severity='info'>
                Want to sync all users? Feel free to skip this step.
              </Alert>)}
          </Grid>
        )}

        <Grid
          item xs={12}
          sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}
        >
          <div style={{ height: '65vh', width: '100%' }}>
            <DataGrid
              style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
              className='DataGrid'
              initialState={{
                sorting: {
                  sortModel: [{ field: 'displayName', sort: 'asc' }]
                }
              }}
              rows={tempStagedGroups}
              columns={stageGroupColumns}
              pageSize={tablePageSize}
              onPageSizeChange={(newSize) => setTablePageSize(newSize)}
              rowsPerPageOptions={[10, 25, 50]}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: {
                    debounceMs: 500,
                    id: 'staged-groups-table-search',
                    'aria-label': 'Search'
                  },
                  printOptions: { disableToolbarButton: true },
                  csvOptions: {
                    fileName: 'StagedGroups',
                    delimiter: ',',
                    fields: ['externalId', 'displayName', 'userCount']
                  }
                }
              }}
              disableSelectionOnClick
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
            />
          </div>
        </Grid>

        <Grid
          item xs={12}
          sx={{ mt: 3, mb: 3, display: 'flex', justifyContent: 'end', alignItems: 'end' }}
        >
          <Tooltip title={(loadingContinue || maxTotalGroups) ? 'Full sync disabled due to group count' : 'Skip to full sync'}>
            <span>
              <Button
                id='skip-button'
                aria-label='Skip Button'
                disabled={loadingContinue || maxTotalGroups}
                color='primary'
                variant='outlined'
                onClick={() => setIsConfirmSkipOpen(true)}
              >Skip
              </Button>
            </span>
          </Tooltip>

          <Button
            id='continue-button'
            aria-label='Continue Button'
            disabled={loadingContinue || selectedGroups.length === 0}
            color='primary'
            variant='contained'
            sx={{ ml: 3 }}
            onClick={handleContinue}
          >Continue
          </Button>

          <PhinModal
            isOpen={isContinueModalShowing}
            close={handleCloseContinueModal}
            closeText='Cancel'
            action={handleContinueConfirm}
            actionText='Confirm'
          >
            <>You are about to sync <strong>{selectedGroups.length} groups</strong>.</>
          </PhinModal>
        </Grid>
      </Grid>
    </>
  )
}
