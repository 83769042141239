import { Alert } from '@mui/material'
import { PhinCard } from '../PhinCard'

function renderTopics (topics) {
  const topicsArray = []

  topics.forEach(topic => {
    topicsArray.push(<li key={topic}>{topic}</li>)
  })

  return topicsArray
}

function TrainingCampaignResource ({ trainingTopics, trainingCampaignName, trainingDescription }) {
  return (
    <PhinCard
      title={trainingCampaignName}
      description={trainingDescription}
    >
      <h5 className='phin-h5 padding-bottom:-2'>Topics:</h5>
      <ul style={{ fontWeight: '400' }}>
        {renderTopics(trainingTopics)}
      </ul>

      <div className='margin-top:1'>
        <Alert severity='info'>
          {'Continuous Training Campaigns will run continuously with a finite amount of training courses. If a campaign uses' +
              ' all of the training courses from a specific topic, the campaign will start re-using courses.'}
        </Alert>
      </div>
    </PhinCard>
  )
}

export default TrainingCampaignResource
