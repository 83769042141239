import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ReportingHistory from './components/reportingPage/ReportingHistory'
import ReportSettings from './components/reportingPage/ReportSettings'
import { getReportsThunk } from '../store/ReportingSettingsSlice'

function ReportingPage ({ companyId }) {
  const dispatch = useDispatch()
  const { settings } = useSelector(state => state.reportingSettings)

  useEffect(() => {
    async function setupReports () {
      dispatch(getReportsThunk({ companyId }))
    }
    setupReports()
  }, [])

  return (
    <div className='d-flex flex-column'>
      <div className='mb-4' style={{ alignSelf: 'start' }}>
        <h2 className='phin-h2'>Reporting</h2>
      </div>
      <ReportSettings
        companyId={companyId}
      />

      <ReportingHistory
        companyId={companyId}
        rows={settings.reports || []}
      />
    </div>
  )
}

export default ReportingPage
