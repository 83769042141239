import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import './css/integrationCard.css'
import { integrationStatuses, integrationTypes, partnerIntegrationStatuses } from '../frontendConsts.js'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from './components/Spinner'
import { getPartnerIntegrationsThunk } from '../store/PartnerIntegrationSlice.js'
import { Chip } from '@mui/material'

const integrationsCatalog = {
  phinApi: {
    integrationStatus: integrationStatuses.NOT_CONNECTED,
    name: 'Phin API',
    provider: 'Phin',
    integrationType: integrationTypes.PHIN_API,
    id: integrationTypes.PHIN_API,
    description: 'Enable the Phin API. Use the API Client ID and Client Secret to make requests for partner and company specific data from an external application.',
    logo: '/logoBlueNoTextSmall.png'
  },
  gradient: {
    integrationStatus: integrationStatuses.NOT_CONNECTED,
    name: 'Gradient MSP',
    provider: 'Gradient',
    integrationType: integrationTypes.GRADIENT,
    id: integrationTypes.GRADIENT,
    description: 'Enable the Gradient MSP - Phin integration. Automatically send usage data for your clients\' companies directly to Gradient MSP.',
    logo: '/gradientLogoGSmall.png'
  },
  connectWiseBilling: {
    integrationStatus: integrationStatuses.NOT_CONNECTED,
    name: 'ConnectWise Billing',
    provider: 'ConnectWise',
    integrationType: integrationTypes.CW_BILLING,
    id: integrationTypes.CW_BILLING,
    description: 'Send usage data for you client\'s companies directly to ConnectWise Manage',
    logo: '/ConnectWiseIntegrationLogo.png',
    tag: 'BETA'
  },
  connectWiseServiceDesk: {
    integrationStatus: integrationStatuses.NOT_CONNECTED,
    name: 'ConnectWise Service Desk',
    provider: 'ConnectWise',
    integrationType: integrationTypes.CW_SERVICE_DESK,
    id: integrationTypes.CW_SERVICE_DESK,
    description: 'Automatically create service desk tickets in ConnectWise Manage for reported phishing emails.',
    logo: '/ConnectWiseIntegrationLogo.png',
    tag: 'BETA'
  }
}

export function PartnerIntegrationsPage ({ id }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { partnerIntegrationsMap, loaders, partnerIdOfLastFetch } = useSelector((state) => state.partnerIntegrations)
  const { isLoadingPartnerIntegrations } = loaders
  const { partner, loaders: partnerLoaders } = useSelector((state) => state.partner)
  const { isLoadingPartner } = partnerLoaders

  const { authorization } = useSelector((state) => state.auth)
  const hasSeenCWBilling = authorization?.hasSeenCWBilling

  const [integrations, setIntegrations] = useState(integrationsCatalog)

  // Fetch the partner integrations if the partnerIntegrationsMap is null or the partnerIdOfLastFetch is different from the current partnerId
  useEffect(() => {
    if (!partnerIntegrationsMap || partnerIdOfLastFetch !== id) {
      dispatch(getPartnerIntegrationsThunk(id))
    }
  }, [])

  async function validatePartnerIntegrations () {
    const updatedIntegrationsMap = { ...integrations }
    if (!partnerIntegrationsMap) {
      return
    }

    for (const partnerIntegration of Object.values(updatedIntegrationsMap)) {
      if (partnerIntegrationsMap[partnerIntegration.id]) {
        partnerIntegration.integrationStatus = partnerIntegrationsMap[partnerIntegration.id].integrationStatus
      } else if (partnerIntegration.id === integrationTypes.CW_BILLING) {
        partnerIntegration.integrationStatus = (partnerIntegrationsMap[integrationTypes.CONNECTWISE]?.billingIsEnabled) ? partnerIntegrationStatuses.ENABLED : partnerIntegrationStatuses.NOT_ENABLED
      } else if (partnerIntegration.id === integrationTypes.CW_SERVICE_DESK) {
        partnerIntegration.integrationStatus = (partnerIntegrationsMap[integrationTypes.CONNECTWISE]?.serviceDeskIsEnabled) ? partnerIntegrationStatuses.ENABLED : partnerIntegrationStatuses.NOT_ENABLED
      } else {
        partnerIntegration.integrationStatus = integrationStatuses.NOT_CONNECTED
      }
    }

    setIntegrations(updatedIntegrationsMap)
  }

  useEffect(() => {
    validatePartnerIntegrations()
  }, [partnerIntegrationsMap])

  const generateStatus = (integrationStatus, integrationId) => {
    if (integrationStatus === partnerIntegrationStatuses.ENABLED) {
      return <p className='statusBadge connected'>connected</p>
    } else {
      return <p className='statusBadge connect'>connect</p>
    }
  }

  const generateOnClickFunction = (integrationId, integrationType) => {
    switch (integrationType) {
      case integrationTypes.CW_BILLING:
      case integrationTypes.CW_SERVICE_DESK:
        return () => history.push(`integrations/connectWise/${integrationId}`)
      default:
        return () => history.push(`integrations/${integrationId}`)
    }
  }

  function generateIntegrationCard ({ key, integrationId, name, integrationStatus, integrationType, description, logo, tag = null }) {
    const onclickFunction = generateOnClickFunction(integrationId, integrationType)
    return (
      <div style={{ cursor: 'pointer' }} key={key} onClick={onclickFunction}>
        <div className='interCardPadding'>
          <div
            id={`${name}-integration-card`}
            aria-label={`${name} Integration Card`}
            className={(integrationId === integrationTypes.CW_BILLING && !hasSeenCWBilling)
              ? 'betaBorder cardBody betaBorderAnimation'
              : 'cardBody'}
          >
            <div className='cardContents'>
              <div className='cardInfoQuick'>
                <img src={logo} className='cardLogo' />
                <p className='cardStatus'>{generateStatus(integrationStatus, integrationId)}</p>
              </div>
              <div className='cardTitle'>
                {name}
                {tag && <Chip size='small' label={tag} sx={{ backgroundColor: 'var(--purple-75)', color: 'white' }} />}
              </div>
              <p className='cardDesc'>{description}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function generateIntegrationsList () {
    return Object.entries(integrations)
      // Filter out integrations that have a feature flag that is not enabled
      .filter(([id, integration]) => !integration.featureFlag || (integration.featureFlag && partner.featureFlags?.[integration.featureFlag]))
      .map(([id, integration], i) => {
        let { name, integrationStatus, description, logo, tag } = integration

        if (!name) {
          name = 'Unknown Integration'
          integrationStatus = integrationStatuses.CONNECTED
        }

        return (
          generateIntegrationCard({
            key: i,
            name,
            integrationId: integration.id,
            integrationStatus,
            integrationType: integration.integrationType,
            description,
            logo,
            tag
          })
        )
      })
  }

  return (
    <div>
      <div className='phin-h2 padding-bottom:2'>Multi-Tenant Integrations</div>
      <div className='phin-h4 padding-bottom:0'>Phin Integrations</div>

      {(isLoadingPartner || !partner || !partnerIntegrationsMap || isLoadingPartnerIntegrations) && (
        <Spinner message='Loading Partner Integrations' />
      )}

      {(!isLoadingPartner && partner && partnerIntegrationsMap && !isLoadingPartnerIntegrations) && (
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap'
        }}
        >
          {generateIntegrationsList()}
        </div>
      )}

      <div className='phin-h4 padding-top:2 padding-bottom:0'>Third-Party Integrations</div>
      {(!isLoadingPartner && partner && partnerIntegrationsMap && !isLoadingPartnerIntegrations) && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap'
          }}
        >
          <div className='interCardPadding'>
            <div
              onClick={() => window.open('https://vciotoolbox.com/vciotoolbox-phin-security-integration/')} style={{ cursor: 'pointer' }}
              id='vcio-toolbox-integration-card'
              aria-label='VCIOToolbox Integration Card'
              className='cardBody'
            >
              <div className='cardContents'>
                <div className='cardInfoQuick'>
                  <img src='/vCiO_Brand_Color.svg' className='cardLogo' />
                  <p className='cardStatus'>
                    <p className='statusBadge external-badge'>external <OpenInNewIcon fontSize='small' /></p>
                  </p>
                </div>
                <div className='cardTitle'>vCiOToolbox</div>
                <p className='cardDesc'>
                  A powerful Unified Account Management and Advisory Platform for MSPs and MSSPs. Enable the Phin integration to import training and phishing compliance data directly into your QBR or GRC reports and dashboards within vCIOToolbox.
                </p>
              </div>
            </div>
          </div>
          <div className='interCardPadding'>
            <div
              onClick={() => window.open('https://help.lifecycleinsights.io/hc/en-us/articles/17948381482139-Security-Awareness-Data-Phin')}
              style={{ cursor: 'pointer' }}
              id='lifecycle-insights-integration-card'
              aria-label='Lifecycle Insights Integration Card'
              className='cardBody'
            >
              <div className='cardContents'>
                <div className='cardInfoQuick'>
                  <img src='/lifecycle_insights.png' className='cardLogo' />
                  <p className='cardStatus'>
                    <p className='statusBadge external-badge'>external <OpenInNewIcon fontSize='small' /></p>
                  </p>
                </div>
                <div className='cardTitle'>Lifecycle Insights</div>
                <p className='cardDesc'>
                  Lifecycle Insights makes it easier to grow revenue with vCIO services. Enable the Phin integration to view your Security Awareness Training insights in your LCI dashboard and maximize your Business Review Meetings with customers.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
