import { createSlice } from '@reduxjs/toolkit'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'

export const reportGeneratorSlice = createSlice({
  name: 'reportGenerator',
  initialState: {
    loaders: {
      isLoadingReportGenerationRequest: false
    }
  },
  reducers: {
    setReportGenerationLoader: (state, action) => {
      state.loaders.isLoadingReportGenerationRequest = action.payload
    }
  }
})

export const {
  setReportGenerationLoader: setReportGenerationLoaderAction
} = reportGeneratorSlice.actions

export default reportGeneratorSlice.reducer

// viewers is an array of emails
// options is an object with four keys mapping to bools for each of the standard reporting settings { includeHistoricalData, includeUsersToWatch, includePhishingCSV, includeTrainingCSV }
// type weekly or monthly
export const generateReportThunk = ({ companyId, deliveryEmails, options, type }) => {
  return async (dispatch, getState, api) => {
    dispatch(setReportGenerationLoaderAction(true))
    try {
      const res = await api.post(`/api/companies/${companyId}/reports/generate`, { deliveryEmails, options, type })
      if (res.status === 200) {
        dispatch(setReportGenerationLoaderAction(false))
        return true
      } else {
        NotificationUtilities.sendErrorMessage('Failed to create Report Generation Request. Please try again or reach out to Phin Support for assistance.')
      }
    } catch (err) {
      NotificationUtilities.sendErrorMessage('Failed to create Report Generation Request. Please try again or reach out to Phin Support for assistance.')
      console.error(err)
    }
    dispatch(setReportGenerationLoaderAction(false))
    return false
  }
}
