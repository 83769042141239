import { createSlice } from '@reduxjs/toolkit'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'
import { integrationDisconnectedAction } from './IntegrationsSlice'

export const spamFilterBypassSlice = createSlice({
  name: 'spamFilterBypass',
  initialState: {
  }
})

export default spamFilterBypassSlice.reducer

export const disconnectSpamFilterBypassThunk = ({ companyId, history }) => {
  return async (dispatch, getState, api) => {
    try {
      const response = await api.delete(`/api/companies/${companyId}/integrations/spamFilterBypass/`)

      if (response.status === 200) {
        const { removedIntegrationId } = await response.json()
        dispatch(integrationDisconnectedAction(removedIntegrationId))

        history.push(`/companies/${companyId}/integrations`)
        return true
      } else {
        NotificationUtilities.sendErrorMessage('Failed to disconnect Graph API Mail Delivery. Please try again or contact Phin Support if issue persists.')
      }
    } catch (error) {
      NotificationUtilities.sendErrorMessage('Failed to disconnect Graph API Mail Delivery. Please try again or contact Phin Support if issue persists.')
    }

    return false
  }
}
