import Mustache from 'mustache'

function checkLengthIsGreaterThanMaxLengthAsBoolean (text, maxLength) {
  return text.length > maxLength
}

class ValidationUtils {
  /**
     * Uses RegEx to check and validate that the parameter is not just empty space
     * @static
     * @param {string} text The input text to be validated
     * @returns {boolean} Whether or not this input has whitespace or not (true if it does not)
     */
  static isNotWhiteSpace (text) {
    return /\S/.test(text) && this.isNotUndefinedOrNull(text)
  }

  /**
   * Returns whether the passed value is not null or undefined
   * @param {string} text The input text to be validated
   */
  static isNotUndefinedOrNull (text) {
    return text !== undefined && text !== null
  }

  static isNotEmptyString (text) {
    if (text === '') {
      throw new Error('text should not be empty.')
    }
  }

  /**
     * Determines if the passed email string is a valid email address
     * @static
     * @param {string} email The email to be validated
     * @returns {boolean} Whether or not this email adheres to the standard email address patterns
     */
  static isValidEmail (email) {
    // Regex that determines if the passed string adheres to the standard characters allowed in an email address (no special characters, must have an @, must have a ., etc.)
    const valid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
    return valid && this.isNotUndefinedOrNull(email)
  }

  static isValidPhone (phone) {
    // Regex that determines if the passed string adheres to the standard characters allowed in an email address (no special characters, must have an @, must have a ., etc.)
    const valid = /\d{10}/.test(phone)
    return valid && this.isNotUndefinedOrNull(phone)
  }

  /**
   * Tries to parse the passed text/html for mustache tags. Will return true if the mustache is valid and can be parsed.
   * @static
   * @param {string} text HTML or plaintext to be used in a Mustache template
   * @returns {boolean} Whether or not the passed text is valid markup
   */
  static isValidMustache (text) {
    try {
      Mustache.parse(text)
      return true && this.isNotWhiteSpace(text)
    } catch (err) {
      return false
    }
  }

  /**
   * Determines if all values in a specified object are false
   * @param {*} object An object of boolean keys
   * @returns {boolean} True if all are false, false otherwise
   */
  static allFalse (object) {
    for (const i in object) {
      if (object[i] === true) return false
    }
    return true
  }

  /**
   * Loops through an array of emails and runs the isValidEmail validation on each
   * @param {Array<String>} emails Array of email strings
   * @returns {boolean} true if all strings in Array are examples of emails
   */
  static areValidEmailAddresses (emails) {
    for (let i = 0; i < emails.length; i++) {
      if (emails[i] !== '') {
        if (!ValidationUtils.isValidEmail(emails[i])) {
          return false
        }
      }
    }
    return true
  }

  static areValidPhoneNumbers (phones) {
    for (let i = 0; i < phones.length; i++) {
      if (phones[i] !== '') {
        if (!ValidationUtils.isValidPhone(phones[i])) {
          return false
        }
      }
    }
    return true
  }

  static validateIsOnlyLetters (text) {
    if (!/^[a-zA-Z]+$/.test(text)) {
      throw new Error('text contains non letter characters.')
    }
  }

  static validateIsOnlyLettersAndAddressPunctuation (text) {
    if (!/^[a-zA-Z0-9.+\-]+$/.test(text)) {
      throw new Error('text contains non letter characters.')
    }
  }

  static validateIsOnlyLettersNumbersSpacesPlusAndDash (text) {
    if (!/^[a-zA-Z0-9\s+\-]+$/.test(text)) {
      throw new Error('text should only contain letters, numbers, spaces, plus, and dash')
    }
  }

  static validateIsOnlyLettersAndSpaces (text) {
    if (!/^[a-zA-Z\s]+$/.test(text)) {
      throw new Error('text should only contain letters and spaces.')
    }
  }

  static validateIsOnlyLettersNumbersAndPunctuation (text) {
    // validate that the text only contains letters, numbers, spaces, and punctuation
    if (!/^[a-zA-Z0-9\s.,;:!?'()\-]*$/.test(text)) {
      throw new Error('text should only contain letters, numbers, and punctuation.')
    }
  }

  static validateIsOnlyLettersNumbersAndExtendedPunctuation (text) {
    // validate that the text only contains letters, numbers, spaces, and punctuation and other stuff
    if (!/^[a-zA-Z0-9\s.,;:!?'\\\/^$*+?()|{}[\]\-"\u201C\u201D\u2013=@#%_&]*$/.test(text)) {
      throw new Error('text should only contain letters, numbers, and punctuation.')
    }
  }

  static validateLengthIsLessThan64 (text) {
    // validate that the text is less than 64 characters
    if (checkLengthIsGreaterThanMaxLengthAsBoolean(text, 64)) {
      throw new Error('Text is too long. It should be less than 64 characters.')
    }
  }

  static validateLengthIsLessThan256 (text) {
    // validate that the text is less than 256 characters
    if (checkLengthIsGreaterThanMaxLengthAsBoolean(text, 256)) {
      throw new Error('Text is too long. It should be less than 256 characters.')
    }
  }

  static validateLengthIsLessThan10000 (text) {
    // validate that the text is less than 10000 characters
    if (checkLengthIsGreaterThanMaxLengthAsBoolean(text, 10000)) {
      throw new Error('Text is too long. It should be less than 10000 characters.')
    }
  }

  static validateWelcomeEmailFields ({ emailBody, emailFromName, emailFromUsername, emailSubject }) {
    if (!emailBody) {
      throw new Error('Email body is required.')
    }
    if (!emailFromName) {
      throw new Error('Email from name is required.')
    }
    if (!emailSubject) {
      throw new Error('Email subject is required.')
    }

    if (!emailFromUsername) {
      throw new Error('Email from username is required.')
    }

    // validate that emailBody only contains letters, numbers, spaces, and punctuation
    try {
      this.validateIsOnlyLettersNumbersAndExtendedPunctuation(emailBody)
    } catch (err) {
      throw new Error('Email body contains invalid characters.')
    }

    // validate that the emailFromName is not too long
    try {
      this.validateLengthIsLessThan256(emailFromName)
    } catch (err) {
      throw new Error('Email from name is too long.')
    }

    // validate that the emailFromName only contains letters
    try {
      this.validateIsOnlyLetters(emailFromName)
    } catch (err) {
      throw new Error('Email from name contains non letter characters.')
    }

    try {
    // validate that the emailFromUsername is not too long
      this.validateLengthIsLessThan256(emailFromUsername)
    } catch (err) {
      throw new Error('Email from username is too long.')
    }

    // validate that the emailFromUsername only contains letters
    if (!/^[a-zA-Z]*$/.test(emailFromUsername)) {
      throw new Error('Email from username must only contain letters and numbers.')
    }

    try {
      // validate that the emailSubject is not too long
      this.validateLengthIsLessThan256(emailSubject)
    } catch (err) {
      throw new Error('Email subject is too long.')
    }

    try {
      // validate that the emailSubject only contains letters
      this.validateIsOnlyLettersNumbersAndExtendedPunctuation(emailSubject)
    } catch (err) {
      throw new Error('Email subject contains non letter characters.')
    }

    return true
  }
}

export default ValidationUtils
