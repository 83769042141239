import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import './index.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import Auth0ProviderWithHistory from './auth/Auth0ProviderWithHistory'
import App from './App'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/react'
import store from './store.js'
import { Provider } from 'react-redux'
import { StyledEngineProvider } from '@mui/material/styles'

if (!process.env.REACT_APP_DEV_MODE) {
  Sentry.init({ dsn: 'https://c10b4bcc35db4f14b973dcf599a06e02@o428197.ingest.sentry.io/5373368' })
}
if (process.env.REACT_APP_DEV_MODE === 'true') {
  const bodyEl = document.querySelector('body')
  const devFrameEl = document.createElement('div')
  bodyEl.prepend(devFrameEl)

  devFrameEl.style.position = 'fixed'
  devFrameEl.style.backgroundColor = '#fb3'
  devFrameEl.style.zIndex = 100000
  devFrameEl.style.height = '3px'
  devFrameEl.style.width = '100%'
  devFrameEl.style.top = 0
  devFrameEl.style.left = 0
  devFrameEl.style.pointerEvents = 'none'
}

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Auth0ProviderWithHistory>
        <StyledEngineProvider>
          <App />
        </StyledEngineProvider>
      </Auth0ProviderWithHistory>
    </Router>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
