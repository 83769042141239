import React from 'react'
import SelectOptions from '../SelectOptions'
import TemplateEditor from './TemplateEditor'
import { Grid, TextField } from '@mui/material'
import { PhinCard } from '../PhinCard'

class HTMLTemplateOptions extends React.Component {
  render () {
    const {
      handleEmailDomainEdit,
      handleEmailNameEdit,
      handleFromNameEdit,
      handleSubjectEdit,
      sendingDomains,
      selectedTemplate,
      setTemplateEditor
    } = this.props

    const fromName = selectedTemplate.fromEmail.split('@')[0]
    const fromDomain = selectedTemplate.fromEmail.split('@')[1]

    return (
      <div className='padding:-2'>
        <PhinCard
          title='Edit Template'
        >
          <TemplateEditor setTemplateEditor={setTemplateEditor} />
        </PhinCard>

        <PhinCard title='Email Address'>
          <Grid container spacing={2} sx={{ marginBottom: 'var(--phin-s-1)' }}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                name='senderSubject'
                label='Email Subject'
                labelClass='text-dark'
                value={selectedTemplate.subject}
                onChange={e => handleSubjectEdit(e)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                name='senderName'
                label='Display Name'
                labelClass='text-dark'
                value={selectedTemplate.fromName}
                onChange={e => handleFromNameEdit(e)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className='phin-flex-center'>
                <TextField
                  id='sending-name-input'
                  label='Sending Name'
                  value={fromName}
                  onChange={e => handleEmailNameEdit(e)}
                /> <div className='phin-h4'> @ </div>

                <SelectOptions
                  className='my-auto'
                  name='templateDomain'
                  options={sendingDomains}
                  value={fromDomain}
                  onChange={e => handleEmailDomainEdit(e)}
                />
              </div>
            </Grid>
          </Grid>
        </PhinCard>
      </div>
    )
  }
}

export default HTMLTemplateOptions
