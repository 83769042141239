import { Button, CircularProgress, Grid, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { IoCheckmarkCircleSharp, IoCloseCircleSharp } from 'react-icons/io5'
import { updateHubSpotPartnerNameAction, updateIsValidHubSpotPartnerAction, updateMinimumBillableUsersAction, updatePricePerUserOptionAction, validateHubSpotPartnerThunk } from '../../../store/HubSpotPartnersSlice'

export default function HubSpotPartnerSelector ({ distributorId, hubSpotCompanyId, setHubSpotCompanyId }) {
  const dispatch = useDispatch()
  const {
    loaders, hubSpotPartnerName, isValidHubSpotPartner,
    hubSpotPartnerErrorMessage, minimumBillableUsers, pricePerUserOption
  } = useSelector((state) => state.hubSpotPartners)
  const { isLoadingValidateHubSpotPartner } = loaders

  async function validateHubSpotPartner () {
    if (hubSpotCompanyId.replace(/\s/g, '') !== '') {
      await dispatch(validateHubSpotPartnerThunk({ distributorId, hubSpotCompanyId }))
    }
  }

  return (
    <Grid className='margin-top:1'>
      <hr />
      <div className='phin-h4 phin-flex-space-between'>Custom Phin Properties</div>
      <Grid className='phin-flex-center padding-top:1'>
        <div className='margin-left:-1 margin-right:-1'>
          {(isLoadingValidateHubSpotPartner)
            ? <CircularProgress />
            : (isValidHubSpotPartner)
                ? <IoCheckmarkCircleSharp size='3em' color='var(--phin-green)' />
                : <IoCloseCircleSharp size='3em' color='var(--phin-red)' />}
        </div>

        <div className='margin-left:-1 margin-right:-1'>
          <TextField
            id='hubSpot-partner-id'
            aria-label='HubSpot Partner ID'
            label='HubSpot Partner ID'
            variant='outlined'
            value={hubSpotCompanyId}
            onChange={(event) => {
              setHubSpotCompanyId(event.target.value)
              dispatch(updateIsValidHubSpotPartnerAction(false))
              dispatch(updateHubSpotPartnerNameAction())
              dispatch(updateMinimumBillableUsersAction())
              dispatch(updatePricePerUserOptionAction())
            }}
          />
        </div>

        <div className='margin-left:-1 margin-right:-1'>
          <Button variant='contained' onClick={() => validateHubSpotPartner()}>Validate</Button>
        </div>

      </Grid>

      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center' className='margin-top:1 phin-h5 margin-bottom:2'
      >
        <div><b>HubSpot Partner Name: </b>{hubSpotPartnerName}</div>
        <div><b>Minimum Billable Users: </b>{minimumBillableUsers}</div>
        <div><b>Price per User: </b>{pricePerUserOption}</div>
      </Grid>
      {(!isValidHubSpotPartner && hubSpotPartnerErrorMessage) && (
        <Grid className='phin-flex-center margin-top:1 phin-h5 margin-bottom:2' color='var(--phin-red)'>
          <>{hubSpotPartnerErrorMessage}</>
        </Grid>
      )}
    </Grid>
  )
}
