import React, { useEffect, useState } from 'react'
import {
  MDBIcon
} from 'mdb-react-ui-kit'
import { orgLevels, sendEngineTypes } from '../../../frontendConsts.js'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  setWelcomeEmailHasChangedAction, setWelcomeEmailFromAddressAction,
  setWelcomeEmailBodyAction, setWelcomeEmailSubjectAction,
  setWelcomeEmailFromNameAction,
  saveWelcomeEmailThunk, sendTestEmailThunk,
  getUnWelcomedUsersThunk, setUnwelcomedUserIdsAction
} from '../../../store/WelcomeEmailSlice'
import '../../css/branding.css'
import AudienceFiltersV2 from '../audience/AudienceFiltersV2'
import TimeScheduler from './TimeScheduler'
import NotificationUtilities from '../notifications/notificationUtils'
import ValidationUtils from '../../../utils/validationUtils'
import { toggleWelcomeEmailAutomationThunk, updateBrandingSettingsThunk } from '../../../store/BrandingSlice.js'
import AutomaticSendingBanner from './AutomaticSendingBanner.js'
import WelcomeUsersModal from '../WelcomeUsersModal.js'
import { bulkWelcomeThunk } from '../../../store/UsersSlice.js'
import { updateOnboardingChecklistDataThunk } from '../../../store/CompanySlice.js'
import WelcomeEmailInheritanceBadges from './WelcomeEmailInheritanceBadges.js'
import TestWelcomeEmailModal from './TestWelcomeEmailModal.js'
import { SaveCancelButtonsRow } from '../../../shared/components/SaveCancelButtonsRow.js'
import PhinStyledSwitch from './PhinStyledSwitch.js'
import { FreeTrialLockoutModal } from '../../partnerPages/components/FreeTrialLockoutModal.js'
import PhinModal from '../PhinModal.js'
import Moment from 'moment'

function WelcomeEmail ({ id, orgLevel }) {
  const dispatch = useDispatch()
  const history = useHistory()

  // from redux
  const {
    welcomeEmailBody, welcomeEmailSubject, welcomeEmailHasChanged,
    welcomeEmailFromDomain, welcomeEmailFromAddress,
    welcomeEmailFromName, loaders, unwelcomedUserIds,
    welcomeEmailOrgLevel
  } = useSelector((state) => state.welcomeEmail)
  const { isLoadingWelcomeEmail } = loaders
  const { company, onboardingChecklist } = useSelector((state) => state.company)

  const welcomeEmailSlice = useSelector((state) => state.welcomeEmail)
  const { logoUrl } = useSelector((state) => state.logo)
  const { groups } = useSelector((state) => state.users)
  const { brandingSettings } = useSelector((state) => state.branding)
  const { hasSpamFilterBypass } = useSelector((state) => state.integrations)
  const { partner } = useSelector((state) => state.partner)

  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showCancelSendAtModal, setShowCancelSendAtModal] = useState(false)
  const [editedEmailBody, setEditedEmailBody] = useState(welcomeEmailBody)
  const [editedBodyError, setEditedBodyError] = useState('')
  const [editedEmailSubject, setEditedEmailSubject] = useState(welcomeEmailSubject)
  const [editedSubjectError, setEditedSubjectError] = useState('')
  const [editedFromName, setEditedFromName] = useState('')
  const [editedFromNameError, setEditedFromNameError] = useState('')
  const [editedFromAddress, setEditedFromAddress] = useState('')
  const [editedFromAddressError, setEditedFromAddressError] = useState('')
  const [emailEditMode, setEmailEditMode] = useState(false)
  const [automaticSending, setAutomaticSending] = useState(brandingSettings.isAutoWelcomeEnabled || false)
  const [testEmailModal, setTestEmailModal] = useState(false)
  const [testEmail, setTestEmail] = useState('')
  const [useSpamFilterBypass, setUseSpamFilterBypass] = useState(hasSpamFilterBypass)
  const [isFreeTrialLockoutModalOpen, setIsFreeTrialLockoutModalOpen] = useState(false)

  const [tempAutoWelcomeSendTime, setTempAutoWelcomeSendTime] = useState(brandingSettings.autoWelcomeSendTime)
  const [tempAudienceFilterType, setTempAudienceFilterType] = useState(brandingSettings.audienceFilterType)
  const [tempAudienceParameters, setTempAudienceParameters] = useState(brandingSettings.audienceParameters.map(parameter => { return { ...parameter } }))

  const [showWelcomeUserModal, setShowWelcomeUserModal] = useState(false)

  useEffect(() => {
    if (!isLoadingWelcomeEmail && welcomeEmailSlice.welcomeEmailFromName) {
      setEditedEmailBody(welcomeEmailBody)
      setEditedFromName(welcomeEmailFromName)
      setEditedFromAddress(welcomeEmailFromAddress)
      setEditedEmailSubject(welcomeEmailSubject)
    }
  }, [isLoadingWelcomeEmail])

  useEffect(() => {
    if (orgLevel === orgLevels.COMPANY && brandingSettings && brandingSettings.isAutoWelcomeEnabled) {
      dispatch(getUnWelcomedUsersThunk(id))
    }
  }, [brandingSettings])

  async function handleAutomaticWelcomeEmailToggle () {
    const success = await dispatch(toggleWelcomeEmailAutomationThunk(id, !automaticSending))

    if (success) {
      setAutomaticSending(!automaticSending)
    }
  }

  async function handleSaveAutomaticWelcomeSettings () {
    await dispatch(updateBrandingSettingsThunk(id, {
      autoWelcomeSendTime: tempAutoWelcomeSendTime,
      audienceParameters: tempAudienceParameters,
      audienceFilterType: tempAudienceFilterType
    }))
  }

  function handleChangeTempAutoWelcomeSendTime (val) {
    setTempAutoWelcomeSendTime(val)
  }

  function handleChangeTempAudienceParameters (val) {
    setTempAudienceParameters(val)
  }

  function handleChangeTempAudienceFilterType (val) {
    setTempAudienceFilterType(val)
  }

  const discardEmailChanges = () => {
    setEditedEmailBody(welcomeEmailBody)
    setEditedEmailSubject(welcomeEmailSubject)
    setEditedFromName(welcomeEmailFromName)
    setEditedFromAddress(welcomeEmailFromAddress)

    dispatch(setWelcomeEmailHasChangedAction(false))
  }

  const discardSendAtChanges = () => {
    setTempAutoWelcomeSendTime(brandingSettings.autoWelcomeSendTime)
    setTempAudienceFilterType(brandingSettings.audienceFilterType)
    setTempAudienceParameters(brandingSettings.audienceParameters)
  }

  const markTemplateChanged = () => {
    if (!welcomeEmailHasChanged) {
      dispatch(setWelcomeEmailHasChangedAction(true))
    }
  }

  const emailSubjectChanged = (e, changedText) => {
    e.preventDefault()
    markTemplateChanged()
    try {
      ValidationUtils.isNotEmptyString(changedText)
      ValidationUtils.validateIsOnlyLettersNumbersAndExtendedPunctuation(changedText)
      ValidationUtils.validateLengthIsLessThan256(changedText)
      setEditedSubjectError('')
    } catch (err) {
      setEditedSubjectError(err.message)
    }
    setEditedEmailSubject(changedText)
  }

  const emailBodyChanged = (changedText) => {
    markTemplateChanged()
    try {
      ValidationUtils.isNotEmptyString(changedText)
      ValidationUtils.validateIsOnlyLettersNumbersAndExtendedPunctuation(changedText)
      ValidationUtils.validateLengthIsLessThan10000(changedText)
      setEditedBodyError('')
    } catch (err) {
      setEditedBodyError(err.message)
    }
    setEditedEmailBody(changedText)
  }

  const emailFromNameChanged = (e, changedText) => {
    e.preventDefault()
    markTemplateChanged()
    try {
      ValidationUtils.isNotEmptyString(changedText)
      ValidationUtils.validateIsOnlyLettersNumbersSpacesPlusAndDash(changedText)
      ValidationUtils.validateLengthIsLessThan256(changedText)
      setEditedFromNameError('')
    } catch (err) {
      setEditedFromNameError(err.message)
    }
    setEditedFromName(changedText)
  }

  const emailFromAddressChanged = (e, changedText) => {
    e.preventDefault()
    markTemplateChanged()
    try {
      ValidationUtils.isNotEmptyString(changedText)
      ValidationUtils.validateIsOnlyLettersNumbersSpacesPlusAndDash(changedText)
      ValidationUtils.validateLengthIsLessThan64(changedText)
      setEditedFromAddressError('')
    } catch (err) {
      setEditedFromAddressError(err.message)
    }
    setEditedFromAddress(changedText)
  }

  const saveWelcomeEmail = () => {
    if (editedBodyError || editedSubjectError || editedFromAddressError || editedFromNameError) {
      NotificationUtilities.sendErrorMessage('Please fix any outstanding validation errors before saving template. Boxes highlighted in red have errors.')
    } else {
      dispatch(setWelcomeEmailSubjectAction(editedEmailSubject))
      dispatch(setWelcomeEmailBodyAction(editedEmailBody))
      dispatch(setWelcomeEmailFromNameAction(editedFromName))
      dispatch(setWelcomeEmailFromAddressAction(editedFromAddress))
      dispatch(saveWelcomeEmailThunk({ id, orgLevel, emailSubject: editedEmailSubject, emailBody: editedEmailBody, emailFromName: editedFromName, emailFromAddress: editedFromAddress }))
    }
  }

  async function sendTestWelcomeEmail () {
    if (!ValidationUtils.isValidEmail(testEmail)) {
      NotificationUtilities.sendWarningMessage('You must input a valid email address')
      return
    }

    const sendingEngine = useSpamFilterBypass ? sendEngineTypes.SPAM_FILTER_BYPASS : sendEngineTypes.MANDRILL

    const success = await dispatch(sendTestEmailThunk(id, testEmail, sendingEngine))

    if (success) {
      setTestEmailModal(false)
      setTestEmail('')
    }
  }

  const today = Moment()

  const [sendDate, setSendDate] = useState(today)
  const [sendTime, setSendTime] = useState(today)

  async function scheduleWelcomeEmails () {
    NotificationUtilities.sendInfoMessage('Scheduling welcome messages...')

    const completeDate = Moment(sendDate)
    completeDate.hour(sendTime.hours())
    completeDate.minute(sendTime.minutes())
    const success = await dispatch(bulkWelcomeThunk(id, unwelcomedUserIds, completeDate.toDate()))
    setSendDate(today)

    if (success) {
      dispatch(setUnwelcomedUserIdsAction([]))
      setShowWelcomeUserModal(false)
      // TODO: we should be doing this check-off in the bulkWelcomeThunk API call
      if (!onboardingChecklist.hasWelcomedUsers) {
        dispatch(updateOnboardingChecklistDataThunk(id, { hasWelcomedUsers: true }))
      }
      NotificationUtilities.sendSuccessMessage('Welcome messages have been scheduled. It may take a few minutes for the changes to appear.')
    }
  }

  function closeCancelModal () {
    setShowCancelModal(false)
  }

  function discardChangesOnClick () {
    setShowCancelModal(true)
  }

  function confirmChangesDiscard () {
    discardEmailChanges()
    setShowCancelModal(false)
  }

  function confirmSendAtChangesDiscard () {
    discardSendAtChanges()
    setShowCancelSendAtModal(false)
  }

  function updateEmailEditMode () {
    if (partner && partner.freeTrial) {
      setIsFreeTrialLockoutModalOpen(!isFreeTrialLockoutModalOpen)
      setEmailEditMode(false)
    } else {
      setEmailEditMode(!emailEditMode)
    }
  }

  return (
    <div data-testid='welcome-email-container' className='pageContainer'>
      <PhinModal
        size='medium'
        title='Test Welcome Email'
        isOpen={testEmailModal}
        close={() => setTestEmailModal(!testEmailModal)}
        action={sendTestWelcomeEmail}
        actionText='Send'
      >
        <TestWelcomeEmailModal
          testEmail={testEmail}
          setTestEmail={setTestEmail}
          useSpamFilterBypass={useSpamFilterBypass}
          setUseSpamFilterBypass={setUseSpamFilterBypass}
          testEmailModal={testEmailModal}
          setTestEmailModal={setTestEmailModal}
          hasSpamFilterBypass={hasSpamFilterBypass}
          sendTestWelcomeEmail={sendTestWelcomeEmail}
        />
      </PhinModal>

      <PhinModal
        size='large'
        title='Schedule Welcome Emails'
        isOpen={showWelcomeUserModal}
        close={() => setShowWelcomeUserModal(!showWelcomeUserModal)}
        action={scheduleWelcomeEmails}
        actionText='Schedule'
      >
        <WelcomeUsersModal
          userCount={unwelcomedUserIds.length}
          today={today}
          sendTime={sendTime}
          setSendTime={setSendTime}
          sendDate={sendDate}
          setSendDate={setSendDate}
        />
      </PhinModal>

      <FreeTrialLockoutModal
        open={isFreeTrialLockoutModalOpen}
        feature='welcome emails'
        featureIntroTitle='configure custom welcoming emails'
        description={'Welcome emails introduce and prepare your end-users for the security awareness training journey they are about to embark on. Customize the welcome email to give it your company\'s logo and tone.'}
        KBLink='https://www.phinsec.io/knowledge/how-to-configure-welcome-emails'
        closeModal={() => setIsFreeTrialLockoutModalOpen(false)}
      />

      <PhinModal
        isOpen={showCancelModal}
        title='Discard Changes'
        close={() => closeCancelModal()}
        closeText='Keep Changes'
        action={() => confirmChangesDiscard()}
        actionText='Discard Changes'
      >
        <p>Are you sure you want to discard your changes? <strong>This cannot be undone!</strong>
        </p>
      </PhinModal>

      <PhinModal
        isOpen={showCancelSendAtModal}
        title='Discard Changes'
        close={() => setShowCancelSendAtModal(false)}
        closeText='Keep Changes'
        action={() => confirmSendAtChangesDiscard()}
        actionText='Discard Changes'
      >
        <p>Are you sure you want to discard your changes? <strong>This cannot be undone!</strong>
        </p>
      </PhinModal>

      {(!isLoadingWelcomeEmail) && (
        <div id='welcomeEmailCard'>
          <div className='welcomeCardHeader'>
            <p className='phinCardHeader'>Welcome Emails</p>
            <p className='welcomeEmailDesc'>
              Welcome emails are an important step in the Phin onboarding process. They provide an advanced notification to your end users that they will be getting phishing and cybersecurity training. In this section you can modify and test your welcome email contents. For more information please visit the <a target='_blank' href='https://www.phinsec.io/knowledge/how-to-configure-welcome-emails' rel='noreferrer'><b>Phin Knowledge Base</b></a>.
            </p>
          </div>

          {orgLevel === orgLevels.COMPANY && (
            <div>
              <div className='featureToggle'>
                <div className='toggleGroup'>
                  <div className='toggleBody'>
                    <h3 className='featureTitle'>
                      Automatic Welcome Email Sending
                      <span className='featureSubText'>&nbsp;&#40;optional&#41;</span>
                    </h3>
                    <p className='featureDesc'>
                      <span>Enabling automatic welcome email sending will automatically welcome users after they are added via user sync, uploaded via csv, or added manually. Welcome emails will be sent at the specfied time. <strong>Note: If automatic email sending is turned off, you will need to manually select and welcome users from the <Link to={`/companies/${id}/users`}>Users page</Link>.</strong></span>
                    </p>
                  </div>
                  <label className='switch branding-switch user-collapse'>
                    <input
                      id='automatic-welcome-email-toggle'
                      aria-label='Automatic Welcome Email Toggle'
                      type='checkbox'
                      checked={automaticSending}
                      onChange={handleAutomaticWelcomeEmailToggle}
                    />
                    <span className='slider round slider-auto-welcome' />
                  </label>
                </div>
              </div>

              {(automaticSending) && (
                <section id='autoSendGroup'>
                  <h3 className='phinCardSubHeadingText subheadingTextLeftAlign' style={{ marginLeft: '3em' }}>Automatic Welcome Email Configuration</h3>
                  <div className='automaticAdjustmentGroup'>
                    <TimeScheduler
                      timezone={company.timezone.value}
                      label='When a new user is added, emails will be scheduled to be sent at the following time:'
                      sendTime={tempAutoWelcomeSendTime}
                      setSendTime={handleChangeTempAutoWelcomeSendTime}
                      title='Send At'
                    />
                    <AudienceFiltersV2
                      groups={groups}
                      parameters={tempAudienceParameters}
                      setParameters={handleChangeTempAudienceParameters}
                      audienceFilterType={tempAudienceFilterType}
                      setAudienceFilterType={handleChangeTempAudienceFilterType}
                      id={id}
                    />

                    {/* Users who match our audience, BUT are not welcomed */}
                    {(unwelcomedUserIds && unwelcomedUserIds.length > 0) && (
                      <AutomaticSendingBanner
                        unwelcomedUserIds={unwelcomedUserIds}
                        setShowWelcomeUserModal={setShowWelcomeUserModal}
                      />
                    )}

                    <div className='margin-top:0'>
                      <SaveCancelButtonsRow
                        action={handleSaveAutomaticWelcomeSettings}
                        actionText='Save Changes'
                        actionId='save-sendAt-time-button'
                        close={() => setShowCancelSendAtModal(true)}
                        closeText='Discard Changes'
                        closeId='cancel-sendAt-time-button'
                      />
                    </div>
                  </div>
                </section>
              )}
            </div>
          )}

          <div className='emailPreviewGroup'>
            <div className='emailPreviewHeadingGroup'>
              <h3 className='emailPreviewSubHeadingLayout phinCardSubHeadingText'>Welcome Email Preview/Editor</h3>
              <div className='emailPreviewToggle'>
                Preview
                <PhinStyledSwitch
                  id='welcome-email-edit-toggle' data-testid='email-edit-toggle-btn' aria-label='Welcome Email Edit Toggle' checked={emailEditMode} onClickFunction={() => { updateEmailEditMode() }}
                />
                Edit
              </div>
            </div>

            <div className='emailPreviewPaneGroup'>
              <div className='emailPreviewPane'>

                <>
                  {(!emailEditMode) && (
                    <>
                      <div className='emailMetadataContainer' style={{}}>
                        <div className='emailMetadataDetailsGroup'>
                          <p><b>From: </b> {editedFromName} {`<${editedFromAddress}${welcomeEmailFromDomain}>`}</p>
                          <p><b>Subject: </b>{editedEmailSubject}</p>
                        </div>

                        <WelcomeEmailInheritanceBadges
                          welcomeEmailOrgLevel={welcomeEmailOrgLevel}
                          orgLevel={orgLevel}
                        />
                      </div>
                      <div className='emailHeader'>
                        <img
                          alt=''
                          src={logoUrl}
                        />
                      </div>
                    </>
                  )}

                  <div className='emailBody'>
                    {(!emailEditMode) && (
                      <p style={{ whiteSpace: 'pre-wrap' }}>{welcomeEmailBody}</p>
                    )}
                    {(emailEditMode) && (
                      <div className='editContainer'>
                        <div className='subjectLineGroup'>
                          <section className='labelGroup'>
                            <p>From Name</p>
                            <input data-testid='from-name-input' type='text' placeholder='From' className={`textInputBox2 ${editedFromNameError ? 'error' : ''}`} value={editedFromName} onChange={(e) => { emailFromNameChanged(e, e.target.value) }} />
                            {editedFromNameError && <p data-testid='from-name-error' className='branding-text-small branding-text-red'>{editedFromNameError}</p>}
                          </section>
                          <section className='labelGroupFromAddress'>
                            <p>From Address</p>
                            <div className='fromAddressRowContainer'>
                              <input data-testid='from-address-input' type='text' placeholder='From Address' className={`textInputBoxAddress ${editedFromAddressError ? 'error' : ''}`} value={editedFromAddress} onChange={(e) => { emailFromAddressChanged(e, e.target.value) }} />
                              <span className='domainNamePadding'>@phinsecurity.com</span>
                            </div>
                            {editedFromAddressError && <p data-testid='from-address-error' className='branding-text-small branding-text-red'>{editedFromAddressError}</p>}
                          </section>
                          <section className='labelGroup'>
                            <p>Subject Line</p>
                            <input data-testid='email-subject-input' type='text' placeholder='Subject' className={`textInputBox2 ${editedSubjectError ? 'error' : ''}`} value={editedEmailSubject} onChange={(e) => { emailSubjectChanged(e, e.target.value) }} />
                            {editedSubjectError && <p data-testid='email-subject-error' className='branding-text-small branding-text-red'>{editedSubjectError}</p>}
                          </section>
                        </div>
                        <section className='labelGroup'>
                          <p>Email Contents</p>
                          <textarea
                            data-testid='email-body-input'
                            className={`emailBodyEditArea ${editedBodyError ? 'error' : ''}`}
                            value={editedEmailBody}
                            onChange={(e) => { emailBodyChanged(e.target.value) }}
                          />
                          {editedBodyError && <p data-testid='body-error' className='branding-text-small branding-text-red'>{editedBodyError}</p>}
                        </section>

                        <SaveCancelButtonsRow
                          action={saveWelcomeEmail}
                          actionText='Save Changes'
                          actionId='save-welcome-email-update-button'
                          close={discardChangesOnClick}
                          closeText='Discard Changes'
                          closeId='cancel-welcome-email-update-button'
                        />
                      </div>
                    )}
                  </div>
                </>

              </div>

              {orgLevel === orgLevels.COMPANY && (
                <div className='emailButtonGroup'>
                  <button onClick={() => setTestEmailModal(true)} className='primaryBtn noSelect'>Test</button>
                  <button onClick={() => history.push(`/companies/${id}/users`)} className='redirectBtn'>User Management <MDBIcon icon='arrow-right' /></button>
                </div>
              )}
            </div>

          </div>

        </div>
      )}
    </div>

  )
}
export default WelcomeEmail
