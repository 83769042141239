import { createSlice } from '@reduxjs/toolkit'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'

export const distributorIntegrationsSlice = createSlice({
  name: 'distributorIntegrations',
  initialState: {
    distributorIntegrationsMap: null,
    distributorIdOfLastFetch: null,
    loaders: {
      isLoadingDistributorIntegrations: false
    }
  },
  reducers: {
    setIsLoadingDistributorIntegrations: (state, action) => {
      state.loaders.isLoadingDistributorIntegrations = action.payload
    },
    setDistributorIntegrationsMap: (state, action) => {
      const newMap = {}
      for (const distributorIntegration of action.payload.distributorIntegrations) {
        newMap[distributorIntegration.id] = distributorIntegration
      }

      state.distributorIntegrationsMap = newMap
      state.distributorIdOfLastFetch = action.payload.distributorId
    },
    removeDistributorIntegration: (state, action) => {
      const newMap = { ...state.distributorIntegrationsMap }
      delete newMap[action.payload]

      state.distributorIntegrationsMap = newMap
    },
    addDistributorIntegration: (state, action) => {
      const newMap = { ...state.distributorIntegrationsMap }

      newMap[action.payload.id] = action.payload

      state.distributorIntegrationsMap = newMap
    }
  }
})

export const {
  setIsLoadingDistributorIntegrations: setIsLoadingDistributorIntegrationsAction,
  setDistributorIntegrationsMap: setDistributorIntegrationsMapAction,
  removeDistributorIntegration: removeDistributorIntegrationAction,
  addDistributorIntegration: addDistributorIntegrationAction
} = distributorIntegrationsSlice.actions

export default distributorIntegrationsSlice.reducer

export const getDistributorIntegrationsThunk = (distributorId) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingDistributorIntegrationsAction(true))
    try {
      const response = await api.get(`/api/distributors/${distributorId}/integrations`)
      if (response.status === 200) {
        const distributorIntegrations = await response.json()
        dispatch(setDistributorIntegrationsMapAction({ distributorIntegrations, distributorId }))
      } else {
        const statusObj = await response.json()

        setTimeout(() => {
          NotificationUtilities.sendErrorMessage(statusObj.error)
        }, 0)
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to load Distributor Integrations. Please try again or contact Phin Support for assistance.')
      }, 0)
    }
    dispatch(setIsLoadingDistributorIntegrationsAction(false))
  }
}
