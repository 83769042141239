import React from 'react'
import { apiRequestUtils } from '../../../utils/apiRequestUtils'

function CustomTrainingVideoPreview ({ companyId, courseDefinition, ...other }) {
  return (
    <>
      <video className='video-center' id='player' width='100%' controls>
        <source src={apiRequestUtils.getCourseVideo({ companyId, courseDefinition })} type='video/mp4' />
        Your browser does not support the video tag.
      </video>
    </>
  )
}

export default CustomTrainingVideoPreview
