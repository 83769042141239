import { useHistory } from 'react-router-dom'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from './components/Spinner'
import { getPolicyCatalogThunk } from '../store/PoliciesSlice'
import { Button, Grid } from '@mui/material'
import { IoAddCircleSharp, IoRocket } from 'react-icons/io5'
import { PhinCard } from './components/PhinCard'
import PDFObject from 'pdfobject/pdfobject.js'
import { apiRequestUtils } from '../utils/apiRequestUtils'

function PolicyCard ({ policy, openConfirmModal, companyId }) {
  useEffect(() => {
    async function getPolicy () {
      const policyIFrame = document.querySelector(`#policy-frame-${policy.id}`)
      policyIFrame.src = apiRequestUtils.getPolicyRootPath(policy.id, companyId)
      PDFObject.embed(apiRequestUtils.getPolicyRootPath(policy.id, companyId), `#policy-frame-${policy.id}`)
    }
    getPolicy()
  }, [])

  return (
    <PhinCard
      id={`policy-card-${policy.name}`}
      key={policy.id}
      title={policy.name}
      description={policy.description || 'No description'}
    >
      <Grid
        container
        direction='column'
        justifyContent='space-between'
      >

        <iframe id={`policy-frame-${policy.id}`} className=' w-100' style={{ height: '200px', backgroundColor: 'grey', marginBottom: 'var(--phin-s0)' }} />

        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
        >
          <Button
            id={`distribute-policy-${policy.name}-button`}
            aria-label='Distribute Policy Button'
            variant='contained'
            onClick={() => openConfirmModal(policy)}
            startIcon={<IoRocket size='1.5em' />}
          >
            Distribute Policy
          </Button>
        </Grid>
      </Grid>
    </PhinCard>
  )
}

function renderPolicies (policyCatalog, openConfirmModal, companyId) {
  return policyCatalog.map(policy =>
    <Grid item key={policy.id}>
      <PolicyCard
        policy={policy}
        openConfirmModal={openConfirmModal}
        companyId={companyId}
      />
    </Grid>
  )
}

function CatalogPolicyPage ({ id }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { company, loaders } = useSelector((state) => state.company)
  const { isLoadingCompany } = loaders
  const { policies, policyCatalog, loaders: policyLoader } = useSelector((state) => state.policies)
  const { isLoadingPolicies, isLoadingPolicyCatalog } = policyLoader

  useEffect(() => {
    if (!policyCatalog) {
      dispatch(getPolicyCatalogThunk({ id }))
    }
  }, [])

  function goToUploadPolicyPage () {
    history.push(`/companies/${id}/policy/upload`)
  }

  function goToPolicyDistributionPage (policy) {
    history.push(`/companies/${id}/policy/distribute/${policy.id}`)
  }

  return (
    <div>
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
      >
        <h1>Policy Document Catalog</h1>

        <p>View our available policy document selection here as well as distribute new policy documents to your employees</p>
      </Grid>

      {(!company || isLoadingCompany ||
      !policies || isLoadingPolicies ||
      !policyCatalog || isLoadingPolicyCatalog) && (
        <Spinner />
      )}

      {(company && !isLoadingCompany &&
      policies && !isLoadingPolicies &&
      policyCatalog && !isLoadingPolicyCatalog) && (
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
        >

          <Button
            id='upload-policy-button'
            onClick={goToUploadPolicyPage}
            variant='contained'
            color='primary'
            size='lg'
            startIcon={<IoAddCircleSharp size='3em' />}
          >
            Upload A Custom Policy
          </Button>

          <Grid container spacing={4} sx={{ marginTop: 'var(--phin-s-2)' }}>
            {renderPolicies(policyCatalog, goToPolicyDistributionPage, id)}
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default CatalogPolicyPage
