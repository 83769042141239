import { Alert, Snackbar } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { notificationClosedAction } from '../../../store/MuiNotificationsSlice'

export function MuiNotificationWrapper () {
  const dispatch = useDispatch()
  const { open, alertSeverity, alertMessage } = useSelector((state) => state.muiNotifications)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(notificationClosedAction())
  }

  return (
    <>
      {(open && alertSeverity && alertMessage) && (
        <Snackbar
          sx={{ marginTop: '50px', maxWidth: '400px' }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          autoHideDuration={3500}
          onClose={handleClose}
        >
          <Alert
            id={`phin-alert-${alertSeverity}`}
            aria-label={`Phin Alert ${alertSeverity}`}
            onClose={handleClose}
            severity={alertSeverity}
            sx={{ width: '100%', borderRadius: '8px' }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}
