import React, { useState } from 'react'
import NotificationUtilities from '../notifications/notificationUtils'
import { useHistory } from 'react-router-dom'
import ValidationUtils from '../../../utils/validationUtils'
import { Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material'
import PhinModal from '../PhinModal'

function ClientCreator ({ isOpen, closeModal, createClient }) {
  const history = useHistory()

  const [clientName, setClientName] = useState('')
  const [shouldCreateAnotherCompany, setShouldCreateAnotherCompany] = useState(false)

  const handleCancelCreation = () => {
    closeModal()
  }

  const handleCreation = async () => {
    if (ValidationUtils.isNotWhiteSpace(clientName)) {
      try {
        const companyId = await createClient(clientName)
        if (shouldCreateAnotherCompany) {
          setClientName('')
        } else {
          closeModal()
          history.push(`/companies/${companyId}`)
        }
      } catch (err) {

      }
    } else {
      NotificationUtilities.sendWarningMessage('You must input a valid company name.')
    }
  }

  return (
    <PhinModal
      isOpen={isOpen}
      title='Company Creator'
      close={() => handleCancelCreation()}
      action={handleCreation}
      actionText='Create Company'
    >
      <p>Give this new client a name, then log in and add some users to your new client.</p>
      <div className='padding-top:1 padding-bottom:2'>
        <TextField
          style={{ width: '100%' }}
          id='new-client-name-input'
          label='Company Name'
          value={clientName}
          onChange={(event) => {
            setClientName(event.target.value)
          }}
        />
      </div>

      <FormGroup>
        <FormControlLabel
          onChange={() => setShouldCreateAnotherCompany(!shouldCreateAnotherCompany)}
          checked={shouldCreateAnotherCompany}
          control={<Checkbox id='create-another-company-checkbox' />}
          label='Create Another Company'
        />
      </FormGroup>
    </PhinModal>
  )
}

export default ClientCreator
