import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  MDBIcon, MDBRow, MDBCardBody, MDBCard, MDBCol,
  MDBCardFooter, MDBContainer, MDBCardHeader, MDBCardTitle
} from 'mdb-react-ui-kit'
import NotificationUtilities from '../notifications/notificationUtils'
import { apiRequestUtils } from '../../../utils/apiRequestUtils'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../Spinner'
import { integrationStatusUpdatedAction } from '../../../store/IntegrationsSlice'
import { integrationStatuses, microsoftIntegrationStatuses, integrationTypes } from '../../../frontendConsts.js'
import { disconnectSpamFilterBypassThunk } from '../../../store/SpamFilterBypassSlice'
import { updateOnboardingChecklistDataThunk } from '../../../store/CompanySlice'
import { Alert, AlertTitle, Button, FormControl, InputLabel, MenuItem, Select, TextField, Grid } from '@mui/material'
import { StatusBreadCrumbs } from './StatusBreadCrumbs.js'
import PhinModal from '../PhinModal.js'

export function MicrosoftEmailBypassPage ({ id }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const intervalRef = useRef()

  const { company, loaders } = useSelector((state) => state.company)
  const { isLoadingCompany } = loaders
  const { integrationsMap, loaders: integrationLoaders } = useSelector((state) => state.integrations)
  const { isLoadingIntegrations, isLoadingDisableSpamBypass } = integrationLoaders

  const [testEmail, setTestEmail] = useState('')
  const [fromAddress, setFromAddress] = useState('')
  const [fromName, setFromName] = useState('')
  const [disconnectModal, setDisconnectModal] = useState(false)
  const fromEmailOptions = [
    'bill@emails.notificationhandler.com',
    'research@coronacouncil.org',
    'reginald@invites.shippingalerts.com',
    'maryberry@orders.berrysupply.net'
  ]

  useEffect(() => {
    if (integrationsMap && integrationsMap.spamFilterBypass) {
      if (integrationsMap.spamFilterBypass.integrationStatus === microsoftIntegrationStatuses.CONSENT_SUBMITTED) {
        validateConsent()
        return function cleanUpInterval () {
          clearInterval(intervalRef.current)
        }
      }
    }
  }, [integrationsMap])

  async function handleDisconnect () {
    const success = await dispatch(disconnectSpamFilterBypassThunk({ companyId: id, history }))

    if (success) {
      setDisconnectModal(false)
    }
  }

  async function sendTestEmail () {
    if (testEmail !== '' && fromAddress !== '' && fromName) {
      const res = await apiRequestUtils.post(`/api/companies/${id}/settings/integrations/test-email`, {
        toAddress: testEmail,
        fromAddress,
        fromName
      })
      if (res.status !== 200) {
        NotificationUtilities.sendErrorMessage('Error sending test email')
      } else {
        NotificationUtilities.sendSuccessMessage('Test Email Successfully sent')
      }
    } else {
      NotificationUtilities.sendWarningMessage('Please input a valid email and from address to test')
    }
  }

  const consentToIntegration = () => {
    const redirectURL = encodeURIComponent(`${window.location.protocol}//${window.location.host}/integrations`)
    window.open(`https://login.microsoftonline.com/common/adminconsent?client_id=${process.env.REACT_APP_MICROSOFT_SPAM_BYPASS_APPLICATION_ID}&redirect_uri=${redirectURL}&state=${id}%7CspamFilterBypass`, '_self')
  }

  const validateConsent = () => {
    apiRequestUtils.put(`/api/companies/${id}/integrations/microsoft/validateConsent`, { integrationId: integrationTypes.SPAM_FILTER_BYPASS }).then(async (response) => {
      if (response.status === 200) {
        const data = await response.json()
        if (data.integrationStatus === microsoftIntegrationStatuses.CONNECTED) {
          dispatch(integrationStatusUpdatedAction({ integrationId: integrationTypes.SPAM_FILTER_BYPASS, integrationStatus: data.integrationStatus }))
        } else {
          intervalRef.current = setInterval(() => {
            apiRequestUtils.put(`/api/companies/${id}/integrations/microsoft/validateConsent`, { integrationId: integrationTypes.SPAM_FILTER_BYPASS }).then(async (response) => {
              if (response.status === 200) {
                const data = await response.json()
                if (data.integrationStatus === microsoftIntegrationStatuses.CONNECTED) {
                  clearInterval(intervalRef.current)
                  dispatch(integrationStatusUpdatedAction({ integrationId: integrationTypes.SPAM_FILTER_BYPASS, integrationStatus: data.integrationStatus }))
                  dispatch(updateOnboardingChecklistDataThunk(id, { hasEmailSettings: true }))
                }
              }
            })
          }, 10000)
        }
      } else {
        intervalRef.current = setInterval(() => {
          apiRequestUtils.put(`/api/companies/${id}/integrations/microsoft/validateConsent`, { integrationId: integrationTypes.SPAM_FILTER_BYPASS }).then(async (response) => {
            if (response.status === 200) {
              const data = await response.json()
              if (data.integrationStatus === microsoftIntegrationStatuses.CONNECTED) {
                clearInterval(intervalRef.current)
                dispatch(integrationStatusUpdatedAction({ integrationId: integrationTypes.SPAM_FILTER_BYPASS, integrationStatus: data.integrationStatus }))
                dispatch(updateOnboardingChecklistDataThunk(id, { hasEmailSettings: true }))
              }
            }
          })
        }, 10000)
      }
    })
  }

  const getBreadCrumbSteps = () => {
    const steps = { ...microsoftIntegrationStatuses }

    delete steps.TEMP_STAGING
    delete steps.TEMP_STAGED
    delete steps.STAGING
    delete steps.STAGED
    delete steps.COMMITTING

    return Object.keys(steps).map((key) => steps[key])
  }

  const getBreadCrumbStep = (status) => {
    if (!status) return 0 // Show not connected if no integration exists
    const steps = getBreadCrumbSteps()
    return Object.keys(steps).findIndex((key) => steps[key] === status)
  }

  return (
    <MDBContainer>
      <PhinModal
        isOpen={disconnectModal}
        title='Disconnect'
        close={() => setDisconnectModal(false)}
        closeText='Cancel'
        action={handleDisconnect}
        actionText='Disconnect'
        actionColor='error'
      >
        <p>Are you sure you want to disconnect Graph API Mail Delivery? Emails may not reach end users if Phin has not been properly allowlisted.</p>
      </PhinModal>

      <MDBContainer className='m-5'>
        <div className='Alert'>
          <Alert severity='warning'>
            <AlertTitle>
              Caution
            </AlertTitle>
            Microsoft has been making continuous updates to their email security offering and unfortunately this has impacted the effectiveness of our Graph API Mail Delivery feature for some of our partners. Run a test to make sure this works with your tenant configurations. In either scenario, remember to always use allowlisting to ensure proper delivery.
            For more information on how to configure allowlisting check <a target='_blank' href='https://www.phinsec.io/knowledge/allow-listing-guide' rel='noreferrer'>here</a>.
          </Alert>
        </div>

        <MDBRow className='mt-4 d-flex justify-content-center'>
          <h2 className='text-center'>Phin Microsoft Graph API Mail Delivery</h2>
        </MDBRow>
        {company && integrationsMap && (
          <StatusBreadCrumbs
            steps={getBreadCrumbSteps()}
            step={getBreadCrumbStep(integrationsMap.spamFilterBypass?.integrationStatus)}
          />
        )}

        {(!company || !integrationsMap ||
      isLoadingCompany || isLoadingIntegrations ||
      isLoadingDisableSpamBypass) && (
        <Spinner />
        )}

        {(company && integrationsMap &&
      !isLoadingCompany && !isLoadingIntegrations &&
      !isLoadingDisableSpamBypass) && (
        <>
          {(!integrationsMap.spamFilterBypass || integrationsMap.spamFilterBypass.integrationStatus === microsoftIntegrationStatuses.NOT_CONNECTED) && (
            <>
              <div className='consentCard'>
                <div className='consentTitle'>You will need to grant Microsoft consent before you can start sending emails with this integration.</div>
                <div className='permissions'>
                  <p className='consentSubtitle'>What are the permissions?</p>
                  <ul>
                    <li>
                      Mail.ReadWrite
                      <ul>
                        <li>
                          used to create the email messages directly in a user's email mailbox without the use of Simple Mail Transfer Protocol (SMTP)
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      User.Read.All
                      <ul>
                        <li>
                          used to fetch the list of users to send emails to
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Sign in and read user profile
                      <ul>
                        <li>
                          allows Phin to make API calls to a Microsoft Azure tenant
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <p className='consentSubtitle'>How will Phin use the permissions?</p>
                  <ul>
                    <li>
                      Phin will use the above listed permissions to automatically insert email messages directly into an end user's mailbox.
                    </li>
                  </ul>
                </div>
                <div className='d-flex justify-content-center'>
                  <button
                    id='continue-to-microsoft-button'
                    aria-label='Continue to Microsoft Button'
                    className='consentBtn'
                    onClick={() => consentToIntegration()}
                  >Continue to Microsoft
                  </button>
                </div>
              </div>
            </>)}

          {(integrationsMap.spamFilterBypass && integrationsMap.spamFilterBypass.integrationStatus === microsoftIntegrationStatuses.CONSENT_SUBMITTED) && (
            <>
              <Spinner message='Validating Microsoft Consent' />
            </>
          )}

          {(!integrationsMap.spamFilterBypass || integrationsMap.spamFilterBypass.integrationStatus === microsoftIntegrationStatuses.CONNECTED) && (
            <>
              <MDBRow style={integrationsMap.spamFilterBypass && integrationsMap.spamFilterBypass.integrationStatus === integrationStatuses.CONNECTED ? {} : { filter: 'opacity(.2)', pointerEvents: 'none' }}>
                <MDBCol size='12'>
                  <MDBCard>
                    <MDBCardHeader>
                      <MDBCardTitle className='pt-2'>Test Graph API Mail Delivery</MDBCardTitle>
                    </MDBCardHeader>
                    <MDBCardBody>
                      <Grid container direction='column' p={1} rowGap={2}>
                        <TextField
                          value={testEmail}
                          id='test-microsoft-email'
                          onChange={(e) => {
                            setTestEmail(e.target.value)
                          }}
                          label='To Email'
                        />
                        <TextField
                          value={fromName}
                          id='test-microsoft-from-name'
                          onChange={(e) => {
                            setFromName(e.target.value)
                          }}
                          label='From Name'
                        />
                      </Grid>
                      <FormControl fullWidth variant='standard'>
                        <InputLabel id='from-email-select-label'>Select From Email</InputLabel>
                        <Select
                          labelId='from-email-select-label'
                          id='from-email-select'
                          label='Select From Email'
                          value={fromAddress}
                          onChange={(event) => setFromAddress(event.target.value)}
                        >
                          {fromEmailOptions.map((option) => {
                            return <MenuItem key={option} value={option}>{option}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    </MDBCardBody>
                    <MDBCardFooter className='justify-content-end'>
                      <Button
                        id='send-test-mail-button'
                        aria-label='Send Test Mail Button'
                        onClick={sendTestEmail}
                        variant='contained'
                        color='primary'
                      >
                        Send Test Mail
                      </Button>
                    </MDBCardFooter>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </>
          )}

          {(integrationsMap.spamFilterBypass && integrationsMap.spamFilterBypass.integrationStatus !== integrationStatuses.NOT_CONNECTED) &&
            <MDBRow className='d-flex justify-content-center mb-5'>
              <MDBCol xl='4' md='4' className='pt-4 d-flex justify-content-center align-items-center'>
                <Button size='large' onClick={() => setDisconnectModal(true)} color='error' variant='contained'>
                  Disconnect
                </Button>
              </MDBCol>
            </MDBRow>}
        </>

        )}
      </MDBContainer>
    </MDBContainer>
  )
}
