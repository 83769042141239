import { useHistory } from 'react-router-dom'
import { useState } from 'react'

import { MDBRow, MDBCol } from 'mdb-react-ui-kit'

import PresetLibrary from './components/Campaigns/PresetLibrary'
import NotificationUtilities from './components/notifications/notificationUtils'
import { useSelector, useDispatch } from 'react-redux'
import { removePhishingCampaignPresetAction } from '../store/CampaignsSlice'

import { navigationRoutes } from '../frontendConsts.js'
import { apiRequestUtils } from '../utils/apiRequestUtils'

import './css/presetLibrary.css'
import Spinner from './components/Spinner'
import PhinModal from './components/PhinModal.js'

function CampaignPresetLibrary ({ companyId }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const [validationModal, setValidationModal] = useState(false)
  const [selectedPreset, setSelectedPreset] = useState({})

  const { phishingCampaignPresets, loaders } = useSelector((state) => state.campaigns)
  const { isLoadingPhishingCampaignPresets } = loaders

  function launchCampaignPreset (campaignPreset) {
    const presetCopy = Object.assign({}, campaignPreset)
    history.push(`/companies/${companyId}${navigationRoutes.CAMPAIGNLAUNCHER}`, { campaign: presetCopy, preset: true })
  }

  function editCampaignPreset (campaignPreset) {
    history.push(`/companies/${companyId}${navigationRoutes.CAMPAIGNEDITOR}`, { presetId: campaignPreset.id, edit: true })
  }

  async function deleteCampaignPreset () {
    try {
      const res = await apiRequestUtils.delete(`/api/companies/${companyId}/campaign-presets/${selectedPreset.id}`, { shareLevel: selectedPreset.shareLevel })
      if (res.status === 200) {
        dispatch(removePhishingCampaignPresetAction(selectedPreset.id))
        NotificationUtilities.sendSuccessMessage('Successfully deleted campaign preset!')
        toggleValidationModal()
      } else {
        throw Error('Failed to delete campaign preset. Server responded with non 200 status')
      }
    } catch (err) {
      NotificationUtilities.sendErrorMessage('Failed to delete campaign preset!')
      toggleValidationModal()
      console.error(err)
    }
  }

  function handlePresetDelete (campaignPreset) {
    setSelectedPreset(campaignPreset)
    toggleValidationModal()
  }

  function toggleValidationModal () {
    setValidationModal(!validationModal)
  }

  return (
    <>
      <PhinModal
        isOpen={validationModal}
        title='Delete Campaign Preset'
        close={() => setValidationModal(false)}
        closeText='Cancel'
        action={deleteCampaignPreset}
        actionColor='error'
        actionText='Delete'
      >
        <p>Are you sure you want to delete this preset? This will delete the preset for any company in this partner that shares this preset! <strong>This can not be undone!</strong></p>
      </PhinModal>
      <MDBRow className='d-flex justify-content-center align-items-center'>
        <MDBCol className='text-center'>
          <h1>Campaign Preset Library</h1>
          <p>Edit and manage your saved campaign presets here</p>
        </MDBCol>
      </MDBRow>
      {isLoadingPhishingCampaignPresets && (
        <Spinner message='Loading presets' />
      )}
      {phishingCampaignPresets && !isLoadingPhishingCampaignPresets && (
        <>
          <hr style={{ marginBottom: '2rem' }} />
          <PresetLibrary
            modify
            campaignPresets={phishingCampaignPresets}
            launchCampaignPreset={launchCampaignPreset}
            editCallback={editCampaignPreset}
            deleteCallback={handlePresetDelete}
          />
        </>
      )}
    </>
  )
}

export default CampaignPresetLibrary
