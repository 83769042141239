import { learningTypes as learningTypeDef } from '../../../frontendConsts.js'
import React from 'react'
import { StaticAlert } from '../../../shared/components/StaticAlert.js'
import { Collapse, Grid, Checkbox, FormControlLabel } from '@mui/material'
import { PhinCard } from '../PhinCard.js'

const renderTextWaring = (learningTypes) => {
  return (
    <div className='margin:0'>
      <Collapse in={learningTypes.video === true} {...(learningTypes.video === true ? { timeout: 500 } : {})}>
        <StaticAlert
          severity='warning' color='warning' variant='filled' text='You have selected to include templates with embedded video learning. This feature is currently in beta. If there is video content
      you would like to see included reach out to us!'
        />
      </Collapse>
    </div>
  )
}

const CampaignLearningTypesEditor = ({ learningTypes, toggleLearningTypes, disableUpdating = false }) => {
  return (
    <PhinCard
      title='Learning Types'
      description='Here you can filter templates in your campaign based on which learning types they are configured to use.'
    >
      <Grid container justifyContent='start'>
        <FormControlLabel
          label='Learning Moment'
          control={
            <Checkbox
              id='lmCheckBox'
              disabled={disableUpdating}
              checked={learningTypes?.lm}
              onChange={() => toggleLearningTypes(learningTypeDef.LM)}
            />
  }
        />

        <FormControlLabel
          label='Video'
          control={
            <Checkbox
              id='videoCheckBox'
              disabled={disableUpdating}
              checked={learningTypes?.video}
              onChange={() => toggleLearningTypes(learningTypeDef.VIDEO)}
            />
  }
        />

        <FormControlLabel
          label='Custom URL'
          control={
            <Checkbox
              id='customCheckBox'
              disabled={disableUpdating}
              checked={learningTypes?.custom}
              onChange={() => toggleLearningTypes(learningTypeDef.CUSTOM)}
            />
  }
        />
      </Grid>
      {renderTextWaring(learningTypes)}
    </PhinCard>
  )
}

export default CampaignLearningTypesEditor
