function formatDateForCard (date) {
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
}

function getCampaignNameFromId (campaignId, campaigns) {
  const campaign = campaigns.find(campaign => campaign.id === campaignId)
  if (campaign) {
    return campaign.name
  } else {
    return '?'
  }
}

function shouldCourseBeArchived (campaignId, pastCampaigns) {
  const campaign = pastCampaigns.find(campaign => campaign.id === campaignId)

  if (campaign) {
    return true
  } else {
    return false
  }
}

export {
  formatDateForCard,
  getCampaignNameFromId,
  shouldCourseBeArchived
}
