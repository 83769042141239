import { createSlice } from '@reduxjs/toolkit'
import { integrationTypes } from '../frontendConsts.js'
import { removePartnerIntegrationAction } from './PartnerIntegrationSlice.js'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'

export const gradientMSPApiSlice = createSlice({
  name: 'gradientMSPAPI',
  initialState: {
    gradientMSPApiIntegration: null,
    loaders: {
      isLoadingGradientMSPAPI: false,
      isSyncingGradientMSPData: false,
      isSyncingCompanyAccounts: false
    }

  },
  reducers: {
    setIsLoadingGradientMSPAPI: (state, action) => {
      state.loaders.isLoadingGradientMSPAPI = action.payload
    },
    setGradientMSPApiIntegration: (state, action) => {
      state.gradientMSPApiIntegration = action.payload
    },
    setIsSyncingGradientMSPData: (state, action) => {
      state.loaders.isSyncingGradientMSPData = action.payload
    },
    setIsSyncingCompanyAccounts: (state, action) => {
      state.loaders.isSyncingCompanyAccounts = action.payload
    }

  }

})

export const {
  setIsLoadingGradientMSPAPI: setIsLoadingGradientMSPAPIAction,
  setIsSyncingGradientMSPData: setIsSyncingGradientMSPDataAction,
  setGradientMSPApiIntegration: setGradientMSPApiIntegrationAction,
  setIsSyncingCompanyAccounts: setIsSyncingCompanyAccountsAction
} = gradientMSPApiSlice.actions

export default gradientMSPApiSlice.reducer

export const createGradientApiIntegrationThunk = (partnerId, partnerApiKey) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingGradientMSPAPIAction(true))
    try {
      const response = await api.post(`/api/partners/${partnerId}/integrations/gradient-api/create`, { partnerApiKey })

      if (response.status === 200) {
        const partnerIntegration = await response.json()
        dispatch(setGradientMSPApiIntegrationAction(partnerIntegration))
        dispatch(setIsLoadingGradientMSPAPIAction(false))
        return true
      } else {
        const statusObj = await response.json()

        setTimeout(() => {
          NotificationUtilities.sendErrorMessage(statusObj.error)
        }, 0)
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to create GradientMSP API Integration. Please try again or contact Phin Support for assistance.')
      }, 0)
    }
    dispatch(setIsLoadingGradientMSPAPIAction(false))
  }
}

export const getGradientApiIntegrationThunk = () => {
  return async (dispatch, getState) => {
    dispatch(setIsLoadingGradientMSPAPIAction(true))
    try {
      const partnerIntegrationsMap = { ...getState().partnerIntegrations.partnerIntegrationsMap }

      if (partnerIntegrationsMap && partnerIntegrationsMap[integrationTypes.GRADIENT]) {
        dispatch(setGradientMSPApiIntegrationAction(partnerIntegrationsMap[integrationTypes.GRADIENT]))
      } else {
        dispatch(setGradientMSPApiIntegrationAction(null))
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to load GradientMSP API Integration. Please try again or contact Phin Support for assistance.')
      }, 0)
    }
    dispatch(setIsLoadingGradientMSPAPIAction(false))
  }
}

export const updateGradientApiPartnerKeyThunk = (partnerId, partnerApiKey) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingGradientMSPAPIAction(true))
    try {
      const response = await api.put(`/api/partners/${partnerId}/integrations/gradient-api/updates`, { partnerApiKey })

      if (response.status === 200) {
        setTimeout(() => {
          NotificationUtilities.sendSuccessMessage('Key updated')
        }, 0)
        return true
      } else {
        const statusObj = await response.json()

        setTimeout(() => {
          NotificationUtilities.sendErrorMessage(statusObj.error)
        }, 0)
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to update GradientMSP API Secret. Please try again or contact Phin Support for assistance.')
      }, 0)
    }
    dispatch(setIsLoadingGradientMSPAPIAction(false))
  }
}

export const pushGradientApiPartnerBillingStatsThunk = (partnerId) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsSyncingGradientMSPDataAction(true))
    try {
      const response = await api.post(`/api/partners/${partnerId}/integrations/gradient-api/sync`)

      if (response.status === 200) {
        const statusObj = await response.json()
        setTimeout(() => {
          NotificationUtilities.sendSuccessMessage(statusObj.message)
        }, 0)
        dispatch(setIsSyncingGradientMSPDataAction(false))
        return true
      } else if (response.status === 209) {
        const statusObj = await response.json()
        setTimeout(() => {
          NotificationUtilities.sendWarningMessage(statusObj.message)
        }, 0)
        dispatch(setIsSyncingGradientMSPDataAction(false))
        return true
      } else {
        const statusObj = await response.json()

        setTimeout(() => {
          NotificationUtilities.sendErrorMessage(statusObj.error)
        }, 0)
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to sync billing data with Gradient MSP. Please try again or contact Phin Support for assistance.')
      }, 0)
    }
    dispatch(setIsSyncingGradientMSPDataAction(false))
    return false
  }
}

export const syncCompanyAccountsWithGradientThunk = (partnerId) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsSyncingCompanyAccountsAction(true))

    try {
      const response = await api.post(`/api/partners/${partnerId}/integrations/gradient-api/sync-company-accounts`)
      if (response.status === 200) {
        const { message } = await response.json()
        setTimeout(() => {
          NotificationUtilities.sendSuccessMessage(message)
        }, 0)
        dispatch(setIsSyncingCompanyAccountsAction(false))
        return true
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to sync Company Accounts with Gradient MSP. Please try again or contact Phin Support for assistance.')
        }, 0)
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to sync Company Accounts with Gradient MSP. Please try again or contact Phin Support for assistance.')
      }, 0)
    }
    dispatch(setIsSyncingCompanyAccountsAction(false))
    return false
  }
}

export const disconnectGradientMSPApiThunk = ({ partnerId, history }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingGradientMSPAPIAction(true))
    try {
      const response = await api.delete(`/api/partners/${partnerId}/integrations/gradient-api`)
      if (response.status === 200) {
        dispatch(setGradientMSPApiIntegrationAction(null))
        dispatch(removePartnerIntegrationAction(integrationTypes.GRADIENT))
        setTimeout(() => {
          NotificationUtilities.sendSuccessMessage('Successfully deleted GradientMSP API Integration.  If you have not disconnected in the GradientMSP portal, please do so now.')
        }, 0)
      } else {
        const statusObj = await response.json()

        setTimeout(() => {
          NotificationUtilities.sendErrorMessage(statusObj.error)
        }, 0)
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to delete GradientMSP API Integration. Please try again or contact Phin Support for assistance.')
      }, 0)
    }
    dispatch(setIsLoadingGradientMSPAPIAction(false))
  }
}
