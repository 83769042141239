import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Link } from '@mui/material'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { IoExit, IoDiamond } from 'react-icons/io5'

export function FreeTrialLockoutModal ({ open, closeModal, feature, featureIntroTitle, description, KBLink }) {
  const history = useHistory()
  const { partner } = useSelector((state) => state.partner)

  const { loaders } = useSelector((state) => state.partnerBilling)
  const { isLoadingPartnerBillingDetails } = loaders

  async function billingRedirect () {
    closeModal()
    history.push(`/partners/${partner.id}/billing/manage`)
  }

  return (
    <>

      <Dialog
        sx={{ '& .MuiDialog-paper': { maxWidth: '30vw', maxHeight: '100vh', padding: 'var(--phin-s1)' } }}
        maxWidth='xs'
        open={open}
        onClose={closeModal}
      >
        <DialogTitle align='left'>
          <div className='phin-h4'>
            <IoDiamond />
            <span id='free-trial-upgrade-modal-title' className='padding-left:-2'>Upgrade to {featureIntroTitle}</span>
          </div>
        </DialogTitle>
        <DialogContent>
          <p className='phin-body-text padding-bottom:2'>{description}</p>
          {KBLink && (

            <p className='phin-body-text'>
              If you would like to learn more about {feature} check out the following knowledge base article. <Link href={KBLink} target='_blank' rel='noopener noreferrer'><span style={{ paddingRight: '4px' }}>More info</span><IoExit /></Link>
            </p>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            id='cancel-operation-button'
            aria-label='Cancel Operation'
            onClick={closeModal}
            disableElevation
            disabled={isLoadingPartnerBillingDetails}
          >Cancel
          </Button>
          <Button
            id='upgrade-account-button'
            aria-label='Upgrade Account Button'
            color='primary'
            disableElevation
            variant='contained'
            onClick={() => billingRedirect()}
            disabled={isLoadingPartnerBillingDetails}
          >Upgrade Now
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
