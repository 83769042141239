import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import './CampaignFrequencySelector.css'
import SelectOptions from '../../SelectOptions'
import InlineMultiSelect from '../../InlineMultiSelect'
import { setIterationsAction, setFrequencyAction, setMonthAction, setWeekAction, setWeekdayAction, setCampaignFireTimeAction } from '../../../../store/CampaignFrequencySlice'
import { setTrainingIterationsAction, setTrainingFrequencyAction, setTrainingMonthAction, setTrainingWeekAction, setTrainingWeekdayAction, setTrainingCampaignFireTimeAction } from '../../../../store/TrainingCampaignFrequencySlice'
import TextField from '@mui/material/TextField'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'

import { LocalizationProvider, DatePicker, TimePicker, DateTimePicker } from '@mui/x-date-pickers'
import { Alert, FormControlLabel, Grid, MenuItem, Radio, RadioGroup } from '@mui/material'
import CampaignScheduleDisplay from './CampaignScheduleDisplay'
import { campaignFrequencyIterations, campaignTypes } from '../../../../frontendConsts'
import { PhinCard } from '../../PhinCard'
import { DateTime } from 'luxon'

const SCHEDULE_TYPES = {
  RECURRING: 'recurring',
  ALL_AT_ONCE: 'all at once'
}

const CampaignFrequencySelector = ({
  startTime, setStartTime, phishingAttemptWindowDays, setPhishingAttemptWindowDays,
  campaignFrequency, tz, campaignType,
  isRunning, nextFireTimes,
  firstFireDate, setFirstFireDate,
  isCampaignFrequencyWeeksValid, setIsCampaignFrequencyWeeksValid,
  nextFireTimesErrorMessage,
  modules, // modules is optional
  useTrainingSelector = false, // keep separate state for training since we can use 2 of these components on the same page
  recurringScheduleError, setRecurringScheduleError,
  isContinuous
}) => {
  const {
    iterations, iterationsOptions, frequency,
    frequencyOptions, month, monthOptions,
    week, weekOptions, weekday, weekdayOptions
  } = campaignFrequency

  const dispatch = useDispatch()

  const [scheduleType, setScheduleType] = useState(campaignFrequency.iterations === campaignFrequencyIterations.ALL_AT_ONCE ? SCHEDULE_TYPES.ALL_AT_ONCE : SCHEDULE_TYPES.RECURRING)

  const handleDateError = (error) => {
    if (error && error === 'minDate') {
      setRecurringScheduleError('Recurring schedule must start after the first fire date.')
    }
  }

  const renderOptions = (activeValue, setActiveValue, options, id, ariaLabel) => {
    return (activeValue
      ? (
        <SelectOptions
          value={activeValue.length ? activeValue[0].value : activeValue.value}
          label=''
          innerClassName='mad-lib-picker blue0-background-color'
          onChange={
            (e) => setActiveValue(activeValue.length
              ? [{ text: e.target.value, value: e.target.options[e.target.selectedIndex].value }]
              : { text: e.target.value, value: e.target.options[e.target.selectedIndex].value })
          }
          options={options}
          id={id}
          aria-label={ariaLabel}
          campaignStyleOverride
        />
        )
      : (<></>)
    )
  }

  const shouldBeDisabled = (option, selectedOptions) => {
    if (frequency.text === 'Once' ? selectedOptions.length === 1 : selectedOptions.length === 2) {
      const disabledStatus = selectedOptions.map(option => option.value).includes(option.value) ? '' : 'disabled'
      return disabledStatus
    }
  }

  const phishingWindowOptions = [
    {
      value: 3,
      label: 'Across 3 Days'
    },
    {
      value: 4,
      label: 'Across 4 Days'
    },
    {
      value: 5,
      label: 'Across 5 Days'
    },
    {
      value: 6,
      label: 'Across 6 Days'
    },
    {
      value: 7,
      label: 'Across 7 Days'
    }
  ]

  const [collapsed, setCollapsed] = useState(true)

  const [hours, minutes] = campaignFrequency.time.split(':')

  const iterationsSelectionId = useTrainingSelector ? 'iterations-select' : 'phishing-iterations-select'
  const frequencySelectionId = useTrainingSelector ? 'frequency-select' : 'phishing-frequency-select'
  const weeksSelectionId = useTrainingSelector ? 'weeks-select' : 'phishing-weeks-select'
  const monthsSelectionId = useTrainingSelector ? 'months-select' : 'phishing-months-select'
  const weekdaySelectionId = useTrainingSelector ? 'weekdayOptions-select' : 'phishing-weekdayOptions-select'
  const firstFireDateTimeId = useTrainingSelector ? 'training-first-fire-picker' : 'phishing-first-fire-picker'

  const toggleScheduleType = async (newScheduleType) => {
    if (newScheduleType === SCHEDULE_TYPES.RECURRING) {
      await dispatch(setIterationsAction({ text: 'Once', value: 'Once' }))
    } else {
      await dispatch(setIterationsAction({ text: 'All At Once', value: 'All At Once' }))
    }

    setScheduleType(newScheduleType)
  }

  useEffect(() => {
    if (campaignFrequency.iterations.value === 'All At Once') {
      setScheduleType(SCHEDULE_TYPES.ALL_AT_ONCE)
    } else {
      setScheduleType(SCHEDULE_TYPES.RECURRING)
    }
  }, [campaignFrequency])

  return (
    <PhinCard
      title={campaignType === campaignTypes.PHISHING ? 'Phishing Time and Sending Settings' : 'Training Time and Sending Settings'}
    >
      <Grid container direction='column' sx={{ marginTop: 'var(--phin-s2)' }}>
        {/* Select Schedule type, recurring or all at once */}
        {firstFireDate && campaignType !== campaignTypes.PHISHING && !isContinuous && (
          <div className='phin-h5 padding-bottom:2'>
            <div className='phin-h5 padding-bottom:-2'>Schedule Type</div>
            <RadioGroup
              row
              name='row-radio-buttons-group'
              value={scheduleType}
              onChange={(event) => toggleScheduleType(event.target.value)}
              aria-label='Schedule Type'
            >
              <FormControlLabel id='scheduleTypeRecurringButton' value={SCHEDULE_TYPES.RECURRING} control={<Radio disabled={isRunning} />} label='Recurring' />
              <FormControlLabel id='scheduleTypeAllAtOnceButton' value={SCHEDULE_TYPES.ALL_AT_ONCE} control={<Radio disabled={isRunning} />} label='All at Once' />
            </RadioGroup>
          </div>
        )}

        <div className='phin-h5 margin-bottom:2'>
          Campaign Schedule
        </div>

        <Grid
          container
          justifyContent='center'
          alignItems='baseline'
          direction='row'
          sx={{
            fontSize: '1.75rem',
            marginBottom: 'var(--phin-s3)',
            paddingRight: '125px',
            paddingLeft: '125px',
            borderRadius: '8px',
            backgroundColor: 'var(--blue-0)',
            paddingTop: 'var(--phin-s0)',
            paddingBottom: 'var(--phin-s2)'
          }}
          rowSpacing={2}
        >
          {firstFireDate && (
            <>

              {(scheduleType === SCHEDULE_TYPES.RECURRING
                ? (
                  <Grid item sx={{ marginRight: 'var(--phin-s-1)' }}>{campaignType === campaignTypes.PHISHING ? 'Schedule first phishing emails on' : 'Send first training on'}</Grid>
                  )
                : (
                  <Grid item sx={{ marginRight: 'var(--phin-s-1)' }}>Send all training courses on</Grid>
                  )
            )}

              <Grid item>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DateTimePicker
                    id='campaign-frequency-selector-first-fire-date-time-picker'
                    renderInput={(props) => <TextField {...props} />}
                    value={firstFireDate}
                    onChange={(value) => {
                      setFirstFireDate(value)
                      if (campaignFrequency.iterations.value === 'All At Once') {
                        setStartTime(value)
                      }
                    }}
                    disablePast
                    disabled={isRunning}
                    slotProps={{
                      textField: {
                        testId: firstFireDateTimeId,
                        sx: {
                          backgroundColor: '#FFF',
                          borderRadius: '4px',
                          '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                            top: '-12px'
                          }
                        }
                      },
                      inputAdornment: {
                        'test-id': 'FirstFireDateTimePickerIcon'
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </>
          )}

          {(scheduleType === SCHEDULE_TYPES.RECURRING) && (
            <>
              <Grid item sx={{ marginRight: 'var(--phin-s-1)' }}>{firstFireDate ? '. Then send' : 'Send'}</Grid>

              <Grid item sx={{ marginRight: 'var(--phin-s-1)', fontFamily: '"Caveat", cursive' }}>
                {renderOptions(iterations, (d) => {
                  if (useTrainingSelector) {
                    dispatch(setTrainingIterationsAction(d))
                  } else {
                    dispatch(setIterationsAction(d))
                  }
                  setIsCampaignFrequencyWeeksValid(true)
                }, iterationsOptions, iterationsSelectionId, 'Iterations Selector')}
              </Grid>

              <Grid item sx={{ marginRight: 'var(--phin-s-1)' }}>a</Grid>

              <Grid item sx={{ marginRight: 'var(--phin-s-1)', fontFamily: '"Caveat", cursive' }}>
                {renderOptions(frequency, (d) => {
                  if (useTrainingSelector) {
                    dispatch(setTrainingFrequencyAction(d))
                  } else {
                    dispatch(setFrequencyAction(d))
                  }
                }, frequencyOptions, frequencySelectionId, 'Frequency Selector')}
              </Grid>

              {frequency.text === 'Quarter' && (
                <>
                  <Grid item sx={{ marginRight: 'var(--phin-s-1)' }}>in the</Grid>

                  <Grid item sx={{ marginRight: 'var(--phin-s-1)', fontFamily: '"Caveat", cursive' }}>
                    {renderOptions(month, (d) => {
                      if (useTrainingSelector) {
                        dispatch(setTrainingMonthAction(d))
                      } else {
                        dispatch(setMonthAction(d))
                      }
                    }, monthOptions, monthsSelectionId, 'Month Selector')}
                  </Grid>

                  <Grid item sx={{ marginRight: 'var(--phin-s-1)' }}>month</Grid>
                </>
              )}

              {frequency.text !== 'Week' &&
                            (iterations.text === 'Once'
                              ? (
                                <>
                                  <Grid item sx={{ marginRight: 'var(--phin-s-1)' }}>on the</Grid>

                                  <Grid item sx={{ marginRight: 'var(--phin-s-1)', fontFamily: '"Caveat", cursive' }}>
                                    {renderOptions(week, (d) => {
                                      if (useTrainingSelector) {
                                        dispatch(setTrainingWeekAction(d))
                                      } else {
                                        dispatch(setWeekAction(d))
                                      }
                                    }, weekOptions, weeksSelectionId, 'Week Selector')}
                                  </Grid>
                                </>)
                              : (
                                <>
                                  <Grid item sx={{ marginRight: 'var(--phin-s-1)' }}>on the</Grid>

                                  <Grid item sx={{ marginRight: 'var(--phin-s-1)' }}>
                                    <InlineMultiSelect
                                      shouldBeDisabled={shouldBeDisabled}
                                      collapsed={collapsed}
                                      setCollapsed={setCollapsed}
                                      selectedOptions={week}
                                      options={weekOptions}
                                      setSelectedOptions={(d) => {
                                        if (useTrainingSelector) {
                                          dispatch(setTrainingWeekAction(d))
                                        } else {
                                          dispatch(setWeekAction(d))
                                        }
                                      }}
                                      numberOfRequiredChoices={frequency === 'Once' ? 1 : 2}
                                      isValid={isCampaignFrequencyWeeksValid}
                                      setIsValid={setIsCampaignFrequencyWeeksValid}
                                    />
                                  </Grid>

                                </>
                                ))}

              <Grid item sx={{ marginRight: 'var(--phin-s-1)', fontFamily: '"Caveat", cursive' }}>

                {renderOptions(weekday, (d) => {
                  if (useTrainingSelector) {
                    dispatch(setTrainingWeekdayAction(d))
                  } else {
                    dispatch(setWeekdayAction(d))
                  }
                }, weekdayOptions, weekdaySelectionId, 'Weekday Selector')}
              </Grid>

              <Grid item sx={{ marginRight: 'var(--phin-s-1)' }}>
                at
              </Grid>

              <Grid item sx={{ marginRight: 'var(--phin-s-1)' }}>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <TimePicker
                    value={DateTime.now().set({ hours: +hours, minutes: +minutes })}
                    onChange={(newValue) => {
                      if (newValue && newValue._isValid) {
                        if (useTrainingSelector) {
                          dispatch(setTrainingCampaignFireTimeAction({ minutes: newValue.minutes(), hours: newValue.hours() }))
                        } else {
                          dispatch(setCampaignFireTimeAction({ minutes: newValue.minutes(), hours: newValue.hours() }))
                        }
                      }
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    slotProps={{
                      textField: {
                        sx: {
                          backgroundColor: '#FFF',
                          borderRadius: '4px',
                          '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                            top: '-12px'
                          }
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item sx={{ marginRight: 'var(--phin-s-1)' }}> starting on</Grid>

              {/* Scheduling Date */}
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                    inputFormat='MM/DD/YYYY'
                    value={startTime}
                    id='campaign-frequency-selector-start-date-picker'
                    minDate={firstFireDate ? firstFireDate.plus({ days: 1 }) : DateTime.now()}
                    disablePast={!isRunning}
                    disabled={isRunning}
                    onChange={(value) => { setRecurringScheduleError(false); setStartTime(value.set({ hours, minutes })) }}
                    renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} {...params} />}
                    onError={(error) => {
                      if (error && campaignFrequency.iterations.value !== 'All At Once') {
                        handleDateError(error)
                      } else { setRecurringScheduleError(false) }
                    }}
                    slotProps={{
                      textField: {
                        inputProps: {
                          'data-testid': 'campaign-frequency-selector-start-date-picker'
                        },
                        sx: {
                          backgroundColor: '#FFF',
                          borderRadius: '4px',
                          '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                            top: '-12px'
                          }
                        },
                        helperText: firstFireDate && recurringScheduleError ? recurringScheduleError : ''
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item>.</Grid>
            </>
          )}
        </Grid>

        {campaignFrequency.iterations.value !== 'All At Once' &&
          campaignType === campaignTypes.PHISHING &&
            <div className='margin-bottom:3'>
              <h5 className='phin-h5 mb-3'>Phishing Sending Window</h5>

              <Alert style={{ width: '100%' }} severity='info'>
                Learn more about how we schedule phishing messages <a target='_blank' href='https://www.phinsec.io/knowledge/when-will-continuous-phishing-campaigns-deliver' rel='noreferrer'>here</a>.
              </Alert>

              <TextField
                id='phishing-attempt-window-select'
                select
                className='mt-3'
                style={{ minWidth: '200px' }}
                type='outlined'
                value={phishingAttemptWindowDays}
                onChange={(event) => setPhishingAttemptWindowDays(event.target.value)}
              >
                {phishingWindowOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>}
      </Grid>

      <hr className='margin-bottom:3' />

      <CampaignScheduleDisplay
        tz={tz}
        modules={modules}
        nextFireTimes={nextFireTimes}
        firstFireDate={firstFireDate}
        nextFireTimesErrorMessage={nextFireTimesErrorMessage}
        isCampaignFrequencyWeeksValid={isCampaignFrequencyWeeksValid}
        displayType={useTrainingSelector ? 'training' : 'phishing'}
      />
    </PhinCard>
  )
}

export default CampaignFrequencySelector
