import React from 'react'
import {
  MDBRow,
  MDBCol,
  MDBCardBody
} from 'mdb-react-ui-kit'
import { TextField } from '@mui/material'

const CampaignName = ({ name, setName }) => {
  return (
    <div className='phin-card-container'>
      <h4>Campaign Name</h4>
      <MDBCardBody>
        <MDBRow className='d-flex justify-content-center'>
          <MDBCol size='12'>
            <TextField
              name='campaignName'
              onChange={(e) => setName(e.target.value)}
              value={name}
              fullWidth
            />
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </div>
  )
}

export default CampaignName
