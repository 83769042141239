import { Button, CardActions, Dialog, Grid, IconButton, LinearProgress } from '@mui/material'
import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { IoClose } from 'react-icons/io5'
import { STYLE } from '../../frontendConsts'

// large component is handled by passing "fullScreen" prop to Dialog
const getMinWidth = (size) => {
  if (size === 'small') {
    return '30%'
  } else if (size === 'medium') {
    return '65%'
  } else {
    return 'auto'
  }
}

const getMargin = (size) => {
  if (size === 'small') {
    return '0'
  } else if (size === 'medium') {
    return '0'
  } else if (size === 'large') {
    return '5rem'
  } else {
    return '0'
  }
}

const getOverflow = (size) => {
  if (size === 'small') {
    return 'auto'
  } else if (size === 'medium') {
    return 'auto'
  } else if (size === 'large') {
    return 'scroll'
  } else {
    return 'auto'
  }
}

const PhinDialog = styled(Dialog)(({ theme, ...props }) => ({
  '& .MuiDialog-paper': {
    display: 'flex',
    flexDirection: 'column',
    margin: getMargin(props.size),
    height: 'auto',
    overflow: getOverflow(props.size),
    boxShadow: '0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%)',
    borderRadius: '1em',
    minWidth: getMinWidth(props.size)
  }
}))

const PhinModal = ({
  isOpen = false,
  size = 'small',
  title = 'Confirm',
  close = () => { },
  closeText = 'Cancel',
  closeColor = 'primary',
  closeVariant = 'text',
  action = () => { },
  actionText = 'Confirm',
  actionColor = 'primary',
  actionVariant = 'contained',
  children = (<div>Confirm this action?</div>),
  noButtons = false,
  disableBackdropClick = false,
  disableEscapeKeyDown = false,
  actionButtonDisabled = false
}) => {
  const [actionPending, setActionPending] = useState(false)

  function closeDialog (e, reason) {
    if ((reason === 'backdropClick' && (actionPending || disableBackdropClick)) || (reason === 'escapeKeyDown' && (actionPending || disableEscapeKeyDown))) {
      e.stopPropagation()
    } else {
      close()
    }
  }

  async function actionFunction () {
    setActionPending(true)
    await action()
    setActionPending(false)
  }

  return (
    <PhinDialog
      open={isOpen}
      fullScreen={size === 'large'}
      size={size}
      onClose={closeDialog}
      aria-labelledby='parent-modal-title'
      aria-describedby='parent-modal-description'
    >
      {actionPending && (
        <LinearProgress />
      )}

      <div style={
        actionPending
          ? {
              paddingTop: '28px',
              paddingLeft: 'var(--phin-s2)',
              paddingRight: 'var(--phin-s2)',
              paddingBottom: noButtons ? 'var(--phin-s2)' : 'var(--phin-s1)'
            }
          : {
              paddingTop: '32px',
              paddingLeft: 'var(--phin-s2)',
              paddingRight: 'var(--phin-s2)',
              paddingBottom: noButtons ? 'var(--phin-s2)' : 'var(--phin-s1)'
            }
        }
      >

        <div className='phin-h4 padding-bottom:2' id='alert-dialog-title'>
          <div className='phin-flex-space-between-row'>
            <div>
              {title}
            </div>
            <IconButton disabled={actionPending} onClick={close}><IoClose /></IconButton>
          </div>

        </div>
        <div
          style={{
            maxHeight: 'calc(92vh - 212px)',
            overflow: 'auto'
          }}
        >
          {children}
        </div>

        {!noButtons &&
        (
          <CardActions sx={{ marginTop: 'var(--phin-s1)' }}>
            <Grid
              container
              direction='row'
              justifyContent='flex-end'
            >
              <span className='padding-right:-1'>
                <Button
                  id='cancel-button'
                  style={STYLE.BUTTONS.TERTIARY}
                  aria-label='true-option'
                  size='large'
                  variant={closeVariant}
                  color={closeColor}
                  onClick={close}
                  disabled={actionPending}
                >{closeText}
                </Button>
              </span>

              <Button
                id='action-button'
                aria-label='false-option'
                variant={actionVariant}
                size='large'
                color={actionColor}
                onClick={actionFunction}
                disabled={actionPending || actionButtonDisabled}
              >{actionText}
              </Button>
            </Grid>
          </CardActions>
        )}
      </div>
    </PhinDialog>
  )
}

export default PhinModal
