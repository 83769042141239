import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import DOMPurify from 'dompurify'
import {
  Box,
  Grid,
  Typography,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Select,
  MenuItem,
  Button,
  Autocomplete,
  TextField,
  FormControlLabel,
  Checkbox,
  Chip
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { DateTime } from 'luxon'
import { useDeleteEmailsFromSenderMutation, useGetPartnerReportedEmailByIdQuery, useLazyGetSimilarEmailsByFilterQuery, useResolveReportedEmailMutation } from '../../../../store/PartnerTriagingSlice'
import { getFeatureToggleEnabledThunk } from '../../../../store/AuthSlice'
import { EMAIL_TRIAGE_TAGS, RAP_FILTER_TYPES, featureToggleNames } from '../../../../frontendConsts'
import NotificationUtilities from '../../../components/notifications/notificationUtils'
import Spinner from '../../../components/Spinner'
import { useSelector, useDispatch } from 'react-redux'
import { smartRoute } from '../../../../utils/RouterUtils'
import { useAuth0 } from '@auth0/auth0-react'
import { DataGrid } from '@mui/x-data-grid'
import { Warning } from '@mui/icons-material'
import PhinModal from '../../../components/PhinModal'
import { IoAlertCircle, IoCheckmarkCircleSharp } from 'react-icons/io5'
import { TriageAuditLogTable } from './TriageAuditLogTable'
import PhinBadge from '../../../components/PhinBadge'

export const ReportedEmailTriagePage = () => {
  const filterBy = RAP_FILTER_TYPES.SENDER
  const history = useHistory()
  const { id: partnerId, messageId } = useParams()
  const { data: reportedEmail, isLoading: isLoadingReportedEmail } = useGetPartnerReportedEmailByIdQuery({ partnerId, messageId })
  const [getSimilarEmails, { data: similarEmailsData, error: similarEmailsError, isFetching: isFetchingSimilarEmails }] = useLazyGetSimilarEmailsByFilterQuery()
  const { authorization, featureToggles } = useSelector((state) => state.auth)
  const [deleteSimilarEmails, setDeleteSimilarEmails] = useState(false)
  const [openSimilarEmailsModal, setOpenSimilarEmailsModal] = useState(false)

  const { logout } = useAuth0()
  const dispatch = useDispatch()

  const cleanHTML = DOMPurify.sanitize(reportedEmail?.messageContent?.body?.content)

  async function getProcessReportedEmailsFeatureToggle () {
    await dispatch(getFeatureToggleEnabledThunk(featureToggleNames.PROCESS_REPORTED_EMAILS, partnerId))
  }

  async function getEmailAIProcessingFeatureToggle () {
    await dispatch(getFeatureToggleEnabledThunk(featureToggleNames.EMAIL_AI_PROCESSING, partnerId))
  }

  useEffect(() => {
    if (!featureToggles[partnerId] || featureToggles[partnerId]?.processReportedEmails === undefined) {
      getProcessReportedEmailsFeatureToggle()
    } else if (!featureToggles[partnerId]?.processReportedEmails) {
      smartRoute({ authorization, history, logout })
    }
  }, [])

  useEffect(() => {
    if (!featureToggles[partnerId] || featureToggles[partnerId]?.emailAIProcessing === undefined) {
      getEmailAIProcessingFeatureToggle()
    }
  }, [])

  const isProcessWithAIEnabled = featureToggles[partnerId]?.emailAIProcessing

  const [tabIndex, setTabIndex] = useState(0)
  const [disposition, setDisposition] = useState('')
  const [tags, setTags] = useState([])

  const [resolveReportedEmail] = useResolveReportedEmailMutation()
  const [deleteEmailsFromSender] = useDeleteEmailsFromSenderMutation()

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue)
  }

  const handleGetSimilarEmails = () => {
    getSimilarEmails({ partnerId, messageId, filterBy })
  }

  useEffect(() => {
    if (similarEmailsError) {
      const errorMessage = similarEmailsError?.data || 'unknown error'
      const errorCode = similarEmailsError?.status || 'unknown status'
      NotificationUtilities.sendErrorMessage(`Failed to get similar emails. Please try again or contact Phin Support for assistance. Error code: ${errorCode}. Error message: ${errorMessage}`)
    }
  }, [similarEmailsError])

  const columns = [
    {
      field: 'receiverEmail',
      headerName: 'Receiver Email',
      width: 200
    },
    {
      field: 'companyNames',
      headerName: 'Company Name',
      width: 150,
      valueGetter: (params) => params.row.companyNames.join(', ')
    },
    {
      field: 'receivedTime',
      headerName: 'Received Time',
      width: 150,
      valueGetter: (params) =>
        new Date(params.row.receivedDateTime).toLocaleString()
    },
    {
      field: 'senderName',
      headerName: 'Sender Name',
      width: 200
    }
  ]

  // removes duplicates of emails with the same ID which can happen for dev using the same tenant for multiple companies
  const rows = similarEmailsData?.similarEmailsFromSender?.map((email, index) => ({
    id: email.id || index,
    receiverEmail: email.email || 'N/A',
    companyNames: email.companyNames || ['N/A'],
    receivedDateTime: email.receivedDateTime || '',
    senderIP: email.senderIP || 'N/A',
    senderName: email.sender?.emailAddress?.name || 'N/A'
  }))

  useEffect(() => {
    if (reportedEmail && isProcessWithAIEnabled) {
      setTags(reportedEmail.tags?.map((tag) => tag.name) || [])
    }
  }, [reportedEmail, featureToggles])

  const handleDeleteEmailsFromSender = async () => {
    try {
      await deleteEmailsFromSender({ partnerId, senderAddress: reportedEmail.senderEmail, filterBy: RAP_FILTER_TYPES.SENDER, reportedEmailId: messageId })

      await resolveReportedEmail({
        partnerId,
        reportedEmailId: reportedEmail.messageId,
        disposition,
        tags
      })
      history.push(`/partners/${partnerId}/triaging`)
    } catch (error) {
      NotificationUtilities.sendErrorMessage('Failed to delete emails from sender. Please try again or contact Phin Support for assistance.')
    }
  }

  const handleResolve = async () => {
    try {
      if (!disposition) {
        NotificationUtilities.sendErrorMessage('No disposition selected for Email')
      } else {
        if (deleteSimilarEmails) {
          if (!similarEmailsData) {
            handleGetSimilarEmails()
          }
          setOpenSimilarEmailsModal(true)
        } else {
          await resolveReportedEmail({
            partnerId,
            reportedEmailId: reportedEmail.messageId,
            disposition,
            tags
          })

          history.push(`/partners/${partnerId}/triaging`)
        }
      }
    } catch (error) {
      NotificationUtilities.sendErrorMessage('Failed to save resolution. Please try again or contact Phin Support for assistance.')
    }
  }

  function parseAuthResults (headerValue) {
    const result = {
      spf: null,
      dkim: null,
      dmarc: null
    }

    if (!headerValue) {
      return result
    }

    // Regex patterns for SPF, DKIM, and DMARC
    const spfRegex = /spf=(\w+)/
    const dkimRegex = /dkim=(\w+)/
    const dmarcRegex = /dmarc=(\w+)/

    // Match and extract the result for SPF
    const spfMatch = headerValue.match(spfRegex)
    if (spfMatch) {
      result.spf = spfMatch[1]
    }

    // Match and extract the result for DKIM
    const dkimMatch = headerValue.match(dkimRegex)
    if (dkimMatch) {
      result.dkim = dkimMatch[1]
    }

    // Match and extract the result for DMARC
    const dmarcMatch = headerValue.match(dmarcRegex)
    if (dmarcMatch) {
      result.dmarc = dmarcMatch[1]
    }

    return result
  }

  if (isLoadingReportedEmail) {
    return (
      <Box>
        <Spinner />
      </Box>
    )
  }

  // eslint-disable-next-line
  const regex = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g
  const urls = [...new Set(cleanHTML.match(regex) || [])]

  const sortedHeaders = [...reportedEmail.emailHeaders].sort((a, b) => a.name.localeCompare(b.name))
  const xHeaders = sortedHeaders.filter(header => header.name.toLowerCase().startsWith('x-'))
  const regularHeaders = sortedHeaders.filter(header => !header.name.toLowerCase().startsWith('x-'))
  const authResultsHeaderResults = parseAuthResults(sortedHeaders.find(header => header.name === 'Authentication-Results')?.value)

  return (
    <>
      <PhinModal
        size='small'
        title='Are you sure?'
        isOpen={openSimilarEmailsModal}
        close={() => setOpenSimilarEmailsModal(!openSimilarEmailsModal)}
        action={handleDeleteEmailsFromSender}
        actionText='Confirm'
      >
        {isFetchingSimilarEmails
          ? <Spinner usePhinAnimation />
          : (
            <>
              <Typography><Warning sx={{ color: 'var(--yellow-warning-outline)' }} /> You cannot undo this action!</Typography>
              <br />
              <Typography>{`Are you sure you want to delete ${similarEmailsData?.similarEmailsFromSender?.length || 0} Email${similarEmailsData?.similarEmailsFromSender?.length > 1 ? 's' : ''}?`}</Typography>
            </>
            )}
      </PhinModal>

      <Box sx={{ p: 1 }}>
        <Grid container alignItems='center' marginBottom='var(--phin-s2)'>
          <h1 style={{ fontWeight: 500 }}>
            {reportedEmail.subject}
          </h1>

          {reportedEmail.isResolved &&
            <Chip label='Resolved' sx={{ backgroundColor: 'var(--phin-green)', color: 'white', marginLeft: '.75rem' }} />}
        </Grid>
        <Box
          sx={{
            border: '1px solid #ccc',
            borderRadius: '4px',
            overflow: 'hidden'
          }}
        >
          <Box sx={{ borderBottom: '1px solid #ccc', display: 'flex' }}>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              sx={{ flex: { xs: '1', md: '1' }, '& .MuiTab-root': { textTransform: 'none' } }}
            >
              <Tab label='Headers' />
              <Tab label='X-Headers' />
              <Tab label='URLs' />
              <Tab label='Similar Emails' />
              {reportedEmail.isResolved && (
                <Tab label='Resolution Audit Log' />
              )}
            </Tabs>
            <Box
              sx={{
                borderLeft: '1px solid #ccc',
                flex: 1,
                textAlign: 'center',
                display: { xs: 'none', md: 'block' }
              }}
            >
              <Tab label='Preview' />
            </Box>
          </Box>

          <Grid sx={{ minHeight: '50vh' }} container>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                borderRight: { md: '1px solid #ccc' },
                borderBottom: { xs: '1px solid #ccc', md: 'none' }
              }}
            >
              <Box sx={{ maxWidth: '100%', overflow: 'hidden', p: 2 }}>
                {tabIndex === 0 && (
                  <Box>
                    <Box sx={{ pl: '1rem', display: 'flex', flexDirection: 'column', gap: '24px', marginBottom: '24px' }}>

                      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '2rem', justifyContent: 'left' }}>
                        <AuthHeaderResult headerLabel='SPF' isHeaderPassed={authResultsHeaderResults.spf === 'pass'} />
                        <AuthHeaderResult headerLabel='DKIM' isHeaderPassed={authResultsHeaderResults.dkim === 'pass'} />
                        <AuthHeaderResult headerLabel='DMARC' isHeaderPassed={authResultsHeaderResults.dmarc === 'pass'} />
                      </Box>

                      <div>
                        <Typography><strong>From</strong></Typography>
                        <Typography>{reportedEmail.senderEmail}</Typography>
                      </div>

                      <div>
                        <Typography><strong>To</strong></Typography>
                        <Typography>{reportedEmail.reporterName}</Typography>
                      </div>

                      <div>
                        <Typography><strong>Received Time</strong></Typography>
                        <Typography>{DateTime.fromSeconds(Number(reportedEmail.reportedTime?._seconds)).toLocaleString(DateTime.DATETIME_MED)}</Typography>
                      </div>

                      <div>
                        <Typography><strong>Sender IP</strong></Typography>
                        <Typography>{reportedEmail.senderIpAddress}</Typography>
                      </div>
                    </Box>

                    <Box sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
                      {regularHeaders.map((header, index) => (
                        <Header key={index} headerName={header.name} headerValue={header.value} />
                      ))}
                    </Box>
                  </Box>
                )}

                {tabIndex === 1 &&
                  <Box sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
                    {xHeaders.map((header, index) => (
                      <Header key={index} headerName={header.name} headerValue={header.value} />
                    ))}
                  </Box>}

                {tabIndex === 2 && (
                  <Box>
                    <Typography variant='h6' gutterBottom>
                      URLs found in the email:
                    </Typography>
                    <List sx={{ listStyleType: 'disc', pl: 2 }}>
                      {urls.length > 0
                        ? (
                            urls.map((url, index) => (
                              <ListItem
                                key={index}
                                sx={{ display: 'list-item', paddingLeft: 0 }}
                              >
                                <ListItemText primary={url} />
                              </ListItem>
                            ))
                          )
                        : (
                          <Typography>No URLs found.</Typography>
                          )}
                    </List>
                  </Box>

                )}

                {tabIndex === 3 && (
                  <Box>
                    <Typography variant='h6' gutterBottom>
                      Similar Emails:
                    </Typography>
                    {!similarEmailsData
                      ? (
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={handleGetSimilarEmails}
                          disabled={isFetchingSimilarEmails}
                        >
                          {isFetchingSimilarEmails ? 'Loading...' : 'Get Similar Emails'}
                        </Button>
                        )
                      : (
                        <Typography>
                          Matching sender email: {reportedEmail.senderEmail}
                        </Typography>
                        )}

                    {isFetchingSimilarEmails && !similarEmailsData && <Spinner usePhinAnimation />}

                    {similarEmailsData && similarEmailsData.similarEmailsFromSender?.length > 0
                      ? (
                        <Box mt={4} sx={{ height: 300, width: '100%', overflowY: 'auto' }}>
                          <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5, 10, 15]}
                            loading={isFetchingSimilarEmails}
                            autoHeight
                          />
                        </Box>
                        )
                      : (
                          !similarEmailsData && (
                            <Typography mt={4}>Click the "Get Similar Emails" button to generate a list of emails across all companies matching the sender email address of the email you’re currently viewing. The list will include emails not reported across companies with the Report Phishing integration configured.
                            </Typography>
                          )
                        )}
                  </Box>
                )}

                {tabIndex === 4 && (
                  <TriageAuditLogTable />
                )}
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: { xs: 'block', md: 'none' },
                  borderTop: '1px solid #ccc',
                  textAlign: 'center',
                  py: 1
                }}
              >
                <Tab label='Preview' />
              </Box>

              <iframe
                srcDoc={cleanHTML}
                title='content-preview'
                style={{
                  width: '100%',
                  height: '100%',
                  border: 'none',
                  overflowY: 'auto',
                  maxHeight: '100vh'
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: 4, p: 2, borderTop: '1px solid #ccc' }}>
          {reportedEmail.isResolved
            ? (
              <Grid container justifyContent='end'>
                <Button variant='outlined' sx={{ mr: 2 }} onClick={() => history.push(`/partners/${partnerId}/triaging`)}>Cancel</Button>
              </Grid>
              )
            : (
              <>
                <Typography variant='h6'>Actions</Typography>
                <Grid container alignItems='center' justifyContent='space-between'>
                  <Grid item xs={12} md={9}>
                    <Box sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column', md: 'row' } }}>
                      <Select
                        value={disposition}
                        onChange={(e) => setDisposition(e.target.value)}
                        displayEmpty
                        sx={{ minWidth: 120 }}
                      >
                        <MenuItem value='' disabled>Select Disposition</MenuItem>
                        <MenuItem value='spam'>Spam</MenuItem>
                        <MenuItem value='malicious'>Malicious</MenuItem>
                        <MenuItem value='safe'>Safe</MenuItem>
                      </Select>

                      {isProcessWithAIEnabled && <Autocomplete
                        multiple
                        options={Object.values(EMAIL_TRIAGE_TAGS)}
                        value={tags}
                        onChange={(event, newValue) => setTags(newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Tags'
                            placeholder='Select tags'
                          />
                        )}
                        sx={{ flex: 1, marginBottom: { xs: 2, md: 0 } }}
                                                 />}
                      <FormControlLabel
                        sx={{
                          '& .MuiTypography-root': {
                            fontSize: '.8rem'
                          }
                        }}
                        control={
                          <Checkbox
                            id='delete-similar-email-checkbox'
                            onChange={(e) => setDeleteSimilarEmails(e.target.checked)}
                          />
              } label='Delete All Emails from Same Sender'
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={3} sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                    <Box sx={{ mb: '1rem' }}>

                      <Button variant='outlined' sx={{ mr: 2 }} onClick={() => history.push(`/partners/${partnerId}/triaging`)}>Cancel</Button>
                      <Button variant='contained' color='primary' onClick={handleResolve}>Resolve</Button>
                    </Box>
                  </Grid>
                </Grid>
              </>
              )}
        </Box>
      </Box>
    </>
  )
}

function AuthHeaderResult ({ headerLabel, isHeaderPassed }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '0.5rem', alignItems: 'center' }}>
      <Typography variant='subtitle1'><strong>{headerLabel}</strong></Typography>
      {isHeaderPassed
        ? <PhinBadge displayString='Pass' icon={<IoCheckmarkCircleSharp size='1.25em' />} color='var(--phin-green)' />
        : <PhinBadge displayString='Fail' icon={<IoAlertCircle size='1.25em' />} color='var(--phin-red)' />}
    </Box>
  )
}

function Header ({ headerName, headerValue }) {
  return (
    <Accordion sx={{ maxWidth: '100%' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography noWrap sx={{ maxWidth: '100%' }}>
          {headerName}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ overflowX: 'auto', maxWidth: '100%' }}>
        <Typography sx={{ wordBreak: 'break-all' }}>
          {headerValue}
        </Typography>
      </AccordionDetails>
    </Accordion>
  )
}
