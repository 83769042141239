import { Alert, Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useGetDeletedEmailsByReportedEmailQuery } from '../../../../store/PartnerTriagingSlice'
import { useParams } from 'react-router-dom'
import { DateTime } from 'luxon'

export function TriageAuditLogTable () {
  const { id: partnerId, messageId } = useParams()
  const { data: deletedEmailsData, error: deletedEmailsError, isLoading: isFetchingDeletedEmails } = useGetDeletedEmailsByReportedEmailQuery({ partnerId, messageId })

  const columns = [
    {
      field: 'recipientEmail',
      headerName: 'Recipient Email',
      width: 200
    },
    { field: 'emailSubject', headerName: 'Email Subject', width: 200 },
    {
      field: 'senderEmail',
      headerName: 'Sender Email',
      width: 200
    },
    { field: 'microsoftEmailId', headerName: 'Microsoft Email ID', width: 200 },
    {
      field: 'resolvedTimestamp',
      headerName: 'Resolved Date',
      width: 150,
      valueGetter: (params) =>
        DateTime.fromSeconds(params.row.resolvedTimestamp._seconds).toLocaleString()
    },
    { field: 'adminEmail', headerName: 'Resolved By', width: 200 }
  ]

  return (
    <Box>
      <h4>
        Deleted Emails
      </h4>

      <Box mt={4} sx={{ height: 300, width: '100%', overflowY: 'auto' }}>
        {deletedEmailsError
          ? (
            <Alert severity='error'>{deletedEmailsError.message}</Alert>
            )
          : (
            <DataGrid
              rows={deletedEmailsData || []}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 15]}
              loading={isFetchingDeletedEmails}
              autoHeight
            />
            )}
      </Box>
    </Box>
  )
}
