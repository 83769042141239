import { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { MDBRow, MDBCol } from 'mdb-react-ui-kit'

import { PHISHING_DEFAULT_SEND_WINDOW, STYLE, campaignTypes, navigationRoutes } from '../frontendConsts.js'
import CampaignName from './components/Campaigns/modules/CampaignName'
import { calculateStartTime, sanitizeCampaignFrequency } from '../utils/dateUtils'
import ContentViewer from './components/Campaigns/modules/ContentViewer'
import PresetLibrary from './components/Campaigns/PresetLibrary'

import './css/presetLibrary.css'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from './components/Spinner.js'
import { createPhishingCampaignThunk, getNextCampaignScheduleThunk, removePhishingCampaignPresetAction } from '../store/CampaignsSlice.js'
import CampaignFrequencySelector from './components/Campaigns/modules/CampaignFrequencySelector.js'
import { updateOnboardingChecklistDataThunk } from '../store/CompanySlice.js'
import NotificationUtilities from './components/notifications/notificationUtils.js'
import { validatePhishingCampaign } from '../validation/PhishingCampaigns.js'
import { StaticAlert } from '../shared/components/StaticAlert.js'
import { Button } from '@mui/material'
import { IoArrowBackOutline } from 'react-icons/io5'
import { StepperButtonTray } from './components/StepperButtonTray.js'
import { PhinCard } from './components/PhinCard.js'
import PhinModal from './components/PhinModal.js'
import { apiRequestUtils } from '../utils/apiRequestUtils.js'
import { DateTime } from 'luxon'

function CampaignLauncher ({ companyId }) {
  const history = useHistory()
  const dispatch = useDispatch()

  const { company, loaders, onboardingChecklist } = useSelector((state) => state.company)
  const { isLoadingCompany } = loaders
  const { sharedTemplates, userTemplates, defaultTemplates, campaignCategories, loaders: templateLoaders } = useSelector((state) => state.templates)
  const { isLoadingTemplates, isLoadingCampaignCategories, isLoadingSendingDomains } = templateLoaders
  const { phishingCampaignPresets, loaders: campaignLoaders } = useSelector((state) => state.campaigns)
  const campaignFrequency = useSelector(state => state.campaignFrequency)
  const { isLoadingActiveCampaigns, isLoadingPhishingCampaignPresets } = campaignLoaders

  const locationState = useLocation().state || {}
  const { campaign, preset } = locationState
  const [validationModal, setValidationModal] = useState(false)
  const [selectedPreset, setSelectedPreset] = useState({})

  useEffect(() => {
    window.scrollTo(0, 0)

    let load = null
    if (campaign) {
      load = campaign
    }

    setSelectedCampaign(load)
  }, [])

  const [selectedCampaign, setSelectedCampaign] = useState(null)

  const [name, setName] = useState('')
  const [isPreset, setIsPreset] = useState(preset)
  const [startTime, setStartTime] = useState(calculateStartTime(DateTime.now()))
  const [phishingAttemptWindowDays, setPhishingAttemptWindowDays] = useState(3)
  const [nextFireTimes, setNextFireTimes] = useState([])
  const [firstFireDate, setFirstFireDate] = useState(DateTime.now().set({ minute: 0, second: 0, millisecond: 0 }).plus({ days: 1 }))
  const [nextFireTimesErrorMessage, setNextFireTimesErrorMessage] = useState()

  const [isCampaignFrequencyWeeksValid, setIsCampaignFrequencyWeeksValid] = useState(true)
  const [recurringScheduleError, setRecurringScheduleError] = useState(false)

  useEffect(() => {
    // update scheduling dates on component load
    if (startTime) {
      try {
        let startDate = DateTime.fromISO(startTime.toISO())

        const running = startDate < DateTime.now()

        if (running) {
        // This campaign is currently running, cap the startDate
          startDate = DateTime.now().setZone(company.timezone.value)
        }

        const frequency = sanitizeCampaignFrequency(campaignFrequency)

        let nextFireTimeCount = 5
        if (selectedCampaign.campaignType === campaignTypes.FIXEDLENGTH && !running) {
          nextFireTimeCount = selectedCampaign.templateNames.length
        }

        // We need the return value from this thunk so we callback the result
        dispatch(getNextCampaignScheduleThunk({ companyId, campaignFrequency: frequency, startDate: startDate.toISO(), count: nextFireTimeCount - 1 }))
          .then(({ nextFireDateTimes }) => {
            setNextFireTimesErrorMessage()
            setNextFireTimes(nextFireDateTimes)
          })
      } catch (error) {
        setNextFireTimesErrorMessage(error.message)
      }
    }
  }, [selectedCampaign, startTime, campaignFrequency, firstFireDate])

  useEffect(() => {
    if (selectedCampaign) {
      if (isPreset) {
        setName(`${selectedCampaign.name}, ${calculateStartTime(DateTime.now()).toLocaleString(DateTime.DATE_SHORT)}`)
      } else {
        setName(selectedCampaign.name)
      }

      setPhishingAttemptWindowDays(selectedCampaign.phishingAttemptWindowDays || PHISHING_DEFAULT_SEND_WINDOW)
    }
  }, [selectedCampaign])

  function launchCampaignPreset (campaign) {
    setIsPreset(true)
    const copiedPreset = Object.assign({}, campaign)
    setSelectedCampaign(copiedPreset)
  }

  async function launchCampaign () {
    if (firstFireDate && recurringScheduleError) {
      NotificationUtilities.sendWarningMessage(recurringScheduleError)
    } else {
      const { status, reason } = validatePhishingCampaign({ type: 'create', isCampaignFrequencyWeeksValid })

      if (status) {
        selectedCampaign.startTime = startTime.toISO()
        selectedCampaign.name = name
        selectedCampaign.phishingAttemptWindowDays = phishingAttemptWindowDays
        selectedCampaign.firstFireDate = firstFireDate.toISO()

        await dispatch(createPhishingCampaignThunk({ companyId, selectedCampaign, history }))
        await dispatch(updateOnboardingChecklistDataThunk(companyId, { hasActiveCampaigns: true }))
      } else {
        NotificationUtilities.sendWarningMessage(reason)
      }
    }
  }

  function editCampaignPreset (campaignPreset) {
    history.push(`/companies/${companyId}${navigationRoutes.CAMPAIGNEDITOR}`, { presetId: campaignPreset.id, edit: true })
  }

  function toggleValidationModal () {
    setValidationModal(!validationModal)
  }

  async function deleteCampaignPreset () {
    try {
      const res = await apiRequestUtils.delete(`/api/companies/${companyId}/campaign-presets/${selectedPreset.id}`, { shareLevel: selectedPreset.shareLevel })
      if (res.status === 200) {
        dispatch(removePhishingCampaignPresetAction(selectedPreset.id))
        NotificationUtilities.sendSuccessMessage('Successfully deleted campaign preset!')
        toggleValidationModal()
      } else {
        throw Error('Failed to delete campaign preset. Server responded with non 200 status')
      }
    } catch (err) {
      NotificationUtilities.sendErrorMessage('Failed to delete campaign preset!')
      toggleValidationModal()
      console.error(err)
    }
  }

  function handlePresetDelete (campaignPreset) {
    setSelectedPreset(campaignPreset)
    toggleValidationModal()
  }

  if (selectedCampaign) {
    const allTemplates = [...userTemplates, ...defaultTemplates, ...sharedTemplates]
    const content = (selectedCampaign.campaignType === campaignTypes.CONTINUOUS) ? { allCategories: campaignCategories, allTemplates } : allTemplates
    return (
      <>
        <MDBRow>
          <MDBCol>
            <h1>Campaign Launcher</h1>
            <p>Configure this campaign's scheduling details and then you're done!</p>
          </MDBCol>
        </MDBRow>
        {!onboardingChecklist.hasWelcomedUsers && (
          <StaticAlert
            severity='warning' color='warning' text='You have not sent a welcome notification to your users yet. Phin recommends sending a welcome email before launching a phishing campaign.
          You can do this from the users page, or go back to the onboarding checklist on the home page.'
          />
        )}
        {(!company || isLoadingCompany ||
        isLoadingTemplates || isLoadingCampaignCategories ||
        isLoadingSendingDomains || isLoadingActiveCampaigns ||
        isLoadingPhishingCampaignPresets) && (
          <Spinner />
        )}
        {(company && !isLoadingCompany &&
        !isLoadingTemplates && !isLoadingCampaignCategories &&
        !isLoadingSendingDomains && !isLoadingActiveCampaigns &&
        !isLoadingPhishingCampaignPresets) && (
          <>
            {isPreset &&
              <CampaignName name={name} setName={setName} />}
            <CampaignFrequencySelector
              startTime={startTime}
              setStartTime={setStartTime}
              phishingAttemptWindowDays={phishingAttemptWindowDays}
              setPhishingAttemptWindowDays={setPhishingAttemptWindowDays}
              campaignFrequency={campaignFrequency}
              tz={company ? company.timezone.value : 'America/New_York'}
              campaignType={campaignTypes.PHISHING}
              isRunning={selectedCampaign ? selectedCampaign.running : false}
              nextFireTimes={nextFireTimes}
              firstFireDate={firstFireDate}
              setFirstFireDate={setFirstFireDate}
              nextFireTimesErrorMessage={nextFireTimesErrorMessage}
              isCampaignFrequencyWeeksValid={isCampaignFrequencyWeeksValid}
              setIsCampaignFrequencyWeeksValid={setIsCampaignFrequencyWeeksValid}
              recurringScheduleError={recurringScheduleError}
              setRecurringScheduleError={setRecurringScheduleError}
            />
            <PhinCard title='Campaign Content'>
              <ContentViewer content={content} selectedCampaign={selectedCampaign} />
            </PhinCard>
            <StepperButtonTray
              cancel={() => history.goBack()}
              action={launchCampaign}
              actionText='Launch'
            />
          </>
        )}
      </>
    )
  } else {
    return (
      <>
        <PhinModal
          isOpen={validationModal}
          title='Delete Campaign Preset'
          close={() => setValidationModal(false)}
          closeText='Cancel'
          action={deleteCampaignPreset}
          actionColor='error'
          actionText='Delete'
        >
          <p>Are you sure you want to delete this preset? This will delete the preset for any company in this partner that shares this preset! <strong>This can not be undone!</strong></p>
        </PhinModal>

        <MDBRow>
          <MDBCol>
            <div className='padding-bottom:1'>
              <Button variant='text' style={STYLE.BUTTONS.TERTIARY} color='primary' size='small' onClick={() => history.goBack()} startIcon={<IoArrowBackOutline />}>Return to Phishing Dashboard</Button>
            </div>
            <h1>Campaign Launcher</h1>
            <p>Select a preset below to launch that campaign. If you would rather build a custom campaign, select the "Start A New Campaign" button on the phishing dashboard.</p>
          </MDBCol>
        </MDBRow>

        {(!company || isLoadingCompany ||
        isLoadingTemplates || isLoadingCampaignCategories ||
        isLoadingSendingDomains || isLoadingActiveCampaigns ||
        isLoadingPhishingCampaignPresets || !phishingCampaignPresets) && (
          <Spinner />
        )}

        {(company && !isLoadingCompany &&
        !isLoadingTemplates && !isLoadingCampaignCategories &&
        !isLoadingSendingDomains && !isLoadingActiveCampaigns &&
        !isLoadingPhishingCampaignPresets && phishingCampaignPresets) && (
          <>
            <PresetLibrary
              campaignPresets={phishingCampaignPresets}
              launchCampaignPreset={launchCampaignPreset}
              editCallback={editCampaignPreset}
              deleteCallback={handlePresetDelete}
            />
          </>
        )}
      </>
    )
  }
}

export default CampaignLauncher
