import GrapesJS from 'grapesjs'
import React from 'react'

const Editor = React.forwardRef(
  (props, ref) => {
    const {
      onInit,
      id,
      blockManager,
      styleManager,
      storageManager,
      width,
      height,
      plugins: propPlugins,
      children,
      onDestroy
    } = props

    const [editor, setEditor] = React.useState(null)

    React.useEffect(
      () => {
        const editor = GrapesJS.init({
          container: `#${id}`,
          fromElement: true,
          blockManager,
          styleManager,
          storageManager,
          width,
          height,
          plugins: [
            ...propPlugins
          ]
        })
        if (typeof onInit === 'function') {
          onInit(editor)
        }
        setEditor(editor)

        // This causes issues. We don't ever rerender this component so maybe we shouldn't
        // destroy on unmount either. Number of editors becomes 0 and stays 0 if you close the editor
        // even if you open it back up multiple times.

        // removing destroy on unmount seems to create a new editor every time you open it up. If this doesn't break anything
        // else we can just write it off as ineffecient for right now!

        // return function cleanup() {
        //   if (editor) {
        //     if (typeof onDestroy === 'function') {
        //       onDestroy(editor);
        //     }
        //     GrapesJS.editors = GrapesJS.editors.filter((e: any) => e !== editor);
        //     editor.destroy();
        //     if (document) {
        //       const container: HTMLDivElement = document.getElementById(id) as HTMLDivElement;
        //       if (container) {
        //         container.innerHTML = '';
        //       }
        //     }
        //   }
        // };
      },
      [blockManager, height, id, onDestroy, onInit, propPlugins, storageManager, styleManager, width]
    )

    React.useImperativeHandle(ref, () => {
      return editor
    })

    return (
      <div id={id}>
        {children}
      </div>
    )
  }
)

Editor.defaultProps = {
  id: 'grapesjs-react-editor',
  plugins: [],
  blocks: [],
  blockManager: {},
  storageManager: {},
  styleManager: {},
  width: 'auto',
  height: '100vh',
  components: []
}

export default Editor

window.GrapesJS = GrapesJS
