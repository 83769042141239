import { LESSON_STATUS, userStatuses } from '../../../../frontendConsts.js'
import { IoCheckmarkCircle, IoReloadSharp, IoSendSharp } from 'react-icons/io5'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { DateTime } from 'luxon'
import ActionSplitButton from '../../buttons/ActionSplitButton.js'
import { capitalizeFirstLetter } from '../../../../utils/FormattingUtils.js'

export function UserDataTable ({ userData, remindUser, resetTrainingForUser, selectedCourse, toggleCompleteTrainingModalOpen, isUserCourseTable }) {
  const renderActionButton = (user) => {
    const menuItems = [
      {
        text: 'Reset Training',
        disabled: selectedCourse.archived || user.status === userStatuses.DELETED,
        icon: <IoReloadSharp />,
        actionFunction: () => {
          resetTrainingForUser(user)
        }
      },
      {
        text: 'Complete Training',
        disabled: selectedCourse.archived || user.status === userStatuses.DELETED || user.lesson_status === LESSON_STATUS.PASSED,
        icon: <IoCheckmarkCircle />,
        actionFunction: () => {
          toggleCompleteTrainingModalOpen({ user })
        }
      }
    ]

    return (
      <ActionSplitButton
        key={`${user.first}-${user.last}-${user.email}`}
        disabled={selectedCourse.archived || user.status === userStatuses.DELETED}
        actionButtonText='Send Reminder'
        actionButtonIcon={<IoSendSharp />}
        actionButtonFunction={() => remindUser(user)}
        menuItems={menuItems}
      />
    )
  }

  const userDataColumns = [
    { field: 'first', headerName: 'First Name', minWidth: 100, flex: 0.5 },
    { field: 'last', headerName: 'Last Name', minWidth: 100, flex: 0.5 },
    { field: 'email', headerName: 'Email', minWidth: 250, flex: 1 },
    { field: 'status', headerName: 'User Status', minWidth: 100, flex: 0.5, valueGetter: ({ row }) => capitalizeFirstLetter(row.status), type: 'singleSelect', valueOptions: [{ value: 'Active', label: 'Active' }, { value: 'Deleted', label: 'Deleted' }, { value: 'Slept', label: 'Slept' }] },
    { field: 'lesson_status', headerName: 'Course Status', minWidth: 100, flex: 0.5, valueGetter: ({ row }) => capitalizeFirstLetter(row.lesson_status) },
    { field: 'score', headerName: 'Score', minWidth: 100, flex: 0.5, valueGetter: ({ row }) => row.score ? (+row.score.raw / (+row.score.max - +row.score.min) * 100) + '%' : 'N/A' },
    { field: 'actions', headerName: 'Actions', minWidth: 250, flex: 1, disableColumnMenu: true, sortable: false, filterable: false, renderCell: ({ row }) => renderActionButton(row) }
  ]

  return (
    <DataGrid
      style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
      className='DataGrid'
      rows={userData}
      columns={userDataColumns}
      getRowId={(row) => `${row.first}-${row.last}-${row.email}`}
      components={{ Toolbar: GridToolbar }}
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
          csvOptions: {
            fields: ['first', 'last', 'email', 'status', 'lesson_status', 'score'],
            fileName: `Training Analytics ${selectedCourse.name} ${DateTime.now().toLocaleString()}`
          },
          printOptions: { disableToolbarButton: true }
        }
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: 'dateAssigned', sort: 'desc' }]
        },
        pagination: {
          pageSize: 25
        },
        filter: {
          filterModel: {
            items: [{
              columnField: 'status',
              operatorValue: 'is',
              value: 'Active'
            }]
          }
        }
      }}
      disableColumnSelector
      disableDensitySelector
      disableSelectionOnClick
    />
  )
}
