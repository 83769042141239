import React, { useState } from 'react'
import { Button, Collapse, Grid, TextField } from '@mui/material'
import { IoCaretDown } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import PhinModal from './PhinModal'

function AddUserModal ({
  isOpen,
  addUserInfo,
  uploadUser,
  masterToggle,
  user
}) {
  const { company } = useSelector(state => state.company)
  const [additionalFieldsOpened, setAdditionalFieldsOpened] = useState(false)

  const primaryFields = []
  const optionalFields = []

  if (company) {
    const structure = JSON.parse(company.deviceStructure)
    for (const column of structure) {
      if (column.field === 'first' || column.field === 'last' || column.field === 'email') {
        primaryFields.push(
          <TextField
            id={`add-user-modal-input-${column.field}`}
            aria-label={`add user input ${column.field}`}
            key={column.field}
            value={user[column.field]}
            label={column.label}
            onChange={e => {
              addUserInfo(e, column.field)
            }}
          />
        )
      } else {
        optionalFields.push(
          <TextField
            id={`add-user-modal-input-${column.field}`}
            aria-label={`add user input ${column.field}`}
            key={column.field}
            value={user[column.field]}
            label={column.label}
            onChange={e => {
              addUserInfo(e, column.field)
            }}
          />
        )
      }
    }
  }

  return (
    <PhinModal
      isOpen={isOpen}
      size='medium'
      title='Add User'
      close={() => { masterToggle('Close Add User'); setAdditionalFieldsOpened(false) }}
      closeText='Cancel'
      action={async () => {
        await uploadUser()
        setAdditionalFieldsOpened(false)
      }}
      actionText='Add User'
    >
      <Grid container direction='column' p={1} rowGap={2}>
        {primaryFields}
      </Grid>
      <Button
        size='large'
        id='additional-fields-button'
        aria-label='Additional Fields Button'
        onClick={() => setAdditionalFieldsOpened(!additionalFieldsOpened)}
        color='primary'
        variant='contained'
        endIcon={<IoCaretDown />}
        sx={{ marginBottom: 'var(--phin-s-1)', marginLeft: 'var(--phin-s-2)' }}
      >Additional Fields
      </Button>

      <Collapse in={additionalFieldsOpened}>
        <Grid container direction='column' rowGap={2}>
          {optionalFields}
        </Grid>
      </Collapse>
    </PhinModal>
  )
}

export default AddUserModal
