import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { PhinCard } from '../PhinCard'

function ConfigQuestion ({ index, questionsCollection, setQuestionsCollection, addPhishingCategories, removePhishingCategories, addTrainingTopic, removeTrainingTopic }) {
  const questionUpdate = (answer) => {
    questionsCollection[index].answer = answer
    setQuestionsCollection([...questionsCollection])
    if (questionsCollection[index].answer === 'Yes') {
      if (questionsCollection[index].topic && questionsCollection[index].topic !== '') {
        addTrainingTopic(questionsCollection[index].topic)
      }
      if (questionsCollection[index].categories && questionsCollection[index].categories.length > 0) {
        addPhishingCategories(questionsCollection[index].categories)
      }
    } else if (questionsCollection[index].answer === 'No') {
      if (questionsCollection[index].topic && questionsCollection[index].topic !== '') {
        removeTrainingTopic(questionsCollection[index].topic)
      }
      if (questionsCollection[index].categories && questionsCollection[index].categories.length > 0) {
        removePhishingCategories(questionsCollection[index].categories)
      }
    }
  }

  return (
    <PhinCard
      title={`Question ${index + 1}`}
      description={questionsCollection[index].question}
    >
      <div className='padding-top:1' />
      <FormControl>
        <RadioGroup
          aria-labelledby='trial-radio-buttons-group-label'
          defaultValue=''
          name='trial-radio-buttons-group'
          value={questionsCollection[index].answer || ''}
          onChange={(e) => questionUpdate(e.target.value)}
        >
          {questionsCollection[index].optionsArray.map((option, index) => {
            return (<FormControlLabel key={index} value={option} control={<Radio />} label={option} />)
          }
          )}
        </RadioGroup>
      </FormControl>
    </PhinCard>
  )
}

export default ConfigQuestion
