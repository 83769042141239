import { createSlice } from '@reduxjs/toolkit'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'

export const tempStagedGroupsSlice = createSlice({
  name: 'tempStagedGroups',
  initialState: {
    loaders: {
      loadingTempStagedGroups: false
    },
    tempStagedGroups: null
  },
  reducers: {
    loadingTempStagedGroupsUpdated: (state, action) => {
      state.loaders.loadingTempStagedGroups = action.payload
    },
    getTempStagedGroups: (state, action) => {
      state.tempStagedGroups = action.payload
    },
    deleteTempStagedGroups: (state) => {
      state.tempStagedGroups = null
    }
  }
})

export const {
  loadingTempStagedGroupsUpdated: loadingTempStagedGroupsUpdatedAction,
  getTempStagedGroups: getTempStagedGroupsAction,
  deleteTempStagedGroups: deleteTempStagedGroupsAction
} = tempStagedGroupsSlice.actions

export default tempStagedGroupsSlice.reducer

export const getTempStagedGroupsByCompanyThunk = ({ companyId }) => {
  return async (dispatch, getState, api) => {
    dispatch(loadingTempStagedGroupsUpdatedAction(true))

    try {
      const response = await api.get(`/api/companies/${companyId}/stagedGroups/temp`)
      if (response.status === 200) {
        const { tempStagedGroups } = await response.json()
        dispatch(getTempStagedGroupsAction(tempStagedGroups))
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to get stage groups.')
        }, 0)
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to get stage groups.')
      }, 0)
    }

    dispatch(loadingTempStagedGroupsUpdatedAction(false))
  }
}
