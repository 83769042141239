import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PhinCard } from '../pages/components/PhinCard'
import { Grid, TextField } from '@mui/material'
import NotificationUtilities from './components/notifications/notificationUtils'
import ValidationUtils from '../utils/validationUtils'
import { useSelector, useDispatch } from 'react-redux'
import { createAdminThunk } from '../store/DistributorSlice'
import Spinner from './components/Spinner'
import { SaveCancelButtonsRow } from '../shared/components/SaveCancelButtonsRow'

function cancelCreation (history) {
  history.goBack()
}

const CreateAdmin = ({ id }) => {
  const [adminFirst, setAdminFirstName] = useState('')
  const [adminLast, setAdminLastName] = useState('')
  const [adminEmail, setAdminEmail] = useState('')
  const history = useHistory()
  const dispatch = useDispatch()
  const { isLoadingCreateAdmin } = useSelector((state) => state.distributor.loaders)
  const distributor = useSelector((state) => state.distributor.distributor)

  function validate () {
    if (!ValidationUtils.isNotWhiteSpace(adminFirst)) {
      throw Error('Please input a first name')
    }
    if (!ValidationUtils.isNotWhiteSpace(adminLast)) {
      throw Error('Please input a last name')
    }
    if (!ValidationUtils.isValidEmail(adminEmail)) {
      throw Error('Please use a valid email')
    }
  }

  async function finishCreation () {
    try {
      validate()
      dispatch(createAdminThunk({
        adminEmail,
        adminFirst,
        adminLast,
        id,
        distributorName: distributor.name,
        history
      }))
    } catch (error) {
      console.error(error)
      NotificationUtilities.sendWarningMessage(error.message)
    }
  }

  return (
    <>
      <h1 id='partner-page-heading'>Add A New Administrator</h1>
      {!isLoadingCreateAdmin && (
        <>
          <PhinCard
            title='Admin Info'
          >
            <Grid container direction='column' rowGap={2}>
              <TextField
                value={adminFirst}
                id='new-admin-first-input'
                onChange={(e) => {
                  setAdminFirstName(e.target.value)
                }}
                name='admin first name'
                label='First Name'
              />
              <TextField
                value={adminLast}
                id='new-admin-last-input'
                onChange={(e) => {
                  setAdminLastName(e.target.value)
                }}
                name='admin last name'
                label='Last Name'
              />

              <TextField
                value={adminEmail}
                id='new-admin-email-input'
                onChange={(e) => {
                  setAdminEmail(e.target.value)
                }}
                name='admin email'
                label='Email'
              />
            </Grid>
          </PhinCard>
          <div className='padding-top:2'>
            <SaveCancelButtonsRow
              action={finishCreation}
              actionText='Finish'
              actionId='create-admin-button'
              close={() => cancelCreation(history)}
              closeText='Cancel'
              closeVariant='underlined'
              closeId='cancel-admin-creation-button'
            />
          </div>
        </>
      )}

      {isLoadingCreateAdmin && (
        <Spinner />
      )}
    </>
  )
}

export default CreateAdmin
