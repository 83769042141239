import { createSlice } from '@reduxjs/toolkit'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'
import { addImportedUsersToUserCountAction } from './CompanySlice'
import { userStatuses } from '../frontendConsts'

const compareUsers = (a, b) => {
  if (a.last.toLowerCase() < b.last.toLowerCase()) {
    return -1
  } else if (a.last.toLowerCase() > b.last.toLowerCase()) {
    return 1
  } else {
    return 0
  }
}

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    users: null,
    usersMap: null,
    groups: null,
    editingUser: null,
    loaders: {
      isLoadingUsers: false,
      isLoadingUsersMap: false,
      isLoadingGroups: false,
      isLoadingImportUsers: false
    }
  },
  reducers: {
    setIsLoadingUsers: (state, action) => {
      state.loaders.isLoadingUsers = action.payload
    },
    getUsers: (state, action) => {
      state.users = action.payload.sort(compareUsers)
    },
    setIsLoadingUsersMap: (state, action) => {
      state.loaders.isLoadingUsersMap = action.payload
    },
    setUsersMap: (state, action) => {
      state.usersMap = action.payload
    },
    setIsLoadingGroups: (state, action) => {
      state.loaders.isLoadingGroups = action.payload
    },
    getGroups: (state, action) => {
      state.groups = action.payload
    },
    addUser: (state, action) => {
      if (state.users) {
        state.users = [...state.users, action.payload]
        state.usersMap[action.payload.id] = action.payload
      } else {
        state.users = [action.payload]
        state.usersMap[action.payload.id] = action.payload
      }
    },
    toggleUserSleep: (state, action) => {
      const updatedUsersMap = {}

      const updatedUsers = state.users.map((currentUser) => {
        for (const updatedUser of action.payload) {
          if (currentUser.id === updatedUser.userId) {
            currentUser.status = updatedUser.status
          }
        }

        if (currentUser.status === userStatuses.ACTIVE) {
          updatedUsersMap[currentUser.id] = currentUser
        }

        return currentUser
      })

      state.users = updatedUsers
      state.usersMap = updatedUsersMap
    },
    deleteUsers: (state, action) => {
      const newUsers = []
      const newUsersMap = {}

      for (const user of state.users) {
        let deleteStatus = false
        action.payload.forEach(deletedUserId => {
          if (deletedUserId === user.id) {
            deleteStatus = true
          }
        })

        if (deleteStatus === false) {
          newUsers.push(user)
          newUsersMap[user.id] = user
        }
      }

      state.users = newUsers
      state.usersMap = newUsersMap
    },
    setEditingUser: (state, action) => {
      state.editingUser = action.payload
    },
    resetEditingUser: (state, action) => {
      state.editingUser = {}
    },
    updateUser: (state, action) => {
      state.users = state.users.map((user) => {
        if (action.payload.userId === user.id) {
          return { ...user, ...action.payload.editedUserFields }
        }
        return user
      })

      const updatedUsersMap = { ...state.usersMap }
      updatedUsersMap[action.payload.userId] = { ...updatedUsersMap[action.payload.userId], ...action.payload.editedUserFields }

      state.usersMap = updatedUsersMap
    },
    setisLoadingImportUsers: (state, action) => {
      state.loaders.isLoadingImportUsers = action.payload
    },
    importUsers: (state, action) => {
      const updateUsers = [...state.users]
      updateUsers.concat(action.payload)
      state.users = updateUsers
    },
    updateGroup: (state, action) => {
      const updatedGroups = state.groups

      for (const updatedGroup of updatedGroups) {
        if (updatedGroup.id === action.payload.groupId) {
          updatedGroup.status = action.payload.status
        }
      }

      state.updatedGroups = state.groups
    }
  }
})

export const {
  setIsLoadingUsers: setIsLoadingUsersAction,
  getUsers: getUsersAction,
  setIsLoadingUsersMap: setIsLoadingUsersMapAction,
  setUsersMap: setUsersMapAction,
  setIsLoadingGroups: setIsLoadingGroupsAction,
  getGroups: getGroupsAction,
  addUser: addUserAction,
  toggleUserSleep: toggleUserSleepAction,
  deleteUsers: deleteUsersAction,
  setEditingUser: setEditingUserAction,
  resetEditingUser: resetEditingUserAction,
  updateUser: updateUserAction,
  setisLoadingImportUsers: setisLoadingImportUsersAction,
  importUsers: importUsersAction,
  updateGroup: updateGroupAction
} = usersSlice.actions

export default usersSlice.reducer

export const getUsersThunk = (companyId) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingUsersAction(true))
    try {
      const res = await api.get(`/api/companies/${companyId}/users`)
      if (res.status === 200) {
        const users = await res.json()
        dispatch(getUsersAction(users))
      }
    } catch (err) {
      console.error(err)
    }
    dispatch(setIsLoadingUsersAction(false))
  }
}

export const getUsersMapThunk = (companyId) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingUsersMapAction(true))
    try {
      const res = await api.get(`/api/companies/${companyId}/users/active-users-map`)
      if (res.status === 200) {
        const usersMap = await res.json()
        dispatch(setUsersMapAction(usersMap))
      }
    } catch (err) {
      console.error(err)
    }
    dispatch(setIsLoadingUsersMapAction(false))
  }
}

export const getGroupsThunk = (companyId) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingGroupsAction(true))
    try {
      const res = await api.get(`/api/companies/${companyId}/groups`)
      if (res.status === 200) {
        const groups = await res.json()
        dispatch(getGroupsAction(groups))
      }
    } catch (err) {
      console.error(err)
    }
    dispatch(setIsLoadingGroupsAction(false))
  }
}

export const addUserThunk = (id, user) => {
  return async (dispatch, getState, api) => {
    const res = await api.post(`/api/companies/${id}/users`, { user })

    if (res.ok) {
      const userId = await res.json()
      if (res.response !== 'No Increment') {
        await dispatch(addUserAction({ id: userId, ...user, status: 'active', email: user.email.toLowerCase() }))
      }

      return { userId, success: true }
    } else {
      return { success: false, statusCode: res.status }
    }
  }
}

export const bulkSleepThunk = (id, userStatuses) => {
  return async (dispatch, getState, api) => {
    // send request to back end
    const status = userStatuses[0].shouldSleep ? 'slept' : 'active'
    const res = await api.put(`/api/companies/${id}/users/bulk-sleep`, { users: userStatuses })
    const { users } = await res.json()
    if (res.status !== 200) {
      NotificationUtilities.sendErrorMessage(`Error changing ${status} users! Please try again or contact Phin Support for assistance.`)
      return { users: null, success: false }
    } else {
      await dispatch(toggleUserSleepAction(users))
      return { users, success: true }
    }
  }
}

export const bulkWelcomeThunk = (companyId, userIds, sendAt) => {
  return async (dispatch, getState, api) => {
    const res = await api.post(`/api/companies/${companyId}/notifications/welcome`, { userIds, sendAt })
    if (res.status !== 200) {
      NotificationUtilities.sendErrorMessage('Error welcoming those users!')
      return false
    } else {
      return true
    }
  }
}

export const deleteUsersThunk = (id, userIds) => {
  return async (dispatch, getState, api) => {
    const res = await api.delete(`/api/companies/${id}/users`, { userIds })
    dispatch(deleteUsersAction(userIds))
    return res
  }
}

export const editUserThunk = (id, userId, editedUserFields) => {
  return async (dispatch, getState, api) => {
    if (editedUserFields.email) {
      editedUserFields.email = editedUserFields.email.toLowerCase()
    }

    const res = await api.put(`/api/companies/${id}/users/${userId}`,
      { editedUserFields })

    if (res.status === 200) {
      dispatch(updateUserAction({ userId, editedUserFields }))
      setTimeout(() => {
        NotificationUtilities.sendSuccessMessage('Successfully updated user!')
      }, 500)
      dispatch(resetEditingUserAction())
      return res
    } else {
      NotificationUtilities.sendErrorMessage('Failed to update user!')
    }
  }
}

export const importUsersThunk = ({ id, importedUsers }) => {
  return async (dispatch, getState, api) => {
    dispatch(setisLoadingImportUsersAction(true))

    try {
      const res = await api.post(`/api/companies/${id}/users/import`, { users: importedUsers })

      if (res.status === 200) {
        const ids = await res.json()
        const merged = []
        for (let i = 0; i < importedUsers.length; i++) {
          merged.push({ id: ids[i], ...importedUsers[i] })
        }

        dispatch(importUsersAction(merged))
        dispatch(addImportedUsersToUserCountAction(merged.length))
        dispatch(setisLoadingImportUsersAction(false))
        return { success: true, userIds: ids }
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to import users. Please try again later.')
        })
      }
    } catch (err) {
      console.error(err)
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to import users. Please try again later.')
      })
    }
    dispatch(setisLoadingImportUsersAction(false))
    return false
  }
}

export const updateGroupThunk = ({ companyId, groupId, updatedFields }) => {
  return async (dispatch, getState, api) => {
    try {
      const response = await api.put(`/api/companies/${companyId}/groups/${groupId}/ignore`, updatedFields)

      if (response.status === 200) {
        const { status } = await response.json()
        dispatch(updateGroupAction({ groupId, status }))
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to update Group.')
        }, 0)
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to update Group.')
      }, 0)
    }
  }
}
