import React, { useEffect, useRef } from 'react'
import { apiRequestUtils } from '../../../utils/apiRequestUtils'

function TrainingModulePreview ({ companyId, courseDefinition, ...other }) {
  const iFrameRef = useRef()

  useEffect(() => {
    window.API = new window.Scorm12API({ })

    window.API.on('LMSInitialize', function () {
      return true
    })

    iFrameRef.current.src = apiRequestUtils.getCourseRootPath(courseDefinition)
  }
  , [])

  return (
    <>
      <div className='scorm-content'>
        <iframe ref={iFrameRef} id='scorm-iframe' className='scorm-frame scorm-container' height='800px' width='100%' object-fit='scale-down' />
      </div>
    </>
  )
}

export default TrainingModulePreview
