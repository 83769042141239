import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Grid, Select, Switch, MenuItem, Alert, IconButton, TextField } from '@mui/material'
import ValidationUtils from '../../../utils/validationUtils'
import NotificationUtilities from '../notifications/notificationUtils'
import { generateReportThunk } from '../../../store/ReportGeneratorSlice'
import PhinModal from '../PhinModal'
import { IoTrash } from 'react-icons/io5'

const ReportGenerator = ({
  companyId
}) => {
  const dispatch = useDispatch()
  const [includeHistoricalData, setIncludeHistoricalData] = useState(false)
  const [includeUsersToWatch, setIncludeUsersToWatch] = useState(false)
  const [includePhishingCSV, setIncludePhishingCSV] = useState(false)
  const [includeTrainingCSV, setIncludeTrainingCSV] = useState(false)
  const [includePhishingReport, setIncludePhishingReport] = useState(false)
  const [includeUsersToWatchCSV, setIncludeUsersToWatchCSV] = useState(false)
  const [emailInput, setEmailInput] = useState('')
  const [deliveryEmails, setDeliveryEmails] = useState([])
  const [open, setOpen] = useState(false)
  const [type, setType] = useState('monthly')

  const { loaders } = useSelector(state => state.reportGenerator)
  const { isLoadingReportGenerationRequest } = loaders

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick' && isLoadingReportGenerationRequest) { return }
    setOpen(false)
  }
  const addEmail = async () => {
    if (ValidationUtils.isValidEmail(emailInput) && !deliveryEmails.includes(emailInput)) {
      setDeliveryEmails([...deliveryEmails, emailInput])
      setEmailInput('')
    } else {
      NotificationUtilities.sendWarningMessage('Please enter a valid email address.')
    }
  }

  const generateReport = async () => {
    const options = { includeHistoricalData, includeUsersToWatch, includePhishingCSV, includeTrainingCSV, includePhishingReport, includeUsersToWatchCSV }
    const succeeded = await dispatch(generateReportThunk({ companyId, deliveryEmails, options, type }))

    if (succeeded) {
      setOpen(false)
      setTimeout(() => {
        NotificationUtilities.sendSuccessMessage('Report generation started. Check the Reporting History table for the report in a few minutes.')
      })
    }
  }

  const removeEmail = async (email) => {
    setDeliveryEmails(deliveryEmails.filter(thisEmail => thisEmail !== email))
  }

  const generateReportViewerList = () => {
    const reportViewersList = []

    if (deliveryEmails && deliveryEmails.length > 0) {
      deliveryEmails.forEach((deliveryEmail, idx) => {
        reportViewersList.push(
          <div key={idx} className='d-flex justify-content-start align-items-center'>
            <span className='align-self-center'>{deliveryEmail}</span>

            <div className='padding-left:-2 phin-flex-end'>
              <IconButton
                id={`delete-report-viewer-${deliveryEmail}-button`}
                aria-label={`Delete Report Viewer ${deliveryEmail} Button`}
                onClick={() => removeEmail(deliveryEmail)}
                rounded
                className='align-items-center'
                type='submit'
                size='large'
                variant='contained'
                color='error'
              >
                <IoTrash />
              </IconButton>
            </div>
          </div>
        )
      })
    } else {
      reportViewersList.push(
        <p key='1'>No Report Viewers</p>
      )
    }

    return reportViewersList
  }

  return (
    <>
      <Button
        size='large'
        variant='contained'
        sx={{ marginBottom: '1em' }}
        id='generate-report-button'
        aria-label='Generate Report Button'
        onClick={handleClickOpen}
      >
        Generate Report
      </Button>

      <PhinModal
        isOpen={open}
        title='Report Generator'
        size='large'
        close={() => {
          handleClose()
          setIncludeHistoricalData(false)
          setIncludeUsersToWatch(false)
          setIncludePhishingCSV(false)
          setIncludeTrainingCSV(false)
          setIncludePhishingReport(false)
          setIncludeUsersToWatchCSV(false)
          setDeliveryEmails([])
          setType('monthly')
          setEmailInput('')
        }}
        action={generateReport}
        actionText='Generate'
        aria-labelledby='customized-dialog-title'
        maxWidth={false}
      >
        <>
          <p>
            Generate an Ad-Hoc Report using custom settings with optional delivery to multiple recipients. Choose options for the custom delivery including a list of recipients. Standard configured report viewers will not receive this report, only those selected for this unique generation. If no emails are specified, the report will be saved in the Reporting History table only.
          </p>
          <Alert severity='info' sx={{ marginTop: 'var(--phin-s2)', marginBottom: 'var(--phin-s2)' }}>
            Your report will be generated in a few minutes, and will appear in the reporting history table.
            It will not be sent to any user.
          </Alert>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className='phin-h4 padding-bottom:2'>Report Recipients</div>
              <div className='margin-bottom:2'>
                {generateReportViewerList()}
              </div>
              <Grid container spacing={2}>
                <Grid item xs={9} md={5}>
                  <TextField
                    id='report-generator-email-address-field'
                    aria-label='Report Generator Email Address Field'
                    fullWidth
                    value={emailInput}
                    onChange={(e) => setEmailInput(e.target.value)}
                    label='Email Address'
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    id='report-generator-add-email-button'
                    size='large'
                    aria-label='Report Generator Add Email Button'
                    variant='contained'
                    onClick={() => addEmail()}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container sx={{ paddingTop: 'var(--phin-s2)', paddingLeft: 'var(--phin-s0)' }} spacing={2}>
              <Grid item xs={6}>
                Include "Historical Data" Table
                <Switch
                  id='toggleIncludeHistoricalData' checked={includeHistoricalData} onClick={() => { setIncludeHistoricalData(!includeHistoricalData) }}
                />
              </Grid>
              <Grid item xs={6}>
                Include "Users To Watch" Table
                <Switch id='toggleIncludeUsersToWatch' checked={includeUsersToWatch} onClick={() => { setIncludeUsersToWatch(!includeUsersToWatch) }} />
              </Grid>
              <Grid item xs={6}>
                Include "Users To Watch" CSV
                <Switch id='toggleIncludeUsersToWatchCSV' checked={includeUsersToWatchCSV} onClick={() => { setIncludeUsersToWatchCSV(!includeUsersToWatchCSV) }} />
              </Grid>
              <Grid item xs={6}>
                Include CSV output of raw phishing data
                <Switch id='toggleIncludePhishingCSV' checked={includePhishingCSV} onClick={() => setIncludePhishingCSV(!includePhishingCSV)} />
              </Grid>
              <Grid item xs={6}>
                Include CSV output of training data
                <Switch id='toggleIncludeTrainingCSV' checked={includeTrainingCSV} onClick={() => setIncludeTrainingCSV(!includeTrainingCSV)} />
              </Grid>
              {type === 'monthly' && (
                <Grid item xs={6}>
                  Include Phishing Report PDF
                  <Switch id='toggleMonthlyPhishingPDFReport' checked={includePhishingReport} onClick={() => setIncludePhishingReport(!includePhishingReport)} />
                </Grid>
              )}
              <Grid item xs={12}>
                Report Type to generate:
                <Select
                  id='report-type-dropdown'
                  value={type}
                  onChange={(event) => { setType(event.target.value) }}
                  sx={{ marginLeft: '1rem' }}
                  displayEmpty
                  data-cy='report-type-dropdown'
                >
                  <MenuItem data-cy='report-type-dropdown-weekly' value='monthly'>Monthly - Last Month's Monthly Report</MenuItem>
                  <MenuItem data-cy='report-type-dropdown-monthly' value='weekly'>Weekly - Current Month Progress Report</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </>
      </PhinModal>
    </>
  )
}

export default ReportGenerator
