/**
 * Tests to see if the current browser allows local storage
 * @param {Storage} localStorage An instance of the Storage object
 * @returns {boolean} Whether or not this browser is allowing access to localStorage
 */
module.exports.hasAccessToLocalStorage = () => {
  const test = 'test'
  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

const localStorageEnabled = module.exports.hasAccessToLocalStorage()

module.exports.readLocalRole = () => {
  if (localStorageEnabled) {
    const roleType = localStorage.getItem('roleType')
    const id = localStorage.getItem('id')
    const role = localStorage.getItem('role')
    if (roleType === null) {
      return null
    } else {
      return { roleType, id, role }
    }
  }
  return null
}

module.exports.writeLocalRole = (roleObj) => {
  if (localStorageEnabled) {
    localStorage.setItem('roleType', roleObj.roleType)
    localStorage.setItem('id', roleObj.id)
    localStorage.setItem('role', roleObj.role)
  }
}

/**
 * Tests to see if the current browser allows local session storage
 * @param {Storage} sessionStorage An instance of the Storage object
 * @returns {boolean} Whether or not this browser is allowing access to sessionStorage
 */
module.exports.hasAccessToSessionStorage = () => {
  const test = 'test'
  try {
    sessionStorage.setItem(test, test)
    sessionStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}
