import React from 'react'
import '../../css/integrationsDetail.css'
import '../../css/integrationCard.css'

export function ReportAPhishConsentCard ({ consentFunction }) {
  return (
    <div className='consentCard'>
      <div className='consentTitle'>You will need to grant Microsoft consent to enable this integration.</div>
      <div className='permissions'>
        <p className='consentSubtitle'>What are the permissions?</p>
        <ul>
          <li>
            User.Read.All
            <ul>
              <li>
                Allows the app to read user profiles without a signed in user.
                Used to fetch all of the microsoft azure users to be imported into Phin
              </li>
            </ul>
          </li>
        </ul>
        <ul>
          <li>
            Mail.Send
            <ul>
              <li>
                Allows the app to send mail as any user without a signed-in user.
                Used to forward reported phishing emails to the configured email or Microsoft's reporting service
              </li>
            </ul>
          </li>
        </ul>
        <ul>
          <li>ThreatSubmission.ReadWrite.All</li>
          <ul>
            <li>
              Allows the app to submit reported emails for threat analysis to Microsoft Defender.
            </li>
          </ul>
        </ul>
        <p className='consentSubtitle'>How will Phin use the permissions?</p>
        <ul>
          <li>
            Phin will use the above listed permissions to access your client's users' inboxes for mail forwarding and reporting purposes.
          </li>
        </ul>
      </div>
      <div className='d-flex justify-content-center'>
        <button
          id='continue-to-microsoft-button'
          aria-label='Continue to Microsoft Button'
          className='consentBtn'
          onClick={() => consentFunction()}
        >
          Continue to Microsoft
        </button>
      </div>
    </div>
  )
}
