import React, { useRef } from 'react'
import { Button, Grid, Switch, TextField, FormControlLabel } from '@mui/material'
import { useMountEffect } from '../../../utils/SettingsUtils'
import { phishTestHeaderText } from '../../../frontendConsts.js'
import { PhinCard } from '../PhinCard.js'

const SendingSettings = ({
  phishTestHeader,
  setPhishTestHeader,
  customSendingHeader,
  setCustomSendingHeader,
  shouldFocusSendingSettings
}) => {
  const customHeaderRef = useRef(null)

  const executeScroll = () => {
    if (shouldFocusSendingSettings) {
      customHeaderRef.current.scrollIntoView()
    }
  }

  useMountEffect(executeScroll)

  return (
    <div className='padding-bottom:2' ref={customHeaderRef}>
      <PhinCard
        title='Sending Settings'
        description="Define a custom header to be attached to emails sent by Phin.  You can use this value to find and filter Phin's emails in other email products."
      >
        <Grid container direction='row' sx={{ marginBottom: 'var(--phin-s1)', marginTop: 'var(--phin-s0)' }}>
          <Grid item sm={3}>
            <FormControlLabel
              sx={{ marginLeft: '0px' }}
              value='Phish Test Header'
              control={<Switch
                id='phish-test-header-toggle'
                aria-label='Phish Test Header Toggle'
                checked={phishTestHeader}
                onClick={() => setPhishTestHeader()} color='primary'
                       />}
              label='Phish Test Header'
              labelPlacement='start'
            />
          </Grid>
          {phishTestHeader &&
            <Grid item sm={9}>
              <TextField
                multiline
                rows='2'
                fullWidth
                label='PhishTest Header'
                value={`${phishTestHeaderText.name} : ${phishTestHeaderText.value}`}
              />
            </Grid>}

        </Grid>
        <Grid container direction='row'>
          <Grid item sm={3}>
            <Button
              id='generate-header-button'
              aria-label='Generate Header Button'
              color='primary'
              variant='contained'
              onClick={setCustomSendingHeader}
            >Generate
            </Button>
          </Grid>
          <Grid item sm={9}>
            <TextField
              id='custom-header-text'
              aria-label='Custom Header Text'
              fullWidth
              label='Custom Header'
              value={customSendingHeader.name !== '' ? `${customSendingHeader.name} : ${customSendingHeader.value}` : 'No Custom Header'}
            />
          </Grid>
        </Grid>
      </PhinCard>
    </div>
  )
}

export default SendingSettings
