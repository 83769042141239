import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { UserSyncActionButtons } from './UserSyncActionButtons'
import { userStatuses, groupStatuses } from '../../../frontendConsts.js'
import '../../css/integrationsDetail.css'
import '../../css/integrationCard.css'
import { bulkSleepThunk, updateGroupThunk } from '../../../store/UsersSlice'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Button, Skeleton } from '@mui/material'
import { decrementUserCountAction, incrementUserCountAction } from '../../../store/CompanySlice.js'

export function UserSyncTable ({
  companyId, haveConsented
}) {
  const dispatch = useDispatch()
  const { users, groups, loaders } = useSelector((state) => state.users)
  const { isLoadingUsers, isLoadingGroups } = loaders
  const { integrationsMap } = useSelector((state) => state.integrations)
  const { loaders: userSyncSliceLoaders } = useSelector((state) => state.userSync)
  const { loadingUserSyncStatus } = userSyncSliceLoaders
  const [integration, setIntegration] = useState()
  const [tableType, setTableType] = useState('users')
  const [tablePageSize, setTablePageSize] = useState(10)
  const [syncedUsers, setSyncedUsers] = useState()

  useEffect(() => {
    if (integrationsMap && integrationsMap.userSync) {
      setIntegration(integrationsMap.userSync)
    }
  }, [integrationsMap])

  useEffect(() => {
    if (users) {
      setSyncedUsers(users.filter((user) => user.externalId))
    }
  }, [users])

  const toggleIgnoreUser = ({ userId, status }) => {
    const shouldSleep = (status !== userStatuses.SLEPT)
    dispatch(bulkSleepThunk(companyId, [{ id: userId, shouldSleep }]))

    if (shouldSleep) {
      dispatch(decrementUserCountAction())
    } else {
      dispatch(incrementUserCountAction())
    }
  }

  const toggleIgnoreGroup = ({ groupId, status }) => {
    const updatedStatus = status === groupStatuses.SLEPT ? groupStatuses.ACTIVE : groupStatuses.SLEPT
    dispatch(updateGroupThunk({ companyId, groupId, updatedFields: { status: updatedStatus } }))
  }

  const renderUserToggleButton = (user) => {
    return (
      <Button
        id={`toggle-user-status-button-${user.id}`}
        aria-label={user.status === userStatuses.SLEPT
          ? 'Toggle User to Active'
          : 'Toggle User to Slept'}
        variant={user.status === userStatuses.SLEPT ? 'outlined' : 'contained'}
        onClick={() => toggleIgnoreUser({ userId: user.id, status: user.status })}
      >
        {user.status === userStatuses.SLEPT ? 'Include' : 'Sleep'}
      </Button>
    )
  }

  const renderGroupToggleButton = (group) => {
    return (
      <Button
        id={`toggle-group-status-button-${group.id}`}
        aria-label={group.status === groupStatuses.SLEPT
          ? 'Toggle Group to Active'
          : 'Toggle Group to Slept'}
        variant={group.status === groupStatuses.SLEPT ? 'outlined' : 'contained'}
        onClick={() => toggleIgnoreGroup({ groupId: group.id, status: group.status })}
      >
        {group.status === groupStatuses.SLEPT ? 'Include' : 'Sleep'}
      </Button>
    )
  }

  const userColumns = [
    {
      headerName: 'Email',
      field: 'email',
      flex: 2
    },
    {
      headerName: 'First',
      field: 'first',
      flex: 1
    },
    {
      headerName: 'Last',
      field: 'last',
      flex: 1
    },
    {
      headerName: 'Action',
      field: 'action',
      flex: 1,
      renderCell: (params) => renderUserToggleButton(params.row),
      sortable: false,
      filterable: false,
      disableExport: true
    }
  ]

  const groupColumns = [
    {
      headerName: 'Display Name',
      field: 'displayName',
      flex: 3
    },
    {
      headerName: 'Group Size',
      field: 'groupSize',
      valueGetter: (params) => { return +params.row.members.length },
      flex: 1
    },
    {
      headerName: 'Action',
      field: 'action',
      flex: 1,
      renderCell: (params) => renderGroupToggleButton(params.row),
      sortable: false,
      filterable: false,
      disableExport: true
    }
  ]

  return (
    <div className='right' style={haveConsented ? {} : { filter: 'opacity(.2)', pointerEvents: 'none' }}>
      <div className='tableTabSection'>
        <div className='tableTabGroup'>
          <Button
            onClick={() => setTableType('users')}
            variant={tableType === 'users' ? 'contained' : 'outlined'}
            sx={{ mr: 2 }}
          >Users
          </Button>
          <Button
            onClick={() => setTableType('groups')}
            variant={tableType === 'groups' ? 'contained' : 'outlined'}
          >
            Groups
          </Button>

        </div>
        <div className='phinCardTitle'>
          User Sync
        </div>

      </div>

      {(isLoadingUsers || !users || isLoadingGroups || !groups || !integration ||
      integration.isSyncing || loadingUserSyncStatus || !syncedUsers) && (
        <div className='margin-top:0' style={{ height: '100%' }}>
          <Skeleton variant='rounded' width='100%' height='100%' />
        </div>
      )}

      {(!isLoadingUsers && users && !isLoadingGroups && groups && integration && !integration.isSyncing &&
      !loadingUserSyncStatus && syncedUsers) && (
        <div className='margin-top:0' style={{ height: '100%' }}>
          {tableType === 'users' && (
            <DataGrid
              style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
              className='DataGrid'
              initialState={{
                sorting: {
                  sortModel: [{ field: 'last', sort: 'asc' }]
                }
              }}
              rows={syncedUsers}
              columns={userColumns}
              getRowClassName={(params) => params.row.status === groupStatuses.SLEPT ? 'slept' : ''}
              pageSize={tablePageSize}
              onPageSizeChange={(newSize) => setTablePageSize(newSize)}
              rowsPerPageOptions={[10, 25, 50]}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: {
                    debounceMs: 500,
                    id: 'synced-users-table-search',
                    'aria-label': 'Search'
                  },
                  printOptions: { disableToolbarButton: true },
                  csvOptions: {
                    fileName: 'SyncedUsers',
                    delimiter: ','
                  }
                }
              }}
              disableSelectionOnClick
              disableColumnSelector
              disableDensitySelector
              disableColumnFilter
              disableColumnMenu
            />
          )}
          {tableType === 'groups' && (
            <DataGrid
              style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
              className='DataGrid'
              initialState={{
                sorting: {
                  sortModel: [{ field: 'displayName', sort: 'asc' }]
                }
              }}
              rows={groups}
              columns={groupColumns}
              getRowClassName={(params) => { return params.row.status === userStatuses.SLEPT ? 'slept' : '' }}
              pageSize={tablePageSize}
              onPageSizeChange={(newSize) => setTablePageSize(newSize)}
              rowsPerPageOptions={[10, 25, 50]}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: {
                    debounceMs: 500,
                    id: 'synced-groups-table-search',
                    'aria-label': 'Search'
                  },
                  printOptions: { disableToolbarButton: true },
                  csvOptions: {
                    fileName: 'SyncedGroups',
                    delimiter: ','
                  }
                }
              }}
              disableSelectionOnClick
              disableColumnSelector
              disableDensitySelector
              disableColumnFilter
              disableColumnMenu
            />
          )}
        </div>
      )}
      <UserSyncActionButtons companyId={companyId} />
    </div>
  )
}
