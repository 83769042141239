import PhishingCampaignResource from './PhishingCampaignResource'
import TrainingCampaignResource from './TrainingCampaignResource'

function ContinuousCampaignViewer ({ phishingCategories, phishingCampaignName, phishingDescription, trainingTopics, trainingCampaignName, trainingDescription }) {
  return (
    <div>

      <h2 className='phin-h2 d-flex justify-content-left padding-top:2 padding-bottom:2'>Campaign Review</h2>

      <PhishingCampaignResource
        phishingDescription={phishingDescription}
        phishingCampaignName={phishingCampaignName}
        phishingCategories={phishingCategories}
      />

      <br />

      <TrainingCampaignResource
        trainingCampaignName={trainingCampaignName}
        trainingDescription={trainingDescription}
        trainingTopics={trainingTopics}
      />

    </div>
  )
}
export default ContinuousCampaignViewer
