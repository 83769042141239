import Badge from '@mui/material/Badge'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton'
import { useEffect, useRef, useState } from 'react'
import { DateTime } from 'luxon'
import { Tooltip } from '@mui/material'

// TODO: Set up calendar to show the window of time the phishes will actually be sent

export default function CampaignScheduleCalendar ({ firstFireDate, nextFireTimes }) {
  const requestAbortController = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [highlightedDays, setHighlightedDays] = useState([])
  const [viewableDate, setViewableDate] = useState(DateTime.now())

  const handleMonthChange = (dateTime) => {
    const newHighlightedDates = []
    if (requestAbortController.current) {
      // make sure that you are aborting useless requests
      // because it is possible to switch between months pretty quickly
      requestAbortController.current.abort()
    }

    setIsLoading(true)

    for (const nextFireTime of nextFireTimes) {
      if (nextFireTime.year === dateTime.year && nextFireTime.month === dateTime.month) {
        newHighlightedDates.push(nextFireTime.day)
      }
    }

    setHighlightedDays(newHighlightedDates)
    setViewableDate(dateTime)
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)

    const newHighlightedDates = []

    if (nextFireTimes) {
      for (const nextFireTime of nextFireTimes) {
        if (nextFireTime.year === viewableDate.year && nextFireTime.month === viewableDate.month) {
          newHighlightedDates.push(nextFireTime.day)
        }
      }
    }

    setHighlightedDays(newHighlightedDates)

    setIsLoading(false)
  }, [firstFireDate, nextFireTimes])

  function HighlightedDate (props) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props

    const isSelected =
      !props.outsideCurrentMonth && highlightedDays.indexOf(day.day) >= 0

    if (firstFireDate && day.year === firstFireDate.year && day.month === firstFireDate.month && day.day === firstFireDate.day) {
      return (
        <Badge
          key={day.toString()}
          overlap='circular'
          variant={props.outsideCurrentMonth ? undefined : 'dot'}
          color='primary'
          badgeContent={props.outsideCurrentMonth ? undefined : ''}
        >
          <Tooltip
            title='First Run Time' placement='top'
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -14]
                    }
                  }
                ]
              }
            }}
          >
            <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
          </Tooltip>
        </Badge>
      )
    } else {
      return (
        <Badge
          key={day.toString()}
          overlap='circular'
          variant={isSelected ? 'dot' : undefined}
          color='primary'
          badgeContent={isSelected ? '' : undefined}
        >
          {isSelected
            ? (
              <Tooltip
                title='Campaign Run Time' placement='top'
                slotProps={{
                  popper: {
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, -14]
                        }
                      }
                    ]
                  }
                }}
              >
                <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
              </Tooltip>
              )
            : (
              <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
              )}
        </Badge>
      )
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <DateCalendar
        readOnly
        loading={isLoading}
        onMonthChange={handleMonthChange}
        renderLoading={() => <DayCalendarSkeleton />}
        slots={{
          day: HighlightedDate
        }}
        slotProps={{
          day: {
            highlightedDays
          }
        }}
      />

      <p className='padding-left:1'> <Badge
        overlap='circular'
        variant='dot'
        color='primary'
        badgeContent=''
        sx={{ margin: 'var(--phin-s-2)', marginBottom: 'var(--phin-s-1)' }}
                                     />Indicates a campaign send, or window
      </p>
    </LocalizationProvider>
  )
}
