import { Button, ButtonGroup, ClickAwayListener, Grow, ListItemIcon, MenuItem, MenuList, Paper, Popper } from '@mui/material'
import { useRef, useState } from 'react'
import { IoCaretDown } from 'react-icons/io5'

export default function ActionSplitButton ({ key, disabled = false, actionButtonText, actionButtonIcon, actionButtonFunction, menuItems }) {
  const [open, setOpen] = useState(false)

  const ref = useRef()

  return (
    <>
      <ButtonGroup key={key} variant='outlined' color='primary' ref={ref} aria-label='split button'>
        <Button disabled={disabled} onClick={actionButtonFunction} startIcon={actionButtonIcon || undefined}>{actionButtonText}</Button>
        <Button
          color='primary'
          size='small'
          disabled={disabled}
          aria-label='Send user reminder'
          aria-haspopup='menu'
          onClick={() => setOpen(!open)}
        >
          <IoCaretDown />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={ref.current} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList id='split-button-menu'>
                  {menuItems.map((option, index) => (
                    <MenuItem
                      key={index}
                      disabled={option.disabled || false}
                      onClick={() => option.actionFunction()}
                    >
                      <ListItemIcon>
                        {option.icon}
                      </ListItemIcon>
                      {option.text}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}
