import { Tooltip } from '@mui/material'
import '../css/badge.css'

function PhinBadge ({ icon, displayString, color }) {
  return (
    <div>
      <Tooltip title={displayString} placement='right'>
        <div className='default-badge' style={{ color }}>
          {icon}
        </div>
      </Tooltip>
    </div>
  )
}
export default PhinBadge
