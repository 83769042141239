import React, { useState } from 'react'
import ViewerModal from '../../ViewerModal'
import TemplatesPreviewer from '../TemplatesPreviewer'

import CampaignSendingChannelEditor from '../CampaignSendingChannelEditor.js'
import { campaignTypes } from '../../../../frontendConsts.js'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { IconButton, Tooltip } from '@mui/material'
import { IoEye } from 'react-icons/io5'

const previewTemplate = (template, setModalViewer, setSelectedTemplate) => {
  setModalViewer(true)
  setSelectedTemplate(template)
}

const previewTemplates = (templates, setTemplatesPreviewerModal, setCategoryTemplates) => {
  setTemplatesPreviewerModal(true)
  setCategoryTemplates(templates)
}

const ContentViewer = ({ selectedCampaign, content }) => {
  const isContinuous = (selectedCampaign.campaignType === campaignTypes.CONTINUOUS)
  const [modalViewer, setModalViewer] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState({})
  const [tablePageSize, setTablePageSize] = useState(10)

  const [templatesPreviewerModal, setTemplatesPreviewerModal] = useState(false)
  const [categoryTemplates, setCategoryTemplates] = useState([])

  const continuousCampaignColumns = [
    {
      headerName: 'Category Name',
      field: 'name',
      flex: 2
    },
    {
      headerName: 'Options',
      field: 'options',
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip
            title='View Category' placement='top'
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -14]
                    }
                  }
                ]
              }
            }}
          >
            <IconButton
              id={`view-${params.row.name}-category-button`}
              aria-label={`View ${params.row.name} Category Button`}
              color='primary'
              onClick={() => { previewTemplates(content.allTemplates.filter((template) => template.tags.includes(params.row.name)), setTemplatesPreviewerModal, setCategoryTemplates) }}
            >
              <IoEye />
            </IconButton>
          </Tooltip>
        )
      }
    }
  ]

  const fixedLengthCampaignColumns = [
    {
      headerName: 'Template Name',
      field: 'name',
      flex: 2
    },
    {
      headerName: 'View Template',
      field: 'options',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Tooltip
            title='View Template' placement='top'
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -14]
                    }
                  }
                ]
              }
            }}
          >
            <IconButton
              id={`view-${params.row.id}-template-button`}
              aria-label={`View ${params.row.name} Template Button`}
              color='primary'
              onClick={() => { previewTemplate(params.row, setModalViewer, setSelectedTemplate) }}
            >
              <IoEye />
            </IconButton>
          </Tooltip>
        )
      }
    }
  ]

  const handleToggleViewerModal = (html) => {
    setModalViewer(!modalViewer)
    setSelectedTemplate({})
  }

  const handleToggleTemplatesPreviewerModal = () => {
    setTemplatesPreviewerModal(!templatesPreviewerModal)
    setCategoryTemplates([])
  }

  const sendingChannelViewer = (isContinuous) ? <CampaignSendingChannelEditor disableUpdating updateSendingChannels={() => {}} sendingChannels={selectedCampaign.sendingChannels} /> : (<></>)

  return (
    <>
      <ViewerModal title='Templates' isOpen={modalViewer} toggleViewerModal={handleToggleViewerModal} template={selectedTemplate} />

      <TemplatesPreviewer toggleModal={handleToggleTemplatesPreviewerModal} isOpen={templatesPreviewerModal} templates={categoryTemplates} />

      {sendingChannelViewer}

      <div style={{ height: '50vh', width: '100%' }}>
        <DataGrid
          style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
          className='DataGrid'
          rows={isContinuous
            ? selectedCampaign.categoryNames.map((category) => ({ name: category, id: category }))
            : content.filter((template) => selectedCampaign.templateNames.includes(template.id))}
          columns={isContinuous
            ? continuousCampaignColumns
            : fixedLengthCampaignColumns}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 }
            }
          }}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          pageSize={tablePageSize}
          rowsPerPageOptions={[10, 20, 50]}
          onPageSizeChange={(newSize) => setTablePageSize(newSize)}
          pagination
        />
      </div>
    </>
  )
}

export default ContentViewer
