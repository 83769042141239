import React, { memo } from 'react'
import TemplatePreset from '../../../grapesjs-preset-newsletter/src/index'
import 'grapesjs/dist/css/grapes.min.css'
import Editor from './TemplateEditor/Editor.js'

const shouldNotRerender = () => true

const TemplateEditor = ({ setTemplateEditor }) => {
  const storageOptions = {
    setAutosave: false
  }

  return (
    <Editor id='template-editor' presetType='newsletter' plugins={[TemplatePreset]} storageManager={storageOptions} onInit={(editor) => setTemplateEditor(editor)} />
  )
}

export default memo(TemplateEditor, shouldNotRerender)
