import { Button, Card, CardActions, CardContent, Chip, Grid } from '@mui/material'
import { IoEye, IoRocket } from 'react-icons/io5'

export function CourseCatalogCard ({ course, openConfirmModal, togglePreviewModal }) {
  return (
    <Card
      variant='outlined'
      sx={{
        width: '400px',
        minHeight: '525px',
        marginBottom: 'var(--phin-s2)',
        padding: '16px',
        borderWidth: '3px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <CardContent>
        <Grid>
          <div style={{ minHeight: '100px' }}>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              sx={{ marginBottom: 'var(--phin-s-1)' }}
            >
              <Chip
                label={<span className='phin-body-text'>{course.provider}</span>}
                color='phinSecondary'
              />
              <Chip
                color='primary'
                variant='outlined'
                label={course.topic}
              />
            </Grid>

            <p className='phin-h5 margin-bottom:-1'>{course.name}</p>
          </div>

          <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
            sx={{ marginBottom: 'var(--phin-s-1)' }}
          >
            <img
              src={course.thumbnail || 'https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20%28131%29.jpg'}
              style={{ width: '16rem', border: '2px solid grey', borderRadius: '8px', marginBottom: 'var(--phin-s1)' }}
              class='center'
            />
          </Grid>

          <p className='phin-body-text-small-gray'>{course.description}</p>

        </Grid>
      </CardContent>
      <CardActions>
        <Grid
          container
          direction='row'
          justifyContent='space-around'
          alignItems='center'
        >
          <Button
            id={`launch-training-${course.name}-button`}
            aria-label='Launch Training Button'
            size='large'
            variant='outlined'
            startIcon={<IoRocket />}
            onClick={() => openConfirmModal(course)}
          >Assign
          </Button>

          <Button
            id={`preview-training-${course.name}-button`}
            aria-label='Preview Training Course Button'
            size='large'
            variant='outlined'
            startIcon={<IoEye />}
            onClick={() => togglePreviewModal(course, true)}
          >View
          </Button>
        </Grid>
      </CardActions>
    </Card>
  )
}
