import { ServerError } from './errors/index'

export const callAndRetry = async (handleToPromise, retryAmount, errorMessage, waitTime = 1000) => {
  const wait = ms => new Promise((resolve) => setTimeout(resolve, ms))
  if (retryAmount < 0) {
    throw new Error(`${errorMessage} Encountered an error retrying method. Please refresh the page and try again.`)
  } else {
    try {
      const response = await handleToPromise()
      if (response.status === 200) {
        return response
      } else if (response.status === 500) {
        throw new ServerError(`${errorMessage} `, response.statusText || 'Internal Server Error. Please refresh the page and try again.')
      } else {
        throw new Error(`${errorMessage} `, response.statusText || '')
      }
    } catch (err) {
      if (err instanceof ServerError) {
        console.error(err)
        await wait(waitTime)
        return callAndRetry(handleToPromise, retryAmount - 1, waitTime)
      } else {
        throw err
      }
    }
  }
}
