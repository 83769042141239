import { createSlice } from '@reduxjs/toolkit'
import { campaignTypes } from '../frontendConsts.js'
const kv = (name) => ({ text: name, value: name })

const textToValueMap = {
  iterations: {
    Once: { text: 'Once', value: 'Once' },
    Twice: { text: 'Twice', value: 'Twice' },
    'All At Once': { text: 'All At Once', value: 'All At Once' }
  },
  frequency: {
    Week: { text: 'Week', value: 'Week' },
    Month: { text: 'Month', value: 'Month' },
    Quarter: { text: 'Quarter', value: 'Quarter' }
  },
  month: {
    1: { text: '1st', value: 1 },
    2: { text: '2nd', value: 2 },
    3: { text: '3rd', value: 3 }
  },
  week: {
    1: { text: '1st', value: 1 },
    2: { text: '2nd', value: 2 },
    3: { text: '3rd', value: 3 },
    4: { text: '4th', value: 4 }
  },
  weekday: {
    0: { text: 'Sunday', value: 0 },
    1: { text: 'Monday', value: 1 },
    2: { text: 'Tuesday', value: 2 },
    3: { text: 'Wednesday', value: 3 },
    4: { text: 'Thursday', value: 4 },
    5: { text: 'Friday', value: 5 },
    6: { text: 'Saturday', value: 6 }
  }
}

const fix = (name, value) => {
  return textToValueMap[name][value]
}

export const campaignFrequencySlice = createSlice({
  name: 'campaignFrequency',
  initialState: {
    iterations: kv('Once'),
    iterationsOptions: [kv('Once'), kv('Twice')],
    frequency: kv('Month'),
    frequencyOptions: [{ text: 'Month', value: 'Month' }, { text: 'Week', value: 'Week' }, { text: 'Quarter', value: 'Quarter' }],
    month: { text: '1st', value: 1 },
    monthOptions: [{ text: '1st', value: 1 }, { text: '2nd', value: 2 }, { text: '3rd', value: 3 }],
    week: [{ text: '1st', value: 1 }],
    weekOptions: [{ text: '1st', value: 1 }, { text: '2nd', value: 2 }, { text: '3rd', value: 3 }, { text: '4th', value: 4 }],
    weekday: { text: 'Monday', value: 1 },
    weekdayOptions: [{ text: 'Monday', value: 1 }, { text: 'Tuesday', value: 2 }, { text: 'Wednesday', value: 3 }, { text: 'Thursday', value: 4 },
      { text: 'Friday', value: 5 }, { text: 'Saturday', value: 6 }, { text: 'Sunday', value: 0 }],
    time: '08:00'
  },
  reducers: {
    setIterations: (state, action) => {
      state.iterations = action.payload
      if (action.payload.value === 'Once') {
        state.frequency = kv('Month')
        state.frequencyOptions = [{ text: 'Week', value: 'Week' }, { text: 'Month', value: 'Month' }, { text: 'Quarter', value: 'Quarter' }]
        state.week = [{ text: '1st', value: 1 }]
      } else if (action.payload.value === 'Twice') {
        state.frequency = kv('Month')
        state.frequencyOptions = [{ text: 'Month', value: 'Month' }]
        state.week = [{ text: '1st', value: 1 }, { text: '3rd', value: 3 }]
      }
    },
    setFrequency: (state, action) => {
      state.frequency = action.payload
      if (action.payload.value === 'Quarter') {
        state.month = { text: '1st', value: 1 }
        if (!state.week) {
          state.week = [{ text: '1st', value: 1 }]
        }
      } else if (action.payload.value === 'Month') {
        if (!state.week) {
          state.week = [{ text: '1st', value: 1 }]
        }
      }
    },
    setFrequencyOptions: (state, action) => {
      state.frequencyOptions = action.payload
    },
    setMonth: (state, action) => {
      state.month = action.payload
    },
    setWeek: (state, action) => {
      const weekOptions = []

      // The input field sends us string numbers, but we need these to be sanitized as integers for calculations
      for (let i = 0; i < action.payload.length; i++) {
        const sanitizedValue = parseInt(action.payload[i].value)
        weekOptions.push({ text: action.payload[i].text, value: sanitizedValue })
      }

      state.week = weekOptions
    },
    setWeekday: (state, action) => {
      state.weekday = action.payload
    },
    resetAllCampaignFrequencyChoices: (state, action) => {
      state.iterations = kv('Once')
      state.iterationsOptions = [kv('Once'), kv('Twice')]
      state.frequency = kv('Month')
      state.frequencyOptions = [{ text: 'Month', value: 'Month' }, { text: 'Week', value: 'Week' }, { text: 'Quarter', value: 'Quarter' }]
      state.month = { text: '1st', value: 1 }
      state.monthOptions = [{ text: '1st', value: 1 }, { text: '2nd', value: 2 }, { text: '3rd', value: 3 }]
      state.week = [{ text: '1st', value: 1 }]
      state.weekOptions = [{ text: '1st', value: 1 }, { text: '2nd', value: 2 }, { text: '3rd', value: 3 }, { text: '4th', value: 4 }]
      state.weekday = { text: 'Monday', value: 1 }
      state.weekdayOptions = [{ text: 'Monday', value: 1 }, { text: 'Tuesday', value: 2 }, { text: 'Wednesday', value: 3 }, { text: 'Thursday', value: 4 },
        { text: 'Friday', value: 5 }, { text: 'Saturday', value: 6 }, { text: 'Sunday', value: 0 }]
      state.time = '08:00'
    },

    setAllCampaignFrequencyChoices: (state, action) => {
      state.iterations = action.payload.iterations
      state.frequency = action.payload.frequency
      state.month = action.payload.month
      state.week = action.payload.week
      state.weekday = action.payload.weekday
    },

    campaignSelected: (state, action) => {
      // TODO move ALLLLL of the text options to the visual components and only store the values in the slice
      const { isContinuous, campaignType, campaignFrequency } = action.payload
      if (campaignType !== campaignTypes.ONBOARDING) {
        state.iterations = fix('iterations', campaignFrequency.iterations)
        state.frequency = fix('frequency', campaignFrequency.frequency)
        state.month = campaignFrequency.month ? fix('month', campaignFrequency.month) : null
        state.week = campaignFrequency.week ? campaignFrequency.week.map(week => fix('week', week)) : null
        state.weekday = fix('weekday', campaignFrequency.weekday)
        state.time = campaignFrequency.time
      }

      if (campaignType === campaignTypes.TRAINING && !isContinuous) {
        state.iterationsOptions = [kv('Once'), kv('Twice'), kv('All At Once')]
      } else if (campaignType !== campaignTypes.ONBOARDING) {
        state.iterationsOptions = [kv('Once'), kv('Twice')]
      }

      if (action.payload.iterations === 'Once') {
        state.frequencyOptions = [{ text: 'Week', value: 'Week' }, { text: 'Month', value: 'Month' }, { text: 'Quarter', value: 'Quarter' }]
      } else if (action.payload.iterations === 'Twice') {
        state.frequencyOptions = [{ text: 'Month', value: 'Month' }]
      }
    },

    setCampaignFireTime: (state, action) => {
      const { minutes, hours } = action.payload
      let minutesString = '' + minutes
      let hoursString = '' + hours
      if (minutesString.length === 1) {
        minutesString = '0' + minutesString
      }
      if (hoursString.length === 1) {
        hoursString = '0' + hoursString
      }
      state.time = `${hoursString}:${minutesString}`
    }
  }
})

export const {
  setCampaignFireTime: setCampaignFireTimeAction,
  setIterations: setIterationsAction,
  campaignSelected: campaignSelectedAction,
  setFrequency: setFrequencyAction,
  setFrequencyOptions: setFrequencyOptionsAction,
  setMonth: setMonthAction,
  setWeek: setWeekAction,
  setWeekday: setWeekdayAction,
  resetAllCampaignFrequencyChoices: resetAllCampaignFrequencyChoicesAction
} = campaignFrequencySlice.actions

export default campaignFrequencySlice.reducer
