import React, { Component } from 'react'

import {
  MDBCardBody,
  MDBCard,
  MDBModalBody
} from 'mdb-react-ui-kit'
import { processFromField } from '../../utils/templateUtils'
import PhinModal from './PhinModal'

class ViewerModal extends Component {
  renderIframe (template, height) {
    if (template.videoSource !== undefined) {
      return (<iframe style={{ borderColor: '#fff', boxShadow: 'none' }} title='Viewer' height={`${height}px`} src={template.videoSource} />)
    }

    if (template.html !== undefined) {
      return (<iframe title='Viewer' style={{ borderColor: '#fff', boxShadow: 'none' }} height={`${height}px`} srcDoc={template.html} />)
    }
  }

  renderSMS (template) {
    const { message } = template
    const replacedMessage = message ? message.replaceAll('\r\n', '<br />') : ''
    return (
      <div dangerouslySetInnerHTML={{ __html: replacedMessage }} />
    )
  }

  renderView (template) {
    const iframeHeightInPixels = window.innerHeight / 1.8
    if (template) {
      if (template.channel === 'email') {
        return this.renderIframe(template, iframeHeightInPixels)
      } else {
        return this.renderSMS(template)
      }
    } else {
      return <></>
    }
  }

  render () {
    const {
      isOpen,
      toggleViewerModal,
      template,
      title
    } = this.props

    return (
      <PhinModal
        key='1234'
        title={title}
        size='medium'
        autoFocus={false}
        isOpen={isOpen}
        close={toggleViewerModal}
        noButtons
      >
        <MDBModalBody>
          <MDBCard className='mb-4'>
            <MDBCardBody>
              <strong>From: {processFromField(template)}</strong>
            </MDBCardBody>
          </MDBCard>
          <MDBCard>
            {this.renderView(template)}
          </MDBCard>
        </MDBModalBody>
      </PhinModal>
    )
  }
}

export default ViewerModal
