import React, { Component } from 'react'
import SelectOptions from '../SelectOptions'
import NotificationUtilities from '../notifications/notificationUtils'
import { parseMustacheTemplate, validateSubmittedTemplate, injectedFieldSupportsCustomDefault } from '../../../utils/templateUtils'
import remove from 'lodash/remove'
import {
  MDBCol,
  MDBRow,
  MDBIcon,
  MDBBadge
} from 'mdb-react-ui-kit'

import HTMLTemplateOptions from './HTMLTemplateOptions'
import SMSTemplateOptions from './SMSTemplateOptions'
import BaseTemplateOptions from './BaseTemplateOptions'
import ValidationUtils from '../../../utils/validationUtils'
import './TemplateBuilder.css'
import { PHISHING_TEMPLATE_INJECTED_FIELDS, videos } from '../../../frontendConsts'
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { StaticAlert } from '../../../shared/components/StaticAlert'
import PhinModal from '../PhinModal'
import { PhinCard } from '../PhinCard'

// weird global reference to editor to escape all react management
let templateEditor

let updateTemplateTimer
const updateTemplateTimerTimeout = 2000

class TemplateBuilder extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedTemplate: this.generateInitialTemplateState(),
      testEmail: '',
      loading: false,
      hasActionURLField: false,
      tagInput: '',
      injectedFieldDefaultInputs: {}
    }

    if (this.props.editTemplate && Object.keys(this.props.editTemplate).length > 0) {
      this.state.selectedTemplate = this.props.editTemplate
      this.appendTagsIfMissing(this.state.selectedTemplate)
    }

    this.state.injectedFieldDefaultInputs = this.generateInjectedFieldDefaultInputs(this.state.selectedTemplate)

    this.templateUpdateCallback = this.props.templateUpdateCallback
  }

  componentDidUpdate (prevProps) {
    if (prevProps && this.props.editTemplate !== prevProps.editTemplate && Object.keys(this.props.editTemplate).length > 0) {
      const templateCopy = Object.assign({}, this.props.editTemplate)
      this.appendTagsIfMissing(templateCopy)
      const injectedFieldDefaultInputs = this.generateInjectedFieldDefaultInputs(templateCopy)
      this.setState({ selectedTemplate: templateCopy, injectedFieldDefaultInputs })
    }
  }

  generateInitialTemplateState () {
    return {
      name: '',
      type: '',
      channel: '',
      fromName: '',
      fromEmail: '',
      injectedFields: [],
      shared: false,
      tags: [],
      appeals: [],
      learningUrl: '',
      learningType: 'lm'
    }
  }

  readSystemDefaultForInjectedField (field) {
    const injectedFieldSystemDefault = this.props.injectedFieldDefaults[field.value]

    if (!injectedFieldSystemDefault) {
      // Handle this field supports a custom default, but we don't have a system default
      return ''
    }

    if (injectedFieldSystemDefault.key) {
      // Switch statement to allow for future additions of new custom default supporting fields
      switch (injectedFieldSystemDefault.key) {
        case 'partnerName':
          return this.props.company.partnerName
        default:
          // This field has a key but is not supported as a custom default
          return injectedFieldSystemDefault
      }
    } else {
      return injectedFieldSystemDefault
    }
  }

  getSystemDefaultForInjectedField (field) {
    if (injectedFieldSupportsCustomDefault(field.value)) {
      return this.readSystemDefaultForInjectedField(field)
    } else {
      return field.value
    }
  }

  generateInjectedFieldDefaultInputs (selectedTemplate) {
    const { injectedFields } = selectedTemplate
    const injectedFieldDefaultInputs = {}

    for (const injectedField of injectedFields) {
      if (injectedField.customDefault) {
        injectedFieldDefaultInputs[injectedField.name] = injectedField.customDefault
      } else {
        injectedFieldDefaultInputs[injectedField.name] = this.getSystemDefaultForInjectedField(injectedField)
      }
    }

    return injectedFieldDefaultInputs
  }

  appendTagsIfMissing (selectedTemplate) {
    if (selectedTemplate.tags === undefined) {
      selectedTemplate.tags = []
    }
  }

  validateTestEmail () {
    if (!ValidationUtils.isNotWhiteSpace(this.state.selectedTemplate.html)) {
      NotificationUtilities.sendWarningMessage('You must have a template uploaded before sending a test email')
      return false
    }

    if (!ValidationUtils.isValidEmail(this.state.testEmail)) {
      NotificationUtilities.sendWarningMessage('You must enter a valid email to send a test email')
      return false
    }

    return true
  }

  filterSystemInjectedFields (injectedFields) {
    const filteredFields = injectedFields.filter(field => { return field !== 'action_url' })
    return filteredFields
  }

  parseSMSInjectedFields (text) {
    try {
      const injectedFields = parseMustacheTemplate(text)
      return injectedFields
    } catch (err) {
      // Errors are acceptable here (still finishing a tag for example)
      return []
    }
  }

  loadFromTemplateObject (editor, templateObj, type = 'full') {
    editor.DomComponents.clear()
    editor.CssComposer.clear()
    editor.UndoManager.clear()
    if (type === 'full') {
      editor.setStyle(JSON.parse(templateObj.styles))
      editor.setComponents(JSON.parse(templateObj.components))
    } else if (type === 'html-string') {
      editor.setComponents(templateObj.html)
    }
  }

  clearEditor (editor) {
    editor.DomComponents.clear()
    editor.CssComposer.clear()
    editor.UndoManager.clear()
    editor.setStyle([])
    editor.setComponents([])
  }

  loadGrapesTemplates (editor, templateObj) {
    this.loadFromTemplateObject(editor, templateObj, 'full')
  }

  loadLegacyTemplate (editor, templateObj) {
    this.loadFromTemplateObject(editor, templateObj, 'html-string')
  }

  setTemplateEditor (editor) {
    templateEditor = editor
    templateEditor.on('storage:store', (obj) => {
      for (const [key, value] of Object.entries(obj)) {
        // TODO: This seems to work for now. Not sure if it's the best way to do it. We should fix this later.
        this.state.selectedTemplate[key] = value
      }
      this.setState({ selectedTemplate: this.state.selectedTemplate })
    })
    const viewComponentsButton = templateEditor.Panels.getButton('options', 'sw-visibility')

    // update broken icon. MDBReact must override the Font Awesome version or something
    viewComponentsButton.attributes.className = 'fa fa-eye'

    templateEditor.on('storage:load', (obj) => {
      const isGrapesTemplate = this.state.selectedTemplate && this.state.selectedTemplate.components !== undefined
      const isLegacyTemplate = this.state.selectedTemplate.components === undefined && this.state.selectedTemplate.html !== undefined

      if (isGrapesTemplate) {
        this.loadFromTemplateObject(templateEditor, this.state.selectedTemplate, 'full')
      } else if (isLegacyTemplate) {
        this.loadLegacyTemplate(templateEditor, this.state.selectedTemplate)
      } else {
        // reset the editor when we call load with no selected template (i.e. new templates)
        this.clearEditor(templateEditor)
      }
    })

    function updateInjectedFields (newFields, oldFields) {
      const newFieldNames = newFields.map(newField => newField.name)
      const results = oldFields.filter(oldField => newFieldNames.includes(oldField.name))
      newFields.forEach((newField, i) => {
        const existingInjectedFieldIndex = oldFields.findIndex(oldField => oldField.name === newField.name)
        if (existingInjectedFieldIndex < 0) {
          results.push(newFields[i])
        }
      })
      return results
    }

    const updateTemplate = () => {
      clearTimeout(updateTemplateTimer)
      this.setState({ loading: true })

      // editor gets destroyed on cancel. This makes sure we don't try to update when 'remove' events
      // are fired when the editor is unmounted. Definitely not ideal implementation. App crashes if it
      // attempts to update selectedTemplate when editor is unmounted
      if (this.props.display) {
        updateTemplateTimer = setTimeout(() => {
          const updatedTemplate = Object.assign({}, this.state.selectedTemplate)
          updatedTemplate.css = templateEditor.getCss()
          updatedTemplate.html = templateEditor.getHtml()
          updatedTemplate.components = templateEditor.getComponents()
          updatedTemplate.styles = templateEditor.getStyle()

          const injectedFields = parseMustacheTemplate(updatedTemplate.html)
          updatedTemplate.injectedFields = updateInjectedFields(injectedFields, updatedTemplate.injectedFields)
          this.setState({ loading: false, selectedTemplate: updatedTemplate })
        }, updateTemplateTimerTimeout)
      }
    }

    templateEditor.on('component:add', (model) => {
      updateTemplate(templateEditor)
    })
    templateEditor.on('component:update', (model) => {
      updateTemplate(templateEditor)
    })
    templateEditor.on('component:remove', (model) => {
      updateTemplate(templateEditor)
    })
  }

  handleInjectedFieldDefaultEdit (fieldName, defaultValue) {
    const { injectedFields } = this.state.selectedTemplate

    injectedFields.forEach((injectedField, index) => {
      if (injectedField.name === fieldName) {
        injectedFields[index] = {
          name: injectedField.name,
          value: injectedField.value,
          customDefault: defaultValue
        }
      }
    })

    const selectedTemplate = this.state.selectedTemplate
    selectedTemplate.injectedFields = injectedFields

    // Update the input value as well so the change shows up in the UI
    const injectedFieldDefaultInputs = this.state.injectedFieldDefaultInputs
    injectedFieldDefaultInputs[fieldName] = defaultValue

    this.setState({ selectedTemplate, injectedFieldDefaultInputs })
  }

  handleInjectedFieldEdit (event) {
    const injectedFields = this.state.selectedTemplate.injectedFields
    const injectedFieldDefaultInputs = this.state.injectedFieldDefaultInputs

    injectedFields.forEach((injectedField, index) => {
      if (injectedField.name === event.info.name) {
        injectedFields[index] = {
          name: injectedField.name,
          value: event.info.value
        }

        // Update the default override input if needed
        if (injectedFieldSupportsCustomDefault(event.info.value)) {
          injectedFieldDefaultInputs[event.info.name] = this.getSystemDefaultForInjectedField(event.info)
        }
      }
    })

    const selectedTemplate = this.state.selectedTemplate
    selectedTemplate.injectedFields = injectedFields

    this.setState({ selectedTemplate, injectedFieldDefaultInputs })
  }

  cancelEdit () {
    clearTimeout(updateTemplateTimer)

    const { toggle } = this.props
    toggle('create')

    const template = this.generateInitialTemplateState()
    this.setState({ loading: false, selectedTemplate: template })
  }

  handleNameEdit (event) {
    const template = this.state.selectedTemplate
    template.name = event.target.value
    this.setState({ selectedTemplate: template })
  }

  handleChannelEdit (event) {
    const template = this.generateInitialTemplateState()
    template.channel = event.target.value
    template.name = this.state.selectedTemplate.name

    this.setState({ selectedTemplate: template })
  }

  handleSubjectEdit (event) {
    const template = this.state.selectedTemplate
    template.subject = event.target.value
    this.setState({ selectedTemplate: template })
  }

  handleFromNameEdit (event) {
    const template = this.state.selectedTemplate
    template.fromName = event.target.value
    this.setState({ selectedTemplate: template })
  }

  handleEmailNameEdit (event) {
    const template = this.state.selectedTemplate
    const domain = template.fromEmail.split('@')[1]
    template.fromEmail = `${event.target.value}@${domain}`
    this.setState({ selectedTemplate: template })
  }

  handleEmailDomainEdit (event) {
    const template = this.state.selectedTemplate
    const name = template.fromEmail.split('@')[0]
    template.fromEmail = `${name}@${event.target.value}`
    this.setState({ selectedTemplate: template })
  }

  handleSMSMessageEdit (event) {
    const template = this.state.selectedTemplate
    template.message = event.target.value

    const injectedFields = this.parseSMSInjectedFields(template.message)
    template.injectedFields = injectedFields

    this.setState({ selectedTemplate: template })
  }

  tagEnterListener (event) {
    const ENTER_KEY = 13
    if (event.charCode === ENTER_KEY || !event.charCode) {
      this.handleTagAdd()
    }
  }

  handleTagEdit (event) {
    event.preventDefault()
    this.setState({ tagInput: event.target.value })
  }

  handleTagAdd () {
    if (ValidationUtils.isNotWhiteSpace(this.state.tagInput)) {
      this.addTagToSelectedTemplate(this.state.tagInput)
    } else {
      NotificationUtilities.sendWarningMessage('You must have a title for this tag to add it')
    }
  }

  updateTags (tags) {
    const selectedTemplate = this.state.selectedTemplate
    selectedTemplate.tags = tags.target.value
    this.setState({
      selectedTemplate
    })
  }

  updateLearningType (type) {
    const selectedTemplate = this.state.selectedTemplate
    selectedTemplate.learningType = type
    switch (type) {
      case 'lm':
        selectedTemplate.learningUrl = ''
        break
      case 'video':
        selectedTemplate.learningUrl = Object.values(videos)[0]
        break
      case 'custom':
        selectedTemplate.learningUrl = ''
        break
      default:
        break
    }
    this.setState({
      selectedTemplate
    })
  }

  updateLearningUrl (val) {
    const selectedTemplate = this.state.selectedTemplate
    selectedTemplate.learningUrl = val
    this.setState({
      selectedTemplate
    })
  }

  handleTagRemoval (tag) {
    const selectedTemplate = this.state.selectedTemplate
    selectedTemplate.tags = remove(selectedTemplate.tags, (val) => { return val !== tag })
    this.setState({ selectedTemplate })
  }

  addTagToSelectedTemplate (tag) {
    const selectedTemplate = this.state.selectedTemplate
    selectedTemplate.tags.push(tag)
    this.setState({ selectedTemplate, tagInput: '' })
  }

  async submitUpdatedTemplate () {
    const template = this.state.selectedTemplate
    if (template.channel === 'email') {
      templateEditor.store()
    }

    if (!validateSubmittedTemplate(template)) {
      return
    }

    // If we were passed a template to edit, handle this as an edit if not create new
    if (this.props.editTemplate && Object.keys(this.props.editTemplate).length > 0) {
      await this.templateUpdateCallback(template, true, this.props.company)
      this.cancelEdit()
    } else {
      await this.templateUpdateCallback(template, false, this.props.company)
      this.cancelEdit()
    }
    this.setState({ loading: false })
  }

  renderSpecialTemplateOptions () {
    const { sendingDomains } = this.props
    if (this.state.selectedTemplate.channel === '') {
      return
    }

    if (this.state.selectedTemplate.channel === 'email') {
      return (
        <HTMLTemplateOptions
          sendingDomains={sendingDomains}
          selectedTemplate={this.state.selectedTemplate}
          handleEmailDomainEdit={this.handleEmailDomainEdit.bind(this)}
          handleEmailNameEdit={this.handleEmailNameEdit.bind(this)}
          setTemplateEditor={this.setTemplateEditor.bind(this)}
          templateEditor={templateEditor}
          handleSubjectEdit={this.handleSubjectEdit.bind(this)}
          handleFromNameEdit={this.handleFromNameEdit.bind(this)}
        />
      )
    } else {
      return (
        <SMSTemplateOptions
          handleSMSMessageEdit={this.handleSMSMessageEdit.bind(this)}
          selectedTemplate={this.state.selectedTemplate}
        />
      )
    }
  }

  renderTemplateTags () {
    const tags = this.state.selectedTemplate.tags
    const renderedTags = []

    for (const tag of tags) {
      renderedTags.push(
        <a onClick={() => this.handleTagRemoval(tag)}>
          <h4 id={tag} className='m-2'><MDBBadge color='secondary'>{tag} <MDBIcon className='ml-1' size='sm' icon='times' /></MDBBadge></h4>
        </a>
      )
    }

    return renderedTags
  }

  renderTemplateOptions (tableData) {
    if (this.renderSpecialTemplateOptions()) {
      return (
        <>
          {this.renderSpecialTemplateOptions()}
          <BaseTemplateOptions
            updateTags={this.updateTags.bind(this)}
            updateLearningType={this.updateLearningType.bind(this)}
            updateLearningUrl={this.updateLearningUrl.bind(this)}
            tags={this.state.selectedTemplate.tags}
            categories={this.props.categories}
            learningType={this.state.selectedTemplate.learningType}
            learningUrl={this.state.selectedTemplate.learningUrl}
            tableData={tableData}
            id={this.props.id}
          />
        </>
      )
    } else {
      return (
        <div className='padding:2'>
          <StaticAlert
            severity='info' variant='filled' text='Select a template type to change additional options here.'
          />
        </div>
      )
    }
  }

  renderInjectedFieldsOptions (injectedFields, options) {
    const renderedInjectedRows = []

    for (const field of injectedFields) {
      let renderedDefault

      if (injectedFieldSupportsCustomDefault(field.value)) {
        renderedDefault = (
          <TextField
            style={{ paddingBottom: '9px' }}
            variant='outlined'
            value={this.state.injectedFieldDefaultInputs[field.name]}
            size='small'
            placeholder='Add field default here'
            onChange={(event) => {
              this.handleInjectedFieldDefaultEdit(field.name, event.target.value)
            }}
          />
        )
      } else {
        renderedDefault = <></>
      }

      renderedInjectedRows.push({
        name: field.name,
        options: (
          <SelectOptions
            name='templateFields'
            key={`${field.name}`}
            options={options}
            value={field.value}
            onChange={e =>
              this.handleInjectedFieldEdit({
                target: { name: e.target.name },
                info: {
                  name: field.name,
                  value: e.target.value
                }
              })}
          />
        ),
        default: renderedDefault
      })
    }

    return renderedInjectedRows
  }

  renderSubmitButtonText () {
    if (this.state.loading) {
      return (
        <div
          className='spinner-border'
        />
      )
    } else if (this.props.editTemplate && Object.keys(this.props.editTemplate).length > 0) {
      return 'Save'
    } else {
      return 'Create'
    }
  }

  render () {
    const {
      display,
      typeOptions
    } = this.props

    const { injectedFields } = this.state.selectedTemplate

    const injectedOptions = [
      {
        text: 'Select a Value',
        value: '',
        disabled: true,
        selected: true
      }
    ]

    for (const injectedField in PHISHING_TEMPLATE_INJECTED_FIELDS) {
      injectedOptions.push({
        text: PHISHING_TEMPLATE_INJECTED_FIELDS[injectedField].label,
        value: PHISHING_TEMPLATE_INJECTED_FIELDS[injectedField].value
      })
    }

    const selectedInjectedFields = (params) => {
      return (
        <FormControl fullWidth>
          <InputLabel id='supervisor-email'>field</InputLabel>
          <Select
            value={injectedFields[params.id].value}
            label={injectedFields[params.id].name}
            onChange={(e) => {
              const newSelectedTemplate = JSON.parse(JSON.stringify(this.state.selectedTemplate))
              newSelectedTemplate.injectedFields[params.id].value = e.target.value
              this.setState({ selectedTemplate: newSelectedTemplate })
            }}
          >
            {injectedOptions.map((option) => (
              <MenuItem
                value={option.value}
                key={option.value}
              >{option.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    }

    const renderDefaultField = (params) => {
      let renderedDefault = <></>

      if (injectedFieldSupportsCustomDefault(params.row.selectedValue)) {
        renderedDefault = (
          <TextField
            style={{ paddingBottom: '9px' }}
            variant='outlined'
            value={this.state.injectedFieldDefaultInputs[params.row.fieldName]}
            size='small'
            placeholder='Add field default here'
            onChange={(event) => {
              this.handleInjectedFieldDefaultEdit(params.row.fieldName, event.target.value)
            }}
          />
        )
      }
      return renderedDefault
    }

    const renderedInjectedRows = this.renderInjectedFieldsOptions(injectedFields, injectedOptions)

    const tableData = {
      columns: [
        {
          field: 'fieldName',
          headerName: 'Field Name',
          width: 300
        },
        {
          field: 'selectedValue',
          headerName: 'Selected Value',
          renderCell: selectedInjectedFields,
          width: 300
        },
        {
          field: 'defaultValue',
          headerName: 'Default Value',
          renderCell: renderDefaultField,
          width: 300
        }
      ],
      rows: injectedFields.map((field, index) => {
        return {
          id: index,
          fieldName: field.name,
          selectedValue: field.value,
          // defaultValue: (<div>wow</div>)
          defaultValue: renderedInjectedRows[index].options
        }
      })
    }

    const submitButtonText = this.renderSubmitButtonText()

    return (
      <PhinModal
        isOpen={display}
        title='Template Builder'
        size='large'
        className='template-builder-size'
        modalStyle='info'
        close={() => this.cancelEdit()}
        action={() => this.submitUpdatedTemplate()}
        actionText={submitButtonText}
        disableBackdropClick
        disableEscapeKeyDown
      >

        <div>
          <div className='padding:-2'>

            <PhinCard
              title='Template Name and Type'
              description='Name your template to keep track of it. Then, select if it will be an email template or a text (sms) template.'
            >

              <MDBRow className='d-flex justify-content-center'>
                <MDBCol
                  className='d-flex justify-content-center align-items-center'
                  size='6'
                >
                  <TextField
                    fullWidth
                    id='template-name-input'
                    name='templateName'
                    label='Template Name'
                    value={this.state.selectedTemplate.name}
                    onChange={e => this.handleNameEdit(e)}
                  />
                </MDBCol>

                <MDBCol
                  className='d-flex justify-content-center align-items-center'
                  size='6'
                >
                  <SelectOptions
                    name='templateChannel'
                    options={typeOptions}
                    value={this.state.selectedTemplate.channel}
                    onChange={e => this.handleChannelEdit(e)}
                  />
                </MDBCol>
              </MDBRow>
            </PhinCard>
          </div>
        </div>
        <div style={{ position: 'relative', overflow: 'auto', height: 'auto' }}>
          {this.renderTemplateOptions(tableData)}
        </div>
      </PhinModal>
    )
  }
}

export default TemplateBuilder
