import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { createAdminThunk, deleteAdminThunk } from '../../../store/PartnerSlice'
import { Box, Button, Grid, TextField, IconButton, Alert } from '@mui/material'
import { IoTrash } from 'react-icons/io5'
import { useAuth0 } from '@auth0/auth0-react'
import { DataGrid } from '@mui/x-data-grid'
import InfoIcon from '@mui/icons-material/Info'
import ValidationUtils from '../../../utils/validationUtils'
import PhinModal from '../../components/PhinModal'

export default function CreateAdminForm ({ partnerId, partner }) {
  const dispatch = useDispatch()
  const [adminFirst, setAdminFirst] = useState('')
  const [adminLast, setAdminLast] = useState('')
  const [adminEmail, setAdminEmail] = useState('')

  const [adminFirstError, setAdminFirstError] = useState()
  const [adminLastError, setAdminLastError] = useState()
  const [adminEmailError, setAdminEmailError] = useState()

  async function createAdmin (adminFirst, adminLast, adminEmail) {
    const adminCreated = await dispatch(createAdminThunk({
      adminEmail,
      adminFirst,
      adminLast,
      id: partnerId,
      partnerName: partner.name,
      adminPermission: 'full-access'
    }))
    if (adminCreated) {
      setAdminFirst('')
      setAdminLast('')
      setAdminEmail('')
    }
  }

  function handleSubmit (e) {
    e.preventDefault()
    let hasError = false

    // Reset errors
    setAdminFirstError()
    setAdminLastError()
    setAdminEmailError()

    if (!ValidationUtils.isNotWhiteSpace(adminFirst)) {
      setAdminFirstError('Please provide a first name.')
      hasError = true
    }

    if (!ValidationUtils.isNotWhiteSpace(adminLast)) {
      setAdminLastError('Please provide a last name.')
      hasError = true
    }

    if (!ValidationUtils.isValidEmail(adminEmail)) {
      setAdminEmailError('Please provide a valid email address.')
      hasError = true
    }

    if (hasError) {
      return
    }

    createAdmin(adminFirst, adminLast, adminEmail)
  }

  return (
    <Grid container spacing={4}>
      <Grid item>
        <p className='phin-h4'>Add Additional Free Trial Admin</p>

        <p className='phin-body-text padding-bottom:0'>
          Invite the rest of your team as admins to help setup your Phin free trial. Admins added here will always have Full Access.
        </p>
        <Alert icon={<InfoIcon />} severity='info' variant='outlined' sx={{ background: 'var(--phin-light-blue)', marginBottom: 'var(--phin-s0)', borderRadius: '8px' }}>Full Access grants Partner admins additional permission to view & edit billing information in our Billing page and to configure Partner admin permissions under Administrators.</Alert>

        <Grid item width='100%'>
          <form onSubmit={handleSubmit}>
            <Grid container direction='column' spacing={2}>
              <Grid item>
                <Grid container direction='row' justifyContent='space-between' spacing={2}>
                  <Grid item flex={1}>
                    <TextField
                      value={adminFirst}
                      error={adminFirstError}
                      helperText={adminFirstError}
                      onChange={(e) => setAdminFirst(e.target.value)}
                      label='First Name'
                      id='admin-first-name-field'
                      variant='outlined'
                      fullWidth
                    />
                  </Grid>
                  <Grid item flex={1}>
                    <TextField
                      value={adminLast}
                      error={adminLastError}
                      helperText={adminLastError}
                      onChange={(e) => setAdminLast(e.target.value)}
                      label='Last Name'
                      id='admin-last-name-field'
                      variant='outlined'
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <TextField
                  value={adminEmail}
                  error={adminEmailError}
                  helperText={adminEmailError}
                  onChange={(e) => setAdminEmail(e.target.value)}
                  label='Email'
                  id='admin-email-field'
                  variant='outlined'
                  type='email'
                  fullWidth
                />
              </Grid>
              <Grid item>
                <TextField
                  value='Full Access'
                  label='Permission'
                  variant='outlined'
                  disabled
                  required
                />
              </Grid>
              <Grid item alignSelf='flex-end'>
                <Button
                  sx={{ backgroundColor: 'var(--light-blue)' }}
                  variant='contained'
                  type='submit'
                  id='invite-admin-button'
                >Invite Admin
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Grid item width='100%'>
        <AdminsTable id={partnerId} partner={partner} />
      </Grid>
    </Grid>
  )
}

function AdminsTable ({ id, partner }) {
  const dispatch = useDispatch()

  const { user } = useAuth0()
  const [adminToDeleteUid, setAdminToDeleteUid] = useState('')
  const [deleteAdminConfirm, setDeleteAdminConfirm] = useState(false)

  const adminTableRows = []
  partner.admins.forEach((admin) => {
    adminTableRows.push({
      ...admin
    })
  })

  async function deleteAdmin (uid) {
    /**
     * uid -> The auth0 id of the admin
     * id -> The partner id of the partner to delete the admin from
     */
    const success = await dispatch(deleteAdminThunk({ id, uid, distributorId: partner?.distributorId }))

    if (success) {
      setDeleteAdminConfirm(false)
    }
  }

  function generatePermissionLabel (permissionLevel) {
    const textString = permissionLevel === 'full-access' ? 'Full Access' : 'Standard'
    return (
      textString
    )
  }

  function generateActions (id, email) {
    const isSelf = user.email.toLowerCase() === email.toLowerCase()
    return (
      <Box>
        {!isSelf &&
          <IconButton onClick={() => { setAdminToDeleteUid(id); setDeleteAdminConfirm(true) }}>
            <IoTrash style={{ color: 'red' }} id={`delete-${email}`} />
          </IconButton>}
      </Box>
    )
  }
  const adminTableColumns = [
    {
      headerName: 'Name',
      field: 'name',
      width: 100
    },
    {
      headerName: 'Email Address',
      field: 'email',
      width: 250
    }, {
      headerName: 'Admin Level',
      field: 'adminLevels',
      renderCell: (params) => generatePermissionLabel(params.row.adminLevels),
      sortable: false,
      filterable: false
    },
    {
      headerName: 'Actions',
      field: 'uid',
      renderCell: (params) => generateActions(params.id, params.row.email, params.row.adminLevels, params.row.name),
      sortable: false,
      filterable: false,
      width: 80
    }
  ]

  return (
    <div style={{ width: '100%' }}>
      <PhinModal
        isOpen={deleteAdminConfirm}
        title='Delete Admin'
        close={() => setDeleteAdminConfirm(!deleteAdminConfirm)}
        action={() => deleteAdmin(adminToDeleteUid)}
        actionText='Delete'
        actionColor='error'
      >
        <p>Are you sure you want to delete this admin? <strong>This can not be undone!</strong></p>
      </PhinModal>
      <p className='phin-h4'>Admin List</p>
      <DataGrid
        className='DataGrid'
        style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
        getRowId={(row) => row.uid}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }]
          }
        }}
        autoHeight
        rows={adminTableRows}
        columns={adminTableColumns}
        hideFooterPagination
        hideFooter
        disableSelectionOnClick
        disableColumnSelector
        disableDensitySelector
        disableColumnFilter
        disableColumnMenu
      />
    </div>
  )
}
