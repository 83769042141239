import { useEffect, useState } from 'react'
import { campaignTypes } from '../../../frontendConsts.js'
import { useSelector } from 'react-redux'
import ActiveTrainingCampaignCard from './ActiveTrainingCampaignCard.js'
import { StaticAlert } from '../../../shared/components/StaticAlert.js'
import { Grid } from '@mui/material'

export default function ActiveTrainingCampaigns ({
  openCancelCampaignModal, company
}) {
  const { activeCampaigns } = useSelector((state) => state.campaigns)

  const [trainingCampaigns, setTrainingCampaigns] = useState([])

  useEffect(() => {
    if (activeCampaigns) {
      setTrainingCampaigns(activeCampaigns.filter(campaign => campaign.campaignType === campaignTypes.TRAINING || campaign.campaignType === campaignTypes.ONBOARDING))
    }
  }, [activeCampaigns])

  if (trainingCampaigns.length === 0) {
    return (
      <StaticAlert
        severity='info' color='phinGray' variant='filled' text="You haven't started any trainings campaigns yet. To start a training campaign select the button above"
      />
    )
  } else {
    return (
      <Grid container spacing={4}>
        {trainingCampaigns.map(campaign => {
          return (
            <Grid item key={campaign.id}>
              <ActiveTrainingCampaignCard
                key={campaign.id}
                campaign={campaign}
                openCancelCampaignModal={openCancelCampaignModal}
                company={company}
              />
            </Grid>
          )
        }
        )}
      </Grid>
    )
  }
}
