import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { postGoogleUserSyncConsentThunk } from '../../../store/GoogleUserSyncIntegrationSlice'
import { useDispatch } from 'react-redux'
import Spinner from '../Spinner'
import NotificationUtilities from '../notifications/notificationUtils'

export function ExternalGoogleUserSyncIntegrationPage () {
  const history = useHistory()
  const dispatch = useDispatch()
  const searchParams = new URLSearchParams(history.location.search)
  const companyId = searchParams.get('state')
  const code = searchParams.get('code')

  async function setupGoogleWorkspaceSync () {
    const res = await dispatch(postGoogleUserSyncConsentThunk(companyId, { code }))
    if (res.status !== 200) {
      NotificationUtilities.sendErrorMessage('An error occured loading your consent from Google. Please try again or contact Phin support for assistance if this issue persists.')
      history.push(`/companies/${companyId}/integrations`)
    } else {
      history.push(`/companies/${companyId}/integrations/googleUserSync`)
    }
  }

  useEffect(() => {
    setupGoogleWorkspaceSync()
  }, [])

  return (<Spinner message='Submitting Google Workspace Sync' />)
}
