import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Spinner from '../Spinner'
import { enrollableUsersUpdatedAction, getEnrollableUsersThunk } from '../../../store/TrainingSlice'
import NotificationUtilities from '../notifications/notificationUtils'
import { Autocomplete, Checkbox, FormControlLabel, FormGroup, Grid, TextField } from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import PhinModal from '../PhinModal'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

export function EnrollUserModal ({
  isOpen,
  companyId, selectedCourse,
  toggleEnrollUserModal,
  selectedUsers, selectUsers,
  setEnrollModalOpen,
  setIsConfirmEnrollModalOpen,
  shouldSendReminders, setShouldSendReminders
}) {
  const dispatch = useDispatch()
  const { enrollableUsers, loaders } = useSelector((state) => state.trainings)
  const { isLoadingGetEnrollableUsers } = loaders

  const [userOptions, setUserOptions] = useState([])
  const [allSelected, setAllSelected] = useState(false)

  useEffect(() => {
    const getEnrollableUsers = async () => {
      const apiSucceeded = await dispatch(getEnrollableUsersThunk({ companyId, courseId: selectedCourse.id }))
      if (!apiSucceeded) {
        toggleEnrollUserModal({ isOpen: false, course: null })
      }
      return (
        () => {
          dispatch(enrollableUsersUpdatedAction(null))
        }
      )
    }

    getEnrollableUsers()
  }, [])

  useEffect(() => {
    if (enrollableUsers && Array.isArray(enrollableUsers)) {
      const enrollableUserOptions = [
        {
          text: 'Select All',
          value: 'all'
        }
      ]
      for (const enrollableUser of enrollableUsers) {
        enrollableUserOptions.push({
          text: `${enrollableUser.first} ${enrollableUser.last}`,
          value: enrollableUser
        })
      }
      setUserOptions(enrollableUserOptions)
    }
  }, [enrollableUsers])

  const submitEnrollUser = () => {
    if (selectedUsers && selectedUsers.length > 0) {
      setIsConfirmEnrollModalOpen(true)
      setEnrollModalOpen(false)
    } else {
      NotificationUtilities.sendWarningMessage('Please select at least 1 user to enroll before submitting.')
    }
  }

  return (
    <>
      <PhinModal
        isOpen={isOpen}
        title='Enroll Users'
        close={() => { selectUsers([]); toggleEnrollUserModal({ isOpen: false, course: null }) }}
        action={submitEnrollUser}
        actionText='Enroll'
      >
        <div className='padding:1'>
          {(isLoadingGetEnrollableUsers || !enrollableUsers) && (
            <div className='padding-left:5 padding-right:5'>
              <Spinner />
            </div>
          )}

          {(!isLoadingGetEnrollableUsers && enrollableUsers) && (
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
            >
              <Autocomplete
                multiple
                id='enrollable-users-select'
                options={userOptions}
                value={selectedUsers}
                onChange={(event, newValue, reason) => {
                  if (reason === 'clear') {
                    setAllSelected(false)
                    selectUsers([])
                  } else {
                    if (newValue.some((option) => option.value === 'all')) {
                      if (allSelected) {
                        setAllSelected(false)
                        selectUsers([])
                      } else {
                        setAllSelected(true)
                        selectUsers(userOptions.filter((option) => option.value !== 'all'))
                      }
                    } else {
                      selectUsers(newValue)
                    }
                  }
                }}
                disableCloseOnSelect
                getOptionLabel={(option) => option.text}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.value === 'all' ? 'selectAll' : option.value.id}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      checked={option.value === 'all' ? allSelected : selected}
                    />
                    {option.text}
                  </li>
                )}
                style={{ width: 450 }}
                renderInput={(params) => (
                  <TextField {...params} label='Users' placeholder={selectUsers.length > 0 ? '' : 'Select one of more users'} />
                )}
                sx={{ marginBottom: 'var(--phin-s0)' }}
              />

              <FormGroup>
                <FormControlLabel
                  onChange={() => setShouldSendReminders(!shouldSendReminders)}
                  checked={shouldSendReminders}
                  control={<Checkbox />}
                  label='Send Training Notification Now'
                />
              </FormGroup>
            </Grid>
          )}
        </div>
      </PhinModal>
    </>
  )
}
