import { Grid } from '@mui/material'
import CampaignScheduleCalendar from './CampaignScheduleCalendar'
import { useSelector } from 'react-redux'
import { campaignFrequencyIterations } from '../../../../frontendConsts'
import { DateTime } from 'luxon'

const CampaignScheduleDisplay = ({
  tz, nextFireTimes,
  firstFireDate, nextFireTimesErrorMessage,
  isCampaignFrequencyWeeksValid, displayType
}) => {
  const campaignFrequency = useSelector((state) => state.campaignFrequency)

  function renderNextFireTimes () {
    const today = DateTime.now().setZone(tz)

    if (nextFireTimes) {
      const content = []

      if (firstFireDate && firstFireDate > today) {
        content.push(
          <span
            id={`${displayType}-first-fire-time`}
            style={{ padding: '3px 0' }}
            key={firstFireDate?.toString()}
          >
            {firstFireDate?.toFormat('cccc, LLLL d, yyyy \'at\' t')} ({tz})
          </span>
        )
      }

      for (const nextFireTime of nextFireTimes) {
        content.push(
          <span
            style={{ padding: '3px 0' }}
            key={nextFireTime?.toString()}
          >
            {nextFireTime?.toFormat('cccc, LLLL d, yyyy \'at\' t')} ({tz})
          </span>
        )
      }

      return content
    }
  }

  return (
    <>
      {nextFireTimesErrorMessage && (
        <div className='phin-error-text'>
          Your campaign schedule is malformed: {nextFireTimesErrorMessage}
        </div>
      )}

      {!isCampaignFrequencyWeeksValid && (
        <div className='phin-error-text'>
          Your campaign schedule is malformed: Please select two weeks of the month to send content or change the frequency.
        </div>
      )}

      <div className='h4 margin-bottom:3'>Send Preview</div>
      <Grid container direction='row' columnSpacing={4}>
        <Grid item>
          <div className='h5 margin-bottom:1'>Next Fire Times</div>
          {(!nextFireTimesErrorMessage &&
          (isCampaignFrequencyWeeksValid || campaignFrequency.iterations === campaignFrequencyIterations.ALL_AT_ONCE) &&
          nextFireTimes) && (
            <div className='p-3 d-flex flex-column border rounded' style={{ maxHeight: '20em', overflow: 'scroll' }}>
              {renderNextFireTimes()}
            </div>
          )}
        </Grid>
        <Grid item>
          <div className='h5 margin-bottom:1 padding-left:1'>Calendar Preview</div>
          <Grid container alignItems='start' justifyContent='start' sx={{ paddingBottom: 'var(--phin-s0)' }}>
            <Grid item>
              <CampaignScheduleCalendar
                firstFireDate={firstFireDate}
                nextFireTimes={nextFireTimes}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </>
  )
}

export default CampaignScheduleDisplay
