import { createSlice } from '@reduxjs/toolkit'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'

export const stagedUsersSlice = createSlice({
  name: 'stagedUsers',
  initialState: {
    loaders: {
      loadingStagedUsers: false
    },
    stagedUsers: null
  },
  reducers: {
    loadingStagedUsersUpdated: (state, action) => {
      state.loaders.loadingStagedUsers = action.payload
    },
    getStagedUsers: (state, action) => {
      state.stagedUsers = action.payload
    },
    deleteStagedUsers: (state) => {
      state.stagedUsers = null
    },
    updateStagedUserStatus: (state, action) => {
      const updatedStagedUsers = state.stagedUsers

      for (const updatedStagedUser of updatedStagedUsers) {
        if (updatedStagedUser.id === action.payload.stagedUserId) {
          updatedStagedUser.status = action.payload.status
        }
      }
    }
  }
})

export const {
  loadingStagedUsersUpdated: loadingStagedUsersUpdatedAction,
  getStagedUsers: getStagedUsersAction,
  deleteStagedUsers: deleteStagedUsersAction,
  updateStagedUserStatus: updateStagedUserStatusAction
} = stagedUsersSlice.actions

export default stagedUsersSlice.reducer

export const getStagedUsersByCompanyThunk = ({ companyId }) => {
  return async (dispatch, getState, api) => {
    dispatch(loadingStagedUsersUpdatedAction(true))

    try {
      const response = await api.get(`/api/companies/${companyId}/stagedUsers`)
      if (response.status === 200) {
        const { stagedUsers } = await response.json()
        dispatch(getStagedUsersAction(stagedUsers))
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to get stagedUsers.')
        }, 0)
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to get stagedUsers.')
      }, 0)
    }

    dispatch(loadingStagedUsersUpdatedAction(false))
  }
}

export const updateStagedUserStatusThunk = ({ companyId, stagedUserId, updatedFields }) => {
  return async (dispatch, getState, api) => {
    try {
      const response = await api.put(`/api/companies/${companyId}/stagedUsers/${stagedUserId}/ignore`, updatedFields)

      if (response.status === 200) {
        const { status } = await response.json()
        dispatch(updateStagedUserStatusAction({ stagedUserId, status }))
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to update stagedUser.')
        }, 0)
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to update stagedUser.')
      }, 0)
    }
  }
}
