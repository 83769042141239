import React, { useEffect } from 'react'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import PhishingAnalyticsPage from '../pages/components/analytics/phishingAnalytics/PhishingAnalytics'
import TrainingAnalyticsPage from '../pages/components/analytics/trainingAnalytics/TrainingAnalytics'
import PoliciesAnalyticsPage from '../pages/policiesAnalytics'
import SettingsPage from '../pages/Settings'
import UsersPage from '../pages/UsersPage'
import UsersImport from '../pages/UsersImport'
import TemplatesPage from '../pages/templates'
import OutgoingMessagesPage from '../pages/OutgoingMessagesPage'
import TrainingPage from '../pages/TrainingActive'
import TrainingViewer from '../pages/TrainingViewer'
import CatalogPage from '../pages/trainingCatalog'
import TrainingCreator from '../pages/trainingCreator'
import PhishingDashboard from '../pages/PhishingDashboard'
import CampaignEditor from '../pages/CampaignEditor'
import CampaignCreator from '../pages/CampaignCreator'
import CampaignLauncher from '../pages/CampaignLauncher'
import CampaignPresetLibrary from '../pages/CampaignPresetLibrary'
import TrainingCurriculumEditor from '../pages/TrainingCurriculumEditor'
import ClientAutoConfig from '../pages/ClientAutoConfig'
import CompanyBrandingPage from '../pages/CompanyBrandingPage'
import UserAnalyticsPage from '../pages/components/analytics/UserAnalyticsPage'
import { navigationRoutes, orgTypes, PAX8_ACCOUNT_STATUSES } from '../frontendConsts.js'
import { IntegrationsPage } from '../pages/components/integrations/IntegrationsPage'
import { MicrosoftUserSyncPage } from '../pages/components/integrations/MicrosoftUserSyncPage'
import CatalogPolicyPage from '../pages/policyCatalog'
import ActivePolicyPage from '../pages/ActivePolicyPage'
import CompanyHome from '../pages/CompanyHome'
import { useAuth0 } from '@auth0/auth0-react'
import UploadPolicy from '../pages/policies/UploadPolicy'
import DistributePolicy from '../pages/policies/DistributePolicy'
import EditUser from '../pages/EditUser'
import { MicrosoftEmailBypassPage } from '../pages/components/integrations/MicrosoftEmailBypassPage'
import { useDispatch, useSelector } from 'react-redux'
import { getCompanyThunk, getOnboardingChecklistDataThunk } from '../store/CompanySlice'
import { getCompanyIntegrationsThunk } from '../store/IntegrationsSlice'
import { getGroupsThunk, getUsersMapThunk, getUsersThunk } from '../store/UsersSlice'
import { getActiveCampaignsThunk, getPastCampaignsThunk, getPhishingCampaignPresetsThunk } from '../store/CampaignsSlice'
import { getTemplateCategoriesThunk, getTemplatesThunk, getSendingDomainsThunk } from '../store/TemplatesSlice'
import { getTrainingsThunk } from '../store/TrainingSlice'
import { getLogoLocationThunk } from '../store/LogoSlice'
import { getPoliciesThunk } from '../store/PoliciesSlice'
import { getBrandingSettingsThunk } from '../store/BrandingSlice'
import { getCompanyReportingSettingsThunk } from '../store/ReportingSettingsSlice'
import ReportingPage from '../pages/ReportingPage'
import { PhishingViewer } from '../pages/phishingPages/PhishingViewer'
import { setSideNavOrgDataAction } from '../store/SideNavSlice.js'
import { setTopNavCompanyDataAction } from '../store/TopNavSlice.js'
import { smartRoute } from '../utils/RouterUtils.js'
import { ReportAPhishIntegrationPage } from '../pages/components/integrations/MicrosoftReportAPhishPage'
import { AutomatedAllowlistPage } from '../pages/components/integrations/allowlist/AutomatedAllowlistPage'
import { GoogleUserSync } from '../pages/components/integrations/GoogleUserSync.js'

export function CompanyRouter ({ id }) {
  const { company } = useSelector((state) => state.company)
  const { partner } = useSelector((state) => state.partner)
  const { logoUrl } = useSelector((state) => state.logo)
  const { authorization } = useSelector((state) => state.auth)

  const history = useHistory()
  const dispatch = useDispatch()
  const { path } = useRouteMatch()
  const { logout } = useAuth0()

  useEffect(() => {
    async function setupCompany () {
      try {
        const fetchedCompany = await dispatch(getCompanyThunk(id))

        const { name, partnerName, partnerId, distributorName, distributorId, isInternal } = fetchedCompany
        dispatch(setTopNavCompanyDataAction({ name, id, partnerName, partnerId, distributorName, distributorId, isInternal }))
        dispatch(getLogoLocationThunk({ companyId: id, partnerId: fetchedCompany.partnerId }))
        dispatch(getCompanyIntegrationsThunk(id))
        dispatch(getUsersThunk(id))
        dispatch(getUsersMapThunk(id))
        dispatch(getPastCampaignsThunk(id))
        dispatch(getActiveCampaignsThunk(id))
        dispatch(getTrainingsThunk(id))
        dispatch(getGroupsThunk(id))
        dispatch(getPoliciesThunk({ id }))
        dispatch(getPhishingCampaignPresetsThunk(id))
        dispatch(getTemplateCategoriesThunk(id))
        dispatch(getTemplatesThunk(id))
        dispatch(getSendingDomainsThunk(id))
        dispatch(getOnboardingChecklistDataThunk(id))
        dispatch(getBrandingSettingsThunk(id, fetchedCompany.partnerId))
        dispatch(getCompanyReportingSettingsThunk(id))
      } catch (error) {
        console.error(error)
        smartRoute({ authorization, history, logout })
      }
    }

    if (id && company?.id !== id) {
      setupCompany()
    } else if (company) {
      const { name, id, isFreeTrial, partnerName, partnerId, distributorName, distributorId, isInternal } = company
      dispatch(setSideNavOrgDataAction({ name, id, isFreeTrial, orgType: orgTypes.COMPANY }))
      dispatch(setTopNavCompanyDataAction({ name, id, partnerName, partnerId, distributorName, distributorId, isInternal }))

      if (!logoUrl || (logoUrl && !logoUrl.includes(id))) {
        dispatch(getLogoLocationThunk({ partnerId: company.partnerId, companyId: id }))
      }
    }
  }, [])

  return (
    <div style={(company?.freeTrial || partner?.pax8AccountStatus === PAX8_ACCOUNT_STATUSES.TRIALING || partner?.pax8AccountStatus === PAX8_ACCOUNT_STATUSES.AWAITING_FREE_TRIAL_START)
      ? {
          margin: '8rem 3% 2rem',
          height: '82vh'
        }
      : {
          margin: '5rem 3% 2rem',
          height: '88vh'
        }}
    >
      <Switch>
        <Route
          exact
          path={`${path}/analytics/phishing`}
          render={() => (
            <PhishingAnalyticsPage
              id={id}
            />
          )}
        />

        <Route
          exact
          path={`${path}/reports`}
          render={() => (
            <ReportingPage
              companyId={id}
            />
          )}
        />

        <Route
          exact
          path={`${path}${navigationRoutes.ANALYTICS_TRAINING}`}
          render={() => (
            <TrainingAnalyticsPage
              companyId={id}
            />
          )}
        />

        <Route
          exact
          path={`${path}${navigationRoutes.ANALYTICS_USERS}/:userId`}
          render={() => (
            <UserAnalyticsPage
              companyId={id}
            />
          )}
        />

        <Route
          exact
          path={`${path}/analytics/policies`}
          render={() => (
            <PoliciesAnalyticsPage
              companyId={id}
            />
          )}
        />

        <Route
          exact
          path={`${path}${navigationRoutes.CAMPAIGNPRESETS}`}
          render={() => (
            <CampaignPresetLibrary
              companyId={id}
            />
          )}
        />

        <Route
          exact
          path={`${path}${navigationRoutes.CAMPAIGNCREATOR}`}
          render={() => (
            <CampaignCreator
              companyId={id}
            />
          )}
        />

        <Route
          exact path={`${path}${navigationRoutes.CAMPAIGNEDITOR}`}
          render={() => (
            <CampaignEditor
              companyId={id}
            />
          )}
        />

        <Route
          exact
          path={`${path}${navigationRoutes.CAMPAIGNLAUNCHER}`}
          render={() => (
            <CampaignLauncher
              companyId={id}
            />
          )}
        />

        <Route
          exact
          path={`${path}/edit-user`}
          render={() => (
            <EditUser
              id={id}
            />
          )}
        />

        <Route
          exact
          path={`${path}${navigationRoutes.CAMPAIGNS}`}
          render={() => (
            <PhishingDashboard
              companyId={id}
            />
          )}
        />

        <Route
          exact
          path={`${path}/training`}
          render={() => (
            <TrainingPage
              id={id}
            />
          )}
        />

        <Route
          exact
          path={`${path}${navigationRoutes.TRAINING_VIEWER}/:campaignId`}
          render={() => (
            <TrainingViewer
              id={id}
            />
          )}
        />

        <Route
          exact
          path={`${path}${navigationRoutes.PHISHING_VIEWER}/:campaignId`}
          render={() => (
            <PhishingViewer
              id={id}
            />
          )}
        />

        <Route
          exact
          path={`${path}/messages`}
          render={() => (
            <OutgoingMessagesPage
              id={id}
            />
          )}
        />

        <Route
          exact
          path={`${path}${navigationRoutes.TRAININGCREATOR}`}
          render={() => (
            <TrainingCreator
              companyId={id}
            />
          )}
        />

        <Route
          exact
          path={`${path}${navigationRoutes.TRAINING_CURRICULUM_FORM}/:campaignId`}
          render={() => (
            <TrainingCurriculumEditor
              companyId={id}
            />
          )}
        />

        <Route
          exact
          path={`${path}/config-wizard/`}
          render={() => (
            <ClientAutoConfig
              companyId={id}
            />
          )}
        />

        <Route
          exact
          path={`${path}/training/catalog`}
          render={() => (
            <CatalogPage
              companyId={id}
            />
          )}
        />

        <Route
          exact
          path={`${path}/policy/catalog`}
          render={() => (
            <CatalogPolicyPage
              id={id}
            />
          )}
        />

        <Route
          exact
          path={`${path}/policy/upload`}
          render={() => (
            <UploadPolicy
              id={id}
            />
          )}
        />

        <Route
          exact
          path={`${path}/policy/distribute/:policyId`}
          render={() => (
            <DistributePolicy
              id={id}
            />
          )}
        />

        <Route
          exact
          path={`${path}/policy/active`}
          render={() => (
            <ActivePolicyPage
              companyId={id}
            />
          )}
        />

        <Route
          exact
          path={`${path}${navigationRoutes.USERS}`}
          render={() => (
            <UsersPage
              history={history}
              id={id}
            />
          )}
        />

        <Route
          exact
          path={`${path}${navigationRoutes.USERS_UPLOAD}`}
          render={() => (
            <UsersImport
              id={id}
            />
          )}
        />

        <Route
          exact
          path={`${path}`}
          render={() => (
            <CompanyHome
              id={id}
            />
          )}
        />

        <Route
          path={`${path}/templates`}
          render={() => (
            <TemplatesPage
              id={id}
            />
          )}
        />

        <Route
          path={`${path}/integrations/userSync`}
          render={() => (
            <MicrosoftUserSyncPage
              id={id}
            />
          )}
        />

        <Route
          path={`${path}/integrations/spamFilterBypass`}
          render={() => (
            <MicrosoftEmailBypassPage
              id={id}
            />
          )}
        />

        <Route
          path={`${path}/integrations/reportAPhish`}
          render={() => (
            <ReportAPhishIntegrationPage
              id={id}
            />
          )}
        />

        <Route
          path={`${path}/integrations/allowlist`}
          render={() => (
            <AutomatedAllowlistPage
              id={id}
            />
          )}
        />

        <Route
          path={`${path}/integrations/googleUserSync`}
          render={() => (
            <GoogleUserSync
              id={id}
            />
          )}
        />

        <Route
          path={`${path}/integrations`}
          render={() => (
            <IntegrationsPage
              id={id}
            />
          )}
        />
        <Route
          exact
          path={`${path}/branding`}
          render={() => (
            <CompanyBrandingPage
              id={id}
            />
          )}
        />

        <Route
          path={`${path}/settings`}
          render={(props) => (
            <SettingsPage
              id={id}
              history={history}
              props={props}
            />
          )}
        />
      </Switch>
    </div>
  )
}
