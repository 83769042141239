import { MDBIcon } from 'mdb-react-ui-kit'
import { orgLevels } from '../../../frontendConsts.js'
import { Tooltip } from '@mui/material'

function WelcomeEmailInheritanceBadges ({ welcomeEmailOrgLevel, orgLevel }) {
  return (
    <div className='inheritanceBadgeGroup'>
      <Tooltip
        placement='top'
        title='Default Template'
      >
        <div className={welcomeEmailOrgLevel === orgLevels.DEFAULT ? 'phinBadge activeBadge' : 'phinBadge'}>
          <MDBIcon className='badgeIcon' fas icon='globe' />
        </div>
      </Tooltip>
      <Tooltip placement='top' title='Partner Template'>
        <div className={welcomeEmailOrgLevel === orgLevels.PARTNER ? 'phinBadge activeBadge' : 'phinBadge'}>
          <MDBIcon className='badgeIcon' far icon='handshake' />
        </div>
      </Tooltip>
      {orgLevel === orgLevels.COMPANY &&
        <Tooltip
          placement='top'
          title='Company Template'
        >
          <div className={welcomeEmailOrgLevel === orgLevels.COMPANY ? 'phinBadge activeBadge' : 'phinBadge'}>
            <MDBIcon className='badgeIcon' far icon='building' />
          </div>
        </Tooltip>}
    </div>
  )
}

export default WelcomeEmailInheritanceBadges
