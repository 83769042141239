import React from 'react'
import '../../css/integrationsDetail.css'
import '../../css/integrationCard.css'
import { Button } from '@mui/material'

export function MicrosoftConsentCard ({ consentFunction, isAzureGovTenant, toggleIsAzureGovTenant }) {
  return (
    <div className='consentCard'>
      <div className='consentTitle'>You will need to grant Microsoft consent before we can start the initial sync.</div>
      <div className='permissions'>
        <p className='consentSubtitle'>What are the permissions?</p>
        <ul>
          <li>
            User.Read.All
            <ul>
              <li>
                used to fetch all of the microsoft azure users to be imported into Phin
              </li>
            </ul>
          </li>
        </ul>
        <ul>
          <li>
            GroupMember.Read.All
            <ul>
              <li>
                used to fetch all of the microsoft azure groups to be imported into Phin
              </li>
            </ul>
          </li>
        </ul>
        <ul>
          <li>
            Sign in and read user profile
            <ul>
              <li>
                allows Phin to make API calls to a Microsoft Azure tenant
              </li>
            </ul>
          </li>
        </ul>
        <p className='consentSubtitle'>How will Phin use the permissions?</p>
        <ul>
          <li>
            Phin will use the above listed permissions to automatically import and update your clients users and groups.
          </li>
        </ul>
      </div>
      <div>
        <p className='consentSubtitle'>Azure Tenant Type</p>
        <div className='azureTypeToggle'>
          <span
            className={isAzureGovTenant ? 'margin-right:3' : 'toggleSelected  margin-right:3'}
          >Azure AD
          </span>
          <label className='switch branding-switch user-collapse'>
            <input type='checkbox' checked={isAzureGovTenant} onChange={toggleIsAzureGovTenant} />
            <span className='slider round' />
          </label>
          <span className={isAzureGovTenant ? 'toggleSelected margin-left:3' : 'margin-left:3'}>Azure AD for US Government</span>
        </div>
      </div>
      <div className='d-flex justify-content-center margin-top:1'>
        <Button
          id='continue-to-microsoft-button'
          aria-label='Continue to Microsoft Button'
          variant='contained'
          color='primary'
          className='consentBtn'
          onClick={() => consentFunction()}
        >Continue to Microsoft
        </Button>
      </div>
    </div>
  )
}
