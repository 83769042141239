import { orgTypes, PAX8_ACCOUNT_STATUSES } from '../../frontendConsts'
import CompanyNavRoutes from './sideNav/CompanyNavRoutes'
import PartnerNavRoutes from './sideNav/PartnerNavRoutes'
import DistributorNavRoutes from './sideNav/DistributorNavRoutes'
import { useSelector } from 'react-redux'
import { Divider, Grid, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Skeleton, styled } from '@mui/material'
import MuiDrawer from '@mui/material/Drawer'
import { SideNavItem } from './sideNav/SideNavItem'
import HelpIcon from '@mui/icons-material/Help'
import { useRef, useState } from 'react'
import { IoChatboxEllipsesOutline, IoHelpCircleOutline, IoLogoYoutube } from 'react-icons/io5'

const drawerWidth = 240

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
)

function SideNav ({ id }) {
  const {
    isSideNavOpen,
    sideNavOrgId,
    sideNavOrgType
  } = useSelector((state) => state.sideNav)

  const { logoUrl } = useSelector((state) => state.logo)
  const { partner, loaders: partnerLoaders } = useSelector((state) => state.partner)
  const { isLoadingPartner } = partnerLoaders
  const { company, loaders: companyLoaders } = useSelector((state) => state.company)
  const { isLoadingCompany } = companyLoaders
  const [resourcesPopoverEl, setResourcesPopoverEl] = useState(null)
  const drawerRef = useRef()

  function renderRoutes () {
    switch (sideNavOrgType) {
      case orgTypes.COMPANY:
        return <CompanyNavRoutes id={sideNavOrgId || id} isSideNavOpen={isSideNavOpen} />
      case orgTypes.PARTNER:
        return <PartnerNavRoutes id={sideNavOrgId || id} isSideNavOpen={isSideNavOpen} />
      case orgTypes.DISTRIBUTOR:
        return <DistributorNavRoutes id={sideNavOrgId || id} isSideNavOpen={isSideNavOpen} />
      default:
        return <></>
    }
  }

  const sideNavHelpRowRect = resourcesPopoverEl?.getBoundingClientRect()
  const drawerRect = drawerRef.current?.getBoundingClientRect()

  const applyNoticeBannerStyles = company?.freeTrial ||
    partner?.freeTrial ||
    partner?.pax8AccountStatus === PAX8_ACCOUNT_STATUSES.TRIALING ||
    partner?.pax8AccountStatus === PAX8_ACCOUNT_STATUSES.AWAITING_FREE_TRIAL_START ||
    partner?.suspensionStartTimestamp

  return (
    <>
      <Menu
        open={!!resourcesPopoverEl}
        anchorReference='anchorPosition'
        anchorPosition={{ top: sideNavHelpRowRect?.y, left: drawerRect?.x + drawerRect?.width }}
        onClose={() => setResourcesPopoverEl(null)}
      >
        <MenuList>
          <MenuItem
            onClick={() => window.open('https://www.phinsec.io/knowledge')}
          >
            <ListItemIcon>
              <IoHelpCircleOutline size='1.5em' />
            </ListItemIcon>
            <ListItemText>Help & Documentation
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => window.open('https://www.phinsec.io/knowledge/kb-tickets/new')}
          >
            <ListItemIcon>
              <IoChatboxEllipsesOutline size='1.5em' />
            </ListItemIcon>
            <ListItemText>Submit a Ticket</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => window.open('https://www.youtube.com/channel/UCg3LU45c_egmUZYO43OHMdQ/videos')}
          >
            <ListItemIcon>
              <IoLogoYoutube size='1.5em' />
            </ListItemIcon>
            <ListItemText>YouTube</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
      <Drawer
        variant='permanent'
        open={isSideNavOpen}
        anchor='left'
        ref={drawerRef}
        sx={{ zIndex: '800 !important' }}
        PaperProps={
          (applyNoticeBannerStyles)
            ? {
                sx: {
                  backgroundColor: 'var(--phin-blue)',
                  color: 'var(--phin-white)',
                  marginTop: '106px'
                }
              }
            : {
                sx: {
                  backgroundColor: 'var(--phin-blue)',
                  color: 'var(--phin-white)',
                  marginTop: '58px'
                }
              }
        }
      >
        <Divider />

        {(isLoadingCompany || isLoadingPartner) && (
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            wrap='nowrap'
            sx={{ padding: '0.25rem', marginTop: '0.75rem', marginBottom: '0.75rem' }}
          >
            <Skeleton variant='rectangular' width={239} height={58.75} />
          </Grid>
        )}

        {(!isLoadingCompany && !isLoadingPartner) && (
          <>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              sx={{ padding: '0.25rem', marginTop: '0.75rem', marginBottom: '0.75rem' }}
            >
              <Grid item>
                <img
                  alt=''
                  src={logoUrl}
                  width='40rem'
                />
              </Grid>
            </Grid>

          </>
        )}

        <Divider />

        <Grid container sx={{ height: '100%' }} direction='column' xs={12} justifyContent='space-between'>

          <Grid item xs={10}>
            {renderRoutes()}
          </Grid>
          <Grid item xs={2}>
            <Divider />
            <div className='padding-bottom:-2' />
            <span
              onClick={(e) => { setResourcesPopoverEl(e.currentTarget) }}
            >
              <SideNavItem
                text='Help & Support'
                isSideNavOpen={isSideNavOpen}
                icon={<HelpIcon style={{ color: 'var(--phin-white)' }} size='1.5em' />}
              />
            </span>
          </Grid>
        </Grid>
      </Drawer>
    </>
  )
}

export default SideNav
