import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { integrationUpdatedAction } from '../../../store/IntegrationsSlice'
import { apiRequestUtils } from '../../../utils/apiRequestUtils'
import NotificationUtilities from '../notifications/notificationUtils'
import '../../css/integrationsDetail.css'
import '../../css/integrationCard.css'
import { getGroupsThunk, getUsersThunk } from '../../../store/UsersSlice'
import { UserSyncTable } from './UserSyncTable'
import { integrationTypes } from '../../../frontendConsts.js'

export function SyncedUsers ({ companyId, integration }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const intervalRef = useRef()

  const pollForSyncStatus = async () => {
    const response = await apiRequestUtils.get(`/api/companies/${companyId}/integrations/userSync`)
    if (response.status === 200) {
      const integration = await response.json()
      if (!integration.isSyncing) {
        dispatch(integrationUpdatedAction({ integrationId: integrationTypes.USER_SYNC, updatedFields: integration }))
      } else {
        intervalRef.current = setInterval(async () => {
          const response = await apiRequestUtils.get(`/api/companies/${companyId}/integrations/userSync`)
          if (response.status === 200) {
            const integration = await response.json()
            if (!integration.isSyncing) {
              clearInterval(intervalRef.current)

              dispatch(integrationUpdatedAction({ integrationId: integrationTypes.USER_SYNC, updatedFields: integration }))
              dispatch(getUsersThunk(companyId))
              dispatch(getGroupsThunk(companyId))
            }
          } else {
            clearInterval(intervalRef.current)
            setTimeout(() => {
              NotificationUtilities.sendErrorMessage('Failed to load User Sync data. Please try again or contact Phin Support for assistance.')
            }, 0)
          }
        }, 10000)
      }
    } else {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to load User Sync data. Please try again or contact Phin Support for assistance.')
      }, 0)
      history.push(`/companies/${companyId}/integrations`)
    }
  }

  useEffect(() => {
    if (integration && integration.isSyncing) {
      pollForSyncStatus()

      return function cleanUpInterval () {
        clearInterval(intervalRef.current)
      }
    }
  }, [integration])

  return (
    <>
      <UserSyncTable
        companyId={companyId}
        haveConsented
      />
    </>
  )
}
