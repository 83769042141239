import '../../css/TestWelcomeEmailModal.css'
import { TextField } from '@mui/material'

export default function TestWelcomeEmailModal ({
  testEmail, setTestEmail,
  useSpamFilterBypass, setUseSpamFilterBypass,
  hasSpamFilterBypass
}) {
  return (
    <div>
      <div className='sidePaddingOneEm'>
        <p>
          Schedule a test welcome email to the address below using the latest saved template.
        </p>
        <div>
          <TextField
            fullWidth
            value={testEmail}
            label='Email Address'
            onChange={e => { setTestEmail(e.target.value) }}
          />
        </div>
        {hasSpamFilterBypass &&
          <div className='margin-top:-2 '>
            <div className='spamFilterBypassToggle'>
              <div className='margin-right:-2 '>
                Use Graph API Mail Delivery:
              </div>
              <div>
                <label className='switch branding-switch user-collapse'>
                  <input type='checkbox' checked={useSpamFilterBypass} onChange={() => setUseSpamFilterBypass(!useSpamFilterBypass)} />
                  <span className='slider round slider-preview' />
                </label>
              </div>
            </div>
            {useSpamFilterBypass &&
              <p className='helperText'>If using Graph API Mail Delivery send, please ensure the email address belongs to a user in this company's Azure tenant.</p>}
          </div>}
      </div>
    </div>
  )
}
