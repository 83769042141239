import { Step, StepConnector, StepLabel, Stepper } from '@mui/material'
import './sharedStepper.scss'

export const SharedStepper = ({
  activeTab, setActiveTab, steps, linear
}) => {
  // steps takes in an array of objects like [{ icon: IconComponentReference, label: 'string', tab: 'string'}]
  return (
    <>
      {linear && (
        <Stepper className='shared-stepper-container' alternativeLabel connector={<StepConnector />}>
          {steps.map((step, index) => (
            <Step key={index} disabled>
              {activeTab === step.tab && (
                <StepLabel
                  className='active-tab'
                  StepIconComponent={step.icon}
                >{step.label}
                </StepLabel>
              )}
              {activeTab !== step.tab && (
                <StepLabel
                  className='disabled-tab'
                  StepIconComponent={step.icon}
                >{step.label}
                </StepLabel>
              )}
            </Step>
          ))}
        </Stepper>
      )}
      {!linear && (
        <Stepper className='shared-stepper-container' alternativeLabel connector={<StepConnector />}>
          {steps.map((step, index) => (
            <Step key={index} onClick={() => setActiveTab(step.tab)}>
              <StepLabel
                className={activeTab === step.tab && 'active-tab'}
                StepIconComponent={step.icon}
              >{step.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      )}

    </>
  )
}
