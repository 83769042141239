import React from 'react'
import { useHistory } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'

const Auth0ProviderWithHistory = ({ children }) => {
  const history = useHistory()

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_CUSTOM_DOMAIN}
      clientId={process.env.REACT_APP_AUTH_SPA_CLIENT_ID}
      audience={process.env.REACT_APP_AUTH_AUDIENCE}
      redirectUri={`${window.location.origin}/callback`}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory
