import React, { useState } from 'react'
import ViewerModal from '../ViewerModal'

import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Box, IconButton, Tooltip } from '@mui/material'
import { IoEye } from 'react-icons/io5'
import PhinModal from '../PhinModal'

export function TemplatesPreviewer ({ templates, isOpen, toggleModal }) {
  const [modalViewer, setModalViewer] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState({})

  const fixedLengthCampaignColumns = [
    {
      headerName: 'Template Name',
      field: 'name',
      flex: 2
    },
    {
      headerName: 'View Template',
      field: 'options',
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip
            title='View Template' placement='top'
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -14]
                    }
                  }
                ]
              }
            }}
          >
            <IconButton
              id={`view-${params.row.id}-template-button`}
              aria-label={`View ${params.row.name} Template Button`}
              color='primary'
              onClick={() => { previewTemplates(params.row) }}
            >
              <IoEye />
            </IconButton>
          </Tooltip>
        )
      }
    }
  ]

  const previewTemplates = (template) => {
    setModalViewer(true)
    setSelectedTemplate(template)
  }

  const closeViewerModal = () => {
    setModalViewer(false)
    setSelectedTemplate({})
  }

  return (
    <PhinModal
      className='p-1'
      title='Category Templates'
      size='medium'
      isOpen={isOpen}
      close={toggleModal}
      noButtons
    >
      <ViewerModal title='Templates' isOpen={modalViewer} toggleViewerModal={closeViewerModal} template={selectedTemplate} />
      <Box sx={{ height: 600, width: '100%' }}>
        <DataGrid
          style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
          className='DataGrid'
          rows={templates}
          columns={fixedLengthCampaignColumns}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 }
            }
          }}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          pageSize={10}
          pagination
        />
      </Box>
    </PhinModal>
  )
}

export default TemplatesPreviewer
