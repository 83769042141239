import { Alert } from '@mui/material'
import { IoCheckmarkCircle, IoHelpCircle, IoWarning } from 'react-icons/io5'

export const StaticAlert = ({ severity, color, text, variant }) => {
  return (
    <Alert severity={severity} variant={variant} color={color} icon={false}>
      <div className='phin-flex-space-around-row' style={{ width: '100%' }}>
        <div className='margin-left:1 margin-right:1 '>
          {severity === 'info' && (
            <IoHelpCircle size='4.5em' />
          )}
          {severity === 'warning' && (
            <IoWarning size='4.5em' />
          )}
          {severity === 'success' && (
            <IoCheckmarkCircle size='4.5em' />
          )}
        </div>
        <div className='phin-h6'>{text}</div>
      </div>
    </Alert>
  )
}
