import React, { useEffect, useState } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import NotificationUtilities from './components/notifications/notificationUtils'
import { useSelector, useDispatch } from 'react-redux'
import { enrollUsersInOnboardingCampaignThunk, getActiveCampaignsThunk, getCampaignAudienceThunk, getCampaignCoursesWithTotalEnrolledThunk, getNextCampaignScheduleThunk } from '../store/CampaignsSlice'
import { capitalizeFirstLetter } from '../utils/FormattingUtils'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { IoArrowBackOutline } from 'react-icons/io5'
import { Button, Box, Typography } from '@mui/material'
import { campaignTypes, navigationRoutes, STYLE } from '../frontendConsts.js'
import { sanitizeCampaignFrequency } from '../utils/dateUtils'
import { DateTime } from 'luxon'
import Spinner from './components/Spinner'
import { CoursePreviewModal } from '../shared/components/CoursePreviewModal'
import { getCourseCatalogThunk } from '../store/TrainingSlice'
import { AudienceDetailsTable } from './components/audience/AudienceDetailsTable'
import { CampaignSchedulePreviewer } from './components/Campaigns/CampaignSchedulePreviewer'
import { isUserSelecting } from '../utils/userInterfaceUtils'
import { AddOnboardingUsersTable } from './components/Campaigns/AddOnboardingUsersTable.js'
import PhinModal from './components/PhinModal.js'

function TrainingViewer ({ id: companyId }) {
  const history = useHistory()

  const [campaign, setCampaign] = useState(null)
  const [formattedCampaignCourses, setFormattedCampaignCourses] = useState([])
  const [futureCourses, setFutureCourses] = useState([])
  const { campaignId } = useParams()
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [previewModalHeader, setPreviewModalHeader] = useState('')
  const [course, setCourse] = useState(null)

  const [addUserModalOpen, setAddUserModalOpen] = useState(false)
  const handleOpenAddUserModal = () => setAddUserModalOpen(true)
  const handleCloseUserModal = () => setAddUserModalOpen(false)
  const [fixedAudienceList, setFixedAudienceList] = useState([])
  const [initialFixedAudienceList, setInitialFixedAudienceList] = useState([])

  const newChanges = fixedAudienceList.filter((user) => !initialFixedAudienceList.map((user) => user.id).includes(user.id))

  const submitAddUsersToOnboarding = async () => {
    const success = await dispatch(enrollUsersInOnboardingCampaignThunk({
      companyId: company.id,
      campaignId: campaign.id,
      userIdsList: newChanges.map(user => user.id),
      usersList: newChanges
    }))

    if (success) {
      handleCloseUserModal()
    }
  }

  const activeCampaigns = useSelector((state) => state.campaigns.activeCampaigns)
  const audience = useSelector((state) => state.campaigns.campaignAudience)
  const campaignCourses = useSelector((state) => state.campaigns.campaignCourses)
  const company = useSelector((state) => state.company.company)
  const { courseCatalog, loaders: trainingLoaders } = useSelector((state) => state.trainings)
  const { isLoadingCourseCatalog } = trainingLoaders
  const isLoadingCampaignCourses = useSelector((state) => state.campaigns.loaders.isLoadingCampaignCourses)
  const isLoadingAudience = useSelector((state) => state.campaigns.loaders.isLoadingCampaignAudience)
  const campaignSchedule = campaign?.campaignFrequency

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getActiveCampaignsThunk(companyId))
    dispatch(getCampaignAudienceThunk({ companyId, campaignId }))
    dispatch(getCampaignCoursesWithTotalEnrolledThunk({ companyId, campaignId }))
    dispatch(getCourseCatalogThunk({ companyId }))
  }, [])

  useEffect(() => {
    if (activeCampaigns && courseCatalog && campaignCourses) {
      const tempActiveCampaign = activeCampaigns.find(campaign => campaign.id === campaignId)

      if (tempActiveCampaign) {
        setCampaign(tempActiveCampaign)

        // Add formatted courses for rows on the Launched Courses and Onboarding Courses tables
        const tempFormattedCampaignCourses = campaignCourses.courses.map((course) => {
          const defId = course.courseDefinitionId ? course.courseDefinitionId : course.courseId
          return { ...course, id: defId, courseDefinitionId: defId, type: course.type, indexPath: course.indexPath }
        })
        setFormattedCampaignCourses(tempFormattedCampaignCourses)

        // Pre-work to grab required fields for course preview from courseCatalog
        const coursesWithPreviewFields = tempActiveCampaign.curriculum.modules.map((module) => {
        // Grab the courseDefinitionId from each path by stripping out the 'course-definition/' string from the start
        // For custom courses, there will also be a 'clients/{companyId}... prefix that needs to be stripped out, so
        // we grab the last element of the split array.
          const courseDefinitionIdSplit = module.path.split('/')
          const courseDefinitionId = courseDefinitionIdSplit[courseDefinitionIdSplit.length - 1]

          // find the index of the course in courseCatalog using the courseDefintion id we stripped out
          const courseDefinition = courseCatalog.findIndex((courseDefinition) => courseDefinition.id === courseDefinitionId)
          // If we find the course, grab what we need to preview a course from the course catalog
          if (courseDefinition !== -1) {
            const { indexPath, type } = courseCatalog[courseDefinition]
            return { ...module, courseDefinitionId, indexPath, type }
          }
          // Course was not defined. Leave the indexPath and type as null and we will error handle this
          // in the renderCoursePreviewButton function.
          return { ...module, courseDefinitionId: null, indexPath: null, type: null }
        })

        // Fill the Future Courses table for fixed/continuous training campaigns
        if (tempActiveCampaign.campaignType === campaignTypes.TRAINING) {
          const tempFutureCourses = coursesWithPreviewFields.filter((module) => !module.moduleId)

          let startingDateTime = DateTime.fromISO(tempActiveCampaign.startTime)

          if (DateTime.now() > startingDateTime) {
          // This campaign is currently running, cap the startDate
            startingDateTime = DateTime.now().setZone(company.timezone.value)
          }

          const campaignFrequency = tempActiveCampaign.campaignFrequency

          const numberOfNextFireTimes = tempFutureCourses.length
          const frequency = sanitizeCampaignFrequency(campaignFrequency)

          dispatch(getNextCampaignScheduleThunk({ companyId, campaignFrequency: frequency, startDate: startingDateTime, count: numberOfNextFireTimes }))
            .then(({ nextFireDateTimes }) => {
              if (tempActiveCampaign.firstFireDate) {
                const firstFireDateTime = DateTime.fromISO(tempActiveCampaign.firstFireDate)

                if (firstFireDateTime > DateTime.now()) {
                  nextFireDateTimes.unshift(firstFireDateTime)
                }
              }

              try {
              // Each row in a datagrid is required to have an id
                const result = tempFutureCourses.map((module, index) => {
                  if (!module.id) {
                  // We need to grab the indexPath of each course to show the preview
                    return { ...module, id: module.path, scheduledDate: DateTime.fromISO(nextFireDateTimes[index]) }
                  } else {
                    return { ...module, scheduledDate: DateTime.fromISO(nextFireDateTimes[index]) }
                  }
                })
                setFutureCourses(result)
              } catch (error) {
                NotificationUtilities.sendErrorMessage('Failed to load future courses')
              }
            })
        }
      }
    }
  }, [activeCampaigns, campaignCourses, courseCatalog])

  function viewUserTrainingAnalytics (userId) {
    if (!isUserSelecting(window)) history.push(`/companies/${companyId}${navigationRoutes.ANALYTICS_USERS}/${userId}`, { previousPageTitle: 'Training Campaign Details' })
  }

  function togglePreviewModal (course, shouldShowPreviewModal) {
    setCourse(course)
    setPreviewModalHeader(course === null ? '' : course.name)
    setShowPreviewModal(shouldShowPreviewModal)
  }

  const handleCoursePreview = (course) => {
    if (course.courseDefinitionId) {
      if (!isUserSelecting(window)) togglePreviewModal(course, true)
    } else {
      NotificationUtilities.sendErrorMessage('Course Preview was not found in Course Catalog. Please contact Phin Support')
    }
  }

  const audienceColumns = [
    {
      field: 'first',
      headerName: 'First Name (Click for analytics)',
      flex: 0.8,
      renderCell: (params) =>
        <Box
          sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }} id={`view-${params.row.email}-analytics-button`}
        >
          <Typography sx={{ color: 'var(--phin-blue)', fontWeight: 'bold' }}>{params.row.first}</Typography>
        </Box>
    },
    { field: 'last', headerName: 'Last Name', flex: 0.8 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 0.5, valueGetter: ({ row }) => capitalizeFirstLetter(row.status) }
  ]

  const onboardingCourseColumns = [
    {
      field: 'name',
      headerName: 'Name (Click for preview)',
      flex: 1.5,
      renderCell: (params) =>
        <Box
          id={`preview-${params.row.name}-button`}
          sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
        >
          <Typography sx={{ color: 'var(--phin-blue)', fontWeight: 'bold' }}>{params.row.name}</Typography>
        </Box>
    },
    { field: 'dateStarted', headerName: 'Start Date', flex: 1, renderCell: (field) => { return new Date(field.value).toLocaleString() } },
    { field: 'totalEnrolled', headerName: 'Total Enrolled', flex: 1 },
    { field: 'totalCompleted', headerName: 'Total Completed', flex: 1 }
  ]
  const futureCourseColumns = [

    {
      field: 'name',
      headerName: 'Name (Click for preview)',
      flex: 1.5,
      renderCell: (params) =>
        <Box id={`preview-${params.row.name}-button`} sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
          <Typography sx={{ color: 'var(--phin-blue)', fontWeight: 'bold' }}>{params.row.name}</Typography>
        </Box>
    },
    { field: 'scheduledDate', headerName: 'Scheduled Assignment Date', flex: 1, renderCell: (field) => { return new Date(field.value).toLocaleString() } }
  ]

  const futureContinuousCampaignCourseColumns = [

    {
      field: 'name',
      headerName: 'Name (Click for preview)',
      flex: 1.5,
      renderCell: (params) =>
        <Box id={`preview-${params.row.name}-button`} sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
          <Typography sx={{ color: 'var(--phin-blue)', fontWeight: 'bold' }}>{params.row.name}</Typography>
        </Box>
    },
    { field: 'topic', headerName: 'Topic', flex: 1, renderCell: (field) => { return field.value || 'Custom' } },
    { field: 'scheduledDate', headerName: 'Scheduled Assignment Date', flex: 1, renderCell: (field) => { return new Date(field.value).toLocaleString() } }
  ]

  const pastCourseColumns = [

    {
      field: 'name',
      headerName: 'Name (Click for preview)',
      flex: 1.5,
      renderCell: (params) =>
        <Box id={`preview-${params.row.name}-button`} sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
          <Typography sx={{ color: 'var(--phin-blue)', fontWeight: 'bold' }}>{params.row.name}</Typography>
        </Box>
    },
    { field: 'topic', headerName: 'Topic', flex: 1, renderCell: (field) => { return field.value || 'Custom' } },
    { field: 'dateStarted', headerName: 'Date Assigned', flex: 1, renderCell: (field) => { return new Date(field.value).toLocaleString() } },
    { field: 'totalEnrolled', headerName: 'Total Enrolled', flex: 1 },
    { field: 'totalCompleted', headerName: 'Total Completed', flex: 1 }
  ]

  return (
    <>
      <PhinModal
        isOpen={addUserModalOpen}
        size='medium'
        title='Enroll Users'
        close={handleCloseUserModal}
        action={submitAddUsersToOnboarding}
        actionText='Enroll Users'
      >
        <AddOnboardingUsersTable
          fixedAudienceList={fixedAudienceList}
          initialFixedAudienceList={initialFixedAudienceList}
          setInitialFixedAudienceList={setInitialFixedAudienceList}
          setFixedAudienceList={setFixedAudienceList}
          companyId={companyId}
          campaignId={campaignId}
        />
      </PhinModal>

      <CoursePreviewModal
        companyId={companyId}
        courseDefinition={course}
        previewModalHeader={previewModalHeader}
        showPreviewModal={showPreviewModal}
        togglePreviewModal={togglePreviewModal}
      />
      {(isLoadingAudience || isLoadingCampaignCourses || isLoadingCourseCatalog) && (<Spinner message='Making everything look nice' />)}
      {(!isLoadingAudience && !isLoadingCampaignCourses && !isLoadingCourseCatalog) &&
        <div>

          <div className='padding-bottom:1'>
            <Button component={Link} sx={{ mb: 1 }} variant='text' style={STYLE.BUTTONS.TERTIARY} color='primary' size='small' to={`/companies/${companyId}/training`} onClick={() => null} startIcon={<IoArrowBackOutline />}>Return to Campaign Dashboard</Button>
          </div>
          <div className='d-flex flex-column'>
            <div style={{ alignSelf: 'left' }}>
              <h1 className='phin-h1'>{campaign?.name}</h1>
              <p>{campaign?.description}</p>
            </div>

            {(campaign && campaignSchedule && campaign.campaignType === campaignTypes.TRAINING) && (
              <CampaignSchedulePreviewer campaign={campaign} nextTraining={futureCourses[0]} />
            )}

            <AudienceDetailsTable
              audience={audience}
              audienceColumns={audienceColumns}
              rowClickAction={viewUserTrainingAnalytics}
              addUsersButton={
              campaign && campaign.campaignType === campaignTypes.ONBOARDING &&
                <Button
                  id='add-user-button'
                  aria-label='Add User Button'
                  variant='contained'
                  color='primary'
                  onClick={handleOpenAddUserModal}
                >
                  Add User
                </Button>
            }
            />
            {(campaign && campaign.campaignType === campaignTypes.TRAINING) &&
              <Box sx={{ paddingBottom: '2em', width: '100%', height: 'auto' }}>
                <h3 className='phin-h4' style={{ paddingBottom: '0.5em' }}>Launched Courses</h3>
                <DataGrid
                  onRowClick={(params) => handleCoursePreview(params.row)}
                  autoHeight
                  style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
                  className='DataGrid'
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'dateStarted', sort: 'asc' }]
                    }
                  }}
                  rows={formattedCampaignCourses || []}
                  columns={pastCourseColumns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  components={{ Toolbar: GridToolbar }}
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: {
                        debounceMs: 250,
                        id: 'launched-courses-table-search',
                        'aria-label': 'Search'
                      },
                      csvOptions: { disableToolbarButton: true },
                      printOptions: { disableToolbarButton: true }
                    }
                  }}
                  disableColumnSelector
                  disableDensitySelector
                  sx={{
                    borderRadius: '8px',
                    border: '2px solid var(--phin-light-gray)',
                    '.MuiDataGrid-cell:focus': {
                      outline: 'none'
                    },
                    '& .MuiDataGrid-row:hover': {
                      cursor: 'pointer'
                    }
                  }}
                />
              </Box>}
            {(campaign && campaign.campaignType === campaignTypes.TRAINING) && (
              <Box sx={{ paddingBottom: '2em', width: '100%', height: 'auto' }}>
                <h3 className='phin-h4' style={{ paddingBottom: '0.5em' }}>Future Courses</h3>
                <DataGrid
                  onRowClick={(params) => handleCoursePreview(params.row)}
                  autoHeight
                  style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
                  className='DataGrid'
                  rows={futureCourses || []}
                  columns={(campaign && campaign.isContinuous)
                    ? futureContinuousCampaignCourseColumns
                    : futureCourseColumns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  components={{ Toolbar: GridToolbar }}
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: {
                        debounceMs: 250,
                        id: 'future-courses-table-search',
                        'aria-label': 'Search'
                      },
                      csvOptions: { disableToolbarButton: true },
                      printOptions: { disableToolbarButton: true }
                    }
                  }}
                  disableColumnSelector
                  disableDensitySelector
                  sx={{
                    borderRadius: '8px',
                    border: '2px solid var(--phin-light-gray)',
                    '.MuiDataGrid-cell:focus': {
                      outline: 'none'
                    },
                    '& .MuiDataGrid-row:hover': {
                      cursor: 'pointer'
                    }
                  }}
                />
              </Box>
            )}
            {campaign && campaign.campaignType === campaignTypes.ONBOARDING && (
              <Box
                id='course-details-table'
                aria-label='Course Details Table'
                sx={{ paddingBottom: '2em', width: '100%', height: 'auto' }}
              >
                <h3 className='phin-h4' style={{ paddingBottom: '0.5em' }}>Courses</h3>
                <DataGrid
                  onRowClick={(params) => handleCoursePreview(params.row)}
                  autoHeight
                  style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
                  className='DataGrid'
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'name', sort: 'asc' }]
                    }
                  }}
                  rows={formattedCampaignCourses || []}
                  columns={onboardingCourseColumns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  components={{ Toolbar: GridToolbar }}
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 250 },
                      csvOptions: { disableToolbarButton: true },
                      printOptions: { disableToolbarButton: true }
                    }
                  }}
                  disableColumnSelector
                  disableDensitySelector
                  sx={{
                    borderRadius: '8px',
                    border: '2px solid var(--phin-light-gray)',
                    '.MuiDataGrid-cell:focus': {
                      outline: 'none'
                    },
                    '& .MuiDataGrid-row:hover': {
                      cursor: 'pointer'
                    }
                  }}
                />
              </Box>
            )}

          </div>
        </div>}
    </>
  )
}

export default TrainingViewer
