import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { updateBrandingSettingsThunk } from '../../../store/BrandingSlice'
import ValidationUtils from '../../../utils/validationUtils'
import NotificationUtilities from '../notifications/notificationUtils'
import { SaveCancelButtonsRow } from '../../../shared/components/SaveCancelButtonsRow'

export default function EmailBranding ({ id }) {
  const dispatch = useDispatch()

  const { whiteLabelName } = useSelector(state => state.branding.brandingSettings)
  const [displayName, setDisplayName] = useState('')
  const [hasChanged, setHasChanged] = useState(false)

  // Make sure to update displayName with whiteLabelName if it is returned by redux
  useEffect(() => {
    setDisplayName(whiteLabelName)
  }, [whiteLabelName])

  function handleChange (text) {
    if (!hasChanged) {
      setHasChanged(true)
    }

    setDisplayName(text)
  }

  async function saveChanges () {
    if (!ValidationUtils.isNotWhiteSpace(displayName)) {
      NotificationUtilities.sendWarningMessage('Display name can not be empty')
      return
    }

    const success = await dispatch(updateBrandingSettingsThunk(id, { whiteLabelName: displayName }))

    if (success) {
      NotificationUtilities.sendSuccessMessage('Successfully saved email display name.')
      setHasChanged(false)
    }
  }

  function discardChanges () {
    setDisplayName(whiteLabelName || '')
    setHasChanged(false)
  }

  return (
    <div className='pageContainer mt-5 mb-5'>
      <div id='welcomeEmailCard'>
        <div className='welcomeCardHeader'>
          <p className='phinCardHeader'>Additional Email Customization</p>
          <p className='welcomeEmailDesc'>
            Adjust the email "From Name" Phin utilizes when sending training notifications to users. By default this will use the partner name. Note that this will not appear on phishing emails.
            <strong> By default this will utilize the partner name.</strong>
          </p>
        </div>
        <div className='row'>
          <div className='col-6'>
            <section className='labelGroup'>
              <p>Display Name</p>
              <input
                type='text'
                id='display-name-field'
                aria-label='Display Name Field'
                placeholder='Display Name'
                value={displayName}
                onChange={e => handleChange(e.target.value)}
                className='textInputBox2 w-100'
              />
            </section>
          </div>
        </div>

        <SaveCancelButtonsRow
          action={saveChanges}
          actionText='Save Changes'
          actionId='save-from-email-name-button'
          close={discardChanges}
          closeText='Discard Changes'
          closeId='cancel-from-email-name-button'
        />
      </div>
    </div>
  )
}
