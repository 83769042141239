import { CloudDownload } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import Spinner from '../Spinner'

export const CloudDownloadButton = ({ isDownloading, downloadAction, id }) => {
  return (
    <>
      {isDownloading
        ? <Spinner size='1rem' noExtraClasses />
        : (
          <IconButton
            disable={isDownloading}
            onClick={downloadAction}
            color='primary'
            id={`download-report-${id}`}
            aria-label='Download report'
          >
            <CloudDownload />
          </IconButton>
          )}
    </>
  )
}
