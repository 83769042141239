import React, { useEffect } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import PartnerOverviewPage from '../pages/PartnerOverviewPage'
import CreatePartner from '../pages/CreatePartner'
import CreateAdmin from '../pages/CreateAdmin'
import { useDispatch, useSelector } from 'react-redux'
import { getDistributorThunk } from '../store/DistributorSlice'
import { useAuth0 } from '@auth0/auth0-react'
import { setPartnerAction } from '../store/PartnerSlice'
import { getCompanyDataAction } from '../store/CompanySlice'
import { setSideNavOrgDataAction } from '../store/SideNavSlice'
import { setTopNavDistributorDataAction } from '../store/TopNavSlice'
import { orgTypes } from '../frontendConsts'
import { DistributorIntegrationsPage } from '../pages/DistributorIntegrationsPage'
import { PhinDistributorApiIntegrationPage } from '../pages/PhinDistributorApiIntegrationPage'

export function DistributorRouter ({ id }) {
  const { distributor } = useSelector((state) => state.distributor)
  const { path } = useRouteMatch()
  const dispatch = useDispatch()
  const { logout } = useAuth0()

  useEffect(() => {
    async function setUp () {
      try {
        dispatch(getDistributorThunk(id))
      } catch (error) {
        logout({ returnTo: window.location.origin })
        throw error
      }
    }

    if (id && distributor?.id !== id) {
      setUp()
    } else if (distributor) {
      dispatch(setSideNavOrgDataAction({ id, name: distributor.name, isFreeTrial: false, orgType: orgTypes.DISTRIBUTOR }))
      dispatch(setTopNavDistributorDataAction({ id, name: distributor.name, orgType: orgTypes.DISTRIBUTOR }))
    }

    dispatch(setPartnerAction(null))
    dispatch(getCompanyDataAction(null))
  }, [])

  return (
    <div style={{ margin: '5rem 3% 2rem', height: '88vh' }}>
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={() => (
            <PartnerOverviewPage
              id={id}
            />
          )}
        />

        <Route
          exact
          path={`${path}/create-partner`}
          render={() => (
            <CreatePartner
              id={id}
            />
          )}
        />

        <Route
          exact
          path={`${path}/create-admin`}
          render={() => (
            <CreateAdmin
              id={id}
            />
          )}
        />

        <Route
          exact
          path={`${path}/integrations`}
          render={() => (<DistributorIntegrationsPage id={id} />)}
        />

        <Route
          exact
          path={`${path}/integrations/phinApi`}
          render={() => (<PhinDistributorApiIntegrationPage id={id} />)}
        />
      </Switch>
    </div>
  )
}
