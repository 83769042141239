import { createSlice } from '@reduxjs/toolkit'

export const sideNavSlice = createSlice({
  name: 'sideNav',
  initialState: {
    isSideNavOpen: false,
    sideNavLogoUrl: '',
    sideNavOrgName: '',
    sideNavOrgId: '',
    sideNavOrgType: '',
    sideNavIsFreeTrial: false
  },
  reducers: {
    toggleSideNav: (state) => {
      state.isSideNavOpen = !state.isSideNavOpen
    },
    setSideNavLogoUrl: (state, action) => {
      state.sideNavLogoUrl = action.payload
    },
    setSideNavOrgData: (state, action) => {
      state.sideNavOrgName = action.payload.name
      state.sideNavOrgId = action.payload.id
      state.sideNavOrgType = action.payload.orgType
      state.sideNavIsFreeTrial = action.payload.isFreeTrial || false
    },
    setSideNavOrgName: (state, action) => {
      state.sideNavOrgName = action.payload.name
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  toggleSideNav: toggleSideNavAction,
  setSideNavLogoUrl: setSideNavLogoUrlAction,
  setSideNavOrgData: setSideNavOrgDataAction,
  setSideNavOrgName: setSideNavOrgNameAction

} = sideNavSlice.actions

export default sideNavSlice.reducer
