import React, { useRef, useState } from 'react'
import { useMountEffect } from '../../../utils/SettingsUtils'
import { FileUploadInput } from '../../../shared/components/FileUploadInput'

function ImageSettings ({
  logoURL,
  handleLogoImageChange,
  shouldFocusImageSettings
}) {
  const [logoFileName, setLogoFileName] = useState('No File Selected')
  const imageRef = useRef(null)
  const executeScroll = () => {
    if (shouldFocusImageSettings) {
      imageRef.current.scrollIntoView()
    }
  }

  useMountEffect(executeScroll)

  const logoUpload = (logo) => {
    handleLogoImageChange(logo)
    const file = logo
    // Get the file name and size
    const { name: fileName, size } = file
    // Convert size in bytes to kilo bytes
    let fileSuffix = 'KB'
    let fileSize = (size / 1000).toFixed(2)
    if (fileSize > 1000) {
      fileSize = (fileSize / 1000).toFixed(2)
      fileSuffix = 'MB'
    }
    // Set the text content
    const fileNameAndSize = `${fileName} - ${fileSize}${fileSuffix}`
    setLogoFileName(fileNameAndSize)
  }

  return (
    <div className='pageContainer' ref={imageRef}>
      <div id='logoEditCard'>
        <div>
          <p className='phinCardHeader'>Logo Customization</p>
          <p>
            Set an image to be this company's logo.
            The logo will be used on all communications for this
            company besides phishing emails and will appear
            on the Learning Management System.
            <strong>
                  &nbsp; Logo uploader supports PNG and JPG only. Updates to the logo can take up to an hour to appear.
            </strong>
          </p>
          <div className='pl-2' style={{ zIndex: 10 }} />
        </div>
        <div className='logoCardContent'>
          <div style={{ height: '400px' }} className='logoDisplayGroup'>
            <div className='bg-white h-100 w-100 d-flex justify-content-center align-items-center logoDisplayRadiusBlack'>
              <img
                id='logo-white-back-image'
                aria-label='Logo White Background Image'
                style={{ maxHeight: '25%', maxWidth: '25%' }}
                src={logoURL}
              />
            </div>
            <div className='bg-dark h-100 w-100 d-flex justify-content-center align-items-center logoDisplayRadiusWhite'>
              <img style={{ maxHeight: '25%', maxWidth: '25%' }} src={logoURL} />
            </div>

          </div>

          <FileUploadInput
            file={logoFileName}
            setFile={logoUpload}
            acceptedFileTypes='image/png, image/jpg, image/jpeg'
          />
        </div>
      </div>
    </div>
  )
}

export default ImageSettings
