import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, TimePicker, DatePicker } from '@mui/x-date-pickers'
import TextField from '@mui/material/TextField'
import { useSelector } from 'react-redux'

export default function WelcomeUsersModal ({
  today,
  sendDate,
  setSendDate,
  sendTime,
  setSendTime,
  userCount
}) {
  const { welcomeEmailBody, welcomeEmailSubject, welcomeEmailFromName } = useSelector((state) => state.welcomeEmail)
  const { logoUrl } = useSelector((state) => state.logo)

  return (
    <div>
      <div>
        <h5>Schedule when these welcome emails send below</h5>
      </div>
      <div className='d-flex justify-content-center mt-4 mb-4'>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <div>
            <DatePicker
              disableToolbar
              variant='inline'
              margin='normal'
              id='date-picker-inline'
              label='Send Date'
              minDate={today}
              value={sendDate}
              onChange={setSendDate}
              textField={(params) => <TextField {...params} />}
            />
          </div>
          <div className='pl-3'>
            <TimePicker
              label='Send Time'
              value={sendTime}
              onChange={setSendTime}
              slotProps={{
                textField: {
                  testId: 'send-time-field'
                }
              }}
            />
          </div>
        </LocalizationProvider>
      </div>

      <div className='pb-2 pt-2'>
        <h5>
          {userCount === 1 && (
            <>
              <strong>{userCount}</strong> user will be sent this welcome email
            </>
          )}
          {userCount > 1 && (
            <>
              <strong>{userCount}</strong> users will be sent this welcome email
            </>
          )}
        </h5>
      </div>
      <div className='welcomeModalPreviewContainer'>
        <div className='emailPreviewGroup'>
          <div className='emailPreviewPaneGroup'>
            <div className='emailPreviewPane'>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', paddingLeft: '16px', paddingTop: '1em', borderBottom: '1px solid' }}>
                <p><b>From: </b> {welcomeEmailFromName}</p>
                <p><b>Subject: </b>{welcomeEmailSubject}</p>
              </div>
              <div className='emailHeader'>
                <img
                  alt=''
                  src={logoUrl}
                />
              </div>
              <div className='emailBody'>
                <p style={{ whiteSpace: 'pre-wrap' }}>{welcomeEmailBody}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
