import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export default createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: (headers, { getState, extra }) => {
      // Passed as extra from the store
      const token = extra.getAccessToken()

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    }
  }),
  endpoints: () => ({})
})
