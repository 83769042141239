import { Badge, Chip, Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { SideNavItem } from './SideNavItem'
import { navigationRoutes } from '../../../frontendConsts'
import { useState } from 'react'
import { IoBarChart, IoChevronDown, IoChevronUp, IoColorPalette, IoDocuments, IoFish, IoHome, IoPaperPlane, IoPeople, IoSchool, IoSettings, IoSync } from 'react-icons/io5'
import { toggleSideNavAction } from '../../../store/SideNavSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

function CompanyNavRoutes ({ id, isSideNavOpen }) {
  const dispatch = useDispatch()
  const location = useLocation()
  const { authorization } = useSelector((state) => state.auth)
  const hasSeenReportAPhish = authorization?.hasSeenReportAPhish
  const [phishingNavOpen, setPhishingNavOpen] = useState(false)
  const [trainingNavOpen, setTrainingNavOpen] = useState(false)
  const [policiesNavOpen, setPoliciesNavOpen] = useState(false)

  return (
    // Routing to any of these will cause the routers to
    // remount and refetch all the company/partner/distributor APIs
    <List>
      <SideNavItem
        id='company-home-nav-link'
        text='Home'
        route={`/companies/${id}`}
        isSideNavOpen={isSideNavOpen}
        icon={<IoHome style={{ color: 'var(--phin-white)' }} size='1.5em' />}
      />
      <ListItem
        disablePadding
        sx={
          (!phishingNavOpen || !isSideNavOpen) && location?.pathname && location.pathname.includes('phishing')
            ? { display: 'block', backgroundColor: 'var(--phin-dark-blue)' }
            : { display: 'block' }
          }
        onClick={() => {
          if (isSideNavOpen) {
            setPhishingNavOpen(!phishingNavOpen)
          } else {
            dispatch(toggleSideNavAction(true))
            setPhishingNavOpen(true)
          }
          setTrainingNavOpen(false)
          setPoliciesNavOpen(false)
        }}
      >
        <ListItemButton sx={{ padding: '8px 20px' }} id='company-phishing-dropdown-nav-link'>
          <ListItemIcon sx={{ minWidth: '0px', marginRight: '8px' }}>
            <IoFish style={{ color: 'var(--phin-white)' }} size='1.5em' />
          </ListItemIcon>
          <ListItemText primary='Phishing' sx={isSideNavOpen ? {} : { visibility: 'hidden' }} />
          {phishingNavOpen ? <IoChevronUp /> : <IoChevronDown />}
        </ListItemButton>
      </ListItem>
      <Collapse in={isSideNavOpen && phishingNavOpen} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          <SideNavItem
            id='company-phishing-analytics-nav-link'
            text='Analytics'
            route={`/companies/${id}/analytics/phishing`}
            isSideNavOpen={isSideNavOpen}
          />
          <SideNavItem
            id='company-phishing-dashboard-nav-link'
            text='Campaigns'
            route={`/companies/${id}${navigationRoutes.CAMPAIGNS}`}
            isSideNavOpen={isSideNavOpen}
          />
          <SideNavItem
            id='company-phishing-templates-nav-link'
            text='Template Library'
            route={`/companies/${id}/templates`}
            isSideNavOpen={isSideNavOpen}
          />
          <SideNavItem
            id='company-phishing-library-nav-link'
            text='Campaign Presets'
            route={`/companies/${id}${navigationRoutes.CAMPAIGNPRESETS}`}
            isSideNavOpen={isSideNavOpen}
          />
        </List>
      </Collapse>
      <ListItem
        disablePadding
        sx={
          (!trainingNavOpen || !isSideNavOpen) && location?.pathname && location?.pathname.includes('training')
            ? { display: 'block', backgroundColor: 'var(--phin-dark-blue)' }
            : { display: 'block' }
          }
        onClick={() => {
          if (isSideNavOpen) {
            setTrainingNavOpen(!trainingNavOpen)
          } else {
            dispatch(toggleSideNavAction(true))
            setTrainingNavOpen(true)
          }
          setPhishingNavOpen(false)
          setPoliciesNavOpen(false)
        }}
      >
        <ListItemButton sx={{ padding: '8px 20px' }} id='company-training-dropdown-nav-link'>
          <ListItemIcon sx={{ minWidth: '0px', marginRight: '8px' }}>
            <IoSchool style={{ color: 'var(--phin-white)' }} size='1.5em' />
          </ListItemIcon>
          <ListItemText primary='Training' sx={isSideNavOpen ? {} : { visibility: 'hidden' }} />
          {trainingNavOpen ? <IoChevronUp /> : <IoChevronDown />}
        </ListItemButton>
      </ListItem>
      <Collapse in={isSideNavOpen && trainingNavOpen} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          <SideNavItem
            id='company-training-analytics-nav-link'
            text='Analytics'
            route={`/companies/${id}/analytics/training`}
            isSideNavOpen={isSideNavOpen}
          />
          <SideNavItem
            id='company-training-dashboard-nav-link'
            text='Campaigns'
            route={`/companies/${id}/training`}
            isSideNavOpen={isSideNavOpen}
          />
          <SideNavItem
            id='company-training-catalog-nav-link'
            text='Library'
            route={`/companies/${id}/training/catalog`}
            isSideNavOpen={isSideNavOpen}
          />
        </List>
      </Collapse>
      <ListItem
        disablePadding
        sx={
          (!policiesNavOpen || !isSideNavOpen) && location?.pathname && (location.pathname.includes('policy') || location.pathname.includes('policies'))
            ? { display: 'block', backgroundColor: 'var(--phin-dark-blue)' }
            : { display: 'block' }
          }
        onClick={() => {
          if (isSideNavOpen) {
            setPoliciesNavOpen(!policiesNavOpen)
          } else {
            dispatch(toggleSideNavAction(true))
            setPoliciesNavOpen(true)
          }
          setTrainingNavOpen(false)
          setPhishingNavOpen(false)
        }}
      >
        <ListItemButton sx={{ padding: '8px 20px' }} id='company-policy-dropdown-nav-link'>
          <ListItemIcon sx={{ minWidth: '0px', marginRight: '8px' }}>
            <IoDocuments style={{ color: 'var(--phin-white)' }} size='1.5em' />
          </ListItemIcon>
          <ListItemText primary='Policies' sx={isSideNavOpen ? {} : { visibility: 'hidden' }} />
          {policiesNavOpen ? <IoChevronUp /> : <IoChevronDown />}
        </ListItemButton>
      </ListItem>
      <Collapse in={isSideNavOpen && policiesNavOpen} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          <SideNavItem
            id='company-policy-analytics-nav-link'
            text='Analytics'
            route={`/companies/${id}/analytics/policies`}
            isSideNavOpen={isSideNavOpen}
          />
          <SideNavItem
            id='company-policy-dashboard-nav-link'
            text='Active Policies'
            route={`/companies/${id}/policy/active`}
            isSideNavOpen={isSideNavOpen}
          />
          <SideNavItem
            id='company-policy-catalog-nav-link'
            text='Library'
            route={`/companies/${id}/policy/catalog`}
            isSideNavOpen={isSideNavOpen}
          />
        </List>
      </Collapse>
      <SideNavItem
        id='company-users-nav-link'
        text='Users'
        route={`/companies/${id}/users`}
        isSideNavOpen={isSideNavOpen}
        icon={<IoPeople style={{ color: 'var(--phin-white)' }} size='1.5em' />}
      />
      <SideNavItem
        id='company-reporting-dashboard-nav-link'
        text='Reports'
        route={`/companies/${id}/reports`}
        isSideNavOpen={isSideNavOpen}
        icon={<IoBarChart style={{ color: 'var(--phin-white)' }} size='1.5em' />}
      />
      <SideNavItem
        id='company-integrations-nav-link'
        text={!hasSeenReportAPhish ? <>Integrations <Chip label='new' size='small' color='phinInvertedSecondary' sx={{ marginLeft: 'var(--phin-s-2)' }} /></> : 'Integrations'}
        route={`/companies/${id}/integrations`}
        isSideNavOpen={isSideNavOpen}
        icon={(!hasSeenReportAPhish && !isSideNavOpen)
          ? <Badge color='phinInvertedSecondary' variant='dot'><IoSync style={{ color: 'var(--phin-white)' }} size='1.5em' /></Badge>
          : <IoSync style={{ color: 'var(--phin-white)' }} size='1.5em' />}
      />
      <SideNavItem
        id='company-outgoing-messages-nav-link'
        text='Outgoing Messages'
        route={`/companies/${id}/messages`}
        isSideNavOpen={isSideNavOpen}
        icon={<IoPaperPlane style={{ color: 'var(--phin-white)' }} size='1.5em' />}
      />
      <SideNavItem
        id='company-branding-nav-link'
        text='Branding'
        route={`/companies/${id}/branding`}
        isSideNavOpen={isSideNavOpen}
        icon={<IoColorPalette style={{ color: 'var(--phin-white)' }} size='1.5em' />}
      />
      <SideNavItem
        id='company-settings-nav-link'
        text='Settings'
        route={`/companies/${id}/settings`}
        isSideNavOpen={isSideNavOpen}
        icon={<IoSettings style={{ color: 'var(--phin-white)' }} size='1.5em' />}
      />
    </List>
  )
}

export default CompanyNavRoutes
